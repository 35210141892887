import React, { useEffect, useState } from 'react';
import { fetchAdminApi } from "../../../services/api";
import "../../../styles/totalregistration.css";
import { Breadcrumb, Row, Col, Card, Form, DatePicker, Space, Table, Select } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import moment from "moment";
import { Chart } from "react-google-charts";
import { useHistory } from "react-router";
import constants from '../../../constants/constants';
import { registrationFilter } from "../../../globalFunctions/GlobalFunctions";
const { Option } = Select;
const RegistrationByCountry = (props) => {
	const history = useHistory();
	let currentYear = moment().year();
	const [form] = Form.useForm();
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [totalRegistrationByCountryCount, setTotalRegistrationByCountryCount] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [graphData, setGraphData] = useState([]);
	const [sMonth, setSmonth] = useState(props && props.location && props.location.state && props.location.state.sd);
	const [eMonth, setEmonth] = useState(props && props.location && props.location.state && props.location.state.ed);
	const [sortedInfo, setsortedInfo] = useState({
		order: 'ascend',
		columnKey: 'srNumber',
	});
	const handleChange = (_pagination, _filters, sorter) => {
		setsortedInfo(sorter);
	};
	useEffect(() => {
		fetchRegistrationByCountry();
	}, [startDate, endDate, sMonth, eMonth]);
	useEffect(() => {
		// return () => {
		// 	if (history.action === "POP") {
		// 		history.push("/admin-dashboard");
		// 	}
		// };
	}, [history]);
	// fetching RegistrationByCountry data based on the date picker.
	const fetchRegistrationByCountry = () => {
		let sded = startDate && endDate  ? startDate && endDate : sMonth && eMonth;
		let sd = moment(sMonth).format("YYYY-MM-DD");
		let ed = moment(eMonth).format("YYYY-MM-DD");
		let curentYear = `/students-by-country?start_date=${constants.startDateOfTheYear}&end_date=${constants.endDateOfTheYear}`;
		let selectedDate = `/students-by-country?start_date=${startDate ? moment(startDate).format("YYYY-MM-DD") : sd}&end_date=${endDate ? moment(endDate).format("YYYY-MM-DD") : ed}`;
		fetchAdminApi(sded ? selectedDate : curentYear, "get").then((res) => {
			if (res && res.data && res.data !== null && res.data !== undefined) {
				let count = res.data.map(item => parseInt(item.registration_count)).reduce((prev, curr) => prev + curr, 0);
				setTotalRegistrationByCountryCount(count);
				let dat = res.data;
				dat.sort((a, b) => {
					return b.registration_count - a.registration_count;
				});
				setTableData(dat);
				let data = res.data;
				//data to display in graph.
				let gfHeader = [];
				gfHeader.push(["Countries", "Registrations"]);
				data.map(obj => gfHeader.push([obj.country_code, parseInt(obj.registration_count)]));
				gfHeader = gfHeader.sort(function (a, b) {
					return b[1] - a[1];
				}).filter((_month, idx) => idx < 11);
				setGraphData(gfHeader);
			} else {
				setTableData([]);
				setTotalRegistrationByCountryCount(0);
				let dat = [];
				dat.push(["Countries", "Registrations"]);
				dat.push(['', 0]);
				setGraphData(dat);
			}
		});
	};
	// routing to registered page with countryid and date baseed on selected country.
	const viewRegistration = (record) => {
		let sd = startDate ? startDate : sMonth;
		let ed = endDate ? endDate : eMonth;
		let country_id = record.country_id;
		let country = {};
		country.country_id = country_id;
		country.country_code = record.country_code;
		country.country_name = record.country_name;
		country.sd = (sd) ? moment(sd).format("YYYY-MM-DD") : constants.startDateOfTheYear;
		country.ed = (ed) ? moment(ed).format("YYYY-MM-DD") : constants.endDateOfTheYear;
		country.sded = startDate&&endDate || sMonth&&eMonth  ? 1 : 2;
		country.title = "Registration By Country";
		country.push = "/registration-by-country";
		country.serchCriteria = `Country: ${record.country_code}, ${`Registrations from ${moment(country.sd).format('MM/DD/YYYY')} to ${moment(country.ed).format('MM/DD/YYYY')}`}`;
		history.push({
			pathname: "/registered-students",
			state: country
		});
	};
	const column = [
		{
			title: "Countries",
			key: "country",
			dataIndex: "country_name",
			width: 200,
			render: (_text, record) => {
				let country_name = record.country_name;
				let countryName = country_name.slice(0, 20);
				return (
					<Space size="middle">
						<a onClick={() => { viewRegistration(record); }} style={{ color: "black" }}>{`${record.country_code} - ${countryName}`}</a>
					</Space>
				);
			},
			sorter: (a, b) => a.country_code.localeCompare(b.country_code),
			sortOrder: sortedInfo.columnKey === 'country' && sortedInfo.order,
		},
		{
			title: "Registrations",
			key: "registrations",
			dataIndex: "registration_count",
			render: (_text, record) => {
				return (
					<Space size="middle">
						<span style={{ width: "65px" }} className="p-relative"><a onClick={() => { viewRegistration(record); }} style={{ color: "black" }}>{record.registration_count}</a></span>
					</Space>
				);
			},
			sorter: (a, b) => a.registration_count - b.registration_count,
			className: "td-registartion-role",
			width: 200,
			align: "center",
			sortOrder: sortedInfo.columnKey === 'registrations' && sortedInfo.order,
		}
	];
	const from = (_date, dateString) => {
		setStartDate(dateString);
		setSmonth();
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
		setEmonth();
	};
	let y = startDate && endDate ? startDate && endDate : sMonth && eMonth;
	if (y) {
		let selectedDate = `${startDate ? moment(startDate).format(constants.dateFormat) : moment(sMonth).format(constants.dateFormat)} to ${endDate ?  moment(endDate).format(constants.dateFormat) : moment(eMonth).format(constants.dateFormat)}`;
		currentYear = selectedDate;
	}
	const graphOption = {
		title: `Country Registrations - ${currentYear }`,
		legend: { position: 'none' },
		colors: ['#0000FF'],
		hAxis: {
			title: "Countries",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		vAxis: {
			title: "\n\nRegistrations",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			},
			format: '#'
		},
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	// Table filter by date
	const onChangeDateFilter = (e) => {
		registrationFilter(e,setStartDateEndDate);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>Registrations by Country</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">{<GlobalOutlined className="icon-lg"/>}{" "}<span>{constants.RegistrationCountry}</span></span>} extra={
								<Form layout="horizontal" form = {form}>
									<Space>
										<Form.Item className="label-default mb-0" label="From:" name = "from">
											<DatePicker defaultValue={sMonth ? moment(sMonth) : null} onChange={ from} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0" label="To:" name = "to">
											<DatePicker defaultValue={eMonth ? moment(eMonth) : null} onChange={to} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0">
											<Select defaultValue="0" style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
												<Option value="0">This Year</Option>
												<Option value="7">Last Year</Option>
												<Option value="1">This Month</Option>
												<Option value="2">Last Month</Option>
												<Option value="3">Last 3 Months</Option>
												<Option value="4">This Month (Last Year)</Option>
												<Option value="5">Last 3 Months (Last Year)</Option>
												<Option value="6">Previous Years</Option>
											</Select>
										</Form.Item>
									</Space>
								</Form>
							}>
								<Row>
									<Col xs={24} sm={24} md={8} lg={8}>
										<Table className="gap-table"
											onChange={handleChange}
											columns={column}
											pagination={true}
											dataSource={tableData}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													<Table.Summary.Row style = {{ fontWeight:500 }}>
														<Table.Summary.Cell index={0} className="last-element"><span>Total</span></Table.Summary.Cell>
														<Table.Summary.Cell index={1} className="last-element"><span style={{ width: "65px" }}>{totalRegistrationByCountryCount}</span></Table.Summary.Cell>
													</Table.Summary.Row>
												</Table.Summary>
											)}
										/>
									</Col>
									<Col xs={24} sm={24} md={16} lg={16}>
										<Chart
											chartType="ColumnChart"
											width="100%"
											height="400px"
											data={graphData}
											options={graphOption}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>;
			</div>
		</>
	);
};
export default RegistrationByCountry;
