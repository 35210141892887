import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table, Space, Avatar,Spin,Tooltip,Typography ,message } from 'antd';
import { FileSearchOutlined, DownloadOutlined, HistoryOutlined, ShareAltOutlined ,MailOutlined, RollbackOutlined } from '@ant-design/icons';
import '../../styles/test.css';
import constants from "../../constants/constants";
import { fetchApi } from "../../services/api";
import { useHistory } from "react-router";
import{ COOKIE,getCookie } from "../../services/cookie";
import { getBadgeIcon } from "../../globalFunctions/GlobalFunctions";
import Loader from "../Common/Cred/Loader";
const { Text } = Typography;
const FullLengthTestResultPage = (props) => {
	const history = useHistory();
	// eslint-disable-next-line react/prop-types
	const [scoreId,]=useState(props&&props.location&&props.location.state?props.location.state.scoreCardId!==undefined&&props.location.state.scoreCardId!==null?props.location.state.scoreCardId:sessionStorage.getItem("fullLengthTestScorecadId"):sessionStorage.getItem("fullLengthTestScorecadId"));
	const [isReview,]=useState(props&&props.location&&props.location.state?props.location.state.isReview?props.location.state.isReview:"f":sessionStorage.getItem("isReview"));
	const [ffpTopicList,setFfpTopicList]=useState([]);
	const [pietTopicList,setPietTopicList]=useState([]);
	const [saedTopicList,setSaedTopicList]=useState([]);
	const [ffpTopicListData,setFfpTopicListData]=useState([]);
	const [pietTopicListData,setPietTopicListData]=useState([]);
	const [saedTopicListData,setSaedTopicListData]=useState([]);
	const [resultData,setResultData]=useState([]);
	const [isPass,setIsPass]=useState(false);
	const [loader,setLoader]=useState(false);
	const [shareScoreMinPercentage,]=useState(62);
	const columns = [
		{
			title: 'Topic',
			dataIndex: 'topic' 
		},
		{
			title: 'Correct',
			className: 'column-money',
			// dataIndex: 'correct',
			align: 'center',
			width:'80px', 
			render:(_record,object)=>{
				return <span className="ans-display">{object.correct}/{object.questions}</span>;
			}
		},
		{
			title: 'Incorrect',
			className: 'column-money',
			align: 'center',
			width:'90px' ,
			render:(record,object)=>{
				let tempValue=object.questions-object.correct;
				if(tempValue===0){
					return <span>-</span>;
				}else{
					return <Tooltip title={constants.ReviewLinkToolTipMsg}><Button type="link" onClick={()=>{reviewIncorrect(record.category_id,record.topic_id);}}>{object.questions-object.correct}/{object.questions}</Button></Tooltip>;
				}
			}
		},
	];
	useEffect(() => {
		fetchTestResult();
		if(props&&props.location&&props.location.state){
			sessionStorage.setItem("fullLengthTestScorecadId",props.location.state.scoreCardId);
			sessionStorage.setItem("isReview",props.location.state.isReview?props.location.state.isReview:"f");
			if(sessionStorage.getItem("resultScorecardId")!==null){
				sessionStorage.removeItem("resultTypeFull");
				sessionStorage.removeItem("resultTestCategory");
				sessionStorage.removeItem("resultScorecardId");
				sessionStorage.removeItem("resultIsTopic");
			}
		}else{
			let queryString = window.location.href;
			var routeValue = queryString.substring(queryString.lastIndexOf('/') + 1);
			if(routeValue==="full-result-page"){
				history.push("/my-tests");
			}else{
				history.push("/home");
			}
		}
	}, []);
	useEffect(()=>{
		return () => {
			if (history.action === "POP") {
				let userId= getCookie(COOKIE.UserId);
				if(userId){
					history.push("/home");
				}
			}
		};
	},[history]);
	// Set state variable after fetch
	const setFetchedData=(fetchedData)=>{
		setResultData(fetchedData);
		if(fetchedData.categories){
			if(fetchedData.categories.FPP){
				setFfpTopicList(fetchedData.categories.FPP);
				setFfpTopicListData(fetchedData.categories.FPP.topics);
			}
			if(fetchedData.categories.PIET){
				setPietTopicList(fetchedData.categories.PIET);
				setPietTopicListData(fetchedData.categories.PIET.topics);
			}
			if(fetchedData.categories.SAED){
				setSaedTopicList(fetchedData.categories.SAED);
				setSaedTopicListData(fetchedData.categories.SAED.topics);
			}
		}
		if(fetchedData.test_result && fetchedData.test_result.toLocaleLowerCase()==="pass"){
			setIsPass(true);
		}
		setLoader(false);
	};
	//To fetch test history data
	const fetchTestResult = () => {
		let resultApilink=`/score_card/${scoreId}?type=result`;
		let resultReviewApilink=`/score_card/${scoreId}?type=result&review=1`;
		fetchApi(isReview==="t"?resultReviewApilink:resultApilink, "get").then((res) => {
			setLoader(true);
			if (res.code===200) {
				setFetchedData(res.data);
			} 
			setLoader(false);
		});
	};
	//To download score card
	const downloadScoreCard = () => {
		window.open(constants.ApiUrl+'/download/pdf/'+scoreId);
	};
		//To send result email
	const sendEmailToMe=()=>{
		fetchApi(`/send_email/${scoreId}`,"post").then((res) => {
			if (res.code===200) {
				message.success(res.message);
			} else{
				message.error(res.message);
			}
		});
	};
	//Function to redirect to Review Incorrect answer.
	const reviewIncorrect=(categoryId,topicId)=>{
		if(scoreId){
			setcategoryDetailsredirect(categoryId,topicId);
		}
	};
		//Function to set category details
	const setcategoryDetailsredirect=(categoryId,topicId)=>{
		let categoryDetails={};
		categoryDetails.scoreCardId=scoreId;
		categoryDetails.review="full_length";
		categoryDetails.ans_inc="incorrect";
		categoryDetails.testTitle="";
		categoryDetails.attempt="";
		categoryDetails.totalTimeTaken="";
		categoryDetails.totalCorrectAnswers="";
		categoryDetails.totalTestQuestions="";
		categoryDetails.testTakenDate="";
		categoryDetails.testTakenTime="";
		if(resultData){
			if(resultData.test_length){
				categoryDetails.testTitle=resultData.test_length;
			}
			if(resultData.test_attempt){
				categoryDetails.attempt=resultData.test_attempt;
			}
			if(resultData.total_time_taken){
				categoryDetails.totalTimeTaken=resultData.total_time_taken;
			}
			if(resultData.total_correct_questions){
				categoryDetails.totalCorrectAnswers=resultData.total_correct_questions;
			}
			if(resultData.test_total_questions){
				categoryDetails.totalTestQuestions=resultData.test_total_questions;
			}
			if(resultData.test_taken_date){
				categoryDetails.testTakenDate=resultData.test_taken_date;
			}
		}
		if(categoryId){
			categoryDetails.categoryId=Number(categoryId);
		}
		if(topicId){
			categoryDetails.topicId=Number(topicId);
		}
		history.push({
			pathname:"/review-incorrect",
			state:{ categoryDetails:categoryDetails } 
		});
	};
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="p-4 resultPage">
					<Row gutter={[{
						xs: 0,
						sm: 0,
						md: 24,
						lg: 24 
					}, {
						xs: 24,
						sm: 24,
						md: 24,
						lg: 40 
					}]} >
						<Col span={24}>
							<Row className="user-detail-wrapper">
								<Col span={24}>
									<Space size="middle" wrap>
										<Avatar size={64} className="avatar mr-2 result-avatar" >{resultData.name_short_inital}</Avatar>
										<div className="user-details">
											<p className="user-name-result">{resultData.user_name}</p>
											<Space wrap>
												<p className="test-name">Test Results of {resultData.test_length} - {resultData.test_total_questions} questions </p>
												<p><Text type="secondary" className="test-name">({resultData.test_taken_date})</Text></p>
											</Space>
											<div>
												<Space wrap>
													{resultData.total_correct_questions===resultData.test_total_questions?null:
														<Button className="btnDb" onClick={()=>{reviewIncorrect();}}><FileSearchOutlined/>{constants.ReviewIncorrectBtn}</Button>
													} 
													<Button className="btnLb d-sm-block" onClick={downloadScoreCard}><DownloadOutlined/>{constants.DownloadResultBtn}</Button>
													{resultData.is_disable_email===1?null:
														<Button className="btnLighter" onClick={sendEmailToMe}><MailOutlined/>{constants.EmailResultsBtn}</Button>
													}
													<Button className="purple-bg"  onClick={()=>{history.push("/my-tests");}}><RollbackOutlined/>{constants.ReturnToMyTests}</Button>
												</Space>
											</div>
										</div>
									</Space>
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							{isPass?
								<div className="score-section score-bkg">
									<Space size="large" wrap>
										<span className="pass-score">PASS : {resultData.test_percentage}%</span>
										<span className="pass-score">SCORE : {resultData.total_score}</span>  
										<span>
											{shareScoreMinPercentage<=resultData.test_percentage?
												<span><Button className="gold-btn share-score" 
													onClick={()=>{history.push({
														pathname:"/share-score",
														state:
												{
													scoreCardId:scoreId,
													resultObj:resultData,
													isResultPage:false 
												} 
													});}}
												> <ShareAltOutlined/>{constants.ShareScoreBtn}</Button></span>
												:null}
										</span> 
									</Space> 
								</div>:
								<div className="score-section">
									<Space size="large" wrap>
										<span>DID NOT PASS : {resultData.test_percentage}%</span>
										<span>SCORE : {resultData.total_score}</span>   
									</Space> 
								</div> 
							}
							<Row gutter={[8, 8]} className="w-100 result-table-container">
								<Col span={24} lg={8} >
									<Table 
										columns={columns} 
										dataSource={ffpTopicListData} 
										bordered
										title={() => {
											return(
												<div className="table-title w-100">
													<p>{constants.CategoryFPP}</p>
													<p className="title-two">{ffpTopicList.total_correct?ffpTopicList.total_correct:0}/{ffpTopicList.total_questions?ffpTopicList.total_questions:0} questions answered correctly</p>
												</div>
											);
										}}
										className="customTable grey-custom-table resut-table"
										pagination={false}
									/>
								</Col>
								<Col span={24} lg={8}>
									<Table 
										columns={columns} 
										dataSource={saedTopicListData} 
										bordered
										//   title={() => '[SAED] Screening, Assessment, Evaluation and Diagnosis'}
										title={() => {
											return(
												<div className="table-title w-100">
													<p>{constants.CategorySAED}</p>
													<p className="title-two">{saedTopicList.total_correct?saedTopicList.total_correct:0}/{saedTopicList.total_questions?saedTopicList.total_questions:0} questions answered correctly</p>
												</div>
											);
										}}
										//   title={() => this.handleTitleSAED()}
										className="customTable grey-custom-table resut-table"
										//   rowSelection={{
										//       type: selectionType
										//   }}
										pagination={false}
									/>
								</Col>
								<Col span={24} lg={8}>
									<Table 
										columns={columns} 
										dataSource={pietTopicListData} 
										bordered
										//   title={() => this.handleTitlePIET()}
										className="customTable grey-custom-table resut-table"
										title={() => {
											return(
												<div className="table-title w-100">
													<p>{constants.CategoryPIET}</p>
													<p className="title-two">{pietTopicList.total_correct?pietTopicList.total_correct:0}/{pietTopicList.total_questions?pietTopicList.total_questions:0} questions answered correctly</p>
												</div>
											);
										}}
										pagination={false}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={24} sm={24} md={24}>
							<Row className="result-bottom-section">
								<Col md={16} className="mb-3 fs-20">
									{resultData.is_timed==="t"?
										<><span className="correct-answer mr-1">You answered {resultData.total_correct_questions}/{resultData.test_total_questions} questions correctly in {resultData.total_time_taken}</span><Tooltip title="Timed"><HistoryOutlined/></Tooltip></>
										:
										<p className="correct-answer">You answered {resultData.total_correct_questions}/{resultData.test_total_questions} questions correctly.</p>
									}
									{resultData.is_timed==="t"?
										<p className="avg-time">Average time per question : {resultData.average_time}</p>
										:null}
								</Col>
								<Col className="justify-end-middle" md={8}>
									<div className="pr-3 space-between">
										<div className="pr-3">
											<p>{constants.Levels[resultData&&
											resultData.level&&
											resultData.level.current_level&&
											resultData.level.current_level.level]}
											</p>
											<p>{resultData&&resultData.level&&resultData.level.current_level&&resultData.level.current_level.designation}</p>
										</div>
										{getBadgeIcon(resultData&&resultData.level&&resultData.level.current_level&&resultData.level.current_level.level,"","score-icon")}
									</div>
									<div className="fs-20">
										<p>You earned <span className="blue-2">{resultData.points_earned} points</span> on this test attempt </p>
										<p>Total points earned : <span className="blue-2">{resultData.total_points}</span> </p>
										{!resultData.is_next_level?
											<>
												{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.current_level.level>=1?
													resultData&&resultData.level&&resultData.level.next_level&&resultData.level.current_level.level>=5?
														<p>{constants.Level6Message}</p>:
														<p><span className="blue-2">{resultData.points_required_level}</span> {constants.ReachLevelText}{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.next_level.level+", "+resultData.level.next_level.designation}</p>:
													<p><span>{constants.ReachLevel0}{", "}</span>{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.next_level.designation}</p>												}
											</>
											:<p>Congratulations! You reached <span className="blue-2">Level {resultData&&resultData.level&&resultData.level.current_level.level}</span></p>
										}
									</div>
								</Col>
							</Row>
						</Col>
					</Row> 
				</div>
			</Spin>
		</>
	);
};
export default FullLengthTestResultPage;			
