import React, { useState, useEffect } from "react";
import { Input, Form, Row, Col, Button, Space, message, Menu, Drawer, Layout,Card } from 'antd';
import { MenuOutlined } from "@ant-design/icons";
import "../../../styles/login.css";
import constants from '../../../constants/constants';
import { useHistory } from "react-router";
import { fetchAdminApi, fetchApi } from "../../../services/api";
import Footers from "../../../footer";
import { spacesNotAllowed } from "../../../globalFunctions/GlobalFunctions";
import Book from './Book';
const ResetPassword = () => {
	const { Header } = Layout;
	const [mobileMenu, setMobileMenu] = useState(false);
	const showMobileMenu = () => {setMobileMenu(true);};
	const closeMobileMenu = () => {setMobileMenu(false);};
	const history = useHistory();
	const [userToken,setUserToken]=useState("");
	const [passwordStatus,setPasswordStatus]=useState("");
	const [loading,setLoading]=useState(false);
	const [displayValidationMessage,setValidationMessage]=useState("");
	const [apiWait,setApiWait]=useState(false);
	const [isTokenValid,setIstokenVaid]=useState(false);
	const [isAdmin,setIsAdmin]=useState(false);
	useEffect(() => {
		fetchUrlParameters();
	}, []);
	const fetchUrlParameters=()=>{
		let queryString = window.location.href;
		var token = queryString.substring(queryString.lastIndexOf('?') + 1);
		var tempValueHref=queryString.substring(queryString.lastIndexOf('/') + 1);
		setIsAdmin(tempValueHref.split('?')[0]==="password-reset"?true:false);
		setUserToken(token);
		checkActivationvalidity(token,tempValueHref.split('?')[0]==="password-reset"?true:false);
	};
	const checkActivationvalidity=(tokenValue,isAdminValue)=>{
		let payload=null;
		payload={
			token:tokenValue,
			type:isAdminValue===true?"admin":"student"
		};
		fetchApi(`/user/validate-token`, "post",payload).then((res) => {
			if (res && res.code ) {
				setValidationMessage(res.message);
				setApiWait(true);
				if(res.code===200){
					setIstokenVaid(true);
				}else{
					setIstokenVaid(false);
				}
			}else{
				setApiWait(true);
				setIstokenVaid(false);
			}
		});
	};
	const handleResetPassword=(values)=>{
		let payload = {};
		payload = { 
			password: spacesNotAllowed(values.newPassword),
			confirm_password:spacesNotAllowed(values.confirmPassword),
			token:userToken,
			type:isAdmin===true?"admin":"student",
		};
		setLoading(true);
		if(isAdmin===true){
			fetchAdminApi("/user/reset-password", "post", payload).then((res) => {
				setLoading(false);
				if (res && res.code && res.code === 200) {
					setLoading(false);
					message.success(res.message);
					history.push("/admin-login");
				} else {
					setLoading(false);
					message.error(res.message);
				}
			});
		}else{
			fetchApi("/user/reset-password", "post", payload).then((res) => {
				setLoading(false);
				if (res && res.code && res.code === 200) {
					setLoading(false);
					message.success(res.message);
					history.push("/");
				} else {
					setLoading(false);
					message.error(res.message);
				}
			});
		}
	};
	//Function to validate password to check if it has 1 special character, 1 Upper case, 1 number and should have 8 characters
	const handlePasswordValidation=(rule,value,callback)=>{
		try {
			if(value){
				let passwordValidate=/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/g;
				let check=passwordValidate.test(value);
				if(check===false){
					setPasswordStatus("error");
					throw new Error(constants.PasswordValidation);
				}else{
					setPasswordStatus("success");
				}
			}else{
				setPasswordStatus("error");
				throw new Error(constants.PasswordRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	return (
		<div>
			<Header className="header-light slp-header pl-4 pr-3">
				<div>
					{/* Login header start */}
					<div className="logo bookname mt-3">
						<Space>
							<div className="mobile-menu-div">
								<Button
									type="primary"
									shape="circle"
									icon={<MenuOutlined/>}
									onClick={showMobileMenu}
									className="menu-btn"
								/>
							</div>
							<h3 className="mb-0" onClick={() => {
								window.open(constants.SiteUrl);
							}}>
									An Advanced Review of Speech-Language Pathology, 6th Edition:
									Practice Examinations
							</h3>
						</Space>
					</div>
					<div className="pc-navbar">
						<Menu mode="horizontal">
							<Menu.Item key="author1" onClick={()=>{window.open(constants.SiteUrl+'/celeste-roseberry-mckibbin/', '_blank');}}>
									Celeste Roseberry-McKibbin, Ph.D.
							</Menu.Item>
							<Menu.Item key="author2" onClick={()=>{window.open(constants.SiteUrl+'/m-n-giri-hegde/', '_blank');}}>M. N. Hegde, Ph.D.</Menu.Item>
							<Menu.Item key="author3" onClick={()=>{window.open(constants.SiteUrl+'/dr-glen-tellis/', '_blank');}}>Glen M. Tellis, Ph.D.</Menu.Item>
						</Menu>
					</div>
					{/* Mobile Menu Start */}
					<div className="mobile-menu-div">
						<Drawer
							title="PRAXIS - SLP Review"
							placement="left"
							className="mobile-menu"
							onClose={closeMobileMenu}
							visible={mobileMenu}
						>
							<Menu mode="inline">
								<Menu.Item key="author1">Glen M. Tellis, PhD</Menu.Item>
								<Menu.Item key="author2">M. N. Hegde, PhD</Menu.Item>
								<Menu.Item key="author3">
										Celeste Roseberry-McKibbin, PhD
								</Menu.Item>
							</Menu>
						</Drawer>
					</div>
					{/* Mobile Menu End */}
					{/* Login header end */}
				</div>
			</Header>
			<div className="login-content flex-vertical-middle">
				<Row gutter={[{
					xs: 8,
					sm: 24,
					md: 48,
					lg: 8,
					xl: 8
				}, 8]} className="login-px">
					<Book/>
					<Col lg={24} xl={14} className="w-100 flex-middle">
						{isAdmin===true?null:
							<div className="text-right signup-link">
								<Space>
									{constants.SignUpRedirect}
									<Button type="link" className="pl-0" onClick={()=>{	history.push("/registration");}}>
										{constants.SignUpRedirectLink}
									</Button>
								</Space>
							</div>
						}
						{apiWait===true?
							<div className="ml-135">
								<br/>
								{!isTokenValid?
									<Card>
										<h1 className="purple-bg p-3">{constants.ResetPsd}</h1>
										<>
											{displayValidationMessage}
										</>
									</Card>
									:
									<>
										<h1>{constants.ResetPsd}</h1>
										<Form layout="vertical" onFinish={handleResetPassword}>
											<div className="login-form">
												<Form.Item
													name="newPassword"
													label={constants.NewPsd}
													validateStatus={passwordStatus}
													rules={[
														{
															required: true,
															message: "",
														},
														{ validator: handlePasswordValidation },
													]}
													hasFeedback
													tooltip={constants.PsdTip}
												>
													<Input.Password className="login-input" placeholder={constants.Password} autoFocus/>
												</Form.Item>
												<Form.Item
													name="confirmPassword"
													label={constants.ConfirmNewPsd}
													dependencies={["newPassword"]}
													hasFeedback
													rules={[
														{
															required: true,
															message: "Confirm your password",
														},
														({ getFieldValue }) => ({
															validator(_, value) {
																if (!value || getFieldValue("newPassword") === value) {
																	return Promise.resolve();
																}
																return Promise.reject(
																	new Error(
																		"The two passwords that you entered do not match"
																	)
																);
															},
														}),
													]}
												>
													<Input.Password className="login-input" placeholder={constants.ConfirmNewPsd}/>
												</Form.Item>
											</div>
											<Space>
												<Form.Item>
													<Button shape="round" size="large" className="gold-btn" onClick={()=>{isAdmin===true?history.push("/admin-login"):history.push("/");}}>
														{constants.BackToLogin}
													</Button>
												</Form.Item>
												<Form.Item>
													<Button shape="round" size="large" className="gold-btn" htmlType="submit" loading={loading}>
														{constants.ChangePsd}
													</Button>
												</Form.Item>
											</Space>
										</Form>
									</>
								}
							</div>
							:null}
					</Col>
				</Row>
			</div>
			<Footers/>
		</div>
	);
};
export default ResetPassword;
