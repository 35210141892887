import constants from "../constants/constants";
import { StarFilled, TrophyOutlined, HourglassOutlined,EyeOutlined,EyeInvisibleOutlined,PauseOutlined,CheckOutlined,ClockCircleOutlined,FlagOutlined,ReadOutlined } from "@ant-design/icons";
import { Button,Tooltip,Space,notification } from 'antd';
// function used to trim a string (to browsers that doesn't suppot trim() function)
import Icon from '@ant-design/icons';
import CustomIcons from "../assets/CustomIcons";
import { useHistory } from "react-router";
import '../styles/pausetest.css';
import { Modal, Table,Col,Image } from 'antd';
import moment from 'moment';
export function myTrim(myString) {
	return myString.replace(/^\s+|\s+$/gm, "");
}
export function removeExtraSpaces(myString) {
	let tempStr = myTrim(myString);
	return tempStr.replace(/\s\s+/g, " ");
}
export function spacesNotAllowed(myString){
	return myString.replace(/\s/g, "");
}
//To get the Badge level.
export function getbadge(points){
	if(points>=1&&points<=500){
		return constants.Novoice;
	}else if(points>500&&points<=2000){
		return constants.Achiever;
	}else if(points>2000&&points<=3500){
		return constants.Scholar;
	}else if(points>3500&&points<=5500){
		return constants.AllStar;
	}else if(points>5500&&points<7278){
		return constants.Champion;
	}
}
//To display email warning
export const openNotification = () => {
	const args = {
		description:
		<div>
			<strong>Important Note :</strong>
			<p>We recommend that you do not use Yahoo, Hotmail, AOL or Outlook email accounts to register as we have received reports of mail delivery issues to these accounts.</p>
			<p>
To avoid emails from the advancedreviewpractice.com going automatically into the Spam/Junk folder, please add our email address <strong><a href="mailto:advancedreviewpractice@proedinc.com">(advancedreviewpractice@proedinc.com)</a></strong> to your address book.
			</p>
			<p>
If you are still unable to proceed, email us at <strong><a href="mailto:advancedreviewpractice@proedinc.com">advancedreviewpractice@proedinc.com</a></strong> with an alternate email address.
			</p>
		</div>
		,
		duration: 0,
		placement: 'bottomLeft',
		style: { width: 600, },
	};
	notification.open(args);
};
export const openNotificationCred = () => {
	const args = {
		description:
		<div>
			<strong>Important Note :</strong>
			<p>If your primary email is either Yahoo, Hotmail, AOL or Outlook account, you may experience issues receiving emails from the website. Check for the Instructions mail at your alternate email account.
			</p>
			<p>
To avoid emails from <a href="mailto:advancedreviewpractice@proedinc.com">advancedreviewpractice.com</a> going automatically into the Spam/Junk folder, please add our email address <strong><a href="mailto:advancedreviewpractice@proedinc.com">(advancedreviewpractice@proedinc.com)</a></strong> to your address book.
			</p>
			<p>
If you are still unable to proceed, email us at <strong><a href="mailto:advancedreviewpractice@proedinc.com">advancedreviewpractice@proedinc.com</a></strong> with an alternate email address.
			</p>
		</div>
		,
		duration: 0,
		placement: 'bottomLeft',
		style: { width: 600, },
	};
	notification.open(args);
};
//To display the Icon of the badge.
export function getBadgeIcon(level,divClassName,iconClassName){
	const Certificate = (props) => (<Icon component={CustomIcons.getsvg("Certificate")} {...props}/>);
	const WheatStar = (props) => (<Icon component={CustomIcons.getsvg("WheatStar")} {...props}/>);
	const Graduation = (props) => (<Icon component={CustomIcons.getsvg("Graduation")} {...props}/>);
	const Ladder = (props) => (<Icon component={CustomIcons.getsvg("Ladder")} {...props}/>);
	if(level===0){
		return <div className={"hexagon level0 "+divClassName}><Ladder  className={iconClassName}/> </div>;
	}else if(level===1){
		return <div className={"hexagon level1 "+divClassName}><StarFilled  className={iconClassName}/> </div>;
	}else if(level===2){
		return <div className={"hexagon level2 "+divClassName} ><Certificate  className={iconClassName}/> </div>;
	}else if(level===3){
		return <div className={"hexagon level3 "+divClassName}><Graduation  className={iconClassName}/> </div>;
	}else if(level===4){
		return <div className={"hexagon level4 "+divClassName}><WheatStar  className={iconClassName}/> </div>;
	}else if(level===5){
		return <div className={"hexagon level5 "+divClassName}><TrophyOutlined  className={iconClassName}/> </div>;
	}
}
//To calculate the percentage completed for the current level
export function calculatePercentage(testsTaken,totalTests){
	let percentage=(testsTaken/totalTests)*100;
	return Math.round(percentage);
}
export const timeOutPopup=(timeOutVisible,handleTimeOutOk)=>{
	return(
		<Modal
			visible={timeOutVisible}
			okText="Ok"
			onOk={handleTimeOutOk}
			closable={false}
			maskClosable={false}
			footer={false}
			bodyStyle={{ padding:'40px' }}
		>
			<div className="status-container text-center">
				<HourglassOutlined className="status-icon"/>
				<h1 className="status-title">{constants.TimeOutHeader}</h1>
				<div className="status-content">
					<p>{constants.TimeOutText}</p>
					<p> Click <Button onClick={()=>{handleTimeOutOk();}} className="btnDb">OK</Button> to submit.</p>
				</div>
			</div>
		</Modal>
	);
};
export const caseStudyPopup=(caseStudyVisible,onOk)=>{
	return(
		<Modal
			visible={caseStudyVisible}
			onOk={onOk}
			okText="Ok"
			closable={false}
			maskClosable={false}
			cancelButtonProps={{ style: { display: "none" } }}
			title={constants.CaseStudyPopupHeader}>
			{constants.CaseStudyPopupText}
		</Modal>
	);
};
export function sortArrayOfObjects(items, targetIndex, targetIndex2 = null,isNumber=false) {
	items.sort(function (a, b) {
		var nameA = a[targetIndex].toString().toUpperCase(); // ignore upper and lowercase
		var nameB = b[targetIndex].toString().toUpperCase(); // ignore upper and lowercase
		if (targetIndex2) {
			// sort if supplied 2 indices
			var target2A = a[targetIndex2].toString().toUpperCase();
			var target2B = b[targetIndex2].toString().toUpperCase();
			if (nameA < nameB || target2A < target2B) {
				return -1;
			}
			if (nameA > nameB || target2A > target2B) {
				return 1;
			}
			// names must be equal
			return 0;
		}else if(isNumber===true){
			var number1=a[targetIndex];
			var number2=b[targetIndex];
			return number1-number2;
		}else {
			// sort if only one index is passed
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		}
	});
	return items;
}
export const searchQuestionTable=(columnsQuestions,searchQuestionData)=>{
	return (<div>
		<Table
			showHeader={false}
			columns={columnsQuestions} 
			dataSource={searchQuestionData} 
			bordered
			pagination={false}      
		/>
	</div>);
};
export const displayQuestionDetails=(listQuestions,questionIndex)=>{
	let correctOptionToDisplay=[];
	if(listQuestions&&listQuestions.length>0){
		if(listQuestions[questionIndex].q_type==="5"){
			for (let j = 0; j < listQuestions[questionIndex].correct_option.length; j++) {
				// if(res.data[reviewType].question_list[i].correct_option[j].includes("/")){
				// 	correct_option=res.data[reviewType].question_list[i].correct_option[res.data[reviewType].question_list[i].correct_option.length-1];
				// }
				let splitted=listQuestions[questionIndex].correct_option[j].split("");
				let convertedToAlpha=[];
				for (let k = 0; k < splitted.length; k++) {
					convertedToAlpha.push(constants.OptionstoDisplayValues[splitted[k]]);
				}
				correctOptionToDisplay.push(convertedToAlpha.toString().replaceAll(",",""));
			}
		}else{
			for (let j = 0; j < listQuestions[questionIndex].correct_option.length; j++) {
				let converted=Number(listQuestions[questionIndex].correct_option[j]);
				correctOptionToDisplay.push(constants.OptionstoDisplayValues[converted]);
			}
		}
	}
	return(
		<Col span={24} style={{ visibility:"hidden" }}>
			<b>Question Id:</b> {listQuestions[questionIndex].question_id?listQuestions[questionIndex].question_id:""}{"      "}
			{listQuestions[questionIndex].case_study_id?(<b>CS*</b>):null}{"     "}
			<b>Category:</b> {listQuestions[questionIndex].category_abbreviation?listQuestions[questionIndex].category_abbreviation:""}{"      "}
			<b>Topic:</b>{listQuestions[questionIndex].topic_title?listQuestions[questionIndex].topic_title:""}{"      "}
			<b>Correct Answer:</b> {
				correctOptionToDisplay&&
				correctOptionToDisplay.length>0?correctOptionToDisplay[0].includes("/")?correctOptionToDisplay[0].replaceAll("/",", "):
						correctOptionToDisplay.join(", ")
					:listQuestions&&
                    listQuestions.length>0&&
                    correctOptionToDisplay?
						correctOptionToDisplay:""}
		</Col>
	);
};
export const featuredImageUrl=(featuredImg)=>{
	return(<span className="imgwidthunset">
		<Image 
			width="auto" 
			height="150px" 
			src={featuredImg} 
			alt="FeaturedImage"
			preview={{
				mask: (
					<Space direction="vertical" align="center">
					Click to zoom
					</Space>
				),
			}}
		/>
	</span>);
};
export const registrationFilter =(e,setStartDateEndDate) =>{
	if (e === "0") {
		//current year
		setStartDateEndDate(constants.startDateOfTheYear,constants.endDateOfTheYear);
	}
	else if (e === "1") {
		//current month
		setStartDateEndDate(constants.currentMonthSD,constants.currentMonthED);
	}
	else if(e === "2") {
		//last month
		setStartDateEndDate(constants.lastMonthSD,constants.lastMonthED);
	} 
	else if(e === "3") {
		//Last three months
		setStartDateEndDate(constants.lastThreeMonthSD,constants.lastThreeMonthED);
	}
	else if(e === "4") {
		//This month last year
		setStartDateEndDate(constants.currentMonthLastYearSD,constants.currentMonthLastYearED);
	}
	else if(e === "5") {
		//Last three month last year
		// Get the current date
		const currentDate = moment();
		// Calculate the start date of the same month three months ago
		const startDate = currentDate.clone().startOf('month').subtract(3, 'months');
		// If the current month is January, subtract an additional year
		// Check if the current month is January
		if (currentDate.month() === 0) {
			// If so, subtract an additional year
			startDate.subtract(1, 'year');
		} else {
			// Subtract one more year to get the start date in the previous year
			startDate.subtract(1, 'year');
		}
		const endDate = startDate.clone().add(2, 'months').endOf('month');
		setStartDateEndDate(startDate,endDate);
	}
	else if(e ==="6"){
		//previous year
		setStartDateEndDate(constants.lastYearStartDate,constants.lastYearEndDate);
	}
	else if(e ==="7"){
		//Last year
		setStartDateEndDate(constants.startDateOfTheLastYear,constants.endDateOfTheLastYear);
	}
};
export const displayOtherUniversityAlaska = (key,value) => {
	if(key){
		if (key ==="Alaska") {
			for (let i = 0; i < value?.universities.length; i++) {
				if ( value?.universities?.region_code === undefined ) { 
					value?.universities?.splice(i, 1); 
				}
			}
		}
	}
};
export const testModalPopup = (
	pauseTest,
	completeTest,
	categoryDetails,
	topicDetails,
	viewTimer,
	timerDisplay,
	toggleTimer,
	attemptedQuestions,
	pauseTestAnswer,
	saveNotes,
	pauseTestLoading,
	setPauseTest,
	fullLengthDetails,
	checkAnswers,
	completeTestApi,
	completeTestLoading,
	lastTenMinutes,
	setLastTenMinutes,
	markedLaterVisible,
	handleOkMarkForLaterModal,
	timeOutVisible,
	handleTimeOutOk,
	caseStudyVisible,
	handleCaseStudyOk
)=>{
	let listQuestions=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
	return(
		<div>
			<Modal
				visible={pauseTest?true:completeTest?true:lastTenMinutes?true:markedLaterVisible?true:timeOutVisible?true:caseStudyVisible?true:false}
				maskClosable={false}
				title={
					// Header of the Popup starts here.
					<>
						<div>Test - {/* {categoryDetails&&categoryDetails!=={}&&categoryDetails!==null?
							categoryDetails.catType?
								categoryDetails.catType:""
							:""
						} */}
						{topicDetails&&topicDetails!=={}&&topicDetails!==null?
							topicDetails.topicTitle?
								topicDetails.topicTitle:""
							:""}
						{(topicDetails&&topicDetails!=={}&&topicDetails!==null&&topicDetails.topicTitle&&topicDetails.topicTitle.length>=constants.mimimumCharactersPopup)?
							<div className="pausetime-wrapper">
								{topicDetails&&topicDetails!=={}&&topicDetails!==null?
									topicDetails.topicTitle?
										topicDetails.isRetake===true?" (2nd Attempt) 25 questions":" (1st Attempt) 25 questions":""
									:""}
							</div>:
							<>
								{topicDetails&&topicDetails!=={}&&topicDetails!==null?
									topicDetails.topicTitle?
										topicDetails.isRetake===true?" (2nd Attempt) 25 questions":" (1st Attempt) 25 questions":""
									:""}
							</>
						}
						{categoryDetails&&categoryDetails.attempt!==null?categoryDetails.count===25?categoryDetails.attempt===1?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #1 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
							categoryDetails.attempt===2?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #2 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
								categoryDetails.attempt===3?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #3 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
									categoryDetails.attempt===4?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #4 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
										categoryDetails.attempt===5?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #5 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
											categoryDetails.attempt===6?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #6 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):""
							:categoryDetails.count===50?
								categoryDetails.attempt===1?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #3 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
									categoryDetails.attempt===2?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #4 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):""
								:""
							:""}
						{fullLengthDetails&&fullLengthDetails.test_id?"Full Length #"+fullLengthDetails.test_id+ "  132 questions":""}
						</div>
						<div className="pausetime-wrapper">
							{viewTimer ? "Time Remaining: "+timerDisplay : ""}
							{categoryDetails&&categoryDetails.isTimed!==null&&
                        categoryDetails.isTimed===true ?
								<Button className="timerIcon"
									onClick={toggleTimer} 
									icon={viewTimer ?
										<Tooltip title={constants.HideTimer}>
											<EyeOutlined/>
										</Tooltip> : 
										<Tooltip title={constants.ShowTimer}>
											<EyeInvisibleOutlined/>
										</Tooltip>}>
								</Button>:""
							}
							{
								topicDetails&&
                        topicDetails.isTimed!==null&&
                        topicDetails.isTimed===true ?
									<Button className="timerIcon"
										onClick={toggleTimer} 
										icon={viewTimer ?
											<Tooltip title={constants.HideTimer}>
												<EyeOutlined/>
											</Tooltip> : 
											<Tooltip title={constants.ShowTimer}>
												<EyeInvisibleOutlined/>
											</Tooltip>}>
									</Button>:""
							}
							{
								fullLengthDetails&&
								fullLengthDetails.isTimed!==null&&
								fullLengthDetails.isTimed===true ?
									<Button className="timerIcon"
										onClick={toggleTimer} 
										icon={viewTimer ?
											<Tooltip title={constants.HideTimer}>
												<EyeOutlined/>
											</Tooltip> : 
											<Tooltip title={constants.ShowTimer}>
												<EyeInvisibleOutlined/>
											</Tooltip>}>
									</Button>:""
							}
						</div>
					</>
					//Modal title ends here.
				}
				footer={false}
				closable={false}
				className="completed-modal"
			>
				{/* Pause test popup contents start here */}
				{pauseTest?<div className="status-container text-center">
					<PauseOutlined className="status-icon"/>
					<h1 className="status-title">Pause Test</h1>
					<div className="status-content">
						<p>You have attempted <span className="navy-2"> {attemptedQuestions}/{listQuestions&&listQuestions.length} </span>questions.<br/>
							{constants.PauseTestText3}</p>
						<p><span>Click here to </span>
							<Button className="btnDb"
								onClick={()=>{
									pauseTestAnswer();
									saveNotes();
								}
								} 
								loading={pauseTestLoading}>
                                    Pause
							</Button> 
						</p>
						<div><p className="my-3 or">OR</p></div>
						<p><Button className="btnDb" onClick={()=>{setPauseTest(false);}}> Resume </Button>{constants.PauseTestText2}</p>
					</div>
					{/* Pause test popup contents ends here */}
				</div>:
					completeTest?
						<div className="status-container text-center">
							{/* Complete test popup contents start here */}
							<CheckOutlined className="status-icon"/>
							<h1 className="status-title">You Have Completed The Test</h1>
							<div className="status-content status-container">
								<p>{constants.CompleteTestText}<Button className="btnDb" onClick={()=>{checkAnswers();}}> Check </Button>{constants.CompleteTestText3}</p>
								<div><p className="my-3 or">OR</p></div>
								<p><Button className="btnDb" onClick={()=>{fullLengthDetails&&fullLengthDetails.test_id?null:saveNotes();completeTestApi();}} loading={completeTestLoading}>Finish </Button>{constants.CompleteTestText2}</p>
							</div>
							{/* Complete test popup contents ends here */}
						</div>
						:lastTenMinutes?
							<div className="status-container text-center">
								{/* Last 5 minutes for short length test and 10 minutes left for test popup starts here */}
								<ClockCircleOutlined className="status-icon danger-icon"/>
								<h1 className="status-title">{categoryDetails&&categoryDetails.type==="normal"||topicDetails&&topicDetails.type==="normal"?constants.FiveMinutesTitle:constants.TenMinutesTitle}</h1>
								<div className="status-content">
									<p>{categoryDetails&&categoryDetails.type==="normal"||topicDetails&&topicDetails.type==="normal"?constants.LastFiveMinutes:constants.LastTenMinutes}</p>
									<p> Click <Button className="btnDb" onClick={()=>{setLastTenMinutes(false);}}>OK</Button> to continue.</p>
								</div>
								{/* Last 5 minutes for short length test and 10 minutes left for test popup ends here */}
							</div>:markedLaterVisible?
								<div className="status-container text-center">
									{/* Marked for later questions popup content starts here*/}
									<FlagOutlined className="status-icon mfl-icon"/>
									<h1 className="status-title">{constants.PopupText}</h1>
									<div className="status-content">
										<p>{constants.PopupTextDescription}</p>
										<p><span>Click </span>
											<Button className="btnDb" onClick={()=>{handleOkMarkForLaterModal();}}>
                                                            OK
											</Button>
											<span> to answer these question(s),</span>
										</p>
										<div><p className="my-3 or">OR</p></div>
										<p><Button className="btnDb" onClick={()=>{fullLengthDetails&&fullLengthDetails.test_id?null:saveNotes();completeTestApi();}}> Submit </Button>
											<span> the test and display your results.</span></p>
									</div>
									{/* Marked for later questions popup content ends here*/}
								</div>:timeOutVisible?
									<div className="status-container text-center">
										{/* Test ended popup content starts here */}
										<HourglassOutlined className="status-icon"/>
										<h1 className="status-title">{constants.TimeOutHeader}</h1>
										<div className="status-content">
											<p>{constants.TimeOutText}</p>
											<p> Click <Button onClick={()=>{handleTimeOutOk();}} className="btnDb">OK</Button> to submit.</p>
										</div>
										{/* Test ended popup content ends here */}
									</div>
									:caseStudyVisible?
										<div className="status-container text-center">
											{/* Case study popup content starts here */}
											<ReadOutlined className="status-icon"/>
											<h1 className="status-title">{constants.CaseStudyPopupHeader}</h1>
											<div className="status-content">
												{constants.CaseStudyPopupText}
												<p> Click <Button onClick={()=>{handleCaseStudyOk();}} className="btnDb">OK</Button> to continue.</p>
											</div>
											{/* Case study popup content ends here */}
										</div>:""}
			</Modal>
		</div>
	);
};
export const learnFooter=()=>{
	const history = useHistory();
	return(
		<div className="text-center p-3 purple-bg-dark">
			<Space>
				<h2 className="mb-0 text-light">Learn with innovative tools like flashcards</h2>
				<Button shape="round" className="gold-btn" onClick={()=>{history.push("/flashcard-list");}}>
                        Learn
				</Button>
			</Space>
		</div>
	);
};