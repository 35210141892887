import React,{ useEffect,useState } from 'react';
import { Breadcrumb, Row, Col, Card, Table,BackTop, DatePicker, Form, Select, Space, Radio, } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
import { fetchAdminApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import moment from 'moment';
import { Chart } from "react-google-charts";
import { registrationFilter } from '../../../globalFunctions/GlobalFunctions';
const { Option } = Select;
const TotalTutorialTaken =(props)=> {
	const history = useHistory();
	const [allTestData, setAllTutorialtData] = useState({});
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [tutorialType,setTutorialType]=useState(4);
	const [categoryGraphData, setCategoryGraphData] = useState([]);
	const [topicGraphData, setTopicGraphData] = useState([]);
	const [customGraphData, setCustomGraphData] = useState([]);
	const [markForLaterGraphData, setMarkForLaterGraphData] = useState([]);
	const [incorrectAnswerGraphData, setincorrectAnswerGraphData] = useState([]);
	const [duration,setDuration]=useState("1");
	const [AllGraphData, setAllGraphData] = useState([]);
	const [form] = Form.useForm();
	useEffect(() => {
		if(props&&props.location&&props.location.state&&props.location.state.type&&props.location.state.type==="dashboard"){
			setDateFilters(constants.startDateOfTheYear,constants.endDateOfTheYear);
			fetchTableData(constants.startDateOfTheYear,constants.endDateOfTheYear);
		}else{
			fetchTableData();
		}
	}, []);
	const setDateFilters=(startDate,endDate)=>{
		setStartDate(moment(startDate).format("MM/DD/YYYY"));
		setEndDate(moment(endDate).format("MM/DD/YYYY"));
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
		setDuration("0");
	};
	// const noDataAvailableText=()=>{
	// 	return(
	// 		<>							
	// 			<div className="nograph-div">
	// 				<span>No Data Available.</span>
	// 			</div>
	// 		</>
	// 	);
	// };
	const fetchTableData = (startDateValue,endDateValue) =>{
		var startDate=null;
		var endDate=null;
		if(startDateValue&&endDateValue){
			startDate=moment(startDateValue).format("YYYY-MM-DD");
			endDate=moment(endDateValue).format("YYYY-MM-DD");
		}else{
			var date = new Date(), y = date.getFullYear(), m = date.getMonth();
			var firstDay = new Date(y, m, 1);
			var lastDay = new Date(y, m + 1, 0);
			startDate=moment(firstDay).format("YYYY-MM-DD");
			endDate=moment(lastDay).format("YYYY-MM-DD");
			setStartDate(moment(startDate).format("MM/DD/YYYY"));
			setEndDate(moment(endDate).format("MM/DD/YYYY"));
			form.setFieldsValue({
				from:moment(startDate),
				to:moment(endDate)
			});
		}
		fetchAdminApi(`/total-tutorials-taken?start_date=${startDate}&end_date=${endDate}`, "get").then(
			(res) => {
				if (res && res.data) {
					setAllTutorialtData(res.data);
					// setCategoryData(res.data?.Category);
					// setTopicData(res.data?.Topic);
					if(res.data?.all?.length>0){
						let allChartData = [
							["Month/Year", "All"],
						];
						for (let index = 0; index < res.data.all.length; index++) {
							let all=[];
							all.push(res.data.all[index].month_in_3_words.toUpperCase(), parseInt(res.data.all[index]?.total));
							allChartData.push(all);
						}
						setAllGraphData(allChartData);
					}
					if(res.data?.Category?.length>0){
						let categoryChartData = [
							["Month/Year", "Category"],
						];
						for (let index = 0; index < res.data.Category.length; index++) {
							let category=[];
							category.push(res.data.Category[index]?.month_in_3_words.toUpperCase(), parseInt(res.data.Category[index]?.total));
							categoryChartData.push(category);
						}
						setCategoryGraphData(categoryChartData);
					}
					if(res.data?.Topic?.length>0){
						let topicChartData = [
							["Month/Year", "Topic"],
						];
						for (let index = 0; index < res.data.Topic.length; index++) {
							let topic=[];
							topic.push(res.data.Topic[index]?.month_in_3_words.toUpperCase(), parseInt(res.data.Topic[index].total));
							topicChartData.push(topic);
						}
						setTopicGraphData(topicChartData);
					}
					if(res.data?.Custom?.length>0){
						let customChartData = [
							["Month/Year", "Custom"],
						];
						for (let index = 0; index < res.data.Custom.length; index++) {
							let custom=[];
							custom.push(res.data.Custom[index]?.month_in_3_words.toUpperCase(), parseInt(res.data.Custom[index].total));
							customChartData.push(custom);
						}
						setCustomGraphData(customChartData);
					}
					if(res.data?.Incorrect_answers?.length>0){
						let incorrectChartData = [
							["Month/Year", "Incorrect answers"],
						];
						for (let index = 0; index < res.data.Incorrect_answers.length; index++) {
							let incorrect_answers=[];
							incorrect_answers.push(res.data.Incorrect_answers[index]?.month_in_3_words.toUpperCase(), parseInt(res.data.Incorrect_answers[index].total));
							incorrectChartData.push(incorrect_answers);
						}
						setincorrectAnswerGraphData(incorrectChartData);
					}
					if(res.data?.Mark_for_later?.length>0){
						let markforLaterChartData = [
							["Month/Year", "Mark for later"],
						];
						for (let index = 0; index < res.data.Mark_for_later.length; index++) {
							let mark_for_later=[];
							mark_for_later.push(res.data.Mark_for_later[index]?.month_in_3_words.toUpperCase(), parseInt(res.data.Mark_for_later[index].total));
							markforLaterChartData.push(mark_for_later);
						}
						setMarkForLaterGraphData(markforLaterChartData);
					}
				}
			}
		);
	};
	const column = [
		{
			title: "Month/Year",
			key: "MY",
			dataIndex: "month_in_words",
			sorter: (a, b) => a.month_in_words.localeCompare(b.month_in_words),
			render: (_text, record) => (
				<Space size="middle">
					<a onClick={() => { tutorialsTakenRedirect(record); }} style={{ color: "black" }}>{record.month_in_words} <span>{record.year}</span></a>
				</Space>
			),
		},
		{
			title:  tutorialType&&tutorialType===1?"Category":tutorialType&&tutorialType===2?
				"Topic":tutorialType&&tutorialType===3?"Custom":tutorialType&&tutorialType===6?"Incorrect Answers":tutorialType&&tutorialType===5?"Mark For Later":
					tutorialType&&tutorialType===4?"All":"",
			key: "total",
			dataIndex: "total",
			sorter: (a, b) => a.tests - b.tests,
			render: (_text, record) => (
				<span><a onClick={() => { tutorialsTakenRedirect(record); }} style={{ color: "black" }}><span style = {{ width : "40px" }}>{record.total}</span></a></span>
			),
			width :200,
			align:"center",
		}
	];
	const tutorialsTakenRedirect = (record) => {
		const month_in_words = record.month_in_words;
		const month = Number(record?.month) - 1; // June (0-indexed, so 5 represents June)
		const year = Number(record?.year);
		const rec_startDate = moment({
			year,
			month
		}).startOf('month');
		const rec_endDate = moment({
			year,
			month
		}).endOf('month');
		const data = {
			sd:rec_startDate.format('YYYY-MM-DD'),
			ed:rec_endDate.format('YYYY-MM-DD'),
			month:month_in_words,
			selectedYear:"",
			year:record.year,
			duration:duration,
			type:"tutorialTaken",
			tutorial_type:tutorialType
		};
		history.push({
			pathname: "/tutorial-scores",
			state: data,
		});
	};
	const handleStartDate = (_date, dateString) => {
		setStartDate(dateString);
		fetchTableData(dateString,endDate);
	};
	const handleEndDate = (_date, dateString) => {
		setEndDate(dateString);
		fetchTableData(startDate,dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const onChangeDateFilter = (e) => {
		setDuration(e);
		registrationFilter(e,setStartDateEndDate);
	};
	const onChangeTestType = (e) => {
		setTutorialType(e.target.value);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
		fetchTableData(startDate,endDate);
	};
	const graphOption = { chart:{ title: `Tutorials Taken` } };
	const totalColumn = (type_total,height) =>{
		return (<Table.Summary.Row style = {{ 
			fontWeight:500 ,
			height:height 
		}}>
			<Table.Summary.Cell  index={0}><span>Total</span></Table.Summary.Cell>
			<Table.Summary.Cell index={1} align = "center" ><span >{type_total}</span></Table.Summary.Cell>
		</Table.Summary.Row>);
	};
	const noDataAvailableText=()=>{
		return(
			<>							
				<div className="nograph-div">
					<span>No Data Available.</span>
				</div>
			</>
		);
	};
	const chartTag=(options,data)=>{
		return(
			<Chart
				chartType="Bar"
				width="100%"
				height="400px"
				data={data}
				options={options}
			/>);
	};
	return (
		<>
			<div className="div">
				<BackTop/>
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item>{constants.TotalTutorialTaken}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" 
								title={<Row>
									<Col xs={24} xl={24} className="text-uppercase flex-vertical-middle">{constants.TotalTutorialTaken}</Col>
									<Col xs={24} xl={24}>
										<Form layout="horizontal" form = {form}>
											<Space>
												<Form.Item className="label-default mb-0">
													<Radio.Group value={tutorialType} onChange={onChangeTestType} placeholder="Select test type" >
														<Radio value={4}>All</Radio>
														<Radio value={1}>Category</Radio>
														<Radio value={2}>Topic</Radio>
														<Radio value={3}>Custom</Radio>
														<Radio value={6}>Incorrect Answers</Radio>
														<Radio value={5}>Mark for Later</Radio>
													</Radio.Group>
												</Form.Item>
												<Form.Item className="label-default mb-0" label="From:" name = "from">
													<DatePicker defaultValue={startDate} onChange={ handleStartDate} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
												</Form.Item>
												<Form.Item className="label-default mb-0" label="To:" name = "to">
													<DatePicker defaultValue={endDate} onChange={handleEndDate} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
												</Form.Item>
												<Form.Item className="label-default mb-0">
													<Select value={duration} style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
														<Option value="0">This Year</Option>
														<Option value="7">Last Year</Option>
														<Option value="1">This Month</Option>
														<Option value="2">Last Month</Option>
														<Option value="3">Last 3 Months</Option>
														<Option value="4">This Month (Last Year)</Option>
														<Option value="5">Last 3 Months (Last Year)</Option>
														<Option value="6">Previous Years</Option>
													</Select>
												</Form.Item>
											</Space>
										</Form>
									</Col>
								</Row>}
							>
								<Row>
									<Col xs={24} sm={24} md={8} lg={8}>
										<span style = {{ 
											fontWeight:500,
											fontSize:"medium"
										}}>{startDate&&endDate&&`Search Criteria: From: ${moment(startDate).format("MM/DD/YYYY")} To: ${moment(endDate).format("MM/DD/YYYY")}`}</span>
										<Table className="gap-table"
											bordered
											columns={
												column
											}
											dataSource={tutorialType&&tutorialType===4?allTestData&&allTestData.all&&allTestData.all.length>0?allTestData.all:[]:
												tutorialType&&tutorialType===1?allTestData&&allTestData.Category&&allTestData.Category.length>0?allTestData.Category:[]:
													tutorialType&&tutorialType===2?allTestData&&allTestData.Topic&&allTestData.Topic.length>0?allTestData.Topic:[]:
														tutorialType&&tutorialType===3?allTestData&&allTestData.Custom&&allTestData.Custom.length>0?allTestData.Custom:[]:
															tutorialType&&tutorialType===6?allTestData&&allTestData.Incorrect_answers&&allTestData.Incorrect_answers.length>0?allTestData.Incorrect_answers:[]:
																tutorialType&&tutorialType===5?allTestData&&allTestData.Mark_for_later&&allTestData.Mark_for_later.length>0?allTestData.Mark_for_later:[]:[]}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													{tutorialType&&tutorialType===4?
														totalColumn(allTestData&&allTestData.all_total?allTestData.all_total:0):
														tutorialType&&tutorialType===1?
															totalColumn(allTestData&&allTestData.category_total?allTestData.category_total:0):
															tutorialType&&tutorialType===2?totalColumn(allTestData&&allTestData.topic_total?allTestData.topic_total:0):
																tutorialType&&tutorialType===3?totalColumn(allTestData&&allTestData.custom_total?allTestData.custom_total:0):
																	tutorialType&&tutorialType===6?totalColumn(allTestData&&allTestData.incorrect_ans_total?allTestData.incorrect_ans_total:0):
																		tutorialType&&tutorialType===5?totalColumn(allTestData&&allTestData.mark_for_later_total?allTestData.mark_for_later_total:0):
																			""}
												</Table.Summary>
											)}
										/>
									</Col>
									<Col xs={24} sm={24} md={16} lg={16}>
										{tutorialType&&tutorialType===1?
											categoryGraphData&&categoryGraphData.length>0?
												chartTag(graphOption,categoryGraphData)
												:noDataAvailableText()
											:
											tutorialType&&tutorialType===2?
												topicGraphData&&topicGraphData.length>0?
													chartTag(graphOption,topicGraphData)
													:noDataAvailableText()
												:tutorialType&&tutorialType===3?
													customGraphData&&customGraphData.length>0?
														chartTag(graphOption,customGraphData)
														:noDataAvailableText():
													tutorialType&&tutorialType===6?
														incorrectAnswerGraphData&&incorrectAnswerGraphData.length>0?
															chartTag(graphOption,incorrectAnswerGraphData)
															:noDataAvailableText():
														tutorialType&&tutorialType===5?
															markForLaterGraphData&&markForLaterGraphData.length>0?
																chartTag(graphOption,markForLaterGraphData)
																:noDataAvailableText()
															:
															tutorialType&&tutorialType===4?
																AllGraphData&&AllGraphData.length>0?
																	chartTag(graphOption,AllGraphData)
																	:noDataAvailableText()
																:
																""}
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default TotalTutorialTaken;
