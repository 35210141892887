import React,{ useState,useEffect } from 'react';
import { Breadcrumb, Row, Col, Card,Form,Button,Space,Input,message,Checkbox } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from "react-router";
import constants from "../../../constants/constants";
import { fetchAdminApi, } from "../../../services/api";
const EditFaq = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [faqType] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state.faqType :sessionStorage.getItem("faq-type"));
	const [editFaqRecords] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state.faqRecord :JSON.parse(sessionStorage.getItem("edit-faq")));
	const [displayChecked, setDisplayChecked] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state.faqRecord && props.history.location.state.faqRecord.is_display :sessionStorage.getItem("edit-faq-is_display"));
	const [newPageChecked, setNewPageChecked] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state.faqRecord && props.history.location.state.faqRecord.is_new_page :sessionStorage.getItem("edit-faq-is_new_page"));
	const [addNewPage] = useState("f");
	const [questionInfo, setQuestionInfo] = useState({
		question: '',
		answer: '',
	});
	const answerModules = {
		toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline','strike', 'blockquote'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, ],
			['link','image' ],
		],
	};
	const answerFormats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 
		'link', 'image'
	];
	const questionChange = (value) => {
		setQuestionInfo({
			...questionInfo,
			question:value
		});
	};
	const answerChange = (value) => {
		setQuestionInfo({ 
			...questionInfo,
			answer:value
		});
	} ;
		//Validation Checks 
	const handleQuestionValidation=(_rule,value,callback)=>{
		try {
			if(value){
				if(value.trim()===""||value.trim()==="<p><br></p>"){
					throw new Error(constants.QuestionRequired);
				}
			}else{
				throw new Error(constants.QuestionRequired);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleAnswerValidation=(_rule,value,callback)=>{
		try {
			if(value){
				if(value.trim()===""||value.trim()==="<p><br></p>"){
					throw new Error(constants.AnswerRequired);
				}
			}else{
				throw new Error(constants.AnswerRequired);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//calling edit function 
	const editFaq=(payload)=>{
		fetchAdminApi(`/faq-edit/${editFaqRecords.id}`, "put", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.message);
						history.push({ pathname: "/admin-faq" });
					} else {
						message.error(res.message);
					}
				} else {
					message.error(constants.saveFailedEditFaqError);
				}
			}
		);
	};
	// calling add function
	const addFaq = (payload) =>{
		fetchAdminApi(`/insert-faq`, "post", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.message);
						history.push({ pathname: "/admin-faq" });
					} else {
						message.error(res.message);
					}
				} else {
					message.error(constants.saveFailedFaqError);
				}
			}
		);
	};
	//on edit faq submit
	const onFinishEditFaq=(values)=>{
		let payload=null;
		payload={
			question:String( values.question),
			answer: String (values.answer),
			is_new_page:newPageChecked ? newPageChecked:addNewPage,
			is_display:displayChecked,
			linked_url:values.linked_url?values.linked_url:null,
			// display_order:values.display_order?parseInt(values.display_order):null,
		};
		if(faqType ==0){
			editFaq(payload);
		}else{
			addFaq(payload);
		}
	};
	useEffect(() => {
		if (props && props.history && props.history.location && props.history.location.state) {
			sessionStorage.setItem("edit-faq", JSON.stringify(props.history.location.state.faqRecord));
			sessionStorage.setItem("faq-type", JSON.stringify(props.history.location.state.faqType));
			sessionStorage.setItem("edit-faq-is_new_page", JSON.stringify(props.history.location.state.faqType && props.history.location.state.faqType.is_new_page));
			sessionStorage.setItem("edit-faq-is_display", JSON.stringify(props.history.location.state.faqType && props.history.location.state.faqType.is_display));
		}
		if(faqType==0){
			setEditFormFields();
		}else{
			sessionStorage.setItem("edit-faq", null);
			form.setFieldsValue({ linked_url: constants.SiteUrl });
		}
	}, []);
	const setEditFormFields = () =>{
		form.setFieldsValue({
			question:editFaqRecords && editFaqRecords.question?editFaqRecords.question:"",
			answer:editFaqRecords && editFaqRecords.answer?editFaqRecords.answer:"",
			display_order: editFaqRecords&&editFaqRecords.display_order?editFaqRecords.display_order:"",
			display:editFaqRecords&&editFaqRecords.is_display?editFaqRecords.is_display:"",
			// image: editFaqRecords&&editFaqRecords.image?editFaqRecords.image:"",
			is_new_page: editFaqRecords&&editFaqRecords.is_new_page?editFaqRecords.is_new_page:"",
			linked_url: editFaqRecords&&editFaqRecords.linked_url?editFaqRecords.linked_url:"",
		});
	};
	const onCheckedDisplay = (e) =>{
		if(e.target.checked === true){
			setDisplayChecked("t");
		}else{
			setDisplayChecked("f");
		}
	};
	const onCheckedNewPage = (e) =>{
		if(e.target.checked === true){
			setNewPageChecked("t");
			form.setFieldsValue({ linked_url: constants.SiteUrl });
		}else{
			setNewPageChecked("f");
		}
	};
	const addEditTitle = () =>{
		if(faqType === 0){
			return "Edit FAQ";
		}else{
			return "Add FAQ";
		}
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item><a onClick={() => {
							history.push({ pathname: "/admin-faq" });
						}}>FAQs</a></Breadcrumb.Item>
						<Breadcrumb.Item><a >{addEditTitle()}</a></Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">{addEditTitle()}</span>}>								
								<Form form={form} layout="vertical" onFinish={onFinishEditFaq}>
									<Form.Item
										label="Question"
										name="question"
										rules={[
											{
												required: true,
												message: "",
											},
											{ validator: handleQuestionValidation },
										]}
										initialValue=''
									>
										<ReactQuill theme="snow" value={questionInfo.question} onChange={questionChange} modules={constants.ReactQuillModules} formats={constants.ReactQuillFormats}/>
									</Form.Item>
									<Form.Item
										// style={{ display:vissibleAnswerEditor }}
										label="Answer"
										name="answer"
										rules={[
											{
												required: true,
												message: "",
											},
											{ validator: handleAnswerValidation },
										]}
										initialValue=''
									>
										<ReactQuill  readOnly={newPageChecked ==="t"?true:false} className = {newPageChecked ==="t"?"react-qill-editor disable_faq_ans":"react-qill-editor"} theme="snow" value={questionInfo.answer} onChange={answerChange} modules={answerModules} formats={answerFormats}/>
									</Form.Item>
									{/* <Form.Item
										label="Display Order"
										name="display_order"
									>
										<Input ></Input>
									</Form.Item> */}
									<Form.Item
										name="display"
									>
										<Checkbox defaultChecked = {editFaqRecords&&editFaqRecords.is_display ==="t"?true:false} onChange={onCheckedDisplay}>Display</Checkbox>
									</Form.Item>
									{/* <Form.Item
										label="Image URL"
										name="image"
									>
										<Input ></Input>
									</Form.Item> */}
									<Form.Item
										name="is_new_page"
									>
										<Checkbox defaultChecked = {editFaqRecords&&editFaqRecords.is_new_page ==="t"?true:false} onChange={onCheckedNewPage}>New page</Checkbox>
									</Form.Item>
									{newPageChecked ==="t" && (
										<Form.Item
											label="URL"
											name="linked_url"
										>
											<Input></Input>
										</Form.Item>
									)}
									<div className="text-right">
										<Space>
											<Form.Item>
												<Button type="primary" htmlType="submit" className="navy-btn">Save</Button>
											</Form.Item>
											<Form.Item>
												<Button onClick={()=>{history.push({ pathname: "/admin-faq" });}}>Cancel</Button>
											</Form.Item>										
										</Space>
									</div>
								</Form>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default EditFaq;