import { Card,Row,Col, Button,Typography } from "antd";
import "../../styles/test.css";
import constants from "../../constants/constants";
import{ COOKIE,getCookie } from "../../services/cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
const { Text } = Typography;
const MarkForLaterTutorial = () => {
	const history = useHistory();
	const [tutorialType,setTutorialType]=useState("");
	const [markForLaterCount,setMarkForLaterCount]=useState(0);
	// eslint-disable-next-line no-unused-vars
	const [answerIncorrectCount,setAnswerIncorrectCount]=useState(0);
	useEffect(() => {
		getTutorialType();
	}, []);
	const getTutorialType=()=>{
		setTutorialType(sessionStorage.getItem("tutorialType"));
		let markedForLaterCount=Number(sessionStorage.getItem("markedForLaterCount"));
		let answerIncorrectQuestionsCount=Number(sessionStorage.getItem("incorrectAnswersCount"));
		setMarkForLaterCount(markedForLaterCount);
		setAnswerIncorrectCount(answerIncorrectQuestionsCount);
	};
	const redirectToMarkForLater=(type)=>{
		let markForLaterAndAnswerIncorrectDetails={};
		let userId = getCookie(COOKIE.UserId);
		markForLaterAndAnswerIncorrectDetails.stud_id=userId;
		markForLaterAndAnswerIncorrectDetails.type="tutor";
		markForLaterAndAnswerIncorrectDetails.attempt=1;
		markForLaterAndAnswerIncorrectDetails.isTimed=false;
		if(type&&type==="markForLater"){
			markForLaterAndAnswerIncorrectDetails.isMarkedLater= true;
			history.push({
				pathname:"/marked-for-later-tutorial",
				state:{ markForLaterAndAnswerIncorrectDetails:markForLaterAndAnswerIncorrectDetails } 
			});
		}else{
			markForLaterAndAnswerIncorrectDetails.isAnsIncorrect=true;
			history.push({
				pathname:"/incorrect-answers-tutorial",
				state:{ markForLaterAndAnswerIncorrectDetails:markForLaterAndAnswerIncorrectDetails } 
			});
		}
	};
	return (
		<div className="container instructions py-4">
			<Row
				gutter={[
					{
						xs: 0,
						sm: 0,
						md: 8,
						lg: 16 
					},
					{
						xs: 8,
						sm: 8,
						md: 8,
						lg: 16 
					},
				]}
			>
				<Col xs={24} lg={24}>
					<div className="text-center position-relative">
						<h3 className="mainHeading">{tutorialType&&tutorialType==="MarkForLater"?constants.MarkForLaterTutorial:constants.AnswerIncorrectTutorial}</h3>
						<div style= {{ textAlign: "center" }} className="subHeading">{tutorialType&&tutorialType==="MarkForLater"? constants.MLInstructions:constants.InAnsInstruction  } </div>
					</div>
				</Col>
				<Col xs={24}
					lg={{
						span: 16, 
						offset: 4,
					}}>
					<Card size="small" className="mt-3" bordered={false}>
						<Text className="category-list-container">
							{tutorialType&&tutorialType==="MarkForLater"?
								(<span>{constants.MarkedForLaterTutorialText}</span>):
								(<span>{constants.AnswerIncorrectTutorialText}</span>)
							}
							{tutorialType&&tutorialType==="MarkForLater"?
								markForLaterCount<constants.minimumMarkedCount?null:
									(<span>{constants.MarkForLaterBottomText}</span>)
								:
								answerIncorrectCount<constants.minimumMarkedCount?null:
									(<span>{constants.AnswerIncorrectBottomText}</span>)}
							{
								tutorialType&&tutorialType==="MarkForLater"?
									markForLaterCount&&markForLaterCount>=constants.minimumMarkedCount?(
										<p>{constants.MarkedForLaterTutorial15QuestionsAvailableText}
											<Button
												type="link"
												onClick={()=>{redirectToMarkForLater("markForLater");}}
											>
												<span className="category-list-container">{constants.ClickToStartBTN}.</span>
											</Button>	
										</p>
									)
										:markForLaterCount&&markForLaterCount>0&&markForLaterCount<constants.minimumMarkedCount?
											(<span>{constants.MarkedForLaterLessThen15QuestionsText}</span>)
											:
											(<span >{constants.MarkedForLater0QuestionsText}</span>)
									:
									answerIncorrectCount&&answerIncorrectCount>=constants.minimumMarkedCount?
										(<p>{constants.AnswerIncorrectTutorial15QuestionsAvailableText}
											<Button
												type="link"
												onClick={()=>{redirectToMarkForLater("ansIncorrect");}}
											>
												<span className="category-list-container">{constants.ClickToStartBTN}.</span>
											</Button>	
										</p>)
										:answerIncorrectCount&&answerIncorrectCount>0&&answerIncorrectCount<constants.minimumMarkedCount?
											(<span>{constants.AnswerIncorrectTutorialLessThen15QuestionText}</span>)
											:
											(<span>{constants.AnswerIncorrectTutorial0QuestionText}</span>)
							}
						</Text>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default MarkForLaterTutorial;
