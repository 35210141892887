import React, { useState, useEffect } from 'react';
import { Breadcrumb, Row, Col, Card, Form, DatePicker, Table,Radio,Space } from 'antd';
import { Chart } from "react-google-charts";
import { useHistory } from "react-router";
import moment from "moment";
const TestScores = () => {
	const history = useHistory();
	const [selectedYear, setSelectedYear] = useState("");
	const [graphData, setGraphData] = useState([]);
	const [radioButtonValue, setRadioButtonValue] = useState(1);
	console.log("selectedYear", selectedYear);
	const onChange = (date, dateString) => {
		setSelectedYear(dateString);
	};
	useEffect(() => {
		let gfHeader = [];
		gfHeader.push(["Test Scores", "Pass", "Fail"]);
		data.map(obj => gfHeader.push([obj.test, parseInt(obj.pass_count), parseInt(obj.fail_count)]));
		console.log("gfHeader", gfHeader);
		setGraphData(gfHeader);
	}, []);
	// disabled the future year
	const disabledyear = (current) => {
		return current && current > moment().endOf('year');
	};
	const column = [
		{
			title: "Type of Test",
			key: "type_of_test",
			dataIndex: "test",
		},
		{
			title: "Pass",
			key: "pass",
			dataIndex: "pass_count",
			align: "center",
			sorter: (a, b) => a.pass_count - b.pass_count,
		},
		{
			title: "Fail",
			key: "registrations",
			dataIndex: "fail_count",
			align: "center",
			sorter: (a, b) => a.fail_count - b.fail_count,
		},
		{
			title: "Percent Pass",
			key: "registrations",
			dataIndex: "percent_count",
			align: "center"
		}
	];
	const data = [];
	for (let i = 0; i < 8; i++) {
		data.push({
			key: i,
			test: `Test ${i + 1}`,
			pass_count: i + 300,
			fail_count: i + 300,
			percent_count: i + 300,
		});
	}
	// let gfHeader = [];
	// gfHeader.push(["Test Scores", "Pass", "Fail"]);
	// data.map(obj => gfHeader.push([obj.test, parseInt(obj.pass_count), parseInt(obj.fail_count)]));
	// setGraphData(gfHeader);
	const graphOption = {
		title: `Score Comparison `,
		titleTextStyle: {
			bold: true,
			fontSize: 13,
		},
		hAxis: {
			title: "Tests",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		isStacked: true,
		vAxis: {
			title: "\n\nNo,of Students",
			formate: 'none',
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		legend: {
			position: 'top',
			maxLines: 3,
			alignment: 'start'
		},
		bars: "vertical",
		colors: ["#FF550A", "#0066BC"]
	};
	const onChangeRadioButton = e => {
		console.log('radio checked', e.target.value);
		setRadioButtonValue(e.target.value);
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item>Tests</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" title={<span className="text-uppercase">score comparison</span>} extra={
								<Form layout="horizontal">
									<Radio.Group onChange={onChangeRadioButton} value={radioButtonValue}>
										<Space direction = "horizontal" >
											<Form.Item className="label-default mb-0">
												<Radio value={1}>Full length</Radio>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Radio value={2}>Category</Radio>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Radio value={3}>Topic</Radio>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<DatePicker picker="year" onChange={onChange} disabledDate={disabledyear} placeholder="Select year" inputReadOnly={true}/>
											</Form.Item>
										</Space>
									</Radio.Group>
								</Form>
							}>
								<Row>
									<Col xs={12} sm={12} md={10} lg={10}>
										<Table className="gap-table"
											// onChange ={handleChange}
											columns={column}
											dataSource={data}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
										// summary={() => (
										// 	<Table.Summary fixed>
										// 		<Table.Summary.Row>
										// 			<Table.Summary.Cell index={0} className="last-element"><span>Total</span></Table.Summary.Cell>
										// 			<Table.Summary.Cell index={1} className="last-element"><span style={{ width: "65px" }}>{totalRegistrationCount}</span></Table.Summary.Cell>
										// 		</Table.Summary.Row>
										// 	</Table.Summary>
										// )}
										/>
									</Col>
									<Col xs={12} sm={12} md={14} lg={14}>
										<Chart
											width="100%"
											height="400px"
											chartType="ColumnChart"
											data={graphData}
											options={graphOption}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default TestScores;