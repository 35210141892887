import React, { useEffect } from "react";
import {
	Input,
	Form,
	Row,
	Col,
	Button,
	message,
	notification,
} from "antd";
import "../../../styles/login.css";
import { fetchApi } from "../../../services/api";
import constants from "../../../constants/constants";
import Footer from "./../../../footer";
import Headers from "../../../header";
import Book from './Book';
import { openNotificationCred } from "../../../globalFunctions/GlobalFunctions";
const ResendEmail = () => {
	const [form] = Form.useForm();
	useEffect(() => {
		notification.destroy();
		openNotificationCred();
	}, []);
	//Executes on click of login button
	const onFinish = (values) => {
		console.log("values",values);
		if(values.userName||values.alternateEmail||values.email){
			console.log("send mail");
			let payload=null;
			payload = {
				login_username: values.userName?values.userName:"",
				stud_email: values.email?values.email:"",
				alternate_stud_email:values.alternateEmail?values.alternateEmail:""
			};
			fetchApi("/resend_email", "post", payload)
				.then((res) => {
					if (res && res.code && res.code === 200) {
						message.success(res.message);
					}else{
						message.error(res.message);
					}
				});
		}else{
			message.eror("Fill atleast one field");
		}
	};
	return (
		<div>
			<Headers/>
			<div className="login-content flex-vertical-middle">
				<Row gutter={[{
					xs: 8,
					sm: 24,
					md: 48,
					lg: 8,
					xl: 8
				}, 8]} className="login-px">
					<Book/>
					<Col lg={24} xl={14} className="w-100 flex-middle">
						<div className="login-col">
							<div className="ml-135">
								<h1 className="mb-0">{constants.ResendEmail}</h1>
								<h3 className="mb-4">{constants.ResendEmailSubTitle}</h3>
								<Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off">
									<div className="login-form">
										<Form.Item
											label={constants.Username}
											name="userName"
										>
											<Input autoFocus className="login-input" placeholder={constants.Username}/>
										</Form.Item>	
										<Form.Item
											label={constants.Email}
											name="email"
											rules={[
												{
													type: 'email',
													message: constants.EmailNotValid,
												},                      
											]}
										>
											<Input className="login-input" placeholder={constants.Email}/>
										</Form.Item>
										<Form.Item
											label={constants.AlternateEmail}
											name="alternateEmail"
											rules={[
												{
													type: 'email',
													message: constants.EmailNotValid,
												},                      
											]}
										>
											<Input className="login-input" placeholder={constants.AlternateEmail}/>
										</Form.Item>
									</div>
									<Form.Item>
										<Button
											shape="round"
											className="gold-btn login-btn text-capitalize"
											// type="button"
											htmlType="submit"
											// onClick={()=>{onFinish();}}
											// href="/"
										>
											{constants.ResendEmail}
										</Button>
									</Form.Item>
								</Form>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<Footer/>
		</div>
	);
};
export default ResendEmail;
