/*
File Name: FlashcardChapterListing
Author: Dhiraj Rajput
Modified On: 13/04/2022
*/
import React, { useEffect, useState } from "react";
import { Form, Input, Breadcrumb, Row, Col, Card, Button, Space, Tooltip, Table,Modal,Tag, message } from "antd";
import { EditOutlined, PlayCircleOutlined } from '@ant-design/icons';
import "../../../styles/login.css";
import "../../../styles/admin.css";
import "../../../styles/flashcard.css";
import { fetchAdminApi, fetchAdminDownloadApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import moment from "moment";
import { myTrim } from "../../../globalFunctions/GlobalFunctions";
const { Meta } = Card;
const FlashcardListing = (props) => {
	const [isFlipped, setisFlipped] = useState(false);
	const handleClick=()=>{
		setisFlipped(!isFlipped);
	};
	const history = useHistory();
	const [flashcardPayload] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state :JSON.parse(sessionStorage.getItem("flashcardPayloads")));
	const [tableData, setTableData] = useState([]);
	const [filterTable, setFilterTable] = useState([]);
	const [dataLength, setDataLength] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [viewModalData, setviewModalData] = useState({});
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	useEffect(() => {
		if (props && props.history && props.history.location && props.history.location.state) {
			sessionStorage.setItem("flashcardPayloads", JSON.stringify(props.history.location.state));
		}
		fetchFlashcardListing();
	}, []);
	// Activity Log 
	const fetchFlashcardListing = () =>{
		let fetch = ``;
		if(flashcardPayload && flashcardPayload.type === 0){
			fetch = `/list-flashcards/0/${flashcardPayload&&flashcardPayload.chapterId}`;
		}else if(flashcardPayload && flashcardPayload.type === 1){
			fetch = `/list-flashcards/1/${flashcardPayload&&flashcardPayload.topicId}`;
		}
		fetchAdminApi(fetch, "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					setTableData(res.data);
					setDataLength(res.data.length);
					setFilterTable(res.data);
				}
			}else{
				setTableData([]);
				setDataLength("");
			}
		});
	};
	const searchFilterStatus = (status,search) =>{
		let statusMessage = "";
		if(status == "1"){
			statusMessage = "Verified";
		}else if (status == "0"){
			statusMessage = "To be verified";
		}else{
			statusMessage = "Rejected";
		}
		return statusMessage.toLowerCase().includes(search.toLowerCase());
	};
	const onChange = (event) =>{
		let search = event.target.value;
		if(search !== null|| search !== ""){
			let tempData = filterTable.length > 0? filterTable.filter((month) =>
				month&&month.question&&month.question.toLowerCase().includes(search.toLowerCase())||
				month&&month.Topic&&month.Topic.toLowerCase().includes(search.toLowerCase())||
				month&&month.Chapter&&month.Chapter.toLowerCase().includes(search.toLowerCase())||
				month&&month.Id&&month.Id.toLowerCase().includes(search.toLowerCase())||
				month&&month.Category&&month.Category.toLowerCase().includes(search.toLowerCase())||
				searchFilterStatus(month&&month.Status,search)
			):[];
			setTableData(tempData);
			setDataLength(tempData.length);
		}
	};
	const onEditAndView = (record) =>{
		return (
			<Space>
				<Tooltip title={'Edit'}><Button type="text" onClick = {() =>{history.push({ 
					pathname:"/edit-flaschcard" ,
					state:{
						record:record,
						flashcardType:flashcardPayload && flashcardPayload.type,
						title:flashcardPayload && flashcardPayload.title,
						flashcardTitle: flashcardPayload && flashcardPayload.type == 1 ? "TOPICS":" TEXTBOOK",
					}
				});} }  icon={<EditOutlined/>}></Button></Tooltip>
				<Tooltip title={'View'}><Button onClick = {()=>{setIsModalVisible(true); setviewModalData(record);}} type="text" icon={<PlayCircleOutlined/>}></Button></Tooltip>
			</Space>
		);
	};
	const commonColumns = [
		{
			title: 'Actions',
			align: 'center',
			render: (record) => {
				return (
					onEditAndView(record)
				);
			}
		},
		{
			title: 'Questions',
			key: 'questions',
			dataIndex: 'question',
			render: (record) => {
				return (
					<div dangerouslySetInnerHTML={{ __html: record?.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}/>
				);
			}
		},
		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				if(record.Status =="1"){
					return <Tag color={'blue'} >Verified</Tag>;
				}else if(record.Status =="0"){
					return <Tag color={'green'} >To be verified</Tag>;
				}else if(record.Status =="2"){
					return <Tag color={'red'} >Rejected</Tag>;
				}
			},
			align: 'center',
		},
		{
			title: '#',
			key: 'id',
			dataIndex: 'Id',
			align: 'center',
			className: 'td-right',
			width: 50
		},
		{
			title: 'Category',
			key: 'category',
			dataIndex: 'Category',
			align: 'center',
		}
	];
	const flashcardChapterColumn = [
		...commonColumns,
		{
			title: 'Topics',
			key: 'topic',
			align: 'center',
			render: (record) => {
				return <Tooltip title = {record?.Topic}>{record?.topic_abbreviation}</Tooltip>;
			},
		}
	];
	const flashcardTopicColumn = [
		...commonColumns,
		{
			title: 'Chapters',
			key: 'Chapter',
			dataIndex: 'Chapter',
			align: 'center',
		}
	];
	//Function to export to word
	const onWordDownload = () =>{
		let fileName="";
		let url=``;
		if(flashcardPayload && flashcardPayload.type === 0){
			url = `/download-flashcard-questions/1/${flashcardPayload&&flashcardPayload.chapterId}`;
		}else if(flashcardPayload && flashcardPayload.type === 1){
			url = `/download-flashcard-questions/2/${flashcardPayload&&flashcardPayload.topicId}`;
		}
		fetchAdminDownloadApi(url, "get", null).then(
			(res) => {
				if(res){
					let url,a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application/vnd.ms-word" }),
						{ type: "data:attachment/doc" }
					);
					let todayDate= moment().format(constants.dateFormat);
					let filename="";
					fileName=fileName+ flashcardPayload && flashcardPayload.type ==1?`Topic-${flashcardPayload.title}`:flashcardPayload.title ;
					if(constants.EnviromentName!=="qa"&&constants.EnviromentName!=="staging"&&constants.EnviromentName!=="dev"){
						filename="Flashcards-"+fileName+"("+todayDate+").doc";
					}else{
						filename=constants.EnviromentName+"-Flashcards-"+fileName+"("+todayDate+").doc";
					}
					a = document.createElement("a");
					a.download = myTrim(filename);
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
				}else{
					message.error("Failed to download");
				}
			}
		);
	};
	return (
		<div className="div">
			<div className="p-3" >
				<Breadcrumb>
					<Breadcrumb.Item ><a onClick={() => {
						history.push({ pathname: "/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/masters-menu" });
					}}>Masters</a></Breadcrumb.Item>
					<Breadcrumb.Item ><a onClick={() => {
						history.push({ 
							pathname: "/flashcard-list-chapter",
							state:{ flashcardsForValue:flashcardPayload&&flashcardPayload.type === 1 ?false:true }
						});
					}}>{flashcardPayload&&flashcardPayload.type === 1 ?"Flashcards - Topics":constants.FlashcardchapterTitle}</a></Breadcrumb.Item>
					<Breadcrumb.Item>{constants.FlashcardListing}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<Row gutter={[24, 24]}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title={<Meta title={<span className="text-uppercase">{flashcardPayload && flashcardPayload.type == 1 ? "FLASHCARDS - TOPICS":constants.FlashcardchapterTitle}</span>} description={<h2 style={{ fontSize: "15px" }}>{flashcardPayload && flashcardPayload.type ==1?`Topic - ${flashcardPayload.title}`:flashcardPayload.title }</h2>}/>} extra={
						<Form layout="horizontal">
							<Space>
								<Form.Item className="label-default mb-0">
									<Button className = "edit-butoon" onClick={()=> history.push({
										pathname : "/add-flashcard",
										state : {
											categoryType:flashcardPayload.type,
											chapterTopic:flashcardPayload.type == 0?flashcardPayload.chapterId:flashcardPayload.topicId,
										}
									})}
									>Add Flashcard</Button>
								</Form.Item>
								<Form.Item className="label-default mb-0">
									<Button className = "edit-butoon" onClick={onWordDownload}>Export to Word</Button>
								</Form.Item>
							</Space>
						</Form>
					}>
						<Space className="justify-between">
							<Form.Item className="label-default mb-0">
								{/* <span className="p-1">{dataLength !== null ? `Displaying ${dataLength} Flashcards.` : ""}</span> */}
								<span className="px-3">{ `${dataLength !== null?dataLength:0} record(s) found.` }</span>
							</Form.Item>
							<Form.Item label="Search" className="label-default mb-0">
								<Input style={{ width: "200px" }} size="small" onChange={onChange}/>
							</Form.Item>
						</Space>
						{flashcardPayload && flashcardPayload.type ===0 ?
							<Table className="admin-table"
								locale={{ emptyText: 'No Records Found' }}
								dataSource={tableData}
								columns={flashcardChapterColumn}
								pagination={{ showSizeChanger:true }}
							/>:
							<Table className="admin-table"
								locale={{ emptyText: 'No Records Found' }}
								dataSource={tableData}
								columns={flashcardTopicColumn}
								pagination={{ showSizeChanger:true }}
							/>
						}
						<Modal className="flashcards modal-close-button" visible={isModalVisible} centered 
							okButtonProps={{ style: { display:"none" } }} cancelButtonProps={{ style: { display:"none" }  }} onCancel={handleCancel}
						>
							{isFlipped===false?
								<Card 
									className ="flashcard-items-center"
									title="Q" 
									onClick={handleClick}
									size="small"
									extra = {
										<span style = {{ fontSize : "25px" }}>#{viewModalData && viewModalData.Id}</span>
									}
								>
									<div
										dangerouslySetInnerHTML={{ __html: viewModalData?.question?.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
									/>
								</Card>:
								<Card
									className ="flashcard-items-center"
									title="A" 
									onClick={handleClick}
									size="small"
								>
									<div
										dangerouslySetInnerHTML={{ __html: viewModalData?.answer?.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
									/>
								</Card>	
							}
						</Modal>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default FlashcardListing;
