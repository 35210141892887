import { Skeleton, Button, Space, Row, Col, /* Divider */ } from 'antd';
import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import constants from '../../constants/constants';
import { fetchApi } from "../../services/api";
import { COOKIE, getCookie } from "../../services/cookie";
import { useHistory } from "react-router";
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
const QODChart = () => {
	const history = useHistory();
	const [qodChart, setQODChart] = useState({});
	const [qodData,setQodData]=useState({});
	useEffect(() => {
		fetchScoreCards();
	}, []);
	const fetchScoreCards = async() => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/compare-score?stud_id=${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				let qodArray = [];
				qodArray.push(["Month", "Correct", "Incorrect",/* "Missed" */]);
				if(res.data.QOD){
					// eslint-disable-next-line no-unused-vars
					Object.entries(res.data.QOD).forEach(([_key, value]) => {
						let eachMonth=[];
						eachMonth.push(value.Month,value.Correct,value.Incorrect/*,value.Missed*/);
						qodArray.push(eachMonth);
					});
					setQODChart(qodArray);
				}
				if(res.data.total_qod){
					setQodData(res.data.total_qod);
				}
				return true;
			}
		});
	};
	let options = { 
		colors: [constants.QODCorrectColor, constants.QODIncorrectColor, /* constants.QODMissedColor */], 
		vAxis: { 
			title: "Questions",
			titleTextStyle: { italic: false },
			viewWindow: { min: 0 }
		},
		seriesType: "bars",
		legend: { 
			position: 'top',
			alignment: 'start' 
		},
	};
	const startQuestionOfDay=()=>{
		let userId = getCookie(COOKIE.UserId);
		let questionOfDayDetails={};
		questionOfDayDetails.userId=userId;
		questionOfDayDetails.type="qod";
		history.push({
			pathname: "/question-of-the-day",
			state: { questionOfDayDetails:questionOfDayDetails, }
		});
	};
	return (
		<div>
			{ qodChart&&qodChart.length>1? 
				<>
					{qodData&&
						<Row className="text-center">
							<Col span={24}>
								<Space size='large'>
									<span><div><Title className='mb-0'>{qodData.Correct}</Title></div><Text>Correct</Text></span>
									<span><div><Title className='mb-0'>{qodData.Incorrect}</Title></div><Text>Incorrect</Text></span>
									<span><div><Title className='mb-0'>{qodData.Percentage}%</Title></div><Text>Correct %</Text></span>
									{/* <span><div><Title className='mb-0'>{qodData.Missed}</Title></div><Text>Missed</Text></span>{"  "} */}
								</Space>
							</Col>
						</Row>
					}
					<Chart
						chartType="ComboChart"
						loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
						width="100%"
						height="400px"
						data={qodChart}
						options={options}
					/>
				</>
				: 
				<>                          
					<div className="nograph-div">
						<span>{constants.QODPopupText}</span>
						<p>{constants.QODPopupText2}</p>
						<span><Button type="primary" className="navy-btn mt-3" onClick={()=>{startQuestionOfDay();}}>{constants.ClickQODAnswer}</Button></span>
					</div>
				</>
			}
		</div>
	);
};
export default QODChart;
