import React, { useState } from "react";
import { Button, Card, Tooltip } from 'antd';
import { PauseOutlined, EyeOutlined, EyeInvisibleOutlined, CheckOutlined } from '@ant-design/icons';
import '../../../styles/pausetest.css';
import constants from "../../../constants/constants";
const PauseTest = () => {
	// // eye icon toggle
	const [viewTimer, setviewTimer] = useState(false);
	const toggleTimer = () => {
		setviewTimer(!viewTimer);
	};
	return (
		<div className="test-status">
			<div className="test-status-content">
				<div className="pb-2">
					<h3 className="main-title mb-0">{constants.CategoryShortFPP} #1 – 25 questions</h3>
					{/* for completed */}
					{/* <h3 className="main-title mb-0">Full Length #5 - 132 questions</h3> */}
					{/* xx */}
					<div className="statustimer-wrapper">
						<Button className="timerIcon" onClick={toggleTimer} icon={viewTimer ? <Tooltip title="Show Timer"><EyeOutlined/></Tooltip> : <Tooltip title="Hide Timer"><EyeInvisibleOutlined/> </Tooltip> }></Button>
						{viewTimer ? "00:02:22" : "" }
					</div> 
				</div>
				<Card className="status-container">
					<PauseOutlined className="status-icon"/> 
					<h1 className="status-title">Pause Test</h1>
					<div className="status-content">
						<p>{constants.PauseTestText}</p>
						<div><p className="my-3 or">OR</p></div>
						<p>{constants.PauseTestText2}</p>
					</div>
				</Card>
				{/* Completed */}
				<Card className="status-container">
					<CheckOutlined className="status-icon"/>
					<h1 className="status-title">You Have Completed The Test</h1>
					<div className="status-content">
						<p>{constants.CompleteTestText}</p>
						<div><p className="my-3 or">OR</p></div>
						<p>{constants.CompleteTestText2}</p>
					</div>
				</Card>
			</div>
		</div>
	);
};
export default PauseTest;
