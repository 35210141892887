import React, { useEffect, useState } from "react";
import {
	Space,
	Card,
	Row,
	Col,
	BackTop,
	Collapse,
} from "antd";
import { fetchApi } from "../../services/api";
import { RightOutlined } from "@ant-design/icons";
import "../../styles/faq.css";
import constants from "../../constants/constants";
const { Panel } = Collapse;
const Faq = () => {
	const [faqList, setFaqList] = useState([]);
	useEffect(() => {
		fetchFaq();
	}, []);
	const fetchFaq = () => {
		fetchApi(`/faq`, "get").then((res) => {
			if (res && res.code === 200) {
				setFaqList( res.data );				
			}else{
				setFaqList([]);
			}
		});
	};
	return (
		<div className="container py-4">
			<BackTop/>
			<Row>
				<Col span={24}>
					<Card title="Frequently Asked Questions" size="default" 
						// extra={<a onClick={()=>{window.open(constants.SiteUrl+constants.studentFaqHelpLink, '_blank');}} className="navy-2">More FAQs</a>}
					>
						<Space direction="vertical" size="large" className="w-100">
							{faqList &&
									faqList.length > 0 &&
									faqList.map((faqListItem) => (
										<>
											{faqListItem.is_new_page==="f"?
												<Collapse accordion className="faq-accordion" key={faqListItem.id} >
													<Panel header={<>
														<div dangerouslySetInnerHTML={{ __html: faqListItem.title }}/>
													</>} key="1">
														<div dangerouslySetInnerHTML={{ __html: faqListItem.answer }}/>
													</Panel>
												</Collapse>
												:
												<>
													<a href={faqListItem.linked_url} target="_blank" rel="noopener noreferrer">
														<Card className="faq-card">
															<div className="justify-between">
																<div dangerouslySetInnerHTML={{ __html: faqListItem.title }}/>
																<RightOutlined className='faq-arrow'/>
															</div>
														</Card>
													</a>
												</>
											}
										</>
									))}
						</Space>
						<br/>
						<br/>
						<div className="text-right">
							<a onClick={()=>{window.open(constants.SiteUrl+constants.studentFaqHelpLink, '_blank');}} className="navy-2">More FAQs</a>
						</div>
					</Card>
				</Col>  
			</Row>
		</div>
	);
};
export default Faq;
