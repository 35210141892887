import React, { useState } from "react";
import { Col, Row, Button, Card, Space, Tooltip, Divider } from 'antd';
import {  EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import '../../styles/test.css';
const SearchResult = () => {
	// eye icon toggle
	const [viewTimer, setviewTimer] = useState(false);
	const toggleTimer = () => { 
		setviewTimer(!viewTimer);
	};
	return (
		<div className="full-length-test-container">
			<Row gutter={[0,16]}>
				<Col sm={12} className="test-question-details">
					<Space size={'large'} className="flex-vertical-middle">
						<span><span className="qust-title">Questions</span> : 1 of 10</span>
						<span>
							<Button className="timerIcon" onClick={toggleTimer} icon={viewTimer ? <Tooltip title="Show Timer"><EyeOutlined/></Tooltip> : <Tooltip title="Hide Timer"><EyeInvisibleOutlined/> </Tooltip>}></Button>
							{viewTimer ? "00:02:22" : "" }
						</span> 
					</Space>
				</Col>
				<Col>
					<Card>
						<div className="test-question search-result">
							<p><b>Q5.</b> When is a speech–language pathologist responsible for counseling a laryngeal cancer patient in terms of their speech and swallowing treatment? </p>
							<Divider/>
							<p><b>Q6.</b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
							<Divider/>
							<p><b>Q7.</b>It is a long established fact that a reader will be distracted by the readable content layout? </p>
						</div>
					</Card>
				</Col>
				<Col>   
					<Space size={'small'} className="w-100 space-quit" >
						<Button className="btnDb">Resume Test</Button>
						<Button className="btnQuit">Quit</Button>
					</Space>
				</Col>
			</Row>
		</div>
	);
};
export default SearchResult;