/*
File Name: MyTests.js
Author: Diksha Lotlikar
Modified On: 23/02/2022
Description: User Test Details/Progress
API's used:
1) scorecards?stud_id=${userId}&month=11
*/
import React, { useEffect } from "react";
import {
// Input,
// Form,
	Row,
	Col,
	Card,
	// Checkbox,
	Button,
	Space,
	Tooltip,
	BackTop,
	Table
// message,
} from "antd";
import { EyeOutlined } from '@ant-design/icons';
import "../../../styles/login.css";
import "../../../styles/admin.css";
// import { fetchApi } from "../../../services/api";
import constants from "../../../constants/constants";
// import { useHistory } from "react-router";
// import { COOKIE, getCookie, createCookie } from "./../../../services/cookie";
// import Footer from "./../../../footer";
// import Headers from "../../../header";
const ActivityLog = () => {
	const pagination = {
		// showSizeChanger: true,
		pageSize:'50',
	};
	useEffect(() => {}, []);
	// Activity Log 
	const activityLogColumn = [
		{
			title: 'Date Time ',
			key: 'date_time ',
			dataIndex: 'date_time',
		},
		{
			title: 'Activity',
			key: 'activity',
			dataIndex: 'activity',
		},
		{
			title: 'Progress',
			key: 'progress',
			dataIndex: 'progress',
			align: 'center',
			className: 'td-right',
			render: () => {
				return (
					<span>
						5/5
					</span>
				);
			}
		},
		{
			title: 'Correct Answers',
			key: 'correct',
			dataIndex: 'correct',
			align: 'center',
			className: 'td-right',
			render: () => {
				return (
					<span>
						3
					</span>
				);
			}
		},
		{
			title: 'Points Earned',
			key: 'points',
			dataIndex: 'points',
			align: 'center',
			className: 'td-right',
			render: () => {
				return (
					<span>
						50
					</span>
				);
			}
		},
		{
			title: 'Actions',
			align: 'center',
			render: () => {
				return (
					<Space>
						<Tooltip title={'View'}><Button type="text" icon={<EyeOutlined/>}></Button></Tooltip>
					</Space>
				);
			}
		},
	];
	const data = [];
	for (let i = 0; i < 46; i++) {
		data.push({
			key: i,
			date_time: `08-25-2021   03:15:00 `,
			activity: `Tutorial – Incorrectly answered ` ,
			progress: ``,
			correct: ``,
			points: ``,
			address: ``,
		});
	}
	return (
		<div className="p-4">
			<BackTop/>
			<Row gutter={[24, 24]} className='my-test mt-4'>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title={<span className="text-uppercase">{constants.ActivityLogTitle}</span>}>
						<Table className="admin-table" 
							locale={{ emptyText: 'No Records Found' }}
							dataSource={data} 
							columns={activityLogColumn}
							pagination={pagination}
							scroll={{ x: 991 }}
						/>
					</Card>
				</Col>
			</Row> 
		</div>
	);
};
export default ActivityLog;
