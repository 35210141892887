import React, { useEffect, useState } from 'react';
import { fetchAdminApi } from "../../../services/api";
import "../../../styles/totalregistration.css";
import { Breadcrumb, Row, Col, Card, Form, DatePicker, Space,Select } from 'antd';
import Icon from '@ant-design/icons';
import { Chart } from "react-google-charts";
import moment from "moment";
import { useHistory } from "react-router";
import CustomIcons from "../../../assets/CustomIcons";
import constants from '../../../constants/constants';
import { registrationFilter } from "../../../globalFunctions/GlobalFunctions";
// import flag from "../../../assets/icons-usa.png";
const UsaIcon = (props) => (<Icon component={CustomIcons.getsvg("UsaIcon")} {...props}/>);
const RegistrationInUsOnly = (props) => {
	const { Option } = Select;
	const history = useHistory();
	const [form] = Form.useForm();
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [tableData, setTableData] = useState([]);
	const [totalRegistrationCount, setTotalRegistrationCount] = useState(null);
	const [graphData, setGraphData] = useState([]);
	const [sMonth,setSmonth] = useState(props && props.location && props.location.state && props.location.state.sd );
	const [eMonth,setEmonth] = useState(props && props.location && props.location.state && props.location.state.ed );
	useEffect(() => {
		fetchRegistrationInUs();
	}, [startDate, endDate,sMonth,eMonth]);
	useEffect(()=>{
		// return () => {
		// 	if (history.action === "POP") {
		// 		history.push("/admin-dashboard");
		// 	}
		// };
	},[history]);
	let currentYear = moment().year();
	let y = startDate && endDate ? startDate && endDate : sMonth && eMonth;
	if(y){
		let selectedDate = `${startDate ? moment(startDate).format(constants.dateFormat): moment(sMonth).format(constants.dateFormat)} to ${endDate ? moment(endDate).format(constants.dateFormat) : moment(eMonth).format(constants.dateFormat)}`;
		currentYear = selectedDate;
	}
	const graphOption = {
		title: `Top 10 States Registrations - ${currentYear }`,
		legend: { position: 'none' },
		colors: ['#0000FF'],
		hAxis: {
			title: "States",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		vAxis: {
			title: "\n\n\nRegistrations",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false,
			},
			minValue: 0,
			format: '#',
		},
	};
	// fetching RegistrationInUs data based on the date picker.
	const fetchRegistrationInUs = () => {
		let sded = startDate && endDate ? startDate && endDate : sMonth && eMonth;
		let sd = moment(sMonth).format("YYYY-MM-DD");
		let ed = moment(eMonth).format("YYYY-MM-DD");
		let curentData = "/total-registrations-US";
		let selectedMonth = `/total-registrations-US?start_date=${startDate ? moment(startDate).format("YYYY-MM-DD") : sd}&end_date=${endDate ? moment(endDate).format("YYYY-MM-DD") : ed}`;
		fetchAdminApi(sded ? selectedMonth : curentData, "get").then((res) => {
			if (res && res.data && res.data !== null && res.data !== undefined) {
				let count = res.data.map(item => parseInt(item.registration_count)).reduce((prev, curr) => prev + curr, 0);
				setTotalRegistrationCount(count);
				setTableData(res.data);
				let data = res.data;
				//data to display in graph.
				let gfHeader = [];
				gfHeader.push(["States", "Registrations"]);
				data.map(obj => gfHeader.push([obj.state, parseInt(obj.registration_count)]));
				gfHeader = gfHeader.sort(function (a, b) {
					return b[1] - a[1];
				}).filter((_month, idx) => idx < 11);
				setGraphData(gfHeader);
			} else {
				setTableData([]);
				setGraphData([]);
				setTotalRegistrationCount(null);
			}
		});
	};
	const from = (_date, dateString) => {
		setStartDate(dateString);
		setSmonth();
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
		setEmonth();
	};
	// routing to registered page with stateid and date baseed on selected state.
	const viewRegistrationtd = (s_id, tdata, country,c_id) => {
		let sd = startDate  ? startDate :sMonth ;
		let ed = endDate  ? endDate : eMonth ;
		let state_id = s_id;
		let country_id = c_id;
		let st = tdata;
		let cntry = country;
		let stateid = {};
		stateid.state_id = state_id;
		stateid.country_id = country_id;
		stateid.sd = (sd) ? moment(sd).format("YYYY-MM-DD")  : constants.startDateOfTheYear;
		stateid.ed = (ed) ? moment(ed).format("YYYY-MM-DD") : constants.endDateOfTheYear;
		stateid.sded = startDate&&endDate || sMonth&&eMonth ? 1 : 2;
		stateid.state = st;
		stateid.country = cntry;
		stateid.title = "Registration By States";
		stateid.push = "/registration-in-us";
		stateid.serchCriteria = `Country: ${country}, State: ${tdata}, ${`Registrations from ${moment(stateid.sd).format('MM/DD/YYYY')} to ${moment(stateid.ed).format('MM/DD/YYYY')}`}`;
		history.push({
			pathname: "/registered-students",
			state: stateid
		});
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const todaysdate = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && todaysdate.isAfter(current));
	};
	const listStates = (td) =>{
		return <td key={td.state_id}><a style={{ color: "black" }} onClick={() => { viewRegistrationtd(td.state_id, td.state, td.country_code,td.country_id); }}>{td.state}</a></td>;
	};
	const listStateRegistrations = (td) =>{
		return <td key={td.state_id}><a style={{ color: "black" }} onClick={() => { viewRegistrationtd(td.state_id,td.state,td.country_code,td.country_id); }}>{td.registration_count}</a></td>;
	};
	const onChangeDateFilter = (e) => {
		registrationFilter(e,setStartDateEndDate);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>Registrations by US States</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase ">{<UsaIcon className="icon-lg"/>}{" "}<span style={{ display:"table-caption" }}>{constants.RegistrationByState}</span></span>} extra={
								<Form layout="horizontal" form ={form}>
									<Space>
										<Form.Item className="label-default mb-0" label="From:" name = "from">
											<DatePicker defaultValue = {sMonth? moment(sMonth) : null} onChange={from} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0" label="To:" name = "to">
											<DatePicker defaultValue = {eMonth ? moment(eMonth) : null} onChange={to} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0">
											<Select defaultValue="0" style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
												<Option value="0">This Year</Option>
												<Option value="7">Last Year</Option>
												<Option value="1">This Month</Option>
												<Option value="2">Last Month</Option>
												<Option value="3">Last 3 Months</Option>
												<Option value="4">This Month (Last Year)</Option>
												<Option value="5">Last 3 Months (Last Year)</Option>
												<Option value="6">Previous Years</Option>
											</Select>
										</Form.Item>
									</Space>
								</Form>
							}>
								<Row>
									<Col xs={24} sm={24} md={24} lg={24} className="reg-table">
										<table style={{ width: "100%" }}>
											<tr>
												<th>States</th>
												{tableData.filter((_month, idx) => idx < 25).map(td => {
													return listStates(td);
												})}
											</tr>
											<tr>
												<th>Registrations</th>
												{tableData.filter((_month, idx) => idx < 25).map(td => {
													return listStateRegistrations(td);
												})}
											</tr>
										</table>
										<br/>
										<table style={{ width: "100%" }} >
											<tr>
												<th>States</th>
												{tableData.filter((_month, idx) => idx >= 25).map(td => {
													return listStates(td);
												})}
											</tr>
											<tr>
												<th>Registrations</th>
												{tableData.filter((_month, idx) => idx >= 25).map(td => {
													return listStateRegistrations(td);
												})}
											</tr>
										</table>
										<br/>
										<span style={{ fontWeight: "bold" }}>Total Registrations: {totalRegistrationCount}</span>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24}>
										<div>
											<Chart
												chartType="ColumnChart"
												width="100%"
												height="400px"
												data={graphData}
												options={graphOption}
											/>
										</div>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>;
			</div>
		</>
	);
};
export default RegistrationInUsOnly;
