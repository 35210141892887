/*
File Name: Dashboard.js
Author: Dahlin Carneiro
Modified On: 24/05/2022
Description: Dashboard V2 - UI changed.
API's used:
1) /student/dashboard/${userId}
2) /student/feedback
3) /student/${userId}
4) /student/refer-a-friend
*/
import { Row, Col, Button, Space, Card, Carousel, Progress, Typography, Form, Input, Radio, Skeleton, Modal, message, Spin, notification } from "antd";
import { CopyOutlined, HistoryOutlined, QuestionCircleOutlined,WarningOutlined, FileTextOutlined, ReadOutlined, ArrowRightOutlined, GiftOutlined, UserAddOutlined, LeftOutlined, RightOutlined, LineChartOutlined, EditOutlined, FireFilled, SolutionOutlined, MinusCircleOutlined, CloseCircleFilled, PlayCircleOutlined,MailOutlined,SmileOutlined,MessageOutlined } from "@ant-design/icons";
import "../../styles/dashboard.css";
import Chart from "react-google-charts";
import React, { useState,useEffect } from "react";
import constants from "../../constants/constants";
// import CustomIcons from "../../assets/CustomIcons";
// import Icon from "@ant-design/icons";
import{ COOKIE,getCookie } from "../../services/cookie";
import { fetchApi } from "../../services/api";
import { useHistory } from "react-router";
import { getBadgeIcon, calculatePercentage } from "../../globalFunctions/GlobalFunctions";
import Loader from "./Cred/Loader";
// Custom icons
// const Glasses = (props) => (<Icon component={CustomIcons.getsvg("Glasses")} {...props} />);
// const Certificate = (props) => (<Icon component={CustomIcons.getsvg("Certificate")} {...props}/>);
// const WheatStar = (props) => (<Icon component={CustomIcons.getsvg("WheatStar")} {...props} />);
// const Graduation = (props) => (<Icon component={CustomIcons.getsvg("Graduation")} {...props} />);
const { Text, Paragraph } = Typography;
const { TextArea } = Input;
const Dashboard = () => {
	const carouselRef = React.useRef();
	const [form] = Form.useForm();
	const handleNext = () => carouselRef.current.next();
	const handlePrev = () => carouselRef.current.prev();
	const history = useHistory();
	var options = {
		// title: 'Progress on Tests',
		chartArea: { width: '70%' },
		legend: {
			position: 'top',
			alignment: 'end' 
		},
		tooltip: { isHtml: true },
		colors: [constants.AttemptedColor, constants.NotAttemptedColor],
		isStacked: true,
		hAxis: { minValue: 0, },
	};
	// Refer a friend modal
	const [isFriendModalVisible, setIsFriendModalVisible] = useState(false);
	// const showFriendModal = () => {setIsFriendModalVisible(true);};
	const handleFriendCancel = () => {
		setIsFriendModalVisible(false);
		referFriendForm.resetFields();
	};
	const handleReferfriendClear=()=>{
		setIsFriendModalVisible(false);
		referFriendForm.resetFields();};
	// Feedback modal
	const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
	const showFeedbackModal = () => {
		setOtherMessage(false);
		setIsFeedbackModalVisible(true);
	};
	const handleFeedbackCancel = () => {
		form.resetFields();
		setIsFeedbackModalVisible(false);
	};
	// const [value, setValue] = useState('');
	// const [otherValue, setOtherValue] = useState('');
	const [otherMessage, setOtherMessage] = useState(false);
	// const [testStatusValue, setTestStatusValue] = useState('');
	// const [productRatingValue, setProductRatingValue] = useState('');
	// const [feedbackCommentValue, setFeedbackCommentValue] = useState('');
	// const [hearabtbook,setHearabtbook]=useState(null);
	// const [testrating,setTestrating]=useState(null);
	// const [otherFeedback,setOtherFeedback]=useState(null);
	// const [productrating,setProductrating]=useState(null);
	// const [feedbackcomment,setFeedbackcomment]=useState(null);
	const [checkboxContainer, setCheckboxContainer] = useState(true);
	// Gift card modal
	const [isGiftModalVisible, setIsGiftModalVisible] = useState(false);
	// const showGiftModal = () => {setIsGiftModalVisible(true);};
	const handleGiftCancel = () => {setIsGiftModalVisible(false);};
	const [dashboardData,setDashboardData]=useState(null);
	const [progressGraphData,setProgressGraphData]=useState([]);
	const [completedMytest, setCompletedMytest] = useState(null);
	const [testPercent, setTestPercent] = useState({});
	const [flashcardPercent, setflashcardPercent] = useState({});
	const [tutorialPercent, settutorialPercent] = useState({});
	const [categoryPercent, setcategoryPercent] = useState({});
	const [categoryAttemtedTotal, setCategoryAttemtedTotal] = useState(null);
	const [categoryTotal, setCategoryTotal] = useState(0);
	const [topicTotal, setTopicTotal] = useState(0);
	const [topicPercent, settopicPercent] = useState({});
	const [fullLengthTotal, setFullLengthTotal] = useState(0);
	const [fullLengthPercent, setfullLengthPercent] = useState({});
	const [referFriendForm] = Form.useForm();
	const [loader,setLoader]=useState(false);
	const [disableCarouselButton,setdisableCarouselbutton]=useState(true);
	const [visibleQod,setVisibleQod]=useState(false);
	const [showfeedbackNotification,setShowFeedbackNotification]=useState(false);
	useEffect(() => {
		fetchDashboardData();
		let crashTest= sessionStorage.getItem("crash");
		if(crashTest==="true"){
			fetchDashboardData();
			sessionStorage.removeItem("crash");
		}
		return()=>{
			notification.destroy();
		};
	}, []);
	//To open the feedback modal if user clicks on the link from the full length test result email
	const openFeedbackModal=()=>{
		setIsFeedbackModalVisible(true);
		sessionStorage.removeItem("feedback");
	};
	const descriptionMsg=<span>{constants.feedbackNotificationDescription1}<br/>{constants.feedbackNotificationDescription2}</span>;
	//Notification feedback 
	const openNotification = () => {
		const key = `open${Date.now()}`;
		const btn = (
			<Button
				icon={<MessageOutlined/>}
				type="primary"
				size="small"
				className="btnLb"
				onClick={() => {
					setIsFeedbackModalVisible(true);
					notification.close(key);
				}}
			>
				Feedback
			</Button>
		);
		notification.open({
			message: constants.feedbackNotificationMessage,
			description:descriptionMsg,
			placement: "bottomRight",
			icon: (
				<SmileOutlined
					style={{ color: "#9b51e0" }}
				/>
			),
			duration: 0,
			btn,
			key,
			onClose: notification.close(key)
		});
	};
	//Function to show feedback notification
	const showfeedbackNotificationPopUp=()=>{
		openNotification();
		sessionStorage.setItem("feedbackShown","true");
	};
	//To Fetch the data of the user logged in to be displayed on the dashboard
	const fetchDashboardData=()=>{
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/student/dashboard/${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				let totaltests=res.data&&res.data.my_test&&res.data.my_test.completed?res.data.my_test.completed:0;
				sessionStorage.setItem("totalTestsTaken",totaltests);
				setDashboardData(res.data);
				let viewedQOD=sessionStorage.getItem("viewedQOD");
				if(res.data&&res.data.other&&res.data.other.is_qod_answered===0&&!viewedQOD){
					sessionStorage.setItem("viewedQOD","false");
				}
				viewedQOD=sessionStorage.getItem("viewedQOD");
				if(res.data&&res.data.other&&res.data.other.is_qod_answered===0&&viewedQOD&&viewedQOD!=="true"){
					setVisibleQod(true);
				}
				if(res.data&&res.data.feedback_flag==1&&sessionStorage.getItem("feedbackShown")!=="true"
				||res.data&&res.data.feedback_flag=="1"&&sessionStorage.getItem("feedbackShown")!=="true"){
					setShowFeedbackNotification(true);
					if(!(res.data&&res.data.other&&res.data.other.is_qod_answered===0&&viewedQOD&&viewedQOD!=="true")){
						showfeedbackNotificationPopUp();
						setShowFeedbackNotification(false);
					}
				}
				if(res.data&&res.data.data_retention_period){
					setdisableCarouselbutton(true);
				}
				if(res.data&&res.data.subscription_days_remaining){
					setdisableCarouselbutton(true);
				}
				setCategoryAttemtedTotal(res.data.progress_graph.FPP.attempted + res.data.progress_graph.MIXED.attempted +res.data.progress_graph.PIET.attempted+res.data.progress_graph.SAED.attempted);
				let flashcardtotal = res.data.flashcards.total!==null?res.data.flashcards.total:0;
				let flashcardcompleted = res.data.flashcards.completed!==null?res.data.flashcards.completed:0;
				let tutorialtotal = res.data.tutorials.total!==null?res.data.tutorials.total:0;
				let tutorialcompleted = res.data.tutorials.completed!==null?res.data.tutorials.completed:0;
				let categorytotal = res.data.progress_graph.FPP.attempted + res.data.progress_graph.FPP.not_attempted + res.data.progress_graph.MIXED.attempted +res.data.progress_graph.MIXED.not_attempted +res.data.progress_graph.PIET.attempted+res.data.progress_graph.PIET.not_attempted+res.data.progress_graph.SAED.attempted+res.data.progress_graph.SAED.not_attempted;
				let categorycompleted = res.data.progress_graph.FPP.attempted + res.data.progress_graph.MIXED.attempted +res.data.progress_graph.PIET.attempted+res.data.progress_graph.SAED.attempted;
				setCategoryTotal(categorytotal);
				let topictotal = res.data.progress_graph.topic.attempted + res.data.progress_graph.topic.not_attempted;
				let topiccompleted = res.data.progress_graph.topic.attempted;
				setTopicTotal(topictotal);
				let fulllengthtotal = res.data.progress_graph.full_length.attempted + res.data.progress_graph.full_length.not_attempted;
				let fulllengthcompleted = res.data.progress_graph.full_length.attempted;
				setFullLengthTotal(fulllengthtotal);
				let fullLengthp = calculatePercent(fulllengthtotal,fulllengthcompleted);
				let category = calculatePercent(categorytotal,categorycompleted);
				let topicp = calculatePercent(topictotal,topiccompleted);
				let tutorial = calculatePercent(tutorialtotal,tutorialcompleted);
				let flashcard = calculatePercent(flashcardtotal,flashcardcompleted);
				setfullLengthPercent(fullLengthp);
				settopicPercent(topicp);
				setcategoryPercent(category);
				settutorialPercent(tutorial);
				setflashcardPercent(flashcard);
				let tempCategoryAttemtedTotal=res.data.progress_graph.FPP.attempted + res.data.progress_graph.MIXED.attempted +res.data.progress_graph.PIET.attempted+res.data.progress_graph.SAED.attempted;
				if(tempCategoryAttemtedTotal == category.twentyfive || tempCategoryAttemtedTotal == category.fifty || tempCategoryAttemtedTotal == category.seventyfive || parseInt(tempCategoryAttemtedTotal) == parseInt(categorytotal)){
					setdisableCarouselbutton(true);
				}
				if(res.data && res.data.progress_graph && res.data.progress_graph.topic && res.data.progress_graph.topic.attempted == topicp.twentyfive ||
					res.data && res.data.progress_graph && res.data.progress_graph.topic && res.data.progress_graph.topic.attempted == topicp.fifty ||
					res.data && res.data.progress_graph && res.data.progress_graph.topic && res.data.progress_graph.topic.attempted == topicp.seventyfive||
					parseInt(res.data && res.data.progress_graph && res.data.progress_graph.topic && res.data.progress_graph.topic.attempted) == parseInt(topictotal)){
					setdisableCarouselbutton(true);
				}
				if(res.data && res.data.progress_graph && res.data.progress_graph.full_length && res.data.progress_graph.full_length.attempted == fullLengthp.twentyfive ||
					res.data && res.data.progress_graph && res.data.progress_graph.full_length && res.data.progress_graph.full_length.attempted == fullLengthp.fifty ||
					res.data && res.data.progress_graph && res.data.progress_graph.full_length && res.data.progress_graph.full_length.attempted == fullLengthp.seventyfive ||
					parseInt(res.data && res.data.progress_graph && res.data.progress_graph.full_length && res.data.progress_graph.full_length.attempted) == parseInt(fulllengthtotal)){
					setdisableCarouselbutton(true);
				}
				if(res.data&&res.data.flashcards&&
					res.data.flashcards.completed ==flashcard.twentyfive||
					res.data&&res.data.flashcards&&
					res.data.flashcards.completed==flashcard.fifty || 
					res.data&&res.data.flashcards&&
					res.data.flashcards.completed==flashcard.seventyfive||
					parseInt(res.data&&res.data.flashcards&&
						res.data.flashcards.completed) ==parseInt(res.data&&res.data.flashcards&&
							res.data.flashcards.total)){
					setdisableCarouselbutton(true);
				}
				if(res.data&&
					res.data.tutorials&&
					res.data.tutorials.completed ==tutorial.twentyfive ||
					res.data&&
					res.data.tutorials&&
					res.data.tutorials.completed ==tutorial.fifty ||
					res.data&&
					res.data.tutorials&&
					res.data.tutorials.completed ==tutorial.seventyfive ||
					parseInt(res.data&&
						res.data.tutorials&&
						res.data.tutorials.completed) ==parseInt(res.data&&
							res.data.tutorials&&
							res.data.tutorials.total) ){
					setdisableCarouselbutton(true);
				}
				if(res.data.my_test){
					// let testCompleted=0;
					let completed=res.data.my_test.completed!==null?res.data.my_test.completed:0;
					setCompletedMytest(completed);
					let total=res.data.my_test.total!==null?res.data.my_test.total:0;
					// testCompleted=(completed/total)*100;
					let test = calculatePercent(total,completed);
					setTestPercent(test);
					if(completed ==test.twentyfive ||  completed == test.fifty || completed ==test.seventyfive || (parseInt(completed) ==parseInt( res.data.my_test && res.data.my_test.total))) {
						setdisableCarouselbutton(true);
					}
				}
				if(res.data.pause_count > 0){
					setdisableCarouselbutton(true);
				}
				//data to display in progress graph
				let fpp=[];
				fpp.push("FPP",res.data.progress_graph&&
				res.data.progress_graph.FPP&&
				res.data.progress_graph.FPP.attempted?res.data.progress_graph.FPP.attempted:0,
				res.data.progress_graph&&
				res.data.progress_graph.FPP&&
				res.data.progress_graph.FPP.not_attempted?res.data.progress_graph.FPP.not_attempted:0);
				let piet=[];
				piet.push("PIET",res.data.progress_graph&&
				res.data.progress_graph.PIET&&
				res.data.progress_graph.PIET.attempted?res.data.progress_graph.PIET.attempted:0,
				res.data.progress_graph&&
				res.data.progress_graph.PIET&&
				res.data.progress_graph.PIET.not_attempted?res.data.progress_graph.PIET.not_attempted:0);
				let saed=[];
				saed.push("SAED",res.data.progress_graph&&
				res.data.progress_graph.SAED&&
				res.data.progress_graph.SAED.attempted?res.data.progress_graph.SAED.attempted:0,
				res.data.progress_graph&&
				res.data.progress_graph.SAED&&
				res.data.progress_graph.SAED.not_attempted?res.data.progress_graph.SAED.not_attempted:0);
				let mixed=[];
				mixed.push("Mixed",res.data.progress_graph&&
				res.data.progress_graph.MIXED&&
				res.data.progress_graph.MIXED.attempted?res.data.progress_graph.MIXED.attempted:0,
				res.data.progress_graph&&
				res.data.progress_graph.MIXED&&
				res.data.progress_graph.MIXED.not_attempted?res.data.progress_graph.MIXED.not_attempted:0);
				let topic=[];
				topic.push("Topic",res.data.progress_graph&&
				res.data.progress_graph.topic&&
				res.data.progress_graph.topic.attempted?res.data.progress_graph.topic.attempted:0,
				res.data.progress_graph&&
				res.data.progress_graph.topic&&
				res.data.progress_graph.topic.not_attempted?res.data.progress_graph.topic.not_attempted:0);
				let fullLength=[];
				fullLength.push("Full length",res.data.progress_graph&&
				res.data.progress_graph.full_length&&
				res.data.progress_graph.full_length.attempted?	res.data.progress_graph.full_length.attempted:0,
				res.data.progress_graph&&
				res.data.progress_graph.full_length&&
				res.data.progress_graph.full_length.not_attempted?res.data.progress_graph.full_length.not_attempted:0);
				let graphHeader=[];
				graphHeader.push("Tests", "Attempted", "Not Attempted");
				let graphData=[];
				graphData.push(graphHeader,
					fpp&&fpp.length>0?fpp:[],
					saed&&saed.length>0?saed:[],
					piet&&piet.length>0?piet:[],
					mixed&&mixed.length>0?mixed:[],
					topic&&topic.length>0?topic:[],
					fullLength&&fullLength.length>0?fullLength:[]);
				setProgressGraphData(graphData);
			}
			// display comment section on form submit
			if (res.data.other.is_feedback_given===0) {
				setCheckboxContainer(true);
				let isFeedBackFromSession=sessionStorage.getItem("feedback");
				if(isFeedBackFromSession){
					openFeedbackModal();
				}
			}
			else {
				setCheckboxContainer(false);
			}
		});
	};
	const calculatePercent = (typetotal,completed) =>{
		return {
			twentyfive: completed == Math.ceil(0.25*typetotal)? Math.ceil(0.25*typetotal) : Math.floor(0.25*typetotal),
			fifty:  completed == Math.ceil(0.50*typetotal) ?Math.ceil(0.50*typetotal): Math.floor(0.50*typetotal),
			seventyfive: completed == Math.ceil(0.75*typetotal) ? Math.ceil(0.75*typetotal) : Math.floor(0.75*typetotal)  ,
		};
	};
	// To send feedback by the user
	const onFinish = (value) => {
		setLoader(true);
		let userId = getCookie(COOKIE.UserId);
		let payload = null;
		payload = {
			book_status:value.hearabtbook,
			test_status:value.testrating,
			test_rating:value.productrating,
			test_comments:value.feedbackcomment,
			other_feedback:value.otherFeedback,
			stud_id:userId,
			first_submit:checkboxContainer,
		};
		fetchApi(`/student/feedback`, "post", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					setLoader(false);
					message.success(constants.FeedbackSucess);
					setIsFeedbackModalVisible(false);
					// setValue(0);
					// setTestStatusValue(0);
					// setProductRatingValue(0);
					setOtherMessage(false);
					form.resetFields();
					setCheckboxContainer(false);
				} else {
					setLoader(false);
					message.error("Feedback Failed");
				}
			})
			.catch(() => {
				message.error("Error");
			});
	};
	const onChange = e => {
		// setValue(e.target.value);	
		if (e.target.value===8) {
			setOtherMessage(true);
		}
		else {
			setOtherMessage(false);
		}
	};
	// const onChangeTestStatus = e => {
	// 	setTestStatusValue(e.target.value);	
	// };
	// const onChangeProductRating = e => {
	// 	setProductRatingValue(e.target.value);	
	// };
	// const onFeedbackChange = e => {
	// 	setFeedbackCommentValue(e.target.value);
	// };
	// const otherOnChange = e => {
	// 	setOtherValue(e.target.value);	
	// };
	const handleOtherFeedBackValidation = (rule, value, callback) => {
		try {
			if(value){
				if(value.trim()===""){
					throw new Error(constants.FeedbackMessageError);
				}
			}else{
				throw new Error(constants.FeedbackMessageError);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleValidateFeedbackComment=(rule,value,callback)=>{
		try {
			if(value){
				if(value.trim()===""){
					throw new Error(constants.FeedBackCommentRequired);
				}
			}else{
				throw new Error(constants.FeedBackCommentRequired);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//To submit refer a friend
	const onSubmitReferFriend=(values)=>{
		let userId = getCookie(COOKIE.UserId);
		let payload = null;
		let friendObj={
			friend_name:values.name1,
			friend_email:values.email1
		};
		//Map to check if values exists in the array
		let arrayTemp=values.users!==undefined?values.users:[];
		let indexLast=-1;
		let isError=false;
		arrayTemp.map((item,index)=>{
			if(
				((item.friend_name!==undefined&&item.friend_name!=="")&&(item.friend_email===undefined||item.friend_email===""))
				||((item.friend_email!==undefined&&item.friend_email!=="")&&(item.friend_name===undefined||item.friend_name===""))
				||(item.friend_name!==undefined && item.friend_name!=="" && item.friend_email!==undefined && item.friend_email!=="")
			)
			{
				indexLast=index;
			}
		});
		if(indexLast!==-1){
			let indexSplice=null;
			if(
				(arrayTemp[indexLast].friend_name==="" && arrayTemp[indexLast].friend_email==="")
			||(arrayTemp[indexLast].friend_name===undefined && arrayTemp[indexLast].friend_email===undefined)
			||(arrayTemp[indexLast].friend_name===undefined && arrayTemp[indexLast].friend_email==="")
			||(arrayTemp[indexLast].friend_name===""&& arrayTemp[indexLast].friend_email===undefined)
			){
				indexSplice=indexLast;
			} else{
				indexSplice=indexLast+1;
			} 
			arrayTemp.splice(indexSplice);
		}else{
			arrayTemp=[];
		}
		if(indexLast!==-1 && arrayTemp.length>0){
			arrayTemp.map((itemNew)=>{
				if(
					itemNew.friend_name===undefined || itemNew.friend_email===undefined||
					itemNew.friend_name==="" || itemNew.friend_email===""
				)
				{
					isError=true;
				}
			});
		}
		if(isError===true){
			message.error("Fields cannot be empty.");
		}else{		
			let friendList=arrayTemp;
			// if(values.users!==undefined){
			// 	friendList=values.users;
			// }
			friendList.push(friendObj);
			let executeAPI=true;
			friendList.map(ele => {
				let noOccurence = friendList.filter(obj => obj.friend_email ===ele.friend_email).length;
				if(noOccurence > 1){
					executeAPI=false;
					return;
				}
			});
			if(executeAPI===true){
				payload = {
					stud_id:userId,
					data:friendList
				};
				fetchApi(`/student/refer-a-friend`, "post", payload).then((res) => {
					if (res && res.code && res.code === 200) {
						message.success(res.message);
						handleReferfriendClear();
					} else {
						message.error(res.message);
					}
				});
			}else{
				message.error("Duplicate emails found");
			}
		}
	};
	const startQuestionOfDay=()=>{
		let userId = getCookie(COOKIE.UserId);
		let questionOfDayDetails={};
		questionOfDayDetails.userId=userId;
		questionOfDayDetails.type="qod";
		history.push({
			pathname: "/question-of-the-day",
			state: { questionOfDayDetails:questionOfDayDetails, }
		});
	};
	const handleCancel = (showPopup) => {
		sessionStorage.setItem("viewedQOD","true");
		setVisibleQod(false);
		if(showfeedbackNotification===true&&showPopup==="1"){
			showfeedbackNotificationPopUp();
			setShowFeedbackNotification(false);
		}
	};
	const emailMessageCard = () =>{
		let emailMessage=null;
		if(dashboardData&&dashboardData.email_flag === 1){
			emailMessage = constants.alternateEmail;
		}else if(dashboardData&&dashboardData.email_flag === 2){
			emailMessage = constants.primaryEmail;
		}else if(dashboardData&&dashboardData.email_flag === 0){
			emailMessage = constants.bothEmailBounces;
		}
		return 	<Card className="smart-card glance-card">
			<small className="text-uppercase dash-title">{constants.EmailMessage}</small>
			<Row gutter={[0, 18]} className="w-100">
				<Col xs={24} sm={5} md={5} lg={6} xl={6} xxl={5}>
					<MailOutlined className="errorMsg" style={{ fontSize: '75px' }}/>
				</Col>
				<Col xs={24} sm={19} md={19} lg={18} xl={18} xxl={19}>
					<Text>{emailMessage}</Text>
				</Col>
			</Row>
		</Card>;
	};
	return (
		<div className="p-4">
			<Row gutter={[{
				xs: 0,
				sm: 0,
				md: 24,
				lg: 24,
				xl: 24  
			}, {
				xs: 24,
				sm: 24,
				md: 24,
				lg: 16,
				xl: 16
			}]}>
				<Col xs={24} sm={24} md={24} lg={6} xl={6} className="slp-card">
					<Card hoverable onClick={()=>{	history.push("/my-tests");}}>
						<Row>
							<Col span={8} className="green-bg place-center"><HistoryOutlined className="icon-lg text-light"/></Col>
							<Col span={16} className="place-center">
								<div className="pr-5 m-2">
									<span className="font-18">{constants.MyTests}</span>
									<h2 className="mb-0">{(dashboardData&&
											dashboardData.my_test&&
											dashboardData.my_test.completed?
										dashboardData.my_test.completed:0)
											+"/"+
											(dashboardData&&
											dashboardData.my_test&&
											dashboardData.my_test.total?
												dashboardData.my_test.total:0)}</h2>
								</div>
								<div className="crd-arrow"><ArrowRightOutlined className="icon-md text-mute pr-2 pb-2" onClick={()=>{	history.push("/my-tests");}}/></div>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={6} xl={6} className="slp-card">
					<Card hoverable onClick={()=>{	history.push("/flashcard-list");}}>
						<Row>
							<Col span={8} className="sky-bg2 place-center"><CopyOutlined className="icon-lg flash-ico text-light"/></Col>
							<Col span={16} className="place-center">
								<div className="pr-5 m-2">
									<span className="font-18">{constants.Flashcards}</span>
									<h2 className="mb-0">{(dashboardData&&
											dashboardData.flashcards&&
											dashboardData.flashcards.completed?
										dashboardData.flashcards.completed:0)
											+"/"+
											(dashboardData&&
											dashboardData.flashcards&&
											dashboardData.flashcards.total?
												dashboardData.flashcards.total:0)}
									</h2>
								</div>
								<div className="crd-arrow"><ArrowRightOutlined className="icon-md text-mute pr-2 pb-2" onClick={()=>{	history.push("/flashcard-list");}}/></div>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={6} xl={6} className="slp-card">
					<Card hoverable onClick={()=>{	history.push("/select-tutorial");}}>
						<Row>
							<Col span={8} className="bg-secondary place-center"><ReadOutlined className="icon-lg text-light"/></Col>
							<Col span={16} className="place-center">
								<div className="pr-5 m-2">
									<span className="font-18">{constants.Tutorials}</span>
									<h2 className="mb-0">{(dashboardData&&
											dashboardData.tutorials&&
											dashboardData.tutorials.completed?
										dashboardData.tutorials.completed:0)
											+"/"+
											(dashboardData&&
											dashboardData.tutorials&&
											dashboardData.tutorials.total?
												dashboardData.tutorials.total:0)}</h2>
								</div>
								<div className="crd-arrow"><ArrowRightOutlined className="icon-md text-mute pr-2 pb-2"onClick={()=>{	history.push("/select-tutorial");}}/></div>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={6} xl={6} className="slp-card">
					<Card hoverable onClick={()=>{	history.push("/notes");}}>
						<Row>
							<Col span={8} className="orange-bg place-center"><FileTextOutlined className="icon-lg note-ico text-light"/></Col>
							<Col span={16} className="place-center">
								<div className="pr-5 m-2">
									<span className="font-18">{constants.MyNotes}</span>
									<h2 className="mb-0">{dashboardData&&
											dashboardData.my_notes?
										dashboardData.my_notes:0}
									</h2>
								</div>
								<div className="crd-arrow"><ArrowRightOutlined className="icon-md text-mute pr-2 pb-2" onClick={()=>{	history.push("/notes");}}/></div>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12} xl={10}>
					<Row gutter={[{
						xs: 0,
						sm: 0,
						md: 0,
						lg: 24 
					}, {
						xs: 24,
						sm: 24,
						md: 24,
						lg: 16 
					}]}>
						<Col xs={24} sm={24} md={24} lg={24} className="px-0">
							<div className="smart-crd-section">
								{/* {disableCarouselButton? */}
								<Button onClick={handlePrev} type="link" icon={disableCarouselButton?<LeftOutlined/>:null} size='large' style={{ zIndex:999 }}/>
								<Carousel dots={false} ref={carouselRef} autoplay autoplaySpeed="70">
									<div>
										<Card className="smart-card glance-card">
											<small className="text-uppercase dash-title">{constants.AtaGlance}</small>
											<Row gutter={[0, 18]}>
												<Col xs={12} sm={12} md={10} lg={12}>
													<Text>Last Logged On: </Text>
												</Col>
												<Col xs={12} sm={12} md={14} lg={12} className="glance-data">
													<Text>{dashboardData&&
																dashboardData.at_a_glance&&
																dashboardData.at_a_glance.dt_last_login&&
																dashboardData.at_a_glance.dt_last_login}
													</Text>
												</Col>
												<Col xs={12} sm={12} md={10} lg={12}><Text>Last Test Completed: </Text></Col><Col xs={12} sm={12} md={14} lg={12} className="glance-data"><Text>{(dashboardData&&
																dashboardData.at_a_glance&&
																dashboardData.at_a_glance.last_test_attempted&&
																dashboardData.at_a_glance.last_test_attempted.test_type?
													dashboardData.at_a_glance.last_test_attempted.test_type==="TOPIC"?dashboardData.at_a_glance.last_test_attempted.topic_abbreviation:
														dashboardData.at_a_glance.last_test_attempted.test_type:"")+
														(dashboardData&&
														dashboardData.at_a_glance&&
																dashboardData.at_a_glance.last_test_attempted&&
																dashboardData.at_a_glance.last_test_attempted.test_type?
															dashboardData.at_a_glance.last_test_attempted.test_type==="TOPIC"?
																"":
																(dashboardData&&
																	dashboardData.at_a_glance&&
																	dashboardData.at_a_glance.last_test_attempted&&
																	dashboardData.at_a_glance.last_test_attempted.test_number?
																	" "+dashboardData.at_a_glance.last_test_attempted.test_number:""
																)
															:"")													
																	+
																		(dashboardData&&
																		dashboardData.at_a_glance&&
																		dashboardData.at_a_glance.last_test_attempted&&
																		dashboardData.at_a_glance.last_test_attempted.total_score?" ("+
																		dashboardData.at_a_glance.last_test_attempted.total_score+")":"-")}
												</Text></Col>
												<Col xs={12} sm={12} md={10} lg={12}><Text>Subscription Valid Until: </Text></Col><Col xs={12} sm={12} md={14} lg={12} className="glance-data"><Text className={dashboardData&&dashboardData.subscription_days_remaining?"errorMsg":""}>{dashboardData&&
																	dashboardData.at_a_glance&&
																	dashboardData.at_a_glance.valid_till&&
																	dashboardData.at_a_glance.valid_till}
												</Text></Col>
											</Row>
										</Card>
									</div>
									{/* Hotmail, Yahoo or AOL card */}
									<div>
										<Card className="smart-card glance-card">
											<small className="text-uppercase dash-title">Email Messages</small>
											<Row gutter={[0, 18]} className="w-100">
												<Col xs={24} sm={5} md={5} lg={6} xl={6} xxl={5}>
													{/* <WarningOutlined className="errorMsg" style={{ fontSize: '100px' }}/> */}
													<MailOutlined className="errorMsg" style={{ fontSize: '75px' }}/>
												</Col>
												<Col xs={24} sm={19} md={19} lg={18} xl={18} xxl={19}>
													<Text>{constants.changeMailText}</Text>
												</Col>
											</Row>
										</Card>
									</div>
									{/* Subscription expiry card */}
									{dashboardData&&dashboardData.subscription_days_remaining&&(
										<div>
											<Card className="smart-card glance-card">
												<small className="text-uppercase dash-title">{constants.SubscriptionExpiry}</small>
												<Row gutter={[0, 18]} className="w-100">
													<Col xs={12} sm={10} md={5} lg={10} xl={8}>
														<WarningOutlined className="errorMsg" style={{ fontSize: '100px' }}/>
													</Col>
													<Col xs={12} sm={14} md={19} lg={14} xl={16}>
														<Text>{dashboardData&&dashboardData.data_retention_message}</Text>
													</Col>
												</Row>
											</Card>
										</div>
									)}
									{/*Email Message card */}
									{dashboardData&&dashboardData.email_flag !==null&&(
										<div>
											{emailMessageCard()}
										</div>
									)}
									{/* First Feedbackcard not provided message card */}
									{dashboardData&&dashboardData.other &&dashboardData.other.is_feedback_given === 0 &&(
										<div>
											<Card className="smart-card glance-card">
												<small className="text-uppercase dash-title">{constants.FeedbackTitle}</small>
												<Row gutter={[0, 18]} className="w-100">
													<Col xs={12} sm={10} md={5} lg={10} xl={8}>
														<SolutionOutlined className="purple" style={{ fontSize: '100px' }}/>
													</Col>
													<Col xs={12} sm={14} md={19} lg={14} xl={16}>
														<Text><a onClick={()=>{setIsFeedbackModalVisible(true);}}>Click here</a> {constants.FeedbackMessage}</Text>
													</Col>
												</Row>
											</Card>
										</div>
									)}
									{/* <div>
										<Card className="smart-card smart-card-bg-img">
											<small className="text-uppercase">Gift card 1</small>
											<h2><b>Pro-Ed Discount Code</b></h2>
											<div className="text-right btn-bottom-right p-4"><Button type="primary" className="navy-btn" onClick={showGiftModal}>{constants.RevealBTN}</Button></div>
										</Card>
									</div> */}
									{/* card for pause test */}
									{dashboardData&&
											dashboardData.pause_count !== constants.ShowPauseCard &&(
										<div>
											<Card className="smart-card glance-card">
												<small className="text-uppercase dash-title">{constants.TestPaused}</small>
												<Row gutter={[0, 12]} className="w-100">
													<Col xs={12} sm={10} md={5} lg={10} xl={8}>
														<PlayCircleOutlined className="navy" style={{ fontSize: '100px' }}/>
													</Col>
													<Col xs={12} sm={14} md={19} lg={14} xl={16}> 
														{dashboardData&&
											dashboardData.pause_count ===constants.PauseCountOne &&(
															<Col flex='190'>
																<Text>{`You have paused ${dashboardData&&
											dashboardData.pause_count} Test.`} </Text>
																<div className="mb-3 pb-1"/>
																<a onClick={()=>{history.push({ 
																	pathname:"/my-tests",
																	state:{ IsFilter:true }
																});}}>{constants.ViewPauseCard1}</a>
															</Col>)}
														{dashboardData&&
															dashboardData.pause_count ===constants.PauseCounttwo &&(
															<Col flex='190'>
																<Text>{`You have paused ${dashboardData&&
											dashboardData.pause_count} Tests.`} </Text>
																<div className="mb-3 pb-1"/>
																<a onClick={()=>{history.push({ 
																	pathname:"/my-tests",
																	state:{ IsFilter:true }
																});}}>{constants.ViewmorePauseCard}</a>
															</Col>)}
														{dashboardData&&
															dashboardData.pause_count ===constants.PauseCountthree &&(
															<Col flex='190'>
																<Text>{`You have paused ${dashboardData&&
											dashboardData.pause_count} Tests.` }</Text>
																<div className="mb-3 pb-1"/>
																<Text>{constants.ViewPauseCard3Message}</Text>
																<div className="mb-3 pb-1"/>
																<a onClick={()=>{history.push({ 
																	pathname:"/my-tests",
																	state:{ IsFilter:true }
																});}}>{constants.ViewmorePauseCard}</a>
															</Col>)}
													</Col>
												</Row>
											</Card>
										</div>
									)}
									{(completedMytest ==testPercent.twentyfive ||  completedMytest == testPercent.fifty || completedMytest ==testPercent.seventyfive || parseInt(completedMytest) ==parseInt(dashboardData && dashboardData.my_test && dashboardData.my_test.total)) &&(
										<div>
											<Card className="smart-card glance-card">
												<small className="text-uppercase dash-title">{constants.MilestoneHeader}</small>
												<Row gutter={[0, 18]} className="w-100">
													{
														parseInt(dashboardData && dashboardData.my_test && dashboardData.my_test.total) == parseInt(completedMytest) ?
															<><Col flex='144px'>
																<Progress
																	type="circle"
																	strokeColor={constants.MyTestColor}
																	percent={100}
																	className='black-tick'
																/>
															</Col>
															<Col flex='190'>
																<Text>{constants.OptionPercentTest[100]}</Text>
															</Col></>:
															completedMytest == testPercent.seventyfive ?
																<><Col flex='144px'>
																	<Progress
																		type="circle"
																		strokeColor={constants.MyTestColor}
																		percent={75}
																		className='black-tick'
																	/>
																</Col>
																<Col flex='190'>
																	<Text>{constants.OptionPercentTest[75]}</Text>
																</Col></>:
																completedMytest == testPercent.fifty ? 
																	<><Col flex='144px'>
																		<Progress
																			type="circle"
																			strokeColor={constants.MyTestColor}
																			percent={50}
																			className='black-tick'
																		/>
																	</Col>
																	<Col flex='190'>
																		<Text>{constants.OptionPercentTest[50]}</Text>
																	</Col></>:
																	completedMytest == testPercent.twentyfive ?
																		<><Col flex='144px'>
																			<Progress
																				type="circle"
																				strokeColor={constants.MyTestColor}
																				percent={25}
																				className='black-tick'
																			/>
																		</Col>
																		<Col flex='190'>
																			<Text>{constants.OptionPercentTest[25]}</Text>
																		</Col></>:
																		<div></div>
													}
												</Row>
											</Card>
										</div>
									)}
									{
										(dashboardData&&dashboardData.flashcards&&
											dashboardData.flashcards.completed ==flashcardPercent.twentyfive||
											dashboardData&&dashboardData.flashcards&&
											dashboardData.flashcards.completed==flashcardPercent.fifty || 
											dashboardData&&dashboardData.flashcards&&
											dashboardData.flashcards.completed==flashcardPercent.seventyfive||
											parseInt(dashboardData&&dashboardData.flashcards&&
											dashboardData.flashcards.completed) ==parseInt(dashboardData&&dashboardData.flashcards&&
											dashboardData.flashcards.total))
											&&(
												<div>
													<Card className="smart-card glance-card">
														<small className="text-uppercase dash-title">{constants.MilestoneFlashcardHeader}</small>
														<Row gutter={[0, 18]} className="w-100">
															{
																parseInt(dashboardData && dashboardData.flashcards && dashboardData.flashcards.total) == parseInt(dashboardData && dashboardData.flashcards && dashboardData.flashcards.completed) ?
																	<><Col flex='144px'>
																		<Progress
																			type="circle"
																			strokeColor={constants.Flashcard}
																			percent={100}
																			className='dark-orange-tick'
																		/>
																	</Col>
																	<Col flex='190'>
																		<Text>{constants.OptionPercentFlashcard[100]}</Text>
																	</Col></>:
																	(dashboardData && dashboardData.flashcards && dashboardData.flashcards.completed == flashcardPercent.seventyfive )  ?
																		<><Col flex='144px'>
																			<Progress
																				type="circle"
																				strokeColor={constants.Flashcard}
																				percent={75}
																				className='dark-orange-tick'
																			/>
																		</Col>
																		<Col flex='190'>
																			<Text>{constants.OptionPercentFlashcard[75]}</Text>
																		</Col></>:
																		(dashboardData && dashboardData.flashcards && dashboardData.flashcards.completed == flashcardPercent.fifty ) ? 
																			<><Col flex='144px'>
																				<Progress
																					type="circle"
																					strokeColor={constants.Flashcard}
																					percent={50}
																					className='dark-orange-tick'
																				/>
																			</Col>
																			<Col flex='190'>
																				<Text>{constants.OptionPercentFlashcard[50]}</Text>
																			</Col></>:
																			(dashboardData && dashboardData.flashcards && dashboardData.flashcards.completed == flashcardPercent.twentyfive) ?
																				<><Col flex='144px'>
																					<Progress
																						type="circle"
																						strokeColor={constants.Flashcard}
																						percent={25}
																						className='dark-orange-tick'
																					/>
																				</Col>
																				<Col flex='190'>
																					<Text>{constants.OptionPercentFlashcard[25]}</Text>
																				</Col></>:
																				<div></div>
															}
														</Row>
													</Card>
												</div>
											)}
									{(dashboardData&&
											dashboardData.tutorials&&
											dashboardData.tutorials.completed ==tutorialPercent.twentyfive ||
											dashboardData&&
											dashboardData.tutorials&&
											dashboardData.tutorials.completed ==tutorialPercent.fifty ||
											dashboardData&&
											dashboardData.tutorials&&
											dashboardData.tutorials.completed ==tutorialPercent.seventyfive ||
											parseInt(dashboardData&&
											dashboardData.tutorials&&
											dashboardData.tutorials.completed) ==parseInt(dashboardData&&
											dashboardData.tutorials&&
											dashboardData.tutorials.total) )&&(
										<div>
											<Card className="smart-card glance-card">
												<small className="text-uppercase dash-title">{constants.MilestoneTutorialHeader}</small>
												<Row gutter={[0, 18]} className="w-100">
													{
														parseInt(dashboardData && dashboardData.tutorials && dashboardData.tutorials.total) == parseInt(dashboardData && dashboardData.tutorials && dashboardData.tutorials.completed) ?
															<><Col flex='144px'>
																<Progress
																	type="circle"
																	strokeColor={constants.Tutorial}
																	percent={100}
																	className='light-orange-tick'
																/>
															</Col>
															<Col flex='190'>
																<Text>{constants.OptionPercentTutorials[100]}</Text>
															</Col>
															</>:
															(dashboardData && dashboardData.tutorials && dashboardData.tutorials.completed == tutorialPercent.seventyfive ) ?
																<><Col flex='144px'>
																	<Progress
																		type="circle"
																		strokeColor={constants.Tutorial}
																		percent={75}
																		className='light-orange-tick'
																	/>
																</Col>
																<Col flex='190'>
																	<Text>{constants.OptionPercentTutorials[75]}</Text>
																</Col></>:
																(dashboardData && dashboardData.tutorials && dashboardData.tutorials.completed == tutorialPercent.fifty  ) ? 
																	<><Col flex='144px'>
																		<Progress
																			type="circle"
																			strokeColor={constants.Tutorial}
																			percent={50}
																			className='light-orange-tick'
																		/>
																	</Col>
																	<Col flex='190'>
																		<Text>{constants.OptionPercentTutorials[50]}</Text>
																	</Col></>:
																	(dashboardData && dashboardData.tutorials && dashboardData.tutorials.completed == tutorialPercent.twentyfive)?
																		<><Col flex='144px'>
																			<Progress
																				type="circle"
																				strokeColor={constants.Tutorial}
																				percent={25}
																				className='light-orange-tick'
																			/>
																		</Col>
																		<Col flex='190'>
																			<Text>{constants.OptionPercentTutorials[25]}</Text>
																		</Col></>:
																		<div></div>
													}
												</Row>
											</Card>
										</div>
									)}
									{/* welcome card's for category*/}
									{
										(categoryAttemtedTotal == categoryPercent.twentyfive || categoryAttemtedTotal == categoryPercent.fifty || categoryAttemtedTotal == categoryPercent.seventyfive || parseInt(categoryAttemtedTotal) == parseInt(categoryTotal)) && (
											<div>
												<Card className="smart-card glance-card">
													<small className="text-uppercase dash-title">{constants.MilestoneCategoryHeader}</small>
													<Row gutter={[0, 18]} className="w-100">
														{
															parseInt(categoryTotal) == parseInt(categoryAttemtedTotal) ?
																<><Col flex='144px'>
																	<Progress
																		type="circle"
																		strokeColor={constants.FPPDarkColor}
																		percent={100}
																		className='blue-tick'
																	/>
																</Col>
																<Col flex='190'>
																	<Text>{constants.OptionPercentCategory[100]}</Text>
																</Col></>:
																categoryAttemtedTotal == categoryPercent.seventyfive ?
																	<><Col flex='144px'>
																		<Progress
																			type="circle"
																			strokeColor={constants.FPPDarkColor}
																			percent={75}
																			className='blue-tick'
																		/>
																	</Col>
																	<Col flex='190'>
																		<Text>{constants.OptionPercentCategory[75]}</Text>
																	</Col></>:
																	categoryAttemtedTotal == categoryPercent.fifty ? 
																		<><Col flex='144px'>
																			<Progress
																				type="circle"
																				strokeColor={constants.FPPDarkColor}
																				percent={50}
																				className='blue-tick'
																			/>
																		</Col>
																		<Col flex='190'>
																			<Text>{constants.OptionPercentCategory[50]}</Text>
																		</Col></>:
																		categoryAttemtedTotal == categoryPercent.twentyfive ?
																			<><Col flex='144px'>
																				<Progress
																					type="circle"
																					strokeColor={constants.FPPDarkColor}
																					percent={25}
																					className='blue-tick'
																				/>
																			</Col>
																			<Col flex='190'>
																				<Text>{constants.OptionPercentCategory[25]}</Text>
																			</Col></>:
																			<div></div>
														}
													</Row>
												</Card>
											</div>
										)
									}
									{/* welcome card's for topics*/}
									{
										(dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.topic && dashboardData.progress_graph.topic.attempted == topicPercent.twentyfive ||
											dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.topic && dashboardData.progress_graph.topic.attempted == topicPercent.fifty ||
											dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.topic && dashboardData.progress_graph.topic.attempted == topicPercent.seventyfive||
											parseInt(dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.topic && dashboardData.progress_graph.topic.attempted) == parseInt(topicTotal)) && (
											<div>
												<Card className="smart-card glance-card">
													<small className="text-uppercase dash-title">{constants.MilestoneTopicHeader}</small>
													<Row gutter={[0, 18]} className="w-100">
														{
															parseInt(topicTotal) == parseInt(dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.topic && dashboardData.progress_graph.topic.attempted) ?
																<><Col flex='144px'>
																	<Progress
																		type="circle"
																		strokeColor={constants.TopicDarkColor}
																		percent={100}
																		className='indigo-tick'
																	/>
																</Col>
																<Col flex='190'>
																	<Text>{constants.OptionPercentTopics[100]}</Text>
																</Col></>:
																dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.topic && dashboardData.progress_graph.topic.attempted == topicPercent.seventyfive?
																	<><Col flex='144px'>
																		<Progress
																			type="circle"
																			strokeColor={constants.TopicDarkColor}
																			percent={75}
																			className='indigo-tick'
																		/>
																	</Col>
																	<Col flex='190'>
																		<Text>{constants.OptionPercentTopics[75]}</Text>
																	</Col></>:
																	dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.topic && dashboardData.progress_graph.topic.attempted == topicPercent.fifty ? 
																		<><Col flex='144px'>
																			<Progress
																				type="circle"
																				strokeColor={constants.TopicDarkColor}
																				percent={50}
																				className='indigo-tick'
																			/>
																		</Col>
																		<Col flex='190'>
																			<Text>{constants.OptionPercentTopics[50]}</Text>
																		</Col></>:
																		dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.topic && dashboardData.progress_graph.topic.attempted == topicPercent.twentyfive ?
																			<><Col flex='144px'>
																				<Progress
																					type="circle"
																					strokeColor={constants.TopicDarkColor}
																					percent={25}
																					className='indigo-tick'
																				/>
																			</Col>
																			<Col flex='190'>
																				<Text>{constants.OptionPercentTopics[25]}</Text>
																			</Col></>:
																			<div></div>
														}
													</Row>
												</Card>
											</div>
										)
									}
									{/* welcome card's for full_length*/}
									{
										(dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.full_length && dashboardData.progress_graph.full_length.attempted == fullLengthPercent.twentyfive ||
											dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.full_length && dashboardData.progress_graph.full_length.attempted == fullLengthPercent.fifty ||
											dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.full_length && dashboardData.progress_graph.full_length.attempted == fullLengthPercent.seventyfive ||
											parseInt(dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.full_length && dashboardData.progress_graph.full_length.attempted) == parseInt(fullLengthTotal))&& (
											<div>
												<Card className="smart-card glance-card">
													<small className="text-uppercase dash-title">{constants.MilestoneFulllengthHeader}</small>
													<Row gutter={[0, 18]} className="w-100">
														{
															parseInt(fullLengthTotal) == parseInt(dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.full_length && dashboardData.progress_graph.full_length.attempted) ?
																<><Col flex='144px'>
																	<Progress
																		type="circle"
																		strokeColor={constants.Fulllength}
																		percent={100}
																		className='olive-tick'
																	/>
																</Col>
																<Col flex='190'>
																	<Text>{constants.OptionPercentFullLength[100]}</Text>
																</Col></>:
																dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.full_length && dashboardData.progress_graph.full_length.attempted == fullLengthPercent.seventyfive ?
																	<><Col flex='144px'>
																		<Progress
																			type="circle"
																			strokeColor={constants.Fulllength}
																			percent={75}
																			className='olive-tick'
																		/>
																	</Col>
																	<Col flex='190'>
																		<Text>{constants.OptionPercentFullLength[75]}</Text>
																	</Col></>:
																	dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.full_length && dashboardData.progress_graph.full_length.attempted == fullLengthPercent.fifty? 
																		<><Col flex='144px'>
																			<Progress
																				type="circle"
																				strokeColor={constants.Fulllength}
																				percent={50}
																				className='olive-tick'
																			/>
																		</Col>
																		<Col flex='190'>
																			<Text>{constants.OptionPercentFullLength[50]}</Text>
																		</Col></>:
																		dashboardData && dashboardData.progress_graph && dashboardData.progress_graph.full_length && dashboardData.progress_graph.full_length.attempted == fullLengthPercent.twentyfive ?
																			<><Col flex='144px'>
																				<Progress
																					type="circle"
																					strokeColor={constants.Fulllength}
																					percent={25}
																					className='olive-tick'
																				/>
																			</Col>
																			<Col flex='190'>
																				<Text>{constants.OptionPercentFullLength[25]}</Text>
																			</Col></>:
																			<div></div>
														}
													</Row>
												</Card>
											</div>
										)
									}
								</Carousel>
								{/* {disableCarouselButton? */}
								<Button onClick={handleNext} type="link" icon={disableCarouselButton?<RightOutlined/>:null} size='large'/>
								{/* :null} */}
							</div>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Button className="sky-bg2 text-uppercase text-left sm-text-btn text-light" /*onClick={showFriendModal}*/ onClick={()=>{	history.push("/instructions");}} block size='large'><EditOutlined className="icon-md"/><span>{constants.TakeTest}</span></Button>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Button onClick={()=>{startQuestionOfDay();}} className="orange-bg text-uppercase text-left sm-text-btn text-light" block size='large'><QuestionCircleOutlined className="icon-md"/><span>{constants.ViewDayQuestion}</span></Button>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Button className="green-bg text-uppercase text-left sm-text-btn text-light" block size='large' onClick={()=>{history.push("/compare-score");}}><LineChartOutlined className="icon-md"/><span>{constants.ViewCompareBTN}</span></Button>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Button className="bg-secondary text-uppercase text-left sm-text-btn text-light" onClick={showFeedbackModal} block size='large'><SolutionOutlined className="icon-md"/><span>{constants.FeedbackBTN}</span></Button>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12} xl={14}>
					<Card size="small" title={<small className="dash-title">{constants.ProgressOnTests}</small>} className="progress-chart-crd dash-title">
						<Card bodyStyle={{ padding: 0 }} bordered={false}>
							<Row>
								<Col xs={24} md={8}>
									<Card.Grid hoverable={false} className="text-center w-100 p-1 font-x-small shadow-none">{constants.CategoryFPP}</Card.Grid>
								</Col>
								<Col xs={24} md={8}>
									<Card.Grid hoverable={false} className="text-center w-100 p-1 font-x-small shadow-none">{constants.CategorySAED}</Card.Grid>
								</Col>
								<Col xs={24} md={8}>
									<Card.Grid hoverable={false} className="text-center w-100 p-1 font-x-small shadow-none">{constants.CategoryPIET}</Card.Grid>
								</Col>
							</Row>
						</Card>
						<Chart
							className="progress-chart"
							chartType="BarChart"
							loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
							data={progressGraphData&&progressGraphData.length>0?progressGraphData:[]}
							options={options}
						/>
					</Card>
				</Col>
				{/* {
					dashboardData&&dashboardData.other&&dashboardData.other.streak&&dashboardData.other.streak&&( */}
				<Col xs={24} sm={24} md={24} lg={14} xl={14} className="streak-middle">
					<div className="streak w-100">
						<Space>
							<FireFilled className="lit"/>
							<div className={
								dashboardData&&
											dashboardData.other&&
											dashboardData.other.streak&&dashboardData.other.streak.streak&&
									dashboardData.other.streak.streak>=100? "text-center text-light streak-days int-3" :
									"text-center text-light streak-days" }
							>
								<p className="day-count m-0">{dashboardData&&
											dashboardData.other&&
											dashboardData.other.streak&&
											dashboardData.other.streak.streak}</p>
								{dashboardData&&
											dashboardData.other&&
											dashboardData.other.streak&&dashboardData.other.streak.streak?
									dashboardData.other.streak.streak===1?
										<small className="text-uppercase">day</small>
										:<small className="text-uppercase">days</small>
									:<small className="text-uppercase">days</small>}
							</div>
							<span className="dark-tint">
								<span dangerouslySetInnerHTML={{ 
									__html: dashboardData&&
										dashboardData.other&&
										dashboardData.other.streak&& 
										dashboardData.other.streak.message
								}}/>
							</span>
							{/* <span className="dark-tint">
								{dashboardData&&
										dashboardData.other&&
										dashboardData.other.streak&&
										dashboardData.other.streak!==0?"You’re on a " + dashboardData.other.streak + " day Streak": ""}</span>
							<span>{dashboardData&&
										dashboardData.other&&
										dashboardData.other.streak!==null&&
										dashboardData.other.streak===0?constants.BeginLearning:<small className="text-mute2">{constants.Great}</small>}</span> */}
						</Space>
					</div>
				</Col>
				{/* )
				} */}
				<Col xs={24} sm={24} md={24} lg={10} xl={10}>
					<Row>
						{/* Display the badge */}
						<Col flex='110px' className="place-center">
							{getBadgeIcon(dashboardData&&
							dashboardData.level&&
							dashboardData.level.current_level?
								dashboardData.level.current_level.level
								:0,"hex-lg","score-icon")}</Col>
						<Col flex='auto' className="py-4">
							<div className="text-uppercase badges-pts font-18">
								<span>{dashboardData&&
									dashboardData.level&&
									dashboardData.level.current_level&&
									dashboardData.level.current_level.designation?
									dashboardData.level.current_level.designation
									:""}</span>
								<Text>
									<b>{dashboardData&&
										dashboardData.other&&
										dashboardData.other.points?
										dashboardData.other.points
										:0} </b>
									<span className="text-mute2">
										{" "}points
									</span>
								</Text>
							</div>
							<Progress
								strokeColor={{
									from: 'var(--purple5)',
									to: 'var(--purple3)',
								}}
								percent={calculatePercentage(dashboardData&&
									dashboardData.my_test&&
									dashboardData.my_test.completed?
									dashboardData.my_test.completed:0,dashboardData&&
									dashboardData.my_test&&
									dashboardData.my_test.total?
									dashboardData.my_test.total:0)}
								status="active"
							/>
							<div className="badges-pts font-14">
								<span>{constants.Levels[dashboardData&&
								dashboardData.level&&
								dashboardData.level.current_level&&
								dashboardData.level.current_level.level]}
								</span>
								<span className="text-mute2">
									{dashboardData&&dashboardData.level&&dashboardData.level.next_level&&dashboardData.level.current_level.level>=1?
										dashboardData&&dashboardData.level&&dashboardData.level.next_level&&dashboardData.level.current_level.level>=5?
											constants.Level6Message:
											dashboardData.level.points_required+
										constants.ReachLevelText+
										dashboardData.level.next_level.level+", "+dashboardData.level.next_level.designation:
										dashboardData&&dashboardData.level&&constants.ReachLevel0+", "+dashboardData.level.next_level.designation}
								</span>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col span={24} style={{ visibility:"hidden" }}>
					<b>QOD Date and Time: </b>{dashboardData&&dashboardData.current_date_time?dashboardData.current_date_time:""}
				</Col>
			</Row>
			{/* Refer a friend modal */}
			<Modal title={<small><b>{constants.ReferFrdHeader}</b></small>} centered visible={isFriendModalVisible} onCancel={handleFriendCancel}
				footer={null}
			>
				<div className="purple-bg p-1 mb-24">
					<Row gutter={[16, 0]}>
						<Col span={2} className="place-center"><GiftOutlined className="icon-lg"/></Col>
						<Col span={22}>
							<small>{constants.ReferFrdNote}</small>
						</Col>
					</Row>
				</div>
				<Form form={referFriendForm} name="friend_list" autoComplete="off" layout="vertical" requiredMark={false} onFinish={onSubmitReferFriend}>
					<Row gutter={[8, 0]}>
						<Col span={11}>
							<Form.Item className="label-default"
								label={<Space><span>{constants.FrdName}</span><Text strong type="danger">*</Text></Space>}
								name="name1"
								rules={[
									{
										required: true,
										message: 'Enter Name',
									},
									{
										pattern: /^[A-Za-z]+((\s)?((.)?([A-Za-z])+))*$/,
										message:
									'Invalid Name',
									},
								]}
							>
								<Input placeholder="Name"/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item className="label-default"
								name="email1"
								label={<Space><span>{constants.FrdEmail}</span><Text strong type="danger">*</Text></Space>}
								rules={[
									{
										type: 'email',
										message: 'Enter a valid email!',
									},
									{
										required: true,
										message: 'Enter E-mail!',
									},
								]}
							>
								<Input placeholder={constants.Email}/>
							</Form.Item>
						</Col>
						<Col span={2} className="place-center"></Col>
					</Row>
					<Form.List name="users">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, fieldKey, ...restField }) => (
									<Row gutter={[8, 0]} key={key}>
										<Col span={11}>
											<Form.Item
												{...restField}
												name={[name, "friend_name"]}
												fieldKey={[fieldKey, "name"]}
												rules={[{
													required: true,
													message: "Enter Name" 
												},
												{
													pattern: /^[A-Za-z]+((\s)?((.)?([A-Za-z])+))*$/,
													message:
												'Invalid Name',
												},
												]}
											>
												<Input placeholder="Name"/>
											</Form.Item>
										</Col>
										<Col span={11}>
											<Form.Item
												{...restField}
												name={[name, "friend_email"]}
												fieldKey={[fieldKey, "email"]}
												rules={[
													{
														type: 'email',
														message: 'Enter a valid email!',
													},
													{
														required: true,
														message: 'Enter E-mail!',
													},
												]}
											>
												<Input placeholder="Email"/>
											</Form.Item>
										</Col>
										<Col span={2} className="icon-md text-mute2 text-center"><MinusCircleOutlined onClick={() => remove(name)}/></Col>
									</Row>
								))}
								<Form.Item>
									<Button type="dashed" onClick={() => add()} block icon={<UserAddOutlined/>}>
										Invite another
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
					<div className='text-right mt-3'>
						<Form.Item className='mb-0'>
							<Space>
								<Button key="back" onClick={handleReferfriendClear}>
									{constants.CancelBTN}
								</Button>
								<Button key="submit" type="primary" className="navy-btn" htmlType="submit">
									{constants.SubmitBTN}
								</Button>
							</Space>
						</Form.Item>
					</div>
				</Form>
			</Modal>
			{/* Feedback modal */}
			<Modal footer={false} title={<b>{constants.FeedbackHeader}</b>} centered visible={isFeedbackModalVisible} onCancel={handleFeedbackCancel}
			>
				<Spin spinning={loader} size="large" indicator={<Loader/>}>
					<Form name="feedback_form" form={form} autoComplete="off" layout="vertical" className="feedback-form" onFinish={onFinish}>
						{checkboxContainer ?
							<>
								<Form.Item className="label-default"
									label={constants.FeedbackQ1}
									name="hearabtbook"
									rules={[
										{
											required: true,
											message: constants.FeedbackOptionError,
										},
									]}
								>
									<div className="pl-3">
										<Radio.Group onChange={onChange} 
										// value={value}
										>
											<Row gutter={[8, 8]}>
												<Col xs={24} lg={12} className="pl-0"><Radio value={0}>{constants.HearAbtBookOption1}</Radio></Col>
												<Col xs={24} lg={12} className="pl-0"><Radio value={3}>{constants.HearAbtBookOption2}</Radio></Col>
												<Col xs={24} lg={12} className="pl-0"><Radio value={1}>{constants.HearAbtBookOption3}</Radio></Col>
												<Col xs={24} lg={12} className="pl-0"><Radio value={4}>{constants.HearAbtBookOption4}</Radio></Col>
												<Col xs={24} lg={12} className="pl-0"><Radio value={2}>{constants.HearAbtBookOption5}</Radio></Col>
												<Col xs={24} lg={12} className="pl-0"><Radio value={5}>{constants.HearAbtBookOption6}</Radio></Col>
												<Col xs={24} lg={12} className="pl-0"><Radio value={6}>{constants.HearAbtBookOption8}</Radio></Col>
												<Col xs={24} lg={12} className="pl-0"><Radio value={7}>{constants.HearAbtBookOption9}</Radio></Col>
												<Col xs={24} lg={12} className="pl-0"><Radio value={8}>{constants.HearAbtBookOption7}</Radio></Col>
											</Row>
										</Radio.Group>
									</div>
								</Form.Item>
								{otherMessage ?
									<Form.Item  className="mt-neg-20px pl-3" name="otherFeedback" 
										rules={[
											{
												required: true,
												message: "",
											},
											{ validator: handleOtherFeedBackValidation },
										]}>
										<Input className="w-100"  
										// onChange={otherOnChange} 
										// value={otherValue}
										/>
									</Form.Item>
									: null
								}
								<Form.Item className="label-default"
									label={constants.FeedbackQ2}
									name="testrating"
									rules={[
										{
											required: true,
											message: constants.FeedbackOptionError,
										},
									]}
								>
									<div className="pl-3">
										<Radio.Group 
										// onChange={onChangeTestStatus} 
										// value={testStatusValue}
										>
											<Radio value={0}>{constants.TestRatingAgree}</Radio>
											<Radio value={1}>{constants.TestRatingUndecided}</Radio>
											<Radio value={2}>{constants.TestRatingDisagree}</Radio>
										</Radio.Group>
									</div>
								</Form.Item>
								<Form.Item className="label-default"
									label={constants.FeedbackQ3}
									name="productrating"
								>
									<div className="pl-3">
										<Radio.Group 
										// onChange={onChangeProductRating} 
										// value={productRatingValue}
										>
											<Radio value={0}>{constants.ProductRatingExcellent}</Radio>
											<Radio value={1}>{constants.ProductRatingVeryGood}</Radio>
											<Radio value={2}>{constants.ProductRatingGood}</Radio>
											<Radio value={3}>{constants.ProductRatingFair}</Radio>
										</Radio.Group>
									</div>
								</Form.Item>
							</>
							:""
						}
						<Form.Item name="feedbackcomment" 
							className=""
							rules={[
								{
									required: !checkboxContainer,
									message: "",
								},
								{ validator: !checkboxContainer?handleValidateFeedbackComment:null },
							]}
						>
							<TextArea placeholder="Comments..." allowClear 
							// value={feedbackCommentValue}
								// onChange={onFeedbackChange}
							/>
						</Form.Item>
						<Row justify="end" className="button-container">
							<Button
								key="submit"
								type="primary" 
								className="navy-btn mt-2" 
								htmlType="submit"  
							>
							Submit
							</Button>
							<Button onClick={handleFeedbackCancel} className="mt-2" >
								{constants.CancelBTN}
							</Button>
						</Row>
					</Form>
				</Spin>
			</Modal>
			{/* Gift modal */}
			<Modal visible={isGiftModalVisible} onCancel={handleGiftCancel} footer={null} closeIcon={<Text type="danger"><CloseCircleFilled className="icon-md"/></Text>}>
				<div className="coupon">
					<div className="px-2 pt-2">
						<h1>Pro-Ed Discount Code</h1>
						<Paragraph>Get <Text mark>30% OFF</Text> on your next purchase of <strong>An Advanced Review of Speech-Language Pathology</strong> using the below code.</Paragraph>
					</div>
					<div className="promo-container p-2">
						<Paragraph className="font-18 promo" copyable>BOH232BOH232</Paragraph>
						<Text type="danger">Expires: Jan 03, 2021</Text>
					</div>
				</div>
			</Modal>
			{dashboardData&&
			dashboardData.other&&
			dashboardData.other.is_qod_answered!==null&&
			dashboardData.other.is_qod_answered===0&&(
				<Modal visible={visibleQod} footer={false} onCancel={()=>handleCancel("1")} width={430}>
					<div className="status-container text-center">
						<QuestionCircleOutlined className="navy icon-xlg"/>
						<h1 className="status-title">{constants.ViewDayQuestion}</h1>
						<div className="status-content">
							<p>{constants.QODPopupText}</p>
							<p>{constants.QODPopupText2}</p>
							<p><span>Click </span>
								<Button className="btnDb" onClick={()=>{handleCancel("0");startQuestionOfDay();}}>
									OK
								</Button>
								<span> to answer.</span>
							</p>
						</div>
					</div>
				</Modal>)}
		</div>
	);
};
export default Dashboard;
