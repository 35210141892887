import React, { useState, useEffect } from "react";
import { Row, Col, BackTop, Card, Table, Tooltip, Space , Avatar  } from 'antd';
import { InfoCircleOutlined,  } from '@ant-design/icons';
import { fetchApi } from "../../services/api";
import constants from '../../constants/constants';
// import { useHistory } from "react-router";
import '../../styles/test.css';
const CompareScoreViewMore = () => {
	// const history = useHistory();
	const [latestScore, setLatestScore] = useState([]);
	useEffect(() => {
		fetchLatestScores();
	}, []);
	// To Fetch the data for test taken by the user
	const fetchLatestScores = () => {
		fetchApi(`/student/latest-top-ten-score?show_more=1`, "get").then((res) => {
			if (res && res.code === 200) {
				let tempMark=null;
				let count=0;
				res.data.map((item)=>{
					if(item.marks_scored===tempMark){
						item.srNum=count;
					}else{
						item.srNum=count+1;
						count=count+1;
					}
					tempMark=item.marks_scored;
				});
				setLatestScore( res.data );
			}else{
				setLatestScore([]);
			}
		});
	};
	// Top ten table
	const topTenColumns = [
		{
			title: '#',
			key: 'srNum',
			dataIndex:'srNum',
			align:'center',
			className: 'td-right',
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				const x = record.name;
				const myArray = x.split(" ");
				const initials = x.substring(0, 1).toUpperCase();
				const y = myArray[1];
				const initial2 = y.substring(0, 1).toUpperCase();
				return (
					<>
						<Space size="middle">
							<Avatar className="avatar">{initials}{initial2}</Avatar>{/* Don't call "avatar" class if expired sub user */}
							{/* <span>{record&&record.name? myArray[0].charAt(0).toUpperCase()+ myArray[0].slice(1) + "," + " " + initial2:"-"}</span> */}
							<span>{record&&record.name?record.name:"-"}</span>
						</Space>
					</>
				);
			}
		},
		{
			title: 'State',
			key: 'state',
			render: (record) => {
				return (
					<span>{record&&record.state?record.state:"-"}</span>
				);
			}
		},
		{
			title: 'Date',
			key: 'date',
			render: (record) => {
				return (
					<span>{record&&record.date ? (<span>{record.date}</span>) : ("-")}</span>
				);
			}
		},
		{
			title: 'Score',
			key: 'score',
			align:'center',
			className: 'td-right',
			render: (record) => {
				return (
					<span>{record&&record.marks_scored?record.marks_scored + "/" + "200":"-"}</span>
				);
			}
		},
	];
	return (
		<div className="p-4">
			<BackTop/>
			<Row gutter={[24, 24]}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title={<span className="text-uppercase">{constants.TopHundred}</span>} extra={<Space>
						{/* <a onClick={()=>history.push("/compare-score")}>Back to top 10</a> */}
						<Tooltip placement="left" title={constants.compareTestsInfoToolTip}><InfoCircleOutlined/></Tooltip></Space>}>
						<Table className="gap-table" columns={topTenColumns} 
							dataSource={latestScore}
							pagination={false}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default CompareScoreViewMore;
