import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { message } from "antd";
import { fetchApi } from "../../../services/api";
import constants from "../../../constants/constants";
// import { useHistory } from "react-router";
const useUnsavedChangesWarning = () => {
	// const history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const [isDirty, setDirty] = useState(false);
	useEffect(() => {
		window.addEventListener('beforeunload', alertUser);
		window.addEventListener('unload', handleTestStatus);
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			window.removeEventListener('unload', handleTestStatus);
			handleTestStatus();
		};
	}, []);
	const alertUser = e => {
		e.preventDefault();
		e.returnValue = '';
	};
	const handleTestStatus = async () => {
		let quit=sessionStorage.getItem("quitTest");
		let queryString=window.location.href;
		if(quit==="false"&&!queryString.includes("resume-test")&&!queryString.includes("short-length-test")&&!queryString.includes("retake-test")&&!queryString.includes("full-length-test")){
			sessionStorage.setItem("crash","true");
			let scoreCardId=Number(sessionStorage.getItem("scoreCardId"));
			let payload = {};
			let listQuestions=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
			let questionIndex=Number(sessionStorage.getItem("questionIndex"));
			let categoryDetails=JSON.parse(sessionStorage.getItem("categoryDetails")||"null");
			let topicDetails=JSON.parse(sessionStorage.getItem("topicDetails")||"null");
			// let markedForLaterQuestions=JSON.parse(sessionStorage.getItem("markedForLaterQuestions") || "[]");
			let fullLengthDetails=JSON.parse(sessionStorage.getItem("fullLengthDetails")||"{}");
			let markForLaterAndAnswerIncorrectDetails=JSON.parse(sessionStorage.getItem("markForLaterAndAnswerIncorrectDetails")||"{}");
			let time=sessionStorage.getItem("timeTaken");
			let url=``;
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();
			today = yyyy + '-'+ mm + '-' + dd;
			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
				if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"){
					var sequence=questionIndex+1;
					payload.test_data={
						seq:sequence,
						q_id:Number(listQuestions[questionIndex].question_id),
						user_ans:listQuestions[questionIndex].selectedAnswer,
						corr_ans:listQuestions[questionIndex].correct_option,
						marked_for_later:listQuestions[questionIndex].markedForLater,
						// answered_on:today
					};
				}else if(listQuestions[questionIndex].q_type==="4"){
					let answer=[];
					for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
						answer.push(listQuestions[questionIndex].selectedAnswer[i].index);
					}
					var seq=questionIndex+1;
					payload.test_data={
						seq:seq,
						q_id:Number(listQuestions[questionIndex].question_id),
						user_ans:answer,
						corr_ans:listQuestions[questionIndex].correct_option,
						marked_for_later:listQuestions[questionIndex].markedForLater,
						// answered_on:today
					};
				}else if(listQuestions[questionIndex].q_type==="5"){
					let answer=[];
					for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
						answer.push(Number(listQuestions[questionIndex].selectedAnswer[i].id));
					}
					var sequ=questionIndex+1;
					payload.test_data={
						seq:sequ,
						q_id:Number(listQuestions[questionIndex].question_id),
						user_ans:answer,
						corr_ans:listQuestions[questionIndex].correct_option,
						marked_for_later:listQuestions[questionIndex].markedForLater,
						// answered_on:today
					};
				}
				if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
					url+=`/student/tutorials/update`;
					payload.scorecard_id=scoreCardId;
					payload.question_id=Number(listQuestions[questionIndex].question_id);
					payload.test_status=1;
					if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
						if(listQuestions[questionIndex].matchAns===true){
							payload.type="ans_incorrect";
							payload.is_answered=true;
						}else{
							payload.type="ans_incorrect";
							payload.is_answered=false;
						}
					}else{
						payload.type="marked_for_later";
						payload.is_answered=true;
					}
				}else{
					url+=`/score_card/${scoreCardId}`;
					if (categoryDetails &&
						categoryDetails.type &&
						categoryDetails.type==="normal" ||
						topicDetails && topicDetails.type &&
						topicDetails.type==="normal") {
						payload.total_time_taken = time;
						payload.test_status = 2;
					}else if(fullLengthDetails&&fullLengthDetails.isTimed!==null&&fullLengthDetails.isTimed===true){
						payload.test_status=1;
						payload.total_time_taken=time;
						const date= new Date();
						payload.dt_completed=date;
					}else{
						payload.test_status=1;
						const date= new Date();
						payload.dt_completed=date;
					}
				}
				let totalOptions=null;
				let optionNumver={};
				if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"||listQuestions[questionIndex].q_type==="4"){
					totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt):0;
					optionNumver=constants.OptionNumbers;
				}else if(listQuestions[questionIndex].q_type==="5"){
					optionNumver=constants.OptionNumberDragAndDrop;
					totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt.charAt(2)):0;
				}
				let strikedOptions=[];
				let highlightedOptions=[];
				for (let j = 0; j < totalOptions; j++) {
					let op=optionNumver[j+1];
					if(listQuestions[questionIndex][op].includes("<strike>")){
						strikedOptions.push(j+1);
					}
					if(listQuestions[questionIndex][op].includes("highlighted")){
						highlightedOptions.push({ 
							opt:j+1,
							data:listQuestions[questionIndex][op] 
						});
					}
				}
				if(listQuestions[questionIndex].question_text&&listQuestions[questionIndex].question_text.includes("highlighted")){
					highlightedOptions.push({
						opt:99,
						data:listQuestions[questionIndex].question_text,
					});
				}
				if(strikedOptions&&strikedOptions.length>0||highlightedOptions&&highlightedOptions.length>0){
					payload.highlight_strike_data={
						q_id:Number(listQuestions[questionIndex].question_id),
						strike:strikedOptions,
						highlight:highlightedOptions&&highlightedOptions.length>0?highlightedOptions:[],
					};
				}
			}else{
				if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
					url+=`/student/tutorials/update`;
					payload.scorecard_id=scoreCardId;
					payload.question_id=Number(listQuestions[questionIndex].question_id);
					payload.test_status=1;
					if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
						if(listQuestions[questionIndex].matchAns===true){
							payload.type="ans_incorrect";
							payload.is_answered=true;
						}else{
							payload.type="ans_incorrect";
							payload.is_answered=false;
						}
					}else{
						payload.type="marked_for_later";
						payload.is_answered=true;
					}
				}else{
					url+=`/score_card/${scoreCardId}`;
					if (categoryDetails &&
						categoryDetails.type &&
						categoryDetails.type==="normal" ||
						topicDetails && topicDetails.type &&
						topicDetails.type==="normal") {
						payload.total_time_taken = time;
						payload.test_status = 2;
					}else if(fullLengthDetails&&fullLengthDetails.isTimed!==null&&fullLengthDetails.isTimed===true){
						payload.total_time_taken=time;
						const date= new Date();
						payload.dt_completed=date;
						payload.test_status=1;
						payload.is_autoclose=true;
					}else{
						payload.test_status=1;
						const date= new Date();
						payload.dt_completed=date;
					}
				}
			}
			let markedForLaterQuestionsList=[];
			for (let index = 0; index < listQuestions.length; index++) {
				if(listQuestions&&listQuestions[index].markedForLater===true){
					markedForLaterQuestionsList.push(listQuestions[index].question_id);
				}
			}
			sessionStorage.setItem("markedForLaterQuestions", JSON.stringify(markedForLaterQuestionsList));
			if(markedForLaterQuestionsList&&markedForLaterQuestionsList.length>0){
				payload.marked_for_later=markedForLaterQuestionsList;
			}else{
				payload.marked_for_later=[];
			}
			if(scoreCardId!==0){
				fetchApi(url, "put", payload)
					.then((res) => {
						if (res && res.code && res.code === 200) {
							console.log("paused");
						} else {
							message.error("Failed");
						}
					});
			}
		}
	};
	const routerPrompt = <Prompt when={isDirty} message={sessionStorage.getItem("testType")==="shortLength"?constants.unSavedChangesMessage:sessionStorage.getItem("testType")==="fullLength"?constants.unSavedChangesFullLengthMessage:constants.unSavedChangeTutorialMessage}/>;
	return [routerPrompt, () => setDirty(true), () => setDirty(false)];
};
export default useUnsavedChangesWarning;
