import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import "../../../styles/totalregistration.css";
import { fetchAdminApi } from "../../../services/api";
import { Breadcrumb,Table, Button, Space, Tooltip, Card, Spin } from 'antd';
import { useHistory } from "react-router";
import constants from '../../../constants/constants';
import Loader from '../../Common/Cred/Loader';
const TestListing = () => {
	const history = useHistory();
	const [tableData, setTableData] = useState([]);
	const [topicWiseTableData, setTopicWiseTableData] = useState([]);
	const [loader,setLoader]=useState(false);
	useEffect(() => {
		fetchTestList();
		fetchTopicwiseList();
	}, []);
	useEffect(()=>{
		// return () => {
		// 	if (history.action === "POP") {
		// 		history.push("/admin-dashboard");
		// 	}
		// };
	},[history]);
	// To fetch the data of test-listing type-category to display on test-list table
	const fetchTestList = () => {
		setLoader(true);
		fetchAdminApi("/test-listing?type=category", "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					setTableData(res.data);
					setLoader(false);
				}
				else {
					setTableData([]);
					setLoader(false);
				}
			}else{
				setLoader(false);
			}
		});
	};
	// To fetch the data of test-listing type-topic to display on topic-wise table.
	const fetchTopicwiseList = () => {
		fetchAdminApi("/test-listing?type=topic", "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					let lastElement = res.data[res.data.length - 1];
					let d = res.data.slice(0,22).sort((a, b) => {
						return a.code.localeCompare(b.code);
					});
					d.push(lastElement);
					setTopicWiseTableData(d);
				}
				else {
					setTableData([]);
				}
			}
		});
	};
	const routeToQuestionListing = (data) =>{
		history.push({
			pathname: "/question-list",
			state: data
		});
	};
	const testColumnFeild = (record,otherValues) =>{
		return <Space>
			<Tooltip title={otherValues.title}><a onClick={() => {
				let data = {};
				if(otherValues.statusId){
					data.testId = record.test_id;
					data.statusId = otherValues.statusId;
				}else if(otherValues.categoryId){
					data.testId = record.test_id;
					data.categoryId = otherValues.categoryId;
				}else{
					data.testId = record.test_id;
				}
				routeToQuestionListing(data);
			}}>{otherValues.statusValue}</a></Tooltip>
		</Space>;
	};
	// test-list table
	const columns = [
		{
			title: 'Test #',
			key: 'test',
			dataIndex: 'test',
			width : 150
		},
		{
			title: '',
			key: 'button',
			align: 'center',
			render: (record) => {
				return (
					<Space>
						<Tooltip title={`Click here to view questions in ${record.test}`}><Button onClick={() => {
							let data = {};
							data.testId = record.test_id;
							routeToQuestionListing(data);
						}} style={{
							backgroundColor: "blue",
							fontWeight: "bold",
							width: "100px"
						}} size="large" type="primary" block>Q</Button></Tooltip>
					</Space>
				);
			},
		},
		{
			title: 'Total',
			key: 'questions',
			render: (record) => {
				let otherValues = {
					title:`Total Questions Under ${record.test}`,
					statusValue:record.total_questions,
				};
				return (
					testColumnFeild(record,otherValues)
				);
			},
			align: 'right',
			width: 150,
		},
		{
			title: 'Verified',
			key: 'verified',
			render: (record) => {
				let otherValues = {
					title:`Total Verified Questions Under ${record.test}`,
					statusId:constants.TestListing.verified,
					statusValue:record.verified,
				};
				return (
					testColumnFeild(record,otherValues)
				);
			},
			align: 'right',
			width : 150
		},
		{
			title: 'To be verified',
			key: 'tobeverified',
			render: (record) => {
				let otherValues = {
					title:`Total Not Verified Questions Under ${record.test}`,
					statusId:constants.TestListing.to_be_verified,
					statusValue:record.to_be_verified,
				};
				return (
					testColumnFeild(record,otherValues)
				);
			},
			align: 'right',
			width : 150
		},
		{
			title: 'In progress',
			key: 'inprogress',
			render: (record) => {
				let otherValues = {
					title:`Total In progress Questions Under ${record.test}`,
					statusId:constants.TestListing.in_progress,
					statusValue:record.in_progress,
				};
				return( testColumnFeild(record,otherValues));
			},
			align: 'right',
			width : 150
		},
		{
			title: 'Rejected',
			key: 'rejected',
			render: (record) => {
				let otherValues = {
					title:`Total Rejected Questions Under ${record.test}`,
					statusId:constants.TestListing.rejected,
					statusValue:record.rejected,
				};
				return( testColumnFeild(record,otherValues));
			},
			align: 'right',
			width : 150
		},
		{
			title: 'FPP',
			key: 'fpp',
			render: (record) => {
				let otherValues = {
					title:`Total Questions in ${record.test} Under FPP Category`,
					categoryId:constants.TestListing.category_id_fpp,
					statusValue:record.fpp,
				};
				return( testColumnFeild(record,otherValues));
			},
			align: 'right',
			width : 150
		},
		{
			title: 'PIET',
			key: 'piet',
			render: (record) => {
				let otherValues = {
					title:`Total Questions in ${record.test} Under PIET Category`,
					categoryId:constants.TestListing.category_id_piet,
					statusValue:record.piet,
				};
				return( testColumnFeild(record,otherValues));
			},
			align: 'right',
			width : 150
		},
		{
			title: 'SAED',
			key: 'saed',
			render: (record) => {
				let otherValues = {
					title:`Total Questions in ${record.test} Under SAED Category`,
					categoryId:constants.TestListing.category_id_saed,
					statusValue:record.saed,
				};
				return( testColumnFeild(record,otherValues));
			},
			align: 'right',
			width : 150
		},
	];
	const topicColumnFields = (otherData) =>{
		return <a onClick={() => {
			let data = {};
			data.topicId = otherData.topicId;
			data.categoryId = otherData.categoryId;
			data.testId = otherData.testId;
			data.test = otherData.test;
			routeToQuestionListing(data);
		}}>{otherData.fieldStatus}</a>;
	};
	// topic-wise table
	const columns2 = [
		{
			title: 'Topic',
			key: 'code',
			render: (record) => (
				<Space>
					<Tooltip title={record.topic_title}>{record.code}</Tooltip>
				</Space>
			),
			width: 50,
		},
		{
			title: 'FPP',
			children: [
				{
					title: 'T1',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.fpp,
							testId:constants.TopicWiseListing.test_id.t1,
							fieldStatus:record.t1_fpp
						};
						return topicColumnFields(otherData);
					},
					key: 't1',
					width: '2%'
				},
				{
					title: 'T2',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.fpp,
							testId:constants.TopicWiseListing.test_id.t2,
							fieldStatus:record.t2_fpp
						};
						return topicColumnFields(otherData);
					},
					key: 't2',
					width: '2%'
				},
				{
					title: 'T3',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.fpp,
							testId:constants.TopicWiseListing.test_id.t3,
							fieldStatus:record.t3_fpp
						};
						return topicColumnFields(otherData);
					},
					key: 't3',
					width: '2%'
				},
				{
					title: 'T4',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.fpp,
							testId:constants.TopicWiseListing.test_id.t4,
							fieldStatus:record.t4_fpp
						};
						return topicColumnFields(otherData);
					},
					key: 't4',
					width: '2%'
				},
				{
					title: 'T5',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.fpp,
							testId:constants.TopicWiseListing.test_id.t5,
							fieldStatus:record.t5_fpp
						};
						return topicColumnFields(otherData);
					},
					key: 't5',
					width: '2%'
				},
				{
					title: 'T6',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.fpp,
							testId:constants.TopicWiseListing.test_id.t6,
							fieldStatus:record.t6_fpp
						};
						return topicColumnFields(otherData);
					},
					key: 't6',
					width: '2%'
				},
				{
					title: 'T7',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.fpp,
							testId:constants.TopicWiseListing.test_id.t7,
							fieldStatus:record.t7_fpp
						};
						return topicColumnFields(otherData);
					},
					key: 't7',
					width: '2%'
				},
				{
					title: 'T8',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.fpp,
							testId:constants.TopicWiseListing.test_id.t8,
							fieldStatus:record.t8_fpp
						};
						return topicColumnFields(otherData);
					},
					key: 't8',
					width: '2%'
				},
				{
					title: 'Total',
					key: 'total',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.fpp,
							fieldStatus:record.total_fpp,
							test:0
						};
						return topicColumnFields(otherData);
					},
					width: '2%'
				},
			],
		},
		{
			title: 'PIET',
			children: [
				{
					title: 'T1',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.piet,
							testId:constants.TopicWiseListing.test_id.t1,
							fieldStatus:record.t1_piet
						};
						return topicColumnFields(otherData);
					},
					key: 't1',
					width: '2%'
				},
				{
					title: 'T2',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.piet,
							testId:constants.TopicWiseListing.test_id.t2,
							fieldStatus:record.t2_piet
						};
						return topicColumnFields(otherData);
					},
					key: 't2',
					width: '2%'
				},
				{
					title: 'T3',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.piet,
							testId:constants.TopicWiseListing.test_id.t3,
							fieldStatus:record.t3_piet
						};
						return topicColumnFields(otherData);
					},
					key: 't3',
					width: '2%'
				},
				{
					title: 'T4',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.piet,
							testId:constants.TopicWiseListing.test_id.t4,
							fieldStatus:record.t4_piet
						};
						return topicColumnFields(otherData);
					},
					key: 't4',
					width: '2%'
				},
				{
					title: 'T5',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.piet,
							testId:constants.TopicWiseListing.test_id.t5,
							fieldStatus:record.t5_piet
						};
						return topicColumnFields(otherData);
					},
					key: 't5',
					width: '2%'
				},
				{
					title: 'T6',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.piet,
							testId:constants.TopicWiseListing.test_id.t6,
							fieldStatus:record.t6_piet
						};
						return topicColumnFields(otherData);
					},
					key: 't6',
					width: '2%'
				},
				{
					title: 'T7',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.piet,
							testId:constants.TopicWiseListing.test_id.t7,
							fieldStatus:record.t7_piet
						};
						return topicColumnFields(otherData);
					},
					key: 't7',
					width: '2%'
				},
				{
					title: 'T8',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.piet,
							testId:constants.TopicWiseListing.test_id.t8,
							fieldStatus:record.t8_piet
						};
						return topicColumnFields(otherData);
					},
					key: 't8',
					width: '2%'
				},
				{
					title: 'Total',
					key: 'total',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.piet,
							fieldStatus:record.total_piet,
							test:0
						};
						return topicColumnFields(otherData);
					},
					width: '2%'
				},
			],
		},
		{
			title: 'SAED',
			children: [
				{
					title: 'T1',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.saed,
							testId:constants.TopicWiseListing.test_id.t1,
							fieldStatus:record.t1_saed
						};
						return topicColumnFields(otherData);
					},
					key: 't1',
					width: '2%'
				},
				{
					title: 'T2',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.saed,
							testId:constants.TopicWiseListing.test_id.t2,
							fieldStatus:record.t2_saed
						};
						return topicColumnFields(otherData);
					},
					key: 't2',
					width: '2%'
				},
				{
					title: 'T3',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.saed,
							testId:constants.TopicWiseListing.test_id.t3,
							fieldStatus:record.t3_saed
						};
						return topicColumnFields(otherData);
					},
					key: 't3',
					width: '2%'
				},
				{
					title: 'T4',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.saed,
							testId:constants.TopicWiseListing.test_id.t4,
							fieldStatus:record.t4_saed
						};
						return topicColumnFields(otherData);
					},
					key: 't4',
					width: '2%'
				},
				{
					title: 'T5',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.saed,
							testId:constants.TopicWiseListing.test_id.t5,
							fieldStatus:record.t5_saed
						};
						return topicColumnFields(otherData);
					},
					key: 't5',
					width: '2%'
				},
				{
					title: 'T6',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.saed,
							testId:constants.TopicWiseListing.test_id.t6,
							fieldStatus:record.t6_saed
						};
						return topicColumnFields(otherData);
					},
					key: 't6',
					width: '2%'
				},
				{
					title: 'T7',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.saed,
							testId:constants.TopicWiseListing.test_id.t7,
							fieldStatus:record.t7_saed
						};
						return topicColumnFields(otherData);
					},
					key: 't7',
					width: '2%'
				},
				{
					title: 'T8',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.saed,
							testId:constants.TopicWiseListing.test_id.t8,
							fieldStatus:record.t8_saed
						};
						return topicColumnFields(otherData);
					},
					key: 't8',
					width: '2%'
				},
				{
					title: 'Total',
					key: 'total',
					render: (record) => {
						let otherData={
							topicId:record.topic_id,
							categoryId:constants.TopicWiseListing.category_id.saed,
							fieldStatus:record.total_saed,
							test:0
						};
						return topicColumnFields(otherData);
					},
					width: '2%'
				},
			],
		},
		{
			title: 'Total',
			key: 'total',
			render: (record) => {
				return (
					<a onClick={() => {
						let data = {};
						if(record.topic_id){
							data.topicId = record.topic_id;
							data.test=0;
						}else{
							data.topicId = "";
						}
						routeToQuestionListing(data);
					}}>{record.total}</a>
				);
			},
			width: 50,
		},
	];
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="div">
					<div className="p-3">
						<Breadcrumb>
							<Breadcrumb.Item ><a onClick={() => {
								history.push({ pathname: "/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/masters-menu" });
							}}>Masters</a></Breadcrumb.Item>
							<Breadcrumb.Item>Questions</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<div className="p-3">
						<Card size="small" title={<span className="text-uppercase">Test List</span>}>
							<Table
								locale={{ emptyText: 'No Records Found' }}
								columns={columns}
								dataSource={tableData}
								bordered
								// title={() => <h2 className="text-uppercase">Test List</h2>}
								pagination={false}
							/>
						</Card>
					</div>
					<div className="table">
						<Card size="small" title={<span className="text-uppercase">Topic-wise List</span>}>
							<Table
								locale={{ emptyText: 'No Records Found' }}
								columns={columns2}
								dataSource={topicWiseTableData}
								bordered
								// title={() => <h2>Topic-wise List</h2>}
								pagination={false}
								// scroll={{ x: 'calc(50px + 100%)' }}
							/>
						</Card>
					</div>
				</div>
			</Spin>
		</>
	);
};
export default TestListing;
