/*
File Name:PromoCode.js
Author: 
Modified On: 21/04/2022
Description: Display list of promo codes
API's used:
1) /promotion_codes_list
*/
import React, { useEffect, useState } from "react";
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Button,
	BackTop,
	Table,
	Spin,
	Form,
	Input,
	Space,
	Radio,
	Divider,
	Select,
	DatePicker,
	InputNumber,
} from "antd";
import { FilterOutlined,SearchOutlined,CloseOutlined,ReloadOutlined } from '@ant-design/icons';
import "../../../styles/admin.css";
import { fetchAdminApi,fetchApi } from "../../../services/api";
import constants from "../../../constants/constants";
import moment from "moment";
import { useHistory } from "react-router";
import Loader from "../../Common/Cred/Loader";
const { Option } = Select;
const PromoCodeList = () => {
	const [form] = Form.useForm();
	const [promocodeData,setPromocodeData]=useState([]);
	const [loader,setLoader]=useState(false);
	const [showFilter,setShowFilter]=useState(true);
	const [listCountries, setCountries] = useState([]);
	const [listStates, setStates] = useState([]);
	const [disableState,setDisableState]=useState(true);
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [recordsCount, setRecordsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize,setPagesize]=useState(25);
	const [columnSortName,setColumnSortName]=useState(null);
	const [columnSorttype,setColumnSortType]=useState(null);
	const [filterSearchValues,setFiltersearchvalues]=useState({});
	const [universityList,setUniversitiesList]=useState([]);
	const [tempUniversityList,setTempUniversityList]=useState([]);
	const history = useHistory();
	// const pagination = { showSizeChanger: true, };
	// const horizontalLayout = {
	// 	labelCol: { span: 6, },
	// 	wrapperCol: { span: 18, },
	// };
	useEffect(() => {
		fetchCountries();
		// getPromoCodeList(null,0,25);
		fetchStates();
		fetchUniversities();
	}, []);
	// 
	const topTenColumns = [
		{
			title: 'Promo Code',
			key: 'Promo_Code',
			dataIndex: 'Promo_Code',
			width: '250px',
			fixed: 'left',
			sorter: (a, b) => a.Promo_Code.localeCompare(b.Promo_Code),
		},
		{
			title: 'Student Name',
			key: 'student_name',
			dataIndex: 'student_name',
			// align: 'center',
			width: '200px',
			// className: 'td-right',
			fixed: 'left',
			sorter: (a, b) => {
				a = a.student_name || '';
				b = b.student_name || '';
				return a.localeCompare(b);
			},
			// render: (text,object) => (
			// 	<span>{text ? <a onClick={()=>redirectStudentDetails(object.stud_id)}>{text}</a> : ""}</span>
			// ),
			render: (text,object) => {
				if(object.is_active==="4"){
					// return <span>{"Stud - "+object.stud_id}</span>;
					return <span>{text ? <a onClick={()=>redirectStudentDetails(object.stud_id)}>{"Stud - "+object.stud_id}</a> : ""}</span>;
				}else{
					return <span>{text ? <a onClick={()=>redirectStudentDetails(object.stud_id)}>{text}</a> : ""}</span>;
				}
			}
		},
		{
			title: 'Country',
			key: 'Country',
			dataIndex: 'Country',
			align: 'center',
			width: 120,
			sorter: (a, b) => {
				a = a.Country || '';
				b = b.Country || '';
				return a.localeCompare(b);
			},
		},
		{
			title: 'State',
			key: 'State',
			dataIndex: 'State',
			align: 'center',
			width: 120,
			sorter: (a, b) => {
				a = a.State || '';
				b = b.State || '';
				return a.localeCompare(b);
			},
		},
		{
			title: 'University',
			key: 'University',
			dataIndex: 'University',
			width: 250,
			sorter: (a, b) => {
				a = a.University || '';
				b = b.University || '';
				return a.localeCompare(b);
			},
		},
		{
			title: 'Type',
			key: 'Type',
			dataIndex: 'Type',
			width: 100,
			sorter: (a, b) => a.Type.localeCompare(b.Type),
		},
		{
			title: 'Registered On',
			key: 'registered_on',
			dataIndex: 'registered_on',
			align: 'center',
			width: 120,
			sorter: (a, b) => new Date(a.registered_on) - new Date(b.registered_on),
			render: (text) => (
				<span>{text ? moment(text).format(constants.dateFormat) : ""}</span>
			),
		},
		{
			title: 'Valid Until',
			key: 'valid_till',
			dataIndex: 'valid_till',
			align: 'center',
			width: 120,
			sorter: (a, b) => new Date(a.valid_till) - new Date(b.valid_till),
			render: (text) => (
				<span>{text ?moment(text).format(constants.dateFormat) : ""}</span>
			),
		},
		{
			title: 'Duration',
			key: 'Duration',
			dataIndex: 'Duration',
			align: 'center',
			width: 120,
			sorter: (a, b) => a.Duration - b.Duration,
		},
		{
			title: 'Created On',
			key: 'created_on',
			dataIndex: 'created_on',
			align: 'center',
			width: 120,
			sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
			render: (text) => (
				<span>{text ? moment(text).format(constants.dateFormat) : ""}</span>
			),
		},
		{
			title: 'Created By',
			key: 'Created_By',
			dataIndex: 'Created_By',
			align: 'center',
			width: 120,
			sorter: (a, b) => {
				a = a.Created_By || '';
				b = b.Created_By || '';
				return a.localeCompare(b);
			},
		},
	];
	//promocode list
	const redirectStudentDetails=(studentId)=>{
		localStorage.setItem("studentId", studentId);
		localStorage.setItem("editRedirectLink","/promo-code-list");
		window.open(constants.BaseUrl+"/view-student", '_blank');
	};
	//To get promocode list
	const getPromoCodeList=async(payloadValues,currentPageValue,pageSizeValue,sortColumnValue=null,sortOrderValue=null)=>{
		let payload={};
		payload=payloadValues?payloadValues:{};
		// if(payloadValues===null||!payloadValues.valid){
		// 	payload.valid=1;
		// }
		if(sortColumnValue&&sortOrderValue){
			payload.sort_column =sortColumnValue;
			payload.sort=sortOrderValue;
		}else{
			delete payload['sort'];
			delete payload['sort_column'];
		}
		payload.page=currentPageValue;
		payload.limit=pageSizeValue;
		// payload={ valid:1 };
		setLoader(true);
		fetchAdminApi(`/promotion_codes_list`, "post",payload).then(
			(res) => {
				if (res && res.data) {
					setPromocodeData(res.data);
					setLoader(false);
					setRecordsCount(res.total_count);
				} else {
					setPromocodeData([]);
					setRecordsCount(0);
					setLoader(false);
				}
			}
		);
	};
	//Date validation check
	const from = (_date, dateString) => {
		setStartDate(dateString);
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
	};
	// const disabledFutureDate = (current) => {
	// 	return current && current > moment().endOf('day');
	// };
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		// const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current));
	};
	//To fetch the list of countries
	const fetchCountries = async() => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				//removed the US Country by filtering 
				let remainingArr = res.data.filter(data => data.country_id != '238');
				//Adding a US country at zero index
				remainingArr.unshift({
					country_id: "238",
					country_code: "US",
					country_name: "United States"
				});
				setCountries(remainingArr);
			} else {
				setCountries([]);
			}
		});
	};
		//To fetch the list of states
	const fetchStates = async() => {
		fetchApi(`/states?country_code=US`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setStates(res.data);
			} else {
				setStates([]);
			}
		});
	};
	//Function to fetch university list.
	const fetchUniversities = () => {
		fetchApi(`/universities?country_code=US`, "get").then(
			(res) => {
				if (res && res.data) {
					// Object.entries(res.data).forEach(([key, value]) => {
					// 	displayOtherUniversityAlaska(key,value);
					// });
					setUniversitiesList(res.data);
				} else {
					setUniversitiesList(null);
				}
			}
		);
	};
	//on filter search
	const onFinalSearch=(values)=>{
		let payloadValues={};
		if(values.promocode){
			payloadValues.promocode=values.promocode;
		}
		if(values.student){
			payloadValues.name=values.student;
		}
		if(values.country){
			payloadValues.country_id=values.country;
		}
		if(values.from){
			payloadValues.from=startDate;
		}
		if(values.to){
			payloadValues.to=endDate;
		}
		if(values.state){
			payloadValues.state_id=values.state;
		}
		if(values.duration){
			payloadValues.duration=values.duration;
		}
		if(values.status){
			payloadValues.status=values.status;
		}
		if(values.university){
			payloadValues.university=values.university;
		}
		if(values.registered&&values.from||values.registered&&values.to||(values.registered&&Object.keys(payloadValues).length==0)){
			payloadValues.valid=values.registered;
		}
		// if(values.promocode||values.status){
		// 	payloadValues.valid="2";
		// }
		// payloadValues.page=currentPage;
		// payloadValues.limit=pageSize;
		setCurrentPage(1);
		setFiltersearchvalues(payloadValues);
		getPromoCodeList(payloadValues,0,pageSize,columnSortName,columnSorttype);
	};
	//Reset form filter
	const resetFilterForm=()=>{
		//TODO: check page values after reset
		form.resetFields();
		setStartDate("");
		setEndDate("");
		setCurrentPage(1);
		setPagesize(25);
		setFiltersearchvalues({});
		setPromocodeData([]);
		setRecordsCount(0);
		// getPromoCodeList(null,0,25,columnSortName,columnSorttype);
	};
		//Executes on change of Country field
	const onChangeCountry = (_value, option) => {
		if (option && option.key !== "US") {
			setDisableState(true);
			form.setFieldsValue({ state: null });
		}else{
			setDisableState(false);
		} 
	};
	const onChangeState = (e, option) =>{
		// setState(option.key);
		form.setFieldsValue({ university:null });
		Object.entries(universityList).forEach(([key, value]) => {
			if (key === option?.children) {
				let obj={};
				obj[e]=value;
				setTempUniversityList(obj);
			}
			else if ( value === null) {
				setTempUniversityList(null);
			}	
		});
	};
	// function to return column name
	const returnCoumnName=(sortColumn)=>{
		switch (sortColumn) {
		case "Promo_Code":	
			return "p.promo_code";
		case "student_name":	
			return "s.stud_firstname";
		case "Country":	
			return "s.country";
		case "State":	
			return "s.state";
		case "University":	
			return "g.university_name";
		case "Type":	
			return "p.type";
		case "registered_on":	
			return "date(p.start_date)";
		case "valid_till":	
			return "date(p.valid_till)";
		case "Duration":	
			return "p.duration";
		case "created_on":	
			return "date(p.dt_created)";
		case "Created_By":	
			return "p.created_by_id";
		}
	};
	//Function executes on promocode list table change
	const onchangePromoCodeTable = (pagination, filters, sorter) =>{
		let currentPageValue=currentPage;
		let currentPageSize=pageSize;
		if(pagination){
			currentPageValue=pagination?.current-1;
			currentPageSize=pagination?.pageSize;
			if(pageSize!==pagination.pageSize){
				setCurrentPage(1);
			}else{
				setCurrentPage(pagination?.current);
			}
			setPagesize(pagination?.pageSize);
		}
		let sorterColumnName=null;
		let sorterType=null;
		if(sorter&&sorter.order&&sorter.columnKey){
			sorterColumnName=returnCoumnName(sorter.columnKey);
			sorterType=sorter.order==="ascend"?"asc":"desc";
		}
		setColumnSortName(sorterColumnName);
		setColumnSortType(sorterType);
		//ToDO:Check values to be passed.
		getPromoCodeList(filterSearchValues?filterSearchValues:null,currentPageValue,currentPageSize,sorterColumnName,sorterType);
	};
	const selectFilterOption = (input,option)=>{
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
		// option.key.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.key.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="div">
					<div className="p-3">
						<Breadcrumb>
							<Breadcrumb.Item><a onClick={() => {
								history.push({ pathname: "/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/masters-menu" });
							}}>Masters</a></Breadcrumb.Item>
							<Breadcrumb.Item>Promocode List</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<div >
						<BackTop/>
						<Row gutter={[24, 24]} >
							<Col xs={24} sm={24} md={24} lg={24}>
								<Card size="small" title={<span className="text-uppercase">Promocode List</span>} extra={<Button type="primary" disabled={showFilter} icon={<FilterOutlined/>} onClick={()=>setShowFilter(true)}/>}>
									{showFilter?
										<>
											<Card>
												<Form  form={form} onFinish={onFinalSearch} layout="vertical">
													<Row  gutter={[48,8]}>
														<Col span={8}>
															<Form.Item
															// label="Registered"
																name="registered"
																className="label-default mb-0"
															>
																<Radio.Group >
																	<Radio value={"0"}>Registered</Radio>
																	<Radio value={"1"}>Valid</Radio>
																</Radio.Group>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item className="visibility-hidden mb-0" >
																<Input/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item className="visibility-hidden mb-0" >
																<Input/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item
																label="Country"
																name="country"
																className="label-default mb-0"
															>
																<Select
																	showSearch
																	allowClear
																	placeholder={"Country"}
																	filterOption={selectFilterOption}
																	onChange={onChangeCountry}
																>
																	{listCountries &&
																			listCountries.map((country) => (
																				<Option key={country.country_code} value={country.country_id}>
																					{country.country_name}
																				</Option>
																			))}
																</Select>
															</Form.Item>
														</Col>
														<Col span={8}>
															{disableState?null:
																<Form.Item
																	label="State"
																	name="state"
																	className="label-default mb-0"
																>
																	<Select
																		showSearch
																		allowClear
																		placeholder="State"
																		filterOption={selectFilterOption}
																		onChange={onChangeState}
																	// disabled={disableState}
																	>
																		{listStates &&
																			listStates.map((state) => (
																				<Option key={state.region_code} value={state.region_id}>
																					{state.region_name}
																				</Option>
																			))}
																	</Select>
																</Form.Item>
															}
														</Col>
														<Col span={8}>
															{disableState?null:
																<Form.Item
																	label="University"
																	name="university"
																	className="label-default mb-0"
																>
																	<Select
																		placeholder="University" 
																		allowClear 
																		showSearch 
																		// onChange={onChangeUniversity}
																		filterOption={selectFilterOption}
																	>
																		{tempUniversityList?
																		// eslint-disable-next-line no-unused-vars
																			Object.entries(tempUniversityList).map(([ key,value]) => (
																				<>
																					{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																				</>
																			)):
																			universityList &&
																		// eslint-disable-next-line no-unused-vars
																		Object.entries(tempUniversityList ? tempUniversityList : universityList).map(([ key,value]) => (
																			<>
																				{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																			</>
																		))
																		}
																	</Select>
																</Form.Item>
															}
														</Col>
														<Col span={8}>
															<Form.Item
																label="From"
																name="from"
																className="label-default mb-0"
															>
																<DatePicker format={constants.dateFormat} inputReadOnly={true} onChange={from} className="w-100"
																// disabledDate={disabledFutureDate}
																/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item
																label="To"
																name="to"
																className="label-default mb-0"
															>
																<DatePicker format={constants.dateFormat} inputReadOnly={true}  onChange={to} disabledDate={disabledPastDate} className="w-100"/>
															</Form.Item>	
														</Col>
														<Col span={8}>
															<Form.Item
																label="Promocode"
																name="promocode"
																className="label-default mb-0"
															>
																<Input placeholder="Promocode"/>
															</Form.Item>		
														</Col>
														<Col span={8}>
															<Form.Item
																label="Student"
																name="student"	
																className="label-default mb-0"													
															>
																<Input placeholder="Student Name"/>
															</Form.Item>														
														</Col>												
														<Col span={8}>											
															<Form.Item
																label="Duration"
																name="duration"
																className="label-default mb-0"
															>
																<InputNumber step={1} precision={0} placeholder="Duration" className="w-100"/>
															</Form.Item>													
														</Col>													
														<Col span={8}>																											
															<Form.Item
																label="Status"
																name="status"
																className="label-default mb-0"
															>
																{/* <Select
																	showSearch
																	allowClear
																	placeholder="Status"
																	filterOption={(inputStatus, option) =>
																		option.props.children
																			.toLowerCase()
																			.indexOf(inputStatus.toLowerCase()) >= 0 ||
																			option.key.toLowerCase().indexOf(inputStatus.toLowerCase()) >= 0
																	}															
																>															
																	<Option value={"2"}>Available</Option>
																	<Option value={"0"}>Expired</Option>
																	<Option value={"1"}>Current</Option>
																</Select>																 */}
																<Radio.Group >
																	<Radio value={"2"}>Available</Radio>
																	<Radio value={"1"}>Current</Radio>
																	<Radio value={"0"}>Closed</Radio>
																</Radio.Group>
															</Form.Item>					
														</Col>
													</Row>
													<div className="text-right">
														<Space>
															<Form.Item>
																<Button icon={<SearchOutlined/>}  type="primary" htmlType="submit">Search</Button>
															</Form.Item>
															<Form.Item>
																<Button icon={<ReloadOutlined/>} onClick={resetFilterForm}>Reset</Button>
															</Form.Item>
															<Form.Item>
																<Button icon={<CloseOutlined/>} onClick={()=>setShowFilter(false)} type="primary" danger>Close</Button>
															</Form.Item>
														</Space>
													</div>
												</Form>
											</Card>
											<Divider/></>:null}
									<br/>
									<span className="px-3">{ `${recordsCount !== 0?recordsCount:0} record(s) found.` }</span>
									<br/>
									<Table className="admin-gap-table" 
										locale={{ emptyText: 'No Records Found' }}
										dataSource={promocodeData} 
										columns={topTenColumns}
										onChange ={onchangePromoCodeTable}
										// pagination={pagination}
										pagination={{
											total: recordsCount,
											showSizeChanger: true,
											pageSizeOptions: ["25", "50", "100"],
											defaultPageSize: 25,
											current: currentPage,
										}}
										scroll={{ x: 1200 }}
									/>
								</Card>
							</Col>
						</Row> 
					</div>
				</div>
			</Spin>
		</>
	);
};
export default PromoCodeList;
