import React, { useState, useEffect } from "react";
import { Row, Col, BackTop, Card, Table, Tooltip, Space , Avatar  } from 'antd';
import { InfoCircleOutlined, TrophyOutlined, StarFilled } from '@ant-design/icons';
import { fetchApi } from "../../services/api";
import CustomIcons from "../../assets/CustomIcons";
import Icon from '@ant-design/icons';
import constants from '../../constants/constants';
// import { useHistory } from "react-router";
import '../../styles/test.css';
// Custom icons
const Certificate = (props) => (<Icon component={CustomIcons.getsvg("Certificate")} {...props}/>);
const WheatStar = (props) => (<Icon component={CustomIcons.getsvg("WheatStar")} {...props}/>);
const Graduation = (props) => (<Icon component={CustomIcons.getsvg("Graduation")} {...props}/>);
const LeaderboardViewmore = () => {
	// const history = useHistory();
	const [leaderBoardScore, setLeaderBoardScore] = useState([]);
	useEffect(() => {
		leaderboardScores();
	}, []);
	const leaderboardScores = () => {
		fetchApi(`/leaderboard?type=student&&show_more=1`, "get").then((res) => {
			if (res && res.code === 200) {
				let tempMark=null;
				let count=0;
				res.data.map((item)=>{
					if(item.total_points===tempMark){
						item.srNum=count;
					}else{
						item.srNum=count+1;
						count=count+1;
					}
					tempMark=item.total_points;
				});
				setLeaderBoardScore( res.data );
			}else{
				setLeaderBoardScore([]);
			}
		});
	};
	// Leaderboard table
	const leaderboardColumns = [
		{
			title: '#',
			key: 'srNum',
			dataIndex:'srNum',
			align:'center',
			className: 'td-right',
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				const x = record.name;
				const myArray = x.split(" ");
				const initials = x.substring(0, 1);
				const y = myArray[1];
				const initial2 = y.substring(0, 1);
				return (
					<>
						<Space size="middle">
							<Avatar className="avatar">{initials}{initial2}</Avatar>{/* Don't call "avatar" class if expired sub user */}
							<span>{record&&record.name? record.name:"-"}</span>
						</Space>
					</>
				);
			}
		},
		{
			title: 'State',
			key: 'state',
			render: (record) => {
				return (
					<span>{record&&record.state?record.state:"-"}</span>
				);
			}
		},
		{
			title: 'Level',
			key: 'level',
			align: 'center',
			render: (record) => {
				let icons = " ";
				switch(record.level ) {
				case 1:
					icons = <div className="hexagon hex-sm level1"><StarFilled/></div>;
					break;
				case 2:
					icons = <div className="hexagon hex-sm level2"><Certificate/></div>;
					break;
				case 3:
					icons = <div className="hexagon hex-sm level3"><Graduation/></div>;
					break;
				case 4:
					icons = <div className="hexagon hex-sm level4"><WheatStar/></div>;
					break;
				case 5:
					icons = <div className="hexagon hex-sm level5"><TrophyOutlined/></div>;
					break;
				default:
					icons = "";
				}
				return (
					<Space size="middle" align="center">
						{icons}
						<span>{record&&record.level?"Level " +  record.level:"-"}</span>
					</Space>
				);
			}
		},
		{
			title: 'Points',
			key: 'points',
			align:'center',
			className: 'td-right',
			render: (record) => {
				return (
					<span>{record&&record.total_points?record.total_points:"-"}</span>
				);
			}
		},
	];
	return (
		<div className="p-4">
			<BackTop/>
			<Row gutter={[24, 24]}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title={<span className="text-uppercase">{constants.Leaderboard}</span>} extra={<Space>
						{/* <a onClick={()=>history.push("/compare-score")}>Back to top 10</a> */}
						<Tooltip placement="left" title={constants.compareLeaderboardInfoTooltip}><InfoCircleOutlined/></Tooltip></Space>}>
						<Table className="gap-table" columns={leaderboardColumns} dataSource={leaderBoardScore} pagination={false}/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default LeaderboardViewmore;
