import React,{ useEffect,useState } from 'react';
import { Breadcrumb, Row, Col, Card,Form,Input,Select,Button, Space, message } from 'antd';
import { useHistory } from "react-router";
import constants from "../../../constants/constants";
import { fetchAdminApi } from '../../../services/api';
const EditAdmin =(props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const { Option } = Select;
	const { TextArea } = Input;
	const [type] = useState(props && props.history && props.history.location && props.history.location.state?props.history.location.state.type:sessionStorage.getItem("admin-users-type"));
	const [editAdminDetails] = useState(props && props.history && props.history.location && props.history.location.state?props.history.location.state.adminRecords:type == 1 ?"":JSON.parse(sessionStorage.getItem("edit-admin-details")));
	const [statusType,setStatusType] = useState(1);
	const [adminType,setAdminType] = useState(2);
	const [passwordStatus,setPasswordStatus]=useState("");
	const [confirmPassword,setConfirmPassword]=useState("f");
	useEffect(() => {
		if(props && props.history && props.history.location && props.history.location.state){
			sessionStorage.setItem("admin-users-type",props.history.location.state.type);
			sessionStorage.setItem("edit-admin-details", JSON.stringify(props.history.location.state.adminRecords));
		}
		if(editAdminDetails){
			setEditAdminFormFields();
		}
		if(type ==1){
			form.setFieldsValue({
				Status:statusType,
				Type:adminType
			});
		}
	}, []);
	const horizontalLayout = {
		labelCol: { span: 8, },
		wrapperCol: { span: 16, },
	};
	const horizontalLayout2 = {
		labelCol: { span: 4, },
		wrapperCol: { span: 20, },
	};
	// const horizontalLayoutDetails = {
	// 	labelCol: { span: 3, },
	// 	wrapperCol: { span: 19, },
	// };
	const handlePasswordValidation=(rule,value,callback)=>{
		try {
			if(value){
				setConfirmPassword("t");
				let passwordValidate=/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/g;
				//^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^!&*+=]).*$
				//^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$
				let check=passwordValidate.test(value);
				if(check===false){
					setPasswordStatus("error");
					throw new Error(constants.PasswordValidation);
				}else{
					setPasswordStatus("success");
				}
				let passwordSpaceValidate=/^\S*$/;
				let spaceCheck=passwordSpaceValidate.test(value);
				if(spaceCheck===false){
					setPasswordStatus("error");
					throw new Error(constants.PasswordValidation);
				}
			}else{
				setConfirmPassword("f");
				if(type !== 0){
					setPasswordStatus("error");
					throw new Error(constants.PasswordRegistration);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const onFinalSave =(values)=>{
		console.log(values);
		let payload=null;
		let editPayload=null;
		payload={
			login_username:values.Username,
			user_firstname:values.FirstName,
			user_lastname:values.LastName,
			user_email:values.Email,
			type:values.Type,
			status:values.Status,
			details:values.Details,
			login_password:values.password,
			confirm_password:values.confirmPassword,
		};
		editPayload={
			login_username:values.Username,
			user_firstname:values.FirstName,
			user_lastname:values.LastName,
			user_email:values.Email,
			user_type:values.Type,
			is_active:values.Status,
			user_details:values.Details,
		};
		if(values.password){
			editPayload.login_password=values.password;
		}
		console.log("payload",payload);
		if(editAdminDetails){
			console.log("edit");
			editAdminUser(editPayload);
		}else{
			addAdminUser(payload);
		}
	};
	const addAdminUser =(payload)=>{
		fetchAdminApi(`/add_admin_user`, "post", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.message);
						history.push({ pathname: "/admin-users" });
					} else {
						if(res.message){
							if (res.message.login_username) {
								message.error(res.message.login_username);
							}else if(res.message.user_email){
								message.error(res.message.user_email);
							}else{
								message.error(res.message);
							}
						}
					}
				}
			}
		);
	};
	const editAdminUser =(payload)=>{
		fetchAdminApi(`/edit_admin_user/${editAdminDetails &&editAdminDetails.Admin_id}`, "put", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.message);
						history.push({ pathname: "/admin-users" });
					} else {
						if(res.message){
							if (res.message.login_username) {
								message.error(res.message.login_username);
							}else if(res.message.user_email){
								message.error(res.message.user_email);
							}else{
								message.error(res.message);
							}
						}
					}
				}
			}
		);
	};
	const onchangeStatusType=(value)=>{
		setStatusType(value);
	};
	const onchangeAdminType=(value)=>{
		setAdminType(value);
	};
	const setEditAdminFormFields=()=>{
		form.setFieldsValue({
			Username:editAdminDetails &&editAdminDetails.Username,
			FirstName:editAdminDetails &&editAdminDetails.first_name ,
			Email:editAdminDetails &&editAdminDetails.Email,
			Status:editAdminDetails &&editAdminDetails.status =="Active"?1:0,
			LastName:editAdminDetails &&editAdminDetails.last_name,
			Type:editAdminDetails &&editAdminDetails.User_Type?parseInt(editAdminDetails.Usertype):1,
			Details:editAdminDetails &&editAdminDetails.Details
		});
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item><a onClick = {()=>{ history.push("/admin-users");}}>Admin Users</a></Breadcrumb.Item>
						<Breadcrumb.Item>{type == 0 ?"Edit Admin User":"Add Admin User"}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					{/* <Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}> */}
					<Card bordered size="small" title={<span className="text-uppercase">{type == 0 ?"Edit Admin User":"Add Admin User"}</span>}>
						<Form form={form} onFinish={onFinalSave} >
							<Row gutter={24}>
								<Col span={12}>
									<Form.Item
										{...horizontalLayout}
										label={constants.Username}
										labelAlign="left"
										name="Username"
										rules={[
											{
												required: true,
												message: constants.UserNameRegistration,
											},
											{
											// pattern: /^(?=.{2,})[A-Za-z]+((\s)?((.)?([A-Za-z])+))*$/,
												pattern: /^(?=.{2,})[^0-9]*$/,
												message:
															constants.UserNameRegistrationInvalid,
											},
										]}
									>
										<Input disabled ={type == 0?true:false} placeholder={constants.Username}/>
									</Form.Item>
									<Form.Item
										{...horizontalLayout}
										label={constants.FirstName}
										labelAlign="left"
										name="FirstName"
										rules={[
											{
												required: true,
												message: constants.FirstNameRegistration,
											},
											{
											// pattern: /^(?=.{2,})[A-Za-z]+((\s)?((.)?([A-Za-z])+))*$/,
												pattern: /^(?=.{2,})[^0-9]*$/,
												message:
															constants.FirstNameInvalid,
											},
										]}
									>
										<Input placeholder={constants.FirstName}/>
									</Form.Item>
									<Form.Item
										{...horizontalLayout}
										label="Email"
										labelAlign="left"
										name="Email"
										rules={[
											{
												type: "email",
												message: constants.EmailNotValid,
											},
											{
												required: true,
												message: constants.EmailRegistration,
											},
										]}
									>
										<Input 
											// disabled ={type == 0?true:false} 
											placeholder={constants.Email}
										/>
									</Form.Item>
									<Form.Item
										{...horizontalLayout}
										name="password"
										label={constants.Password}
										labelAlign="left"
										validateStatus={passwordStatus}
										rules={[
											{
												required: type == 0?false:true,
												message: " ",
											},
											{ validator: handlePasswordValidation },
										]}
										hasFeedback
										tooltip={constants.PsdTip}
									>
										<Input.Password placeholder={constants.Password}/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										{...horizontalLayout}
										labelAlign="left"
										label={constants.Status}
										name="Status"
										// rules={[
										// 	{ 
										// 		required: true,
										// 		message: "Select status",
										// 	},
										// ]}
									>
										<Select onChange={onchangeStatusType} value={statusType}>
											{/* <Option value={0}>Select</Option> */}
											<Option value={1}>Active</Option>
											<Option value={0}>Inactive</Option>
										</Select>
									</Form.Item>
									<Form.Item
										{...horizontalLayout}
										label={constants.LastName}
										labelAlign="left"
										name="LastName"
										rules={[
											{
												required: true,
												message: constants.LastNameRegistration,
											},
											{
											// pattern: /^[A-Za-z]+((\s)?((.)?([A-Za-z])+))*$/,
												pattern: /^[^0-9]*$/,
												message:
															constants.LastNameInvalid,
											},
										]}
									>
										<Input placeholder={constants.LastName}/>
									</Form.Item>
									<Form.Item
										{...horizontalLayout}
										labelAlign="left"
										label={constants.Type}
										name="Type"
										// rules={[
										// 	{ 
										// 		required: true,
										// 		message: "Select type",
										// 	},
										// ]}
									>
										<Select onChange={onchangeAdminType} value={adminType}>
											{/* <Option value={0}>Select</Option> */}
											<Option value={1}>Admin</Option>
											<Option value={2}>Auditor</Option>
											<Option value={3}>Contributor</Option>
											<Option value={4}>Pro-Ed</Option>
										</Select>
									</Form.Item>
									<Form.Item
										{...horizontalLayout}
										name="confirmPassword"
										label={constants.ConfirmPsd}
										labelAlign="left"
										dependencies={["password"]}
										hasFeedback
										rules={[
											{
												required: confirmPassword === "f"?false:true,
												message: constants.ConfirmPasswordRegistration,
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue("password") === value) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error(
															constants.PasswordNotMatch
														)
													);
												},
											}),
										]}
									>
										<Input.Password placeholder={constants.ConfirmPsd}/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										{...horizontalLayout2}
										labelAlign="left"
										label={constants.Details}
										name="Details"
									>
										<TextArea rows={4} placeholder="Details"/>
									</Form.Item>
								</Col>
								<Col span={4}></Col>
								<Col span={20}>
									<Form.Item 
									>
										<Space>
											<Button type="primary" htmlType="submit">Save</Button>
											<Button type="primary" onClick={()=>history.push("/admin-users")}>Cancel</Button>
										</Space>
									</Form.Item>
								</Col>
								{/* <Col span={24} className="form-item-mb-3">
									<Form.Item
										className="label-width"
										labelAlign="left"
									>
										<Space>
											<Form.Item >
												<Button type="primary" htmlType="submit">Save</Button>
											</Form.Item>
											<Form.Item>
												<Button type="primary">Cancel</Button>
											</Form.Item>
										</Space>
									</Form.Item>
								</Col> */}
							</Row>
						</Form>
					</Card>
					{/* </Col>
					</Row> */}
				</div>
			</div>
		</>
	);
};
export default EditAdmin;