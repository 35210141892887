/*
File Name: ShareScore.js
Author: Mevito Gonsalves
Modified On: 7/1/2022
Description: Display score and share it on social media platforms.
API's used:None
*/
import React, { useEffect,useState } from "react";
import { Button, Card, Input, Space, Tooltip, Form, Col, Row } from 'antd';
import { CheckOutlined, ShareAltOutlined, CloseOutlined, FileTextOutlined, CopyOutlined } from '@ant-design/icons';
import '../../styles/pausetest.css';
import { useHistory, } from "react-router";
import { FacebookShareButton, TwitterShareButton ,WhatsappShareButton, EmailShareButton ,FacebookIcon, TwitterIcon ,WhatsappIcon,EmailIcon } from "react-share";
// import { ,FacebookIcon, TwitterIcon ,WhatsappIcon,EmailIcon } from "react-share";
// import moment from "moment";
import constants from "../../constants/constants";
const { TextArea } = Input;
const ShareScore = (props) => {
	let history = useHistory();
	const [showIcons,setshowIcons]=useState(true);
	const [showTheThing,setshowTheThing]=useState(false);
	const [quoteData,setQuoteData]=useState(null);
	const [watstwitData,setWastsTwitData]=useState(null);
	const [emailBody,setEmailBody]=useState(null);
	const [emailSubject,setEmailSubject]=useState("");
	const [imageUploadLink,setImageUploadLink]=useState("");
	// eslint-disable-next-line react/prop-types
	const [passedScoreID,]=useState(props&&props.history&&props.history.location&&props.history.location.state?props.history.location.state.scoreCardId:sessionStorage.getItem("shareScorecardId"));
	// eslint-disable-next-line react/prop-types
	const [resultData,]=useState(props&&props.history&&props.history.location&&props.history.location.state?props.history.location.state.resultObj:JSON.parse(sessionStorage.getItem("shareResultObject")));
	const [isResultPage,]=useState(props&&props.history&&props.history.location&&props.history.location.state?props.history.location.state.isResultPage:sessionStorage.getItem("shareIsResultpage"));
	const [form] = Form.useForm();
	const popover = () => { 
		setshowIcons(!showIcons);
		if(showIcons) {
			setshowTheThing(true);
		}
		else{
			setshowTheThing(false);
		}
	};
	//function to set Data
	function setDataValues(resultData) {
		let tempResultType=resultData.test_length.toLowerCase().includes("full length");
		let scoreText=tempResultType?resultData.total_score:resultData.test_percentage+"%";
		let fullName=resultData.name_intial.split(" ");
		let firstName=fullName[0];
		let lastNameIntial=fullName[1].substring(0, 1);
		let tempQuote;
		let tempWatsTwitQuote;
		if(resultData.total_time_taken){
			tempQuote="Congratulations! "+firstName+" "+lastNameIntial+"."+" scored "+scoreText+" in " +resultData.total_time_taken+" "+constants.sharedText;
			tempWatsTwitQuote="Congratulations! "+firstName+" "+lastNameIntial+"."+" scored "+scoreText +" in " +resultData.total_time_taken+" "+constants.sharedText;
		}else{
			tempQuote="Congratulations! "+firstName+" "+lastNameIntial+"."+" scored "+scoreText+" "+constants.sharedText;
			tempWatsTwitQuote="Congratulations! "+firstName+" "+lastNameIntial+"."+" scored "+scoreText+" "+constants.sharedText;
		}
		setWastsTwitData(tempWatsTwitQuote);
		setQuoteData(tempQuote);
		form.setFieldsValue({ quote:tempQuote });
		let tempEmailBoody;
		if(resultData.total_time_taken){
			tempEmailBoody="Congratulations! "+firstName+" "+lastNameIntial+"."+" scored "+scoreText+" in " +resultData.total_time_taken+" "+constants.sharedText;
		}else{
			tempEmailBoody="Congratulations! "+firstName+" "+lastNameIntial+"."+" scored "+scoreText+" "+constants.sharedText;
		}
		let tempEmailSubject=""+resultData.book_name+" test result.";
		setEmailBody(tempEmailBoody);
		setEmailSubject(tempEmailSubject);
	}
	//Navigate to result page
	const navigateResult=()=>{
		if(isResultPage===true||isResultPage==="true"){
			history.push({
				pathname: "/result-page",
				state: { 
					scoreCardId:passedScoreID,
					resultTypeFull:props&&props.history&&props.history.location&&props.history.location.state?props.history.location.state.resultTypeFull:sessionStorage.getItem("shareResulttypeFull"),
					testCategory:props&&props.history&&props.history.location&&props.history.location.state?props.history.location.state.testCategory:sessionStorage.getItem("sharetestCategory"),
					isTopic:props&&props.history&&props.history.location&&props.history.location.state?props.history.location.state.isTopic:sessionStorage.getItem("shareIsTopic"),
				}
			});
		}else{
			history.push({
				pathname:"/full-result-page",
				state:{ scoreCardId:passedScoreID } 
			});
		}
	};
	//copy text on button click
	const copyToClipboard = (content) => {
		const el = document.createElement('textarea');
		el.value = content;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	};
	useEffect(() => {
		let shareUrlLink=constants.sharedLink;
		setImageUploadLink(shareUrlLink);
		if(props&&props.history&&props.history.location&&props.history.location.state){
			setDataValues(props.history.location.state.resultObj);
			sessionStorage.setItem("shareScorecardId",props.history.location.state.scoreCardId);
			sessionStorage.setItem("shareResultObject",JSON.stringify(props.history.location.state.resultObj));
			sessionStorage.setItem("shareIsResultpage",props.history.location.state.isResultPage);
			sessionStorage.setItem("shareIsTopic",props.history.location.state.isTopic);
			sessionStorage.setItem("shareResulttypeFull",props.history.location.state.resultTypeFull);
			sessionStorage.setItem("sharetestCategory",props.history.location.state.testCategory);
		}else{
			setDataValues(JSON.parse(sessionStorage.getItem("shareResultObject")));
		}
	}, []);
	return (
		<div className="test-status">
			<div className="detail-score-wrapper">
				<h4 className="mt-3">Share your achievement</h4>
				<div id="shareScore">
					<Card bordered={false} className="detail-section" >
						<CheckOutlined className="checked"/>
						<p className="greetings">Congratulations!</p>
						<p className="name">{resultData.name_intial}</p>
						{resultData.total_time_taken!==null?
							<p className="score">Scored: {resultData.test_length.toLowerCase().includes("full length")?resultData.total_score:resultData.test_percentage+"%"}<br/>
							on a Practice Test<br/>
								{resultData.test_length}<br/> ({resultData.test_total_questions} questions)<br/>
							in {resultData.total_time_taken } on {resultData.test_taken_date} </p>
							:<p className="score">Scored: {resultData.test_length.toLowerCase().includes("full length")?resultData.total_score:resultData.test_percentage+"%"}<br/>
							on a Practice Test<br/>
								{resultData.test_length}<br/> ({resultData.test_total_questions} questions)<br/>
							on {resultData.test_taken_date}</p>
						}
					</Card>
					<Card bordered={false} className="book-details">
						<p>An Advanced Review of Speech-Language Pathology, 6th Edition: Practice Examinations</p>
						<div className="share-button-wrapper">
							<Space direction="vertical">
								<Button onClick={popover} shape="circle" size="large" className="share-button" icon={showIcons ? <ShareAltOutlined/> : <CloseOutlined/> } ></Button>							
							</Space>
							{ showTheThing && 
								<div className="transition" >
									<FacebookShareButton
										url={imageUploadLink}
										quote={quoteData}
										// hashtag={quoteData}
									>
										<FacebookIcon size={32} round/>
									</FacebookShareButton>
									<TwitterShareButton
										title={watstwitData}
										url={imageUploadLink}
									>
										<TwitterIcon size={32} round/>
									</TwitterShareButton>
									<WhatsappShareButton
										title={watstwitData}
										url={imageUploadLink}
									>
										<WhatsappIcon size={32} round/>
									</WhatsappShareButton>
									<EmailShareButton
										url={imageUploadLink}
										subject ={emailSubject}
										body ={emailBody}
										separator =" "
									>
										<EmailIcon size={32} round/>
									</EmailShareButton>
								</div>
							} 
						</div>
					</Card>
				</div>
				<br/>
				<Button className="result-btn" 
					onClick={()=>navigateResult()}
				><FileTextOutlined/>Back to Test Results
				</Button> 
				<br/>
				<br/>
				<Row gutter={[24, 8]} className='my-3'>
					<Col span={22}>
						<Form form={form} >
							<Form.Item name="quote" className="mb-0">
								<TextArea 
									disabled={true}
									autoSize={{ 
										minRows: 2, 
										maxRows: 6 
									}}
								/>
							</Form.Item>
						</Form>
					</Col>
					<Col span={2} className='place-center'>
						<Tooltip title="Copy" placement="right">
							<Button
								icon={<CopyOutlined className="icon-md"/>
								}
								onClick={() =>  copyToClipboard(quoteData)}>
							</Button>
						</Tooltip>
					</Col>
				</Row>
			</div>
		</div> 
	);
};
export default ShareScore;
