/* eslint-disable react/prop-types */
import React from "react";
import { Route, Switch } from "react-router-dom";
import Layouts from "../layout";
import { RouteWithLayout } from "./helper";
import Login from "../Views/Common/Cred/Login";
import ForgotPassword from "../Views/Common/Cred/ForgotPassword";
import Registration from "../Views/Common/Cred/Registration";
import CategoryTest from "../Views/Test/CategoryTest";
import StartTutorial from "../Views/Tutorials/StartTutorial";
import ReviewIncorrect from "../Views/Test/FullLengthTest/ReviewIncorrect";
import SelectTutorial from "../Views/Test/SelectTutorial";
import TopicTutorials from "../Views/Test/TopicTutorials";
import CustomTutorial from "../Views/Test/CustomTutorial";
import Dashboard from "../Views/Common/Dashboard";
import FullLengthTest from "../Views/Test/FullLengthTest/FullLengthTest";
import ShortLengthTest from "../Views/Test/ShortLengthTest";
import PauseTest from "../Views/Test/testStatus/PauseTest";
import Notes from "../Views/Notes/Notes";
import CompareScore from "../Views/CompareScore/CompareScore";
import MyTests from "../Views/TestHistory/MyTests";
import FlashcardList from "../Views/Flashcards/FlashcardList";
import SearchResult from "../Views/Test/SearchResult";
import ShareScore from "../Views/Test/ShareScore";
import ResultPage from "../Views/Test/ResultPage";
import Flashcards from "../Views/Flashcards/Flashcards";
import ChooseTest from "../Views/Test/ChooseTest";
import Instructions from "../Views/Test/Instructions/Instructions";
import MyProfile from "../Views/Common/Cred/MyProfile";
import ResetPassword from "../Views/Common/Cred/ResetPassword";
import TotalRegistration from "../Views/Admin/Total Registration/TotalRegistration";
import RegisteredStudent from "../Views/Admin/Total Registration/RegisteredStudent";
import RegistrationInUsOnly from "../Views/Admin/Total Registration/RegistrationInUsOnly";
import RegistrationByCountry from "../Views/Admin/Total Registration/RegistrationByCountry";
import TestListing from "../Views/Admin/Tests/TestListing";
import ActivityLog from "../Views/Admin/ActivityLog/ActivityLog";
import ActivityLogAllRegistration from "../Views/Admin/ActivityLog/AllRegistration";
import StudentLeaderboard from "../Views/Admin/ActivityLog/StudentLeaderboard";
import CodeGeneration from "../Views/Admin/Code Generation/CodeGeneration";
import PromoCodeList from "../Views/Admin/Code Generation/PromoCodeList";
import FullLengthTestResultPage from "../Views/Test/FullLengthTestResultPage";
import ResumeTest from "../Views/Test/ResumeTest";
import RetakeTest from "../Views/Test/RetakeTest";
import QuestionListing from "../Views/Admin/Tests/QuestionListing";
import RegistrationByRoles from "../Views/Admin/Total Registration/RegistrationByRoles";
import AdminDashboard from "../Views/Admin/Total Registration/AdminDashboard";
import EditStudent from "../Views/Admin/ManageStudent/EditStudent";
import RegistrationByUniversity from "../Views/Admin/Total Registration/RegistrationByUniversity";
import RegistrationInUsUniversities from "../Views/Admin/Total Registration/RegistrationInUsUniversities";
import Activation from "../Views/Common/Cred/Activation";
import TestScores from "../Views/Admin/Tests/TestScores";
import FlashcardChapterListing from "../Views/Admin/Flashcards/FlashcardChapterListing";
import FlashcardListing from "../Views/Admin/Flashcards/FlashcardListing";
import FeedbackAnalysis from "../Views/Admin/FeedbackAnalysis/FeedbackAnalysis";
import MarkedForLaterTutorial from "../Views/Tutorials/MarkForLaterTutorial";
import Test from "../Views/Admin/Tests/Test";
import TestResultPage from "../Views/Admin/Tests/TestResultPage";
import ReviewIncorrectTest from "../Views/Admin/Tests/ReviewIncorrectTest";
import TestResultFullLength from "../Views/Admin/Tests/TestResultFullLength";
import EditFlashcard from "../Views/Admin/Flashcards/EditFlashcard";
import AddFlashcard from "../Views/Admin/Flashcards/AddFlashcard";
import TotalTestTaken from "../Views/Admin/Tests/TotalTestTaken";
import CaseStudyList from "../Views/Admin/ManageQuestion/CaseStudyList";
import AddCaseStudy from "../Views/Admin/ManageQuestion/AddCaseStudy";
import EditCaseStudy from "../Views/Admin/ManageQuestion/EditCaseStudy";
import CompareScoreViewMore from "../Views/CompareScore/CompaseScoreViewMore";
import LeaderboardViewmore from "../Views/CompareScore/LeaderboardViewMore";
import TestScore from "../Views/Admin/Tests/TestScore";
import ScoreCompare from "../Views/Admin/ScoreCompare/ScoreCompare";
import QuestionRating from "../Views/Admin/QuestionRatings/QuestionRating";
import AdminLogin from "../Views/Admin/Common/AdminLogin";
import UniversityMaster from "../Views/Admin/University/UniversityMaster";
import AdminUsers from "../Views/Admin/Total Registration/AdminUsers";
import EditAdmin from "../Views/Admin/ManageAdmin/EditAdmin";
import LeaderBoardViewmore from "../Views/Admin/ScoreCompare/LeaderBoardViewMore";
import ScoreCompareViewMore from "../Views/Admin/ScoreCompare/ScoreCompareViewMore";
import AdminLayouts from "../Views/Admin/Common/AdminLayout";
import Faq from "../Views/FAQ/Faq";
import AdminFaq from "../Views/Admin/FAQs/AdminFaq";
import FeedbackComments from "../Views/Admin/FeedbackAnalysis/FeedbackComments";
import EditFaq from "../Views/Admin/FAQs/EditFaq";
import TestResult from "../Views/Admin/Tests/TestResult";
import EditQuestion from "../Views/Admin/EditQuestion/EditQuestion";
import PreviewQuestion from "../Views/Admin/PreviewQuestion/PreviewQuestion";
import VersionViewer from "../Views/Common/VersionViewer";
import ResendEmail from "../Views/Common/Cred/ResendEmail";
import PageNotFound from "../Views/Common/Cred/PageNotFound";
import ScorePercentage from "../Views/Admin/StatisticTests/ScorePercentage";
import JsonInfo from "../Views/Admin/JsonInfo/JsonInfo";
import TestResultJsonInfo from "../Views/Admin/Tests/TestResultJsonInfo";
import FlashcardDetails from "../Views/Admin/Flashcards/FlashcardDetails";
// import TutorialResult from "../Views/Admin/Tests/TutorialResult";
import AnalysisMenu from "../Views/Admin/Common/AnalysisMenu";
import MastersMenu from "../Views/Admin/Common/MastersMenu";
import FlashcardResults from "../Views/Admin/Tests/FlashcardResults";
import CronSetting from "../Views/Admin/Cron Setting/CronSetting";
import JsonTestResponse from "../Views/Admin/JsonInfo/JsonTestResponse";
import JsonResponseData from "../Views/Admin/JsonInfo/JsonResponseData";
// import LeaderBoardViewmore from "../Views/Admin/ScoreCompare/LeaderBoardViewMore";
// import ScoreCompareViewMore from "../Views/Admin/ScoreCompare/ScoreCompareViewMore";
// import AdminLogin from "../Views/Admin/Cred/AdminLogin";
import TestScoreGraph from "../Views/Admin/Tests/TestScoreGraph";
import StudentStreak from "../Views/Admin/ManageStudent/StudentStreak";
import QodAnswered from "../Views/Admin/ManageStudent/QodAnswered";
import StudentAllActivities from "../Views/Admin/ManageStudent/StudentAllActivities";
import TutorialScoreGraph from "../Views/Admin/Tests/TutorialScoreGraph";
import TestTakenDetails from "../Views/Admin/Tests/TestTakenDetails";
import EditCronSetting from "../Views/Admin/Cron Setting/EditCronSetting";
import DailyQOD from "../Views/Admin/Tests/DailyQOD";
import ScoreCardPieGraph from "../Views/Admin/Tests/ScoreCardPieGraph";
import DailyQODDetails from "../Views/Admin/Tests/DailyQODDetails";
import TotalFlashcardTaken from "../Views/Admin/Flashcards/TotalFlashcardTaken";
import TotalTutorialTaken from "../Views/Admin/Tests/TotalTutorialTaken";
// eslint-disable-next-line react/prop-types
const Routes = ({ location, ...rest }) => {
	return (
		<div>
			<Switch>
				<Route
					exact
					path="/"
					component={Login}
				/>
				<Route
					exact
					path="/login"
					component={Login}
				/>
				{/* <Route
					exact
					path="/admin"
					component={AdminLogin}
				/> */}
				<Route
					exact
					path="/admin-login"
					component={AdminLogin}
				/>
				<Route
					exact
					path="/resend-email"
					component={ResendEmail}
				/>
				<Route
					exact
					path="/forgot-password"
					component={ForgotPassword}
				/>
				<Route
					exact
					path="/registration"
					//layout={Layouts}
					//location={location}
					component={Registration}
				//{...rest}
				/>
				<Route
					exact
					path="/activation"
					component={Activation}
				/>
				<Route
					exact
					path="/reset-password"
					component={ResetPassword}
				/>
				<Route
					exact
					path="/password-reset"
					component={ResetPassword}
				/>
				<Route
					exact
					path="/version"
					component={VersionViewer}
				/>
				<Route
					exact
					path="/json-info"
					layout={AdminLayouts}
					location={location}
					component={JsonInfo}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/admin-dashboard"
					layout={AdminLayouts}
					location={location}
					component={AdminDashboard}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/total-registrations"
					layout={AdminLayouts}
					location={location}
					component={TotalRegistration}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/registration-in-us"
					layout={AdminLayouts}
					location={location}
					component={RegistrationInUsOnly}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/registration-by-country"
					layout={AdminLayouts}
					location={location}
					component={RegistrationByCountry}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/registration-by-roles"
					layout={AdminLayouts}
					location={location}
					component={RegistrationByRoles}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/registration-by-university"
					layout={AdminLayouts}
					location={location}
					component={RegistrationByUniversity}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/registration-in-us-university"
					layout={AdminLayouts}
					location={location}
					component={RegistrationInUsUniversities}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-student"
					layout={AdminLayouts}
					location={location}
					component={EditStudent}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/view-student"
					layout={AdminLayouts}
					location={location}
					component={EditStudent}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-student-display"
					layout={AdminLayouts}
					location={location}
					component={EditStudent}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/add-flashcard"
					layout={AdminLayouts}
					location={location}
					component={AddFlashcard}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/test-results"
					layout={AdminLayouts}
					location={location}
					component={TestResult}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/admin-result-json"
					layout={AdminLayouts}
					location={location}
					component={TestResultJsonInfo}
					{...rest}
				/>
				<Route
					exact
					path="/testResponseData"
					layout={AdminLayouts}
					location={location}
					component={JsonTestResponse}
					{...rest}
				/>			
				<RouteWithLayout
					exact
					path="/student-streak"
					layout={AdminLayouts}
					location={location}
					component={StudentStreak}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/student-qod-answered"
					layout={AdminLayouts}
					location={location}
					component={QodAnswered}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/student-total-points"
					layout={AdminLayouts}
					location={location}
					component={StudentAllActivities}
					{...rest}
				/>
				<Route
					exact
					path="/responseData"
					layout={AdminLayouts}
					location={location}
					component={JsonResponseData}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/admin-users"
					layout={AdminLayouts}
					location={location}
					component={AdminUsers}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-admin-user"
					layout={AdminLayouts}
					location={location}
					component={EditAdmin}
				/>
				<RouteWithLayout
					exact
					path="/add-admin-user"
					layout={AdminLayouts}
					location={location}
					component={EditAdmin}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/feedback-analysis"
					layout={AdminLayouts}
					location={location}
					component={FeedbackAnalysis}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/promo-code-list"
					layout={AdminLayouts}
					location={location}
					component={PromoCodeList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/university-master"
					layout={AdminLayouts}
					location={location}
					component={UniversityMaster}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/registered-students"
					layout={AdminLayouts}
					location={location}
					component={RegisteredStudent}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/test-list"
					layout={AdminLayouts}
					location={location}
					component={TestListing}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/test-score"
					layout={AdminLayouts}
					location={location}
					component={TestScores}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/test"
					layout={AdminLayouts}
					location={location}
					component={Test}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/tutorial"
					layout={AdminLayouts}
					location={location}
					component={Test}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/flashcard"
					layout={AdminLayouts}
					location={location}
					component={Test}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/admin-faq"
					layout={AdminLayouts}
					location={location}
					component={AdminFaq}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/test-result"
					layout={AdminLayouts}
					location={location}
					component={TestResultPage}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/tutorial-result"
					layout={AdminLayouts}
					location={location}
					component={TestResult}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/flashcard-result"
					layout={AdminLayouts}
					location={location}
					component={FlashcardResults}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/cron-result"
					layout={AdminLayouts}
					location={location}
					component={CronSetting}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/score-percentage"
					layout={AdminLayouts}
					location={location}
					component={ScorePercentage}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/incorrect-test"
					layout={AdminLayouts}
					location={location}
					component={ReviewIncorrectTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/full-length-result-page"
					layout={AdminLayouts}
					location={location}
					component={TestResultFullLength}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-cronsetting"
					layout={AdminLayouts}
					location={location}
					component={EditCronSetting}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-flaschcard"
					layout={AdminLayouts}
					location={location}
					component={EditFlashcard}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/test-score-listing"
					layout={AdminLayouts}
					location={location}
					component={TestScore}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/score-compare"
					layout={AdminLayouts}
					location={location}
					component={ScoreCompare}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/leaderboard-view-more"
					layout={AdminLayouts}
					location={location}
					component={LeaderBoardViewmore}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/score-compare-view-more"
					layout={AdminLayouts}
					location={location}
					component={ScoreCompareViewMore}
					{...rest}
				/> 
				<RouteWithLayout
					exact
					path="/question-rating"
					layout={AdminLayouts}
					location={location}
					component={QuestionRating}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/activity-log"
					layout={AdminLayouts}
					location={location}
					component={ActivityLog}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/activity-log-all-reg"
					layout={AdminLayouts}
					location={location}
					component={ActivityLogAllRegistration}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/student-leaderboard"
					layout={AdminLayouts}
					location={location}
					component={StudentLeaderboard}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/code-generation"
					layout={AdminLayouts}
					location={location}
					component={CodeGeneration}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/case-study-list"
					layout={AdminLayouts}
					location={location}
					component={CaseStudyList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/add-case-study"
					layout={AdminLayouts}
					location={location}
					component={AddCaseStudy}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-case-study"
					layout={AdminLayouts}
					location={location}
					component={EditCaseStudy}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/question-list"
					layout={AdminLayouts}
					location={location}
					component={QuestionListing}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/daily-qod"
					layout={AdminLayouts}
					location={location}
					component={DailyQOD}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/daily-qod-details"
					layout={AdminLayouts}
					location={location}
					component={DailyQODDetails}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/flashcard-list-chapter"
					layout={AdminLayouts}
					location={location}
					component={FlashcardChapterListing}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/flashcard-details"
					layout={AdminLayouts}
					location={location}
					component={FlashcardDetails}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/list-flashcard-chapters"
					layout={AdminLayouts}
					location={location}
					component={FlashcardListing}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/total-test-taken"
					layout={AdminLayouts}
					location={location}
					component={TotalTestTaken}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/total-flashcard-taken"
					layout={AdminLayouts}
					location={location}
					component={TotalFlashcardTaken}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/total-tutorials-taken"
					layout={AdminLayouts}
					location={location}
					component={TotalTutorialTaken}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/feedback-comment"
					layout={AdminLayouts}
					location={location}
					component={FeedbackComments}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-faq"
					layout={AdminLayouts}
					location={location}
					component={EditFaq}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/add-faq"
					layout={AdminLayouts}
					location={location}
					component={EditFaq}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-question"
					layout={AdminLayouts}
					location={location}
					component={EditQuestion}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/add-question"
					layout={AdminLayouts}
					location={location}
					component={EditQuestion}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/review-tutorial"
					layout={AdminLayouts}
					location={location}
					component={ReviewIncorrectTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/home"
					layout={Layouts}
					location={location}
					component={Dashboard}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/notes"
					layout={Layouts}
					location={location}
					component={Notes}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/compare-score"
					layout={Layouts}
					location={location}
					component={CompareScore}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/compare-score-more"
					layout={Layouts}
					location={location}
					component={CompareScoreViewMore}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/leaderboard-more"
					layout={Layouts}
					location={location}
					component={LeaderboardViewmore}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/my-tests"
					layout={Layouts}
					location={location}
					component={MyTests}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/flashcard-list"
					layout={Layouts}
					location={location}
					component={FlashcardList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/flashcards"
					layout={Layouts}
					location={location}
					component={Flashcards}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/choose-a-test"
					layout={Layouts}
					location={location}
					component={ChooseTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/instructions"
					layout={Layouts}
					location={location}
					component={Instructions}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/category-test"
					layout={Layouts}
					location={location}
					component={CategoryTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/select-tutorial"
					layout={Layouts}
					location={location}
					component={SelectTutorial}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/select-category-tutorial"
					layout={Layouts}
					location={location}
					component={CategoryTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/category-tutorial"
					layout={Layouts}
					location={location}
					component={StartTutorial}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/select-topic-tutorial"
					layout={Layouts}
					location={location}
					component={TopicTutorials}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/select-custom-tutorial"
					layout={Layouts}
					location={location}
					component={CustomTutorial}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/full-length-test"
					layout={Layouts}
					location={location}
					component={FullLengthTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/short-length-test"
					layout={Layouts}
					location={location}
					component={ShortLengthTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/pause-test"
					layout={Layouts}
					location={location}
					component={PauseTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/search-result"
					layout={Layouts}
					location={location}
					component={SearchResult}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/share-score"
					layout={Layouts}
					location={location}
					component={ShareScore}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/result-page"
					layout={Layouts}
					location={location}
					component={ResultPage}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/full-result-page"
					layout={Layouts}
					location={location}
					component={FullLengthTestResultPage}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/review-incorrect"
					layout={Layouts}
					location={location}
					component={ReviewIncorrect}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/myprofile"
					layout={Layouts}
					location={location}
					component={MyProfile}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/resume-test"
					layout={Layouts}
					location={location}
					component={ResumeTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/topic-tutorial"
					layout={Layouts}
					location={location}
					component={StartTutorial}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/retake-test"
					layout={Layouts}
					location={location}
					component={RetakeTest}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/custom-tutorial"
					layout={Layouts}
					location={location}
					component={StartTutorial}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/question-of-the-day"
					layout={Layouts}
					location={location}
					component={StartTutorial}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/tutorial-instructions"
					layout={Layouts}
					location={location}
					component={MarkedForLaterTutorial}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/marked-for-later-tutorial"
					layout={Layouts}
					location={location}
					component={StartTutorial}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/incorrect-answers-tutorial"
					layout={Layouts}
					location={location}
					component={StartTutorial}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/faq"
					layout={Layouts}
					location={location}
					component={Faq}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/preview-question"
					layout={AdminLayouts}
					location={location}
					component={PreviewQuestion}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/analysis-menu"
					layout={AdminLayouts}
					location={location}
					component={AnalysisMenu}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/test-taken-details"
					layout={AdminLayouts}
					location={location}
					component={TestTakenDetails}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/masters-menu"
					layout={AdminLayouts}
					location={location}
					component={MastersMenu}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/test-scores"
					layout={AdminLayouts}
					location={location}
					component={TestScoreGraph}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/tutorial-scores"
					layout={AdminLayouts}
					location={location}
					component={TutorialScoreGraph}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/scorecards"
					layout={AdminLayouts}
					location={location}
					component={ScoreCardPieGraph}
					{...rest}
				/>
				<RouteWithLayout 
					exact
					path="*" 
					layout={Layouts} 
					location={location}
					component={PageNotFound}
					{...rest}
				/>
			</Switch>
		</div>
	);
};
export default Routes;
