import React,{ useEffect,useState } from 'react';
import { Breadcrumb, Row, Col, Card, Table,BackTop, DatePicker, Form, Select, Space, Radio, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { fetchAdminApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import moment from 'moment';
import { Chart } from "react-google-charts";
import { registrationFilter } from '../../../globalFunctions/GlobalFunctions';
const { Option } = Select;
const TestScoreGraph =(props)=> {
	const history = useHistory();
	const [allTestData, setAllTestData] = useState({});
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [testType,setTestType]=useState(4);
	const [AllGraphData, setAllGraphData] = useState([]);
	const [AllData, setAllData] = useState([]);
	const [fullLengthGraphData, setFullLengthGraphData] = useState([]);
	const [fullLengthData, setFullLengthData] = useState([]);
	const [categoryData, setCategoryData] = useState([]);
	const [categoryGraphData, setCategoryGraphData] = useState([]);
	const [topicData, setTopicData] = useState([]);
	const [topicGraphData, setTopicGraphData] = useState([]);
	const [duration,setDuration]=useState("1");
	const [allPassTotal,setAllPassTotal]=useState(0);
	const [allFailTotal,setAllFailTotal]=useState(0);
	const [allTestsTotal,setTestsTotal]=useState(0);
	const [propsStateValue,setPropsStateValue]=useState([]);
	const [form] = Form.useForm();
	useEffect(() => {
		if(props&&props.location&&props.location.state&&props.location.state.type&&props.location.state.type==="dashboard"){
			setDateFilters(constants.startDateOfTheYear,constants.endDateOfTheYear,"0");
			fetchTableData(constants.startDateOfTheYear,constants.endDateOfTheYear);
		}else if(props&&props.location&&props.location.state&&props.location.state.type&&props.location.state.type==="testTaken"){
			setPropsStateValue(props&&props.location&&props.location.state);
			setDateFilters(props.location.state.sd,props.location.state.ed,props.location.state.duration);
			fetchTableData(props.location.state.sd,props.location.state.ed);
			// onChangeDateFilter(props&&props.location&&props.location.state&&props.location.state.duration);
			setTestType(props&&props.location&&props.location.state&&props.location.state.test_type);
		}
		else{
			fetchTableData();
		}
	}, []);
	const setDateFilters=(startDate,endDate,durationValue)=>{
		setDuration(durationValue);
		setStartDate(moment(startDate).format("MM/DD/YYYY"));
		setEndDate(moment(endDate).format("MM/DD/YYYY"));
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
	};
	// const noDataAvailableText=()=>{
	// 	return(
	// 		<>							
	// 			<div className="nograph-div">
	// 				<span>No Data Available.</span>
	// 			</div>
	// 		</>
	// 	);
	// };
	const fetchTableData = (startDateValue,endDateValue) =>{
		var startDate=null;
		var endDate=null;
		if(startDateValue&&endDateValue){
			startDate=moment(startDateValue).format("YYYY-MM-DD");
			endDate=moment(endDateValue).format("YYYY-MM-DD");
		}else{
			var date = new Date(), y = date.getFullYear(), m = date.getMonth();
			var firstDay = new Date(y, m, 1);
			var lastDay = new Date(y, m + 1, 0);
			startDate=moment(firstDay).format("YYYY-MM-DD");
			endDate=moment(lastDay).format("YYYY-MM-DD");
			setStartDate(moment(startDate).format("MM/DD/YYYY"));
			setEndDate(moment(endDate).format("MM/DD/YYYY"));
			form.setFieldsValue({
				from:moment(startDate),
				to:moment(endDate)
			});
		}
		fetchAdminApi(`/total-tests-taken-result?start_date=${startDate}&end_date=${endDate}`, "get").then(
			(res) => {
				if (res && res.data) {
					setAllTestData(res.data);
					setCategoryData(res.data?.Category);
					setTopicData(res.data?.Topic);
					if(res.data.all){
						setAllData(res.data.all);
						let passTotal=0;
						let failTotal=0;
						let totalTests=0;
						let allChartData = [
							["No. of Tests", "Pass", "Did not Pass"],
						];
						for (let index = 0; index < res.data.all.length; index++) {
							let all=[];
							passTotal=passTotal+Number(res.data.all[index].pass);
							failTotal=failTotal+Number(res.data.all[index].fail);
							totalTests=totalTests+Number(res.data.all[index].total);
							all.push(res.data.all[index].test_type,Number(res.data.all[index].pass),Number(res.data.all[index].fail));
							allChartData.push(all);
						}
						setAllPassTotal(passTotal);
						setAllFailTotal(failTotal);
						setTestsTotal(totalTests);
						setAllGraphData(allChartData);
					}
					if(res.data.Full_length){
						setFullLengthData(res.data?.Full_length);
						let fullLengthChartData = [
							["No. of Tests", "Pass", "Did not Pass"],
						];
						for (let index = 0; index < res.data.Full_length.length; index++) {
							let fullLength=[];
							fullLength.push("Test "+res.data.Full_length[index].test_type,Number(res.data.Full_length[index].pass),Number(res.data.Full_length[index].fail));
							fullLengthChartData.push(fullLength);
						}
						setFullLengthGraphData(fullLengthChartData);
					}
					if(res.data.Category){
						setCategoryData(res.data.Category);
						let categoryChartData = [
							["No. of Tests", "Pass", "Did not Pass"],
						];
						for (let index = 0; index < res.data.Category.length; index++) {
							let category=[];
							category.push(res.data.Category[index].test_type,Number(res.data.Category[index].pass),Number(res.data.Category[index].fail));
							categoryChartData.push(category);
						}
						setCategoryGraphData(categoryChartData);
					}
					if(res.data.Topic){
						setTopicData(res.data.Topic);
						let topicChartData = [
							["No. of Tests", "Pass", "Did not Pass"],
						];
						for (let index = 0; index < res.data.Topic.length; index++) {
							let topic=[];
							topic.push(res.data.Topic[index].test_type,Number(res.data.Topic[index].pass),Number(res.data.Topic[index].fail));
							topicChartData.push(topic);
						}
						setTopicGraphData(topicChartData);
					}
				}
			}
		);
	};
	const optionsforFullLengthAndCategory = {
		title: constants.TestScores,
		height: 400,
		isStacked: true,
		vAxis: {
			title: "No. of Tests",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		hAxis: { 
			title: "Tests" ,
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		seriesType: "bars",
	};
	const optionsforTopic = {
		title: constants.TestScores,
		height: 400,
		isStacked: true,
		vAxis: {
			title: "No. of Tests",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		hAxis: { textPosition: 'none' },
		seriesType: "bars",
	};
	const getFilterValue=(type)=>{
		switch (type) {
		case "Category": return ['FPP','SAED','PIET','MIXED']		;	
		case "Topic":return ['TOPIC']			;
		case "Full Length":return ['TEST']			;
		}
	};
	const column3 = [
		{
			title: testType&&testType===1?"Full Length":testType&&testType===2?
				"Category":testType&&testType===3?"Topic":"",
			key: "testtype",
			dataIndex: "test_type",
			render: (_text, record) => {
				return<> 
					{testType&&testType!==4?
						<Button type="link" icon={<SearchOutlined/>} onClick={()=>{history.push({
							pathname:"/test-taken-details",
							state:{ 
								type:record.test_type,
								testType:testType,
								duration:duration,
								startDate:startDate,
								endDate:endDate
							}
						});}}></Button>
						:""}
					<span className="p-relative">
						{testType&&testType!==4?
							<Button type="link" onClick={()=>{
								localStorage.setItem("propvalues",JSON.stringify({
									testType:testType,
									testCategoryValue:record.test_type?record.test_type:"",
									startDate:startDate,
									endDate:endDate
								}));
								window.open(constants.BaseUrl+"/test-results", '_blank');
								// history.push({
								// 	pathname:"/test-results",
								// 	state:{
								// 		testType:testType,
								// 		testCategoryValue:record.test_type?record.test_type:"",
								// 		startDate:startDate,
								// 		endDate:endDate
								// 	}
								// });
							}}>{record.test_type?record.test_type:""}</Button>
							:
							<Button type="link" onClick={()=>{
								localStorage.setItem("propvalues",JSON.stringify({
									testType:testType,
									testCategoryValue:getFilterValue(record.test_type),
									startDate:startDate,
									endDate:endDate
								}));
								window.open(constants.BaseUrl+"/test-results", '_blank');
							// 	history.push({
							// 	pathname:"/test-results",
							// 	state:{
							// 		testType:testType,
							// 		testCategoryValue:getFilterValue(record.test_type),
							// 		startDate:startDate,
							// 		endDate:endDate
							// 	}
							// });
							}}>{record.test_type?record.test_type:""}</Button>}
					</span>
				</>;
			},
			align: 'left',
			width:200
		},
		{
			title: "No. of Tests",
			key: "total",
			dataIndex: "total",
			render: (_text, record) => {
				return (
					<span className="p-relative">
						{testType && testType !== 4 ?
							<Button type="link" onClick={() => {
								localStorage.setItem("propvalues", JSON.stringify({
									testType: testType,
									testCategoryValue: record.test_type ? record.test_type : "",
									startDate: startDate,
									endDate: endDate
								}));
								window.open(constants.BaseUrl + "/test-results", '_blank');
							}}>{record.total}</Button>
							:
							<Button type="link" onClick={() => {
								localStorage.setItem("propvalues", JSON.stringify({
									testType: testType,
									testCategoryValue: getFilterValue(record.test_type),
									startDate: startDate,
									endDate: endDate,
								}));
								window.open(constants.BaseUrl + "/test-results", '_blank');
							}}>{record.total}</Button>}
					</span>);
			},
			align: 'center',
			width:100
		},
		{
			title: "Pass",
			key: "Pass",
			dataIndex: "pass",
			// className: 'td-right',
			render: (_text, record) => {
				return (
					<span className="p-relative">
						{testType&&testType!==4?
							<Button type="link" onClick={()=>{
								localStorage.setItem("propvalues",JSON.stringify({
									testType:testType,
									testCategoryValue:record.test_type?record.test_type:"",
									startDate:startDate,
									endDate:endDate,
									result:"pass"
								}));
								window.open(constants.BaseUrl+"/test-results", '_blank');
								// history.push({
								// 	pathname:"/test-results",
								// 	state:{
								// 		testType:testType,
								// 		testCategoryValue:record.test_type?record.test_type:"",
								// 		startDate:startDate,
								// 		endDate:endDate,
								// 		result:"pass"
								// 	}
								// });
							}}>{record.pass}</Button>
							:
							<Button type="link" onClick={()=>{
								localStorage.setItem("propvalues",JSON.stringify({
									testType:testType,
									testCategoryValue:getFilterValue(record.test_type),
									startDate:startDate,
									endDate:endDate,
									result:"pass"
								}));
								window.open(constants.BaseUrl+"/test-results", '_blank');
								// history.push({
								// 	pathname:"/test-results",
								// 	state:{
								// 		testType:testType,
								// 		testCategoryValue:getFilterValue(record.test_type),
								// 		startDate:startDate,
								// 		endDate:endDate,
								// 		result:"pass"
								// 	}
								// });
							}}>{record.pass}</Button>}
					</span>);
			},
			align: 'center',
			width:100
		},
		{
			title: "Did not Pass",
			key: "Did not Pass",
			dataIndex: "fail",
			// className: 'td-right',
			render: (_text, record) => {
				return (
					<span className="p-relative">
						{testType&&testType!==4?
							<Button type="link" onClick={()=>{
								localStorage.setItem("propvalues",JSON.stringify({
									testType:testType,
									testCategoryValue:record.test_type?record.test_type:"",
									startDate:startDate,
									endDate:endDate,
									result:"fail"
								}));
								window.open(constants.BaseUrl+"/test-results", '_blank');
							// 	history.push({
							// 	pathname:"/test-results",
							// 	state:{
							// 		testType:testType,
							// 		testCategoryValue:record.test_type?record.test_type:"",
							// 		startDate:startDate,
							// 		endDate:endDate,
							// 		result:"fail"
							// 	}
							// });
							}}>{record.fail}</Button>
							:
							<Button type="link" onClick={()=>{
								localStorage.setItem("propvalues",JSON.stringify({
									testType:testType,
									testCategoryValue:getFilterValue(record.test_type),
									startDate:startDate,
									endDate:endDate,
									result:"fail"
								}));
								window.open(constants.BaseUrl+"/test-results", '_blank');
							// 	history.push({
							// 	pathname:"/test-results",
							// 	state:{
							// 		testType:testType,
							// 		testCategoryValue:getFilterValue(record.test_type),
							// 		startDate:startDate,
							// 		endDate:endDate,
							// 		result:"fail"
							// 	}
							// });
							}}>{record.fail}</Button>}
					</span>);
			},
			align: 'center',
			width:100
		},
		{
			title: "Pass %",
			key: "pass percentage",
			// className: 'td-right',
			render: (_text, record) => {
				let pass = record.pass?Number(record.pass):0;
				let total = record.total?Number(record.total):0;
				let percentage =0;
				if(total!==0){
					percentage=(pass/total)*100;
				}
				return <span className="p-relative">{percentage.toFixed()}</span>;
			},
			align: 'center',
			width:100
		}
	];
	const handleStartDate = (_date, dateString) => {
		setStartDate(dateString);
		fetchTableData(dateString,endDate);
	};
	const handleEndDate = (_date, dateString) => {
		setEndDate(dateString);
		fetchTableData(startDate,dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const onChangeDateFilter = (e) => {
		setDuration(e);
		registrationFilter(e,setStartDateEndDate);
	};
	const onChangeTestType = (e) => {
		setTestType(e.target.value);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
		fetchTableData(startDate,endDate);
	};
	const totalColumn = (type_total,pass,fail,height) =>{
		return (<Table.Summary.Row style = {{ 
			fontWeight:500 ,
			height:height 
		}}>
			<Table.Summary.Cell align = "left" index={0}><span>Total</span></Table.Summary.Cell>
			<Table.Summary.Cell index={1} align= 'center'><span className="p-relative">{type_total}</span></Table.Summary.Cell>
			<Table.Summary.Cell index={2}  align= 'center'><span className="p-relative">{pass}</span></Table.Summary.Cell>
			<Table.Summary.Cell index={3}  align= 'center'><span className="p-relative">{fail}</span></Table.Summary.Cell>
		</Table.Summary.Row>); 
	};
	const noDataAvailableText=()=>{
		return(
			<>							
				<div className="nograph-div">
					<span>No Data Available.</span>
				</div>
			</>
		);
	};
	const chartTag=(options,data)=>{
		return(
			<Chart
				chartType="ComboChart"
				width="100%"
				data={data}
				options={options}
			/>);
	};
	return (
		<>
			<div className="div">
				<BackTop/>
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>{constants.TestScores}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" title={<span className="text-uppercase">{constants.TestScores}</span>}
								extra={
									<Form layout="horizontal" form = {form}>
										<Space>
											<Form.Item className="label-default mb-0">
												<Radio.Group value={testType} onChange={onChangeTestType} placeholder="Select test type" >
													<Radio value={4}>All</Radio>
													<Radio value={1}>Full Length</Radio>
													<Radio value={2}>Category</Radio>
													<Radio value={3}>Topic</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="From:" name = "from">
												<DatePicker defaultValue={startDate} onChange={ handleStartDate} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="To:" name = "to">
												<DatePicker defaultValue={endDate} onChange={handleEndDate} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Select value={duration} style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
													<Option value="0">This Year</Option>
													<Option value="7">Last Year</Option>
													<Option value="1">This Month</Option>
													<Option value="2">Last Month</Option>
													<Option value="3">Last 3 Months</Option>
													<Option value="4">This Month (Last Year)</Option>
													<Option value="5">Last 3 Months (Last Year)</Option>
													<Option value="6">Previous Years</Option>
												</Select>
											</Form.Item>
										</Space>
									</Form>
								}
							>
								<Row>
									<Col xs={24} sm={24} md={10} lg={10}>
										<span style = {{ 
											fontWeight:500,
											fontSize:"medium"
										}}>{startDate&&endDate&&`Search Criteria: From: ${moment(startDate).format("MM/DD/YYYY")} To: ${moment(endDate).format("MM/DD/YYYY")}`}</span>
										<Table className="gap-table"
											bordered
											columns={column3}
											dataSource={testType&&testType===4?AllData&&AllData.length>0?AllData:[]: testType&&testType===1?fullLengthData&&fullLengthData.length>0?fullLengthData:[]:testType&&testType===2?
												categoryData&&categoryData.length>0?categoryData:[]:testType&&testType===3?topicData&&topicData.length>0?topicData:[]:[]}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													{
														testType&&testType===4?
															totalColumn(allTestsTotal,allPassTotal,allFailTotal):
															testType&&testType===1?
																totalColumn(allTestData&&allTestData?.fulllength_total,allTestData&&allTestData?.fulllength_pass,allTestData&&allTestData?.fulllength_fail):
																testType&&testType===2?totalColumn(allTestData&&allTestData?.category_total,allTestData&&allTestData?.category_pass,allTestData&&allTestData?.category_fail):
																	testType&&testType===3?totalColumn(allTestData&&allTestData?.topic_total,allTestData&&allTestData?.topic_pass,allTestData&&allTestData?.topic_fail):
																		""}
												</Table.Summary>
											)}
										/>
										<Col>
											<div className='text-right pr-3'>
												<a
													onClick={()=>history.push({
														pathname: "/test-score-listing",
														state: propsStateValue,
													})
													}
												>Listing {'>>'}</a></div>
										</Col>
									</Col>
									{/* <Col xs={24} sm={24} md={14} lg={14}>
										<Chart
											chartType="ComboChart"
											width="100%"
											data={testType&&testType===4?AllGraphData&&allTestsTotal!==0&&AllGraphData.length>0?AllGraphData:[]:
												testType&&testType===1?fullLengthGraphData&&fullLengthGraphData.length>0?fullLengthGraphData:[]:testType&&testType===2?
													categoryGraphData&&categoryGraphData.length>0?categoryGraphData:[]:testType&&testType===3?topicGraphData&&topicGraphData.length>0?topicGraphData:[]:[]}
											options={testType&&testType===1||testType&&testType===2||testType&&testType===4?optionsforFullLengthAndCategory:testType&&testType===3?optionsforTopic:{}}
										/>
									</Col> */}
									<Col xs={24} sm={24} md={14} lg={14}>
										{testType&&testType===1?
											fullLengthGraphData&&fullLengthGraphData.length>1?
												chartTag(optionsforFullLengthAndCategory,fullLengthGraphData)
												:noDataAvailableText()
											:
											testType&&testType===2?
												categoryGraphData&&categoryGraphData.length>1?
													chartTag(optionsforFullLengthAndCategory,categoryGraphData)
													:noDataAvailableText()
												:testType&&testType===3?
													topicGraphData&&topicGraphData.length>1?
														chartTag(optionsforTopic,topicGraphData)
														:noDataAvailableText():
													testType&&testType===4?
														AllGraphData&&AllGraphData.length>1 && allTestsTotal !==0?
															chartTag(optionsforFullLengthAndCategory,AllGraphData)
															:noDataAvailableText():
														""}
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default TestScoreGraph;
