import React,{ useEffect,useState } from 'react';
import { Breadcrumb, Row, Col, Card, Table,BackTop, DatePicker, Form, Select, Space, Radio, Button,  } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
import { fetchApi } from "../../../services/api";
// import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import moment from 'moment';
import { Chart } from "react-google-charts";
import { registrationFilter } from '../../../globalFunctions/GlobalFunctions';
import constants from '../../../constants/constants';
const { Option } = Select;
const ScoreCardPieGraph =()=> {
	const history = useHistory();
	const [allTestData, setAllTestData] = useState({});
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [testType,setTestType]=useState(4);
	const [AllGraphData, setAllGraphData] = useState([]);
	const [AllData, setAllData] = useState([]);
	const [fullLengthGraphData, setFullLengthGraphData] = useState([]);
	const [fullLengthData, setFullLengthData] = useState([]);
	const [categoryData, setCategoryData] = useState([]);
	const [categoryGraphData, setCategoryGraphData] = useState([]);
	const [topicData, setTopicData] = useState([]);
	const [topicGraphData, setTopicGraphData] = useState([]);
	const [duration,setDuration]=useState("1");
	const [form] = Form.useForm();
	useEffect(() => {
		// setDateFilters(constants.startDateOfTheYear,constants.endDateOfTheYear);
		fetchTableData();
	}, []);
	// const setDateFilters=(startDate,endDate,durationValue)=>{
	// 	setDuration(durationValue);
	// 	setStartDate(moment(startDate).format("MM/DD/YYYY"));
	// 	setEndDate(moment(endDate).format("MM/DD/YYYY"));
	// 	form.setFieldsValue({
	// 		from:moment(startDate),
	// 		to:moment(endDate)
	// 	});
	// 	setDuration("0");
	// };
	// const noDataAvailableText=()=>{
	// 	return(
	// 		<>							
	// 			<div className="nograph-div">
	// 				<span>No Data Available.</span>
	// 			</div>
	// 		</>
	// 	);
	// };
	const fetchTableData = (startDateValue,endDateValue) =>{
		var startDate=null;
		var endDate=null;
		if(startDateValue&&endDateValue){
			startDate=moment(startDateValue).format("YYYY-MM-DD");
			endDate=moment(endDateValue).format("YYYY-MM-DD");
		}else{
			var date = new Date(), y = date.getFullYear(), m = date.getMonth();
			var firstDay = new Date(y, m, 1);
			var lastDay = new Date(y, m + 1, 0);
			startDate=moment(firstDay).format("YYYY-MM-DD");
			endDate=moment(lastDay).format("YYYY-MM-DD");
			setStartDate(moment(startDate).format("MM/DD/YYYY"));
			setEndDate(moment(endDate).format("MM/DD/YYYY"));
			form.setFieldsValue({
				from:moment(startDate),
				to:moment(endDate)
			});
		}
		fetchApi(`/get-piechart?start_date=${startDate}&end_date=${endDate}`, "get").then(
			(res) => {
				if (res && res.data) {
					setAllTestData(res.data);
					setCategoryData(res.data?.Category);
					setCategoryData([]);//remove
					setTopicData(res.data?.Topic);
					if(res.data.All){
						setAllData(res.data.All);
						let allChartData = [
							["Range", "Total"],
						];
						for (let index = 0; index < res.data.All.length; index++) {
							if(res.data.All[index].count){
								let all=[];
								all.push(res.data.All[index].range,Number(res.data.All[index].count));
								allChartData.push(all);
							}
						}
						setAllGraphData(allChartData);
					}
					if(res.data.Full_length){
						setFullLengthData(res.data?.Full_length);
						let fullLengthChartData = [
							["Range", "Total"],
						];
						for (let index = 0; index < res.data.Full_length.length; index++) {
							if(res.data.Full_length[index].count){
								let fullLength=[];
								fullLength.push(res.data.Full_length[index].range,Number(res.data.Full_length[index].count));
								fullLengthChartData.push(fullLength);
							}
						}
						setFullLengthGraphData(fullLengthChartData);
					}
					if(res.data.Category){
						setCategoryData(res.data.Category);
						let categoryChartData = [
							["Range", "Total"],
						];
						for (let index = 0; index < res.data.Category.length; index++) {
							if(res.data.Category[index].count){
								let category=[];
								category.push(res.data.Category[index].range,Number(res.data.Category[index].count));
								categoryChartData.push(category);
							}
						}
						setCategoryGraphData(categoryChartData);
					}
					if(res.data.Topic){
						setTopicData(res.data.Topic);
						let topicChartData = [
							["Range", "Total"],
						];
						for (let index = 0; index < res.data.Topic.length; index++) {
							if(res.data.Topic[index].count){
								let topic=[];
								topic.push(res.data.Topic[index].range,Number(res.data.Topic[index].count));
								topicChartData.push(topic);
							}
						}
						setTopicGraphData(topicChartData);
					}
				}
			}
		);
	};
	const getFilterValue=(type)=>{
		switch (type) {
		case 2: return ['FPP','SAED','PIET','MIXED']		;	
		case 3:return ['TOPIC']			;
		case 1:return ['TEST']			;
		case 4:return ""			;
		}
	};
	const options = {
		title: "Score Percentage",
		is3D: false,
	};
	const column3 = [
		{
			title: "Range",
			key: "range",
			render: (_text, record) => {
				return <span>{record.range?record.range:""}</span>;
			},
			align: 'left',
			width:100
		},
		{
			title: "Total",
			key: "count",
			dataIndex: "count",
			// className: 'td-right',
			render: (_text, record) => {
				if(record.is_link==="t"){
					return <Button type='link' onClick={()=>{
						if(record.count){
							localStorage.setItem("propvalues",JSON.stringify({
								testType:4,
								testCategoryValue:getFilterValue(testType),
								startDate:startDate,
								endDate:endDate,
								result:"pass"
							}));
							window.open(constants.BaseUrl+"/test-results", '_blank');}}}
					>{record.count?record.count:0}</Button>;
				}else{
					return <Button type='link' onClick={()=>{
						if(record.count){
							localStorage.setItem("propvalues",JSON.stringify({
								testType:4,
								testCategoryValue:getFilterValue(testType),
								startDate:startDate,
								endDate:endDate,
								result:"fail"
							}));
							window.open(constants.BaseUrl+"/test-results", '_blank');}}}>{record.count?record.count:0}</Button>;
				}
			},
			align: 'center',
			width:100
		},
	];
	const handleStartDate = (_date, dateString) => {
		setStartDate(dateString);
		fetchTableData(dateString,endDate);
	};
	const handleEndDate = (_date, dateString) => {
		setEndDate(dateString);
		fetchTableData(startDate,dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const onChangeDateFilter = (e) => {
		setDuration(e);
		registrationFilter(e,setStartDateEndDate);
	};
	const onChangeTestType = (e) => {
		setTestType(e.target.value);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
		fetchTableData(startDate,endDate);
	};
	const totalColumn = (type_total,height) =>{
		return (<Table.Summary.Row style = {{ 
			fontWeight:500 ,
			height:height 
		}}>
			<Table.Summary.Cell align = "left" index={0}><span>Total</span></Table.Summary.Cell>
			<Table.Summary.Cell index={1} align='center'><span >{type_total}</span></Table.Summary.Cell>
		</Table.Summary.Row>);
	};
	const noDataAvailableText=()=>{
		return(
			<>							
				<div className="nograph-div">
					<span>No Data Available.</span>
				</div>
			</>
		);
	};
	const chartTag=(options,data)=>{
		return(
			<Chart
				chartType="PieChart"
				width="100%"
				height="100%"
				data={data}
				options={options}
			/>);
	};
	return (
		<>
			<div className="div">
				<BackTop/>
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>Score Percentage</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" title={<span className="text-uppercase">Score Percentage</span>}
								extra={
									<Form layout="horizontal" form = {form}>
										<Space>
											<Form.Item className="label-default mb-0">
												<Radio.Group value={testType} onChange={onChangeTestType} placeholder="Select test type" >
													<Radio value={4}>All</Radio>
													<Radio value={1}>Full Length</Radio>
													<Radio value={2}>Category</Radio>
													<Radio value={3}>Topic</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="From:" name = "from">
												<DatePicker defaultValue={startDate} onChange={ handleStartDate} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="To:" name = "to">
												<DatePicker defaultValue={endDate} onChange={handleEndDate} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Select value={duration} style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
													<Option value="0">This Year</Option>
													<Option value="7">Last Year</Option>
													<Option value="1">This Month</Option>
													<Option value="2">Last Month</Option>
													<Option value="3">Last 3 Months</Option>
													<Option value="4">This Month (Last Year)</Option>
													<Option value="5">Last 3 Months (Last Year)</Option>
													<Option value="6">Previous Years</Option>
												</Select>
											</Form.Item>
										</Space>
									</Form>
								}
							>
								<Row>
									<Col xs={24} sm={24} md={10} lg={10}>
										<span style = {{ 
											fontWeight:500,
											fontSize:"medium"
										}}>{startDate&&endDate&&`Search Criteria: From: ${moment(startDate).format("MM/DD/YYYY")} To: ${moment(endDate).format("MM/DD/YYYY")}`}</span>
										<Table className="gap-table"
											bordered
											columns={column3}
											dataSource={testType&&testType===4?AllData&&AllData.length>0?AllData:[]: testType&&testType===1?fullLengthData&&fullLengthData.length>0?fullLengthData:[]:testType&&testType===2?
												categoryData&&categoryData.length>0?categoryData:[]:testType&&testType===3?topicData&&topicData.length>0?topicData:[]:[]}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													{
														totalColumn(
															testType&&testType===4?allTestData&&allTestData.All_total?allTestData.All_total[0].tests:0:
																testType&&testType===1?allTestData&&allTestData.Full_length_total?allTestData.Full_length_total[0].tests:0:
																	testType&&testType===2?allTestData&&allTestData.Category_total?allTestData.Category_total[0].tests:0:
																		testType&&testType===3?allTestData&&allTestData.Topic_total?allTestData.Topic_total[0].tests:0:0
														)
													}
												</Table.Summary>
											)}
										/>
									</Col>
									{/* <Col xs={24} sm={24} md={14} lg={14}>
										<Chart
											chartType="ComboChart"
											width="100%"
											data={testType&&testType===4?AllGraphData&&allTestsTotal!==0&&AllGraphData.length>0?AllGraphData:[]:
												testType&&testType===1?fullLengthGraphData&&fullLengthGraphData.length>0?fullLengthGraphData:[]:testType&&testType===2?
													categoryGraphData&&categoryGraphData.length>0?categoryGraphData:[]:testType&&testType===3?topicGraphData&&topicGraphData.length>0?topicGraphData:[]:[]}
											options={testType&&testType===1||testType&&testType===2||testType&&testType===4?optionsforFullLengthAndCategory:testType&&testType===3?optionsforTopic:{}}
										/>
									</Col> */}
									<Col xs={24} sm={24} md={14} lg={14}>
										{testType&&testType===1?
											fullLengthGraphData&&fullLengthGraphData.length>1?
												chartTag(options,fullLengthGraphData)
												:noDataAvailableText()
											:
											testType&&testType===2?
												categoryGraphData&&categoryGraphData.length>1?
													chartTag(options,categoryGraphData)
													:noDataAvailableText()
												:testType&&testType===3?
													topicGraphData&&topicGraphData.length>1?
														chartTag(options,topicGraphData)
														:noDataAvailableText():
													testType&&testType===4?
														AllGraphData&&AllGraphData.length>1?
															chartTag(options,AllGraphData)
															:noDataAvailableText():
														""}
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default ScoreCardPieGraph;
