import React,{ useEffect,useState } from 'react';
import { Breadcrumb,Row, Col, Card, Table, BackTop,Button,Spin,Tooltip, Space } from 'antd';
import { fetchApi } from "../../../services/api";
import constants from "../../../constants/constants";
import Loader from "../../Common/Cred/Loader";
import { useHistory } from "react-router";
import { EditOutlined ,InfoCircleOutlined } from '@ant-design/icons';
// const { Meta } = Card;
import moment from "moment";
const CronSetting =() => {
	const [data, setData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [page, setPage] = useState(1);
	const history = useHistory();
	const columns = [
		{
			title: 'Action',
			align: 'center',
			render: (record) => {
				console.log(record.id,"id");
				return <Tooltip title={'Edit'}><Button type="text" onClick = {() =>{history.push({ 
					pathname:"/edit-cronsetting" ,
					state:{ cronSettingId:record?.id }
				});} }  icon={<EditOutlined/>}></Button></Tooltip>;
			}
		},
		{
			title: '#',
			key: 'id',
			render: (value, item, index) => (page - 1) * 10 + index + 1,
		},
		{
			title: "Type",
			key: "Type",
			dataIndex: "type",
			render: (record,object) => {
				return (
					<Space>
						{object.cron_info?
							<Tooltip title={object.cron_info}><InfoCircleOutlined/></Tooltip>:null}
						<span>
							{record? record:""}
						</span>
					</Space>
				);
			}
		},
		{
			title: "From date",
			key: "From_date",
			dataIndex: "from_date",
			render: (record) => {
				let formate = "MM/DD/YYYY h:mm:ss";
				return (
					<span>
						{record? moment(record).format(formate):""}
					</span>
				);
			}
			// width: 150
		},
		{
			title: "Last run date",
			key: "Last_run_date",
			dataIndex: "last_run_date",
			render: (record) => {
				let formate = "MM/DD/YYYY h:mm:ss";
				return (
					<span>
						{record? moment(record).format(formate):""}
					</span>
				);
			}
		},
		{
			title: "Response",
			key: "Response",
			dataIndex: "response",
			render: (record) => {
				if (record) {
					if (record.length > 20){
						return <Button onClick = {()=>redirectToJsonData(record,"responseData","/responseData")} type = "link">{record.slice(0, 20)+"..."}</Button>;
					} else {
						return <Button onClick = {()=>redirectToJsonData(record,"responseData","/responseData")} type = "link">{record}</Button>;
					}
				}else{
					return <span></span>;
				}
			},
			width: 200,
			// align: 'center'
		},
		{
			title: "Test response",
			key: "Test_response",
			dataIndex: "test_response",
			render: (record) => {
				if (record) {
					// sessionStorage.setItem("testResponseData", record);
					if (record.length > 20){
						return <Button onClick = {()=>redirectToJsonData(record,"testResponseData","/testResponseData")} type = "link">{record.slice(0, 20)+"..."}</Button>;
					} else {
						return <Button onClick = {()=>redirectToJsonData(record,"testResponseData","/testResponseData")} type = "link">{record}</Button>;
					}
				}else{
					return <span></span>;
				}
			},
			width: 200,
			// align: 'center'
		},
		{
			title: "Send Email",
			key: "send_email",
			dataIndex: "send_email",
			render: (record) => {
				return (
					<Space>
						<span>
							{record? record==="t"?"Yes":"No":""}
						</span>
					</Space>
				);
			}
		},
	];
	const redirectToJsonData = (record,setItemKey,path) => {
		sessionStorage.setItem(setItemKey, record);
		window.open(constants.BaseUrl+path, '_blank');
	};
	const fetchCronsettingData = () => {
		fetchApi('/crons-results', "get").then((res) => {
			if (res && res.code === 200) {
				if(res?.data?.length > 0){
					let sortData = res.data;
					sortData.sort((a, b) => {
						return a?.type.localeCompare(b?.type);
					});
					console.log("sortData",sortData);
					setData(sortData);
					setLoader(false);
				}
			}else{
				setData([]);
				setLoader(false);
			}
		});
	};
	useEffect(() => {
		sessionStorage.removeItem("testResponseData");
		sessionStorage.removeItem("responseData");
		fetchCronsettingData();	
	}, []);
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="div">
					<div className="p-3">
						<Breadcrumb>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/masters-menu" });
							}}>Masters</a></Breadcrumb.Item>
							<Breadcrumb.Item>{constants.cronSetting}</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<BackTop/>
					<Row gutter={[24, 24]} >
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">{constants.cronSetting}</span>}>
								<Row gutter={[24, 24]}>
									<Col xs={24} sm={24} md={24} lg={24}>
										<Table className="gap-table mytest-table"
											pagination={{
												onChange(current) {
													setPage(current);
												}
											}}
											locale={{ emptyText: 'No Records Found' }}
											dataSource={data.length > 0 ?data:[]} 
											columns={columns}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row> 
				</div>
			</Spin>
		</>
	);
};
export default CronSetting;