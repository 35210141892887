import React,{ useEffect,useState } from 'react';
import { Breadcrumb,Row, Col, Card, Table, BackTop,Tag,Space,Tooltip,Button,Form,Radio,DatePicker,Select,Input,Divider,Spin, Modal, Popconfirm, message, Checkbox } from 'antd';
import { fetchAdminApi, fetchApi } from "../../../services/api";
import { FilterOutlined,FileSearchOutlined, HourglassOutlined, StarFilled,SearchOutlined,ReloadOutlined ,CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import { useHistory,useLocation } from "react-router";
import constants from "../../../constants/constants";
import { displayOtherUniversityAlaska } from "../../../globalFunctions/GlobalFunctions";
import Loader from "../../Common/Cred/Loader";
import { COOKIE, getCookie } from '../../../services/cookie';
const { Option } = Select;
const { Search } = Input;
const { Meta } = Card;
const TestResult =(props)=> {
	let location = useLocation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [showFilterForm, setShowFilterForm] = useState(false);
	const [tutorialResult] = useState(location?.pathname === "/tutorial-result" ? true:false );
	const [data, setData] = useState([]);
	// const [radioButtonStatus, setRadioButtonStatus] = useState("4");
	const [radioTestResults, setRadioTestresults] = useState(props&&props.location&&props.location.state?'1':null);
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [listCountries, setCountries] = useState([]);
	const [listStates, setListStates] = useState([]);
	const [listUniversities, setUniversities] = useState([]);
	const [passPercentageDate,setPassPercentageDate]=useState("");
	const [filteredSearchCriteria, setfilteredSearchCriteria] = useState("");
	const [stateBlock, setStateBlock] = useState(false);
	const [uniBlock, setUniBlock] = useState(false);
	const [tempUniversity, setTempUniversity] = useState([]);
	const [topicList,setTopicList]=useState([]);
	const [disableTopic,setDisableTopic]=useState(true);
	const [disableFulLength,setDisablefullLength]=useState(true);
	const [filterMessage, setFilterMessage] = useState({
		university : "",
		country:"",
		state:""
	});
	const [totalCountReport, setTotalCountReport] = useState(0);
	const [current, setCurrent] = useState(1);
	const [sortOrder, setsortOrder] = useState("");
	const [sortColumnKey, setsortColumnKey] = useState("");
	const [filterParams, setfilterParams] = useState(null);
	const [url, setUrl] = useState(``);
	const [loader,setLoader]=useState(false);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [sortedInfo, setsortedInfo] = useState(null);
	const [refreshapibuild, setrefreshapibuild] = useState(false);
	const [role, setRole] = useState("");
	const [showTestScoresCrumb,setShowTestScoreCrumb]=useState(false);
	const [listAvailable,setListAvailable]=useState(false);
	const [passedPropsValues,]=useState(props&&props.location&&props.location.state?props&&props.location&&props.location.state:localStorage.getItem("propvalues")?JSON.parse(localStorage.getItem("propvalues")):null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalData,setModalData]=useState(null);
	const [isCleared,setIsCleared]=useState(1);
	const [isfilterChanged,setisFilterChanges]=useState(false);
	useEffect(() => {
		if(listAvailable===true){
			fetchScoreCards();
		}
	}, [listAvailable]);
	useEffect(() => {
		fetchTopicOption();
		fetchCountries();
		fetchStates();
		fetchUniversities();
	}, []);
	//To Fetch the data for test taken by the user
	const fetchScoreCards = (urlParam,pagination,fetch,noProp) => {
		let filterFromProps=``;
		let filterPassToUrl=``;
		// let temitem=JSON.parse(localStorage.getItem("propvalues"));
		if(passedPropsValues&&tutorialResult===false&&noProp!=="1"){
			let filterValue={};
			let fetchTest = ``;
			fetchTest += `&start_date=${moment(passedPropsValues.startDate).format("YYYY-MM-DD")}&end_date=${moment(passedPropsValues.endDate).format("YYYY-MM-DD")}`;
			if(passedPropsValues.testType&&passedPropsValues.testType===2){
				filterValue.test=[passedPropsValues.testCategoryValue];
				filterFromProps=`'${passedPropsValues.testCategoryValue}'`;
				filterPassToUrl=`&test=${filterFromProps}`;
				// fetchTest += `&test=${filterFromProps}`;
			}else if(passedPropsValues.testType&&passedPropsValues.testType===3){
				filterValue.test=['TOPIC'];
				filterFromProps=`'${passedPropsValues.testCategoryValue}'`;
				let tempIdItem=topicList.filter((item)=>item.topic_title===passedPropsValues.testCategoryValue);
				filterPassToUrl=`&topic_id=${tempIdItem[0].topic_id}`;
				fetchTest += `&topic_id=${tempIdItem[0].topic_id}`;
				form.setFieldsValue({ topics:tempIdItem[0].topic_id });
				setDisableTopic(false);
			}else if(passedPropsValues.testType&&passedPropsValues.testType===1){
				filterValue.test=['TEST'];
				filterFromProps=`'${passedPropsValues.testCategoryValue}'`;
				filterPassToUrl=`&test_no=${passedPropsValues.testCategoryValue}`;
				fetchTest += `&test_no=${passedPropsValues.testCategoryValue}`;
				form.setFieldsValue({ fulllengthtest:passedPropsValues.testCategoryValue });
				setDisablefullLength(false);
			}else if(passedPropsValues.testType&&passedPropsValues.testType===4){
				filterValue.test=passedPropsValues.testCategoryValue;
				let tutorialfilter=[];
				for(let i = 0; i < filterValue?.test?.length; i++) {
					tutorialfilter.push(`'${filterValue?.test[i]}'`);
				}
				let arrayTostring = tutorialfilter.toString();
				filterPassToUrl=`&test=${arrayTostring}`;
				// fetchTest += `&test=${arrayTostring}`;
				if(passedPropsValues.testCategoryValue.includes('TOPIC')){
					setDisableTopic(false);
				}
				if(passedPropsValues.testCategoryValue.includes('TEST')){
					setDisablefullLength(false);
				}
			}
			if(passedPropsValues.result&&passedPropsValues.result==="pass"){
				filterValue.result=[passedPropsValues.result];
				filterPassToUrl=filterPassToUrl+`&result=${passedPropsValues.result}`;
			}else if(passedPropsValues.result&&passedPropsValues.result==="fail"){
				filterValue.result=[passedPropsValues.result];
				filterPassToUrl=filterPassToUrl+`&result=${passedPropsValues.result}`;
			}
			// if(passedPropsValues.testType&&passedPropsValues.testType===4){
			filterValue.progress=['1'];
			filterPassToUrl=filterPassToUrl+`&progress=${filterValue.progress[0]}`;
			// }
			// if(!passedPropsValues.result){
			filterPassToUrl=filterPassToUrl+`&test_results=1`;
			fetchTest += `&test_results=1`;
			setRadioTestresults('1');
			// }
			if(!filteredInfo){
				setFilteredInfo(filterValue);
			}
			setShowTestScoreCrumb(true);
			setrefreshapibuild(!refreshapibuild);
			setfilterParams(fetchTest);
		}
		if(passedPropsValues&&tutorialResult===true){
			let filterValue={};
			let fetchTutorial = ``;
			fetchTutorial += `&start_date=${moment(passedPropsValues.startDate).format("YYYY-MM-DD")}&end_date=${moment(passedPropsValues.endDate).format("YYYY-MM-DD")}`;
			if(passedPropsValues.testType&&passedPropsValues.testType===1){
				filterValue.test=[passedPropsValues.testCategoryValue];
				filterFromProps=`'${passedPropsValues.testCategoryValue}'`;
				filterPassToUrl=`&test=${filterFromProps}`;
				// fetchTutorial += `&test=${filterFromProps}`;
			}else if(passedPropsValues.testType&&passedPropsValues.testType===2){
				filterValue.test=['TOPIC'];
				filterFromProps=`'${passedPropsValues.testCategoryValue}'`;
				let tempIdItem=topicList.filter((item)=>item.topic_title===passedPropsValues.testCategoryValue);
				filterPassToUrl=`&topic_id=${tempIdItem[0].topic_id}`;
				fetchTutorial += `&topic_id=${tempIdItem[0].topic_id}`;
				form.setFieldsValue({ topics:tempIdItem[0].topic_id });
				setDisableTopic(false);
			}else if(passedPropsValues.testType&&passedPropsValues.testType===3){
				filterValue.test=['CUSTOM'];
				filterFromProps=`'${passedPropsValues.testCategoryValue}'`;
				filterPassToUrl=`&test=${filterFromProps}`;
				// fetchTutorial += `&test=${filterFromProps}`;
				setDisablefullLength(false);
			}else if(passedPropsValues.testType&&passedPropsValues.testType===5){
				filterValue.test=['MARK_FOR_LATER'];
				filterFromProps=`'MARK_FOR_LATER'`;
				filterPassToUrl=`&test=${filterFromProps}`;
				// fetchTutorial += `&test=${filterFromProps}`;
				setDisablefullLength(false);
			}else if(passedPropsValues.testType&&passedPropsValues.testType===6){
				filterValue.test=['INCORRECT_ANS'];
				filterFromProps=`'INCORRECT_ANS'`;
				filterPassToUrl=`&test=${filterFromProps}`;
				// fetchTutorial += `&test=${filterFromProps}`;
				setDisablefullLength(false);
			}else if(passedPropsValues.testType&&passedPropsValues.testType===4){
				filterValue.test=passedPropsValues.testCategoryValue;
				let tutorialfilter=[];
				for(let i = 0; i < filterValue?.test?.length; i++) {
					tutorialfilter.push(`'${filterValue?.test[i]}'`);
				}
				let arrayTostring = tutorialfilter.toString();
				filterPassToUrl=`&test=${arrayTostring}`;
				// fetchTutorial += `&test=${arrayTostring}`;
				if(passedPropsValues.testCategoryValue.includes('TOPIC')){
					setDisableTopic(false);
				}
			}
			filterValue.progress=['1'];
			filterPassToUrl=filterPassToUrl+`&progress=${filterValue.progress[0]}`;
			filterPassToUrl=filterPassToUrl+`&test_results=1`;
			fetchTutorial += `&test_results=1`;
			setRadioTestresults('1');
			// }
			if(!filteredInfo){
				setFilteredInfo(filterValue);
			}
			setShowTestScoreCrumb(true);
			setrefreshapibuild(!refreshapibuild);
			setfilterParams(fetchTutorial);
		}
		let currPagination =pagination;
		let urlp =`/scorecards-test-results?type=${tutorialResult === true?1:0}`;
		if (urlParam) {
			if(fetch){
				urlp += urlParam+`&page=${currPagination - 1}`+fetch;
			}else{
				urlp += urlParam+`&page=${currPagination?currPagination - 1:0}`;
			}
		}else{
			if(fetch){
				urlp=`/scorecards-test-results?type=${tutorialResult === true?1:0}&limit=25&page=${currPagination - 1}`+fetch;
			}else{
				urlp += `&limit=25&page=${currPagination?currPagination - 1:0}`;
			}
			if(passedPropsValues&&noProp!=="1"){
				// if(props.location.state.testType&&props.location.state.testType===2){
				form.setFieldsValue({
					from:moment(passedPropsValues.startDate),
					to:moment(passedPropsValues.endDate)
				});
				setStartDate(passedPropsValues.startDate);
				setEndDate(passedPropsValues.endDate);
				urlp += `&start_date=${moment(passedPropsValues.startDate).format("YYYY-MM-DD")}&end_date=${moment(passedPropsValues.endDate).format("YYYY-MM-DD")}`;
				// }
				urlp+=filterPassToUrl.toString();
			}
		}
		// if(filteredInfo){
		// 	let test = [];
		// 	let progress = [];
		// 	let result = [];
		// 	if(filteredInfo?.test){
		// 		let enableTopicTemp=filteredInfo?.test.includes("TOPIC");
		// 		setDisableTopic(!enableTopicTemp);
		// 		let enableFullLengthTemp=filteredInfo?.test.includes("TEST");
		// 		setDisablefullLength(!enableFullLengthTemp);
		// 		for(let i = 0; i < filteredInfo?.test?.length; i++) {
		// 			test.push(`'${filteredInfo?.test[i]}'`);
		// 		}
		// 		let arrayTostring = test.toString();
		// 		urlp += `&test=${arrayTostring}`;
		// 	}
		// 	if(filteredInfo?.progress){
		// 		if(filteredInfo?.progress){
		// 			for(let p = 0; p < filteredInfo?.progress?.length; p++) {
		// 				progress.push(`'${filteredInfo?.progress[p]}'`);
		// 			}
		// 			let arrayTostring = progress.toString();
		// 			urlp += `&progress=${arrayTostring}`;
		// 		}
		// 	}
		// 	if(filteredInfo?.result){
		// 		if(filteredInfo?.result?.length === 1){
		// 			for(let r = 0; r < filteredInfo?.result?.length; r++) {
		// 				result.push(`${filteredInfo?.result[r]}`);
		// 			}
		// 			let arrayTostring = result.toString();
		// 			urlp += `&result=${arrayTostring}`;
		// 		}
		// 	}
		// }
		urlp += `&include_cleared=${isCleared}`;
		setLoader(true);
		fetchApi(urlp, "get").then((res) => {
			if (res && res.code === 200) {
				if(res?.data?.length > 0){
					setData(res.data);
					setPassPercentageDate(res.PASS_PERCENT_DATE);
					setTotalCountReport(res?.TOTAL_COUNT);
					setLoader(false);
					if(radioTestResults==='1'&&isfilterChanged===false){
						let tempfilteredInfo=filteredInfo?filteredInfo:{};
						tempfilteredInfo.progress=['1'];
						setFilteredInfo(tempfilteredInfo);
					}
					setisFilterChanges(true);
					// setFilterP("");
				}
			}else{
				setData([]);
				setTotalCountReport(0);
				setPassPercentageDate(null);
				setLoader(false);
			}
		});
	};
	const fetchTopicOption = () => {
		fetchAdminApi("/test-listing?type=topic_options", "get").then((res) => {
			if (res && res.data) {
				setTopicList(res.data);
				setListAvailable(true);
			}
			else {
				setListAvailable(false);
				setTopicList([]);
			}
		});
	};
	const filterSearchCriteriaMessage = (values) =>{
		setFilteredInfo(null);
		let array = [];
		if(values){
			if(values.role){
				array.push(`Role: ${role}`);
			}
			if(values.country){
				array.push(`Country: ${filterMessage.country}`);
			}
			if(values.state){
				array.push(`State: ${filterMessage.state}`);
			}
			if(values.university){
				array.push(`University: ${filterMessage.university}`);
			}
			if(values.from &&values.to){
				array.push(`${tutorialResult === true ? "Tutorial" :"Test"} Results from ${startDate} to ${endDate}`);
			}
			if(values.name){
				array.push(`Name : ${values.name}`);
			}
			if(array.length>=1){
				setfilteredSearchCriteria( array.join(", ")+".");
			}else{
				setfilteredSearchCriteria( array.join(", "));
			}
		}
	};
	const onFinishFilterSearch = (values) =>{
		setCurrent(1);
		let fetch = ``;
		if(values){
			if(values.country){
				fetch += `&country_id=${values.country}`;
			}
			if(values.from && values.to){
				fetch += `&start_date=${moment(values.from).format("YYYY-MM-DD")}&end_date=${moment(values.to).format("YYYY-MM-DD")}`;
			}
			if(values.name){
				fetch += `&name=${values.name}`;
			}
			if(values.state){
				fetch += `&state_id=${values.state}`;
			}
			if(values.university){
				fetch += `&university_id=${values.university}`;
			}
			if(values.role){
				if(values.role === "4"){
					fetch += `&occupation_type=${null}`;
				}else{
					fetch += `&occupation_type=${values.role}`;
				}
			}
			if(values.status){
				if(values.status =="4"){
					fetch += "";
				}else{
					fetch += `&is_active=${values.status}`;
				}	
			}
			if(values.fulllengthtest){
				fetch += `&test_no=${values.fulllengthtest}`;
			}
			if(values.topics){
				fetch += `&topic_id=${values.topics}`;
			}
			if(values.testResults){
				fetch += `&test_results=${values.testResults}`;
				if(values.testResults==='1'){
					fetch +=`&progress=${values.testResults}`;
				}
			}else if(radioTestResults){
				fetch += `&test_results=${radioTestResults}`;
				if(radioTestResults==='1'){
					fetch +=`&progress=${radioTestResults}`;
				}
			}
		}
		// setarrayLength(array);
		filterSearchCriteriaMessage(values);
		setfilterParams(fetch);
		setrefreshapibuild(!refreshapibuild);
		fetchScoreCards(url,1,fetch,"1");
		setsortedInfo(null);
	};
	const onChangeUniversity = (_value, option) =>{
		setFilterMessage({
			...filterMessage,
			university:option.children
		});
		// setUniversityMessage(option.children);
	};
	const onChangeState = (e, option) =>{
		form.setFieldsValue({ university:null });
		setFilterMessage({
			...filterMessage,
			state:option.key
		});
		Object.entries(listUniversities).forEach(([key, value]) => {
			if (key === option?.children) {
				let obj={};
				obj[e]=value;
				setTempUniversity(obj);
				// setUniBlock(true);
			}
			else if ( value === null) {
				setTempUniversity(null);
				// setUniBlock(false);
			}	
		});
		// setState(option.key);
	};
	const onChangeCountry = (e,value) =>{
		setFilterMessage({
			...filterMessage,
			country:value?.key
		});
		if (value?.key==="US") {
			// setSelectState(true);
			setStateBlock(true);
			setUniBlock(true);
		}
		else {
			// setSelectState(false);
			setStateBlock(false);
			setUniBlock(false);
		}
		// setCountry(option.key);
	};
	const redirectResult=(historyObject,object,isMagnify)=>{
		if(historyObject.is_full_length===true){
			let obj =  { 
				scoreCardId:historyObject.scorecard_id ,
				isReview:"t",
				userId:historyObject.stud_id,
				fromTestResult:true,
				returnTest:"Test Results"
			};
			sessionStorage.setItem("TestresultObject", JSON.stringify(obj));
			if(isMagnify==="1"){
				history.push({
					pathname: "/test",
					state: { 
						fromTestResult:true,
						userId:object && object.stud_id,
						name:object && object.name,
					}
				});
			}else{
				window.open(constants.BaseUrl+"/full-length-result-page", '_blank');
			}
		}else{
			if(historyObject.is_topic===true){
				let obj = { 
					scoreCardId:historyObject.scorecard_id,
					resultTypeFull:false,
					testCategory:null,
					isTopic:true,
					isReview:"t",
					userId:historyObject.stud_id,
					fromTestResult:true,
					returnTest:"Test Results"
				};
				sessionStorage.setItem("TestresultObject", JSON.stringify(obj));
				if(isMagnify==="1"){
					history.push({
						pathname: "/test",
						state: { 
							fromTestResult:true,
							userId:object && object.stud_id,
							name:object && object.name,
						}
					});
				}else{
					window.open(constants.BaseUrl+"/test-result", '_blank');
				}
			}else{
				if(historyObject.category_type==="MIXED"){
					let obj = { 
						scoreCardId:historyObject.scorecard_id,
						resultTypeFull:false,
						testCategory:historyObject.category_type,
						isTopic:false,
						isReview:"t",
						userId:historyObject.stud_id,
						fromTestResult:true,
						returnTest:"Test Results"
					};
					sessionStorage.setItem("TestresultObject", JSON.stringify(obj));
					if(isMagnify==="1"){
						history.push({
							pathname: "/test",
							state: { 
								fromTestResult:true,
								userId:object && object.stud_id,
								name:object && object.name,
							}
						});
					}else{
						window.open(constants.BaseUrl+"/test-result", '_blank');
					}
				}else{
					let obj =  { 
						scoreCardId:historyObject.scorecard_id,
						resultTypeFull:true,
						testCategory:historyObject.category_type,
						isTopic:false,
						isReview:"t",
						userId:historyObject.stud_id,
						fromTestResult:true,
						returnTest:"Test Results"
					};
					sessionStorage.setItem("TestresultObject", JSON.stringify(obj));
					if(isMagnify==="1"){
						history.push({
							pathname: "/test",
							state: { 
								fromTestResult:true,
								userId:object && object.stud_id,
								name:object && object.name,
							}
						});
					}else{
						window.open(constants.BaseUrl+"/test-result", '_blank');
					}
				}			
			}	
		}
	};
	const redirectStudentDetails=(studentId)=>{
		localStorage.setItem("studentId", studentId);
		localStorage.setItem("editRedirectLink","/test-results");
		window.open(constants.BaseUrl+"/view-student", '_blank');
	};
	const nameEditButton = (record,object) =>{
		return (<span><Button className = "px-0" type="link" 
			onClick={()=>redirectStudentDetails(object && object.stud_id)}
		>{record}</Button></span>);
	};
	const studentResults = (record,object) =>{
		if(tutorialResult === true){
			return (<span><Button type="link" icon={<SearchOutlined/>} onClick={() => history.push({
				pathname: "/tutorial",
				state: { 
					fromTutorialResult:true,
					userId:object && object.stud_id,
					name:object && object.name,
					type:1
				}
			})}></Button></span>);
		}else{
			return (<span><Button type="link" icon={<SearchOutlined/>} 
			// onClick={() => 
			// 	history.push({
			// 		pathname: "/test",
			// 		state: { 
			// 			fromTestResult:true,
			// 			userId:object && object.stud_id,
			// 			name:object && object.name,
			// 		}
			// 	})
				onClick={()=>redirectResult(record,object,"1")}
			></Button></span>);
		}
	};
	const columnFieldLessThan15Charc = (record) =>{
		if (record?.length > 20) {
			return <Space style = {{ width:"100px" }}>
				<Tooltip title={record}>
					<span>{record.slice(0, 20)}...</span>
				</Tooltip>
			</Space>;
		} else {
			return <span>{record}</span>;
		}
	};
	const handleReviewTutorial=(tutorial)=>{
		let reviewTutorial={};
		reviewTutorial.studId =tutorial?.stud_id;
		reviewTutorial.scoreCardId=tutorial?.scorecard_id;
		if(tutorial?.is_category === true){
			reviewTutorial.categoryId=tutorial?.category_id;
			reviewTutorial.type="category";
		}
		if(tutorial?.is_topic === true){
			reviewTutorial.topicId=tutorial?.topic_id;
			reviewTutorial.type="topic";
		}
		if(tutorial?.test === "CUSTOM"){
			reviewTutorial.type="custom";
		}
		if(tutorial?.is_topic === false){
			reviewTutorial.attempt=tutorial?.attempt;
		}
		reviewTutorial.review="tutorial";
		reviewTutorial.fromTutorialResult=true;
		sessionStorage.setItem("TutorialresultObject", JSON.stringify(reviewTutorial));
		window.open(constants.BaseUrl+"/review-tutorial", '_blank');
	};
	const topTenColumns = [
		{
			title: '#',
			key: 'index',
			dataIndex: "sr_no",
			render: (record) => {
				return (
					<span>
						{record? record:""}
					</span>
				);
			},
		},
		{
			title: "Name",
			key: "name",
			dataIndex: "name",
			render: (record, object) => {
				if(object.is_active==="4"){
					return <Space>
						{studentResults(record,object)}
						{nameEditButton("Stud - "+object.stud_id,object)}
					</Space>;
				}else{
					if (record.length > 15) {
						return <Space>
							{studentResults(record,object)}
							{nameEditButton(record.slice(0, 15)+"...",object)}
						</Space>;
					} else {
						return <Space>
							{studentResults(record,object)}
							{nameEditButton(record,object)}
						</Space>;
					}
				}
			},
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'name' ? sortedInfo?.order : null,
			width:tutorialResult === true ? 200:null
		},
		{
			title: "Username",
			key: "username",
			dataIndex: "username",
			// width:tutorialResult === false ? 100:150,
			render: (record,object) => {
				if(object.is_active==="4"){
					return <span></span>;
				}else{
					if (record.length > 15) {
						return <Space>
							<Tooltip title={record}>
								<span>{record.slice(0, 15)}...</span>
							</Tooltip>
						</Space>;
					} else {
						return <span>{record}</span>;
					}
				}
			},
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'username' ? sortedInfo?.order : null,
			width: 150
		},
		{
			title: <Tooltip title="Country"> <span>C</span></Tooltip>,
			key: "country",
			dataIndex: "country",
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'country' ? sortedInfo?.order : null,
			align: 'center',
			width:tutorialResult === true ? 100:50
		},
		{
			title: <Tooltip title="State"> <span>S</span></Tooltip>,
			key: "state",
			dataIndex: "state",
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'state' ? sortedInfo?.order : null,
			align: 'center',
			width:tutorialResult === true ? 100:50
		},
		{
			title: "University",
			key: "university",
			dataIndex: "university",
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'university' ? sortedInfo?.order : null,
			render: (record) => {
				return columnFieldLessThan15Charc(record);
			},
			width:200
		},
		{
			title:  <Tooltip title="Role"> <span>R</span></Tooltip>,
			key: "occupation_type",
			dataIndex: "role",
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'occupation_type' ? sortedInfo?.order : null,
			render: (record,type) => {
				let tooltip = "";
				if(type.role === "S"){
					tooltip = constants.Role_type.student;
				}else if( type.role === "P"){
					tooltip =constants.Role_type.professional;
				}else if ( type.role === "F"){
					tooltip =constants.Role_type.faculty;
				}else if ( type.role === "O"){
					tooltip =constants.Role_type.other;
				}else if ( type.role === "U"){
					tooltip =constants.Role_type.unspecified;
				}
				return <Tooltip title={tooltip}>
					<span onClick={()=>{
						tutorialResult === true ?
							null:
							// type.is_full_length===false?
							// 	null:
							showModal(tutorialResult);setModalData(type);
					}}>{record}</span>
				</Tooltip>;
			},
			width: 50,
			align: 'center',
		},
		{
			title: 'Type',
			key: 'type',
			render: (record) => {
				if(record?.is_topic === true){
					return <span>Topic</span>;
				}else if(record?.is_category === true){
					return <span>Category</span>;
				}else if (record?.category_type === "CUSTOM"){
					return <span>Custom</span>;
				}
			},
			width:150,
			hidden: tutorialResult === true ?false:true,
		},
		{
			title:'Test',
			key: 'test',
			width:100,
			filters: [
				{
					text: 'FPP',
					value: 'FPP',
				},
				{
					text: 'SAED',
					value: 'SAED',
				},
				{
					text: 'PIET',
					value: 'PIET',
				},
				{
					text: 'Mixed',
					value: 'MIXED',
				},
				{
					text: 'Topics',
					value: 'TOPIC',
				},
				{
					text: 'Full Length',
					value: 'TEST',
				},
			],
			filteredValue: filteredInfo?.test ?filteredInfo?.test: null,
			render: (record) => {
				if (record?.topic_abb !== null) {
					return <Space>
						<Tooltip title={record?.category_type}>
							<span>{record?.topic_abb}</span>
						</Tooltip>
					</Space>;
				} else {
					return <span>{record?.test}</span>;
				}
			},
			hidden: tutorialResult === true ?true:false,
		},
		{
			title: 'Tutorial',
			key: 'test',
			width:200,
			filters: [
				{
					text: 'FPP',
					value: 'FPP',
				},
				{
					text: 'SAED',
					value: 'SAED',
				},
				{
					text: 'PIET',
					value: 'PIET',
				},
				{
					text: 'Custom',
					value: 'CUSTOM',
				},
				{
					text: 'Topics',
					value: 'TOPIC',
				},
				{
					text: 'Mark for later',
					value: 'MARK_FOR_LATER',
				},
				{
					text: 'Incorrect Answers',
					value: 'INCORRECT_ANS', 
				}
			],
			filteredValue: filteredInfo?.test ?filteredInfo?.test: null,
			render: (record) => {
				if (record?.topic_abb !== null) {
					return <Space>
						<Tooltip title={record?.category_type}>
							<span>{record?.topic_abb}</span>
						</Tooltip>
					</Space>;
				} else {
					return <span>{record?.test}{" "}{record?.test==="MARK FOR LATER"||record?.test==="INCORRECT ANS"?"":`#${record?.attempt}`}</span>;
				}
			},
			hidden: tutorialResult === true ?false:true,
		},
		{
			title:  <Tooltip title="Attempt"> <span>A</span></Tooltip>,
			key: 'attempt',
			align: 'center',
			render: (record) => {
				return (
					<span>{record&&record.attempt?record.attempt:""}</span>
				);
			},
			width:50,
			hidden: tutorialResult === true ?true:false,
		},
		{
			title:  <Tooltip title="Mode"> <span>M</span></Tooltip>,
			key: 'mode',
			align: 'center',
			render: (record) => {
				return (
					<span>
						{record&&record.time != null&&record.is_timed&&record.is_timed==="t"? <HourglassOutlined/>:""}
					</span>
				);
			},
			width:50,
			hidden: tutorialResult === true ?true:false,
		},
		{
			title: 'Progress',
			key: 'progress',
			width:100,
			filters: [
				{
					text: 'Complete',
					value: '1',
				},
				{
					text: 'Paused',
					value: '2',
				},
				{
					text: 'In Progress',
					value: '0',
				}
			],
			filteredValue: filteredInfo?.progress? filteredInfo?.progress : null,
			hidden: tutorialResult === true ?true:false,
			render: record => {
				let color="";
				let ifcompleted = "";
				let progress = '';
				if (record.status==="1") {
					progress = "Completed";
					color="green";
					if(record.time != null&&record.is_timed&&record.is_timed==="t"){
						ifcompleted="in " + record.time;
						progress = "Completed" + "...";
					}
				}
				else {
					color="blue";
					if(record.time != null&&record.is_timed&&record.is_timed==="t"){
						ifcompleted=<span>{record.remaining_test_time}<span className="cyan"> remaining</span></span>;
						progress = <span><span>{record.answered_questions }</span><span onClick={()=>{window.open(constants.BaseUrl + "/admin-result-json?"+record.scorecard_id);}}>/</span><span>{record.total_questions} {"..."}</span> </span>;
					}
					else {
						progress =<span><span>{record.answered_questions}</span><span onClick={()=>{window.open(constants.BaseUrl + "/admin-result-json?"+record.scorecard_id);}}>/</span><span>{record.total_questions}</span></span>;
					}
				}
				return(
					<>
						<Tooltip title={ifcompleted}>
							{record?.status==="2"?
								<Tag color={"red"}>
									{"Paused"}
								</Tag>:record?.status==="0"?<Tag color={"orange"}>
									{"In Progress"}
								</Tag>:null}
							<Tag color={color}>
								{progress}
							</Tag>
						</Tooltip>
					</>
				);
			}
		},
		{
			title: 'Progress',
			key: 'progress',
			width:100,
			filters: [
				{
					text: 'Complete',
					value: '1',
				},
				{
					text: 'In Progress',
					value: '0',
				},
			],
			hidden: tutorialResult === true ?false:true,
			filteredValue: filteredInfo?.progress? filteredInfo?.progress : null,
			render: record => {
				let color="";
				let progress = '';
				if (record.status==="1") {
					progress = "Completed";
					color="green";
				}
				else {
					color="blue";
					progress = <span><span>{record.answered_questions }</span><span onClick={()=>{window.open(constants.BaseUrl + "/admin-result-json?"+record.scorecard_id);}}>/</span><span>{record.total_questions}</span> </span>;
				}
				return(
					<>
						{record?.status==="0"?<Tag color={"orange"}>
							{"In Progress"}
						</Tag>:null}
						<Tag color={color}>
							{progress}
						</Tag>
					</>
				);
			}
		},
		{
			title: <Tooltip title={tutorialResult === true ?"Review Tutorial":"View Test Result"}> <span>{tutorialResult === true ?'RT':'VTR'}</span></Tooltip>,
			align: 'center',
			render: (record) => {
				return (
					<Space>
						{record&&record.status&&record.status==="1"?
							tutorialResult === true ?
								record?.test==="MARK FOR LATER"||record?.test==="INCORRECT ANS"?null:
									<Tooltip title={ "Review Tutorial" }><Button type="link" icon={<FileSearchOutlined/>} onClick={()=>handleReviewTutorial(record)}></Button></Tooltip>
								:
								<Tooltip title={ record.is_full_length === true ? "Review Incorrect" : "View Test Result" }><Button type="link" icon={<FileSearchOutlined/>} onClick={()=>redirectResult(record,null,"0")}></Button></Tooltip>
							:""
						}
					</Space>
				);
			},
			width:50,
			// hidden: tutorialResult === true ?true:false,
		},
		{
			title: 'Date',
			key: 'attemptedOn',
			sorter: (a, b) => new Date(a.attempt_date) - new Date(b.attempt_date),
			sortOrder: sortedInfo?.columnKey === 'attemptedOn' ? sortedInfo?.order : null,
			render: (record) => {
				return (
					<span>{record&&record.attempt_date ? (<Tooltip title={moment(record.attempt_date).format("MM/DD/YYYY")+" "+record.attempt_time}><span>{moment(record.attempt_date).format("MM/DD/YYYY")}</span></Tooltip>) : ("-")}</span>
				);
			},
			width:tutorialResult === true ?150:200
		},
		{
			title: <Tooltip title="Questions"> <span>Qns.</span></Tooltip>,
			key: 'questions',
			align:'right',
			render: (record) => {
				var per_result = <div><Space size = {1}><span className='mx-0 w-auto'>{record.total_corr_ans}</span><span className='mx-0 w-auto' onClick={()=>{window.open(constants.BaseUrl + "/admin-result-json?"+record.scorecard_id);}}><span className={record.is_autoclose ==="t"?"errorMsg":""}>/</span></span><span className='mx-0 w-auto'>{record.total_questions}</span></Space></div>;
				if (record.status==="1"){
					return (
						<>{per_result}</>
					);
				}
				else {
					return (
						<div></div>
					);
				}
			}
		},
		{
			title: <Tooltip title="Points"> <span>Pts.</span></Tooltip>,
			key: 'points',
			dataIndex: "points",
			align:'center',
			className: 'td-right',
			render: (record,obj) => {
				if(tutorialResult === true){
					if(record === "0"){
						return <span></span>;
					}else{
						if(obj.is_processed==="f"){
							return <span className="p-relative red-1" style={{ width:"30px" }}>{record ? record:""}</span>;
						}else{
							return <span className="p-relative" style={{ width:"30px" }}>{record ? record:""}</span>;
						}
					}
				}else{
					if(obj?.status ==="2" || obj?.status === "0"){
						return <span></span>;
					}else{
						if(obj.is_processed==="f"){
							return <span className="p-relative" style={{ width:"38px" }}>{record ? record:""}</span>;
						}else{
							return <span className="p-relative" style={{ width:"38px" }}>{record ? record:""}</span>;
						}
					}
				}
			},
		},
		{
			title: 'Score',
			key: 'scr',
			align:'right',
			className: 'td-right',
			render: (record) => {
				if (record.is_full_length=== true){
					return (
						<span className="p-relative">{record?.marks_scored}</span>
					);
				}
				else {
					return (
						<div></div>
					);
				}
			},
			hidden: tutorialResult === true ?true:false,
		},
		{
			title: 'Result',
			key: "result",
			align:'center',
			className: 'td-right result-col center-star',
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'result' ? sortedInfo?.order : null,
			filters: [
				{
					text: 'Pass',
					value: 'pass',
				},
				{
					text: 'Did not pass',
					value: 'fail',
				},
			],
			filteredValue: filteredInfo?.result ? filteredInfo?.result :null,
			hidden: tutorialResult === true ?true:false,
			render: record => {
				if (record.status==="1") {
					let attemptedDate=record.attempt_date?record.attempt_date:"";
					if(new Date(attemptedDate)>=new Date(passPercentageDate)){
						if (record.percentage >= constants.percentagePass){
							return (
								<div>
									<span className="p-relative d-flex">{record.percentage}{returnPercentColor(record.is_processed)} <StarFilled className="yellow mytest-star"/></span>
								</div>
							);
						}else{
							return (
								<div>
									<span className="d-flex">{record.percentage}{returnPercentColor(record.is_processed)}</span>
								</div>
							);
						}
					}else if(new Date(attemptedDate)<new Date(passPercentageDate)){
						if (record.percentage >= constants.percentPassForOlder){
							return (
								<div>
									<span className="p-relative d-flex">{record.percentage}{returnPercentColor(record.is_processed)} <StarFilled className="yellow mytest-star"/></span>
								</div>
							);
						}else{
							return (
								<div>
									<span className="d-flex">{record.percentage}{returnPercentColor(record.is_processed)}</span>
								</div>
							);
						}
					}
				}
				else{
					return (
						<div></div>
					);
				}
			}
		}
	].filter(item => !item.hidden);
	const selectFilterOption = (input,option)=>{
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};
	const returnPercentColor=(is_processed)=>{
		if(is_processed==="f"){
			return <p className='red-1 mb-0'>%</p>;
		}else{
			return <p className='mb-0'>%</p>;
		}
	};
	// const onChangeStatus = (checkedValues) =>{
	// 	setRadioButtonStatus(checkedValues);
	// };
	const onChangeTestResults = (checkedValues) =>{
		setRadioTestresults(checkedValues.target.value);
	};
	const filterReset = () =>{
		form.resetFields();
		// form.setFieldsValue({ status:"4" });
		setfilteredSearchCriteria("");
		setUrl(``);
		setfilterParams(null);
		setrefreshapibuild(!refreshapibuild);
		setFilteredInfo(null);
		setsortedInfo(null);
		setCurrent(1);
		setData([]);
		setTotalCountReport(0);
		setDisableTopic(true);
		setDisablefullLength(true);
		setRadioTestresults(null);
		// fetchScoreCards();
	};
	const from = (_date, dateString) => {
		setStartDate(dateString);
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const filterButton = () =>{
		form.setFieldsValue({ status:"4" });
		setShowFilterForm(!showFilterForm);
	};
	// const select =() =>{
	// 	console.log("select onclick");
	// 	form.setFieldsValue({ country:"238" });
	// };
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				//removed the US Country by filtering 
				let remainingArr = res.data.filter(data => data.country_id != '238');
				//Adding a US country at zero index
				remainingArr.unshift({
					country_id: "238",
					country_code: "US",
					country_name: "United States"
				});
				setCountries(remainingArr);
			} else {
				setCountries([]);
			}
		});
	};
	const fetchStates = () => {
		fetchApi(`/states?country_code=US`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setListStates(res.data);
			} else {
				setListStates([]);
			}
		});
	};
	const fetchUniversities = () => {
		fetchApi(`/universities?country_code=US`, "get").then(
			(res) => {
				if (res && res.data) {
					Object.entries(res.data).forEach(([key, value]) => {
						displayOtherUniversityAlaska(key,value);
					});
					setUniversities(res.data);
				} else {
					setUniversities(null);
				}
			}
		);
	};
	const onchangeTable = (pagination, filters, sorter) =>{
		let sortOrderr = null,
			sortCol = null;
		let col_to_send = "";
		let sort_type ="";
		let url = ``;
		if(pagination){
			url += `&limit=${pagination.pageSize}`;
			setCurrent(pagination.current);
		}
		let test = [];
		let progress = [];
		let result = [];
		if(filters){
			setFilteredInfo(filters);
			if(filters?.test){
				let enableTopicTemp=filters?.test.includes("TOPIC");
				setDisableTopic(!enableTopicTemp);
				let enableFullLengthTemp=filters?.test.includes("TEST");
				setDisablefullLength(!enableFullLengthTemp);
				for(let i = 0; i < filters?.test?.length; i++) {
					test.push(`'${filters?.test[i]}'`);
				}
				let arrayTostring = test.toString();
				url += `&test=${arrayTostring}`;
			}
			if(filters?.progress){
				if(filters?.progress){
					for(let p = 0; p < filters?.progress?.length; p++) {
						progress.push(`'${filters?.progress[p]}'`);
					}
					let arrayTostring = progress.toString();
					url += `&progress=${arrayTostring}`;
					// if(startDate&&endDate){
					// 	console.log("3");
					// 	url	+= `&start_date=${moment(startDate).format("YYYY-MM-DD")}&end_date=${moment(endDate).format("YYYY-MM-DD")}`;
					// }
				}
			}
			if(filters?.result){
				if(filters?.result?.length === 1){
					for(let r = 0; r < filters?.result?.length; r++) {
						result.push(`${filters?.result[r]}`);
					}
					let arrayTostring = result.toString();
					url += `&result=${arrayTostring}`;
				}
			}
		}
		if(sorter){
			setsortedInfo(sorter);
			setsortOrder(sorter.order);
			setsortColumnKey(sorter.columnKey);
			//set variables
			sortOrderr = sorter.order;
			sortCol = sorter.columnKey;
		}else{
			sortOrderr = sortOrder;
			sortCol = sortColumnKey;
		}
		if (sortOrderr === "descend" && sortCol === "name") {
			col_to_send = "s.stud_firstname";
			sort_type = "DESC";
		}
		if (sortOrderr === "ascend" && sortCol === "name") {
			col_to_send = "s.stud_firstname";
			sort_type = "ASC";
		}
		if (sortOrderr === "descend" && sortCol === "username") {
			col_to_send = "s.login_username";
			sort_type = "DESC";
		}
		if (sortOrderr === "ascend" && sortCol === "username") {
			col_to_send = "s.login_username";
			sort_type = "ASC";
		}
		if (sortOrderr === "descend" && sortCol === "country") {
			col_to_send = "gc.country_code";
			sort_type = "DESC";
		}
		if (sortOrderr === "ascend" && sortCol === "country") {
			col_to_send = "gc.country_code";
			sort_type = "ASC";
		}
		if (sortOrderr === "descend" && sortCol === "state") {
			col_to_send = "gr.region_code";
			sort_type = "DESC";
		}
		if (sortOrderr === "ascend" && sortCol === "state") {
			col_to_send = "gr.region_code";
			sort_type = "ASC";
		}
		if (sortOrderr === "descend" && sortCol === "university") {
			col_to_send = "gu.university_name";
			sort_type = "DESC";
		}
		if (sortOrderr === "ascend" && sortCol === "university") {
			col_to_send = "gu.university_name";
			sort_type = "ASC";
		}
		if (sortOrderr === "descend" && sortCol === "attemptedOn") {
			col_to_send = "score_card.dt_created";
			sort_type = "DESC";
		}
		if (sortOrderr === "ascend" && sortCol === "attemptedOn") {
			col_to_send = "score_card.dt_created";
			sort_type = "ASC";
		}
		if (sortOrderr === "descend" && sortCol === "result") {
			col_to_send = "score_card.percent_scored";
			sort_type = "DESC";
		}
		if (sortOrderr === "ascend" && sortCol === "result") {
			col_to_send = "score_card.percent_scored";
			sort_type = "ASC";
		}
		if (sortOrderr === "ascend" && sortCol === "occupation_type") {
			col_to_send = "occupation_type";
			sort_type = "ASC";
		}
		if (sortOrderr === "descend" && sortCol === "occupation_type") {
			col_to_send = "occupation_type";
			sort_type = "DESC";
		}
		if (col_to_send && col_to_send.length) {
			url += `&sort=${sort_type}`;
			url += `&sort_column=${col_to_send}`;
		}
		setrefreshapibuild(!refreshapibuild);
		setUrl(url);
		fetchScoreCards(url,pagination.current,filterParams,"1");
	};
	const onChangeRole = (_value,option) => {
		setRole(option?.children);
	};
	const showModal = (isTutorial) => {
		if(isTutorial===false){
			setIsModalVisible(true);
		}
	};
	const handleOk = () => {
		setIsModalVisible(false);
		setModalData(null);
	};
	const handleCancel = () => {
		setIsModalVisible(false);
		setModalData(null);
	};
	const confirm = (e) => {
		console.log(e);
		deleteScorecard();
	};
	const cancel = (e) => {
		console.log(e);
	};
	const deleteScorecard = () => {
		let userId = getCookie(COOKIE.UserId);
		fetchAdminApi(`/delete_scorecard/${modalData.scorecard_id}/${userId}`, "get").then((res) => {
			if (res && res.code===200) {
				message.success(res.message);
				handleCancel();
				fetchScoreCards(url,current,filterParams,"1");
			} else {
				message.error(res.message);
				handleCancel();
			}
		});
	};
	const checkNameentered=(nameValue)=>{
		if(nameValue.trim().length>0){
			setIsCleared(0);
		}else{
			setIsCleared(1);
		}
	};
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="div">
					<div className="p-3">
						<Breadcrumb>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/analysis-menu" });
							}}>Analysis</a></Breadcrumb.Item>
							{showTestScoresCrumb?
								tutorialResult === false ?
									<Breadcrumb.Item ><a onClick = {() => {
										history.push("/test-scores");
									}}>Test Taken</a></Breadcrumb.Item>		:				
									<Breadcrumb.Item ><a onClick = {() => {
										history.push("/tutorial-scores");
									}}>Tutorial Taken</a></Breadcrumb.Item>
								:null
							}
							<Breadcrumb.Item>{tutorialResult === true ? constants.tutorialResults:constants.testResults}</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<BackTop/>
					<Row gutter={[24, 8]} className='my-test'>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<Meta title={<span className="text-uppercase font-14">{tutorialResult === true ? constants.tutorialResults:constants.testResults}</span>} description={<span style={{
								color: "black",
								fontStyle: "italic",
								whiteSpace:"break-spaces"
							}}>{showFilterForm && (`Search Criteria: ${filteredSearchCriteria? filteredSearchCriteria:""}`)}</span>}/>}extra={
								<Button className = {showFilterForm === false ? "edit-butoon" : "filterbutton"} onClick = {filterButton} icon={<FilterOutlined/>}></Button>
							}>
								<Row gutter={[24, 8]}>
									<Col xs={24} sm={24} md={24} lg={24}>
										<div className = {showFilterForm ===true ? "border p-3": ""}>
											{showFilterForm &&
											(
												<Form layout="vertical" form={form} onFinish={onFinishFilterSearch}>
													<Row gutter={[48,8]}>
														<Col span={8}>
															<Form.Item className="label-default mb-0" label="Country" name ="country">
																<Select 
																	onChange={onChangeCountry} 
																	placeholder={"Country"} 
																	className = "w-00" 
																	showSearch
																	allowClear
																	filterOption={selectFilterOption}>
																	{listCountries &&
														listCountries.map((listCountry) => (
															<Option key={listCountry.country_code} value={listCountry.country_id}>
																{listCountry.country_name}
															</Option>
														))}
																</Select>
															</Form.Item>
														</Col>
														<Col span={8}>
															{
																stateBlock ?
																	<Form.Item className="label-default mb-0" label ="State" name ="state">
																		<Select onChange={onChangeState} placeholder="State" 
																			showSearch
																			allowClear
																			filterOption={selectFilterOption}>
																			{listStates &&
														listStates.map((ListState) => (
															<Option key={ListState.region_code} value={ListState.region_id}>
																{ListState.region_name}
															</Option>
														))}
																		</Select>
																	</Form.Item>:
																	""
															}
														</Col>
														<Col span={8}>
															{uniBlock?
																<Form.Item className="label-default mb-0" label="University"  name="university">
																	<Select
																		placeholder="University" 
																		allowClear 
																		showSearch 
																		onChange={onChangeUniversity}
																		filterOption={selectFilterOption}
																	>
																		{tempUniversity?
																		// eslint-disable-next-line no-unused-vars
																			Object.entries(tempUniversity).map(([ key,value]) => (
																				<>
																					{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																				</>
																			)):
																			listUniversities &&
																		// eslint-disable-next-line no-unused-vars
																		Object.entries(tempUniversity ? tempUniversity : listUniversities).map(([ key,value]) => (
																			<>
																				{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																			</>
																		))
																		}
																	</Select>
																</Form.Item>
																:""}
														</Col>
														<Col span={8}>
															<Form.Item className="	label-default mb-0" label ="Name" name ="name">
																<Search placeholder="Name, Username, Email" onChange={(e)=>checkNameentered(e.target.value)}/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item className="label-default mb-0"  label ="Role" name ="role">
																<Select placeholder = "Select" onChange = {onChangeRole} allowClear >
																	<Option value={"0"}>Student</Option>
																	<Option value={"1"}>Professional</Option>
																	<Option value={"2"}>Faculty</Option>
																	<Option value={"3"}>Other</Option>
																	<Option value={"4"}>Unspecified</Option>
																</Select>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item >
															</Form.Item>
														</Col>
														{/* <Col span={16}>
															<Form.Item className="label-default mb-0" label ="Status" name = "status">
																<Radio.Group  onChange={onChangeStatus} value ={radioButtonStatus}>
																	<Space>
																		<Radio value={"4"}>All</Radio>
																		<Radio value={"1"}>Active</Radio>
																		<Radio value={"0"}>Inactive</Radio>
																		<Radio value={"2"}>Pending</Radio>
																		<Radio value={"3"}>Closed</Radio>
																	</Space>
																</Radio.Group>
															</Form.Item>
														</Col> */}
														<Col span={8}>
															<Form.Item className="label-default mb-0" label ={tutorialResult===true?"Tutorial Results":"Test Results"} name = "testResults">
																<Radio.Group onChange={onChangeTestResults} defaultValue={radioTestResults}>
																	<Space>
																		<Radio value={'0'}>Attempted</Radio>
																		<Radio value={'1'}>Completed</Radio>
																	</Space>
																</Radio.Group>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item className="label-default mb-0" label ={tutorialResult === true ?"Tutorial Results From":"Test Results From"}  name = "from" >
																<DatePicker format="MM/DD/YYYY" inputReadOnly={true} onChange={from} disabledDate={disabledFutureDate}style={{ width: '100%' }}/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item className="label-default mb-0" label ={tutorialResult === true ?"Tutorial Results To":"Test Results To"} name = "to">
																<DatePicker format="MM/DD/YYYY" inputReadOnly={true} onChange={to} disabledDate={disabledPastDate}style={{ width: '100%' }}/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item className="	label-default mb-0" label ="Topics" name ="topics">
																<Select placeholder = "Topics"  allowClear disabled={disableTopic}>
																	{topicList.map((item)=>(
																		<Option key={item.topic_id} value={item.topic_id}>{item.topic_abbreviation}</Option>
																	))
																	}
																</Select>
															</Form.Item>
														</Col>
														<Col span={8}>
															{tutorialResult === true ?null:
																<Form.Item className="	label-default mb-0" label ="Full Length Tests" name ="fulllengthtest">
																	<Select placeholder = "Full Length Tests"  allowClear disabled={disableFulLength}>
																		{constants.FullLengthTestsArray.map((item)=>(
																			<Option key={item.id} value={item.id}>{item.test_name}</Option>
																		))
																		}
																	</Select>
																</Form.Item>
															}
														</Col>
														<Col span={8}>
														</Col>
													</Row>
													<Divider/>
													<div className="text-right">
														<Space >
															<Form.Item className="mb-0 visibility-hidden" label ="" name ="cleared">
																<Checkbox onChange={(e)=>setIsCleared(e.target.checked?1:0)}>Include Cleared</Checkbox>
															</Form.Item>
															<Form.Item>
																<Button htmlType="submit" type="primary" icon={<SearchOutlined/>}>Search</Button>
															</Form.Item>
															<Form.Item>
																<Button onClick = {() =>{ filterReset(); }} icon={<ReloadOutlined/>}>Reset</Button>
															</Form.Item>
															<Form.Item>
																<Button onClick ={()=>{ setShowFilterForm(false);}} icon={<CloseOutlined/>}  type="primary" danger>Close</Button>
															</Form.Item>
														</Space>
													</div>
												</Form>
											)}
										</div>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24}>
										<span className="px-3">{`${totalCountReport} record(s) found.`}</span>
										<Table className="gap-table"
											locale={{ emptyText: 'No Records Found' }}
											dataSource={data.length > 0 ?data:[]} 
											columns={topTenColumns}
											onChange ={onchangeTable}
											pagination={{
												total: totalCountReport,
												showSizeChanger: true,
												pageSizeOptions: ["25", "50", "100"],
												defaultPageSize: 25,
												current: current,
											}}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row> 
					<Modal title="Do you want to delete the following test result?" width="640px" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
						{modalData&&
						<>
							<div className="stud-details">
								<span><b>First Name: </b>{modalData.stud_firstname}</span>	
								<span><b>Primary Email: </b>{modalData.primary_email} </span>	
								<span><b>Last Name: </b>{modalData.stud_lastname} </span>	
								<span><b>Alternate Email: </b>{modalData.alternate_email} </span>	
								<span><b>Scorecard ID: </b>{modalData.scorecard_id} </span>	
								<span><b>Student ID: </b>{modalData.stud_id} </span>			 
								<span><b>Auto Closed: </b>{modalData.is_autoclose==="t"?"Yes":"No"} </span>
								<span><b>Questions Attempted: </b>{modalData.total_corr_ans}/{modalData.total_questions} </span>
								<span><b>Status: </b>{modalData.status==="0"?"In Progress":modalData.status==="1"?"Completed":"Paused"} </span>			 
								<span><b>Date Created: </b>{moment(modalData.attempt_date).format("MM/DD/YYYY")} </span>
								<span><b>Test Type: </b>{modalData.is_topic===true?"Topic":modalData.is_full_length===true?"Test":"Category"} </span>
								<span><b>Test Name: </b>{modalData.test} </span>
								<span><b>Test Attempt: </b>{modalData.attempt} </span>
								<span><b>Points: </b>{modalData.points} </span>
								<span><b>Retake: </b>{modalData.is_retake==="t"?"Yes":"No"} </span>
								<span><b>Score: </b>{modalData.is_full_length=== true?modalData.marks_scored?modalData.marks_scored:"-":"-"} </span>
								{/* [Badge] to be added */}
							</div>
							<div className='text-right'>
								<Popconfirm
									title="Are you sure you want to delete this scorecard?"
									description="Are you sure to delete this scorecard?"
									onConfirm={confirm}
									onCancel={cancel}
									okText="Yes"
									cancelText="No"
								>
									<Button type='primary'>Delete</Button>
								</Popconfirm>
							</div>
						</>
						}
					</Modal> 
				</div>
			</Spin>
		</>
	);
};
export default TestResult;
