import React, { useEffect, useState } from 'react';
import { fetchAdminApi } from "../../../services/api";
import { TeamOutlined } from '@ant-design/icons';
import moment from "moment";
// import logo from "../../../assets/role-wise-logo.png";
import { Breadcrumb,Row, Col, Card, Form, DatePicker, Space, Table, Select } from 'antd';
import { Chart } from "react-google-charts";
import { useHistory } from "react-router";
import constants from '../../../constants/constants';
import { registrationFilter } from "../../../globalFunctions/GlobalFunctions";
const { Option } = Select;
const RegistrationByRoles = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [endDate, setEndDate] = useState(props && props.history && props.history.location && props.history.location.state ?props.history.location.state.ed :null);
	const [startDate, setStartDate] = useState(props && props.history && props.history.location && props.history.location.state ?props.history.location.state.sd :null);
	const [totalCategoryCount, setTotalCategoryCount] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [graphData, setGraphData] = useState([]);
	const [sortedInfo,setsortedInfo]=useState({
		order: 'ascend',
		columnKey: 'srNumber',
	});
	const handleChange = (_pagination, _filters, sorter) => {
		setsortedInfo(sorter);
	};
	useEffect(() => {
		fetchRegistrationByRoles();
	}, [startDate, endDate]);
	const fetchRegistrationByRoles = () => {
		let sd = moment(startDate).format("YYYY-MM-DD");
		let ed = moment(endDate).format("YYYY-MM-DD");
		let curentYear = `/students-by-role`;
		let selectedDate = `/students-by-role?start_date=${sd}&end_date=${ed}`;
		fetchAdminApi(startDate && endDate ? selectedDate : curentYear, "get").then((res) => {
			if (res && res.data && res.data !== null && res.data !== undefined) {
				let tdata = res.data;
				let count = res.data.map(item => parseInt(item.count)).reduce((prev, curr) => prev + curr, 0);
				setTotalCategoryCount(count);
				setTableData(tdata);
				let data = res.data;
				//data to display in graph.
				let gfHeader = [];
				gfHeader.push(["Categories", "Total"]);
				data.map(obj => gfHeader.push([parseInt(obj.count) === 0 ? "" : obj.role_name, parseInt(obj.count)]));
				setGraphData(gfHeader);
			} else {
				setTableData([]);
				let dat = [];
				dat.push(["Categories", "Total"]);
				dat.push(['', 0]);
				setGraphData(dat);
				setTotalCategoryCount(null);
			}
		});
	};
	const registedStudent = (record) =>{
		let payload = {};
		payload.sd = startDate ? moment(startDate).format("YYYY-MM-DD") : constants.startDateOfTheYear;
		payload.ed = endDate ? moment(endDate).format("YYYY-MM-DD") : constants.endDateOfTheYear;
		payload.sded = startDate&&endDate ? 1 : 2;
		payload.role = record.role_name;
		payload.roleId = record.role.toString();
		payload.title = ' Registrations by Roles';
		payload.push = '/registration-by-roles';
		payload.serchCriteria = `Role: ${record.role_name}, ${`Registrations from ${moment(payload.sd).format('MM/DD/YYYY')} to ${moment(payload.ed).format('MM/DD/YYYY')}`}`;
		history.push({
			pathname: "/registered-students",
			state: payload
		});
	};
	const column = [
		{
			title: "Roles",
			key: "role_name",
			dataIndex: "role_name",
			sorter: (a, b) => a.role_name.localeCompare(b.role_name),
			render: (_text, record) => {
				return <span ><a onClick={() => { registedStudent(record); }} style={{ color: "black" }}><span /* className="p-relative" */>{record.role_name}</span></a></span>;
			},
			sortOrder: sortedInfo.columnKey === 'role_name' && sortedInfo.order,
			width :200
		},
		{
			title: "Total",
			key: "total",
			dataIndex: "count",
			sorter: (a, b) => a.count - b.count,
			sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
			// className : "td-registartion-role",
			render: (_text, record) => {
				return <span ><a onClick={() => { registedStudent(record); }} style={{ color: "black" }}><span /* className="p-relative" */>{record.count}</span></a></span>;
			},
			className : "td-total-registration",
			width :200,
			align:"center"
		}
	];
	const from = (_date, dateString) => {
		setStartDate(dateString);
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	let currentYear = moment().year();
	let stdend = `${moment(startDate).format(constants.dateFormat)} to ${moment(endDate).format(constants.dateFormat)}`;
	if (startDate && endDate) {
		currentYear = stdend;
	}
	const graphOption = {
		title: `Role-wise Registrations - ${currentYear}`,
		legend: { position: 'none' },
		colors: ['#0000FF'],
		hAxis: {
			title: "Roles",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		vAxis: {
			title: "\n\nTotal",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			},
			format: '#'
		},
	};
	const onChangeDateFilter = (e) => {
		registrationFilter(e,setStartDateEndDate);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>Registrations by Roles</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">{<TeamOutlined className="icon-lg"/>}{" "}<span>{constants.RegistrationByRoles}</span></span>} extra={
								<Form layout="horizontal" form = {form}>
									<Space>
										<Form.Item className="label-default mb-0" label="From:" name = "from">
											<DatePicker defaultValue = {startDate ? moment(startDate) : null} onChange={from} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0" label="To:" name = "to">
											<DatePicker defaultValue = {endDate ? moment(endDate) : null} onChange={to} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0">
											<Select defaultValue="0" style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
												<Option value="0">This Year</Option>
												<Option value="7">Last Year</Option>
												<Option value="1">This Month</Option>
												<Option value="2">Last Month</Option>
												<Option value="3">Last 3 Months</Option>
												<Option value="4">This Month (Last Year)</Option>
												<Option value="5">Last 3 Months (Last Year)</Option>
												<Option value="6">Previous Years</Option>
											</Select>
										</Form.Item>
									</Space>
								</Form>
							}>
								<Row>
									<Col xs={24} sm={24} md={24} lg={8}>
										<Table
											onChange ={handleChange}
											className="gap-table"
											columns={column}
											pagination={false}
											dataSource={tableData}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													<Table.Summary.Row style = {{ fontWeight:500 }}>
														<Table.Summary.Cell index={0}  className = "last-element" ><span>Total</span></Table.Summary.Cell>
														<Table.Summary.Cell index={1}   className = "last-element" ><span style = {{ width : "65px" }}>{totalCategoryCount}</span></Table.Summary.Cell>
													</Table.Summary.Row>
												</Table.Summary>
											)}/>
									</Col>
									<Col xs={24} sm={24} md={24} lg={16}>
										<Chart
											chartType="ColumnChart"
											width="100%"
											height="400px"
											data={graphData}
											options={graphOption}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>;
			</div>
		</>
	);
};
export default RegistrationByRoles;