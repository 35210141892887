import React, { useState, useEffect } from 'react';
import { fetchApi } from "../../../services/api";
import { Row, Col, BackTop, Card, Table, Tooltip, Space,Breadcrumb, Avatar, Button  } from 'antd';
import { InfoCircleOutlined, TrophyOutlined, StarFilled } from '@ant-design/icons';
import constants from "../../../constants/constants";
import CustomIcons from "../../../assets/CustomIcons";
import Icon from '@ant-design/icons';
import moment from "moment"; 
import { useHistory } from "react-router";
// Custom icons
const Certificate = (props) => (<Icon component={CustomIcons.getsvg("Certificate")} {...props}/>);
const WheatStar = (props) => (<Icon component={CustomIcons.getsvg("WheatStar")} {...props}/>);
const Graduation = (props) => (<Icon component={CustomIcons.getsvg("Graduation")} {...props}/>);
const ScoreCompare = () => {
	const history = useHistory();
	const [leaderBoardScore, setLeaderBoardScore] = useState([]);
	const [latestScore, setLatestScore] = useState([]);
	useEffect(() => {
		fetchLatestScores();
		leaderboardScores();
	}, []);
	useEffect(()=>{
		// return () => {
		// 	if (history.action === "POP") {
		// 		history.push("/admin-dashboard");
		// 	}
		// };
	},[history]);
	// To Fetch the data for test taken by the user
	const fetchLatestScores = async() => {
		fetchApi(`/student/latest-top-ten-score`, "get").then((res) => {
			if (res && res.code === 200) {
				let tempMark=null;
				let count=0;
				res.data.map((item)=>{
					if(item.marks_scored===tempMark){
						item.srNum=count;
					}else{
						item.srNum=count+1;
						count=count+1;
					}
					tempMark=item.marks_scored;
				});
				setLatestScore( res.data );
			}else{
				setLatestScore([]);
			}
		});
	};
	const leaderboardScores = async() => {
		fetchApi(`/leaderboard?type=student`, "get").then((res) => {
			if (res && res.code === 200) {
				let tempMark=null;
				let count=0;
				res.data.map((item)=>{
					if(item.total_points===tempMark){
						item.srNum=count;
					}else{
						item.srNum=count+1;
						count=count+1;
					}
					tempMark=item.total_points;
				});
				setLeaderBoardScore( res.data );
			}else{
				setLeaderBoardScore([]);
			}
		});
	};
	const redirectStudentDetails=(studentId)=>{
		localStorage.setItem("studentId", studentId);
		localStorage.setItem("editRedirectLink","/score-compare");
		window.open(constants.BaseUrl+"/view-student", '_blank');
	};
	const nameEditButton = (record,object) =>{
		return (<span><Tooltip title={getTooltip(object.country_name,object.region_name,object.university_name,object.date)}><Button className = "px-0" type="link" 
			onClick={()=>redirectStudentDetails(object && object.stud_id)}
		>{record?record:"-"}</Button></Tooltip></span>);
	};
	const getTooltip =(country,state,university,dateregistration)=>{
		return (
			<>
				<span>Country: {country?country:"-"}</span><br/>
				<span>State: {state?state:"-"}</span><br/>
				<span>University: {university?university:"-"}</span><br/>
				<span>Date of Registration: {dateregistration?dateregistration:"-"}</span>
			</>
		);
	};
	// Top ten table
	const topTenColumns = [
		{
			title: '#',
			key: 'srNum',
			dataIndex:'srNum',
			align:'center',
			className: 'td-right',
			// render: (value) => value,
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				const x = record.name;
				const myArray = x.split(" ");
				const initials = x.substring(0, 1).toUpperCase();
				const y = myArray[1];
				const initial2 = y.substring(0, 1).toUpperCase();
				return (
					<>
						<Space size="middle">
							<Avatar className="avatar">{initials}{initial2}</Avatar>{/* Don't call "avatar" class if expired sub user */}
							{/* <span>{record&&record.name? myArray[0].charAt(0).toUpperCase()+ myArray[0].slice(1) + "," + " " + initial2:"-"}</span> */}
							<span>{nameEditButton(record&&record.name,record)}</span>
						</Space>
					</>
				);
			}
		},
		{
			title: 'State',
			key: 'state',
			render: (record) => {
				return (
					<span>{record&&record.state?record.state:"-"}</span>
				);
			}
		},
		{
			title: 'Date',
			key: 'date',
			render: (record) => {
				return (
					<span>{record&&record.date ? (<span>{moment(record.date).format("MM/DD/YYYY")}</span>) : ("-")}</span>
				);
			}
		},
		{
			title: 'Score',
			key: 'score',
			align:'center',
			className: 'td-right',
			render: (record) => {
				return (
					<span>{record&&record.marks_scored?record.marks_scored + "/" + "200":"-"}</span>
				);
			}
		},
	];
	// Leaderboard table
	const leaderboardColumns = [
		{
			title: '#',
			key: 'srNum',
			dataIndex:'srNum',
			align:'center',
			className: 'td-right',
			// render: (record) =>{
			// 	return(
			// 		<span>{record.srNum}</span>
			// 	);
			// }
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				const x = record.name;
				const myArray = x.split(" ");
				const initials = x.substring(0, 1);
				const y = myArray[1];
				const initial2 = y.substring(0, 1);
				return (
					<>
						<Space size="middle">
							<Avatar className="avatar">{initials}{initial2}</Avatar>{/* Don't call "avatar" class if expired sub user */}
							<span>{nameEditButton(record&&record.name,record)}</span>
						</Space>
					</>
				);
			}
		},
		{
			title: 'State',
			key: 'state',
			render: (record) => {
				return (
					<span>{record&&record.state?record.state:"-"}</span>
				);
			}
		},
		{
			title: 'Level',
			key: 'level',
			align: 'center',
			render: (record) => {
				let icons = " ";
				switch(record.level ) {
				case 1:
					icons = <div className="hexagon hex-sm level1"><StarFilled/></div>;
					break;
				case 2:
					icons = <div className="hexagon hex-sm level2"><Certificate/></div>;
					break;
				case 3:
					icons = <div className="hexagon hex-sm level3"><Graduation/></div>;
					break;
				case 4:
					icons = <div className="hexagon hex-sm level4"><WheatStar/></div>;
					break;
				case 5:
					icons = <div className="hexagon hex-sm level5"><TrophyOutlined/></div>;
					break;
				default:
					icons = "";
				}
				return (
					<Space size="middle" align="center">
						{icons}
						<span>{record&&record.level?"Level " +  record.level:"-"}</span>
					</Space>
				);
			}
		},
		{
			title: 'Points',
			key: 'points',
			align:'center',
			className: 'td-right',
			render: (record) => {
				return (
					<span>{record&&record.total_points?record.total_points:"-"}</span>
				);
			}
		},
	];
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>Student Stats</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<BackTop/>
				<Row gutter={[24, 24]}>
					<Col xs={24} sm={24} md={24} lg={12}>
						<Card size="small" title={<span className="text-uppercase">{constants.TopTen}</span>} extra={<Space><Tooltip placement="left" title={constants.compareTestsInfoToolTip}><InfoCircleOutlined/></Tooltip></Space>}>
							<Table className="gap-table" columns={topTenColumns} 
								dataSource={latestScore}
								pagination={false}
							/>
							<Col>
								<div className="text-right">
									<a onClick={()=>history.push("/score-compare-view-more")} className="navy-2">View more</a>
								</div>
							</Col>
						</Card>
					</Col>
					<Col xs={24} sm={24} md={24} lg={12}>
						<Card size="small" title={<span className="text-uppercase">{constants.Leaderboard}</span>} extra={<Space><Tooltip placement="left" title="List of all time top scorers"><InfoCircleOutlined/></Tooltip></Space>}>
							<Table className="gap-table" columns={leaderboardColumns} dataSource={leaderBoardScore} pagination={false}/>
							<Col>
								<div className="text-right">
									<a onClick={()=>history.push("/leaderboard-view-more")} className="navy-2">View more</a>
								</div>
							</Col>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default ScoreCompare;