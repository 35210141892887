/*
File Name: 
Author:
Modified On: 
Description: 
API's used:
1) 
*/
import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Card,
	Modal,
	BackTop,
	Table
} from "antd";
// import { EyeOutlined } from '@ant-design/icons';
import "../../../styles/login.css";
// import { fetchApi } from "../../../services/api";
import constants from "../../../constants/constants";
const StudentLeaderboard = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	useEffect(() => {}, []);
	const showModal = () => {
		setIsModalVisible(true);
	};
	const handleOk = () => {
		setIsModalVisible(false);
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	// 
	const studentLeaderboard = [
		{
			title: 'Points',
			key: 'points',
			dataIndex: 'points',
			align: 'center',
			className: 'td-right',
			render: () => {
				return (
					<span>50</span>
				);
			}
		},
		{
			title: 'Level',
			key: 'level',
			dataIndex: 'level',
		},
		{
			title: 'Name',
			key: 'name',
			dataIndex: 'name',
			render: () => {
				return (
					<span  onClick={showModal}><a>11simon</a></span>
				);
			}
		},
		{
			title: 'Country',
			key: 'country',
			dataIndex: 'country',
		},
		{
			title: 'State',
			key: 'state',
			dataIndex: 'state',
		},
		{
			title: 'University',
			key: 'university',
			dataIndex: 'university',
		},
		{
			title: 'Role',
			key: 'role',
			dataIndex: 'role',
			align:'center'
		},
		{
			title: 'Registered',
			key: 'registered',
			dataIndex: 'registered',
			align: 'center',
		},
	];
	const activityColumns = [
		{
			title: 'Activity',
			key: 'activity',
			dataIndex: 'activity',
		},
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'date',
		},
		{
			title: 'Points',
			key: 'points',
			dataIndex: 'points',
			align: 'center',
			className: 'td-right',
		},
	];
	const activityData = [];
	for (let j = 0; j < 5; j++) {
		activityData.push({
			key: j,
			activity: `FPP#1`,
			date: `04-16-2020`,
			points: `50`,
		});
	}
	const data = [];
	for (let i = 0; i < 46; i++) {
		data.push({
			key: i,
			points: 50,
			level: `Level 8 – All Star `,
			name: ``,
			country: `USA`,
			state: `AK`,
			university: `Samford University, Birmingham `,
			role: `P` ,
			registered: `04-20-2021 `,
		});
	}
	return (
		<div className="p-4">
			<BackTop/>
			<Row gutter={[24, 24]} className='my-test mt-4'>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title={<span className="text-uppercase">{constants.StudentLeaderboardTitle}</span>}>
						<Table className="admin-table" 
							locale={{ emptyText: 'No Records Found' }}
							dataSource={data} 
							columns={studentLeaderboard}
							scroll={{ x: 991 }}
							// pagination={pagination}
						/>
					</Card>
				</Col>
			</Row>
			<Modal title="Student Activities" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
				<div className="stud-details">
					<p><b>Full name: </b>Sofia Salazar </p>	
					<p><b>Role: </b>Student</p>			 
					<p><b>Country: </b>USA</p>
					<p><b>State: </b>TX</p>
					<p><b>University: </b>Texas Christian University 	</p>			 
					<p><b>Level: </b>Apprentice</p>
					<p><b>Points: </b>620</p>
					{/* [Badge] to be added */}
				</div>
				<Table className="admin-table" 
					locale={{ emptyText: 'No Records Found' }}
					dataSource={activityData} 
					columns={activityColumns}
					// pagination={pagination}
				/>
			</Modal> 
		</div>
	);
};
export default StudentLeaderboard;
