import React,{ useEffect,useState } from 'react';
import { Breadcrumb, Row, Col, Card, Form, Table,Button,Tag, } from 'antd';
import { fetchAdminApi } from "../../../services/api";
import { useHistory } from "react-router";
const AdminUsers = () => {
	const history = useHistory();
	const [tableData, setTableData] = useState([]);
	const column = [
		{
			title: "Username",
			key: "Username",
			render: (record) => {
				return <span><a onClick = {()=>{history.push({
					pathname: "/edit-admin-user",
					state:{ 
						type:0,
						adminRecords:record
					}
				});}}>{record.Username}</a></span>;
			},
		},
		{
			title: "Name",
			key: "Name",
			dataIndex: "name",
		},
		{
			title: "User Type",
			key: "userType",
			dataIndex: "User_Type",
		},
		{
			title: "Email",
			key: "Email",
			dataIndex: "Email",
		},
		{
			title: "Status",
			key: "status",
			render: (record) => {
				if(record.status =="Active"){
					return <Tag color={'green'} >Active</Tag>;
				}else if(record.status =="Inactive"){
					return <Tag color={'blue'}>Inactive</Tag>;
				}
			},
		}
	];
	const fetchAdminUsers = () =>{
		fetchAdminApi("/admin_list", "get").then((res) => {
			if (res && res.data) {
				setTableData(res.data);
			}else{
				setTableData([]);
			}
		});
	};
	useEffect(() => {
		fetchAdminUsers();
	}, []);
	useEffect(()=>{
		// return () => {
		// 	if (history.action === "POP") {
		// 		history.push("/admin-dashboard");
		// 	}
		// };
	},[history]);
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/masters-menu" });
						}}>Masters</a></Breadcrumb.Item>
						<Breadcrumb.Item>Admin Users</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card style ={{ padding:"1px" }} bordered size="small" title={<span className="text-uppercase">Admin Users List</span>} extra={
								<Form layout="horizontal">
									<Form.Item className="label-default mb-0">
										<Button type ="primary" onClick = {()=>{history.push({
											pathname: "/add-admin-user",
											state:{ type:1 }
										});}}> Add User</Button>
									</Form.Item>
								</Form>
							}>
								<p>{tableData !== null ? `${tableData.length} record(s) found.` : ""}</p>
								<Table 
									className="admin-gap-table"
									columns={column} 
									dataSource={tableData} 
									pagination={false}
									locale={{ emptyText: 'No Records Found' }}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default AdminUsers;