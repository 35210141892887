/*
File Name: Loader.js
Author: Dahlin Carneiro
Modified On: 05/10/2022
Description:Loader icon for the application.
*/
import { Image } from "antd";
import LoaderImage from "../../../assets/Loader.gif";
const Loader = () => {
	return (
		<Image width="10%" className="m-auto"  src={LoaderImage} alt="Loading..." preview={false}/>
	);
};
export default Loader;