import React,{ useEffect,useState } from 'react';
import { Breadcrumb,Row, Col, Card, Table, BackTop,Tag,Space,Tooltip,Button,Input,Form,Radio,DatePicker,Select,Divider,Spin, Checkbox } from 'antd';
import { fetchApi } from "../../../services/api";
import { FilterOutlined,StarFilled,SearchOutlined,ReloadOutlined ,CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import { useHistory } from "react-router";
import constants from "../../../constants/constants";
import Loader from "../../Common/Cred/Loader";
import { displayOtherUniversityAlaska } from "../../../globalFunctions/GlobalFunctions";
const { Meta } = Card;
const { Option } = Select;
const { Search } = Input;
const FlashcardResults =()=> {
	const [form] = Form.useForm();
	const history = useHistory();
	const [data, setData] = useState([]);
	const [totalCountReport, setTotalCountReport] = useState(0);
	const [current, setCurrent] = useState(1);
	const [showFilterForm, setShowFilterForm] = useState(false);
	const [loader,setLoader]=useState(false);
	const [filteredSearchCriteria, setfilteredSearchCriteria] = useState("");
	const [filterMessage, setFilterMessage] = useState({
		university : "",
		country:"",
		state:""
	});
	const [stateBlock, setStateBlock] = useState(false);
	const [uniBlock, setUniBlock] = useState(false);
	const [tempUniversity, setTempUniversity] = useState([]);
	const [listUniversities, setUniversities] = useState([]);
	const [listCountries, setCountries] = useState([]);
	const [role, setRole] = useState("");
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [listStates, setListStates] = useState([]);
	const [radioTestResults, setRadioTestresults] = useState(null);
	// const [radioButtonStatus, setRadioButtonStatus] = useState("4");
	const [filterSearchValues,setFiltersearchvalues]=useState({});
	const [pageSize,setPagesize]=useState(25);
	const [sortedInfo, setsortedInfo] = useState(null);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [filterPayload, setFilterPayload] = useState({});
	const [isCleared,setIsCleared]=useState(1);
	// const [columnSortName,setColumnSortName]=useState(null);
	// const [columnSorttype,setColumnSortType]=useState(null);
	useEffect(() => {
		fetchFlascardResults();
	}, []);
	useEffect(() => {
		fetchCountries();
		fetchStates();
		fetchUniversities();
	}, []);
	//To Fetch the data for test taken by the user
	const fetchFlascardResults = (payloadValues,sorterPayload,currentPageValue,pageSizeValue,filterPayload) => {
		setLoader(true);
		let payload = {};
		payload=payloadValues?payloadValues:{};
		payload.page=currentPageValue ? currentPageValue: 0;
		payload.limit=pageSizeValue ? pageSizeValue: 25 ;
		if(sorterPayload){
			payload.sort_column =sorterPayload.sorterColumnName;
			payload.sort=sorterPayload.sorterType;
		}
		if(filterPayload){
			if(filterPayload.progress){
				let tempProgress=filterPayload.progress.split(",");
				if(tempProgress.length===1){
					payload.progress = filterPayload.progress;
				}else if('progress' in payload){
					delete payload['progress'];
				}
			}
			if(filterPayload.type){
				let tempProgress=filterPayload.type.split(",");
				if(tempProgress.length===1){
					payload.flashcard_type = filterPayload.type;
				}else if('flashcard_type' in payload){
					delete payload['flashcard_type'];
				}
			}
			// payload.flashcard_type = filterPayload.type;
		}
		payload.include_cleared=isCleared;
		fetchApi('/flashcards-test-results', "post",payload).then((res) => {
			if (res && res.code === 200) {
				if(res?.data?.length > 0){
					setData(res.data);
					setTotalCountReport(res?.total_count);
					setLoader(false);
				}
			}else{
				setData([]);
				setTotalCountReport(0);
				setLoader(false);
			}
		});
	};
	const redirectStudentDetails=(studentId)=>{
		localStorage.setItem("studentId", studentId);
		localStorage.setItem("editRedirectLink","/flashcard-result");
		window.open(constants.BaseUrl+"/view-student", '_blank');
	};
	const nameEditButton = (record,object) =>{
		return (<span><Button type="link" 
			onClick={()=>redirectStudentDetails(object && object.stud_id)}
		>{record}</Button></span>);
	};
	const studentResults = (record,object) =>{
		return (<span><Button type="link" icon={<SearchOutlined/>} onClick={() => history.push({
			pathname: "/flashcard",
			state: { 
				fromFlashcardResult:true,
				userId:object && object.stud_id,
				name:object && object.name,
				is_flashcard:1
			}
		})}></Button></span>);
	};
	const filterButton = () =>{
		form.setFieldsValue({ status:"4" });
		setShowFilterForm(!showFilterForm);
	};
	const filterSearchCriteriaMessage = (values) =>{
		let array = [];
		if(values){
			if(values.role){
				array.push(`Role: ${role}`);
			}
			if(values.country){
				array.push(`Country: ${filterMessage.country}`);
			}
			if(values.state){
				array.push(`State: ${filterMessage.state}`);
			}
			if(values.university){
				array.push(`University: ${filterMessage.university}`);
			}
			if(values.from &&values.to){
				array.push(`Flashcard Results from ${startDate} to ${endDate}`);
			}
			if(values.name){
				array.push(`Name : ${values.name}`);
			}
			if(array.length>=1){
				setfilteredSearchCriteria( array.join(", ")+".");
			}else{
				setfilteredSearchCriteria( array.join(", "));
			}
		}
	};
	const onFinishFilterSearch = (values) =>{
		setCurrent(1);
		let payloadValues ={};
		if(values){
			if(values.country){
				payloadValues.country_id = values.country;
			}
			if(values.from && values.to){
				// fetch += `&start_date=${moment(values.from).format("YYYY-MM-DD")}&end_date=${moment(values.to).format("YYYY-MM-DD")}`;
				payloadValues.from=moment(startDate).format("YYYY-MM-DD");
				payloadValues.to=moment(endDate).format("YYYY-MM-DD");
			}
			if(values.name){
				// fetch += `&name=${values.name}`;
				payloadValues.name=values.name;
			}
			if(values.state){
				// fetch += `&state_id=${values.state}`;
				payloadValues.state_id=values.state;
			}
			if(values.university){
				// fetch += `&university_id=${values.university}`;
				payloadValues.university_id=values.university;
			}
			if(values.role){
				if(values.role === "4"){
					// fetch += `&occupation_type=${null}`;
					payloadValues.occupation_type="null";
				}else{
					// fetch += `&occupation_type=${values.role}`;
					payloadValues.occupation_type=values.role;
				}
			}
			if(values.test){
				if(values.status !=="4"){
					payloadValues.is_active=values.status;
				}
			}
			if(values.testResults){
				payloadValues.test_results=values.testResults;
			}
		}
		// setarrayLength(array);
		filterSearchCriteriaMessage(values);
		setCurrent(1);
		setFiltersearchvalues(payloadValues);
		fetchFlascardResults(payloadValues,null,0,pageSize,filterPayload);
		setsortedInfo(null);
	};
	const onChangeUniversity = (_value, option) =>{
		setFilterMessage({
			...filterMessage,
			university:option?.children
		});
		// setUniversityMessage(option.children);
	};
	const onChangeState = (e, option) =>{
		form.setFieldsValue({ university:null });
		setFilterMessage({
			...filterMessage,
			state:option?.key
		});
		Object.entries(listUniversities).forEach(([key, value]) => {
			if (key === option?.children) {
				let obj={};
				obj[e]=value;
				setTempUniversity(obj);
				// setUniBlock(true);
			}
			else if ( value === null) {
				setTempUniversity(null);
				// setUniBlock(false);
			}	
		});
		// setState(option.key);
	};
	const onChangeCountry = (e,value) =>{
		setFilterMessage({
			...filterMessage,
			country:value?.key
		});
		if (value?.key==="US") {
			// setSelectState(true);
			setStateBlock(true);
			setUniBlock(true);
		}
		else {
			// setSelectState(false);
			setStateBlock(false);
			setUniBlock(false);
		}
		// setCountry(option.key);
	};
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				//removed the US Country by filtering 
				let remainingArr = res.data.filter(data => data.country_id != '238');
				//Adding a US country at zero index
				remainingArr.unshift({
					country_id: "238",
					country_code: "US",
					country_name: "United States"
				});
				setCountries(remainingArr);
			} else {
				setCountries([]);
			}
		});
	};
	const fetchStates = () => {
		fetchApi(`/states?country_code=US`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setListStates(res.data);
			} else {
				setListStates([]);
			}
		});
	};
	const fetchUniversities = () => {
		fetchApi(`/universities?country_code=US`, "get").then(
			(res) => {
				if (res && res.data) {
					Object.entries(res.data).forEach(([key, value]) => {
						displayOtherUniversityAlaska(key,value);
					});
					setUniversities(res.data);
				} else {
					setUniversities(null);
				}
			}
		);
	};
	const onChangeRole = (_value,option) => {
		setRole(option?.children);
	};
	const from = (_date, dateString) => {
		setStartDate(dateString);
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const flaschcardResultsColumn = [
		{
			title: '#',
			key: 'index',
			dataIndex: "sr_no",
			render: (value, item, index) => (current - 1) * pageSize + index + 1
		},
		{
			title: "Name",
			key: "name",
			dataIndex: "name",
			render: (record, object) => {
				if(object.is_active==="4"){
					return <Space>
						{studentResults(record,object)}
						{nameEditButton("Stud - "+object.stud_id,object)}
					</Space>;
				}else{
					if (record.length > 15) {
						return <Space>
							{studentResults(record,object)}
							{nameEditButton(record.slice(0, 15)+"...",object)}
						</Space>;
					} else {
						return <Space>
							{studentResults(record,object)}
							{nameEditButton(record,object)}
						</Space>;
					}
				}
			},
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'name' ? sortedInfo?.order : null,
			// sortOrder: columnSortName?.columnKey === 'name' ? columnSorttype?.order : null,
			// sorter: (a, b) => {
			// 	a = a.name || '';
			// 	b = b.name || '';
			// 	return a.localeCompare(b);
			// },
			// width:200
		},
		{
			title: "Username",
			key: "username",
			dataIndex: "login_username",
			render: (record,object) => {
				if(object.is_active==="4"){
					return <span></span>;
				}else{
					if (record.length > 15) {
						return <Space>
							<Tooltip title={record}>
								<span>{record.slice(0, 15)}...</span>
							</Tooltip>
						</Space>;
					} else {
						return <span>{record}</span>;
					}
				}
			},
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'username' ? sortedInfo?.order : null,
			// sortOrder: columnSortName?.columnKey === 'username' ? columnSorttype?.order : null,
			// sorter: (a, b) => {
			// 	a = a.username || '';
			// 	b = b.username || '';
			// 	return a.localeCompare(b);
			// },
			// width: 150
		},
		{
			title:  <Tooltip title="Country"> <span>C</span></Tooltip>,
			key: "country",
			dataIndex: "country",
			// sorter: (a, b) => {
			// 	a = a.country || '';
			// 	b = b.country || '';
			// 	return a.localeCompare(b);
			// },
			align: 'center',
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'country' ? sortedInfo?.order : null,
			// sortOrder: columnSortName?.columnKey === 'country' ? columnSorttype?.order : null,
			// width: 100
		},
		{
			title:  <Tooltip title="State"> <span>S</span></Tooltip>,
			key: "state",
			dataIndex: "state",
			// sorter: (a, b) => {
			// 	a = a.state || '';
			// 	b = b.state || '';
			// 	return a.localeCompare(b);
			// },
			align: 'center',
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'state' ? sortedInfo?.order : null,
			// sortOrder: columnSortName?.columnKey === 'state' ? columnSorttype?.order : null,
			// width: 100
		},
		{
			title: "University",
			key: "university",
			dataIndex: "university",
			// sorter: (a, b) => {
			// 	a = a.university || '';
			// 	b = b.university || '';
			// 	return a.localeCompare(b);
			// },
			render: (record) => {
				if (record?.length > 20) {
					return <Space>
						<Tooltip title={record}>
							<span>{record.slice(0, 20)}...</span>
						</Tooltip>
					</Space>;
				} else {
					return <span>{record}</span>;
				}
			},
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'university' ? sortedInfo?.order : null,
			// sortOrder: columnSortName?.columnKey === 'university' ? columnSorttype?.order : null,
			width:200,
		},
		{
			title:  <Tooltip title="Role"> <span>R</span></Tooltip>,
			key: "occupation_type",
			dataIndex: "role",
			sorter: () => {},
			sortOrder: sortedInfo?.columnKey === 'occupation_type' ? sortedInfo?.order : null,
			render: (record,type) => {
				let tooltip = "";
				if(type.role === "S"){
					tooltip = constants.Role_type.student;
				}else if( type.role === "P"){
					tooltip =constants.Role_type.professional;
				}else if ( type.role === "F"){
					tooltip =constants.Role_type.faculty;
				}else if ( type.role === "O"){
					tooltip =constants.Role_type.other;
				}else if ( type.role === "U"){
					tooltip =constants.Role_type.unspecified;
				}
				return <Tooltip title={tooltip}>
					<span>{record}</span>
				</Tooltip>;
			},
			width: 50,
			align: 'center',
		},
		{
			title: 'Type',
			key: 'type',
			dataIndex: "heading",
			// align: 'center',
			filters: [
				{
					text: 'Textbook',
					value: 'TB',
				},
				{
					text: 'Topic',
					value: 'T',
				},
			],
			filteredValue: filteredInfo?.type ?filteredInfo?.type: null,
			render: (record) => {
				if (record === "TB") {
					return <span>Textbook</span>;
				} else {
					return <span>Topic</span>;
				}
			},
		},
		{
			title: 'Title',
			key: 'title',
			// align: 'center',
			render: (record) => {
				return <Space>
					<Tooltip title={record?.title}>
						<span>{record?.abbreviation}</span>
					</Tooltip>
				</Space>;
			},
		},
		{
			title: 'Progress',
			key: 'progress',
			width:100,
			dataIndex:"is_completed",
			filters: [
				{
					text: 'Complete',
					value: 't',
				},
				{
					text: 'Paused',
					value: 'f',
				},
			],
			filteredValue: filteredInfo?.progress? filteredInfo?.progress : null,
			render: (record) => {
				let color="";
				let progress = '';
				if (record==="f") {
					progress = "Paused";
					color="red";
				}
				else {
					color="green";
					progress = "Completed";
				}
				return <Tag color={color}>
					{progress}
				</Tag>;
			},
		},
		{
			title: 'Date',
			key: 'attemptedOn',
			align:'center',
			className:"td-right-attemtedon result-col",
			sorter: (a, b) => new Date(a.attempt_date) - new Date(b.attempt_date),
			sortOrder: sortedInfo?.columnKey === 'attemptedOn' ? sortedInfo?.order : null,
			// sortOrder: columnSortName?.columnKey === 'attemptedOn' ? columnSorttype?.order : null,
			render: (record) => {
				let dateTooltip="";
				if(record.attempted_on_time){
					dateTooltip=moment(record?.attempted_on).format(constants.dateFormat)+" "+record.attempted_on_time;
				}else{
					dateTooltip=moment(record?.attempted_on).format(constants.dateFormat);
				}
				if(record?.is_completed === "t"){
					return <Tooltip title={dateTooltip}><span className="p-relative">{moment(record?.attempted_on).format(constants.dateFormat)} <StarFilled className="yellow mytest-star"/></span></Tooltip>;
				}
				return (
					<Tooltip title={dateTooltip}><span className="p-relative">{record&&record.attempted_on ? moment(record.attempted_on).format(constants.dateFormat) : ("-")}</span></Tooltip>
				);
			},
			width: 150
		},
		{
			title: <Tooltip title="Points"> <span>Pts.</span></Tooltip>,
			key: 'points',
			align:'center',
			dataIndex:"points",
			className: 'td-right',
			render: (record) => {
				if(record === "0"){
					return <span></span>;
				}else{
					return <span className="p-relative" style={{ width:"38px" }}>{record ? record:""}</span>;
				}
			},
		},
	];
	const onchangeTable = (pagination, filters, sorter) =>{
		let currentPageValue=null;
		let currentPageSize=null;
		if(pagination){
			currentPageValue=pagination?.current-1;
			currentPageSize=pagination?.pageSize;
			setCurrent(pagination.current);
			setPagesize(pagination?.pageSize);
		}
		let sorterPayload ={};
		if(sorter){
			setsortedInfo(sorter);
			if(sorter&&sorter.order&&sorter.columnKey){
				sorterPayload.sorterColumnName=returnCoumnName(sorter.columnKey);
				sorterPayload.sorterType=sorter.order==="ascend"?"asc":"desc";
			}
		}
		let type_filter = [];
		let progress_filter = [];
		let filterPayload = {};
		if(filters){
			setFilteredInfo(filters);
			if(filters?.type){
				for(let i = 0; i < filters?.type?.length; i++) {
					type_filter.push(`${filters?.type[i]}`);
				}
				let arrayTostring = type_filter.toString();
				filterPayload.type = arrayTostring;
			}
			if(filters?.progress){
				for(let p = 0; p < filters?.progress?.length; p++) {
					progress_filter.push(`${filters?.progress[p]}`);
				}
				let arrayTostring = progress_filter.toString();
				filterPayload.progress = arrayTostring;
			}
			setFilterPayload(filterPayload);
		}
		// setColumnSortName(sorterColumnName);
		// setColumnSortType(sorterType);
		fetchFlascardResults(filterSearchValues?filterSearchValues:null,sorterPayload,currentPageValue,currentPageSize,filterPayload);
	};
	const onChangeTestResults = (checkedValues) =>{
		setRadioTestresults(checkedValues.target.value);
	};
	const filterReset = () =>{
		//TODO: check page values after reset
		form.resetFields();
		form.setFieldsValue({ status:"4" });
		setStartDate("");
		setEndDate("");
		setfilteredSearchCriteria("");
		setCurrent(1);
		setPagesize(25);
		setFiltersearchvalues({});
		setsortedInfo(null);
		setFilteredInfo(null);
		setRadioTestresults(null);
		// fetchFlascardResults(null,null,null,0,25);
	};
	// function to return column name
	const returnCoumnName=(sortColumn)=>{
		switch (sortColumn) {
		case "name":	
			return "s.stud_firstname";
		case "username":	
			return "s.login_username";
		case "country":	
			return "gc.country_code";
		case "state":	
			return "gr.region_code";
		case "university":	
			return "gu.university_name";
		case "attemptedOn":	
			return "date(student_flashcards.dt_first_completed)";
		case "occupation_type":	
			return "occupation_type";
		}
	};
	const selectFilterOption = (input,option)=>{
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
		// return option.props.children
		// 	.toLowerCase()
		// 	.indexOf(input.toLowerCase()) >= 0 ||
		// 	option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};
	// const onChangeStatus = (checkedValues) =>{
	// 	setRadioButtonStatus(checkedValues);
	// };
	const checkNameentered=(nameValue)=>{
		if(nameValue.trim().length>0){
			setIsCleared(0);
		}else{
			setIsCleared(1);
		}
	};
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="div">
					<div className="p-3">
						<Breadcrumb>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/analysis-menu" });
							}}>Analysis</a></Breadcrumb.Item>
							<Breadcrumb.Item>{constants.flashcardResults}</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<BackTop/>
					<Row gutter={[24, 8]} className='my-test'>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<Meta title={<span className="text-uppercase font-14">{constants.flashcardResults}</span>} description={<span style={{
								color: "black",
								fontStyle: "italic",
								whiteSpace:"break-spaces"
							}}>{showFilterForm && (`Search Criteria: ${filteredSearchCriteria? filteredSearchCriteria:""}`)}</span>}/>}extra={
								<Button className = {showFilterForm === false ? "edit-butoon" : "filterbutton"} onClick = {filterButton} icon={<FilterOutlined/>}></Button>
							}>
								<Row gutter={[24, 8]}>
									<Col xs={24} sm={24} md={24} lg={24}>
										<div className = {showFilterForm ===true ? "border p-3": ""}>
											{showFilterForm &&
											(
												<Form layout="vertical" form={form} onFinish={onFinishFilterSearch}>
													<Row gutter={[48,8]}>
														<Col span={8}>
															<Form.Item className="label-default mb-0" label="Country" name ="country">
																<Select 
																	onChange={onChangeCountry} 
																	placeholder={"Country"} 
																	className = "w-00" 
																	showSearch
																	allowClear
																	filterOption={selectFilterOption}>
																	{listCountries &&
														listCountries.map((listCountry) => (
															<Option key={listCountry.country_code} value={listCountry.country_id}>
																{listCountry.country_name}
															</Option>
														))}
																</Select>
															</Form.Item>
														</Col>
														<Col span={8}>
															{
																stateBlock ?
																	<Form.Item className="label-default mb-0" label ="State" name ="state">
																		<Select onChange={onChangeState} placeholder="State" 
																			showSearch
																			allowClear
																			filterOption={selectFilterOption}>
																			{listStates &&
														listStates.map((ListState) => (
															<Option key={ListState.region_code} value={ListState.region_id}>
																{ListState.region_name}
															</Option>
														))}
																		</Select>
																	</Form.Item>:
																	""
															}
														</Col>
														<Col span={8}>
															{uniBlock?
																<Form.Item className="label-default mb-0" label="University"  name="university">
																	<Select
																		placeholder="University" 
																		allowClear 
																		showSearch 
																		onChange={onChangeUniversity}
																		filterOption={selectFilterOption}
																	>
																		{tempUniversity?
																		// eslint-disable-next-line no-unused-vars
																			Object.entries(tempUniversity).map(([ key,value]) => (
																				<>
																					{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																				</>
																			)):
																			listUniversities &&
																		// eslint-disable-next-line no-unused-vars
																		Object.entries(tempUniversity ? tempUniversity : listUniversities).map(([ key,value]) => (
																			<>
																				{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																			</>
																		))
																		}
																	</Select>
																</Form.Item>
																:""}
														</Col>
														<Col span={8}>
															<Form.Item className="	label-default mb-0" label ="Name" name ="name">
																<Search placeholder="Name, Username, Email" onChange={(e)=>checkNameentered(e.target.value)}/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item className="label-default mb-0"  label ="Role" name ="role">
																<Select placeholder = "Select" onChange = {onChangeRole} allowClear >
																	<Option value={"0"}>Student</Option>
																	<Option value={"1"}>Professional</Option>
																	<Option value={"2"}>Faculty</Option>
																	<Option value={"3"}>Other</Option>
																	<Option value={"4"}>Unspecified</Option>
																</Select>
															</Form.Item>
														</Col>
														<Col span={8}>
														</Col>
														<Col span={8}>
															<Form.Item className="label-default mb-0" label ="Flashcard Results " name = "testResults">
																<Radio.Group onChange={onChangeTestResults} defaultValue={radioTestResults}>
																	<Space>
																		<Radio value={'0'}>Attempted</Radio>
																		<Radio value={'1'}>Completed</Radio>
																	</Space>
																</Radio.Group>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item className="label-default mb-0" label ={"Flashcard Results From"}  name = "from" >
																<DatePicker format="MM/DD/YYYY" inputReadOnly={true} onChange={from} disabledDate={disabledFutureDate}style={{ width: '100%' }}/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item className="label-default mb-0" label ={"Flashcard Results To"} name = "to">
																<DatePicker format="MM/DD/YYYY" inputReadOnly={true} onChange={to} disabledDate={disabledPastDate}style={{ width: '100%' }}/>
															</Form.Item>
														</Col>
														{/* <Col span={16}>
															<Form.Item className="label-default mb-0" label ="Status" name = "status">
																<Radio.Group  onChange={onChangeStatus} value ={radioButtonStatus}>
																	<Space>
																		<Radio value={"4"}>All</Radio>
																		<Radio value={"1"}>Active</Radio>
																		<Radio value={"0"}>Inactive</Radio>
																		<Radio value={"2"}>Pending</Radio>
																		<Radio value={"3"}>Closed</Radio>
																	</Space>
																</Radio.Group>
															</Form.Item>
														</Col> */}
													</Row>
													<Divider/>
													<div className="text-right">
														<Space >
															<Form.Item >
																<Form.Item className="mb-0 visibility-hidden" label ="" name ="cleared">
																	<Checkbox onChange={(e)=>setIsCleared(e.target.checked?1:0)}>Include Cleared</Checkbox>
																</Form.Item>
															</Form.Item>
															<Form.Item>
																<Button htmlType="submit" type="primary" icon={<SearchOutlined/>}>Search</Button>
															</Form.Item>
															<Form.Item>
																<Button onClick = {() =>{ filterReset(); }} icon={<ReloadOutlined/>}>Reset</Button>
															</Form.Item>
															<Form.Item>
																<Button onClick ={()=>{ setShowFilterForm(false);}} icon={<CloseOutlined/>}  type="primary" danger>Close</Button>
															</Form.Item>
														</Space>
													</div>
												</Form>
											)}
										</div>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24}>
										<span className="px-3">{`${totalCountReport} record(s) found.`}</span>
										<Table className="gap-table mytest-table"
											locale={{ emptyText: 'No Records Found' }}
											dataSource={data.length > 0 ?data:[]} 
											columns={flaschcardResultsColumn}
											onChange ={onchangeTable}
											pagination={{
												total: totalCountReport,
												showSizeChanger: true,
												pageSizeOptions: ["25", "50", "100"],
												defaultPageSize: 25,
												current: current,
											}}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row> 
				</div>
			</Spin>
		</>
	);
};
export default FlashcardResults;
