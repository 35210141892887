import { Layout } from "antd";
import "./index.css";
import constants from "./constants/constants";
const { Footer } = Layout;
const Footers = () => {
	return (
		<div className="">
			<Footer className="text-center slp-footer">
				{" "}
				{constants.footerText}
			</Footer>
		</div>
	);
};
export default Footers;
