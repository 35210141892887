import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { fetchAdminApi, } from "../../../services/api";
import { BackTop, Breadcrumb, Card, Col, Row, Table } from 'antd';
import constants from '../../../constants/constants';
import moment from 'moment';
import { useHistory } from "react-router";
const StudentStreak = () => {
	const history = useHistory();
	const [streakdata, StudentStreak] = useState();
	const pagination = { showSizeChanger: true, };
	const [tokenValue,setTokenValue]=useState();
	useEffect(() => {
		let queryString = window.location.href;
		var token = queryString.substring(queryString.lastIndexOf('?') + 1);
		setTokenValue(token);
		fetchStreakdata(token);
	}, []);
	const Columns = [
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'date',
			// width: '150px',
			align: 'left',
			render: (record) => {
				return (<span>{moment(record).format(constants.dateFormat)}</span>);
			},
		},
		{
			title: 'Streak',
			key: 'streak',
			dataIndex: 'streak',
			// className: 'td-right',
			align: 'center',
			render: (record) => {
				return (<span>{record}</span>);
			},
		},
	];
	const fetchStreakdata =(token)=>{
		fetchAdminApi(`/streaks-data/${token}`, "get").then((res) => {
			if (res && res.data) {
				StudentStreak(res.data);
			}else{
				StudentStreak([]);
			}
		});
	};
	return (
		<>
			<div className='gray-bg'>
				<div className='p-4'>
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item><a onClick={() => {
							sessionStorage.setItem("showEditSaveButton","show");
							history.push({ pathname:"/edit-student", });
						}}>Edit Student User</a></Breadcrumb.Item>
						<Breadcrumb.Item>
						Streaks</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<BackTop/>
				<div>
					<Row gutter={[24, 8]} >
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<><span className="text-uppercase">Streaks - {streakdata?.student_name}</span>
								<span style={{ visibility:"hidden" }}>{" "}Student ID:{tokenValue}</span>
							</>} 
							extra={<span>Username: {streakdata?.login_username}</span>}
							>						
								<Table className="admin-gap-table" 
									locale={{ emptyText: 'No Records Found' }}
									dataSource={streakdata?.details} 
									columns={Columns}
									pagination={pagination}
								/>
							</Card>
						</Col>
					</Row> 
				</div>				
			</div>
		</>
	);
};
export default StudentStreak;
