/*
File Name: FlashcardList.js
Author: Mevito Gonsalves
Modified On: 12/1/2022
Description: Displaying categories of flashcards.
API's used:
1) /flashcards?type=
2)/flashcards
*/
import React, { useState, useEffect } from "react";
import {
	Row, Col, Button, Space, Tooltip, Card, Table, Form, Radio,
	message
} from 'antd';
import { PlayCircleOutlined, StarFilled } from '@ant-design/icons';
import constants from '../../constants/constants';
import { fetchApi } from "../../services/api";
import { useHistory } from "react-router";
import { COOKIE, getCookie } from "../../services/cookie";
const FlashcardList = (props) => {
	const history = useHistory();
	const [sortedInfo,setsortedInfo]=useState({
		order: 'ascend',
		columnKey: 'srNumber',
	});
	const [sortedInfoPractice,setsortedInfoPractice]=useState({
		order: 'ascend',
		columnKey: 'srNumber',
	});
	const flashcardsFor = [
		{
			label: 'Textbook',
			value: true 
		},
		{
			label: 'Topics',
			value: false 
		},
	];
	const startWith = [
		{
			label: 'Q',
			value: 'Q' 
		},
		{
			label: 'A',
			value: 'A' 
		},
		{
			label: 'Q&A',
			value: 'Both' 
		},
	];
	const flashcardlistColumns = [
		{
			title: '#',
			dataIndex: 'srNumber',
			key: 'srNumber',
			align: 'center',
			className: 'sr-no-col'
		},
		{
			// title: 'Actions',
			key: 'action',
			align: 'center',
			render: (_value,object) => (
				<Space>
					<Tooltip title={object.reviewed!==null&&object.reviewed!==undefined&&object.reviewed!=='0'&&object.reviewed!==object.total?"Resume":"Start"}><Button type="text" icon={<PlayCircleOutlined/>} 
						onClick={()=>showFlashcards(object,false,false)}></Button></Tooltip>
				</Space>
			),
		},
		{
			title: 'Topic',
			dataIndex: 'topic',
			key: 'topic',
			sorter: (a, b) => a.topic.localeCompare(b.topic),
			// sorter: (a, b) => a.topic.length - b.topic.length,
			sortOrder: sortedInfo.columnKey === 'topic' && sortedInfo.order,
			render: (value,object) => (
				<Space>
					{object&&object.dt_first_completed&&
						<Tooltip title={<>
							<span>First completed on {object.dt_first_completed}</span>
							{object&&object.points&&<p>{object.points}{Number(object.points)>1?<span> points earned.</span>:<span> point earned.</span>}</p>}
						</>}>
							<StarFilled className='yellow'/>
						</Tooltip>
					}
					<Tooltip title={object&&!object.dt_first_completed&&(Math.floor(object.total/10)===0?1:Math.floor(object.total/10))+" "+(Math.floor(object.total/10)===0||Math.floor(object.total/10)===1?"point ":"points ")+constants.flashcardTooltip}>
						<Button className="table-link" type="link" 
							onClick={()=>showFlashcards(object,false,false)}>{value}</Button>
					</Tooltip>
				</Space>
			),
		},
		{
			title: 'Reviewed',
			dataIndex: 'viewed',
			key: 'viewed',
			className: 'td-right',
			align: 'center',
			width: 150,
			render: (text,object) => {
				if(object.reviewed>0&&object.reviewed!==object.total){
					return <span className="p-relative"><a onClick={()=>showFlashcards(object,false,true)}>{text}</a></span>;
				}else{
					return <span className="p-relative">{text}</span>;
				}
			},
		},
		{
			title: 'Marked',
			dataIndex: 'marked',
			key: 'marked',
			className: 'td-right',
			align: 'center',
			width: 150,
			render:(text,object)=>{
				if(object.marked>0){
					return <span><a 
						onClick={()=>showFlashcards(object,true,false)}>{text}</a></span>;
				}else{
					return <span>{text}</span>;
				}
			}
		},
	];
	const flashcardlistColumnsPractice = [
		{
			title: '#',
			dataIndex: 'srNumber',
			key: 'srNumber',
			align: 'center',
			className: 'sr-no-col'
		},
		{
			// title: 'Actions',
			key: 'action',
			align: 'center',
			render: (_value,object) => (
				<>
					<Space>
						<Tooltip title={object.reviewed!==null&&object.reviewed!==undefined&&object.reviewed!=='0'&&object.reviewed!==object.total?"Resume":"Start"}><Button type="text" icon={<PlayCircleOutlined/>} 
							onClick={()=>showFlashcards(object,false,false)}></Button></Tooltip>
					</Space>
				</>
			),
		},
		{
			title: 'Chapter',
			dataIndex: 'chapter',
			key: 'chapter',
			sorter: (a, b) => a.chapter.localeCompare(b.chapter),
			// sorter: (a, b) => a.chapter.length - b.chapter.length,
			sortOrder: sortedInfoPractice.columnKey === 'chapter' && sortedInfoPractice.order,
			render: (value,object) => (
				<>
					<Space>
						{object&&object.dt_first_completed&&
						<Tooltip title={<>
							<span>First completed on {object.dt_first_completed}</span>
							{object&&object.points&&<p>{object.points}{Number(object.points)>1?<span> points earned.</span>:<span> point earned.</span>}</p>}
						</>}>
							<StarFilled className='yellow'/>
						</Tooltip>
						}
						<Tooltip title={object&&!object.dt_first_completed&&(Math.floor(object.total/10)===0?1:Math.floor(object.total/10))+" "+(Math.floor(object.total/10)===0||Math.floor(object.total/10)===1?"point ":"points ")+constants.flashcardTooltip}>
							<Button className="table-link" type="link" 
								onClick={()=>showFlashcards(object,false,false)}>{value}</Button>
						</Tooltip>
					</Space>
				</>
			),
		},
		{
			title: 'Reviewed',
			dataIndex: 'viewed',
			key: 'viewed',
			className: 'td-right',
			align: 'center',
			width: 150,
			render: (text,object) => {
				if(object.reviewed>0&&object.reviewed!==object.total){
					return <span className="p-relative"><a onClick={()=>showFlashcards(object,false,true)}>{text}</a></span>;
				}else{
					return <span className="p-relative">{text}</span>;
				}
			},
		},
		{
			title: 'Marked',
			dataIndex: 'marked',
			key: 'marked',
			className: 'td-right',
			align: 'center',
			width: 150,
			render:(text,object)=>{
				if(object.marked>0){
					return <span><a 
						onClick={()=>showFlashcards(object,true,false)}>{text}</a></span>;
				}else{
					return <><span>{text}</span></>;
				}
			}
		},
	];
	const [flashCardListDataPractice,setFlashCardListDataPractice]=useState([]);
	const [flashCardListData,setFlashCardListData]=useState([]);
	const [isFlashTextBook,setIsTextBook]=useState(props.location.state?props.location.state.flashcardsForValue:true);
	const [flashcardStartWith,setFlashcardStartWith]=useState(props.location.state?props.location.state.startwith:'Q');
	const handleChange = (_pagination, _filters, sorter) => {
		setsortedInfo(sorter);
	};
	const handleChangePractice = (_pagination, _filters, sorter) => {
		setsortedInfoPractice(sorter);
	};
	useEffect(() => {
		fetcFlashcardListData();
	}, [isFlashTextBook]);
	//To redirect
	const showFlashcards=(object,marked,reviewed)=>{
		if(object.stud_flashcard_id===null)
		{ 
			addFlashcardData(object);
		}else{history.push({
			pathname:"/flashcards",
			state: {
				startWith: flashcardStartWith,
				cardTopicObject:object,
				cardType:isFlashTextBook?1:0 ,
				isMarked:marked,
				isReviewed:reviewed
			} 
		});
		}
	};
	//To fetch flashcard list data
	const fetcFlashcardListData = () => {
		let userId = getCookie(COOKIE.UserId);
		let topicFlashList=`/flashcards?stud_id=${userId}&type=topic`;
		let chapterFlashList=`/flashcards?stud_id=${userId}&type=chapter`;
		fetchApi(isFlashTextBook?chapterFlashList:topicFlashList, "get").then((res) => {
			if (res && res.data && res.data!==null&& res.data!==undefined) {
				res.data.map((item,index)=>{
					let tempArray=item.viewed.split("/");
					item.reviewed=tempArray[0];
					item.total=tempArray[1];
					item.srNumber=index+1;
				});
				setFlashCardListData(res.data);
				setFlashCardListDataPractice(res.data);
			} else {
				setFlashCardListData([]);
				setFlashCardListDataPractice([]);
			}
		});
	};
	//To fetch flashcard list data
	const addFlashcardData = (objectValue) => {
		let userId = getCookie(COOKIE.UserId);
		let payloadChapter = null;
		let payloadTopic = null;
		payloadChapter = {
			stud_id:userId,	
			chapter_id: objectValue.id,	
			flashcard_details: { "viewed": [] }	
		};
		payloadTopic = {
			stud_id:userId,	
			topic_id: objectValue.id,	
			flashcard_details: { "viewed": [] }	
		};
		let payload=isFlashTextBook?payloadChapter:payloadTopic;
		fetchApi(`/flashcards`, "post",payload).then((res) => {
			if (res.code===200) {
				objectValue.stud_flashcard_id=res.stud_flashcard_id;
				history.push({
					pathname:"/flashcards",
					state: {
						startWith: flashcardStartWith,
						cardTopicObject:objectValue,
						cardType:isFlashTextBook?1:0 
					} 
				});
			} else {
				message.error("Failed to view flashcards");
			}
		});
	};
	return (
		<div className="p-4">
			<Row gutter={[24, 24]}>
				<Col 
					sm={{
						span: 24,
						offset: 0
					}}
					md={{
						span: 24,
						offset: 0
					}}
					lg={{
						span: 18,
						offset: 3
					}}
					xl={{
						span: 16,
						offset: 4
					}}
					xxl={{
						span: 16,
						offset: 4
					}}>
					<Card size="small" className="flashcardlist-card" title={<span>{constants.Flashcards}</span>} extra={
						<Form layout="horizontal">
							<Space>
								<Form.Item className="label-default mb-0" label="Display flashcards for">
									<Radio.Group value={isFlashTextBook} onChange={(e)=>{
										setIsTextBook(e.target.value);
										setsortedInfo({
											order: 'ascend',
											columnKey: 'srNumber',
										});
										setsortedInfoPractice({
											order: 'ascend',
											columnKey: 'srNumber',
										});
									}
									} options={flashcardsFor} size="small" optionType="button" buttonStyle="solid"/>
								</Form.Item>
								<Form.Item className="label-default mb-0" label="Start with">
									<Radio.Group defaultValue={flashcardStartWith} onChange={(e)=>setFlashcardStartWith(e.target.value)} options={startWith} size="small" optionType="button" buttonStyle="solid"/>
								</Form.Item>
							</Space>
						</Form>
					}>
						{isFlashTextBook===true?
							<Table  className="gap-table" columns={flashcardlistColumnsPractice} dataSource={flashCardListDataPractice} pagination={false} onChange={handleChangePractice}/>
							:<Table className="gap-table" columns={flashcardlistColumns} dataSource={flashCardListData} pagination={false} onChange={handleChange}/>
						}</Card>
				</Col>
			</Row>
		</div>
	);
};
export default FlashcardList;
