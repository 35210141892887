import React, { useState, useEffect } from "react";
import { Breadcrumb,Col, Row, Button, Table, Space, Avatar,Spin,Tooltip,Typography,message } from 'antd';
import { FileSearchOutlined, DownloadOutlined, HistoryOutlined,MailOutlined,RollbackOutlined } from '@ant-design/icons';
import '../../../styles/test.css';
import constants from "../../../constants/constants";
import { fetchApi } from "../../../services/api";
import { useHistory } from "react-router";
import moment from "moment";
import { getBadgeIcon } from "../../../globalFunctions/GlobalFunctions";
import Loader from "../../Common/Cred/Loader";
const { Text } = Typography;
const TestResultFullLength = () => {
	const history = useHistory();
	// eslint-disable-next-line react/prop-types
	const [payload] = useState(JSON.parse(sessionStorage.getItem("TestresultObject")));
	const [scoreId,]=useState(JSON.parse(sessionStorage.getItem("TestresultObject"))?JSON.parse(sessionStorage.getItem("TestresultObject"))?.scoreCardId!==undefined&&JSON.parse(sessionStorage.getItem("TestresultObject"))?.scoreCardId!==null?JSON.parse(sessionStorage.getItem("TestresultObject"))?.scoreCardId:sessionStorage.getItem("fullLengthTestScorecadId"):sessionStorage.getItem("fullLengthTestScorecadId"));
	const [isReview,]=useState(JSON.parse(sessionStorage.getItem("TestresultObject"))?JSON.parse(sessionStorage.getItem("TestresultObject"))?.isReview?JSON.parse(sessionStorage.getItem("TestresultObject"))?.isReview:"f":sessionStorage.getItem("isReview"));
	const [ffpTopicList,setFfpTopicList]=useState([]);
	const [pietTopicList,setPietTopicList]=useState([]);
	const [saedTopicList,setSaedTopicList]=useState([]);
	const [ffpTopicListData,setFfpTopicListData]=useState([]);
	const [pietTopicListData,setPietTopicListData]=useState([]);
	const [saedTopicListData,setSaedTopicListData]=useState([]);
	const [resultData,setResultData]=useState([]);
	const [isPass,setIsPass]=useState(true);
	const [,setBadge]=useState("");
	const [loader,setLoader]=useState(false);
	// const [shareScoreMinPercentage,]=useState(62);
	const columns = [
		{
			title: 'Topic',
			dataIndex: 'topic' 
		},
		{
			title: 'Correct',
			className: 'column-money',
			// dataIndex: 'correct',
			align: 'center',
			width:'80px', 
			render:(record,object)=>{
				// let tempRecordArray = record.correct.split("/");
				// if(object.correct!==object.questions){
				// 	return <Tooltip title={constants.IncorrectTooltipMsg}><Button type="link" onClick={()=>{reviewIncorrect(record.category_id,record.topic_id);}}>{object.correct}/{object.questions}</Button></Tooltip>;
				// }else{
				// 	return <span>{object.correct}/{object.questions}</span>;
				// }
				return <span className="ans-display">{object.correct}/{object.questions}</span>;
			}
		},
		{
			title: 'Incorrect',
			className: 'column-money',
			align: 'center',
			width:'90px' ,
			render:(record,object)=>{
				let tempValue=object.questions-object.correct;
				if(tempValue===0){
					return <span>-</span>;
				}else{
					return <Tooltip title={constants.ReviewLinkToolTipMsg}><Button type="link" onClick={()=>{reviewIncorrect(record.category_id,record.topic_id,0);}}>{object.questions-object.correct}/{object.questions}</Button></Tooltip>;
				}
			}
		},
	];
	//To return badge
	// const getbadge=(badgeType)=>{
	// 	switch (
	// 		badgeType
	// 	) {
	// 	case constants.Novoice.toLocaleLowerCase():
	// 		return(<div className="hexagon level1"><StarFilled  className="score-icon"/>  </div>);
	// 	case constants.Achiever.toLocaleLowerCase():
	// 		return(<div className="hexagon level2"><Certificate  className="score-icon"/> </div>);	
	// 	case constants.Scholar.toLocaleLowerCase():
	// 		return(<div className="hexagon level3"><Graduation  className="score-icon"/> </div>);	
	// 	case constants.AllStar.toLocaleLowerCase():
	// 		return(<div className="hexagon level4"><WheatStar  className="score-icon"/> </div>);	
	// 	case constants.Champion.toLocaleLowerCase():
	// 		return(<div className="hexagon level5"><TrophyOutlined  className="score-icon"/> </div>);	
	// 	default:
	// 		<div className="hexagon level1"><StarFilled  className="score-icon"/> </div>;
	// 	}
	// };
	useEffect(() => {
		fetchTestResult();
		if(payload){
			// sessionStorage.setItem("payload", JSON.stringify(props.history.location.state));
			sessionStorage.setItem("fullLengthTestScorecadId",payload?.scoreCardId);
			sessionStorage.setItem("fromTestResult",payload?.fromTestResult);
			sessionStorage.setItem("isReview",payload?.isReview?payload?.isReview:"f");
			if(sessionStorage.getItem("resultScorecardId")!==null){
				sessionStorage.removeItem("resultTypeFull");
				sessionStorage.removeItem("resultTestCategory");
				sessionStorage.removeItem("resultScorecardId");
				sessionStorage.removeItem("resultIsTopic");
			}
		}
	}, []);
	//To fetch test history data
	const fetchTestResult = () => {
		let resultApilink=`/score_card/${scoreId}?type=result`;
		let resultReviewApilink=`/score_card/${scoreId}?type=result&review=1`;
		fetchApi(isReview==="t"?resultReviewApilink:resultApilink, "get").then((res) => {
			setLoader(true);
			if (res.code===200) {
				setResultData(res.data);
				setFfpTopicList(res.data.categories?res.data.categories.FPP?res.data.categories.FPP:[]:[]);
				setFfpTopicListData(res.data.categories?res.data.categories.FPP?res.data.categories.FPP.topics:[]:[]);
				setPietTopicList(res.data.categories?res.data.categories.PIET?res.data.categories.PIET:[]:[]);
				setPietTopicListData(res.data.categories?res.data.categories.PIET?res.data.categories.PIET.topics:[]:[]);
				setSaedTopicList(res.data.categories?res.data.categories.SAED?res.data.categories.SAED:[]:[]);
				setSaedTopicListData(res.data.categories?res.data.categories.SAED?res.data.categories.SAED.topics:[]:[]);
				setIsPass(res.data.test_result?res.data.test_result.toLocaleLowerCase()==="pass"?true:false:false);
				setBadge(res.data.bagde?res.data.bagde.toLocaleLowerCase():"");
				setLoader(false);
			} 
			setLoader(false);
		});
	};
	//To download score card
	const downloadScoreCard = () => {
		window.open(constants.ApiUrl+'/download/pdf/'+scoreId);
	};
	//Function to redirect to Review Incorrect answer.
	const reviewIncorrect=(categoryId,topicId,type)=>{
		let categoryDetails={};
		if(scoreId){
			categoryDetails.scoreCardId=scoreId;
			categoryDetails.review="full_length";
			if(type==0){
				categoryDetails.ans_inc="incorrect";
			}
			if(type==1){
				categoryDetails.ans_inc="correct";
			}
			if(type==2){
				categoryDetails.ans_inc="all";
			}
			categoryDetails.testTitle=resultData&&resultData.test_length?resultData.test_length:"";
			categoryDetails.attempt=resultData&&resultData.test_attempt?resultData.test_attempt:"";
			categoryDetails.totalTimeTaken=resultData&&resultData.total_time_taken?resultData.total_time_taken:"";
			categoryDetails.totalCorrectAnswers=resultData&&resultData.total_correct_questions?resultData.total_correct_questions:"";
			categoryDetails.totalTestQuestions=resultData&&resultData.test_total_questions?resultData.test_total_questions:"";
			categoryDetails.testTakenDate=resultData&&resultData.test_taken_date?moment(resultData.test_taken_date).format(constants.dateFormat):"";
			categoryDetails.testTakenTime=resultData&&resultData.test_taken_time?resultData.test_taken_time:"";
			categoryDetails.userId = payload?.userId;
			if(categoryId){
				categoryDetails.categoryId=Number(categoryId);
			}
			if(topicId){
				categoryDetails.topicId=Number(topicId);
			}
			history.push({
				pathname:"/incorrect-test",
				state:{ categoryDetails:categoryDetails } 
			});
		}
	};
	const returnToPrevieousPage = () =>{
		switch (payload?.returnTest){
		case "Test Results" :
			history.push({ pathname:"/test-results" });
			break; 
		case "Tests" :
			history.push({ pathname:"/test" });
			break;
		}
	};
	//sending email to user.
	const emailResults = () => {
		fetchApi(`/send_email/${scoreId}`, "post").then((res) => {
			if(res.code===200){
				message.success(res.message);
			}else{
				message.error(res.message);
			}
		});
	};
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}> 
				<div className="p-3 div">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						{payload&&payload.sd && payload&&payload.ed &&(
							<Breadcrumb.Item><a onClick={() => {
								history.push({ 
									pathname:payload&& payload.push2 ? payload.push2 :payload.push ,
									state : {
										sd : payload && payload.sded ===2 ? "":payload.sd, 
										ed : payload && payload.sded ===2 ? "": payload.ed,
									} 
								});
							}}>{payload&&payload.title2 ? payload.title2 :payload.title}</a></Breadcrumb.Item>
						)}
						{payload&&payload.title2 && (
							<Breadcrumb.Item><a onClick={() => {
								history.push({ 
									pathname:payload&& payload.push ,
									state : {
										sd : payload && payload.sded ===2 ? "":payload.sd, 
										ed : payload && payload.sded ===2 ? "": payload.ed,
									} 
								});
							}}>{payload&&payload.title}</a></Breadcrumb.Item>
						)}
						{payload&&payload.sd && payload&&payload.ed && (
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/registered-students" });
							}}>Registered Students</a></Breadcrumb.Item>
						)}
						{payload&&payload.name &&(
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/test" });
							}}>Tests</a></Breadcrumb.Item>
						)}
						{payload&&payload.fromTestResult &&(
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/test-results" });
							}}>Tests Results</a></Breadcrumb.Item>
						)}
						<Breadcrumb.Item>Results</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div style={{ visibility:"hidden" }}>
					<b>Score card ID:</b> {scoreId}
				</div>
				<div className="p-4 resultPage">
					<Row gutter={[{
						xs: 0,
						sm: 0,
						md: 24,
						lg: 24 
					}, {
						xs: 24,
						sm: 24,
						md: 24,
						lg: 40 
					}]} >
						<Col span={24}>
							<Row className="user-detail-wrapper">
								<Col span={24}>
									<Space size="middle" wrap>
										<Avatar size={64} className="avatar mr-2 my-auto" >{resultData.is_active==="4"?"#":resultData.name_short_inital}</Avatar>
										<div className="user-details">
											<p className="user-name-result">{resultData.is_active==="4"?"-":resultData.user_name}</p>
											<Space wrap>
												<p className="test-name">Test Results of {resultData.test_length} - {resultData.test_total_questions} questions </p>
												<p><Text type="secondary" className="test-name">({moment(resultData.test_taken_date).format(constants.dateFormat)})</Text></p>
											</Space>
											<div>
												<Space wrap>
													{resultData.total_correct_questions===resultData.test_total_questions?null:
														<Button className="btnDb" onClick={()=>{reviewIncorrect(null,null,0);}}><FileSearchOutlined/>{constants.ReviewIncorrectBtn}</Button>
													} 
													{/* {resultData.total_correct_questions!==0&&resultData.total_correct_questions!=="0"?
														<Button className="btnDb" onClick={()=>{reviewIncorrect(null,null,1);}} ><FileSearchOutlined/>{constants.ReviewCorrectBtn}</Button>:null} */}
													<Button className="btnDb" onClick={()=>{reviewIncorrect(null,null,2);}} ><FileSearchOutlined/>{constants.ReviewAllBtn}</Button>
													<Button className="btnLb d-sm-block" onClick={downloadScoreCard}><DownloadOutlined/>{constants.DownloadResultBtn}</Button>
													{resultData.is_disable_email===1||resultData.is_active==="4"?null:
														<Button className="btnLighter" onClick={emailResults}><MailOutlined/>{constants.EmailResultsBtn}</Button>
													}
													<Button className="purple-bg" onClick={returnToPrevieousPage}><RollbackOutlined/>{"Return to"+ payload?.returnTest}</Button>
												</Space>
											</div>
										</div>
									</Space>
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							{isPass?
								<div className="score-section score-bkg">
									<Space size="large" wrap>
										<span className="pass-score">PASS : {resultData.test_percentage}%</span>
										<span className="pass-score">SCORE : {resultData.total_score}</span>   
									</Space> 
								</div>:
								<div className="score-section score-bkg">
									<Space size="large" wrap>
										<span>DID NOT PASS : {resultData.test_percentage}%</span>
										<span>SCORE : {resultData.total_score}</span>    
									</Space>
								</div> 
							}
							<Row gutter={{
								xs: 8,
								sm: 16,
								md: 8,
								lg: 8 
							}} className="w-100 result-table-container">
								<Col span={24} lg={8} >
									<Table 
										columns={columns} 
										dataSource={ffpTopicListData} 
										bordered
										title={() => {
											return(
												<div className="table-title w-100">
													<p>{constants.CategoryFPP}</p>
													{/* {ffpTopicList.total_correct!==ffpTopicList.total_questions?
														<p className="title-two"><Tooltip title={constants.IncorrectTooltipMsg}><Button type="link" onClick={()=>{reviewIncorrect(ffpTopicList&&ffpTopicList.category_id?ffpTopicList.category_id:null,null);}}>{ffpTopicList.total_correct?ffpTopicList.total_correct:0}/{ffpTopicList.total_questions?ffpTopicList.total_questions:0}</Button></Tooltip> questions answered correctly.</p>
														:<p className="title-two">{ffpTopicList.total_correct?ffpTopicList.total_correct:0}/{ffpTopicList.total_questions?ffpTopicList.total_questions:0} questions answered correctly.</p>
													} */}
													<p className="title-two">{ffpTopicList.total_correct?ffpTopicList.total_correct:0}/{ffpTopicList.total_questions?ffpTopicList.total_questions:0} questions answered correctly</p>
												</div>
											);
										}}
										className="customTable grey-custom-table resut-table"
										pagination={false}
									/>
								</Col>
								<Col span={24} lg={8}>
									<Table 
										columns={columns} 
										dataSource={saedTopicListData} 
										bordered
										//   title={() => '[SAED] Screening, Assessment, Evaluation and Diagnosis'}
										title={() => {
											return(
												<div className="table-title w-100">
													<p>{constants.CategorySAED}</p>
													{/* {saedTopicList.total_correct!==saedTopicList.total_questions?
														<p className="title-two"><Tooltip title={constants.IncorrectTooltipMsg}><Button type="link" onClick={()=>{reviewIncorrect(saedTopicList&&saedTopicList.category_id?saedTopicList.category_id:null,null);}}>{saedTopicList.total_correct?saedTopicList.total_correct:0}/{saedTopicList.total_questions?saedTopicList.total_questions:0}</Button></Tooltip> questions answered correctly.</p>
														:<p className="title-two">{saedTopicList.total_correct?saedTopicList.total_correct:0}/{saedTopicList.total_questions?saedTopicList.total_questions:0} questions answered correctly.</p>
													} */}
													<p className="title-two">{saedTopicList.total_correct?saedTopicList.total_correct:0}/{saedTopicList.total_questions?saedTopicList.total_questions:0} questions answered correctly</p>
												</div>
											);
										}}
										//   title={() => this.handleTitleSAED()}
										className="customTable grey-custom-table resut-table"
										//   rowSelection={{
										//       type: selectionType
										//   }}
										pagination={false}
									/>
								</Col>
								<Col span={24} lg={8}>
									<Table 
										columns={columns} 
										dataSource={pietTopicListData} 
										bordered
										//   title={() => this.handleTitlePIET()}
										className="customTable grey-custom-table resut-table"
										title={() => {
											return(
												<div className="table-title w-100">
													<p>{constants.CategoryPIET}</p>
													{/* {pietTopicList.total_correct!==pietTopicList.total_questions?
														<p className="title-two"><Tooltip title={constants.IncorrectTooltipMsg}><Button type="link" onClick={()=>{reviewIncorrect(pietTopicList&&pietTopicList.category_id?pietTopicList.category_id:null,null);}}>{pietTopicList.total_correct?pietTopicList.total_correct:0}/{pietTopicList.total_questions?pietTopicList.total_questions:0}</Button></Tooltip> questions answered correctly.</p>
														:<p className="title-two">{pietTopicList.total_correct?pietTopicList.total_correct:0}/{pietTopicList.total_questions?pietTopicList.total_questions:0} questions answered correctly.</p>
													} */}
													<p className="title-two">{pietTopicList.total_correct?pietTopicList.total_correct:0}/{pietTopicList.total_questions?pietTopicList.total_questions:0} questions answered correctly</p>
												</div>
											);
										}}
										pagination={false}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={24} sm={24} md={24}>
							<Row className="result-bottom-section">
								<Col md={16} className="mb-3 fs-20">
									{resultData.is_timed==="t"?
										<><span className="correct-answer mr-1">You answered {resultData.total_correct_questions}/{resultData.test_total_questions} questions correctly in {resultData.total_time_taken}</span><Tooltip title="Timed"><HistoryOutlined/></Tooltip></>
										:
										<p className="correct-answer">You answered {resultData.total_correct_questions}/{resultData.test_total_questions} questions correctly.</p>
									}
									{resultData.is_timed==="t"?
										<p className="avg-time">Average time per question : {resultData.average_time}</p>
										:null}
								</Col>
								<Col className="justify-end-middle" md={8}>
									<div className="pr-3 space-between">
										<div className="pr-3">
											<p>{constants.Levels[resultData&&
											resultData.level&&
											resultData.level.current_level&&
											resultData.level.current_level.level]}
											</p>
											<p>{resultData&&resultData.level&&resultData.level.current_level&&resultData.level.current_level.designation}</p>
										</div>
										{getBadgeIcon(resultData&&resultData.level&&resultData.level.current_level&&resultData.level.current_level.level,"","score-icon")}
									</div>
									<div className="fs-20">
										<p>You earned <span className="navy-2">{resultData.points_earned} points</span> on this test attempt </p>
										<p>Total points earned : <span className="navy-2">{resultData.total_points}</span> </p>
										{!resultData.is_next_level?
											<>
												{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.current_level.level>=1?
													resultData&&resultData.level&&resultData.level.next_level&&resultData.level.current_level.level>=5?
														<p>{constants.Level6Message}</p>:
														<p><span className="navy-2">{resultData.points_required_level}</span> {constants.ReachLevelText}{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.next_level.level+", "+resultData.level.next_level.designation}</p>:
													<p><span>{constants.ReachLevel0}{", "}</span>{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.next_level.designation}</p>
												}
											</>
											:<p>Congratulations! You reached <span className="navy-2">Level {resultData&&resultData.level&&resultData.level.current_level.level}</span></p>
										}
									</div>
								</Col>
							</Row>
						</Col>
					</Row> 
				</div>
			</Spin>
		</>
	);
};
export default TestResultFullLength;			
