import React, { useEffect, useState } from 'react';
import { fetchAdminApi } from "../../../services/api";
import { Breadcrumb, Row, Col, Card, Form, BackTop, Spin, Input,DatePicker,Button,message, Checkbox, Space } from 'antd';
import constants from "../../../constants/constants";
import Loader from "../../Common/Cred/Loader";
import { useHistory } from "react-router";
import moment from "moment";
const { TextArea } = Input;
const EditCronSetting = (props) => {
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	const [cronId] = useState(props && props.location && props.location.state && props.location.state.cronSettingId);
	const [fromDateValue, setfromDateValue] = useState("");
	const [lastRunDateValue, setLastRunDateValue] = useState("");
	const [form] = Form.useForm();
	const [emailChecked,setEmailChecked]=useState(false);
	useEffect(() => {
		fetchCronData();
	}, []);
	const horizontalLayout = {
		labelCol: { span: 2, },
		wrapperCol: { span: 24, },
	};
	const fetchCronData = () => {
		setLoader(true);
		fetchAdminApi(`/crons-list/${cronId}`, "get").then((res) => {
			if (res && res.data) {
				setFormValues(res.data);
				setLoader(false);
			}else {
				setLoader(false);
			}
		});
	};
	const fromDate = (date) => {
		if(date){
			let fulldate = date?._d;
			let converdateformate=moment(fulldate).format("YYYY-MM-DD h:mm:ss");
			setfromDateValue(converdateformate);
		}else{
			setfromDateValue(null);
		}
	};
	const lastRunDate = (date) => {
		if(date){
			let fulldate = date?._d;
			let converdateformate=moment(fulldate).format("YYYY-MM-DD h:mm:ss");
			setLastRunDateValue(converdateformate);
		}else{
			setLastRunDateValue(null);
		}
	};
	const setFormValues=(values)=>{
		setfromDateValue(values?.from_date);
		setLastRunDateValue(values?.last_run_date);
		setEmailChecked(values.send_email==="t"?true:false);
		form.setFieldsValue({
			type:values?.type,
			last_run_date:values?.last_run_date !==null ? moment(values?.last_run_date) : "",
			from_date:values?.from_date !==null ?moment(values?.from_date):"",
			response:values?.response,
			test_response:values?.test_response,
			croninfo:values?.cron_info,
		});
	};
	const onFinalSave = (values) =>{
		let payload = {};
		payload.from_date = fromDateValue?fromDateValue:null;
		payload.last_run_date = lastRunDateValue?lastRunDateValue:null;
		payload.id = cronId;
		payload.type = values.type?values.type:null;
		payload.response = values.response?values.response:null;
		payload.test_response = values.test_response?values.test_response:null;
		payload.cron_info=values.croninfo?values.croninfo:null;
		payload.send_email=emailChecked===true?"t":"f";
		fetchAdminApi(`/crons-list/${cronId}`, "put",payload).then((res) => {
			if (res && res.code) {
				if (res.code === 200) {
					message.success(res.message);
					history.push("/cron-result");
				} else {
					message.error(res && res.message);
				}
			} else {
				message.error(res && res.message);
			}
		});
	};
	const onFinishFailed = () =>{
	};
	const sendEmailCheckChange=(e)=>{
		setEmailChecked(e.target.checked);
	};
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="div">
					<div className="p-3">
						<Breadcrumb>
							<Breadcrumb.Item ><a onClick={() => {
								history.push({ pathname: "/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick={() => {
								history.push({ pathname: "/masters-menu" });
							}}>Masters</a></Breadcrumb.Item>
							<Breadcrumb.Item><a onClick={() => {
								history.push({ pathname: "/cron-result" });
							}}>{constants.cronSetting}</a></Breadcrumb.Item>
							<Breadcrumb.Item>{constants.editCronSetting}</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<BackTop/>
					<Row gutter={[24, 24]} >
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">{constants.editCronSetting}</span>}>
								<Row gutter={[24, 24]}>
									<Col xs={24} sm={24} md={24} lg={24}>
										<Form form={form} onFinish={onFinalSave} onFinishFailed={onFinishFailed}>
											<Form.Item
												{...horizontalLayout}
												label="type"
												name="type">
												<Input/>
											</Form.Item>
											<Form.Item
												{...horizontalLayout}
												label="From date"
												name="from_date">
												<DatePicker onChange={fromDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item
												{...horizontalLayout}
												label="Last run date"
												name="last_run_date">
												<DatePicker onChange={lastRunDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item
												{...horizontalLayout}
												label="Cron Information"
												name="croninfo">
												<TextArea rows={2}/>
											</Form.Item>
											<Form.Item
												{...horizontalLayout}
												label="Response"
												name="response">
												<TextArea rows={2}/>
											</Form.Item>
											<Form.Item
												{...horizontalLayout}
												label="Test response"
												name="test_response">
												<TextArea rows={2}/>
											</Form.Item>
											<Form.Item
												{...horizontalLayout}
												label="Send Email"
												name="send_email">
												<Checkbox checked={emailChecked} onChange={sendEmailCheckChange}/>
											</Form.Item>
											<div className="text-right">
												<Space>
													<Form.Item>
														<Button htmlType="submit" className="btn-submit">Save</Button>
													</Form.Item>
													<Form.Item>
														<Button onClick = {()=>{history.push("/cron-result");}}>Cancel</Button>
													</Form.Item>
												</Space>
											</div>
										</Form>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</Spin>
		</>
	);
};
export default EditCronSetting;