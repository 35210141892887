import React,{ useEffect,useState } from 'react';
const JsonTestResponse =() => {
	const [jsonData, setJsonData] = useState([]);
	console.log("jsonData",jsonData);
	useEffect(() => {
		fetchJsonData();
	}, []);
	const fetchJsonData = () =>{
		let data = sessionStorage.getItem("testResponseData");
		setJsonData(JSON.stringify(data));
		// sessionStorage.removeItem("testResponseData");
	};
	return (
		<>
			<div><p>{jsonData}</p></div>
		</>
	);
};
export default JsonTestResponse;