/*
File Name: Flashcards.js
Author: Mevito Gonsalves
Modified On: 12/1/2022
Description: Display flash card questions and answers in slide and list view.
API's used:
1) `/flashcards/${categoryId}/${topicId}
*/
import React, { useState, useEffect, useRef } from "react";
import {
	Tabs, Row, Col, Button, Space, Tooltip, Form, Radio, Typography,List,Skeleton,Card,Carousel,
	message,
	Progress,Spin, Divider
} from 'antd';
import Icon,{ 
	PlayCircleOutlined, 
	CopyOutlined, OrderedListOutlined,LeftCircleOutlined, RightCircleOutlined, CloseOutlined
} from '@ant-design/icons';
import "../../styles/flashcard.css";
import CustomIcons from "../../assets/CustomIcons";
import { fetchApi } from "../../services/api";
import constants from "../../constants/constants";
import { useHistory } from "react-router";
import Loader from "../Common/Cred/Loader";
const Bookmark = (props) => (<Icon component={CustomIcons.getsvg("Bookmark")} {...props}/>);
const BookmarkFill = (props) => (<Icon component={CustomIcons.getsvg("BookmarkFill")} {...props}/>);
const { TabPane } = Tabs;
const { Text } = Typography;
const Shuffle = (props) => (<Icon component={CustomIcons.getsvg("Shuffle")} {...props}/>);
const Flip = (props) => (<Icon component={CustomIcons.getsvg("Flip")} {...props}/>);
const Stop = (props) => (<Icon component={CustomIcons.getsvg("Stop")} {...props}/>);
const Flashcards = (props) => {
	const history = useHistory();
	const [flashCardQnAListData,setFlashCardQnAListData]=useState([]);
	const [flashCardReview,setflashCardReview]=useState([]);
	const [flashCardNotReview,setflashCardNotReview]=useState([]);
	const [flashCardMarked,setFlashCardMarked]=useState([]);
	// eslint-disable-next-line react/prop-types
	const [startWithProp,]=useState(checkPropsData(props,'startWithProp'));
	// eslint-disable-next-line react/prop-types
	const [startWithQ,]=useState(checkPropsData(props,'startWithQ'));
	const [flashDisplay,setFlashDisplay]=useState(checkPropsData(props,'flashDisplay'));
	const [tabkeyValue,setTabKey]=useState(checkPropsData(props,'tabkeyValue'));
	const [playFlashCard,setFlashCard]=useState(false);
	const [progressPercent,setProgressPercent]=useState(0);
	const [reviewedCardCount,setReviewedCardCount]=useState(1);
	// const [markedList,setMarkedList]=useState([]);
	const [viewedList,setViewedList]=useState([]);
	const [flashCardReviewDisable,setFlashCardReviewDisable]=useState(false);
	const [flashCardNotReviewDisable,setFlashCardNotReviewDisable]=useState(false);
	const [flashCardMarkedDisable,setFlashCardMarkedDisable]=useState(false);
	const [nextDisable,setNextDisable]=useState(false);
	const [prevDisable,setPrevDisable]=useState(false);
	const [isRadiochanged,setIsRadiochanged]=useState(false);
	const [isReload,setIsReload]=useState(false);
	const [shuffleDisable,setShuffleDisable]=useState(false);
	const [seconds,setSeconds]=useState(1);
	const [loader,setLoader]=useState(false);
	const [flashCardTittle,setFlashcardTittle]=useState("");
	const [listReload,setListReload]=useState(false);
	const [reviewedList,setReviewedList]=useState([]);
	const [dateFirstCompleted,setDateFirstCompleted]=useState("");
	const [initialCarousalSlide,]=useState(getInitialSlide(props&&props.history&&props.history.location&&props.history.location.state?props.history.location.state.cardTopicObject:sessionStorage.getItem("flashCardObject")));
	// eslint-disable-next-line react/prop-types
	let topicId=checkPropsData(props,'topicId');
	// eslint-disable-next-line react/prop-types
	let categoryId=checkPropsData(props,'categoryId');
	// Card Flip
	const [isFlipped, setisFlipped] = useState(false);
	const [carouselEffect,setCarouselEffect]=useState("fade");
	const [browserTime,setbrowserTime]=useState("");
	const [serverTime,setServerTime]=useState("");
	const isFlippedref=useRef(false);
	const handleClick=()=>{
		setisFlipped(!isFlipped);
	};
	const handleFlip=()=>{
		if(isFlippedref.current===true){
			setisFlipped(true);
		}else{
			setisFlipped(false);
		}
	};
	let pageSizeValue=10;
	// Slide
	const carouselRef = React.useRef();
	const carouselRefQnA=React.useRef();
	const handleNext = () => {
		carouselRef.current.next();
	};
	const handlePrev = () => {
		carouselRef.current.prev();
	};
	const handleNextQnA = () => {
		carouselRefQnA.current.next();
	};
	const handlePrevQnA = () => {
		carouselRefQnA.current.prev();
	};
	const flashcardsDisplay = [
		{
			label: 'All',
			value: 'All',
			disabled:playFlashCard
		},
		{
			label: 'Reviewed',
			value: 'Reviewed',
			disabled:disableRadio(flashCardReviewDisable,'Reviewed')
		},
		{
			label: 'Not Reviewed',
			value: 'Not Reviewed' ,
			disabled:disableRadio(flashCardNotReviewDisable,'Not Reviewed')
		},
		{
			label: 'Marked',
			value: 'Marked' ,
			disabled:disableRadio(flashCardMarkedDisable,'Marked')
		},
	];
	function disableRadio(disabledRadioValue,radioValue) {
		return disabledRadioValue||(flashDisplay!==radioValue&&playFlashCard===true)?true:false||loader;
	}
	let OperationsSlot = {
		left: 
								<Row gutter={[8, 0]}>
									<Col lg={8}>
										<div className="flash-controls">
											<Space>
												{
													!playFlashCard?
														<Tooltip placement="top" title="Play"><Button type="text" icon={<PlayCircleOutlined/>} onClick={()=>setFlashCard(true)}/></Tooltip>
														:
														<Tooltip placement="top" title="Stop"><Button type="text" icon={<Stop className="icon-md"/>} onClick={onStopButton}/></Tooltip>
												}
												<Tooltip placement="top" title="Shuffle"><Button type="text" icon={<Shuffle className="icon-md"/>} onClick={shuffleDisable?shuffleSortedData:shuffleData} disabled={playFlashCard}/></Tooltip>
												{
													// eslint-disable-next-line react/prop-types
													startWithProp===true?
														<Tooltip placement="top" title="Flip"><Button type="text" icon={<Flip className="icon-md"/>} onClick={handleClick}/></Tooltip>
														:null}
											</Space>
										</div>
									</Col>
									<Col lg={16} className="flash-filter">
										<Form layout="horizontal">
											<Form.Item className="label-default mb-0" label="Display">
												<Radio.Group options={flashcardsDisplay} size="small" optionType="button" buttonStyle="solid" onChange={(e)=>onRadioGroupchange(e)} value={flashDisplay}/>
											</Form.Item>
										</Form>
									</Col>
								</Row>
	};
	let OperationsSlot2 = {
		left: 
								<Row gutter={[8, 0]}>
									<Col lg={8}>
										<div className="flash-controls">
											<Space>
												<Tooltip placement="top" title="Shuffle"><Button type="text" icon={<Shuffle className="icon-md" onClick={shuffleDisable?shuffleSortedData:shuffleData}/>}/></Tooltip>
											</Space>
										</div>
									</Col>
									<Col lg={16} className="flash-filter">
										<Form layout="horizontal">
											<Form.Item className="label-default mb-0" label="Display">
												<Radio.Group options={flashcardsDisplay} size="small" optionType="button" buttonStyle="solid" onChange={(e)=>setFlashDisplay(e.target.value)} value={flashDisplay}/>
											</Form.Item>
										</Form>
									</Col>
								</Row>
	};
	function getStartWithProp(propValue) {
		return propValue==='Both'?false:true;
	}
	// function to check valid data
	function checkPropsData(propsPassed,valueToCheck){
		if(propsPassed&&propsPassed.history&&propsPassed.history.location&&propsPassed.history.location.state){
			return{
				startWithProp:getStartWithProp(propsPassed.history.location.state.startWith),
				startWithQ:propsPassed.history.location.state.startWith==='Q'?true:false,
				flashDisplay:propsPassed.history.location.state.isMarked===true?'Marked':propsPassed.history.location.state.isReviewed===true?'Reviewed':'All',
				tabkeyValue:sessionStorage.getItem("tabkey")?sessionStorage.getItem("tabkey"):'1',
				topicId:propsPassed.history.location.state.cardTopicObject.stud_flashcard_id,
				categoryId:propsPassed.history.location.state.cardType,
			}[valueToCheck];
		}else{
			return{
				startWithProp:getStartWithProp(sessionStorage.getItem("startWithQ")),
				startWithQ:sessionStorage.getItem("startWithQ")==='Q'?true:false,
				flashDisplay:sessionStorage.getItem("startWithProp"),
				tabkeyValue:sessionStorage.getItem("tabkey"),
				topicId:sessionStorage.getItem("topicId"),
				categoryId:sessionStorage.getItem("categoryId"),
			}[valueToCheck];
		}
	}
	function onStopButton(){
		setSeconds(1);
		setFlashCard(false);
	}
	//To fetch flashcard questions and answers list data displayData
	const fetcFlashcardQnAListData = () => {
		setLoader(true);
		fetchApi(`/flashcards/${categoryId}/${topicId}`, "get").then((res) => {
			if (res && res.data && res.data!==null&& res.data!==undefined) {
				setFlashcardTittle(res.data.title);
				// setMarkedList(res.data.questions.marked_list_ids);
				setViewedList(res.data.questions.viewed_list_ids);
				setFlashCardQnAListData(res.data.questions.all);                        
				setflashCardReview(res.data.questions.flashcards_reviewed);
				setflashCardNotReview(res.data.questions.flashcards_not_reviewed);
				setFlashCardMarked(res.data.questions.flashcards_marked);
				setFlashCardMarkedDisable(res.data.questions.length<1?true:false);
				setDateFirstCompleted(res.data.dt_first_completed);
				const reviewListTemp = res.data.questions.viewed_list_ids.filter(item => {
					return item.flashcard_viewed_on !==null;
				});
				setReviewedList(reviewListTemp);
				setLoader(false);
			} else {
				setLoader(false);
			}
		});
	};
	function getInitialSlide(flashObj){
		let tempSlideNum=flashObj&&flashObj.reviewed!==flashObj.total?flashObj.reviewed:0;
		let tempNum=parseInt(tempSlideNum);
		let resumeSlide=0;
		if(props&&props.history&&props.history.location&&props.history.location.state){
			if(props.history.location.state.isMarked!==true&&props.history.location.state.isReviewed!==true){
				if(tempNum!=0){
					resumeSlide=tempNum-1;
				}
			}
		}
		return resumeSlide;
	}
	//To upldate flashcard 
	const updateFlashcard = async(viewedCardList) => {
		if(carouselEffect==="fade"){
			setCarouselEffect("scrollx");
		}
		let payload = null;
		payload = {
			flashcard_details:{
				viewed: viewedCardList,
				// marked: markedCardList,
			},
			questions_count:flashCardQnAListData.length,
		};
		fetchApi(`/flashcards/${categoryId}/${topicId}`, "put",payload).then((res) => {
			if (!res && res.code!==200 ) {
				message.error(res.message);
			}else{
				setServerTime(res.current_date?res.current_date:"");
				let browerDateTime = new Date();
				setbrowserTime(browerDateTime.toString());
			}
		});
	};
		//To get display data
	function getDisplayFlashList(){
		switch (flashDisplay) {
		case 'All':
			return flashCardQnAListData;
		case 'Reviewed':
			return flashCardReview;
		case 'Not Reviewed':
			return flashCardNotReview;
		case 'Marked':
			return flashCardMarked;
		default:
			return flashCardQnAListData;
		}
	}
	function getMarkIcon(value){
		switch (value) {
		case true:
			return <BookmarkFill className='flag'/>;
		case false:
			return <Bookmark/>;
		}
	}
	//To shuffle flashcards
	function shuffleData(){
		if(flashCardQnAListData.length>1){
			let tempdata=flashCardQnAListData;
			tempdata.sort(() => 0.5 - Math.random());
			// tempdata.map((obj)=>{
			// 	obj.review=false;
			// });
			setFlashCardQnAListData(tempdata);
			// let flashTempMarked=tempdata.filter((item)=>item.marked===true);
			// setflashCardReview([]);
			// setViewedList([]);
			// setflashCardNotReview(tempdata);
			// setFlashCardMarked(flashTempMarked);
			// setFlashCardReviewDisable(true);
			// setFlashCardNotReviewDisable(tempdata.length<1?true:false);
			// setFlashCardMarkedDisable(flashTempMarked.length<1?true:false);
			setIsRadiochanged(true);
			if(startWithProp===true){
				carouselRef.current.goTo(0, false);
			}else{
				carouselRefQnA.current.goTo(0, false);
			}
		}
	}
	//shuffle sorted arrays 
	function shuffleSortedData(){
		let tempdata=getDisplayFlashList();
		if(tempdata.length>1){
			if(flashDisplay==="Reviewed"){
				let tempReview=flashCardQnAListData;
				tempReview.sort(() => 0.5 - Math.random());
				let flashReviewedTemp=tempReview.filter((item)=>item.review===true);
				setflashCardReview(flashReviewedTemp);
			}else if(flashDisplay==="Not Reviewed"){
				tempdata.sort(() => 0.5 - Math.random());
				setflashCardNotReview(tempdata);
			}else if(flashDisplay==="Marked"){
				tempdata.sort(() => 0.5 - Math.random());
				setFlashCardMarked(tempdata);
			}
			setListReload(!listReload);
			if(startWithProp===true){
				carouselRef.current.goTo(0, false);
			}else{
				carouselRefQnA.current.goTo(0, false);
			}
		}
	}
	function setDisableInALL() {
		let flashReviewed=flashCardQnAListData.filter((item)=>item.review===true);
		setflashCardReview(flashReviewed);
		let flashNotReviewed=flashCardQnAListData.filter((item)=>item.review===false);
		let flashMarked=flashCardQnAListData.filter((item)=>item.marked===true);
		setFlashCardReviewDisable(flashReviewed.length<1?true:false);
		setFlashCardNotReviewDisable(flashNotReviewed.length<1?true:false);
		setFlashCardMarkedDisable(flashMarked.length<1?true:false);
	}
	function setNavigationButtonDisable(b,tempLength) {
		setPrevDisable(b===0?true:false);
		setNextDisable(b===tempLength-1?true:false);
	}
	function reviewedCardCountset(count,tempLength) {
		setReviewedCardCount(count>tempLength?tempLength:count);
	}
	//on change on carousal question and answer slide
	function onChangeCarouselref(a,b,c){
		setisFlipped(false);
		setSeconds(1);
		if(c){
			console.log(c,isRadiochanged);
		}
		let markValueArrayAdd=getDisplayFlashList();
		let markedObj=markValueArrayAdd[b];
		if(markedObj){
			setIsReload(markedObj.marked);
		}
		let tempLength=markValueArrayAdd.length;
		setNavigationButtonDisable(b,tempLength);
		let count=b+1;
		let progressPercentTemp=(count/tempLength)*100;
		setProgressPercent(progressPercentTemp);
		reviewedCardCountset(count,tempLength);
		let tempArrayView=viewedList;
		if(flashDisplay!=='Marked'&&flashDisplay!=='Reviewed'&&flashDisplay!=='Not Reviewed'){
			let tempList=flashCardQnAListData;
			let viewItem=tempList[b];
			if(viewItem){
				let indexMainArray=flashCardQnAListData.indexOf(viewItem);
				flashCardQnAListData[indexMainArray].review=true;
				flashCardQnAListData[indexMainArray].flashcard_viewed_on=new Date();
				let CheckPresentvalue=viewedList.some(el => el.flashcard_id === viewItem.flashcard_id);
				let viewTempItem={
					flashcard_id:viewItem.flashcard_id,
					flashcard_viewed_on:true,
					is_marked:false,
				};
				if(CheckPresentvalue===false){
					tempArrayView=[...tempArrayView,viewTempItem];
					setViewedList(tempArrayView);
				}else{
					let tempIndexValue=tempArrayView.findIndex(el => el.flashcard_id ===viewItem.flashcard_id);
					if(tempArrayView[tempIndexValue].flashcard_viewed_on===null){
						tempArrayView[tempIndexValue].flashcard_viewed_on=true;
					}
					setViewedList(tempArrayView);
				}
			}
		}
		if(flashDisplay==='Not Reviewed'){
			let tempList=flashCardNotReview;
			let viewItem=tempList[b];
			if(viewItem){
				let indexMainArray=flashCardQnAListData.indexOf(viewItem);
				flashCardQnAListData[indexMainArray].review=true;
				flashCardQnAListData[indexMainArray].flashcard_viewed_on=new Date();
				let CheckPresentvalue=viewedList.some(el => el.flashcard_id === viewItem.flashcard_id);
				let viewTempItem={
					flashcard_id:viewItem.flashcard_id,
					flashcard_viewed_on:true,
					is_marked:false,
				};
				if(CheckPresentvalue===false){
					tempArrayView=[...tempArrayView,viewTempItem];
					setViewedList(tempArrayView);
				}else{
					let tempIndexValue=tempArrayView.findIndex(el => el.flashcard_id ===viewItem.flashcard_id);
					if(tempArrayView[tempIndexValue].flashcard_viewed_on===null){
						tempArrayView[tempIndexValue].flashcard_viewed_on=true;
					}
					setViewedList(tempArrayView);
				}
			}
		}
		setDisableInALL();
		if(count===tempLength){
			setTimeout(()=>{
				setFlashCard(false); 
			},
			constants.SecondsPerSlide
			);
			setSeconds(1);
		}
		updateFlashcard(tempArrayView);
	}
	function onMarkedClick(item,markValue){
		setIsReload(!markValue);
		item.marked=!markValue;
		let tempArr=[];
		tempArr=viewedList;
		let tempIndex=tempArr.findIndex(el => el.flashcard_id ===item.flashcard_id);
		tempArr[tempIndex].is_marked=!markValue;
		// let checkValue=tempArr.includes(item.flashcard_id);
		// if(checkValue===false){
		// 	tempArr=[...tempArr,item.flashcard_id];
		// }else if(checkValue===true&&!markValue===false){
		// 	let tempIndex=tempArr.indexOf(item.flashcard_id);
		// 	tempArr.splice(tempIndex,1);
		// }
		setViewedList(tempArr);
		// setMarkedList([]);
		let tempQnA=[];
		tempQnA=flashCardQnAListData;
		let temQnAIndex=tempQnA.findIndex(el => el.flashcard_id ===item.flashcard_id);
		flashCardQnAListData[temQnAIndex].marked=!markValue;
		let flashMarkedTempArray=flashCardQnAListData.filter((itemMarked)=>itemMarked.marked===true);
		if(flashMarkedTempArray.length===0&&flashDisplay==='Marked'){
			setFlashDisplay('All');
		}
		setFlashCardMarked(flashMarkedTempArray);
		setFlashCardMarkedDisable(flashMarkedTempArray.length<1?true:false);
		updateFlashcard(tempArr);
	}
	function onTabChange(e){
		setTabKey(e);
		setSeconds(1);
		setFlashCard(false);
		sessionStorage.setItem("tabkey",e);
	}
	function onRadioGroupchange(e){
		setLoader(true);
		setReviewedCardCount(1);
		setShuffleDisable(e.target.value==='All'?false:true);
		setFlashDisplay(e.target.value);
		sessionStorage.setItem("startWithProp",e.target.value);
		let flashReviewed=flashCardQnAListData.filter((item)=>item.review===true);
		let flashNotReviewed=flashCardQnAListData.filter((item)=>item.review===false);
		let flashMarked=flashCardQnAListData.filter((item)=>item.marked===true);
		setFlashCardReviewDisable(flashReviewed.length<1?true:false);
		setFlashCardNotReviewDisable(flashNotReviewed.length<1?true:false);
		setFlashCardMarkedDisable(flashMarked.length<1?true:false);
		setflashCardReview(flashReviewed);
		setflashCardNotReview(flashNotReviewed);
		setFlashCardMarked(flashMarked);
		setIsRadiochanged(true);
		if(startWithProp===true){
			carouselRef.current.innerSlider.state.currentSlide=0;
			carouselRef.current.goTo(0, false);             
		}else{
			carouselRefQnA.current.innerSlider.state.currentSlide=0;
			carouselRefQnA.current.goTo(0, false);
		}
		setLoader(false);
	}
	function getListLength(checkValueList){
		return{
			'All':flashCardQnAListData.length,
			'Reviewed':flashCardReview.length,
			'Not Reviewed':flashCardNotReview.length,
			'Marked':flashCardMarked.length,
		}[checkValueList];
	}
	function returnReviewedMark(item) {
		return(
			<>
				{
					dateFirstCompleted&&reviewedList&&reviewedList.length===0?
						<span className="reviewflashcard">Reviewed</span>:
						reviewedList&&reviewedList.length>0&&item&&reviewedList.some(el => el.flashcard_id === item.flashcard_id )&&<span className="reviewflashcard">Reviewed </span>}
			</>
		);
	}
	function getMarkToolTip(item) {
		return item.marked===true?"Unmark":"Mark for later";
	}
	useEffect(() => {
		document.addEventListener('keypress', event => {
			if (event.code === 'Space') {
				isFlippedref.current=!isFlippedref.current;
				handleFlip();
			}
		});
		fetcFlashcardQnAListData();
		if(props&&props.history&&props.history.location&&props.history.location.state){
			sessionStorage.setItem("topicId",props.history.location.state.cardTopicObject.stud_flashcard_id);
			sessionStorage.setItem("categoryId",props.history.location.state.cardType);
			sessionStorage.setItem("startWithProp",props.history.location.state.isMarked===true?'Marked':props.history.location.state.isReviewed===true?'Reviewed':'All');
			sessionStorage.setItem("startWithQ",props.history.location.state.startWith);
			sessionStorage.setItem("flashCardObject",props.history.location.state.cardTopicObject);
			sessionStorage.setItem("tabkey",'1');
		}else{
			let categoryIdFromSession=sessionStorage.getItem("categoryId");
			let topicIdFromSession=sessionStorage.getItem("topicId");
			if(!topicIdFromSession&&!categoryIdFromSession){
				history.push("/flashcard-list");
			}
		}
	}, []);
	useEffect(()=>{
		if(playFlashCard===true){
			const interval =setInterval(()=>{
				setSeconds((second) => (second === constants.SecondsPerSlideNum ? 1 : second + 1));
			}, 1000);
			if(seconds===(constants.SecondsPerSlideNum/2)||seconds===(constants.SecondsPerSlideNum-2)){
				setisFlipped(true);
			}
			if(seconds===constants.SecondsPerSlideNum&& !nextDisable){
				if(startWithProp===true){
					handleNext();
				}else{
					handleNextQnA();
				}
			}
			return () => clearInterval(interval);
		}
	},[playFlashCard,seconds]);
	useEffect(()=>{
		//Refresh page
		document.addEventListener('copy',disableCopy);
		return () => {
			document.removeEventListener('copy',disableCopy);
		};
	},[listReload]);
	function disableCopy(e) {
		e.preventDefault();
	}
	return (
		<>
			<Spin spinning={false} size="large" indicator={<Loader/>}>
				<div className="py-4 flash-container">
					<Row>
						<Col span={12}>
							<h2 className="mb-0">Flashcards</h2>
						</Col>
						<Col span={12} className="text-right">
							<Button onClick={()=>{history.push(
								{
									pathname:"/flashcard-list",
									state:{ 
										flashcardsForValue:(categoryId===1||categoryId==="1"?true:false ),
										startwith: sessionStorage.getItem("startWithQ")
									}
								});}} type="link" danger icon={<CloseOutlined/>} size="large"/>
						</Col>
					</Row>
					<Divider className="my-2"/>
					<Text className="font-18 flashcard-title">{flashCardTittle}</Text>
					<Tabs activeKey={tabkeyValue} tabBarExtraContent={tabkeyValue==='1'?OperationsSlot:OperationsSlot2} onChange={(e)=>onTabChange(e)} size="small" className="flash-tabs">
						<TabPane tab={<Tooltip placement="top" title="Flashcards View"><CopyOutlined className="flash-ico icon-md"/></Tooltip>} key="1">
							{loader===true?null:
								<div className="flashcard-progress mr-5">
									<Progress percent={progressPercent} format={() => `${reviewedCardCount}/${flashDisplay==='All'?flashCardQnAListData.length:flashDisplay==='Reviewed'?flashCardReview.length:flashDisplay==='Not Reviewed'?flashCardNotReview.length:flashCardMarked.length}`} strokeColor="#00AAB5" strokeLinecap="square" className="flashcard-progress"/>
								</div>
							}
							{startWithProp?
							// Only Quetions or only answers
								<div>
									<Carousel dots={false} ref={carouselRef} effect={carouselEffect} lazyLoad="progressive" className="flashcards" beforeChange={onChangeCarouselref} initialSlide={initialCarousalSlide} >
										{getDisplayFlashList().map((item)=>{
											return(
												<div key={item.flashcard_id}>
													{startWithQ?
														<div>
															<div className="flip-bookmrk-container">
																<Tooltip placement="left" title={getMarkToolTip(item)}><Button className="flip-bookmark" type="text" icon={getMarkIcon(item.marked)} 
																	onClick={()=>onMarkedClick(item,item.marked)}
																></Button></Tooltip>
															</div>
															{isFlipped===false?
																<Card
																	title="Q"
																	onClick={handleClick}
																	size="small"
																	className="flashcard-items-center"
																>
																	<div
																		dangerouslySetInnerHTML={{ __html: item.question.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
																	/>
																	{returnReviewedMark(item)}
																</Card>
																:
																<Card
																	title="A"
																	onClick={handleClick}
																	size="small"
																	className="flashcard-items-center"
																>
																	<div
																		dangerouslySetInnerHTML={{ __html:item.answer.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
																	/>
																	{returnReviewedMark(item)}
																</Card> 
															}                                                                                       
														</div>
														:
														<div>
															<div className="flip-bookmrk-container">
																<Tooltip placement="left" title={getMarkToolTip(item)}><Button className="flip-bookmark" type="text" icon={getMarkIcon(item.marked)} 
																	onClick={()=>onMarkedClick(item,item.marked)}
																></Button></Tooltip>
															</div>
															{isFlipped===false?
																<Card
																	title="A"
																	onClick={handleClick}
																	size="small"
																	className="flashcard-items-center"
																>
																	<div
																		dangerouslySetInnerHTML={{ __html:item.answer.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
																	/>
																	{returnReviewedMark(item)}
																</Card>:
																<Card
																	title="Q"
																	onClick={handleClick}
																	size="small"
																	className="flashcard-items-center"
																>
																	<div
																		dangerouslySetInnerHTML={{ __html:item.question.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
																	/>
																	{returnReviewedMark(item)}
																</Card> }
														</div>
													}
												</div>
											);
										})}
									</Carousel>
									<div className="space-between">
										<Tooltip placement="top" title="Previous"><Button onClick={handlePrev} type="text" icon={<LeftCircleOutlined/>} size="large" disabled={prevDisable}/></Tooltip>
										<Tooltip placement="top" title="Next"><Button onClick={handleNext} type="text" icon={<RightCircleOutlined/>} size="large" disabled={nextDisable}/></Tooltip>
									</div>
									<div style={{ visibility:"hidden" }}>
										<b>Server time:</b> {serverTime?serverTime:""}{"           "}
										<b>Browser time:</b> {browserTime?browserTime:""}
									</div>
								</div>
								:
							//  Quetions and Answers section
								<div>
									<Carousel dots={false} effect={carouselEffect} ref={carouselRefQnA} lazyLoad="progressive"  className="flashcards flashcardsQnA" beforeChange={onChangeCarouselref} initialSlide={initialCarousalSlide} >
										{getDisplayFlashList().map((item)=>{
											return(
												<div key={item}>
													<div>
														<Card
															title="Q"
															extra={
																<Tooltip placement="left" title={getMarkToolTip(item)}>
																	<Button type="text" icon={getMarkIcon(isReload)} 
																		onClick={()=>onMarkedClick(item,item.marked)}
																	></Button>
																</Tooltip>
															}
															size="small"
															className="flashcard-items-center flashQnA-Q"
														>
															<div
																dangerouslySetInnerHTML={{ __html:item.question.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
															/>
															{returnReviewedMark(item)}
														</Card>
														<Card
															title="A"
															size="small"
															className="flashcard-items-center"
														>
															<div
																dangerouslySetInnerHTML={{ __html:item.answer.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
															/>
														</Card>
													</div>
												</div>
											);
										})}
									</Carousel>
									<div className="space-between">
										<Tooltip placement="top" title="Previous"><Button onClick={handlePrevQnA} type="text" icon={<LeftCircleOutlined/>} size="large" disabled={prevDisable}/></Tooltip>
										<Tooltip placement="top" title="Next"><Button onClick={handleNextQnA} type="text" icon={<RightCircleOutlined/>} size="large" disabled={nextDisable}/></Tooltip>
									</div>
									<div style={{ visibility:"hidden" }}>
										<b>Server time:</b> {serverTime?serverTime:""}{"           "}
										<b>Browser time:</b> {browserTime?browserTime:""}
									</div>
								</div>
							}
						</TabPane>
						<TabPane tab={<Tooltip placement="top" title="List View"><OrderedListOutlined className="icon-md"/></Tooltip>} key="2">
							{/* List view section */}
							<div>
								<p>Displaying {getListLength(flashDisplay)}/{flashCardQnAListData.length} Flashcards</p>
								<div className="flash-list flash-listQA">
									<List
										dataSource={getDisplayFlashList()}
										pagination={{
											pageSize: pageSizeValue,
											showSizeChanger:false,
										}}
										renderItem={item => (
											<List.Item >
												<Skeleton loading={false} active>
													<Card size="small" className="w-100"
														title={<div><div className="flash-qa"><span className="flash-Q">Q</span><span dangerouslySetInnerHTML={{ __html:item.question.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}/>
														</div>
														{
															dateFirstCompleted&&reviewedList&&reviewedList.length===0?
																<div className="text-center reviewcard">Reviewed</div>:
																reviewedList&&reviewedList.length>0&&item&&reviewedList.some(el => el.flashcard_id === item.flashcard_id )&&<div className="text-center reviewcard">Reviewed</div>}
														</div>}
														extra={<Button type="text" icon={item.marked===true?<Tooltip placement="left" title="Unmark"><BookmarkFill className="flag"/></Tooltip>:<Tooltip placement="left" title="Mark for later"><Bookmark/></Tooltip>} 
															onClick={()=>{onMarkedClick(item,item.marked);setListReload(!listReload);}}
														></Button>
														}
													>
														<div className="flash-qa">
															<span className="flash-A">A</span>
															<p className="flash-answer"
																dangerouslySetInnerHTML={{ __html:item.answer.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
															/>
														</div>                  
													</Card>
												</Skeleton>
											</List.Item>
										)}
									/>
									<br/>
								</div>
							</div>
						</TabPane>
					</Tabs>
				</div>
			</Spin>
		</>
	);
};
export default Flashcards;
