import React,{ useEffect,useState } from 'react';
import { Breadcrumb, Row, Col, Card, Table,BackTop, DatePicker, Form, Select, Space, Radio, } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
import { fetchAdminApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import moment from 'moment';
import { Chart } from "react-google-charts";
import { registrationFilter } from '../../../globalFunctions/GlobalFunctions';
const { Option } = Select;
const TotalFlashcardTaken =(props)=> {
	const history = useHistory();
	const [allTestData, setAllTestData] = useState({});
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [flashcardType,setFlashcardType]=useState(3);
	const [textBookGraphData, setTextBookGraphData] = useState([]);
	const [textBookData, setTextBookData] = useState([]);
	const [topicData, setTopicData] = useState([]);
	const [topicGraphData, setTopicGraphData] = useState([]);
	const [duration,setDuration]=useState("1");
	const [AllData, setAllData] = useState([]);
	const [AllGraphData, setAllGraphData] = useState([]);
	const [form] = Form.useForm();
	useEffect(() => {
		if(props&&props.location&&props.location.state&&props.location.state.type&&props.location.state.type==="dashboard"){
			setDateFilters(constants.startDateOfTheYear,constants.endDateOfTheYear);
			fetchTableData(constants.startDateOfTheYear,constants.endDateOfTheYear);
		}else{
			fetchTableData();
		}
	}, []);
	const setDateFilters=(startDate,endDate)=>{
		setStartDate(moment(startDate).format("MM/DD/YYYY"));
		setEndDate(moment(endDate).format("MM/DD/YYYY"));
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
		setDuration("0");
	};
	// const noDataAvailableText=()=>{
	// 	return(
	// 		<>							
	// 			<div className="nograph-div">
	// 				<span>No Data Available.</span>
	// 			</div>
	// 		</>
	// 	);
	// };
	const fetchTableData = (startDateValue,endDateValue) =>{
		var startDate=null;
		var endDate=null;
		if(startDateValue&&endDateValue){
			startDate=moment(startDateValue).format("YYYY-MM-DD");
			endDate=moment(endDateValue).format("YYYY-MM-DD");
		}else{
			var date = new Date(), y = date.getFullYear(), m = date.getMonth();
			var firstDay = new Date(y, m, 1);
			var lastDay = new Date(y, m + 1, 0);
			startDate=moment(firstDay).format("YYYY-MM-DD");
			endDate=moment(lastDay).format("YYYY-MM-DD");
			setStartDate(moment(startDate).format("MM/DD/YYYY"));
			setEndDate(moment(endDate).format("MM/DD/YYYY"));
			form.setFieldsValue({
				from:moment(startDate),
				to:moment(endDate)
			});
		}
		fetchAdminApi(`/total-flashcard-data?start_date=${startDate}&end_date=${endDate}`, "get").then(
			(res) => {
				if (res && res.data) {
					setAllTestData(res.data);
					// setCategoryData(res.data?.Category);
					// setTopicData(res.data?.Topic);
					if(res.data?.all?.length>0){
						setAllData(res.data?.all);
						let allChartData = [
							["Month/Year", "All"],
						];
						for (let index = 0; index < res.data.all.length; index++) {
							let all=[];
							all.push(res.data.all[index].month_in_3_words.toUpperCase(), parseInt(res.data.all[index]?.total));
							allChartData.push(all);
						}
						setAllGraphData(allChartData);
					}
					if(res.data?.Textbook?.length>0){
						setTextBookData(res.data?.Textbook);
						let TextbookChartData = [
							["Month/Year", "Textbook"],
						];
						for (let index = 0; index < res.data.Textbook.length; index++) {
							let textbook=[];
							textbook.push(res.data.Textbook[index]?.month_in_3_words.toUpperCase(), parseInt(res.data.Textbook[index]?.total));
							TextbookChartData.push(textbook);
						}
						setTextBookGraphData(TextbookChartData);
					}
					if(res.data?.Topic?.length>0){
						setTopicData(res.data?.Topic);
						let topicChartData = [
							["Month/Year", "Topic"],
						];
						for (let index = 0; index < res.data.Topic.length; index++) {
							let topic=[];
							topic.push(res.data.Topic[index]?.month_in_3_words.toUpperCase(), parseInt(res.data.Topic[index].total));
							topicChartData.push(topic);
						}
						setTopicGraphData(topicChartData);
					}
				}
			}
		);
	};
	const columnAll = [
		{
			title: "Month/Year",
			key: "MY",
			dataIndex: "month_in_words",
			sorter: (a, b) => a.month_in_words.localeCompare(b.month_in_words),
			render: (_text, record) => (
				<Space size="middle">
					<a onClick={() => { testScoreListing(record); }} style={{ color: "black" }}>{record.month_in_words} <span>{record.year}</span></a>
				</Space>
			),
		},
		{
			title: "All",
			key: "total",
			dataIndex: "total",
			sorter: (a, b) => a.tests - b.tests,
			render: (_text, record) => (
				<span ><a onClick={() => { testScoreListing(record); }} style={{ color: "black" }}><span >{record.total}</span></a></span>
			),
			width :200,
			align:"center"
		}
	];
	const columnCategory = [
		{
			title: "Month/Year",
			key: "MY",
			dataIndex: "month_in_words",
			sorter: (a, b) => a.month_in_words.localeCompare(b.month_in_words),
			render: (_text, record) => (
				<Space size="middle">
					<a onClick={() => { testScoreListing(record); }} style={{ color: "black" }}>{record.month_in_words} <span>{record.year}</span></a>
				</Space>
			),
		},
		{
			title: "Textbook",
			key: "total",
			dataIndex: "total",
			sorter: (a, b) => a.tests - b.tests,
			render: (_text, record) => (
				<span ><a onClick={() => { testScoreListing(record); }} style={{ color: "black" }}><span >{record.total}</span></a></span>
			),
			width :200,
			align:"center"
		}
	];
	const columnTopic = [
		{
			title: "Month/Year",
			key: "MY",
			dataIndex: "month_in_words",
			sorter: (a, b) => a.month_in_words.localeCompare(b.month_in_words),
			render: (_text, record) => (
				<Space size="middle">
					<a onClick={() => { testScoreListing(record); }} style={{ color: "black" }}>{record.month_in_words} <span>{record.year}</span></a>
				</Space>
			),
		},
		{
			title: "Topic",
			key: "total",
			dataIndex: "total",
			sorter: (a, b) => a.tests - b.tests,
			render: (_text, record) => (
				<span ><a onClick={() => { testScoreListing(record); }} ><span>{record.total}</span></a></span>
			),
			width :200,
			align:"center"
		}
	];
	const testScoreListing = (record) => {
		const month_in_words = record.month_in_words;
		const month = Number(record?.month) - 1; // June (0-indexed, so 5 represents June)
		const year = Number(record?.year);
		const rec_startDate = moment({
			year,
			month
		}).startOf('month');
		const rec_endDate = moment({
			year,
			month
		}).endOf('month');
		const data = {
			sd: rec_startDate.format('YYYY-MM-DD'),
			ed: rec_endDate.format('YYYY-MM-DD'),
			month:month_in_words,
			selectedYear:"",
			year:record.year,
			duration:duration,
			type:"flashcardTaken",
			flashcard_type:flashcardType
		};
		console.log(data);
		history.push({
			pathname: "/flashcard-details",
			state: data,
		});
	};
	const handleStartDate = (_date, dateString) => {
		setStartDate(dateString);
		fetchTableData(dateString,endDate);
	};
	const handleEndDate = (_date, dateString) => {
		setEndDate(dateString);
		fetchTableData(startDate,dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const onChangeDateFilter = (e) => {
		setDuration(e);
		registrationFilter(e,setStartDateEndDate);
	};
	const onChangeTestType = (e) => {
		setFlashcardType(e.target.value);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
		fetchTableData(startDate,endDate);
	};
	const graphOption = { chart:{ title: `Tests taken for the year ` } };
	const totalColumn = (type_total,height) =>{
		return (<Table.Summary.Row style = {{ 
			fontWeight:500 ,
			height:height 
		}}>
			<Table.Summary.Cell  index={0}><span>Total</span></Table.Summary.Cell>
			<Table.Summary.Cell index={1} align = "center"><span>{type_total}</span></Table.Summary.Cell>
		</Table.Summary.Row>);
	};
	const noDataAvailableText=()=>{
		return(
			<>							
				<div className="nograph-div">
					<span>No Data Available.</span>
				</div>
			</>
		);
	};
	const chartTag=(options,data)=>{
		return(
			<Chart
				chartType="Bar"
				width="100%"
				height="400px"
				data={data}
				options={options}
			/>);
	};
	return (
		<>
			<div className="div">
				<BackTop/>
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item>{constants.TotalFlashcardTaken}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" 
								title={<Row>
									<Col xs={24} xl={6} className="text-uppercase flex-vertical-middle">{constants.TotalFlashcardTaken}</Col>
									<Col xs={24} xl={18}>
										<Form layout="horizontal" form = {form} className="xl-float-right">
											<Space>
												<Form.Item className="label-default mb-0">
													<Radio.Group value={flashcardType} onChange={onChangeTestType} placeholder="Select test type" >
														<Radio value={3}>All</Radio>
														<Radio value={1}>Textbook</Radio>
														<Radio value={2}>Topic</Radio>
													</Radio.Group>
												</Form.Item>
												<Form.Item className="label-default mb-0" label="From:" name = "from">
													<DatePicker defaultValue={startDate} onChange={ handleStartDate} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
												</Form.Item>
												<Form.Item className="label-default mb-0" label="To:" name = "to">
													<DatePicker defaultValue={endDate} onChange={handleEndDate} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
												</Form.Item>
												<Form.Item className="label-default mb-0">
													<Select value={duration} style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
														<Option value="0">This Year</Option>
														<Option value="7">Last Year</Option>
														<Option value="1">This Month</Option>
														<Option value="2">Last Month</Option>
														<Option value="3">Last 3 Months</Option>
														<Option value="4">This Month (Last Year)</Option>
														<Option value="5">Last 3 Months (Last Year)</Option>
														<Option value="6">Previous Years</Option>
													</Select>
												</Form.Item>
											</Space>
										</Form>
									</Col>
								</Row>}
							>
								<Row>
									<Col xs={24} sm={24} md={8} lg={8}>
										<span style = {{ 
											fontWeight:500,
											fontSize:"medium"
										}}>{startDate&&endDate&&`Search Criteria: From: ${moment(startDate).format("MM/DD/YYYY")} To: ${moment(endDate).format("MM/DD/YYYY")}`}</span>
										<Table className="gap-table"
											bordered
											columns={
												flashcardType&&flashcardType===3?columnAll:
													flashcardType&&flashcardType===1?columnCategory:
														flashcardType&&flashcardType===2?columnTopic:[]
											}
											dataSource={
												flashcardType&&flashcardType===3?AllData&&AllData.length>0?AllData:[]:
													flashcardType&&flashcardType===1?textBookData&&textBookData.length>0?textBookData:[]
														:flashcardType&&flashcardType===2?topicData&&topicData.length>0?topicData:[]:[]}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													{
														flashcardType&&flashcardType===3?
															totalColumn(allTestData&&allTestData?.all_total):
															flashcardType&&flashcardType===1?totalColumn(allTestData&&allTestData?.textbook_total):
																flashcardType&&flashcardType===2?totalColumn(allTestData&&allTestData?.topic_total):
																	""}
												</Table.Summary>
											)}
										/>
									</Col>
									<Col xs={24} sm={24} md={16} lg={16}>
										{flashcardType&&flashcardType===1?
											textBookGraphData&&textBookGraphData.length>1?
												chartTag(graphOption,textBookGraphData)
												:noDataAvailableText()
											:flashcardType&&flashcardType===2?
												topicGraphData&&topicGraphData.length>1?
													chartTag(graphOption,topicGraphData)
													:noDataAvailableText():
												flashcardType&&flashcardType===3?
													AllGraphData&&AllGraphData.length>1?
														chartTag(graphOption,AllGraphData)
														:noDataAvailableText():
													""}
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default TotalFlashcardTaken;
