/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Row, Col, Card, Table, BackTop, DatePicker, Form, Select, Space, Radio, Button } from 'antd';
import { fetchAdminApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import moment from 'moment';
import { Chart } from "react-google-charts";
import { registrationFilter } from '../../../globalFunctions/GlobalFunctions';
const { Option } = Select;
const TutorialScoreGraph = (props) => {
	const history = useHistory();
	const [allTestData, setAllTestData] = useState({});
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [tutorialType, setTutorialType] = useState(4);
	const [categoryGraphData, setCategoryGraphData] = useState([]);
	const [topicGraphData, setTopicGraphData] = useState([]);
	const [customGraphData, setCustomGraphData] = useState([]);
	const [markForLaterGraphData, setMarkForLaterGraphData] = useState([]);
	const [incorrectAnswerGraphData, setincorrectAnswerGraphData] = useState([]);
	const [AllGraphData, setAllGraphData] = useState([]);
	const [duration, setDuration] = useState("1");
	const [allTestsTotal, setTestsTotal] = useState(0);
	const [form] = Form.useForm();
	useEffect(() => {
		if (props && props.location && props.location.state && props.location.state.type && props.location.state.type === "dashboard") {
			setDateFilters(constants.startDateOfTheYear, constants.endDateOfTheYear);
			fetchTableData(constants.startDateOfTheYear, constants.endDateOfTheYear);
		} else if (props && props.location && props.location.state && props.location.state.type && props.location.state.type === "tutorialTaken") {
			setDateFilters(props.location.state.sd, props.location.state.ed, props.location.state.duration);
			fetchTableData(props.location.state.sd, props.location.state.ed);
			// onChangeDateFilter(props&&props.location&&props.location.state&&props.location.state.duration);
			setTutorialType(props && props.location && props.location.state && props.location.state.tutorial_type);
		}
		else {
			fetchTableData();
		}
	}, []);
	const setDateFilters = (startDate, endDate, durationValue) => {
		setDuration(durationValue);
		setStartDate(moment(startDate).format("MM/DD/YYYY"));
		setEndDate(moment(endDate).format("MM/DD/YYYY"));
		form.setFieldsValue({
			from: moment(startDate),
			to: moment(endDate)
		});
		// setDuration("0");
	};
	const fetchTableData = (startDateValue, endDateValue) => {
		var startDate = null;
		var endDate = null;
		if (startDateValue && endDateValue) {
			startDate = moment(startDateValue).format("YYYY-MM-DD");
			endDate = moment(endDateValue).format("YYYY-MM-DD");
		} else {
			var date = new Date(), y = date.getFullYear(), m = date.getMonth();
			var firstDay = new Date(y, m, 1);
			var lastDay = new Date(y, m + 1, 0);
			startDate = moment(firstDay).format("YYYY-MM-DD");
			endDate = moment(lastDay).format("YYYY-MM-DD");
			setStartDate(moment(startDate).format("MM/DD/YYYY"));
			setEndDate(moment(endDate).format("MM/DD/YYYY"));
			form.setFieldsValue({
				from: moment(startDate),
				to: moment(endDate)
			});
		}
		fetchAdminApi(`/total-tutorials-taken-result?start_date=${startDate}&end_date=${endDate}`, "get").then(
			(res) => {
				if (res && res.data) {
					setAllTestData(res.data);
					let chartHeaderData = ["Tutorial", "No of Tutorials"];
					if (res.data.Category) {
						let categoryChartData = [];
						categoryChartData.push(chartHeaderData);
						for (let index = 0; index < res.data.Category.length; index++) {
							let category = [];
							category.push(res.data.Category[index].test_type, Number(res.data.Category[index].total));
							categoryChartData.push(category);
						}
						setCategoryGraphData(categoryChartData);
					} else {
						setCategoryGraphData([]);
					}
					if (res.data.all) {
						let allChartData = [];
						let totalTests = 0;
						allChartData.push(chartHeaderData);
						for (let index = 0; index < res.data.all.length; index++) {
							let all = [];
							totalTests = totalTests + Number(res.data.all[index].total);
							all.push(res.data.all[index].test_type, Number(res.data.all[index].total));
							allChartData.push(all);
						}
						setAllGraphData(allChartData);
						setTestsTotal(totalTests);
					} else {
						setAllGraphData([]);
					}
					if (res.data.Topic) {
						let topicChartData = [];
						topicChartData.push(chartHeaderData);
						for (let index = 0; index < res.data.Topic.length; index++) {
							let topic = [];
							topic.push(res.data.Topic[index].test_type, Number(res.data.Topic[index].total));
							topicChartData.push(topic);
						}
						setTopicGraphData(topicChartData);
					} else {
						setTopicGraphData([]);
					}
					if (res.data.Custom) {
						let customChartData = [];
						customChartData.push(chartHeaderData);
						for (let index = 0; index < res.data.Custom.length; index++) {
							let custom = [];
							custom.push(res.data.Custom[index].test_type, Number(res.data.Custom[index].total));
							customChartData.push(custom);
						}
						setCustomGraphData(customChartData);
					} else {
						setCustomGraphData([]);
					}
					if (res.data.Incorrect_answers) {
						let incorrectAnswerChartData = [];
						incorrectAnswerChartData.push(chartHeaderData);
						for (let index = 0; index < res.data.Incorrect_answers.length; index++) {
							let incorrectAnswer = [];
							incorrectAnswer.push(res.data.Incorrect_answers[index].test_type, Number(res.data.Incorrect_answers[index].total));
							incorrectAnswerChartData.push(incorrectAnswer);
						}
						setincorrectAnswerGraphData(incorrectAnswerChartData);
					} else {
						setincorrectAnswerGraphData([]);
					}
					if (res.data.Mark_for_later && res.data.Mark_for_later.length > 0) {
						let markForLaterChartData = [];
						markForLaterChartData.push(chartHeaderData);
						for (let index = 0; index < res.data.Mark_for_later.length; index++) {
							let markForLater = [];
							markForLater.push(res.data.Mark_for_later[index].test_type, Number(res.data.Mark_for_later[index].total));
							markForLaterChartData.push(markForLater);
						}
						setMarkForLaterGraphData(markForLaterChartData);
					} else {
						setMarkForLaterGraphData([]);
					}
				}
			}
		);
	};
	const optionsforGraph = {
		title: constants.TutorialScores,
		height: 400,
		isStacked: true,
		vAxis: {
			title: "No. of Tutorials",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		hAxis: {
			title: "Tutorial",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		seriesType: "bars",
	};
	const optionsforTopic = {
		title: constants.TutorialScores,
		height: 400,
		isStacked: true,
		vAxis: {
			title: "No. of Tutorials",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		hAxis: { textPosition: 'none' },
		seriesType: "bars",
	};
	const getFilterValue = (type) => {
		switch (type) {
		case "Category": return ['FPP', 'SAED', 'PIET'];
		case "Topic": return ['TOPIC'];
		case "Custom": return ['CUSTOM'];
		case "Mark for Later": return ['MARK_FOR_LATER'];
		case "Incorrect Answers": return ['INCORRECT_ANS'];
		}
	};
	const column3 = [
		{
			title: tutorialType && tutorialType === 1 ? "Category" : tutorialType && tutorialType === 2 ?
				"Topic" : tutorialType && tutorialType === 3 ? "Custom" : tutorialType && tutorialType === 6 ? "Incorrect Answers" : tutorialType && tutorialType === 5 ? "Mark For Later" :
					tutorialType && tutorialType === 4 ? "All" : "",
			key: "tutorialType",
			render: (_text, record) => {
				return (
					<span className="p-relative">
						{tutorialType && tutorialType !== 4 ?
							<Button type="link" onClick={() => {
								localStorage.setItem("propvalues", JSON.stringify({
									testType: tutorialType,
									testCategoryValue: record.test_type ? record.test_type : "",
									startDate: startDate,
									endDate: endDate
								}));
								window.open(constants.BaseUrl + "/tutorial-result", '_blank');
								// 	history.push({
								// 	pathname:"/tutorial-result",
								// 	state:{
								// 		testType:tutorialType,
								// 		testCategoryValue:record.test_type?record.test_type:"",
								// 		startDate:startDate,
								// 		endDate:endDate
								// 	}
								// });
							}}>{record.test_type ? record.test_type : ""}</Button>
							:
							<Button type="link" onClick={() => {
								localStorage.setItem("propvalues", JSON.stringify({
									testType: tutorialType,
									testCategoryValue: getFilterValue(record.test_type),
									startDate: startDate,
									endDate: endDate
								}));
								window.open(constants.BaseUrl + "/tutorial-result", '_blank');
								// history.push({
								// 	pathname:"/tutorial-result",
								// 	state:{
								// 		testType:tutorialType,
								// 		testCategoryValue:getFilterValue(record.test_type),
								// 		startDate:startDate,
								// 		endDate:endDate
								// 	}
								// });
							}}>{record.test_type ? record.test_type : ""}</Button>
						}
					</span>
				);
			},
			align: 'left',
			width: 100
		},
		{
			title: "No. of Tutorials",
			key: "total",
			dataIndex: "total",
			className: 'td-right',
			render: (_text, record) => {
				return <span className="p-relative">{record.total}</span>;
			},
			align: 'center',
			width: 100
		},
	];
	const handleStartDate = (_date, dateString) => {
		setStartDate(dateString);
		fetchTableData(dateString, endDate);
	};
	const handleEndDate = (_date, dateString) => {
		setEndDate(dateString);
		fetchTableData(startDate, dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const onChangeDateFilter = (e) => {
		setDuration(e);
		registrationFilter(e, setStartDateEndDate);
	};
	const onChangeTestType = (e) => {
		setTutorialType(e.target.value);
	};
	const setStartDateEndDate = (startDate, endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from: moment(startDate),
			to: moment(endDate)
		});
		fetchTableData(startDate, endDate);
	};
	const totalColumn = (type_total, height) => {
		return (<Table.Summary.Row style={{
			fontWeight: 500,
			height: height
		}}>
			<Table.Summary.Cell align="left" index={0}><span>Total</span></Table.Summary.Cell>
			<Table.Summary.Cell index={1} className='td-right'><span className="p-relative">{type_total}</span></Table.Summary.Cell>
		</Table.Summary.Row>);
	};
	const noDataAvailableText = () => {
		return (
			<>
				<div className="nograph-div">
					<span>No Data Available.</span>
				</div>
			</>
		);
	};
	const chartTag = (options, data) => {
		return (
			<Chart
				chartType="ComboChart"
				width="100%"
				data={data}
				options={options}
			/>);
	};
	return (
		<>
			<div className="div">
				<BackTop/>
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>{constants.TutorialScores}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" title={<Row>
								<Col xs={24} xl={24} className="text-uppercase flex-vertical-middle">{constants.TutorialScores}</Col>
								<Col xs={24} xl={24}>
									<Form layout="horizontal" form={form}>
										<Space>
											<Form.Item className="label-default mb-0">
												<Radio.Group value={tutorialType} onChange={onChangeTestType} placeholder="Select test type" >
													<Radio value={4}>All</Radio>
													<Radio value={1}>Category</Radio>
													<Radio value={2}>Topic</Radio>
													<Radio value={3}>Custom</Radio>
													<Radio value={6}>Incorrect Answers</Radio>
													<Radio value={5}>Mark for Later</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="From:" name="from">
												<DatePicker defaultValue={startDate} onChange={handleStartDate} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="To:" name="to">
												<DatePicker defaultValue={endDate} onChange={handleEndDate} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Select value={duration} style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
													<Option value="0">This Year</Option>
													<Option value="7">Last Year</Option>
													<Option value="1">This Month</Option>
													<Option value="2">Last Month</Option>
													<Option value="3">Last 3 Months</Option>
													<Option value="4">This Month (Last Year)</Option>
													<Option value="5">Last 3 Months (Last Year)</Option>
													<Option value="6">Previous Years</Option>
												</Select>
											</Form.Item>
										</Space>
									</Form>
								</Col>
							</Row>
							}
							>
								<Row>
									<Col xs={24} sm={24} md={8} lg={8}>
										<span style={{
											fontWeight: 500,
											fontSize: "medium"
										}}>{startDate && endDate && `Search Criteria: From: ${moment(startDate).format("MM/DD/YYYY")} To: ${moment(endDate).format("MM/DD/YYYY")}`}</span>
										<Table className="gap-table"
											bordered
											columns={column3}
											dataSource={tutorialType && tutorialType === 4 ? allTestData && allTestData.all && allTestData.all.length > 0 ? allTestData.all : [] :
												tutorialType && tutorialType === 1 ? allTestData && allTestData.Category && allTestData.Category.length > 0 ? allTestData.Category : [] :
													tutorialType && tutorialType === 2 ? allTestData && allTestData.Topic && allTestData.Topic.length > 0 ? allTestData.Topic : [] :
														tutorialType && tutorialType === 3 ? allTestData && allTestData.Custom && allTestData.Custom.length > 0 ? allTestData.Custom : [] :
															tutorialType && tutorialType === 6 ? allTestData && allTestData.Incorrect_answers && allTestData.Incorrect_answers.length > 0 ? allTestData.Incorrect_answers : [] :
																tutorialType && tutorialType === 5 ? allTestData && allTestData.Mark_for_later && allTestData.Mark_for_later.length > 0 ? allTestData.Mark_for_later : [] : []}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													{tutorialType && tutorialType === 4 ?
														totalColumn(allTestsTotal) :
														tutorialType && tutorialType === 1 ?
															totalColumn(allTestData && allTestData.category_total ? allTestData.category_total : 0) :
															tutorialType && tutorialType === 2 ? totalColumn(allTestData && allTestData.topic_total ? allTestData.topic_total : 0) :
																tutorialType && tutorialType === 3 ? totalColumn(allTestData && allTestData.custom_total ? allTestData.custom_total : 0) :
																	tutorialType && tutorialType === 6 ? totalColumn(allTestData && allTestData.incorrect_ans_total ? allTestData.incorrect_ans_total : 0) :
																		tutorialType && tutorialType === 5 ? totalColumn(allTestData && allTestData.mark_for_later_total ? allTestData.mark_for_later_total : 0) :
																			""}
												</Table.Summary>
											)}
										/>
									</Col>
									<Col xs={24} sm={24} md={16} lg={16}>
										{tutorialType && tutorialType === 1 ?
											categoryGraphData && categoryGraphData.length > 0 ?
												chartTag(optionsforGraph, categoryGraphData)
												: noDataAvailableText()
											:
											tutorialType && tutorialType === 2 ?
												topicGraphData && topicGraphData.length > 0 ?
													chartTag(optionsforTopic, topicGraphData)
													: noDataAvailableText()
												: tutorialType && tutorialType === 3 ?
													customGraphData && customGraphData.length > 0 ?
														chartTag(optionsforGraph, customGraphData)
														: noDataAvailableText() :
													tutorialType && tutorialType === 6 ?
														incorrectAnswerGraphData && incorrectAnswerGraphData.length > 0 ?
															chartTag(optionsforGraph, incorrectAnswerGraphData)
															: noDataAvailableText() :
														tutorialType && tutorialType === 5 ?
															markForLaterGraphData && markForLaterGraphData.length > 0 ?
																chartTag(optionsforGraph, markForLaterGraphData)
																: noDataAvailableText()
															:
															tutorialType && tutorialType === 4 ?
																AllGraphData && AllGraphData.length > 0 ?
																	chartTag(optionsforGraph, AllGraphData)
																	: noDataAvailableText()
																:
																""}
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default TutorialScoreGraph;
