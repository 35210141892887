import React, { useState, useEffect } from "react";
import {
	Button,
	Space,
	Card,
	Typography,
	Row,
	Col,
	BackTop,
	Collapse,
	AutoComplete
} from "antd";
import {
	ArrowLeftOutlined,
	ArrowRightOutlined,
	FormOutlined,
	LeftOutlined,
	RightOutlined,
	DragOutlined,
	HighlightOutlined,
	StrikethroughOutlined,
	FlagOutlined,
	EyeOutlined,
	SearchOutlined,
	HourglassOutlined,
	FileSearchOutlined,
	RedoOutlined,
	PlayCircleOutlined,
	ProfileOutlined
} from "@ant-design/icons";
import CustomIcons from "../../../assets/CustomIcons";
import Icon from '@ant-design/icons';
import "../../../styles/notes.css";
import constants from "../../../constants/constants";
import { fetchApi } from "../../../services/api";
import { useHistory } from "react-router";
import { COOKIE,getCookie } from "../../../services/cookie";
const { Panel } = Collapse;
const { Text } = Typography;
const Comment = (props) => (<Icon component={CustomIcons.getsvg("Comment")} {...props}/>);
const Instructions = () => {
	const [count, setCount] = useState(0);
	const [topicTests, setTopicTests] = useState([]);
	const [testCount,]=useState(sessionStorage.getItem("totalTestsTaken"));
	useEffect(() => {
		fetchTopicTests();
	}, []);
	const history = useHistory();
	//To fetch the topics of the Topic test
	const fetchTopicTests = () => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/topics?stud_id=${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				setTopicTests(res.data);
			}else{
				setTopicTests([]);
			}
		});
	};
	const firstPage = (
		<div>
			<Col span={24}>
				<Card title={constants.AboutTheTest} size="small">
					<Text>
						{/* {constants.AboutTheTestInstructions} */}
						<p>{constants.InstructionsSubText}</p>
						<ul>
							<li>{constants.CategoryFPP}</li>
							<li>{constants.CategorySAED}</li>
							<li>{constants.CategoryPIET}</li>
						</ul>
					</Text>
				</Card>
			</Col>
			<Col span={24}>
				<Card title={constants.TopicsAndQuestions} size="small">
					<Text>					
					</Text>
					<Text>
						<Collapse accordion>
							<Panel header={constants.TnQInstructions} key="1">
								<Text>
									{topicTests &&
										topicTests.length > 0 &&
										topicTests.map((topic) => (
											<ul key={topic}>
												<li>{topic.topic_title}</li>
											</ul>
										))}
								</Text>
							</Panel>
						</Collapse>
					</Text>
				</Card>
			</Col>
			<Col span={24}>
				<Card title={constants.SelectingATest} size="small">
					<Text>
						{/* {constants.AboutTheTestInstructions} */}
						<p>The SLP Praxis Review tests has three types of tests:</p>
						<ul>
							<li>{constants.SelectCategoryTestInstructions}</li>
							<li>{constants.SelectTopicTestInstructions}</li>
							<li>{constants.SelectFLTestInstructions}</li>
						</ul>
						<p>{constants.SelectTestInstructions2}</p>
						<p>{constants.SelectTestInstructions3}</p>
					</Text>
				</Card>
			</Col>
			<Col span={24}>
				<Card title={constants.AnsweringATest} size="small">
					<Text>
						{constants.AnsweringATestInstructions}
					</Text>
				</Card>
			</Col>
		</div>
	);
	const secondPage = (
		<Col span={24}>
			<Card title="During the test, use the following features. Click each feature to learn more." size="small">
				<Text>
					<Collapse	accordion>
						<Panel header={<>
							<span className="inst-label">Select Un-timed / Timed test</span>
							<span><HourglassOutlined className="icon-md pl-2"/>
							</span>
						</>} key="1">
							<span>
								<span className="inst-time-block">
									<Button
										block
									>
										<HourglassOutlined className="icon-md"/>
										<br/>
										{constants.Untimed}
										<br/>
										<span>{constants.UntimedSubTitle}</span>
									</Button>
								</span>
								<span className="inst-time-block">
									<Button 
										block
									>
										<HourglassOutlined className="icon-md hourglass pl-2"/>
										<br/>
										{constants.Timed}
										<br/>
										<span>30 minutes</span>
									</Button>
								</span>
							</span>
							<p>{constants.SelectTimeTest}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Question Index</span><span><ProfileOutlined className="icon-md"/></span></>} key="2">
							<p>{constants.QuestionIndexInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Timer</span><span><Button className="timerIcon" icon={ <EyeOutlined className="icon-md"/> }> </Button>0:00:00</span></>} key="3">
							<p>{constants.TimerInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Drag and Drop</span><span><DragOutlined className="icon-md"/></span></>} key="14">
							<p>{constants.DragInst}</p>
						</Panel>
						<Panel header={<>
							<span className="inst-label">Search</span>
							<span>
								<AutoComplete
									style={{ width: 170 }}
									placeholder="Search"
								>
								</AutoComplete>
								<Button><SearchOutlined/></Button>
							</span>
						</>} key="4">
							<p>{constants.SearchInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Resume Test</span><span><Button className="btnDb min-width-100">Resume Test</Button></span></>} key="5">
							<p>{constants.ResumeTestInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Strike-through</span><span><StrikethroughOutlined className="strike icon-md"/></span></>} key="6">
							<p>{constants.StrikeThroughInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Highlight</span><span><HighlightOutlined className="highlight icon-md"/></span></>} key="7">
							<p>{constants.HightlightInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Mark for later</span><span><FlagOutlined className="flag icon-md"/></span></>} key="8">
							<p>{constants.MarkForLaterInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Notes</span><span><Comment className="icon-md"/></span></>} key="9">
							<p>{constants.NotesInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Navigate</span><span className="d-inlineblock"><Button className="btnDb min-width-100"><LeftOutlined/>{constants.Previous}</Button><Button className="btnDb min-width-100 ml-2" >{constants.Next}<RightOutlined/></Button></span></>} key="10">
							<p>{constants.NavigateInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Pause Test</span><span><Button className="btnDb min-width-100">Pause Test</Button></span></>} key="11">
							<p>{constants.PauseTestInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Complete Test</span><span><Button className="btn-submit min-width-100">{constants.SubmitTest}</Button></span></>} key="12">
							<p>{constants.CompleteTestInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Review Test</span><span><Button className="btnDb min-width-100">Check</Button></span></>} key="13">
							<p>{constants.ReviewTestInst}</p>
						</Panel>
					</Collapse>
				</Text>
			</Card>
		</Col>
	);
	const thirdPage = (
		<div>
			<Col span={24}>
				<Card title="After Completing a Test"size="small">
					<Collapse	accordion>
						<Panel header="Test Results" key="1">
							<p>{constants.TestResultInst}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Resume Test</span><span><PlayCircleOutlined className="icon-md"/></span></>} key="2">
							<p>{constants.ResumeTestAfter}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Review Test</span><span><FileSearchOutlined className="icon-md"/></span></>} key="3">
							<p>{constants.ReviewTestAfter}</p>
						</Panel>
						<Panel header={<><span className="inst-label">Retake Test</span><span><RedoOutlined className="icon-md"/></span></>} key="4">
							<p>{constants.RestakeTestAfter}</p>
						</Panel>
					</Collapse>
				</Card>
			</Col>
			<Col span={24}>
				<Card title="IMPORTANT" size="small">
					<Text>
						<p>{constants.ImportantInst}</p>
						<p>{constants.ImportantInst2}</p>
						<p>{constants.ImportantInst3}</p>
					</Text>
				</Card>
			</Col>
		</div>
	);
	return (
		<div className="container instructions py-4">
			<BackTop/>
			<Row
				gutter={[
					{
						xs: 0,
						sm: 0,
						md: 8,
						lg: 16 
					},
					{
						xs: 8,
						sm: 8,
						md: 8,
						lg: 16 
					},
				]}
			>
				<Col span={24}>
					<div className="text-center position-relative">
						<h1 className="text-uppercase mb-0">{constants.TakeaTestText}</h1>
						<h1 className="text-left mb-0 inst-side-header">{constants.Instructions}</h1>
						<span className="inst-btn-wrapper">
							<Space>
								{count > 0 && (
									<Button
										type="primary"
										className="navy-btn"
										icon={<ArrowLeftOutlined/>}
										onClick={() => {
											setCount(count - 1);
										}}
									>
										{constants.Back}
									</Button>
								)}
								{count < 2 && (
									<Button
										type="primary"
										className="navy-btn"
										onClick={() => {
											setCount(count + 1);
										}}
									>
										{constants.Next} <ArrowRightOutlined/>
									</Button>
								)}
								{(count === 2||parseInt(testCount)>=constants.minimumTestsSelectbtn) && (<Button
									type="primary"
									className="navy-btn-select-test"
									icon={<FormOutlined/>}
									onClick={() => {
										history.push("/choose-a-test");
									}}
								>
									{constants.SelectTestBTN}
								</Button>	
								)}
							</Space>
						</span>
					</div>
				</Col>
				{count === 0
					? firstPage
					: count === 1
						? secondPage
						: count === 2
							? thirdPage
							: ""}
				<Col span={24} className="text-right">
					<span >
						<Space>
							{count > 0 && (
								<Button
									type="primary"
									className="navy-btn"
									icon={<ArrowLeftOutlined/>}
									onClick={() => {
										setCount(count - 1);
									}}
								>
									{constants.Back}
								</Button>
							)}
							{count < 2 && (
								<Button
									type="primary"
									className="navy-btn"
									onClick={() => {
										setCount(count + 1);
									}}
								>
									{constants.Next} <ArrowRightOutlined/>
								</Button>
							)}
							{(count === 2||parseInt(testCount)>=constants.minimumTestsSelectbtn)  && (<Button
								type="primary"
								className="navy-btn-select-test"
								icon={<FormOutlined/>}
								onClick={() => {
									history.push("/choose-a-test");
								}}
							>
								{constants.SelectTestBTN}
							</Button>	
							)}
						</Space>
					</span>
				</Col>
			</Row>
		</div>
	);
};
export default Instructions;
