import React,{ useEffect,useState } from 'react';
import { Breadcrumb, Row, Col, Card, Table,BackTop } from 'antd';
import { fetchAdminApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
const TestScore =(props)=> {
	const history = useHistory();
	const [categoryData, setCategoryData] = useState([]);
	const [topicData, setTopicData] = useState([]);
	const [fullLengthData, setFullLengthData] = useState([]);
	const [total, setTotal] = useState([]);
	const [testTypeTotal, setTestTypeTotal] = useState(null);
	const [passTotal, setPassTotal] = useState(null);
	const [failTotal, setFailTotal] = useState(null);
	const [payload] = useState(props && props.location && props.location.state?props && props.location && props.location.state:JSON.parse(sessionStorage.getItem("testscore")));
	const commonColumn = [
		{
			title: "No. of Tests",
			key: "total",
			dataIndex: "total",
			className: 'td-right',
			render: (_text, record) => {
				return <span className="p-relative">{record.total}</span>;
			},
			align: 'center',
			width:100
		},
		{
			title: "Pass",
			key: "Pass",
			dataIndex: "pass",
			className: 'td-right',
			render: (_text, record) => {
				return <span className="p-relative">{record.pass}</span>;
			},
			align: 'center',
			width:100
		},
		{
			title: "Did not Pass",
			key: "Did not Pass",
			dataIndex: "fail",
			className: 'td-right',
			render: (_text, record) => {
				return <span className="p-relative">{record.fail}</span>;
			},
			align: 'center',
			width:100
		}
	];
	const column = [
		{
			title: "Category",
			key: "testtype",	
			render: (record) => {
				return (
					<span >{record.test_type}</span>
				);
			},
			align: 'center',
			width:100
		},
		...commonColumn
	];
	const column2 = [
		{
			title: "Topic",
			key: "testtype",
			dataIndex: "test_type",
			align: 'left',
			width:100
		},
		...commonColumn
	];
	const column3 = [
		{
			title: "Full Length",
			key: "testtype",
			dataIndex: "test_type",
			align: 'center',
			width:100
		},
		...commonColumn
	];
	const fetchTableData = () =>{
		fetchAdminApi(`/total-tests-taken-result?start_date=${payload?.sd}&end_date=${payload?.ed}`, "get").then(
			(res) => {
				if (res && res.data) {
					setTotal(res.data);
					setTestTypeTotal(parseInt(res.data?.category_total) + parseInt(res.data?.topic_total) + parseInt(res.data?.fulllength_total));
					setFailTotal(parseInt(res.data?.category_fail) + parseInt(res.data?.topic_fail) + parseInt(res.data?.fulllength_fail));
					setPassTotal(parseInt(res.data?.category_pass) + parseInt(res.data?.topic_pass) + parseInt(res.data?.fulllength_pass));
					setCategoryData(res.data?.Category);
					setTopicData(res.data?.Topic);
					setFullLengthData(res.data?.Full_length);
				} else {
					setTotal([]);
					setCategoryData([]);
					setTopicData([]);
					setFullLengthData([]);
				}
			}
		);
	};
	const totalColumn = (type_total,pass,fail,height) =>{
		return (<Table.Summary.Row style = {{ 
			fontWeight:500 ,
			height:height 
		}}>
			<Table.Summary.Cell align = "center" index={0}><span>Total</span></Table.Summary.Cell>
			<Table.Summary.Cell index={1} className='td-right'><span className="p-relative">{type_total}</span></Table.Summary.Cell>
			<Table.Summary.Cell index={2} className = "td-right"><span className="p-relative">{pass}</span></Table.Summary.Cell>
			<Table.Summary.Cell index={3} className = "td-right"><span className="p-relative">{fail}</span></Table.Summary.Cell>
		</Table.Summary.Row>);
	};
	useEffect(() => {
		fetchTableData();
		if(props && props.location && props.location.state){
			sessionStorage.setItem("testscore", JSON.stringify(props.history.location.state));
		}
	}, []);
	return (
		<>
			<div className="div">
				<BackTop/>
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						{/* {payload?.total_test_taken &&(
							<Breadcrumb.Item><a onClick={() => {
								history.push({ 
									pathname: "/total-test-taken", 
									state : payload?.selectedYear 
								});
							}}>{constants.TotalTestTaken}</a></Breadcrumb.Item>
						)} */}
						<Breadcrumb.Item><a onClick={()=>
						{
							history.push({
								pathname: "/test-scores",
								state: payload,
							});
						}}>{constants.TestScores}</a></Breadcrumb.Item>
						<Breadcrumb.Item>{constants.TestScores} Details</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card className = "card" size="small" title={<span style = {{ fontSize:"large" }} className="text-uppercase">Tests Score</span>}>
								<Row gutter={[24, 24]} className='my-test mt-4'>
									<Col xs={24} sm={24} md={24} lg={24}>
										<span style = {{ 
											fontWeight:500,
											fontSize:"medium"
										}}>{`Month/Year : ${payload?.month}  ${payload?.year}`}</span>
										<br/>
										<Table className="admin-table"
											bordered
											columns={column}
											dataSource={categoryData}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													{totalColumn(total?.category_total,total?.category_pass,total?.category_fail)}
												</Table.Summary>
											)}
										/>
										<br/>
										<Table className="admin-table admin-table-width th-center"
											bordered
											columns={column2}
											dataSource={topicData}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													{totalColumn(total?.topic_total,total?.topic_pass,total?.topic_fail)}
												</Table.Summary>
											)}
										/>
										<br/>
										<Table className="admin-table admin-table-width"
											bordered
											columns={column3}
											dataSource={fullLengthData}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													{totalColumn(total?.fulllength_total,total?.fulllength_pass,total?.fulllength_fail)}
													{totalColumn(testTypeTotal,passTotal,failTotal,"50px")}
												</Table.Summary>
											)}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default TestScore;