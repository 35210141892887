import React, { useState, useEffect } from "react";
import {
	Input,
	Form,
	Checkbox,
	Button,
	Space,
	Select,
	Card,
	message,
	Col,
	Row,
	Radio,
	Tooltip,
	Typography,
} from "antd";
import "../../../styles/login.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import constants from "../../../constants/constants";
import { fetchApi } from "../../../services/api";
import { COOKIE, getCookie, createCookie } from "./../../../services/cookie";
import { removeExtraSpaces,displayOtherUniversityAlaska } from "../../../globalFunctions/GlobalFunctions";
import { useHistory } from "react-router";
import Avatar from "antd/lib/avatar/avatar";
const { Option, OptGroup } = Select;
const { Text } = Typography;
const MyProfile = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [listCountries, setCountries] = useState([]);
	const [listUniversities, setUniversities] = useState([]);
	const [displayUniversity, setDisplayUniversity] = useState(true);//TODO 3: Set to true when TODO1 and TODO2
	const [checkReminderEmail, setCheckReminderEmail] = useState(true);
	const [checkReminderEmailDuration, setCheckReminderEmailDuration] = useState(2);
	const [checkEmailTestResult, setCheckEmailTestResult] = useState(true);
	const [displayOccupation, setDisplayOccupation] = useState(false);
	const [displayOtherUniversity,setDisplayOtherUniversity]=useState(false);
	const [regionCode,setRegionCode]=useState("");
	const [name, setName] = useState(null);
	const [styles,setStyles]=useState(null);
	const [passwordStatus,setPasswordStatus]=useState("");
	const [passwordRequired,setPasswordRequired]=useState(false);
	const [allowDisplay,setAllowDisplay]=useState(false);
	const [myProfileData,setMyProfileData]=useState(null);
	useEffect(() => {
		fetchUrlParameters();
	}, []);
	const fetchUrlParameters=()=>{
		let queryString = window.location.href;
		var token = queryString.substring(queryString.lastIndexOf('?') + 1);
		let decodedString = null;
		try{
			decodedString=atob(token);
		}catch{
			decodedString=null;
		}
		if(decodedString){
			var decodedJson=JSON.parse(decodedString);
			sessionStorage.setItem("idFromMyProfile",decodedJson.user_id);
			let userId=getCookie(COOKIE.UserId);
			let userToken =getCookie(COOKIE.Token);
			if(decodedJson&&decodedJson.user_id){
				if((userId===null||userId===undefined||userId==="")&&(userToken===null||userToken===undefined||userToken==="")){
					history.push("/");
				}else{
					fetchUniversities();
					fetchCountries();
				}
			}else{
				fetchUniversities();
				fetchCountries();
			}
		}else{
			fetchUniversities();
			fetchCountries();
		}
	}; 
	//To fetch the details of the user logged in
	const fetchUserDetails = () => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/student/${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				if (res.data) {
					setMyProfileData(res.data);
					form.setFieldsValue({
						firstName: res.data.stud_firstname?res.data.stud_firstname:"",
						lastName: res.data.stud_lastname?res.data.stud_lastname:"",
						contactNo: res.data.stud_contact_no?res.data.stud_contact_no:"",
						userName: res.data.login_username?res.data.login_username:"",
						bookCode: res.data.promo_code?res.data.promo_code:"",
						email: res.data.stud_email?res.data.stud_email:"",
						alternateEmail: res.data.alternate_stud_email?res.data.alternate_stud_email:"",
						role: res.data.occupation_type&&Number(res.data.occupation_type)<4? Number(res.data.occupation_type):null,
						occupation:res.data.other_occupation,
						country: res.data.country_id?res.data.country_id:"",
						// university: res.data.university_id && res.data.university_id,
					});
					if(res.data.university_id){
						if(res.data.region_code){
							form.setFieldsValue({ university:res.data.university_id });
							setRegionCode(res.data.region_id);
						}
					}else{
						if(res.data.region_code){
							setRegionCode(res.data.region_id);
							setDisplayOtherUniversity(true);
							if(res.data.other_university){
								form.setFieldsValue({ university:"Other_"+res.data.region_name });
								form.setFieldsValue({ otherUniversity:res.data.other_university });
							}else{
								form.setFieldsValue({ university:[] });
								form.setFieldsValue({ otherUniversity:[] });
								setDisplayOtherUniversity(false);
							}
						}else{
							if(res.data.other_university&&res.data.other_university==="NA"){
								setRegionCode("");
								form.setFieldsValue({ university:"NA" });
							}else if(res.data.other_university){
								setRegionCode("");
								setDisplayOtherUniversity(true);
								form.setFieldsValue({ university:"Other" });
								form.setFieldsValue({ otherUniversity:res.data.other_university });
							}
						}
					}
					if (res.data.email_results && res.data.email_results === "1") {
						setCheckEmailTestResult(true);
					} else {
						setCheckEmailTestResult(false);
					}
					if (res.data.email_frequency && res.data.email_frequency === "1") {
						setCheckReminderEmail(true);
						setCheckReminderEmailDuration(1);
					} else if (res.data.email_frequency && res.data.email_frequency === "2") {
						setCheckReminderEmail(true);
						setCheckReminderEmailDuration(2);
					} else if (res.data.email_frequency && res.data.email_frequency === "4") {
						setCheckReminderEmail(true);
						setCheckReminderEmailDuration(4);
					} else {
						setCheckReminderEmail(false);
						setCheckReminderEmailDuration(0);
					}
					setAllowDisplay(res.data.allow_display&&res.data.allow_display==="1"?true:false);
					if (res.data.country_code === "US") {
						setDisplayUniversity(true);//TODO 4:Set it to true to enable university
					} else {
						setDisplayUniversity(false);
						setDisplayOtherUniversity(false);
					}
					if (
						res.data.occupation_type !== null ||
						res.data.occupation_type !== undefined
					) {
						if (res.data.occupation_type === "3") {
							setDisplayOccupation(true);
						} else {
							setDisplayOccupation(false);
						}
					} else {
						setDisplayOccupation(false);
					}
					let fullName = {};
					if (res.data.stud_firstname) {
						fullName.firstName = res.data.stud_firstname;
					}
					if (res.data.stud_lastname) {
						fullName.lastName = res.data.stud_lastname;
					}
					setName(fullName);
				}
			}
		});
	};
	//To fetch the list of countries
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setCountries(res.data);
				for (let i = 0; i < res.data.length; i++) {
					if (res.data[i].country_code === "US") {
						setDisplayUniversity(true);//TODO 5:set it to true to enable university field
						form.setFieldsValue({ country: res.data[i].country_id });
					}
				}
			} else {
				setCountries([]);
			}
		});
	};
	//To fetch the list of Universities
	const fetchUniversities = () => {
		fetchApi(`/universities?country_code=US`, "get").then(
			(res) => {
				if (res && res.data) {
					Object.entries(res.data).forEach(([key, value]) => {
						displayOtherUniversityAlaska(key,value);
					});
					setUniversities(res.data);
					fetchUserDetails();
				} else {
					setUniversities(null);
				}
			}
		);
	};
	//Executes on change of Country field
	const onChangeCountry = (value, option) => {
		if (option && option.key !== "US") {
			setDisplayUniversity(false);
			setDisplayOtherUniversity(false);
			form.setFieldsValue({ university: null });
			form.setFieldsValue({ otherUniversity:null });
		} else {
			setDisplayUniversity(true);//TODO 6:set it to true to enable univerdity field
		}
	};
	//To update the details of the user from the myprofile form to the API.
	const onFinish = (values) => {
		let userId = getCookie(COOKIE.UserId);
		setLoading(true);
		let payload = null;
		payload = {
			stud_firstname: removeExtraSpaces(values.firstName),
			stud_lastname: removeExtraSpaces(values.lastName),
			stud_contact_no: values.contactNo,
			login_username: values.userName,
			stud_email: values.email,
			alternate_stud_email: values.alternateEmail,
			country_id: values.country,
			allow_display:allowDisplay===true?"1":"0",
		};
		if (values.password) {
			payload.login_password = values.password;
		}
		payload.occupation_type = values.role;
		payload.other_occupation=values.occupation?values.occupation.trim():null;
		//TODO 1: uncomment to get university field
		if (values.university) {
			if(isNaN(values.university)){
				if(values.university.includes("Other_")){
					payload.university_id = null;
					payload.state_id=regionCode;
					payload.other_university=values.otherUniversity?values.otherUniversity.trim():null;
				}else if(values.university.includes("NA")){
					payload.university_id = null;
					payload.state_id=null;
					payload.other_university="NA";
				}else if(values.university.includes("Other")){
					payload.university_id = null;
					payload.state_id=null;
					payload.other_university=values.otherUniversity?values.otherUniversity.trim():"";
				}
			}else{
				payload.university_id=values.university;
				payload.state_id=regionCode;
				payload.other_university=null;
			}
		}
		//TODO 1 End
		//TODO 2: remove when university field is enabled
		// payload.university_id=null;
		// payload.state_id=null;
		// payload.other_university=values.country==="238"?"NA":null;
		//TODO 2 End
		if (checkEmailTestResult === true) {
			payload.email_results = 1;
		} else {
			payload.email_results = 0;
		}
		if(checkReminderEmail){
			if (checkReminderEmailDuration) {
				payload.email_frequency = checkReminderEmailDuration;
			}
		}else{
			payload.email_frequency=0;
		}
		fetchApi(`/student/${userId}`, "put", payload).then((res) => {
			setLoading(false);
			if (res && res.code && res.code === 200) {
				createCookie(COOKIE.FirstName, values.firstName, null, "/");
				createCookie(COOKIE.LastName, values.lastName, null, "/");
				message.success(constants.ProfileUpdateSUC);
				// window.location.reload();
			} else {
				message.error(res.message);
			}
		});
	};
	//Exectus on check of Remind email notification check box
	const onChangeRemindEmail = (e) => {
		if (e.target.checked === true) {
			setCheckReminderEmail(true);
			setCheckReminderEmailDuration(2);
		} else {
			setCheckReminderEmail(false);
			setCheckReminderEmailDuration(0);
		}
	};
	//Executes on change of the duration i.e weekly, fortnightly or monthly
	const onChangeEmailDuration = (e) => {
		if (e.target.value === 1 || e.target.value === 2 || e.target.value === 4) {
			setCheckReminderEmail(true);
			setCheckReminderEmailDuration(e.target.value);
		}
	};
	//Executes on change of the Email test result field
	const onChangeEmailTestResult = (e) => {
		setCheckEmailTestResult(e.target.checked);
	};
	//Executes onChange of allow display name
	const onAllowDisplayChange=(e)=>{
		setAllowDisplay(e.target.checked);
	};
	//Executes on change of the Role field
	const onChangeRole = (value) => {
		if (value === 3) {
			setDisplayOccupation(true);
		} else {
			form.setFieldsValue({ occupation:null });
			setDisplayOccupation(false);
		}
	};
	//Function to set region id and to display other university field if other or NA is selected
	const onChangeUniversity=(value,option)=>{
		setRegionCode(option.props.region);
		if(option.props.children==="Other"){
			setDisplayOtherUniversity(true);
		}else{
			setDisplayOtherUniversity(false);
			form.setFieldsValue({ otherUniversity:null });
		}
	};
	// Validates Occupation to check if it is same as role or if it is empty
	const handleValidateOccupation = (rule, value, callback) => {
		try {
			if(value){
				let occupationValidate=/^[a-zA-Z\s]*$/;
				let check=occupationValidate.test(value);
				if(value.trim().toLowerCase()==="student"||value.trim().toLowerCase()==="professional"||value.trim().toLowerCase()==="faculty"||value.trim().toLowerCase()==="other"
				||value.trim().toLowerCase()==="students"||value.trim().toLowerCase()==="professionals"||value.trim().toLowerCase()==="facultys"||value.trim().toLowerCase()==="others"){
					throw new Error(constants.OccupationRole);
				}
				if(value.trim()===""||check===false){
					throw new Error(constants.OccupationRegistration);
				}
			}else{
				throw new Error(constants.OccupationRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	// Validates university name to check if it is other
	const handleValidateUniversityName = (rule, value, callback) => {
		try {
			if(value){
				if(value.trim().toLowerCase()==="other"||value.trim().toLowerCase()==="others"||value.trim()===""){
					throw new Error(constants.OtherUniversityRegistration);
				}
				checkUniversityExists(value);
			}else{
				throw new Error(constants.OtherUniversityRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
		//Check if university exists
	const checkUniversityExists=(e)=>{
		let universityTempName=e.trim();
		let isErrorExists=false;
		if(listUniversities){
			Object.entries(listUniversities).map(([key, value]) =>{ 
				if(key){
					if(value &&value.universities &&value.universities.length > 0){
						value.universities.map((universe) => {
							let cityname=universe.city?","+universe.city:"";
							let universityCity=universe.university_name+cityname;
							if(universityTempName.replace(/\s+/g, '').toLowerCase()===universe.university_name.replace(/\s+/g, '').toLowerCase()||universityTempName.replace(/\s+/g, '').toLowerCase()===universityCity.replace(/\s+/g, '').toLowerCase()){
								isErrorExists=true;
								return;
							}
						});
					}
				}
			});
		}
		if(isErrorExists===true){
			throw new Error(constants.OtheeUniversityExists);
		}
	};
	//Function to check validation errors on click of register button
	const onFinishFailed=(values)=>{
		if((values&&values.values&&values.values.contactNo===null)||
		(values&&values.values&&values.values.contactNo===undefined)||
		(values&&values.values&&values.values.contactNo==="")){
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		}
	};
	//Function to check the contact number and set validation error using css
	const handleContactNo=(value)=>{
		if(value===""||value===null||value===undefined){
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		}else{
			setStyles(null);
		}
	};
		//Function to validate password to check if it has 1 special character, 1 Upper case,1 lowercase, 1 number and should have 8 characters
	const handlePasswordValidation=(rule,value,callback)=>{
		try {
			if(value){
				let passwordValidate=/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/g;
				let check=passwordValidate.test(value);
				if(check===false){
					setPasswordStatus("error");
					throw new Error(constants.PasswordValidation);
				}else{
					setPasswordStatus("success");
				}
				let passwordSpaceValidate=/^\S*$/;
				let spaceCheck=passwordSpaceValidate.test(value);
				if(spaceCheck===false){
					setPasswordStatus("error");
					throw new Error(constants.PasswordValidation);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handlePasswordChange=(e)=>{
		if(e.target.value){
			setPasswordRequired(true);
		}else{
			setPasswordRequired(false);
		}
	};
	// const termsOfUseMessage = (termMessage) =>{
	// 	return <Button type="link" className="px-1" href="https://advancedreviewpractice.com/terms-of-use/" target="_blank">
	// 		{termMessage}
	// 	</Button>;
	// };
		//Function to set the popup value as false to hide the popup.
	return (
		<div className="reg-form reg-bg py-3 px-2">
			<div className="reg-form-width">
				<h1 className="mb-0">{constants.MyProfileHeader}</h1>
				<br/>
				<Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
					<Row gutter={[8, 8]}>
						<Col sm={24} md={24} lg={8} className="text-center w-100 reg-form">
							<Form.Item className="mb-0">
								<Avatar
									shape="square"
									size={150}
									icon={
										(name && name.firstName && name.firstName.charAt(0)) +
										(name && name.lastName && name.lastName.charAt(0))
									}
								/>
							</Form.Item>
						</Col>
						<Col sm={24} md={24} lg={16} className="w-100">
							<Form.Item
								label={constants.FirstName}
								name="firstName"
								rules={[
									{
										required: true,
										message: constants.RequiredField,
									},
								]}
							>
								<Input placeholder={constants.FirstName} disabled={true}/>
							</Form.Item>
							<Form.Item
								label={constants.LastName}
								name="lastName"
								rules={[
									{
										required: true,
										message: constants.RequiredField,
									},
								]}
							>
								<Input placeholder={constants.LastName} disabled={true}/>
							</Form.Item>
							<Form.Item
								label={constants.ContactNo}
								className="phone-w-100"
								name="contactNo"
								rules={[
									{
										required: true,
										message:constants.RequiredField,
									},
								]}
							>
								<PhoneInput country={"us"} style={styles} onChange={handleContactNo} placeholder="1(999)123-4567"/>
							</Form.Item>
						</Col>
					</Row>
					<Card className="card-gray">
						<Form.Item
							label={constants.Username}
							name="userName"
							rules={[
								{
									required: true,
									message: constants.RequiredField,
								},
							]}
						>
							<Input placeholder={constants.Username} disabled={true}/>
						</Form.Item>
						<Form.Item
							name="password"
							label={constants.NewPassword}
							validateStatus={passwordStatus}
							rules={[
								{
									required: false,
									message: constants.PasswordNew,
								},
								{ validator: handlePasswordValidation },
							]}
							hasFeedback
							tooltip={constants.PsdTip}
						>
							<Input.Password placeholder={constants.Password} onChange={handlePasswordChange}/>
						</Form.Item>
						<Form.Item
							name="confirmPassword"
							label={constants.ConfirmNewPassword}
							dependencies={["password"]}
							hasFeedback
							rules={[
								{
									required: passwordRequired,
									message: constants.ConfirmNewPasswordMessage,
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("password") === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												constants.PasswordNotMatch
											)
										);
									},
								}),
							]}
						>
							<Input.Password placeholder={constants.ConfirmPsd}/>
						</Form.Item>
						<Form.Item
							label={constants.BookCode}
							name="bookCode"
							rules={[
								{
									required: false,
									message: constants.RequiredField,
								},
							]}
						>
							<Input placeholder="xxxxx-xxxxx-xxxxx-xxxxx-xxxxx" disabled={true}/>
						</Form.Item>
						{myProfileData&&myProfileData.dt_created&&myProfileData.valid_till&&<Text className={myProfileData&&myProfileData.subscription_days_remaining?"errorMsg":""}>Subscription valid from {myProfileData.dt_created} to {myProfileData.valid_till}</Text>}
					</Card>
					<br/>
					<Form.Item
						name="email"
						label={constants.Email}
						rules={[
							{
								type: "email",
								message: constants.EmailNotValid,
							},
							{
								required: true,
								message: constants.EmailRegistration,
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									let messageText="";
									if (!value || getFieldValue("alternateEmail") !== value) {
										if(value&&getFieldValue("alternateEmail")&&value!==getFieldValue("alternateEmail")){
											let emailDomain=value;
											let emailDomainText=emailDomain.substring(emailDomain.indexOf('@') + 1);
											let altEmailText=getFieldValue("alternateEmail");
											let altEmailDomainText=altEmailText.substring(altEmailText.indexOf("@")+1);
											if(altEmailDomainText&&emailDomainText){
												if(emailDomainText!==altEmailDomainText){
													return Promise.resolve();
												}else{
													messageText=constants.EmailDomain;
												}
											}else{
												return Promise.resolve();
											}
										}else{
											return Promise.resolve();
										}
									}else{
										messageText=constants.EmailSameAsAlternateEmail;
									}
									return Promise.reject(
										new Error(
											messageText
										)
									);
								},
							}),
						]}
					>
						<Input placeholder={constants.Email} disabled={true}/>
					</Form.Item>
					<Form.Item
						name="alternateEmail"
						label={constants.AlternateEmail}
						tooltip={constants.AlternateEmailTip}
						rules={[
							{
								type: "email",
								message: constants.EmailNotValid,
							},
							{
								required: true,
								message: constants.AlternateEmailRegistration,
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									let messageText="";
									if (!value || getFieldValue("email") !== value) {
										if(value&&getFieldValue("email")&&value!==getFieldValue("email")){
											let altemailDomain=value;
											let altEmailDomainText=altemailDomain.substring(altemailDomain.indexOf('@') + 1);
											let emailText=getFieldValue("email");
											let emailDomainText=emailText.substring(emailText.indexOf("@")+1);
											if(altEmailDomainText&&emailDomainText){
												if(emailDomainText!==altEmailDomainText){
													return Promise.resolve();
												}else{
													messageText=constants.AltEmailDomain;
												}
											}else{
												return Promise.resolve();
											}
										}else{
											return Promise.resolve();
										}
									}else{
										messageText=constants.AlternateEmailSameAsEmail;
									}
									return Promise.reject(
										new Error(
											messageText
										)
									);
								},
							}),
						]}
					>
						<Input placeholder={constants.AlternateEmail} disabled={true}/>
					</Form.Item>
					<Form.Item
						label={constants.Role}
						name="role"
						rules={[
							{
								required: true,
								message: constants.RequiredField,
							},
						]}
					>
						<Select
							showSearch
							placeholder={constants.IamA}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={onChangeRole}
						>
							<Option value={0}>Student</Option>
							<Option value={1}>Professional</Option>
							<Option value={2}>Faculty</Option>
							<Option value={3}>Other</Option>
						</Select>
					</Form.Item>
					{displayOccupation && (
						<Form.Item
							name="occupation"
							label={constants.Occupation}
							rules={[
								{
									required: displayOccupation,
									message: " ",
								},
								{ validator: handleValidateOccupation },
							]}
						>
							<Input placeholder={constants.Occupation}/>
						</Form.Item>
					)}
					<Form.Item
						label={constants.Country}
						name="country"
						rules={[
							{
								required: true,
								message: constants.CountryRegistration,
							},
						]}
					>
						<Select
							showSearch
							placeholder={constants.USA}
							filterOption={(input, option) =>
								option.props.children
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0 ||
								option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={onChangeCountry}
						>
							{/* */}
							{listCountries &&
								listCountries.map((country) => (
									<Option key={country.country_code} value={country.country_id}>
										{country.country_name}
									</Option>
								))}
						</Select>
					</Form.Item>
					{displayUniversity && (
						<Form.Item
							name="university"
							label="University"
							rules={[
								{
									required: displayUniversity,
									message: constants.UniversityRegistration,
								},
							]}
						>
							<Select
								showSearch
								placeholder={constants.University}
								optionFilterProp={"children"}
								onChange={onChangeUniversity}
							>
								{listUniversities &&
									Object.entries(listUniversities).map(([key, value]) => (
										<OptGroup key={key} label={key}>
											{value &&
												value.universities &&
												value.universities.length > 0 &&
												value.universities.map((universe) => (
													<Option
														key={universe.university_id}
														value={universe.university_id?universe.university_id.toString():""}
														region={universe.region_id}
													>
														{universe.university_name+(universe.city?","+universe.city:"")}
													</Option>
												))}
										</OptGroup>
									))}
							</Select>
						</Form.Item>
					)}
					{displayOtherUniversity&&(
						<Form.Item
							name="otherUniversity"
							label={constants.UniversityName}
							rules={[
								{
									required: displayOtherUniversity,
									message: "",
								},
								{ validator: handleValidateUniversityName },
							]}
						>
							<Input placeholder={constants.UniversityName}/>
						</Form.Item>
					)}
					<Row className="profile-checkbox">
						<Col>
							<Tooltip title={constants.ReminderEmailNotificationTooltip}>
								<Form.Item>
									<Checkbox
										onChange={onChangeRemindEmail}
										checked={checkReminderEmail}
									>
										{constants.ReminderEmailNotification}
									</Checkbox>
								</Form.Item>
							</Tooltip>
						</Col>
						<Col>
							<Form.Item>
								<Radio.Group
									onChange={onChangeEmailDuration}
									value={checkReminderEmailDuration}
								>
									<Radio value={1}>Weekly</Radio>
									<Radio value={2}>Biweekly</Radio>
									<Radio value={4}>Monthly</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item className="profile-checkbox">
						<Tooltip title={constants.EmailTestResultsTooltip}>
							<Checkbox
								checked={checkEmailTestResult}
								onChange={onChangeEmailTestResult}
							>
								{constants.EmailMeTestResult}
							</Checkbox>
						</Tooltip>
					</Form.Item>
					<Form.Item className="profile-checkbox">
						<Tooltip title={constants.MyfirstNameTooltip}>
							<Checkbox
								checked={allowDisplay}
								onChange={onAllowDisplayChange}
							>
								{constants.MyfirstName}
							</Checkbox>
						</Tooltip> 
					</Form.Item>
					<div className="text-right">						
						<Space>
							<Form.Item>
								<Button
									shape="round"
									className="gold-btn"
									onClick={()=>history.push("/home")}
									disabled={loading}
								>
									{constants.CancelBTN}
								</Button>
							</Form.Item>
							<Form.Item>
								<Button
									shape="round"
									className="gold-btn"
									htmlType="submit"
									loading={loading}
								>
									{constants.ProfileUpdateBTN}
								</Button>
							</Form.Item>
						</Space>
					</div>
					<div className="text-center">
						<Space size="middle">
							<Form.Item className="mb-0">
								<Text>{" "}<a href={constants.PDFTermsAndConditionsLink1+"#navpanes=0"} target="_blank" className="navy-2 font-x-small" rel="noreferrer">{constants.TnCReadLink}</a></Text>
							</Form.Item>
							<Form.Item className="mb-0">
								<Text>{" "}<a href={constants.PDFTermsAndConditionsLink2+"#navpanes=0"} target="_blank" className="navy-2 font-x-small" rel="noreferrer">{constants.EULALink}</a>
								</Text>
							</Form.Item>
							<Form.Item className="mb-0">
								<Text>{" "}<a href={constants.PDFTermsAndConditionsLink3+"#navpanes=0"} target="_blank" className="navy-2 font-x-small" rel="noreferrer">{constants.PrivacyPolicy}</a>
								</Text>
							</Form.Item>
						</Space>
					</div>
				</Form>
			</div>
		</div>
	);
};
export default MyProfile;
