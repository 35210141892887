import { Button, Skeleton } from 'antd';
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import constants from '../../constants/constants';
import { COOKIE, getCookie } from '../../services/cookie';
import { fetchApi } from '../../services/api';
import { useHistory } from "react-router";
const TopicChart = () => {
	const history = useHistory();
	const [topicData,setTopicData]=useState([]);
	useEffect(() => {
		fetchTopicChartData();
	}, []);
	const fetchTopicChartData = async() => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/compare-score?stud_id=${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				let chartData=["Tests", "1st attempt", "2nd attempt"];
				let topic=[];
				topic.push(chartData);
				let responseData={};
				if(res.data.Topics){
					for (let index = 0; index < res.data.Topics.length; index++) {
						if(!responseData[res.data.Topics[index].test]){
							responseData[res.data.Topics[index].test]={};
							responseData[res.data.Topics[index].test]["attempt"+res.data.Topics[index].attempt]=res.data.Topics[index];
						}else{
							responseData[res.data.Topics[index].test]["attempt"+res.data.Topics[index].attempt]=res.data.Topics[index];
						}
					}
					Object.entries(responseData).forEach(([key, value]) => {
						let eachObjArr=[];
						eachObjArr.push(key,value.attempt1?Number(value.attempt1.total_corr_ans):0,value.attempt2?Number(value.attempt2.total_corr_ans):0);
						topic.push(eachObjArr);
					});
					setTopicData(topic);
				}
				if(res.data.Topic_title){
					let topicTitle=[];
					Object.entries(res.data.Topic_title).forEach(([key, value]) => {
						topicTitle.push({
							topic_abbreviation:key.toString(),
							topic_title:value.title
						});
					});
				}
			}
		});
	};
	let options = { 
		colors: [constants.TopicDarkColor, constants.TopicLightColor], 
		vAxis: { 
			title: "Test Score" ,
			titleTextStyle: { italic: false },
			viewWindow: { min: 0 }
		},
		hAxis: { textPosition: 'none' },
		seriesType: "bars",
		legend: { 
			position: 'top',
			alignment: 'start' 
		},
	};
	return (
		<div>
			{ topicData&&topicData.length>1 ? 
				<Chart
					chartType="ComboChart"
					loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
					width="100%"
					height="400px"
					data={topicData}
					options={options}
				/>
				: 
				<>							
					<div className="nograph-div">
						<span>You have not taken any {constants.TopicTitle} tests.</span>
						<span>Would you like to start now? </span>
						<span><Button type="primary" className="navy-btn mt-3" onClick={()=>{	history.push("/instructions");}}>{constants.TakeTest}</Button></span>
					</div>
				</>
			}
		</div>
	);
};
export default TopicChart;