import React,{ useEffect,useState } from 'react';
import { fetchAdminApi } from "../../../services/api";
const JsonInfo =() => {
	const [jsonData, setJsonData] = useState([]);
	console.log("jsonData",jsonData);
	useEffect(() => {
		fetchJsonData();
	}, []);
	const fetchJsonData = () =>{
		let queryString = window.location.href;
		var id = queryString.substring(queryString.lastIndexOf('?') + 1);
		console.log("id",id);
		fetchAdminApi(`/get-student-data?stud_id=${id}`, "get").then(
			(res) => {
				if (res && res.data) {
					console.log(res.data);
					console.log(JSON.stringify(res.data[0]));
					setJsonData(JSON.stringify(res.data[0]));
				} else {
					setJsonData(null);
				}
			}
		);
	};
	return (
		<>
			<p>{jsonData}</p>
		</>
	);
};
export default JsonInfo;