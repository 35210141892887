/*
File Name: MastersMenu.js
Author: Dahlin Carneiro
Modified On: 27/10/2022
Description:Masters Menu page.
*/
import { Row, Col, Card, BackTop, Space, Divider } from "antd";
import { CopyFilled, TeamOutlined, CrownFilled, BarcodeOutlined, UnorderedListOutlined, QuestionOutlined, QuestionCircleFilled, ReadFilled, BankFilled, SettingOutlined  } from "@ant-design/icons";
import { useHistory } from "react-router";
import "../../../styles/admindashboard.css";
const MastersMenu = () => {
	const history = useHistory();
	return (
		<div className="color-crd-loop container">
			<BackTop/>
			<Divider>Masters</Divider>
			<Card title={<Space><CrownFilled/>Master Data</Space>} className="mb-3 gray-bg" size="small">
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 30
				}]}>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/test-list");}}>
							<Space direction="vertical">
								<QuestionCircleFilled className="icon-xlg"/>
								<span>Questions</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/case-study-list");}}>
							<Space direction="vertical">
								<ReadFilled className="icon-xlg"/>
								<span>Case Study</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/flashcard-list-chapter");}}>
							<Space direction="vertical">
								<CopyFilled className="icon-xlg flash-ico"/>
								<span>Flashcards</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/admin-faq");}}>
							<Space direction="vertical">
								<QuestionOutlined className="icon-xlg"/>
								<span>FAQs</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/university-master");}}>
							<Space direction="vertical">
								<BankFilled className="icon-xlg"/>
								<span>Universities</span>
							</Space>
						</Card>          
					</Col>
				</Row>
			</Card>
			<Card title={<Space><BarcodeOutlined/>Promocodes</Space>} className="mb-3 gray-bg" size="small">
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 30
				}]}>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/code-generation");}}>
							<Space direction="vertical" disabled={true}>
								<BarcodeOutlined className="icon-xlg"/>
								<span>Generate</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/promo-code-list");}}>
							<Space direction="vertical">
								<UnorderedListOutlined className="icon-xlg"/>
								<span>List</span>
							</Space>
						</Card>          
					</Col>
				</Row>
			</Card>
			<Card title={<Space><SettingOutlined/>Settings</Space>} className="mb-3 gray-bg" size="small">
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 30
				}]}>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/cron-result");}}>
							<Space direction="vertical">
								<SettingOutlined  className="icon-xlg"/>
								<span>Cron</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/admin-users");}}>
							<Space direction="vertical">
								<TeamOutlined className="icon-xlg"/>
								<span>Admin Users</span>
							</Space>
						</Card>          
					</Col>
				</Row>
			</Card>
		</div>
	);
};
export default MastersMenu;