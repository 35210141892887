import React, { useState, useEffect } from "react";
import {
	Input,
	Form,
	Checkbox,
	Button,
	Space,
	Select,
	Card,
	message,
	Typography,
	Modal,
	notification
} from "antd";
import "../../../styles/login.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import constants from "../../../constants/constants";
import { removeExtraSpaces, spacesNotAllowed,displayOtherUniversityAlaska,openNotification } from "../../../globalFunctions/GlobalFunctions";
// import { ExclamationCircleOutlined } from '@ant-design/icons';
import { fetchApi } from "../../../services/api";
import { useHistory } from "react-router";
import Headers from "../../../header";
import Footers from "../../../footer";
const { Option, OptGroup } = Select;
const { Text } = Typography;
// const { confirm } = Modal;
const Registration = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [listCountries, setCountries] = useState([]);
	const [listUniversities, setUniversities] = useState([]);
	const [displayUniversity, setDisplayUniversity] = useState(true);//TODO 1:set to true to enable university
	const [displayOccupation, setDisplayOccupation] = useState(false);
	const [displayOtherUniversity,setDisplayOtherUniversity]=useState(false);
	const [bookCodeStatus,setBookCodeStatus]=useState("");
	const [passwordStatus,setPasswordStatus]=useState("");
	const [regionCode,setRegionCode]=useState("");
	const [userNameValid,setUserNameValid]=useState("");
	const [userNamerErrorMsg,setUserNameErrorMsg]=useState("");
	const [visibleTermsAndConditions,setVisibleTermsAndConditions]=useState(false);
	const [emailValidateStatus,setEmailValidateStatus]=useState("");
	const [alternateEmailValidateStatus,setAlternateEmailValidateStatus]=useState("");
	const [termsData,setTermsData]=useState([
		{ 
			count:0,
			check:false,
			url:constants.PDFTermsAndConditionsLink1,
			text:constants.TermsOfUseText,
			viewed:false,
		},
		{ 
			count:1,
			check:false,
			url:constants.PDFTermsAndConditionsLink2,
			text:constants.EULAText,
			viewed:false,
		},
		{ 
			count:2,
			check:false,
			url:constants.PDFTermsAndConditionsLink3,
			text:constants.PrivacyPolicyText,
			viewed:false, 
		}]);
	const [count,setCount]=useState(0);
	const [refresh,setRefresh]=useState(false);
	const [valuesOfForm,setValuesOfForm]=useState(null);
	const [emailIdValue,setEmailIdValue]=useState(null);
	const [alternateIdValue,setAlternateIdValue]=useState(null);
	// const [userPhoneValid,setPhoneValid]=useState("");
	const [styles,setStyles]=useState(null);
	const [validationErrormessages,setValidationErrorMessages]=useState([
		{
			IsError:false,
			errorMessage:""
		},
		{
			IsError:false,
			errorMessage:""
		},
		{
			IsError:false,
			errorMessage:""
		},
	]);
	useEffect(() => {
		notification.destroy();
		openNotification();
		fetchCountries();
		fetchUniversities();
	}, []);
	//To fetch the list of countries
	const fetchCountries = () => {
		fetchApi("/countries", "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setCountries(res.data);
				for (let i = 0; i < res.data.length; i++) {
					if (res.data[i].country_code === "US") {
						setDisplayUniversity(true);//Todo 2:set to tue to enable university
						form.setFieldsValue({ country: res.data[i].country_id });
					}
				}
			} else {
				setCountries([]);
			}
		});
	};
	//To fetch the list of Universities
	const fetchUniversities = () => {
		fetchApi("/universities?country_code=US", "get").then(
			(res) => {
				if (res && res.data) {
					Object.entries(res.data).forEach(([key, value]) => {
						displayOtherUniversityAlaska(key,value);
					});
					setDisplayOtherUniversity(false);
					setUniversities(res.data);
				} else {
					setUniversities(null);
				}
			}
		);
	};
	const onChangeRole = (value) => {
		if (value === 3) {
			setDisplayOccupation(true);
		} else {
			form.setFieldsValue({ occupation:null });
			setDisplayOccupation(false);
		}
	};
	//Executes on change of Country field
	const onChangeCountry = (value, option) => {
		if (option && option.key !== "US") {
			setDisplayUniversity(false);
			setDisplayOtherUniversity(false);
			form.setFieldsValue({ university: null });
			form.setFieldsValue({ otherUniversity:null });
		} else {
			setDisplayUniversity(true);//TODO 3:set to true to enable university
		}
	};
	//To Submit the values from the registration form to the API.
	const onFinish = (values) => {
		let temp;
		validationErrormessages.map((item,index)=>{
			if(item.IsError){
				if(index!==2){
					message.error(item.errorMessage);
				}
				temp=index;
				return;
			}
		});
		if(userNameValid==="error"){
			message.error(userNamerErrorMsg);
		}else if(temp){
			console.log("error");
			// message.error(validationErrormessages[temp].errorMessage);
		}else{
			let payload={};
			if(emailIdValue){
				payload.stud_email=emailIdValue;
			}
			if(alternateIdValue){
				payload.alternate_stud_email=alternateIdValue;
			}
			fetchApi("/student/register/validate-email", "post", payload).then((res) => {
				if (res && res.code && res.code === 200) {
					setEmailValidateStatus("success");
					setAlternateEmailValidateStatus("success");
					setVisibleTermsAndConditions(true);
					setValuesOfForm(values);
				}
			});
		}
	};
	//Function to set region id and to display other university field if other or NA is selected
	const onChangeUniversity=(value,option)=>{
		setRegionCode(option.props.region);
		if(option.props.children==="Other"){
			setDisplayOtherUniversity(true);
		}else{
			setDisplayOtherUniversity(false);
			form.setFieldsValue({ otherUniversity:null });
		}
	};
	// Validates Occupation to check if it is same as role or if it is empty
	const handleValidateOccupation = (rule, value, callback) => {
		try {
			if(value){
				let occupationValidate=/^[a-zA-Z\s]*$/;
				let check=occupationValidate.test(value);
				if(value.trim().toLowerCase()==="student"||value.trim().toLowerCase()==="professional"||value.trim().toLowerCase()==="faculty"||value.trim().toLowerCase()==="other"
				||value.trim().toLowerCase()==="students"||value.trim().toLowerCase()==="professionals"||value.trim().toLowerCase()==="facultys"||value.trim().toLowerCase()==="others"){
					throw new Error(constants.OccupationRole);
				}
				if(value.trim()===""||check===false){
					throw new Error(constants.OccupationRegistration);
				}
			}else{
				throw new Error(constants.OccupationRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
		// Validates university name to check if it is other
	const handleValidateUniversityName = (rule, value, callback) => {
		try {
			if(value){
				if(value.trim().toLowerCase()==="other"||value.trim().toLowerCase()==="others"||value.trim()===""){
					throw new Error(constants.OtherUniversityRegistration);
				}
				checkUniversityExists(value);
			}else{
				throw new Error(constants.OtherUniversityRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//Validates book code to check only hyphens and numbers should be there
	const handleValidateBookCode=(rule,value,callback)=>{
		try {
			if(value){
				// 	throw new Error(constants.BookCodeValidation);
			}else{
				setBookCodeStatus("error");
				throw new Error(constants.BookCodeRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//Validate book code if it is unique
	const handleCheckBookCode=(e)=>{
		let value = removeExtraSpaces(e?.target?.value);
		form?.setFieldsValue({ bookCode: value });
		if(value){
			let payload={};
			setVisibleTermsAndConditions(false);
			payload.promo_code=value;
			setBookCodeStatus("validating");
			fetchApi("/student/register/validate", "post", payload).then((res) => {
				if (res && res.code && res.code === 200) {
					setBookCodeStatus("success");
					let tempArray=validationErrormessages;
					tempArray[1].IsError=false;
					tempArray[1].errorMessage="";
					setValidationErrorMessages(tempArray);
				} else {
					setBookCodeStatus("error");
					if(res.link){
						// let urlRedirectLink=res.link;
						message.error({
							content:  <span>{res.message.promo_code} Click <a href={constants.SiteUrl+constants.BookCodeMoreInfo} target="_blank" rel="noreferrer">here</a> {constants.RegistrationInvalidPromoExtra}</span>, 
							duration: 4 
						});
					}else{
						message.error(res.message.promo_code);
					}
					let tempArray=validationErrormessages;
					tempArray[1].IsError=true;
					tempArray[1].errorMessage=res.message.promo_code;
					setValidationErrorMessages(tempArray);
				}
			});
		}
	};
		//Validate email id
	function handleCheckEmail(type){
		let payload={};
		if(emailIdValue){
			payload.stud_email=emailIdValue;
		}
		if(alternateIdValue){
			payload.alternate_stud_email=alternateIdValue;
		}
		fetchApi("/student/register/validate-email", "post", payload).then((res) => {
			if (res && res.code && res.code === 200) {
				if(type==="primary"){
					setEmailValidateStatus("success");
				}
				if(type==="alternate"){
					setAlternateEmailValidateStatus("success");
				}
				let tempArray=validationErrormessages;
				tempArray[2].IsError=false;
				tempArray[2].errorMessage="";
				setValidationErrorMessages(tempArray);
			} else {
				if(type==="primary"){
					setEmailValidateStatus("error");
				}
				if(type==="alternate"){
					setAlternateEmailValidateStatus("error");
				}
				message.error(res.message);
				let tempArray=validationErrormessages;
				tempArray[2].IsError=true;
				tempArray[2].errorMessage=res.message;
				setValidationErrorMessages(tempArray);
			}
		});
	}
	//Check if university exists
	const checkUniversityExists=(e)=>{
		let universityTempName=e.trim();
		let isErrorExists=false;
		if(listUniversities){
			Object.entries(listUniversities).map(([key, value]) =>{ 
				if(key){
					if(value &&value.universities &&value.universities.length > 0){
						value.universities.map((universe) => {
							let cityname=universe.city?","+universe.city:"";
							let universityCity=universe.university_name+cityname;
							if(universityTempName.replace(/\s+/g, '').toLowerCase()===universe.university_name.replace(/\s+/g, '').toLowerCase()||universityTempName.replace(/\s+/g, '').toLowerCase()===universityCity.replace(/\s+/g, '').toLowerCase()){
								isErrorExists=true;
								return;
							}
						});
					}
				}
			});
		}
		if(isErrorExists===true){
			throw new Error(constants.OtheeUniversityExists);
		}
	};
	//Function to check validation errors on click of register button
	const onFinishFailed=(values)=>{
		if((values&&values.values&&values.values.contactNo===null)||
		(values&&values.values&&values.values.contactNo===undefined)||
		(values&&values.values&&values.values.contactNo==="")){
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		}
	};
	//Function to check the contact number and set validation error using css
	const handleContactNo=(value)=>{
		if(value===""||value===null||value===undefined){
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		}else{
			setStyles(null);
		}
	};
	//Function to validate password to check if it has 1 special character, 1 Upper case, 1 number and should have 8 characters
	const handlePasswordValidation=(rule,value,callback)=>{
		try {
			if(value){
				let passwordValidate=/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/g;
				//^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^!&*+=]).*$
				//^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$
				let check=passwordValidate.test(value);
				if(check===false){
					setPasswordStatus("error");
					throw new Error(constants.PasswordValidation);
				}else{
					setPasswordStatus("success");
				}
				let passwordSpaceValidate=/^\S*$/;
				let spaceCheck=passwordSpaceValidate.test(value);
				if(spaceCheck===false){
					setPasswordStatus("error");
					throw new Error(constants.PasswordValidation);
				}
			}else{
				setPasswordStatus("error");
				throw new Error(constants.PasswordRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//Function used to validate the username through the API
	const handleValidateUsername=(value)=>{
		if(value){
			let payload={};
			payload.login_username=spacesNotAllowed(value);
			fetchApi("/student/register/check-username", "post", payload).then((res) => {
				if (res && res.code && res.code === 200) {
					setUserNameValid("success");
					let tempArray=validationErrormessages;
					tempArray[0].IsError=false;
					tempArray[0].errorMessage="";
					setValidationErrorMessages(tempArray);
				} else {
					setUserNameValid("error");
					setUserNameErrorMsg(res.message&&res.message.login_username?res.message.login_username:res.message);
					message.error(res.message&&res.message.login_username?res.message.login_username:res.message);
					let tempArray=validationErrormessages;
					tempArray[0].IsError=true;
					tempArray[0].errorMessage=res.message&&res.message.login_username?res.message.login_username:res.message;
					setValidationErrorMessages(tempArray);
				}
			});
		}else{
			setUserNameValid("error");
		}
	};
	const handleValidateUsernameSpace=(rule,value,callback)=>{
		try {
			if(value){
				let usernameValidate=/^(?=.{2,})\S*$/;
				// eslint-disable-next-line no-useless-escape
				let usernameValueValidate=/[^A-Za-z0-9\._]+/;
				if(usernameValidate.test(value)===false||usernameValueValidate.test(value)===true){
					setUserNameValid("error");
					throw new Error(constants.UserNameRegistrationInvalid);
				}
				else if(userNameValid==="error"){
					throw new Error(userNamerErrorMsg);
				}else{
					handleValidateUsername(value);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleValidatePhone=(rule,value,callback)=>{
		try {
			if(value){
				let phoneValidate=/^(?=.{5,})\S*$/;
				if(phoneValidate.test(value)===false){
					// setPhoneValid("error");
					const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
					setStyles(stylesValidation);
					throw new Error(constants.ContactNoRegistration);
				}else{
					setStyles(null);
					// setPhoneValid("success");
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleValidateName=(rule,value,callback)=>{
		try {
			if(value){
				// eslint-disable-next-line no-useless-escape
				let nameValidate=/[`~@#$%^&*,<>;?:"\/\[\]\|{}()=+]/;
				if(nameValidate.test(value)===true){
					throw new Error(constants.FirstNameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleValidateLastName=(rule,value,callback)=>{
		try {
			if(value){
				// eslint-disable-next-line no-useless-escape
				let nameValidate=/[`~@#$%^&*,<>;?:"\/\[\]\|{}()=+]/;
				if(nameValidate.test(value)===true){
					throw new Error(constants.LastNameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//Function to validate email 
	const handleValidateEmail=(rule,value,callback)=>{
		// try {
		// 	if(value&&value.includes('@')){
		// 		let subValue=value.substring(0,value.lastIndexOf('@'));
		// 		let emailValidate=/^[ A-Za-z0-9_.-]*$/;
		// 		if(emailValidate.test(subValue)===false){
		// 			// setEmailValidateStatus("error");
		// 			throw new Error(constants.emailVaidationError);
		// 		}else{
		// 			// setEmailValidateStatus("success");
		// 		}
		// 	}
		// 	callback(); // < -- this
		// } catch (err) {
		// 	callback(err);
		// }
		try {
			if(!value){
				setEmailValidateStatus("error");
				throw new Error(constants.EmailRegistration);
			}
			// else{
			// 	setEmailValidateStatus("success");
			// }
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
		//Function to validate email 
	const handleValidateAlternateEmail=(rule,value,callback)=>{
		try {
			if(!value){
				setAlternateEmailValidateStatus("error");
				throw new Error(constants.AlternateEmailRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//Function to check username entered by user.
	const handleUsernameChange=(e)=>{
		if(e.target.value){
			setUserNameValid("");
		}else{
			setUserNameValid("error");
		}
	};
	// const termsOfUseMessage = (termMessage) =>{
	// 	return <Button type="link" className="px-1" href="https://advancedreviewpractice.com/terms-of-use/" target="_blank">
	// 		{termMessage}
	// 	</Button>;
	// };
	//Function to set the popup value as false to hide the popup.
	const handleCancelTermsAndCondition=()=>{
		setVisibleTermsAndConditions(false);
	};
	//Function executes when previous button is clicked on the terms and conditions popup
	const handlePreviousCount=()=>{
		let coun=count-1;
		setCount(coun);
	};
	//Function executes when next button is clicked on the terms and conditions popup
	const handleNextCount=()=>{
		let coun=count+1;
		if(coun<3){
			setCount(coun);
		}
	};
	//Function executes when the checkbox is clicked on the terms and conditions popup
	const handleTermsCheck=(e)=>{
		termsData[count].check=e.target.checked;
		setTermsData(termsData);
		setRefresh(!refresh);
	};
	//Function exectues to register the user and calling the register API.
	const registerUser=()=>{
		setLoading(true);
		let payload = null;
		payload = {
			stud_firstname: removeExtraSpaces(valuesOfForm.firstName),
			stud_lastname: removeExtraSpaces(valuesOfForm.lastName),
			stud_contact_no: valuesOfForm.contactNo,
			login_username: spacesNotAllowed(valuesOfForm.userName),
			login_password: valuesOfForm.password,
			confirm_password: valuesOfForm.confirmPassword,
			promo_code: removeExtraSpaces(valuesOfForm.bookCode),
			stud_email: valuesOfForm.email.toLocaleLowerCase(),
			alternate_stud_email: valuesOfForm.alternateEmail.toLocaleLowerCase(),
			occupation_type: valuesOfForm.role,
			other_occupation:valuesOfForm.occupation?valuesOfForm.occupation.trim():null,
			country_id: valuesOfForm.country,
		};
		//TODO 4:uncomment to get university field
		if (valuesOfForm.university) {
			if(isNaN(valuesOfForm.university)){
				if(valuesOfForm.university.includes("Other_")){
					payload.university_id = null;
					payload.state_id=regionCode;
					payload.other_university=valuesOfForm.otherUniversity?valuesOfForm.otherUniversity.trim():null;
				}else if(valuesOfForm.university.includes("NA")){
					payload.university_id = null;
					payload.state_id=null;
					payload.other_university="NA";
				}else if(valuesOfForm.university.includes("Other")){
					payload.university_id = null;
					payload.state_id=null;
					payload.other_university=valuesOfForm.otherUniversity?valuesOfForm.otherUniversity.trim():"";
				}
			}else{
				payload.university_id=valuesOfForm.university;
				payload.state_id=regionCode;
				payload.other_university=null;
			}
		}
		//TODO 4 END
		//TODO 5 remove this to display university
		// payload.university_id=null;
		// payload.state_id=null;
		// payload.other_university=values.country==="238"?"NA":null;
		//TODO 5 END
		fetchApi("/student/register", "post", payload).then((res) => {
			setLoading(false);
			if (res && res.code && res.code === 200) {
				message.success({
					content:constants.RegisterSuccess,
					duration:8
				});
				history.push("/login");
			} else {
				if (res.message) {
					if (res.message.stud_firstname) {
						message.error(res.message.stud_firstname);
					}
					if (res.message.stud_lastname) {
						message.error(res.message.stud_lastname);
					}
					if (res.message.login_username) {
						message.error(res.message.login_username);
					}
					if (res.message.login_password) {
						message.error(res.message.login_password);
					}
					if (res.message.promo_code) {
						message.error(res.message.promo_code);
					}
					if (res.message.stud_contact_no) {
						message.error(res.message.stud_contact_no);
					}
					if (res.message.stud_email) {
						message.error(res.message.stud_email);
					}
					if (res.message.alternate_stud_email) {
						message.error(res.message.alternate_stud_email);
					}
				}
			}
		});
	};
	return (
		<div>
			<Headers/>
			<div className="reg-form reg-bg py-3 px-2">
				<div className="reg-form-width">
					<h1 className="mb-0">{constants.RegisterHeader}</h1>
					<Space>
						{constants.LoginRedirect}
						<Button
							type="link"
							className="pl-0"
							onClick={() => {
								history.push("/login");
							}}
						>
							{" "}
							{constants.LoginRedirectLink}
						</Button>
					</Space>
					<br/>
					<br/>
					<Form
						form={form}
						layout="vertical"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>
						<Form.Item
							label={constants.FirstName}
							name="firstName"
							rules={[
								{
									required: true,
									message: constants.FirstNameRegistration,
								},
								// {
								// 	// pattern: /^(?=.{2,})[A-Za-z]+((\s)?((.)?([A-Za-z])+))*$/,
								// 	// eslint-disable-next-line no-useless-escape
								// 	pattern:/[`~@#$%^&*,<>;?:"\/\[\]\|{}()=+]/,
								// 	message:
								// 	constants.FirstNameInvalid,
								// },
								{ validator: handleValidateName },
							]}
						>
							<Input autoFocus placeholder={constants.FirstName}/>
						</Form.Item>
						<Form.Item
							label={constants.LastName}
							name="lastName"
							rules={[
								{
									required: true,
									message: constants.LastNameRegistration,
								},
								{ validator: handleValidateLastName },
							]}
						>
							<Input placeholder={constants.LastName}/>
						</Form.Item>
						<Form.Item
							label={constants.ContactNo}
							className="phone-w-100"
							name="contactNo"
							// validateStatus={userPhoneValid}
							rules={[
								{
									required: true,
									message: constants.ContactNoRegistration,
								},
								{ validator: handleValidatePhone },
							]}
						>
							<PhoneInput country={"us"} style={styles} onChange={handleContactNo} placeholder="1(999)123-4567"/>
						</Form.Item>
						<Card className="card-gray">
							<Form.Item
								label={constants.Username}
								name="userName"
								// hasFeedback
								// validateStatus={userNameValid}
								rules={[
									{
										required: true,
										message: constants.UserNameRegistration,
									},
									{ validator: handleValidateUsernameSpace },
									// {
									// 	pattern: /^\S*$/,
									// 	message:
									// constants.UserNameRegistrationInvalid, handleValidateUsernameSpace
									// },
								]}
								tooltip={constants.RegistrationUsernameTooltip}
							>
								<Input placeholder={constants.Username} 
									//onBlur={handleValidateUsername} 
									onChange={handleUsernameChange}/>
							</Form.Item>
							<Form.Item
								name="password"
								label={constants.Password}
								validateStatus={passwordStatus}
								rules={[
									{
										required: true,
										message: " ",
									},
									{ validator: handlePasswordValidation },
								]}
								hasFeedback
								tooltip={constants.PsdTip}
							>
								<Input.Password placeholder={constants.Password}/>
							</Form.Item>
							<Form.Item
								name="confirmPassword"
								label={constants.ConfirmPsd}
								dependencies={["password"]}
								hasFeedback
								rules={[
									{
										required: true,
										message: constants.ConfirmPasswordRegistration,
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													constants.PasswordNotMatch
												)
											);
										},
									}),
								]}
							>
								<Input.Password placeholder={constants.ConfirmPsd}/>
							</Form.Item>
							<Form.Item
								extra={constants.BookCodeSubText}
								label={constants.BookCode}
								name="bookCode"
								hasFeedback
								validateStatus={bookCodeStatus}
								tooltip={constants.BookCodeTip}
								rules={[
									{
										required: true,
										message: " ",
									},
									{ validator: handleValidateBookCode },
								]}
							>
								<Input placeholder="xxxxx-xxxxx-xxxxx-xxxxx-xxxxx" 
								// maxLength={constants.MaxBookcodeChar} 
									onBlur={handleCheckBookCode}
									// onChange={handleChangeBookCode}
								/>
							</Form.Item>
						</Card>
						<br/>
						<Card className="card-gray">
							<Form.Item
								name="email"
								hasFeedback
								validateStatus={emailValidateStatus}
								label={constants.Email}
								tooltip={constants.PrimaryEmailTip}
								rules={[
									// {
									// 	type: "email",
									// 	message: constants.EmailNotValid,
									// },
									{
										required: true,
										message: " ",
									},
									{	validator:handleValidateEmail },
								// ({ getFieldValue }) => ({
								// 	validator(_, value) {
								// 		let message="";
								// 		if (!value || getFieldValue("alternateEmail") !== value) {
								// 			if(value&&getFieldValue("alternateEmail")&&value!==getFieldValue("alternateEmail")){
								// 				let regex=/(?<=@)[^.]+/;
								// 				let emailDomain=value.match(regex);
								// 				let altEmailDomain=getFieldValue("alternateEmail").match(regex);
								// 				if(emailDomain&&emailDomain.length>0){
								// 					if(emailDomain[0]!==altEmailDomain[0]){
								// 						return Promise.resolve();
								// 					}else{
								// 						message=constants.EmailDomain;
								// 					}
								// 				}else{
								// 					message=" ";
								// 				}
								// 			}else{
								// 				return Promise.resolve();
								// 			}
								// 		}else{
								// 			message=constants.EmailSameAsAlternateEmail;
								// 		}
								// 		return Promise.reject(
								// 			new Error(
								// 				message
								// 			)
								// 		);
								// 	},
								// }),
								]}
							>
								<Input placeholder={constants.Email} 
									onChange={(e)=>{
										setEmailIdValue(e.target.value.toLocaleLowerCase());
									}}
									// onChange={handleChangeEmail} 
									onBlur={()=>{handleCheckEmail('primary');}}
								/>
							</Form.Item>
							<Form.Item
								name="alternateEmail"
								hasFeedback
								dependencies={['email']}
								validateStatus={alternateEmailValidateStatus}
								label={constants.AlternateEmail}
								tooltip={constants.AlternateEmailTip}
								rules={[
									// {
									// 	type: "email",
									// 	message: constants.EmailNotValid,
									// },
									{
										required: true,
										message: " ",
									},
									{	validator:handleValidateAlternateEmail },
									// ({ getFieldValue }) => ({
									// 	validator(_, value) {
									// 		let messageText="";
									// 		if (!value || getFieldValue("email") !== value) {
									// 			if(value&&getFieldValue("email")&&value!==getFieldValue("email")){
									// 				let altemailDomain=value;
									// 				let altEmailDomainText=altemailDomain.substring(altemailDomain.indexOf('@') + 1);
									// 				let emailText=getFieldValue("email");
									// 				let emailDomainText=emailText.substring(emailText.indexOf("@")+1);
									// 				if(altEmailDomainText&&emailDomainText){
									// 					if(emailDomainText!==altEmailDomainText){
									// 						return Promise.resolve();
									// 					}else{
									// 						messageText=constants.AltEmailDomain;
									// 					}
									// 				}else{
									// 					return Promise.resolve();
									// 				}
									// 			}else{
									// 				return Promise.resolve();
									// 			}
									// 		}else{
									// 			messageText=constants.AlternateEmailSameAsEmail;
									// 		}
									// 		// setEmailValidateStatus("error");
									// 		return Promise.reject(
									// 			new Error(
									// 				messageText
									// 			)
									// 		);
									// 	},
									// }),
								]}
							>
								<Input placeholder={constants.AlternateEmail} 
									onChange={(e)=>{
										setAlternateIdValue(e.target.value.toLocaleLowerCase());
									}
									}
									onBlur={()=>{handleCheckEmail("alternate");}}
								/>
							</Form.Item>
							<div>
								<Text className = "default-cursor">{constants.RemindersText}</Text>
							</div>
						</Card>
						<br/>
						<Form.Item
							label={constants.Role}
							name="role"
							rules={[
								{
									required: true,
									message: constants.RoleRegistration,
								},
							]}
						>
							<Select
								showSearch
								placeholder={constants.IamA}
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=0
								}
								onChange={onChangeRole}
							>
								<Option value={0}>Student</Option>
								<Option value={1}>Professional</Option>
								<Option value={2}>Faculty</Option>
								<Option value={3}>Other</Option>
							</Select>
						</Form.Item>
						{displayOccupation && (
							<Form.Item
								label={constants.Occupation}
								name="occupation"
								rules={[
									{
										required: displayOccupation,
										message: " ",
									},
									{ validator: handleValidateOccupation },
								]}
							>
								<Input placeholder={constants.Occupation}/>
							</Form.Item>
						)}
						<Form.Item
							label={constants.Country}
							name="country"
							rules={[
								{
									required: true,
									message: constants.CountryRegistration,
								},
							]}
						>
							<Select
								showSearch
								placeholder={constants.USA}
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0 ||
										option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onChange={onChangeCountry}
							>
								{/* */}
								{listCountries &&listCountries.map((country) => (<Option key={country.country_code} value={country.country_id}>{country.country_name}</Option> ))}
							</Select>
						</Form.Item>
						{displayUniversity && (
							<Form.Item
								name="university"
								label="University"
								rules={[
									{
										required: displayUniversity,
										message: constants.UniversityRegistration,
									},
								]}
							>
								<Select
									showSearch
									placeholder={constants.University}
									optionFilterProp={"children"}
									onChange={onChangeUniversity}
								>
									{listUniversities &&
									Object.entries(listUniversities).map(([key, value]) => (<OptGroup label={key} key={key}>
										{value &&value.universities &&value.universities.length > 0 &&
										value.universities.map((universe) => (
											<Option
												key={universe.university_id}
												value={universe.university_id}
												region={universe.region_id}
											>
												{universe.university_name+(universe.city?","+universe.city:"")}
											</Option>
										)
										)
										}
									</OptGroup>
									))}
								</Select>
							</Form.Item>
						)}
						{displayOtherUniversity&&(
							<Form.Item
								name="otherUniversity"
								label={constants.UniversityName}
								rules={[
									{
										required: displayOtherUniversity,
										message: "",
									},
									{ validator: handleValidateUniversityName },
								]}
							>
								<Input placeholder={constants.UniversityName} 
								/>
							</Form.Item>
						)}
						<div>{constants.ConfirmRegisterTittle}</div>
						<p>{constants.ConfirmRegisterDescription}</p>
						<div className="text-right">
							<Space>
								<Form.Item>
									<Button
										shape="round"
										className="gold-btn"
										disabled = {loading}
										onClick={() => {
											history.push("/");
										}}
									>
										{constants.CancelBTN}
									</Button>
								</Form.Item>
								<Form.Item>
									<Button
										shape="round"
										className="gold-btn"
										htmlType="submit"
										loading = {loading}
									>
										{constants.RegisterBTN}
									</Button>
								</Form.Item>
							</Space>
						</div>
					</Form>
				</div>
				<Modal
					visible={visibleTermsAndConditions}
					footer={false}
					title={constants.TermsAndConditionsPopup}
					onCancel={()=>{handleCancelTermsAndCondition();}}
					width={"60%"}
					centered
					className='terms-modal'
				>
					<div className="pdfHeight">
						{termsData&&termsData.length>0&&<iframe width={"100%"} height={"90%"} src={termsData[count].url+"#navpanes=0"}/>}
						<div className='mx-3 mt-1'>
							{termsData&&termsData.length>0&&<Checkbox className="navy-check" onChange={handleTermsCheck} checked={termsData[count].check}><Text strong className="blue-2">{termsData[count].text}</Text></Checkbox>}
							{count>0&&<Button onClick={()=>{handlePreviousCount();}} className="btnDb min-width-100 ml-2">{constants.Previous}</Button>}
							{count<2&&<Button disabled={termsData[count].check?false:true} onClick={()=>{handleNextCount();}} className="btnDb min-width-100 ml-2" >{constants.Next}</Button>}
							{count===2&&<Button disabled={termsData[count].check?false:true}onClick={()=>{registerUser();}} className="btnDb min-width-100 ml-2" >{constants.SubmitBTN}</Button>}
						</div>
					</div>
				</Modal>
			</div>
			<Footers/>
		</div>
	);
};
export default Registration;
