import React,{ useState,useEffect } from 'react';
import { fetchAdminApi } from "../../../services/api";
import { Breadcrumb, Row, Col, Card, Form, DatePicker, Space, Table, Select } from 'antd';
import moment from "moment";
import { useHistory } from "react-router";
import { BankOutlined } from '@ant-design/icons';
import constants from '../../../constants/constants';
const { Option } = Select;
import { registrationFilter } from "../../../globalFunctions/GlobalFunctions";
const RegistrationInUsUniversities = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [endDate, setEndDate] = useState(props && props.history && props.history.location && props.history.location.state?props.history.location.state.ed:"");
	const [startDate, setStartDate] = useState(props && props.history && props.history.location && props.history.location.state?props.history.location.state.sd:"");
	const [tableData, setTableData] = useState([]);
	const [studentCount, setStudentCount] = useState(null);
	const [professionalCount, setProfessionalCount] = useState(null);
	const [professorsCount, setProfessorsCount] = useState(null);
	const [othersCount, setOthersCount] = useState(null);
	const [totalCount, setTotalCount] = useState(null);
	const [payload] =useState(props && props.history && props.history.location && props.history.location.state &&props.history.location.state.more ?props.history.location.state.more:sessionStorage.getItem("more"));
	const [sortedInfo,setsortedInfo]=useState({
		order: 'ascend',
		columnKey: 'srNumber',
	});
	const handleChange = (_pagination, _filters, sorter) => {
		setsortedInfo(sorter);
	};
	useEffect(() => {
		if(props && props.history && props.history.location && props.history.location.state){
			form.setFieldsValue({
				fromDate:props.history.location.state.sd?moment(props.history.location.state.sd):moment(constants.startDateOfTheYear),
				toDate:props.history.location.state.ed?moment(props.history.location.state.ed):moment(constants.endDateOfTheYear),
				filterValue:props.history.location.state.filterValue
			});
		}
	}, []);
	useEffect(() => {
		fetchRegistrationByUSUniversity();
		if(props && props.history && props.history.location && props.history.location.state){
			sessionStorage.setItem("more",props.history.location.state&& props.history.location.state.more);
		}
	}, [startDate, endDate]);
	const fetchRegistrationByUSUniversity = () => {
		const sd = moment(startDate).format("YYYY-MM-DD");
		const ed = moment(endDate).format("YYYY-MM-DD");
		const selectedDate = `/students-by-university?start_date=${sd}&end_date=${ed}`;
		const currentYear = "/students-by-university";
		fetchAdminApi(startDate && endDate ? selectedDate : currentYear, "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					const data = res.data;
					const stud_Count = data.map(item => parseInt(item.student_count)).reduce((prev, curr) => prev + curr, 0);
					setStudentCount(stud_Count);
					const prof_Count = data.map(item => parseInt(item.professional_count)).reduce((prev, curr) => prev + curr, 0);
					setProfessionalCount(prof_Count);
					const professors_Count = data.map(item => parseInt(item.faculty_count)).reduce((prev, curr) => prev + curr, 0);
					setProfessorsCount(professors_Count);
					const others_Count = data.map(item => parseInt(item.other_count)).reduce((prev, curr) => prev + curr, 0);
					setOthersCount(others_Count);
					const total_Count = data.map(item => parseInt(item.total_count)).reduce((prev, curr) => prev + curr, 0);
					setTotalCount(total_Count);
					setTableData(data);
				} else {
					setTableData([]);
					setStudentCount(null);
					setProfessionalCount(null);
					setProfessorsCount(null);
					setOthersCount(null);
					setTotalCount(null);
				}
			}
		});
	};
	const from = (_date, dateString) => {
		setStartDate(dateString);
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const registedStudent = (record,type) =>{
		let role = "";
		if(type ===0){
			role = 'Students,';
		}else if ( type === 1){
			role = "Professionals,";
		}else if ( type === 2){
			role = "Faculties,";
		}else if ( type === 3){
			role = "Others,";
		}else{
			role = null;
		}
		let payloadData = {};
		payloadData.sd = startDate ? moment(startDate).format("YYYY-MM-DD") :  constants.startDateOfTheYear;
		payloadData.ed = endDate ? moment(endDate).format("YYYY-MM-DD") :  constants.endDateOfTheYear;
		payloadData.sded = startDate&&endDate ? 1 : 2;
		payloadData.state_id = record.state_id;
		payloadData.university_id = parseInt(record.university_id);
		payloadData.university_name = record.university_name;
		// payloadData.type = type.toString();
		payloadData.roleId = type.toString();
		payloadData.title = 'Registrations By Universities';
		payloadData.push = '/registration-in-us-university';
		payloadData.title2 = 'Top 10 Universities Registrations';
		payloadData.push2 = '/registration-by-university';
		payloadData.country_id=constants.usCountryId;
		payloadData.serchCriteria = `Role: ${role} University: ${record.university_name}, Country : ${constants.usCountryCode}, State: ${record.region_code}, ${`Registrations from ${moment(payload.sd).format('MM/DD/YYYY')} to ${moment(payload.ed).format('MM/DD/YYYY')}`}`;
		history.push({
			pathname: "/registered-students",
			state: payloadData
		});
	};
	const column = [
		{
			title: "University",
			key: "university",
			dataIndex: "university_name",
			width: 200,
			sorter: (a, b) => a.university_name.localeCompare(b.university_name),
			sortOrder: sortedInfo.columnKey === 'university' && sortedInfo.order,
		},
		{
			title: "State",
			key: "state",
			dataIndex: "region_code",
			width: 50,
			align: "center",
			sorter: (a, b) => a.region_code.localeCompare(b.region_code),
			sortOrder: sortedInfo.columnKey === 'state' && sortedInfo.order,
		},
		{
			title: "Students",
			key: "students",
			dataIndex: "student_count",
			width: 100,
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				console.log("record",record);
				let occupation_type = 0;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative">{record.student_count}</span></a></span>;
			},
			sorter: (a, b) => a.student_count - b.student_count,
			sortOrder: sortedInfo.columnKey === 'students' && sortedInfo.order,
		},
		{
			title: "Professionals",
			key: "professionals",
			dataIndex: "professional_count",
			width: 100,
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				let occupation_type = 1;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative" style={{ width: "75px" }}>{record.professional_count}</span></a></span>;
			},
			sorter: (a, b) => a.professional_count - b.professional_count,
			sortOrder: sortedInfo.columnKey === 'professionals' && sortedInfo.order,
		},
		{
			title: "Faculty",
			key: "professors",
			dataIndex: "faculty_count",
			width: 100,
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				let occupation_type = 2;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative" style={{ width: "65px" }}>{record.faculty_count}</span></a></span>;
			},
			sorter: (a, b) => a.faculty_count - b.faculty_count,
			sortOrder: sortedInfo.columnKey === 'professors' && sortedInfo.order,
		},
		{
			title: "Other",
			key: "other",
			dataIndex: "other_count",
			width: 100,
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				let occupation_type = 3;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative" style={{ width: "35px" }}>{record.other_count}</span></a></span>;
			},
			sorter: (a, b) => a.other_count - b.other_count,
			sortOrder: sortedInfo.columnKey === 'other' && sortedInfo.order,
		},
		{
			title: "Total",
			key: "total",
			dataIndex: "total_count",
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				return <span className="p-relative" style={{ 
					width: "35px",
					color: "black" 
				}}>{record.total_count}</span>;
			},
			width: 50,
			sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
		}
	];
	// const onclickPreviousYear = () =>{
	// 	setStartDate(constants.lastYearStartDate);
	// 	setEndDate(constants.lastYearEndDate);
	// 	form.setFieldsValue({
	// 		fromDate:moment(constants.lastYearStartDate),
	// 		toDate:moment(constants.lastYearEndDate)
	// 	});
	// };
	const onChangeDateFilter = (e) => {
		registrationFilter(e,setStartDateEndDate);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						{payload&&(
							<Breadcrumb.Item><a onClick={() => {
								history.push({ 
									pathname: "/registration-by-university",
									state : {
										sd : startDate, 
										ed : endDate 
									} 
								});
							}}>{constants.Top10University}</a></Breadcrumb.Item>
						)}
						<Breadcrumb.Item>{constants.RegistrationUSUniversity}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">{<BankOutlined className="icon-lg"/>}{" "}<span>{constants.RegistrationUSUniversity}</span></span>} extra={
								<Form layout="horizontal" form = {form}>
									<Space>
										{/* <Form.Item className="label-default mb-0" name = "previousYear">
											<Button type = "link" onClick ={onclickPreviousYear}>Previous Years</Button>
										</Form.Item> */}
										<Form.Item className="label-default mb-0" label="From:" name = "fromDate">
											<DatePicker onChange={from} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0" label="To:" name="toDate">
											<DatePicker onChange={to} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0" name ="filterValue">
											<Select defaultValue="0" style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
												<Option value="0">This Year</Option>
												<Option value="7">Last Year</Option>
												<Option value="1">This Month</Option>
												<Option value="2">Last Month</Option>
												<Option value="3">Last 3 Months</Option>
												<Option value="4">This Month (Last Year)</Option>
												<Option value="5">Last 3 Months (Last Year)</Option>
												<Option value="6">Previous Years</Option>
											</Select>
										</Form.Item>
									</Space>
								</Form>
							}>
								<Row>
									<Col xs={24} sm={24} md={24} lg={24}>
										<Table className="gap-table"
											onChange = {handleChange} 
											columns={column}
											dataSource={tableData.length > 0 ? tableData:[]}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													<Table.Summary.Row style = {{ fontWeight:500 }}>
														<Table.Summary.Cell index={0}><span>Total</span></Table.Summary.Cell>
														<Table.Summary.Cell index={1}><span></span></Table.Summary.Cell>
														<Table.Summary.Cell index={2} className='td-right' ><span className="p-relative">{studentCount}</span></Table.Summary.Cell>
														<Table.Summary.Cell index={3} className='td-right' ><span className="p-relative" style={{ width: "90px" }}>{professionalCount}</span></Table.Summary.Cell>
														<Table.Summary.Cell index={4} className='td-right' ><span className="p-relative" style={{ width: "70px" }}>{professorsCount}</span></Table.Summary.Cell>
														<Table.Summary.Cell index={5} className='td-right' ><span className="p-relative" style={{ width: "35px" }}>{othersCount}</span></Table.Summary.Cell>
														<Table.Summary.Cell index={6} className='td-right'><span className="p-relative" style={{ width: "35px" }}>{totalCount}</span></Table.Summary.Cell>
													</Table.Summary.Row>
												</Table.Summary>
											)}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>;
			</div>
		</>
	);
};
export default RegistrationInUsUniversities;