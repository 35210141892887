/*
File Name: ResultPage.js
Author: Mevito Gonsalves
Modified On: 7/1/2022
Description: Displaying results of the tests.
API's used:
1) /score_card/${scoreId}?type=result
*/
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table, Space, Avatar,Spin,Tooltip,Typography, message } from 'antd';
import { FileSearchOutlined,  HistoryOutlined, ShareAltOutlined,DownloadOutlined,MailOutlined,RollbackOutlined } from '@ant-design/icons';
import '../../styles/test.css';
import constants from "../../constants/constants";
import { fetchApi } from "../../services/api";
import { useHistory } from "react-router";
import{ COOKIE,getCookie } from "../../services/cookie";
import { getBadgeIcon } from "../../globalFunctions/GlobalFunctions";
import Loader from "../Common/Cred/Loader";
const { Text } = Typography;
const ResultPage = (props) => {
	const history = useHistory();
	// eslint-disable-next-line react/prop-types
	const [resultTypeFull,setResultTypeFull]=useState(props&&props.location&&props.location.state?props.location.state.resultTypeFul?props.location.state.resultTypeFull:true:sessionStorage.getItem("resultTypeFull"));
	// eslint-disable-next-line react/prop-types
	const [testCategory,]=useState(props&&props.location&&props.location.state?props.location.state.testCategory?props.location.state.testCategory:"PIET":sessionStorage.getItem("resultTestCategory"));
	// eslint-disable-next-line react/prop-types
	const [scoreId,]=useState(props&&props.location&&props.location.state?props.location.state.scoreCardId?props.location.state.scoreCardId:0:sessionStorage.getItem("resultScorecardId"));
	// eslint-disable-next-line react/prop-types
	const [isTopic,setIsTopic]=useState(false);
	const [ffpTopicList,setFfpTopicList]=useState([]);
	const [pietTopicList,setPietTopicList]=useState([]);
	const [saedTopicList,setSaedTopicList]=useState([]);
	const [ffpTopicListData,setFfpTopicListData]=useState([]);
	const [pietTopicListData,setPietTopicListData]=useState([]);
	const [saedTopicListData,setSaedTopicListData]=useState([]);
	const [resultData,setResultData]=useState([]);
	const [isPass,setIsPass]=useState(false);
	const [numberSufix,setNumberSufix]=useState("st");
	const [loader,setLoader]=useState(false);
	const [shareScoreMinPercentage,]=useState(62);
	let locale = { emptyText: ' ', };
	const columns = [
		{
			title: 'Topic',
			dataIndex: 'topic' 
		},
		{
			title: 'Correct',
			className: 'column-money',
			align: 'center',
			width:'80px' ,
			render:(record)=>{
				return <span className="ans-display">{record.correct}/{record.questions}</span>;
			}
		},
		{
			title: 'Incorrect',
			className: 'column-money',
			align: 'center',
			width:'90px' ,
			render:(record)=>{
				let tempValue=record.questions-record.correct;
				if(tempValue===0){
					return <span>-</span>;
				}else{
					return <Tooltip title={constants.ReviewLinkToolTipMsg}><Button type="link" onClick={()=>{reviewAllAnswers(record.category_id,record.topic_id);}}>{record.questions-record.correct}/{record.questions}</Button></Tooltip>;
				}
			}
		},
	];
	useEffect(() => {
		if(props&&props.location&&props.location.state){
			fetchTestResult(props.location.state.isReview?props.location.state.isReview:"f");
			if(props.location.state.isTopic){
				setIsTopic(props.location.state.isTopic);
			}
			setResultTypeFull(props.location.state.resultTypeFull);
			sessionStorage.setItem("resultTypeFull",props.location.state.resultTypeFull);
			sessionStorage.setItem("resultTestCategory",props.location.state.testCategory);
			sessionStorage.setItem("resultScorecardId",props.location.state.scoreCardId);
			sessionStorage.setItem("resultIsTopic",props.location.state.isTopic);
			sessionStorage.setItem("isReview",props.location.state.isReview?props.location.state.isReview:"f");
			if(sessionStorage.getItem("fullLengthTestScorecadId")!==null){
				sessionStorage.removeItem("fullLengthTestScorecadId");
			}
		}else{
			fetchTestResult(sessionStorage.getItem("isReview"));
			setIsTopic(sessionStorage.getItem("resultIsTopic"));
			setResultTypeFull(sessionStorage.getItem("resultTypeFull"));
		}
	}, []);
	useEffect(()=>{
		return () => {
			if (history.action === "POP") {
				let userId= getCookie(COOKIE.UserId);
				if(userId){
					history.push("/home");
				}
			}
		};
	},[history]);
	//To set fecthed data
	const setFetchedData=(fetchedDataValues)=>{
		setResultData(fetchedDataValues);
		if(fetchedDataValues.categories){
			if(fetchedDataValues.categories.FPP){
				setFfpTopicList(fetchedDataValues.categories.FPP);
				setFfpTopicListData(fetchedDataValues.categories.FPP.topics);
			}
			if(fetchedDataValues.categories.PIET){
				setPietTopicList(fetchedDataValues.categories.PIET);
				setPietTopicListData(fetchedDataValues.categories.PIET.topics);
			}
			if(fetchedDataValues.categories.SAED){
				setSaedTopicList(fetchedDataValues.categories.SAED);
				setSaedTopicListData(fetchedDataValues.categories.SAED.topics);
			}
		}
		if(fetchedDataValues.test_result && fetchedDataValues.test_result.toLocaleLowerCase()==="pass"){
			setIsPass(true);
		}
	};
	//To fetch test history data 
	const fetchTestResult = (isReviewValue) => {
		if(scoreId){
			let resultApilink=`/score_card/${scoreId}?type=result`;
			let resultReviewApilink=`/score_card/${scoreId}?type=result&review=1`;
			fetchApi(isReviewValue==="t"?resultReviewApilink:resultApilink, "get").then((res) => {
				setLoader(true);
				if (res.code===200) {
					setFetchedData(res.data);
					let numSufix=returnInitials(res.data.test_attempt);
					setNumberSufix(numSufix);
					setLoader(false);
				} 
				setLoader(false);
			});
		}else{
			let queryString = window.location.href;
			var routeValue = queryString.substring(queryString.lastIndexOf('/') + 1);
			if(routeValue==="result-page"){
				history.push("/my-tests");
			}else{
				history.push("/home");
			}
		}
	};
	const returnInitials=(key)=>{
		let tempKey=parseInt(key);
		switch (tempKey) {
		case 1:	
			return "st";
		case 2:	
			return "nd";
		case 3:	
			return "rd";
		}
	};
		//To download score card
	const downloadScoreCard = () => {
		window.open(constants.ApiUrl+'/download/pdf/'+scoreId);
	};
	//To send result email
	const sendEmailToMe=()=>{
		fetchApi(`/send_email/${scoreId}`,"post").then((res) => {
			if (res.code===200) {
				message.success(res.message);
			} else{
				message.error(res.message);
			}
		});
	};
	//Function to redirect to Review test.
	const reviewAllAnswers=(categoryId,topicId)=>{
		if(scoreId){
			setcategoryDetailsredirect(categoryId,topicId);
		}
	};
	//Function to set category details
	const setcategoryDetailsredirect=(categoryId,topicId)=>{
		let categoryDetails={};
		categoryDetails.scoreCardId=scoreId;
		categoryDetails.review="short_length";
		categoryDetails.ans_inc="incorrect";
		categoryDetails.testTitle="";
		categoryDetails.attempt="";
		categoryDetails.totalTimeTaken="";
		categoryDetails.totalCorrectAnswers="";
		categoryDetails.totalTestQuestions="";
		categoryDetails.testTakenDate="";
		categoryDetails.testTakenTime="";
		if(resultData){
			if(resultData.test_length){
				categoryDetails.testTitle=resultData.test_length;
			}
			if(resultData.test_attempt){
				categoryDetails.attempt=resultData.test_attempt;
			}
			if(resultData.total_time_taken){
				categoryDetails.totalTimeTaken=resultData.total_time_taken;
			}
			if(resultData.total_correct_questions){
				categoryDetails.totalCorrectAnswers=resultData.total_correct_questions;
			}
			if(resultData.test_total_questions){
				categoryDetails.totalTestQuestions=resultData.test_total_questions;
			}
			if(resultData.test_taken_date){
				categoryDetails.testTakenDate=resultData.test_taken_date;
			}
		}
		if(categoryId){
			categoryDetails.categoryId=Number(categoryId);
		}
		if(topicId){
			categoryDetails.topicId=Number(topicId);
		}
		history.push({
			pathname:"/review-incorrect",
			state:{ categoryDetails:categoryDetails } 
		});	
	};
	//To redirect to share score
	const redirectShareScore=()=>{
		history.push({
			pathname:"/share-score",
			state:
		{
			scoreCardId:scoreId,
			resultObj:resultData,
			isResultPage:true ,
			resultTypeFull:resultTypeFull,
			testCategory:testCategory,
			isTopic:isTopic
		} 
		});
	};
	//To return table header
	const getResultTableHeader=(categoryValue)=>{
		return (
			<p>{categoryValue&&categoryValue.toLocaleLowerCase()===constants.CategoryShortFPP.toLocaleLowerCase()?
				constants.CategoryFPP:
				categoryValue&&categoryValue.toLocaleLowerCase()===constants.CategoryShortPIET.toLocaleLowerCase()?
					constants.CategoryPIET:
					constants.CategorySAED	
			}</p>
		);
	};
	const getTotalCorrect=(totalCorrectValue)=>{
		return (
			totalCorrectValue&&totalCorrectValue.toLocaleLowerCase()===constants.CategoryShortFPP.toLocaleLowerCase()?
				ffpTopicList?ffpTopicList.total_correct:0:
				totalCorrectValue&&totalCorrectValue.toLocaleLowerCase()===constants.CategoryShortPIET.toLocaleLowerCase()?
					pietTopicList?pietTopicList.total_correct:0:
					saedTopicList?saedTopicList.total_correct:0	
		);
	};
	const getTotalQuestions=(totalQuestionValue)=>{
		return(
			totalQuestionValue&&totalQuestionValue.toLocaleLowerCase()===constants.CategoryShortFPP.toLocaleLowerCase()?
				ffpTopicList?ffpTopicList.total_questions:0:
				totalQuestionValue&&totalQuestionValue.toLocaleLowerCase()===constants.CategoryShortPIET.toLocaleLowerCase()?
					pietTopicList?pietTopicList.total_questions:0:
					saedTopicList?saedTopicList.total_questions:0
		);
	};
	//To get level details
	const getLevelDetails=()=>{
		return(
			<>
				{resultData.is_tutorials===true?null:
					<>
						<div className="pr-3 space-between">
							<div className="pr-3">
								<p>{constants.Levels[resultData&&
								resultData.level&&
								resultData.level.current_level&&
								resultData.level.current_level.level]}
								</p>
								<p>{resultData&&resultData.level&&resultData.level.current_level&&resultData.level.current_level.designation}</p>
							</div>
							{getBadgeIcon(resultData&&resultData.level&&resultData.level.current_level&&resultData.level.current_level.level,"","score-icon")}
						</div>
						<div className="fs-20">
							<p>You earned <span className="blue-2">{resultData.points_earned} points</span> on this test attempt </p>
							<p>Total points earned : <span className="blue-2">{resultData.total_points}</span> </p>
							{!resultData.is_next_level?
								<>
									{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.current_level.level>=1?
										resultData&&resultData.level&&resultData.level.next_level&&resultData.level.current_level.level>=5?
											<p>{constants.Level6Message}</p>:
											<p><span className="blue-2">{resultData.points_required_level}</span> {constants.ReachLevelText}{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.next_level.level+", "+resultData.level.next_level.designation}</p>:
										<p><span>{constants.ReachLevel0}{", "}</span>{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.next_level.designation}</p>									}
								</>
								:<p>Congratulations! You reached <span className="blue-2">Level {resultData&&resultData.level&&resultData.level.current_level.level}</span></p>
							}
						</div>
					</>
				}
			</>
		);
	};
	// to display user details
	const displayUserDetails=()=>{
		return (
			<>
				<Col span={24}>
					<Space size="middle" wrap>
						<Avatar size={64} className="avatar mr-2 my-auto" >{resultData.name_short_inital}</Avatar>
						<div className="user-details">
							<p className="user-name-result">{resultData.user_name}</p>
							<Space wrap>
								<p className="test-name">{resultData.is_tutorials===true?"Tutorial":"Test"} Results of {resultData.test_length} - {resultData.test_total_questions} questions </p>
								<p><Text type="secondary" className="test-name">({resultData.test_taken_date})</Text></p>
							</Space>
							<div>
								{resultData.is_tutorials===true?null:
									<Space wrap>
										{resultData.total_correct_questions===resultData.test_total_questions?null:
											<Button className="btnDb" onClick={()=>{reviewAllAnswers();}} ><FileSearchOutlined/>{constants.ReviewIncorrectBtn}</Button>}
										<Button className="btnLb d-sm-block" onClick={downloadScoreCard}><DownloadOutlined/>{constants.DownloadResultBtn}</Button>
										{resultData.is_disable_email===1?null:
											<Button className="btnLighter" onClick={sendEmailToMe}><MailOutlined/>{constants.EmailResultsBtn}</Button>
										}
										<Button className="purple-bg"  onClick={()=>{history.push("/my-tests");}}><RollbackOutlined/>{constants.ReturnToMyTests}</Button>
									</Space>
								}
							</div>
						</div>
					</Space>
				</Col>
			</>
		);
	};
	const resultBottomSection=()=>{
		return(
			<>
				{resultData.is_timed==="t"&&resultData.is_tutorials!==true?
					<><span className="correct-answer mr-1">You answered {resultData.total_correct_questions}/{resultData.test_total_questions} questions correctly in {resultData.total_time_taken}</span><Tooltip title="Timed"><HistoryOutlined/></Tooltip></>
					:
					<p className="correct-answer">You answered {resultData.total_correct_questions}/{resultData.test_total_questions} questions correctly.</p>
				}
				{resultData.is_timed==="t"&&resultData.is_tutorials!==true?
					<p className="avg-time">Average time per question : {resultData.average_time}</p>
					:null}
			</>
		);
	};
	//get table headers
	const fppHeader=()=>{
		return(
			<div className="table-title w-100">
				<p>{constants.CategoryFPP}</p>
				{ffpTopicList.length===0?
					<p className="title-two">No questions displayed</p>
					:<p className="title-two">{ffpTopicList?ffpTopicList.total_correct:0}/{ffpTopicList?ffpTopicList.total_questions:0} questions answered correctly</p>
				}
			</div>
		);
	};
	const saedHeader=()=>{
		return(
			<div className="table-title w-100">
				<p>{constants.CategorySAED}</p>
				{saedTopicListData.length===0?
					<p className="title-two">No questions displayed</p>
					:<p className="title-two">{saedTopicList?saedTopicList.total_correct:0}/{saedTopicList?saedTopicList.total_questions:0} questions answered correctly</p>
				}													
			</div>
		);
	};
	const pietHeader=()=>{
		return(
			<div className="table-title w-100">
				<p>{constants.CategoryPIET}</p>
				{pietTopicListData.length===0?
					<p className="title-two">No questions displayed</p>
					:<p className="title-two">{pietTopicList?pietTopicList.total_correct:0}/{pietTopicList?pietTopicList.total_questions:0} questions answered correctly</p>
				}
			</div>
		);
	};
	//To display result tables
	const returnMultiTableLayout=()=>{
		return(
			<>
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24 
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 40 
				}]} >
					<Col span={24}>
						<Row className="user-detail-wrapper">
							{displayUserDetails()}
						</Row>
					</Col>
					<Col span={24}>
						{resultData.is_tutorials===true?null:
							<div className="justify-between score-section score-bkg">
								{isPass?
									<Space size="large" wrap>
										<span className="pass-score">PASS : {resultData.test_percentage}%</span>
										{shareScoreMinPercentage<=resultData.test_percentage &&(resultData.test_attempt==="1"||resultData.test_attempt===1)&&resultData.is_tutorials!==true?
											<span><Button className="gold-btn share-score" 
												onClick={()=>{redirectShareScore();}}
												// eslint-disable-next-line indent
													> <ShareAltOutlined/>{constants.ShareScoreBtn}</Button></span>
											:null}
									</Space>:
									<div>
										<span>DID NOT PASS : {resultData.test_percentage}%</span>
									</div> 
								}
								<span className="attempts">[{resultData.test_attempt}{numberSufix} Attempt - {resultData.attempt_remaining} {resultData.attempt_remaining===1?"attempt":"attempts"} remaining]</span>
							</div>
						}
						<Row gutter={[8, 8]} className="w-100 result-table-container">
							<Col span={24} lg={8} >
								<Table 
									locale={locale}
									columns={columns} 
									dataSource={ffpTopicListData} 
									bordered
									title={() => {
										return(
											<>
												{fppHeader()}
											</>
										);
									}}
									className="customTable grey-custom-table resut-table"
									pagination={false}
								/>
							</Col>
							<Col span={24} lg={8}>
								<Table 
									locale={locale}
									columns={columns} 
									dataSource={saedTopicListData} 
									bordered
									//   title={() => '[SAED] Screening, Assessment, Evaluation and Diagnosis'}
									title={() => {
										return(
											<>
												{saedHeader()}
											</>
										);
									}}
									//   title={() => this.handleTitleSAED()}
									className="customTable grey-custom-table resut-table"
									//   rowSelection={{
									//       type: selectionType
									//   }}
									pagination={false}
								/>
							</Col>
							<Col span={24} lg={8}>
								<Table 
									locale={locale}
									columns={columns} 
									dataSource={pietTopicListData} 
									bordered
									//   title={() => this.handleTitlePIET()}
									className="customTable grey-custom-table resut-table"
									title={() => {
										return(
											<>
												{pietHeader()}
											</>
										);
									}}
									pagination={false}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs={24} sm={24} md={24}>
						<Row className="result-bottom-section">
							<Col md={16} className="mb-3 fs-20">
								{resultBottomSection()}
							</Col>
							<Col className="justify-end-middle" md={8}>
								{getLevelDetails()}
							</Col>
						</Row>
					</Col>
				</Row> 
			</>
		);
	};
	const returnSingleTableLayout=()=>{
		return (
			<>
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24 
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 40 
				}]} >
					<Col span={24}>
						<Row className="user-detail-wrapper">
							{displayUserDetails()}
						</Row>
					</Col>
					<Col xs={24} sm={24} md={24}>
						{resultData.is_tutorials===true?null:
							<div className="justify-between score-section score-bkg">
								{isPass?
									<Space size="large" wrap>
										<span className="pass-score">PASS : {resultData.test_percentage}%</span>
										{shareScoreMinPercentage<=resultData.test_percentage &&(resultData.test_attempt==="1"||resultData.test_attempt===1)&&resultData.is_tutorials!==true?
											<span><Button className="gold-btn share-score" 
												onClick={()=>{redirectShareScore();}}
											// eslint-disable-next-line indent
													> <ShareAltOutlined/>{constants.ShareScoreBtn}</Button></span>
											:null}
									</Space>:
									<div>
										<span>DID NOT PASS : {resultData.test_percentage}%</span>
									</div> }
								<span className="attempts">[{resultData.test_attempt}{numberSufix} Attempt - {resultData.attempt_remaining} {resultData.attempt_remaining===1?"attempt":"attempts"} remaining]</span>
							</div>
						}
						<Row gutter={{
							xs: 8,
							sm: 16,
							md: 8,
							lg: 8 
						}} className="w-100 result-table-container">
							<Col span={24} lg={24}>
								<Table 
									locale={locale}
									columns={columns} 
									dataSource={testCategory&&testCategory.toLocaleLowerCase()===constants.CategoryShortFPP.toLocaleLowerCase()?
										ffpTopicListData:
										testCategory&&testCategory.toLocaleLowerCase()===constants.CategoryShortPIET.toLocaleLowerCase()?
											pietTopicListData:
											saedTopicListData	
									} 
									bordered
									title={() => {
										return(
											<div className="table-title w-100">
												{getResultTableHeader(testCategory)}
												<p className="title-two">
													{getTotalCorrect(testCategory)}
																/{getTotalQuestions(testCategory)} questions answered correctly
												</p>
											</div>
										);
									}}
									// title={()=> '[FPP] Foundation and Professional Practice'} 
									className="customTable grey-custom-table"
									pagination={false}
								/>      
							</Col>
						</Row>
					</Col>
					<Col xs={24} sm={24} md={24}>
						<Row className="result-bottom-section">
							<Col md={16} className="mb-3 fs-20">
								{resultBottomSection()}
							</Col>
							<Col className="justify-end-middle" md={8}>
								{getLevelDetails()}
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	};
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="p-4 resultPage">
					{resultTypeFull===false||resultTypeFull==="false"?
						<>{returnMultiTableLayout()}</>
						:
					/* result page single category */
						<>{returnSingleTableLayout()}</>
					}
				</div>
			</Spin>
		</>
	);
};
export default ResultPage;			
