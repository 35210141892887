/*
File Name: AnalysisMenu.js
Author: Dahlin Carneiro
Modified On: 27/10/2022
Description:Analysis Menu page.
*/
import { Row, Col, Card, BackTop, Space, Divider } from "antd";
import { CopyFilled, TeamOutlined, ProjectFilled, FileTextOutlined, ContainerOutlined, FileUnknownOutlined, BarChartOutlined, ScheduleOutlined, SolutionOutlined, HourglassOutlined, FlagFilled, StarFilled, BankFilled, SearchOutlined, AreaChartOutlined, QuestionCircleFilled, FileDoneOutlined, PieChartOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import "../../../styles/admindashboard.css";
import { useEffect } from "react";
const AnalysisMenu = () => {
	const history = useHistory();
	useEffect(() => {
		localStorage.removeItem("propvalues");
	}, []);
	return (
		<div className="color-crd-loop container">
			<BackTop/>
			<Divider>Analysis</Divider>
			<Card title={<Space><SolutionOutlined/>Registrations by</Space>} className="mb-3 gray-bg" size="small">
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 30
				}]}>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/total-registrations");}}>
							<Space direction="vertical">
								<HourglassOutlined className="icon-xlg"/>
								<span>Period</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/registration-by-country");}}>
							<Space direction="vertical">
								<FlagFilled className="icon-xlg"/>
								<span>Country</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/registration-in-us");}}>
							<Space direction="vertical">
								<StarFilled className="icon-xlg"/>
								<span>US States</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/registration-by-university");}}>
							<Space direction="vertical">
								<BankFilled className="icon-xlg"/>
								<span>University</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/registration-by-roles");}}>
							<Space direction="vertical">
								<TeamOutlined className="icon-xlg"/>
								<span>Role</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick={()=>{history.push({
							pathname:"/registered-students",
							state:{ isSearch:true } 
						});}}>
							<Space direction="vertical">
								<SearchOutlined className="icon-xlg"/>
								<span>Search</span>
							</Space>
						</Card>          
					</Col>
				</Row>
			</Card>
			<Card title={<Space><FileTextOutlined/>Tests</Space>} className="mb-3 gray-bg" size="small">
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 30
				}]}>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/total-test-taken");}}>
							<Space direction="vertical">
								<FileDoneOutlined className="icon-xlg"/>
								<span>Total Tests Taken</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/test-scores");}}>
							<Space direction="vertical">
								<AreaChartOutlined className="icon-xlg"/>
								<span>Tests Taken</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/test-results");}}>
							<Space direction="vertical">
								<ContainerOutlined className="icon-xlg"/>
								<span>Test Results</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/scorecards");}}>
							<Space direction="vertical">
								<PieChartOutlined className="icon-xlg"/>
								<span>Score Percentage</span>
							</Space>
						</Card>          
					</Col>
				</Row>
			</Card>
			<Card title={<Space><FileTextOutlined/>Tutorials</Space>} className="mb-3 gray-bg" size="small">
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 30
				}]}>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{
							history.push("/total-tutorials-taken");
						}}>
							<Space direction="vertical">
								<FileDoneOutlined className="icon-xlg"/>
								<span>Total Tutorials Taken</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick={()=>{history.push({
							pathname:"/tutorial-scores",
							state:{ isSearch:true } 
						});}}>
							<Space direction="vertical">
								<AreaChartOutlined className="icon-xlg"/>
								<span>Tutorials Taken</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/tutorial-result");}}>
							<Space direction="vertical">
								<ContainerOutlined className="icon-xlg"/>
								<span>Tutorial Results</span>
							</Space>
						</Card>          
					</Col>
				</Row>
			</Card>
			<Card title={<Space><CopyFilled className="flash-ico"/>Flashcards</Space>} className="mb-3 gray-bg" size="small">
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 30
				}]}>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/total-flashcard-taken");}}>
							<Space direction="vertical">
								<ContainerOutlined className="icon-xlg"/>
								<span>Total Flashcards Taken</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/flashcard-details");}}>
							<Space direction="vertical">
								<CopyFilled className="icon-xlg flash-ico"/>
								<span>Flashcards Taken</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/flashcard-result");}}>
							<Space direction="vertical">
								<ContainerOutlined className="icon-xlg"/>
								<span>Flashcard Results</span>
							</Space>
						</Card>          
					</Col>
				</Row>
			</Card>
			<Card title={<Space><BarChartOutlined/>Others</Space>} className="mb-3 gray-bg" size="small">
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 30
				}]}>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/feedback-analysis");}}>
							<Space direction="vertical">
								<ScheduleOutlined className="icon-xlg"/>
								<span>Feedback</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/score-compare");}}>
							<Space direction="vertical">
								<ProjectFilled className="icon-xlg"/>
								<span>Student Stats</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/question-rating");}}>
							<Space direction="vertical">
								<FileUnknownOutlined className="icon-xlg"/>
								<span>Question Ratings</span>
							</Space>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={4} lg={4} className="slp-menu-card text-center">
						<Card hoverable onClick = {() =>{history.push("/daily-qod");}}>
							<Space direction="vertical">
								<QuestionCircleFilled className="icon-xlg"/>
								<span>Daily QOD</span>
							</Space>
						</Card>          
					</Col>
				</Row>
			</Card>
		</div>
	);
};
export default AnalysisMenu;