import { Col, Row, Button, Select, Table, Form, Tooltip } from "antd";
import "../../styles/test.css";
import constants from "../../constants/constants";
import React, { useState, useEffect } from "react";
import { fetchApi } from "../../services/api";
import{ COOKIE,getCookie } from "../../services/cookie";
import { useHistory } from "react-router";
const { Option } = Select;
const TopicTutorials = () => {
	const history = useHistory();
	const [topicList, setTopicList] = useState([]);
	const [topicAttemptedData, setTopicAttemptedData] = useState([]);
	const [hideTestTable,setHideTestTable]=useState(false);
	const [selectedTopicIdObject,setSelectedTopicIdObject]=useState([]);
	const userId = getCookie(COOKIE.UserId);
	const columns = [
		{
			title: "Tutorials attempted (click Topic to view again)",
			key: "tutorials_attempted",
			sortDirections:['descend', 'ascend', 'descend'],
			sorter: (a, b) => a.tutorials_attempted.localeCompare(b.tutorials_attempted),
			render: (record) => {
				return (<Tooltip title={constants.ViewAgain}>
					<Button type="link" className="table-link-btn" onClick={()=>{handleReviewTutorial(record);}}>
						{record&&record.tutorials_attempted?record.tutorials_attempted:""}
					</Button>
				</Tooltip>
				);}
		},
		{
			title: "On",
			render: (record) => {
				return (
					<>
						{record.points!==null?
							<Tooltip title={record.points?record.points+(record.points>1?" points earned":" point earned"):""}>
								<Button type="link" className="table-link-btn" onClick={()=>{handleReviewTutorial(record);}}>
									{record&&record.on?record.on :""}
								</Button>
							</Tooltip>
							:
							<span className="orange">{constants.InProgress}</span>
						}
					</>
				);
			},
			key: "on",
			sortDirections:['descend', 'ascend', 'descend'],
			sorter: (a, b) =>new Date(a.on) -new Date(b.on),
			// render: text=>new Date(text).toLocaleDateString(),
		},
	];
	useEffect(() => {
		fetchTopicRemainingList();
		fetchTopicListData();
	}, []);
	const handleReviewTutorial=(tutorial)=>{
		let reviewTutorial={};
		reviewTutorial.scoreCardId=tutorial.key;
		reviewTutorial.topicId=tutorial.topic_id;
		reviewTutorial.review="tutorial";
		reviewTutorial.type="topic";
		history.push({
			pathname:"/review-incorrect",
			state:{ reviewTutorial:reviewTutorial } 
		});
	};
	//To fetch topics with remaining attempts 
	const fetchTopicRemainingList = () => {
		fetchApi(`/topics?stud_id=${userId}&type=tutor`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				let filterArray=res.data.filter(el=>el.is_test_taken!==true);
				setTopicList(filterArray);
			} else {
				setTopicList([]);
			}
		});
	};
	//To fetch tutorials attempted data
	const fetchTopicListData = () => {
		fetchApi(`/tutorials/history?type=topic&student_id=${userId}`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setTopicAttemptedData(res.data);
				setHideTestTable(true);
			} else {
				setTopicAttemptedData([]);
				setHideTestTable(false);
			}
		});
	};
	//To set values on topic changed
	const onTopicChange=(value,option)=>{
		let selectedTopicId={};
		if(value){
			selectedTopicId.id=value;
			selectedTopicId.topicName=option.children;
			setSelectedTopicIdObject(selectedTopicId);
		}
	};
	//To redirect and start test
	const onStartTest=()=>{
		let topicDetails={};
		if(selectedTopicIdObject){
			topicDetails.topicId=selectedTopicIdObject.id;
			topicDetails.topicName=selectedTopicIdObject.topicName;
			topicDetails.type="tutor";
			topicDetails.count=5;
			topicDetails.isTimed=false;
			history.push({
				pathname:"/topic-tutorial",
				state:{ topicDetails:topicDetails } 
			});
		}
	};
	return (
		<div className="p-4 container topic-tutorial-container">
			<Row gutter={[{
				xs: 0,
				sm: 0,
				md: 24,
				lg: 24 
			}, {
				xs: 24,
				sm: 24,
				md: 24,
				lg: 40 
			}]} >
				<Col span={24} >
					<h3 className="mainHeading">Topic Tutorials</h3>
					<p className="subHeading">{constants.TopicTutorialSubheading}</p>
				</Col>
				<Col span={24} >
					<Form onFinish={onStartTest}>
						<Row gutter={[16, 16]} justify="center" align="top" className="fs-0">
							<Col sm={12} xs={20}>
								<Form.Item
									name="topics"
									label="Topics"
									rules={[{
										required: true,
										message: 'Select Topic' 
									}]}
								>
									<Select placeholder="Select Topic" onChange={onTopicChange}>
										{topicList.map((item)=>{
											return(
												<Option value={item.topic_id} key={item.topic_id}>{item.topic_title}</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item>
									<Button type="primary" htmlType="submit" className="start-btn btnDb" >{constants.StartBtn}</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
				<Col sm={24} xs={24}
					lg={{
						span: 16, 
						offset: 4,
					}}
				>
					{hideTestTable?
						<Table className="customTable topic-table" dataSource={topicAttemptedData} columns={columns} bordered pagination={false}/>
						:null}
				</Col>
			</Row>
		</div>
	);
};
export default TopicTutorials;

