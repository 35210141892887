import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { fetchApi } from "../../../services/api";
const TestResultJsonInfo = () => {
	const [jsondata, setJsondata] = useState();
	useEffect(() => {
		let queryString = window.location.href;
		var token = queryString.substring(queryString.lastIndexOf('?') + 1);
		fetchJdondata(token);
	}, []);
	const fetchJdondata =(token)=>{
		fetchApi(`/scorecard-details?score_card_id=${token}`, "get").then((res) => {
			if (res && res.data) {
				setJsondata(JSON.stringify(res.data));
			}else{
				setJsondata([]);
			}
		});
	};
	return (
		<>
			<p>{jsondata}</p>
		</>
	);
};
export default TestResultJsonInfo;
