import React,{ useEffect,useState } from 'react';
import constants from '../../../constants/constants';
import { fetchAdminApi,fetchApi } from "../../../services/api";
import moment from "moment";
import { Breadcrumb, Row, Col, Card, Form, Space, Table,DatePicker,BackTop,Select,Tooltip,Radio, Spin } from 'antd';
import { useHistory } from "react-router";
import Loader from '../../Common/Cred/Loader';
const QuestionRating =()=> {
	const history = useHistory();
	const [form] = Form.useForm();
	const { Option } = Select;
	const [topicOptionId, setTopicOptionId] = useState([]);
	const [categoryOptionId, setCategoryOptionId] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [testTypeValue, setTestTypeValue] = useState(1);
	const [displayValue, setDisplayValue] = useState(0);
	const [listofQuestion, setListofQuestion] = useState(1);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [category, setCategory] = useState("");
	const [topic, setTopic] = useState("");
	const [topicTitle, setTopicTitle] = useState("");
	const [date, setDate] = useState("");
	const [datemessage, setdatemessage] = useState({
		stard : "",
		endd : ""
	});
	const [loader,setLoader]=useState(false);
	const onChangeMonth = (_date, dateString) => {
		let date1=moment(dateString).format("MM/YYYY");
		setDate(dateString);
		let month = date1.slice(0, 2);
		let year = date1.slice(-4);
		let	sDate = moment([year, month - 1, 1]).format("YYYY-MM-DD");
		const daysInMonth = moment(sDate).daysInMonth();
		let eDate = moment(sDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");
		setStartDate(sDate);
		setEndDate(eDate);
		// setdatemessage({
		// 	...datemessage,
		// 	stard:sDate,
		// 	endd:sDate
		// });
	};
	const onchangeCategoryid = (value) => {
		setCategory(value);
	};
	const onchangeTopicId = (value,type) => {
		setTopic(value);
		setTopicTitle(type.title);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	useEffect(() => {
		fetchCategoryOption();
		fetchTopicOption();
		fetchQuestionListing();
	}, [date,listofQuestion,topic,category,testTypeValue,displayValue]);
	//fetching list of question-listing
	const fetchQuestionListing = () => {
		setLoader(true);
		let currentYear = `/student/rating-report?start_date=${constants.startDateOfTheYear}&end_date=${constants.endDateOfTheYear}&sort=${listofQuestion}`;
		let selectedMonths = `/student/rating-report?start_date=${moment(startDate).format("YYYY-MM-DD")}&end_date=${moment(endDate).format("YYYY-MM-DD")}&sort=${listofQuestion}`;
		if(category){
			currentYear += `&category=${category}`;
			selectedMonths += `&category=${category}`;
		}
		if(topic){
			currentYear += `&topic=${topic}`;
			selectedMonths += `&topic=${topic}`;
		}
		if(testTypeValue === 0 || testTypeValue === 1){
			currentYear += `&test_type=${testTypeValue}`;
			selectedMonths += `&test_type=${testTypeValue}`;
		}
		if(displayValue === 0 || displayValue === 1){
			currentYear += `&display=${displayValue}`;
			selectedMonths += `&display=${displayValue}`;
		}
		if(date){
			setdatemessage({
				...datemessage,
				stard:startDate,
				endd:endDate
			});
		}else{
			setdatemessage({
				...datemessage,
				stard:constants.startDateOfTheYear,
				endd:constants.endDateOfTheYear
			});
		}
		fetchApi(date? selectedMonths:currentYear, "get").then((res) => {
			if (res && res.data) {
				setTableData(res.data);
				setLoader(false);
			}
			else {
				setTableData([]);
				setLoader(false);
			}
		});
	};
	const similerSpan = (text,width) =>{
		if(text == 0 || text == null ){
			return <span style = {{ textAlign: 'center' }}>-</span>;
		}else{
			return <span style ={{ width :width }} className="p-relative">{text}</span>;
		}
	};
	const column = [
		{
			title: "Question",
			key: "question",
			dataIndex: "Question",
			render :(record) =>{
				let question =<span dangerouslySetInnerHTML={{ __html: record.slice(0, 50) + "..." }}/>;
				return <Space style = {{ width : "200px" }}>
					<Tooltip title={<div dangerouslySetInnerHTML={{ __html: record }}/>}>
						{question}
					</Tooltip>
				</Space>;
			},
			sorter: (a, b) => a.Question.localeCompare(b.Question),
		},
		{
			title: "Category",
			key: "category",
			dataIndex: "Categories",
			width: 200,
			align: "center",
			sorter: (a, b) => a.Categories.localeCompare(b.Categories),
		},
		{
			title: "Topic",
			key: "topic",
			width: 50,
			align: "center",
			render :(record) =>{
				return <Space>
					<Tooltip title={record.Topic}>
						{record.topic_abbreviation}
					</Tooltip>
				</Space>;
			},
			sorter: (a, b) => a.Topic.localeCompare(b.Topic),
		},
		{
			title: "No. of Times Displayed",
			key: "noftimesdisplayed",
			dataIndex: "no_of_times_displayed",
			width: 100,
			align: "center",
			render: (text) => {
				if(text != 0){
					return <span style ={{ width :"30px" }} className="p-relative">{text}</span>;
				}
			},
			className: 'td-right',
		},
		{
			title: "No. of Times Rated",
			key: "noftimesrated",
			dataIndex: "no_of_times_rated",
			width: 100,
			align: "center",
			render: (text) => {
				if(text == 0){
					return <span style ={{ 
						width :"25px",
						textAlign:"center"
					}}>-</span>;
				}else{
					return <span style ={{ width :"25px" }} className="p-relative">{text}</span>;
				}
			},
			className: 'td-right',
		},
		{
			title: "%",
			key: "percent",
			dataIndex: "rating_percent",
			width: 100,
			align: "center",
			render: (text) => {
				return similerSpan(text);
			},
			className: 'td-right',
		},
		{
			title: 'Rated as Difficult',
			children: [
				{
					title: 'Times',
					dataIndex: 'rated_as_difficult',
					key: 'times',
					width: 100,
					align: "center",
					render: (text) => {
						let width = "30px";
						return similerSpan(text,width);
					},
					className: 'td-right',
				},
				{
					title: '%',
					dataIndex: 'difficult_percent',
					key: 'persent',
					width: 100,
					align: "center",
					render: (text) => {
						return similerSpan(text);
					},
					className: 'td-right',
				},
			],
		},
		{
			title: 'Rated as Medium',
			children: [
				{
					title: 'Times',
					dataIndex: 'rated_as_medium',
					key: 'times',
					width: 100,
					align: "center",
					render: (text) => {
						let width = "30px";
						return similerSpan(text,width);
					},
					className: 'td-right',
				},
				{
					title: '%',
					dataIndex: 'medium_percent',
					key: 'persent',
					width: 100,
					align: "center",
					render: (text) => {
						return similerSpan(text);
					},
					className: 'td-right',
				},
			],
		},
		{
			title: 'Rated as Easy',
			children: [
				{
					title: 'Times',
					dataIndex: 'rated_as_easy',
					key: 'times',
					width: 100,
					align: "center",
					render: (text) => {
						let width = "30px";
						return similerSpan(text,width);
					},
					className: 'td-right',
				},
				{
					title: '%',
					dataIndex: 'easy_percent',
					key: 'persent',
					width: 100,
					align: "center",
					render: (text) => {
						return similerSpan(text);
					},
					className: 'td-right',
				},
			],
		},
		{
			title: 'Correctly Answered',
			children: [
				{
					title: 'Times',
					dataIndex: 'ans_correct',
					key: 'times',
					width: 100,
					align: "center",
					render: (text) => {
						let width = "30px";
						return similerSpan(text,width);
					},
					className: 'td-right',
				},
				{
					title: '%',
					dataIndex: 'ans_correct_percent',
					key: 'persent',
					width: 100,
					align: "center",
					render: (text) => {
						return similerSpan(text);
					},
					className: 'td-right',
				},
			],
		},
		{
			title: 'Incorrectly answered',
			children: [
				{
					title: 'Times',
					dataIndex: 'ans_incorrect',
					key: 'times',
					width: 100,
					align: "center",
					render: (text) => {
						let width = "30px";
						return similerSpan(text,width);
					},
					className: 'td-right',
				},
				{
					title: '%',
					dataIndex: 'ans_incorrect_percent',
					key: 'persent',
					width: 100,
					align: "center",
					render: (text) => {
						return similerSpan(text);
					},
					className: 'td-right',
				},
			],
		},
		{
			title: 'Marked for Later',
			children: [
				{
					title: 'Times',
					dataIndex: 'marked_for_later',
					key: 'times',
					width: 100,
					align: "center",
					render: (text) => {
						let width = "30px";
						return similerSpan(text,width);
					},
					className: 'td-right',
				},
				{
					title: '%',
					dataIndex: 'marked_percent',
					key: 'persent',
					width: 100,
					align: "center",
					render: (text) => {
						return similerSpan(text);
					},
					className: 'td-right',
				},
			],
		},
	]; 
	// fetching the test-listing data to get all the categoryid to display on the dropdown
	const fetchCategoryOption = () => {
		fetchAdminApi("/test-listing?type=category_options", "get").then((res) => {
			if (res && res.data) {
				setCategoryOptionId(res.data);
			}
			else {
				setCategoryOptionId([]);
			}
		});
	};
	// fetching the test-listing data to get all the topicid to display on the dropdown
	const fetchTopicOption = () => {
		fetchAdminApi("/test-listing?type=topic_options", "get").then((res) => {
			if (res && res.data) {
				setTopicOptionId(res.data);
			}
			else {
				setTopicOptionId([]);
			}
		});
	};
	const onchangeQuestionDisplayed = (value) =>{
		setListofQuestion(value);
	};
	const onChangeTestType = e => {
		setTestTypeValue(e.target.value);
	};
	const onChangeDisplay = e => {
		setDisplayValue(e.target.value);
	};
	// const onclickPreviousYear = () =>{
	// 	// setdatemessage({
	// 	// 	...datemessage,
	// 	// 	stard:constants.lastYearStartDate,
	// 	// 	endd:constants.lastYearStartDate
	// 	// });
	// 	setStartDate(constants.lastYearStartDate);
	// 	setEndDate(constants.lastYearEndDate);
	// 	setRefresh(!refresh);
	// 	setDate("previous year");
	// 	form.setFieldsValue({ month : null });
	// };
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="div">
					<div className="p-3">
						<Breadcrumb>
							<Breadcrumb.Item ><a onClick={() => {
								history.push({ pathname: "/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/analysis-menu" });
							}}>Analysis</a></Breadcrumb.Item>
							<Breadcrumb.Item>{constants.QuestionRatings}</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<BackTop/>
					<div>
						<Row gutter={[24, 24]}>
							<Col xs={24} sm={24} md={24} lg={24}>
								<Card size="small" title = {<span className="text-uppercase">Question Ratings</span>}extra={
									<Form layout="horizontal" form = {form}>
										<Space>
											<Form.Item className="label-default mb-0" >
												<p className = "mb-0 text-mute2">({moment(datemessage.stard).format(constants.dateFormat)}{"   "}to{"   "}{moment(datemessage.endd).format(constants.dateFormat)})</p>
											</Form.Item>
											{/* need to uncomment next year previous year feature */}
											{/* <Form.Item className="label-default mb-0" name = "previousYear">
											<Button type = "link" onClick ={onclickPreviousYear}>Previous Years</Button>
										</Form.Item> */}
											<Form.Item className="label-default mb-0" label="Month:" name = "month">
												<DatePicker onChange={onChangeMonth} format="MMMM yyyy" inputReadOnly={true}  picker="month" disabledDate={disabledFutureDate}/>
											</Form.Item>
										</Space>
									</Form>
								}>
									<Row gutter={[16, 24]}>
										<Col xs={24} sm={24} md={24} lg={24} >
											<Form layout="vertical" className = "bold-label">
												<Space size = {40}>
													<Form.Item className="label-default mb-0" label="Category:">
														<Select onChange={onchangeCategoryid} value={category} style={{ width: 180 }} >
															<Option value={""}>--Category--</Option>
															{categoryOptionId.map(option => {
																return <Option title = {option.category_name} key={option.category_id} value={option.category_id}>{option.category_abbreviation}</Option>;
															})}
														</Select>
													</Form.Item>
													<Form.Item className="label-default mb-0" label="Topic:">
														<Tooltip title = {topicTitle}>
															<Select onChange={onchangeTopicId} value={topic} style={{ width: 180 }}>
																<Option title = {false} value={""}>--Topic--</Option>
																{topicOptionId.map(option => {
																	return <Option title={option.topic_title} key={option.topic_id} value={option.topic_id}>{option.topic_abbreviation}</Option>;
																})}
															</Select>
														</Tooltip>
													</Form.Item>
													<Form.Item className="label-default mb-0" label="List Questions:">
														<Select onChange={onchangeQuestionDisplayed} value={listofQuestion} style={{ width: 180 }} >
															{constants.ListOfQuestions.map(option => {
																let question = option.Question_sorter;
																let id = option.id;
																return <Option key = {id} value = {id}>{question}</Option>;
															})}
														</Select>
													</Form.Item>
													<Form.Item className="label-default mb-0" label="Type:">
														<Radio.Group className = "radio-button-lable" onChange={onChangeTestType} value={testTypeValue}>
															<Radio value={1}>Tests & Tutorials</Radio>
															<Radio value={0}>QOD</Radio>
														</Radio.Group>
													</Form.Item>
													<Form.Item className="label-default mb-0" label="Display:">
														<Radio.Group className = "radio-button-lable" onChange={onChangeDisplay} value={displayValue}>
															<Radio value={0}>Top 100</Radio>
															<Radio value={1}>All</Radio>
														</Radio.Group>
													</Form.Item>
												</Space>
											</Form>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} className="table2" >
											<Table
												columns={column}
												dataSource={tableData}
												pagination={false}
												locale={{ emptyText: 'No Records Found' }}
												bordered
											/>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</div>;
				</div>
			</Spin>
		</>
	);
};
export default QuestionRating;