/* eslint-disable react/prop-types */
import React from "react";
import "../../../index.css";
import { Layout } from "antd";
import AdminHeaders from "./AdminHeader";
import Footers from "../../../footer";
const { Content } = Layout;
const AdminLayouts = (props) => {
	return (
		<div>
			<Layout>
				{/* <Headers/> */}
				<AdminHeaders/>
				<Content className="bg-light min-content-height">
					{React.cloneElement(props.children, { ...props, })}
				</Content>
				<Footers/>
			</Layout>
		</div>
	);
};
export default AdminLayouts;
