/*
File Name:DailyQOD.js
Author: 
Modified On: 19/01/2023
Description: Display list of daily qod
API's used:
1) /
*/
import React, { useEffect, useState } from "react";
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	BackTop,
	Table,
	Tooltip,
	// Tooltip,
} from "antd";
import "../../../styles/admin.css";
import { fetchAdminApi } from "../../../services/api";
import moment from "moment";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import Chart from "react-google-charts";
const DailyQODDetails = (props) => {
	const history = useHistory();
	const [dailyQodDataDetails,setDailyQodDataDetails]=useState([]);
	const [qodGraphData,setQodGraphData]=useState([]);
	const [total,setTotal]=useState(0);
	useEffect(() => {
		getDailyQodDataDetails();
	}, []);
	//Function to get daily Qod data
	const getDailyQodDataDetails=()=>{
		fetchAdminApi(`/Individual-Qod/${props.history.location.state.qid}`, "get").then(
		// fetchAdminApi(`/Individual-Qod/277`, "get").then(
			(res) => {
				if (res && res.data) {
					setDailyQodDataDetails(res.data);
					let qodDetailsChartData = [
						["Dates", "Correct", "Incorrect","Easy", "Medium", "Difficult", "Not Rated"
						],
					];
					let tempTotalValue=0;
					for (let index = 0; index < res.data.length; index++) {
						let qodDataRow=[];
						qodDataRow.push(
							moment(res.data[index].date).format(constants.dateFormat),
							res.data[index].tot_correct?Number(res.data[index].tot_correct):0,
							res.data[index].tot_incorrect?Number(res.data[index].tot_incorrect):0,
							res.data[index].rated_1?Number(res.data[index].rated_1):0,
							res.data[index].rated_2?Number(res.data[index].rated_2):0,
							res.data[index].rated_3?Number(res.data[index].rated_3):0,
							res.data[index].rated_not?Number(res.data[index].rated_not):0
						);
						tempTotalValue=tempTotalValue+qodDataRow[1]+qodDataRow[2]+qodDataRow[3]+qodDataRow[4]+qodDataRow[5]+qodDataRow[6];
						qodDetailsChartData.push(qodDataRow);
					}
					setTotal(tempTotalValue);
					setQodGraphData(qodDetailsChartData);
				} else {
					setDailyQodDataDetails([]);
					setQodGraphData([]);
				}
			}
		);
	};
	//Function to convert Number to Alphabets
	// const convertToAlphabet=(value)=>{
	// 	let covertedValue=(value + 9).toString(36).toUpperCase();
	// 	return covertedValue;
	// };
	const dailyQodDetailsColumns = [
		{
			title: 'Date Displayed',
			key: 'date',
			dataIndex: 'date',
			align: 'center',
			// width: 150,
			sorter: (a, b) => new Date(a.dt_last_displayed) - new Date(b.dt_last_displayed),
			render: (text) => (
				<span>{text ?moment(text).format(constants.dateFormat) : ""}</span>
			),
		},
		{
			title: 'Correct',
			key: 'tot_correct',
			dataIndex: 'tot_correct',
			align: 'right',
			render: (text) => (
				<span>{text ?text : 0}</span>
			),
		},
		{
			title: 'Incorrect',
			key: 'tot_incorrect',
			dataIndex: 'tot_incorrect',
			align: 'right',
			render: (text) => (
				<span>{text ?text : 0}</span>
			),
		},
		{
			title: 'Easy',
			key: 'rated_1',
			dataIndex: 'rated_1',
			align: 'right',
			render: (text) => (
				<span>{text ?text : 0}</span>
			),
		},
		{
			title: 'Medium',
			key: 'rated_2',
			dataIndex: 'rated_2',
			align: 'right',
			render: (text) => (
				<span>{text ?text : 0}</span>
			),
		},
		{
			title: 'Difficult',
			key: 'rated_3',
			dataIndex: 'rated_3',
			align: 'right',
			render: (text) => (
				<span>{text ?text : 0}</span>
			),
		},
		{
			title: 'Not Rated',
			key: 'rated_not',
			dataIndex: 'rated_not',
			align: 'right',
			render: (text) => (
				<span>{text ?text : 0}</span>
			),
		},
	];
	const options = {
		title: "QOD Details",
		height: 400,
		isStacked: true,
		vAxis: {
			title: "Total",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			},
			viewWindow: { min:0 }
		},
		hAxis: { 
			title: "Dates" ,
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			},
			viewWindow: { min:0 }
		},
		seriesType: "bars",
		series: { 
			2: { type: "line" },
			3: { type: "line" },
			4: { type: "line" },
			5: { type: "line" },
		},
	};
	const noDataAvailableText=()=>{
		return(
			<>							
				<div className="nograph-div">
					<span>No Data Available.</span>
				</div>
			</>
		);
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item><a onClick={() => 
							history.push("/admin-dashboard")
						}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => 
							history.push("/analysis-menu")
						}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item><a onClick = {() => 
							history.push("/daily-qod")
						}>Daily QOD</a></Breadcrumb.Item>
						<Breadcrumb.Item>QOD Details</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div >
					<BackTop/>
					<Row gutter={[24, 24]} >
						<Col xs={24} sm={24} md={24} lg={24}>
							<br/>
							<span className="px-3">{ `${dailyQodDataDetails.length !== 0?dailyQodDataDetails.length:0} record(s) found.` }</span>
							<br/>
							<Card size="small" title={
								<>
									<span className="text-uppercase">QOD Details</span><br/>
									<span >Question ID : {props.history.location.state.qid}</span><br/>
									<Tooltip title={props.history.location.state.questionText}><span >Question : {props.history.location.state.questionText}</span></Tooltip><br/>
								</>
							} 
							>
								<Table className="admin-gap-table" 
									locale={{ emptyText: 'No Records Found' }}
									dataSource={dailyQodDataDetails} 
									columns={dailyQodDetailsColumns}
									summary={(pageData) => {
										let totalCorrect = 0;
										let totalIncorrect = 0;
										let totalRated1 = 0;
										let totalRated2 = 0;
										let totalRated3 = 0;
										let totalRatedNot = 0;
										pageData.forEach(({ tot_correct, tot_incorrect,rated_1,rated_2,rated_3,rated_not }) => {
											totalCorrect += tot_correct?Number(tot_correct):0;
											totalIncorrect += tot_incorrect?Number(tot_incorrect):0;
											totalRated1 += rated_1?Number(rated_1):0;
											totalRated2 += rated_2?Number(rated_2):0;
											totalRated3 += rated_3?Number(rated_3):0;
											totalRatedNot += rated_not?Number(rated_not):0;
										});
										return (
											<>
												<Table.Summary.Row>
													<Table.Summary.Cell index={0} align="center">Total</Table.Summary.Cell>
													<Table.Summary.Cell index={1} align="right">
														{totalCorrect}
													</Table.Summary.Cell>
													<Table.Summary.Cell index={2} align="right">
														{totalIncorrect}
													</Table.Summary.Cell>
													<Table.Summary.Cell index={3} align="right">
														{totalRated1}
													</Table.Summary.Cell>
													<Table.Summary.Cell index={4} align="right">
														{totalRated2}
													</Table.Summary.Cell>
													<Table.Summary.Cell index={5} align="right">
														{totalRated3}
													</Table.Summary.Cell>
													<Table.Summary.Cell index={6} align="right">
														{totalRatedNot}
													</Table.Summary.Cell>
												</Table.Summary.Row>
											</>
										);
									}}
								/>
								{total>0?
									<Chart
										chartType="ComboChart"
										width="100%"
										height="400px"
										data={qodGraphData}
										options={options}
									/>
									:noDataAvailableText()}
							</Card>
						</Col>
					</Row> 
				</div>
			</div>
		</>
	);
};
export default DailyQODDetails;
