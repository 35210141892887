import React, { useState,useEffect } from "react";
import { Row, Col, Button, Card, Form, Select, Modal, Spin, message, Space } from 'antd';
import { HourglassOutlined, PauseOutlined, RedoOutlined, PlayCircleOutlined } from '@ant-design/icons'; 
import constants from "../../constants/constants";
import { fetchApi } from "../../services/api";
import { useHistory } from "react-router";
import { COOKIE,getCookie } from "../../services/cookie";
import Loader from "../Common/Cred/Loader";
const { Option } = Select;
const ChooseTest = () => {
	// timed untimed modal
	const history = useHistory();
	const [isTypeModalVisible, setIsTypeModalVisible] = useState(false);
	const [testAttempts, setTestAttempts] = useState({});
	const [topicTests, setTopicTests] = useState([]);
	const [mixedTestVisible, setMixedTestVisible] = useState(false);
	const [categoryDetails,setCategoryDetails]=useState(null);
	const [timer,setTimer]=useState("");
	const [attemptsLoading,setAttemptsLoading]=useState(false);
	const [topicAttemptsLoading,setTopicAttemptsLoading]=useState(false);
	const [selectedTopicId,setSelectedTopicId]=useState(null);
	const [topicDetails,setTopicDetails]=useState(null);
	const [isUserNew,setIsUserNew]=useState(null);
	const [pauseCount,setPauseCount]=useState(0);
	const [categoryPausedTests,setCategoryPausedTest]=useState(0);
	const [topicPausedTests,setTopicPausedTests]=useState(0);
	const [categoryRetakeTests,setCategoryRetakeTests]=useState(0);
	const [topicRetakeTests,setTopicRetakeTests]=useState(0);
	const [isFullengthInstuctionModal, setisFltInstuctionModal] = useState(false);
	useEffect(() => {
		fetchUserDetails();
		fetchTopicTests();
	}, []);
	//To fetch the details of the user logged in
	const fetchUserDetails = async() => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/student/${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				if(res.data){
					setIsUserNew(res.data.is_new);
					fetchTestAttempts();
				}
			}
		});
	};
	//To fetch the test attempts of Category, Topic and Full length tests
	const fetchTestAttempts = () => {
		let userId = getCookie(COOKIE.UserId);
		let payload = {};
		payload.stud_id = userId;
		payload.type = "normal";
		fetchApi(`/student/test-attempts`, "post", payload).then((res) => {
			if (res && res.code === 200) {
				setAttemptsLoading(false);
				setTestAttempts(res.data);
				setTestAttemptData(res);
			}else{
				setAttemptsLoading(false);
				setTestAttempts({});
				setPauseCount(0);
				setCategoryPausedTest(0);
				setTopicPausedTests(0);
				setCategoryRetakeTests(0);
				setTopicRetakeTests(0);
			}
		});
	};
	//Updating the state values to display the count from the api response
	const setTestAttemptData=(res)=>{
		setPauseCount(res.data.pause_count);
		setCategoryPausedTest(res.data.pause_count_category?res.data.pause_count_category:0);
		setTopicPausedTests(res.data.pause_count_topic?res.data.pause_count_topic:0);
		setCategoryRetakeTests(res.data.retake_count_category?res.data.retake_count_category:0);
		setTopicRetakeTests(res.data.retake_count_topic?res.data.retake_count_topic:0);
	};
	//To fetch the topics of the Topic test
	const fetchTopicTests = async() => {
		let userId = getCookie(COOKIE.UserId);
		setTopicAttemptsLoading(false);
		fetchApi(`/topics?stud_id=${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				setTopicAttemptsLoading(false);
				setTopicTests(res.data);
			}else{
				setTopicAttemptsLoading(false);
				setTopicTests([]);
			}
		});
	};
	function errorModalDisplay(){
		Modal.error({ 
			// title: constants.pauseCountErrorTittle,
			icon: " ",
			content: <span>
				{/* {constants.pauseCountErrorContent} Click 
				<a onClick={()=>{
					Modal.destroyAll();
					history.push("/my-tests");
				}}> here</a> {constants.pauseCountErrorContentpart} */}
				<div className="status-container text-center">
					<PauseOutlined className="status-icon"/> 
					<h1 className="status-title">{constants.pauseCountErrorTittle}</h1>
					<div className="status-content">
						<p>{constants.pauseCountErrorContent}</p>
						<p className="mt-4"><span>Click to </span>
							<Button className="btnDb" onClick={()=>{
								Modal.destroyAll();
								history.push({
									pathname:"/my-tests",
									state:{ IsFilter:true } 
								});
							}}>
									View
							</Button> 
							<span> your paused tests.</span>
						</p>
					</div>
				</div>
			</span>,
			closable: true,
			okButtonProps : { style: { display: 'none' } },
			// onOk() {
			// },
		});
	}
	//Popup modal function for Timed or untimed or Mixed test not available for Category test
	const showTypeModal = (id,question,attempt,type,retake) => {
		if(pauseCount>=constants.minimumPauseCount){
			errorModalDisplay();
		}else{
			if(question===25){
				setTimer(constants.TwentyFiveQuestionTimerText);
			}else if(question===50){
				setTimer(constants.FiftyQuestionTimerText);
			}
			if(attempt>=1){
				if (type === "MIXED") {
					if(isUserNew&&isUserNew==="1"){//Checking if the student user is a new user or a migrated user
						setMixedTestVisible(false);
						setIsTypeModalVisible(true);
						let categoryDetailsObject={};
						categoryDetailsObject.type="normal";
						categoryDetailsObject.catId=id;
						categoryDetailsObject.catType=type;
						categoryDetailsObject.count=question;
						let attemptNumber={
							2:1,
							1:2,
						};
						categoryDetailsObject.attempt=attemptNumber[attempt];
						categoryDetailsObject.isReume=false;
						setCategoryDetails(categoryDetailsObject);
					}else{
						setMixedTestVisible(false);
						setIsTypeModalVisible(true);
						let categoryDetailsObject={};
						categoryDetailsObject.type="normal";
						categoryDetailsObject.catId=id;
						categoryDetailsObject.catType=type;
						categoryDetailsObject.count=question;
						categoryDetailsObject.isReume=false;
						let attemptNumber={
							6:1,
							5:2,
							4:3,
							3:4,
							2:5,
							1:6
						};
						categoryDetailsObject.attempt=attemptNumber[attempt];
						setCategoryDetails(categoryDetailsObject);
					}
				} else {
					setIsTypeModalVisible(true);
					let categoryDetailsObject={};
					categoryDetailsObject.type="normal";
					categoryDetailsObject.catId=id;
					categoryDetailsObject.catType=type;
					categoryDetailsObject.count=question;
					categoryDetailsObject.isReume=false;
					if(isUserNew&&isUserNew==="1"){
						let attemptNumber={
							2:1,
							1:2,
						};
						categoryDetailsObject.attempt=attemptNumber[attempt];
					}else{
						let attemptNumber={
							6:1,
							5:2,
							4:3,
							3:4,
							2:5,
							1:6
						};
						categoryDetailsObject.attempt=attemptNumber[attempt];
					}
					setCategoryDetails(categoryDetailsObject);
				}
			}else{
				if(retake>=1){
					message.info(constants.RetakeTest);
				}else{
					message.info(constants.NoRetakeTest);
				}
			}
		}
	};
	//Cancel function for popup modal
	const handleTypeCancel = () => {
		setCategoryDetails(null);
		setTopicDetails(null);
		setIsTypeModalVisible(false);
	};
	//On change function to save the Topic id
	const handleSelectedTopicId=(value,option)=>{
		let topicSelected={};
		topicSelected.id=value;
		topicSelected.topicTitle=option.props.children;
		setSelectedTopicId(topicSelected);
	};
	//Popup modal function for Timed or untimed for Topic test 
	const showTopicTestModal=()=>{
		if(pauseCount>=constants.minimumPauseCount){
			errorModalDisplay();	
		}else{
			setTimer(constants.TwentyFiveQuestionTimerText);
			setIsTypeModalVisible(true);
			let topicDetailsObject={};
			topicDetailsObject.type="normal";
			topicDetailsObject.topicId=selectedTopicId.id;
			topicDetailsObject.count=25;
			topicDetailsObject.topicTitle=selectedTopicId.topicTitle;
			topicDetailsObject.isReume=false;
			setTopicDetails(topicDetailsObject);
		}
	};
	//Function to redirect and start Full length test
	const handleFullLengthTest=()=>{
		if(pauseCount>=constants.minimumPauseCount){
			errorModalDisplay();	
		}else{
			setisFltInstuctionModal(true);
		}
	};
	const fullLengthInstructionModal = () => {
		let index=[12,11,10,9,8,7,6,5,4,3,2,1];
		let testId=index.indexOf(testAttempts.full_length_test_available)+1;
		return <Modal
			title={<b>Are you ready?</b>}
			centered
			visible={isFullengthInstuctionModal}
			onCancel={() => {
				setisFltInstuctionModal(false);
			}}
			footer={null}
			className="type-modal"
		>
			<div className="mainHeading"><b>Full Length Test #{testId}</b></div>
			<div className="subHeading"><b>132 Questions</b></div>
			{constants.FLTModalInstructionMessage}
			<Space>
				<span>If you do not wish to proceed at this time, click</span>
				<Button size="small" onClick={() => {
					setisFltInstuctionModal(false);
				}} type="primary" className="navy-btn btnLb h-auto">Cancel</Button>
			</Space>
			<Space>
				<span>If you wish to take the test now, click</span>
				<Button size="small" onClick={startFullLentTest} type="primary" className="navy-btn btnLb h-auto">Start</Button>
			</Space>
		</Modal>;
	};
	const startFullLentTest = () => {
		if(testAttempts && testAttempts.full_length_test_available>0){
			let index=[12,11,10,9,8,7,6,5,4,3,2,1];
			let fullLengthDetails={};
			fullLengthDetails.type="normal";
			fullLengthDetails.testId=index.indexOf(testAttempts.full_length_test_available)+1;
			if(testAttempts.is_timer&&testAttempts.is_timer==="1"){
				fullLengthDetails.isTimed=true;
			}else{
				fullLengthDetails.isTimed=false;
			}
			fullLengthDetails.isTimer=testAttempts.is_timer;
			history.push({
				pathname:"/full-length-test",
				state:{ fullLengthDetails:fullLengthDetails } 
			});
		}else{
			message.error("No tests available");
		}
	};
	//Function to show either red or blue text color for paused text 
	const checkPausedTestClass=(pausedTestsCount)=>{
		if(pausedTestsCount<=2){
			return "navy-2";
		}else{
			return "errorMsg";
		}
	};
	const checkTextOfTestPausedOrRetake=(categoryRetakeText)=>{
		if(categoryRetakeText==1){
			return "retake available";
		}else{
			return "retakes available";
		}
	};
	const checkTextOfPausedTest=(categoryOrTopicPausedText)=>{
		if(categoryOrTopicPausedText==1){
			return "Test";
		}else{
			return "Tests";
		}
	};
	const displayTestAttempts=(testAttemptsCount)=>{
		let returnValue="";
		if(testAttemptsCount){
			if(testAttemptsCount>1){
				returnValue=(<small>{testAttemptsCount}{" "}tests remaining</small>);
			}else{
				returnValue=(<small className="single-test-temaining">{testAttemptsCount}{" "}test remaining</small>);
			}
		}else{
			returnValue=(<small className="greyMsg">0 tests remaining</small>);
		}
		return returnValue;
	};
	const checkTopicAvailable=(topicAvailable)=>{
		if(topicAvailable.topics_test_available>0){
			if(selectedTopicId&&selectedTopicId.id){
				showTopicTestModal();
			}else{
				message.error(constants.SelectTopicTest);
			}
		}else{
			message.info(constants.RetakeTest);
		}
	};
	return (
		<div>
			<div className="container py-4">
				<Row
					gutter={[
						{
							xs: 0,
							sm: 0,
							md: 0,
							lg: 0 
						},
						{
							xs: 16,
							sm: 16,
							md: 16,
							lg: 24 
						},
					]}
				>
					<Col span={24}>
						<div className="text-center">
							<h1 className="mb-0">{constants.ChooseTestHeader}</h1>
						</div>
					</Col>
					<Col span={24}>
						<Card
							title={constants.CategoryTest}
							className="choose-test-card-wrapper"
							extra={
								<Space size='large'>
									{categoryRetakeTests!==0?
										<a onClick={()=>{
											history.push({
												pathname:"/my-tests",
												state:{ 
													IsFilter:false ,
													type:"category",
													isRetake:"true",
												} 
											});
										}} className="navy-2">
											<span><RedoOutlined/> {categoryRetakeTests} {checkTextOfTestPausedOrRetake(categoryRetakeTests)}</span></a>:
										null}
									{categoryPausedTests!==0?
										<a onClick={()=>{
											history.push({
												pathname:"/my-tests",
												state:{ 
													IsFilter:true ,
													type:"category"
												} 
											});
										}} className={checkPausedTestClass(categoryPausedTests)}>
											<span className="text-lowercase"><PlayCircleOutlined/> {categoryPausedTests} {checkTextOfPausedTest(categoryPausedTests)} {constants.yourPauseTest}</span></a>:
										null}
								</Space>
							}
						>
							<Spin spinning={attemptsLoading} size="large" indicator={<Loader/>}>
								<Row
									gutter={[
										{
											xs: 0,
											sm: 16,
											md: 24,
											lg: 24 
										},
										{
											xs: 24,
											sm: 24,
											md: 24,
											lg: 40 
										},
									]}
								>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Card
											title={<span className="break-spaces font-small">{constants.CategoryFPP}</span>}
											bordered={false}
											className="cat-card"
										>
											<Button
												onClick={() => {
													showTypeModal(1,25,testAttempts &&
													testAttempts.category &&
													testAttempts.category.FPP &&
													testAttempts.category.FPP.set25 &&
													testAttempts.category.FPP.set25.test
														? testAttempts.category.FPP.set25.test
														: 0,
													"FPP",testAttempts &&
													testAttempts.category &&
													testAttempts.category.FPP &&
													testAttempts.category.FPP.set25 &&
													testAttempts.category.FPP.set25.retake?testAttempts.category.FPP.set25.retake
														: 0
													);
												}}
												block
												type="primary"
												className="btnLb"
											>
											25 Questions
											</Button>
											<p className="text-mute2">
												{testAttempts &&
												testAttempts.category &&
												testAttempts.category.FPP &&
												testAttempts.category.FPP.set25 &&
												displayTestAttempts(testAttempts.category.FPP.set25.test)
												}
											</p>
											{isUserNew&&isUserNew==="1"&&<><Button
												onClick={() => {
													showTypeModal(1,50,testAttempts &&
													testAttempts.category &&
													testAttempts.category.FPP &&
													testAttempts.category.FPP.set50 &&
													testAttempts.category.FPP.set50.test
														? testAttempts.category.FPP.set50.test
														: 0,"FPP",testAttempts &&
														testAttempts.category &&
														testAttempts.category.FPP &&
														testAttempts.category.FPP.set50 &&
														testAttempts.category.FPP.set50.retake?testAttempts.category.FPP.set50.retake
														: 0);
												}}
												block
												type="primary"
												className="navy-btn"
											>
											50 Questions
											</Button>
											<p className="text-mute2">
												{testAttempts &&
												testAttempts.category &&
												testAttempts.category.FPP &&
												testAttempts.category.FPP.set50 &&
												displayTestAttempts(testAttempts.category.FPP.set50.test)
												}
											</p></>}
										</Card>
									</Col>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Card
											title={<span className="break-spaces font-small">{constants.CategorySAED}</span>}
											bordered={false}
											className="cat-card"
										>
											<Button
												onClick={() => {
													showTypeModal(2,25,testAttempts &&
													testAttempts.category &&
													testAttempts.category.SAED &&
													testAttempts.category.SAED.set25 &&
													testAttempts.category.SAED.set25.test
														? testAttempts.category.SAED.set25.test
														: 0,"SAED",testAttempts &&
														testAttempts.category &&
														testAttempts.category.SAED &&
														testAttempts.category.SAED.set25 &&
														testAttempts.category.SAED.set25.retake?testAttempts.category.SAED.set25.retake
														: 0);
												}}
												block
												type="primary"
												className="btnLb"
											>
											25 Questions
											</Button>
											<p className="text-mute2">
												{testAttempts &&
												testAttempts.category &&
												testAttempts.category.SAED &&
												testAttempts.category.SAED.set25 &&
												displayTestAttempts(testAttempts.category.SAED.set25.test)
												}
											</p>
											{isUserNew&&isUserNew==="1"&&<><Button
												onClick={() => {
													showTypeModal(2,50,testAttempts &&
													testAttempts.category &&
													testAttempts.category.SAED &&
													testAttempts.category.SAED.set50 &&
													testAttempts.category.SAED.set50.test
														? testAttempts.category.SAED.set50.test
														: 0,"SAED",testAttempts &&
														testAttempts.category &&
														testAttempts.category.SAED &&
														testAttempts.category.SAED.set50 &&
														testAttempts.category.SAED.set50.retake?testAttempts.category.SAED.set50.retake
														: 0);
												}}
												block
												type="primary"
												className="navy-btn"
											>
											50 Questions
											</Button>
											<p className="text-mute2">
												{testAttempts &&
												testAttempts.category &&
												testAttempts.category.SAED &&
												testAttempts.category.SAED.set50 &&
												displayTestAttempts(testAttempts.category.SAED.set50.test)
												}
											</p></>}
										</Card>
									</Col>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Card
											title={<span className="break-spaces font-small">{constants.CategoryPIET}</span>}
											bordered={false}
											className="cat-card"
										>
											<Button
												onClick={() => {
													showTypeModal(3,25,testAttempts &&
													testAttempts.category &&
													testAttempts.category.PIET &&
													testAttempts.category.PIET.set25 &&
													testAttempts.category.PIET.set25.test
														? testAttempts.category.PIET.set25.test
														: 0,"PIET",testAttempts &&
														testAttempts.category &&
														testAttempts.category.PIET &&
														testAttempts.category.PIET.set25 &&
														testAttempts.category.PIET.set25.retake?testAttempts.category.PIET.set25.retake
														: 0);
												}}
												block
												type="primary"
												className="btnLb"
											>
											25 Questions
											</Button>
											<p className="text-mute2">
												{testAttempts &&
												testAttempts.category &&
												testAttempts.category.PIET &&
												testAttempts.category.PIET.set25 &&
												displayTestAttempts(testAttempts.category.PIET.set25.test)
												}
											</p>
											{isUserNew&&isUserNew==="1"&&<><Button
												onClick={() => {
													showTypeModal(3,50,testAttempts &&
													testAttempts.category &&
													testAttempts.category.PIET &&
													testAttempts.category.PIET.set50 &&
													testAttempts.category.PIET.set50.test
														? testAttempts.category.PIET.set50.test
														: 0,"PIET",testAttempts &&
														testAttempts.category &&
														testAttempts.category.PIET &&
														testAttempts.category.PIET.set50 &&
														testAttempts.category.PIET.set50.retake?testAttempts.category.PIET.set50.retake
														: 0);
												}}
												block
												type="primary"
												className="navy-btn"
											>
											50 Questions
											</Button>
											<p className="text-mute2">
												{testAttempts &&
												testAttempts.category &&
												testAttempts.category.PIET &&
												testAttempts.category.PIET.set50 &&
												displayTestAttempts(testAttempts.category.PIET.set50.test)
												}
											</p></>}
										</Card>
									</Col>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Card
											title={
												<span className="break-spaces font-small">
													<div className="mt-1">{constants.CategoryMixed}</div>
													({constants.CategoryShortFPP}, {constants.CategoryShortSAED}, {constants.CategoryShortPIET})
												</span>
											}
											bordered={false}
											className="cat-card"
										>
											<Button
												onClick={() => {
													showTypeModal(0,25,testAttempts &&
													testAttempts.category &&
													testAttempts.category.MIXED &&
													testAttempts.category.MIXED.set25 &&
													testAttempts.category.MIXED.set25.test
														? testAttempts.category.MIXED.set25.test
														: 0,"MIXED",testAttempts &&
														testAttempts.category &&
														testAttempts.category.MIXED &&
														testAttempts.category.MIXED.set25 &&
														testAttempts.category.MIXED.set25.retake?testAttempts.category.MIXED.set25.retake
														: 0);
												}}
												block
												type="primary"
												className="btnLb"
											>
											25 Questions
											</Button>
											<p className="text-mute2">
												{testAttempts &&
												testAttempts.category &&
												testAttempts.category.MIXED &&
												testAttempts.category.MIXED.set25 &&
												displayTestAttempts(testAttempts.category.MIXED.set25.test)
												}
											</p>
											{isUserNew&&isUserNew==="1"&&<><Button
												onClick={() => {
													showTypeModal(0,50,testAttempts &&
													testAttempts.category &&
													testAttempts.category.MIXED &&
													testAttempts.category.MIXED.set50 &&
													testAttempts.category.MIXED.set50.test
														? testAttempts.category.MIXED.set50.test
														: 0,"MIXED",testAttempts &&
														testAttempts.category &&
														testAttempts.category.MIXED &&
														testAttempts.category.MIXED.set50 &&
														testAttempts.category.MIXED.set50.retake?testAttempts.category.MIXED.set50.retake
														: 0);
												}}
												block
												type="primary"
												className="navy-btn"
											>
											50 Questions
											</Button>
											<p className="text-mute2">
												{testAttempts &&
												testAttempts.category &&
												testAttempts.category.MIXED &&
												testAttempts.category.MIXED.set50 &&
												displayTestAttempts(testAttempts.category.MIXED.set50.test)
												}
											</p></>}
										</Card>
									</Col>
								</Row>
							</Spin>
						</Card>
					</Col>
					<Col span={24}>
						<Card
							title={constants.TopicTest}
							className="choose-test-card-wrapper"
							extra={
								<Space size='large'>
									{topicRetakeTests!==0?
										<a onClick={()=>{
											history.push({
												pathname:"/my-tests",
												state:{ 
													IsFilter:false ,
													type:"topic",
													isRetake:"true",
												} 
											});
										}} className="navy-2">
											<span><RedoOutlined/> {topicRetakeTests} {checkTextOfTestPausedOrRetake(topicRetakeTests)}</span></a>:
										null}
									{topicPausedTests!==0?
										<a onClick={()=>{
											history.push({
												pathname:"/my-tests",
												state:{ 
													IsFilter:true ,
													type:"topic"
												} 
											});
										}} className={checkPausedTestClass(topicPausedTests)}>
											<span className="text-lowercase"><PlayCircleOutlined/> {topicPausedTests} {checkTextOfPausedTest(topicPausedTests)} {constants.yourPauseTest}</span></a>:
										null}
								</Space>
							}
						>
							<Form name="topic_tests" layout="horizontal">
								<Row
									gutter={[
										{
											xs: 0,
											sm: 8,
											md: 24,
											lg: 48 
										},
										{
											xs: 8,
											sm: 8,
											md: 24,
											lg: 24 
										},
									]}
								>
									<Col xs={24} sm={16} lg={18}>
										<Form.Item className="label-default mb-0" label="Topics">
											<Select
												showSearch
												placeholder="Select topic"
												optionFilterProp="children"
												filterOption={(input, option) =>
													option.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}
												className="w-100"
												loading={topicAttemptsLoading}
												onChange={handleSelectedTopicId}
											>
												{topicTests &&
													topicTests.length > 0 &&
													topicTests.map((topic) => (
														<Option
															key={topic.topic_id}
															disabled={topic.is_test_taken}
															value={topic.topic_id}
															title=""
														>
															{topic.topic_title}
														</Option>
													))}
											</Select>
											<br/>
											<span className="text-mute2">
												{
													testAttempts &&
														displayTestAttempts(testAttempts.topics_test_available)
												}
											</span>
										</Form.Item>
									</Col>
									<Col xs={24} sm={6} lg={6}>
										<Form.Item>
											<Button block type="primary" className="btnLb" 
												onClick={()=>{
													testAttempts &&
														checkTopicAvailable(testAttempts);
												}
												}
											>
												25 Questions
											</Button>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</Card>
					</Col>
					<Col span={24}>
						<Card
							title={constants.FullLengthTests}
							className="choose-test-card-wrapper"
							extra={<span className="navy">ALL (FPP, SAED, PIET)</span>}
						>
							<Row
								gutter={[
									{
										xs: 0,
										sm: 8,
										md: 24,
										lg: 48 
									},
									{
										xs: 8,
										sm: 8,
										md: 24,
										lg: 24 
									},
								]}
							>
								<Col xs={24} sm={16} lg={18} className="text-center place-center">
									<span>{constants.FullLengthInstructions}</span>
								</Col>
								<Col xs={24} sm={6} lg={6} className="text-center">
									<Form name="full_tests">
										<Form.Item className="mb-0">
											<Button block type="primary" className="navy-btn" onClick={()=>{handleFullLengthTest();}}>
												132 Questions
											</Button>
										</Form.Item>
										<span className="text-mute2">
											{
												testAttempts &&
													displayTestAttempts(testAttempts.full_length_test_available)
											}
										</span>
									</Form>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</div>
			{/* Timed untimed modal */}
			<Modal
				title={<b>Select Timed or Untimed Test</b>}
				centered
				visible={isTypeModalVisible}
				onCancel={handleTypeCancel}
				footer={null}
				className="type-modal"
			>
				{categoryDetails&&categoryDetails.catType?(
					<h2>
						<p style={{ textAlign:"center" }}>{categoryDetails.catType}</p>
						<p style={{ textAlign:"center" }}>{categoryDetails&&categoryDetails.count&&categoryDetails.count} Questions</p>
					</h2>
				):""}
				{topicDetails&&topicDetails.topicId?(
					<h2>
						<p style={{ textAlign:"center" }}>{topicDetails.topicTitle}</p>
						<p style={{ textAlign:"center" }}>25 Questions</p>
					</h2>
				):""}
				<Row gutter={[8, 0]}>
					<Col span={12}>
						<Button
							block
							onClick={
								()=>{
									if(topicDetails&&topicDetails.topicId){
										topicDetails.isTimed=false;
										history.push({
											pathname:"/short-length-test",
											state:{ topicDetails:topicDetails } 
										});
									}else{
										categoryDetails.isTimed=false;
										history.push({
											pathname:"/short-length-test",
											state:{ categoryDetails:categoryDetails } 
										});
									}
								}
							}
						>
							<HourglassOutlined className="icon-md"/>
							<br/>
							{constants.Untimed}
							<br/>
							<span>{constants.UntimedSubTitle}</span>
						</Button>
					</Col>
					<Col span={12}>
						<Button 
							block
							onClick={
								()=>{
									if(topicDetails&&topicDetails.topicId){
										topicDetails.isTimed=true;
										history.push({
											pathname:"/short-length-test",
											state:{ topicDetails:topicDetails } 
										});
									}else{
										categoryDetails.isTimed=true;
										history.push({
											pathname:"/short-length-test",
											state:{ categoryDetails:categoryDetails } 
										});
									}
								}
							}
						>
							<HourglassOutlined className="icon-md"/>
							<br/>
							{constants.Timed}
							<br/>
							<span>{timer}</span>
						</Button>
					</Col>
				</Row>
			</Modal>
			{/* Mixed test not available modal */}
			<Modal
				title={<b>Note</b>}
				centered
				visible={mixedTestVisible}
				onCancel={() => {
					setMixedTestVisible(false);
				}}
				footer={null}
				className="type-modal"
			>
				<span>{constants.MixedTestAvailable}</span>
			</Modal>
			{/* Full length Instruction modal  */}
			{isFullengthInstuctionModal && fullLengthInstructionModal()}
		</div>
	);
};
export default ChooseTest;
