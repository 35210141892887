import React, { useState, useEffect } from "react";
import { Row, Col, BackTop, Card, Table, Tooltip, Space , Avatar ,Breadcrumb, Button } from 'antd';
import { InfoCircleOutlined,  } from '@ant-design/icons';
import { fetchApi } from "../../../services/api";
import moment from "moment"; 
import constants from '../../../constants/constants';
import { useHistory } from "react-router";
import '../../../styles/test.css';
const ScoreCompareViewMore = () => {
	const history = useHistory();
	const [latestScore, setLatestScore] = useState([]);
	useEffect(() => {
		fetchLatestScores();
	}, []);
	// To Fetch the data for test taken by the user
	const fetchLatestScores = () => {
		fetchApi(`/student/latest-top-ten-score?show_more=1`, "get").then((res) => {
			if (res && res.code === 200) {
				let tempMark=null;
				let count=0;
				res.data.map((item)=>{
					if(item.marks_scored===tempMark){
						item.srNum=count;
					}else{
						item.srNum=count+1;
						count=count+1;
					}
					tempMark=item.marks_scored;
				});
				setLatestScore( res.data );
			}else{
				setLatestScore([]);
			}
		});
	};
	const redirectStudentDetails=(studentId)=>{
		localStorage.setItem("studentId", studentId);
		localStorage.setItem("editRedirectLink","/score-compare-view-more");
		window.open(constants.BaseUrl+"/view-student", '_blank');
	};
	const nameEditButton = (record,object) =>{
		return (<span><Tooltip title={getTooltip(object.country_name,object.region_name,object.university_name,object.date)}><Button className = "px-0" type="link" 
			onClick={()=>redirectStudentDetails(object && object.stud_id)}
		>{record?record:"-"}</Button></Tooltip></span>);
	};
	const getTooltip =(country,state,university,dateregistration)=>{
		return (
			<>
				<span>Country: {country?country:"-"}</span><br/>
				<span>State: {state?state:"-"}</span><br/>
				<span>University: {university?university:"-"}</span><br/>
				<span>Date of Registration: {dateregistration?dateregistration:"-"}</span>
			</>
		);
	};
	// Top ten table
	const topTenColumns = [
		{
			title: '#',
			key: 'srNum',
			dataIndex:'srNum',
			align:'center',
			className: 'td-right',
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				const x = record.name;
				const myArray = x.split(" ");
				const initials = x.substring(0, 1).toUpperCase();
				const y = myArray[1];
				const initial2 = y.substring(0, 1).toUpperCase();
				return (
					<>
						<Space size="middle">
							<Avatar className="avatar">{initials}{initial2}</Avatar>{/* Don't call "avatar" class if expired sub user */}
							{/* <span>{record&&record.name? myArray[0].charAt(0).toUpperCase()+ myArray[0].slice(1) + "," + " " + initial2:"-"}</span> */}
							<span>{nameEditButton(record&&record.name,record)}</span>
						</Space>
					</>
				);
			}
		},
		{
			title: 'State',
			key: 'state',
			render: (record) => {
				return (
					<span>{record&&record.state?record.state:"-"}</span>
				);
			}
		},
		{
			title: 'Date',
			key: 'date',
			render: (record) => {
				return (
					<span>{record&&record.date ? (<span>{moment(record.date).format("MM/DD/YYYY")}</span>) : ("-")}</span>
				);
			}
		},
		{
			title: 'Score',
			key: 'score',
			align:'center',
			className: 'td-right',
			render: (record) => {
				return (
					<span>{record&&record.marks_scored?record.marks_scored + "/" + "200":"-"}</span>
				);
			}
		},
	];
	return (
		<div className="div">
			<div className="p-3">
				<Breadcrumb>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick = {() => {
						history.push({ pathname :"/score-compare" });
					}}>Score Comparison </a></Breadcrumb.Item>
					<Breadcrumb.Item>Top 100 Test Scores</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<BackTop/>
			<Row gutter={[24, 24]}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title={<span className="text-uppercase">{constants.TopHundred}</span>} extra={<Space>
						{/* <a onClick={()=>history.push("/compare-score")}>Back to top 10</a> */}
						<Tooltip placement="left" title={constants.compareTestsInfoToolTip}><InfoCircleOutlined/></Tooltip></Space>}>
						<Table className="gap-table" columns={topTenColumns} 
							dataSource={latestScore}
							pagination={false}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default ScoreCompareViewMore;
