/*
File Name: AddCaseStudy.js
Author: Mevito
Modified On: 05/05/2022
Description: To add a case study
API's used:
1) 
*/
import React, { useEffect, useState } from "react";
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Button,
	BackTop,
	Space,
	Select,
	Form,
	message
} from "antd";
import "../../../styles/admin.css";
import { fetchAdminApi, } from "../../../services/api";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
const { Option } = Select;
const EditCaseStudy = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [caseStudyId,setCaseStudyId]=useState(null);
	// const horizontalLayout = {
	// 	labelCol: { span: 4, },
	// 	wrapperCol: { span: 20, },
	// };
	//Validation Checks 
	const handleCaseStudyValidation=(_rule,value,callback)=>{
		try {
			if(value){
				if(value.trim()===""||value.trim()==="<p><br></p>"){
					throw new Error(constants.QuestionRequired);
				}
			}else{
				throw new Error(constants.QuestionRequired);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//on Cancel button
	const onCancelBtn=()=>{
		history.push({ pathname: "/case-study-list" });
	};
	const editCaseStudy=(values)=>{
		let payload={
			// case_study_id:caseStudyId,
			case_study_content:values.caseStudy,
			category_id:values.Category
		};
		console.log("payload",payload);
		console.log("case id",caseStudyId);
		fetchAdminApi(`/edit_case_study/${caseStudyId}`, "put", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.message);
						history.push({ pathname: "/case-study-list" });
					} else {
						message.error(res.message);
					}
				} else {
					message.error(constants.AddCaseStudyFailed);
				}
			}
		);
	};
	useEffect(() => {
		if(props && props.history && props.history.location && props.history.location.state &&props.history.location.state.recordData){
			let caseStudyWithoutHtmlTags = <span dangerouslySetInnerHTML={{ __html: props.history.location.state.recordData.case_study_content }}/>;
			form.setFieldsValue({ 
				Category:Number(props.history.location.state.recordData.category_id) ,
				caseStudy:caseStudyWithoutHtmlTags?.props?.dangerouslySetInnerHTML?.__html 
			});
			setCaseStudyId(props.history.location.state.recordData.case_study_id);
		}else{
			onCancelBtn();
		}
	}, []);
	return (
		<div className="gray-bg">
			<div className="p-3">
				<Breadcrumb>
					<Breadcrumb.Item><a onClick={() => {
						history.push({ pathname: "/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick={() => {
						history.push({ pathname: "/case-study-list" });
					}}>Case Study</a></Breadcrumb.Item>
					<Breadcrumb.Item>Edit Case Study</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<div className="px-3">
				<BackTop/>
				<Row gutter={[24, 24]} className='my-test mt-4'>
					<Col xs={24} sm={24} md={24} lg={24}>
						<Card size="small" title={<span className="text-uppercase">Edit Case Study</span>} 
						>
							<Form form={form} 
								onFinish={editCaseStudy}
								layout="horizontal"
							>
								<Col span={8}>
									<Form.Item
										label="Category"
										name="Category"
										// {...horizontalLayout}
										rules={[
											{
												required: true,
												message: constants.CategoryRequired,
											},
										]}
									>
										<Select
											showSearch
											placeholder="Select category"
											filterOption={(input, option) =>
												option.props.children
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0 ||
														option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
										>
											{constants.CategoryList &&
														constants.CategoryList.map((category) => (
															<Option key={category.category_id} value={category.category_id}>
																{category.category_name}
															</Option>
														))}
										</Select>
									</Form.Item>
								</Col>
								<Form.Item
									label="Case Study"
									name="caseStudy"
									// {...horizontalLayout}
									rules={[
										{
											required: true,
											message: "",
										},
										{ validator: handleCaseStudyValidation },
									]}
									initialValue=''
								>
									<ReactQuill theme="snow"  modules={constants.ReactQuillModules} formats={constants.ReactQuillFormats}/>
								</Form.Item>
								<div className="text-right">
									<Space>
										<Form.Item>
											<Button type="primary" htmlType="submit" className="navy-btn" >Submit</Button>
										</Form.Item>
										<Form.Item>
											<Button onClick={onCancelBtn}>Cancel</Button>
										</Form.Item>
									</Space>
								</div>
							</Form>
						</Card>
					</Col>
				</Row> 
			</div>
		</div>
	);
};
export default EditCaseStudy;
