import React, { useEffect, useState } from "react";
import { fetchAdminApi } from "../../../services/api";
import { Breadcrumb, Row, Col, Card, Form, DatePicker, Table, Space } from 'antd';
import constants from "../../../constants/constants";
import moment from "moment";
import "../../../styles/totalregistration.css";
import { Chart } from "react-google-charts";
import { useHistory } from "react-router";
const TotalRegistration = (props) => {
	const history = useHistory();
	const [selecYear, setYear] = useState("");
	const [selectedYear, setSelectedYear] = useState("");
	const [tableData, setTableData] = useState(null);
	const [graphData, setGraphData] = useState([]);
	const [totalRegistrationCount, setTotalRegistrationCount] = useState(null);
	const [sortedInfo,setsortedInfo]=useState({
		order: 'ascend',
		columnKey: 'srNumber',
	});
	const handleChange = (_pagination, _filters, sorter) => {
		setsortedInfo(sorter);
	};
	const [sYear,setSyear] = useState(props && props.location && props.location.state && props.location.state.sd.slice(0,4));
	useEffect(() => {
		fetchSelectedYear();
	}, [selectedYear,sYear]);
	useEffect(()=>{
		// return () => {
		// 	if (history.action === "POP") {
		// 		history.push("/admin-dashboard");
		// 	}
		// };
	},[history]);
	// fetching the current year & selected yeardata
	const fetchSelectedYear = () => {
		let y = selectedYear ? selectedYear : sYear;
		var year_current = moment().year();
		const Year = `/total-registrations?year=${year_current}`;
		const selectYear = `/total-registrations?year=${selectedYear ? selectedYear : sYear}`;
		fetchAdminApi(y ? selectYear : Year, "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					let count = res.data.map(item => parseInt(item.count)).reduce((prev, curr) => prev + curr, 0);
					setTotalRegistrationCount(count);
					setTableData(res.data);
					let data = res.data;
					let year = data.map(obj => {
						return obj.year;
					});
					setYear(year[0]);
					let gfHeader = [];
					gfHeader.push(["Months", "Registrations"]);
					data.map(obj => gfHeader.push([obj.month_in_3_words, parseInt(obj.count)]));
					setGraphData(gfHeader);
				}else{
					setTotalRegistrationCount(0);
					setTableData([]);
				}
			}
		});
	};
	const onChange =(_date, dateString) => {
		setSelectedYear(dateString);
		setSyear(null);
	};
	// disabled the future year
	const disabledyear = (current) => {
		return current && current > moment().endOf('year');
	};
	//routing to registered student page with the selected month
	const registedStudent = (record) => {
		let startDate = null;
		let endDate = null;
		let current_month = parseInt(moment().format('M'));
		let current_year = moment().year();
		var month = record.month;
		var year = record.year;
		if(month === current_month && parseInt(year) === current_year){
			startDate = moment().startOf('month').format('YYYY-MM-DD');
			endDate = moment().format("YYYY-MM-DD");
		}else{
			startDate = moment([year, month - 1, 1]).format("YYYY-MM-DD");
			const daysInMonth = moment(startDate).daysInMonth();
			endDate = moment(startDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");
		}
		let date = {};
		date.sd = startDate;
		date.ed = endDate;
		date.sded = selectedYear || sYear ? 1 : 2;
		date.serchCriteria = `Registrations from ${moment(startDate).format('MM/DD/YYYY')} to ${moment(endDate).format('MM/DD/YYYY')}`;
		date.title = "Total Registrations";
		date.push = "/total-registrations";
		history.push({
			pathname: "/registered-students",
			state: date,
		});
	};
	const column = [
		{
			title: "Month/Year",
			key: "MY",
			dataIndex: "month_in_words",
			sorter: (a, b) => a.month_in_words.localeCompare(b.month_in_words),
			sortOrder: sortedInfo.columnKey === 'MY' && sortedInfo.order,
			// defaultSortOrder: sortOrder ,
			render: (_text, record) => (
				<Space size="middle">
					<a onClick={() => { registedStudent(record); }} style={{ color: "black" }}>{record.month_in_words} <span>{record.year}</span></a>
				</Space>
			),
		},
		{
			title: "Registrations",
			key: "registrations",
			dataIndex: "count",
			sorter: (a, b) => a.count - b.count,
			sortOrder: sortedInfo.columnKey === 'registrations' && sortedInfo.order,
			render: (_text, record) => (
				<Space size="middle">
					<span className="p-relative"><a onClick={() => { registedStudent(record); }} style={{ color: "black" }}><span>{record.count}</span></a></span>
				</Space>
			),
			className : "td-total-registration",
			width :200,
			align:"center"
		}
	];
	const graphOption = {
		title: `Registrations for the Year ${selecYear}`,
		titleTextStyle: {
			bold: true,
			fontSize: 13,
			horizontalAlign: "center",
		},
		colors: ["#0000FF"],
		hAxis: {
			title: "Months",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		vAxis: {
			title: "\n\nRegistrations",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		pointSize: 6,
		legend: { position: "none" },
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>Total Registrations</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" title={<span className="text-uppercase">{constants.TotalRegistration}</span>} extra={
								<Form layout="horizontal">
									<Form.Item className="label-default mb-0">
										<DatePicker defaultValue = {sYear ? moment(sYear) : ""} picker="year" onChange={onChange} disabledDate={disabledyear} placeholder = "Select year" inputReadOnly={true}/>
									</Form.Item>
								</Form>
							}>
								<Row>
									<Col xs={24} sm={24} md={8} lg={8}>
										<Table className="gap-table" 
											onChange ={handleChange}
											columns={column} 
											dataSource={tableData} 
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													<Table.Summary.Row style = {{ fontWeight:500 }}>
														<Table.Summary.Cell index={0} className = "last-element"><span>Total</span></Table.Summary.Cell>
														<Table.Summary.Cell index={1} className = "last-element"><span style = {{ width : "65px" }}>{totalRegistrationCount}</span></Table.Summary.Cell>
													</Table.Summary.Row>
												</Table.Summary>
											)}/>
									</Col>
									<Col xs={24} sm={24} md={16} lg={16}>
										<Chart
											chartType="LineChart"
											width="100%"
											height="400px"
											data={graphData}
											options={graphOption}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default TotalRegistration;
