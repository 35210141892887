import { Button, Result } from 'antd';
import CustomIcons from "../../../assets/CustomIcons";
import Icon from '@ant-design/icons';
import React from 'react';
import { useHistory } from "react-router";
import constants from "../../../constants/constants";
const NotFound = (props) => (<Icon component={CustomIcons.getsvg("NotFound")} {...props}/>);
const PageNotFound = () => {
	const history = useHistory();
	return (
		<div className='pg-not-found'>
			<Result
				icon={<NotFound/>}
				title={
					<div>
						<div className='icon-xlg'>404</div>
						<div className='mb-3'>{constants.PageNotFound}</div>
					</div>
				}
				subTitle={constants.SubTitle404}
				extra={<Button className="btnDb" onClick={()=>{	history.push("/home");}}>{constants.BackHome}</Button>}
			/>
		</div>
	);
};
export default PageNotFound;