import React, { useState, useEffect } from "react";
import { Layout, Menu, Avatar, Space, Drawer, Button,message } from "antd";
import {
	DownOutlined,
	UserOutlined,
	PoweroffOutlined,
	MenuOutlined,
} from "@ant-design/icons";
import "./index.css";
import constants from "./constants/constants";
import { COOKIE, getCookie, deleteCookie } from "./services/cookie";
import { useHistory,useLocation } from "react-router";
import { fetchApi } from "./services/api";
const { Header } = Layout;
const { SubMenu } = Menu;
const Headers = () => {
	let location = useLocation();
	const history = useHistory();
	// Mobile Menu
	const [mobileMenu, setMobileMenu] = useState(false);
	const [userId, setUserId] = useState(null);
	const [name, setName] = useState(null);
	const [current, setCurrent] = useState(
		location.pathname === "/" || location.pathname === ""
			? "/home"
			: location.pathname,
	);
	useEffect(() => {
		let userIdFromCookies = getCookie(COOKIE.UserId);
		if (userIdFromCookies) {
			let profileName = {};
			profileName.firstName = getCookie(COOKIE.FirstName);
			profileName.lastName = getCookie(COOKIE.LastName);
			setName(profileName);
			setUserId(userIdFromCookies);
		}
	}, []);        
	useEffect(() => {
		if (location) {
			if( current !== location.pathname ) {
				setCurrent(location.pathname);
			}
		}
	}, [location, current]);
	function handleClick(e) {
		setCurrent(e.key);
	}
	const showMobileMenu = () => {
		setMobileMenu(true);
	};
	const closeMobileMenu = () => {
		setMobileMenu(false);
	};
	const handleLogout = () => {
		let userIdFromCookies = getCookie(COOKIE.UserId);
		let payload = null;
		payload = { is_logged_in: 0, };
		let listQuestions=sessionStorage.getItem("listQuestions");
		let testType=sessionStorage.getItem("testType");
		if(listQuestions&&testType!=="review"&&testType!=="qod"){
			if(testType==="shortLength"){
				message.error(constants.ShortLengthLogoutText);
			}else if(testType==="fullLength"){
				message.error(constants.FullLengthLogoutText);
			}else if(testType==="tutorial"){
				message.error(constants.TutorialLogoutText);
			}
		}else{
			fetchApi(`/logout/${userIdFromCookies}`, "post", payload).then((res) => {
				if (res && res.code && res.code === 200) {
					deleteCookie(COOKIE.UserId, "/");
					deleteCookie(COOKIE.FirstName, "/");
					deleteCookie(COOKIE.LastName, "/");
					deleteCookie(COOKIE.Token,"/");
					sessionStorage.removeItem("idFromMyProfile");
					window.location.href = constants.BaseUrl + "/";
					sessionStorage.clear();
				} else {
					message.error(res.message);
				}
			});
		}
	};
	return (
		<Header className="header-light slp-header pl-4 pr-3">
			{userId ? (
				<div>
					<div className="logo">
						<Space>
							<div className="mobile-menu-div">
								<Button
									type="primary"
									shape="circle"
									className="lavender-btn"
									icon={<MenuOutlined/>}
									onClick={showMobileMenu}
								/>
							</div>
							<h2 className="mb-0 pointer" onClick={() => {
								window.open(constants.SiteUrl);
							}}>SLP Praxis Review</h2>
						</Space>
					</div>
					<div className="pc-navbar">
						<Menu mode="horizontal" onClick={handleClick} defaultSelectedKeys={["/home"]} selectedKeys={[current]}>
							<Menu.Item
								key="/home"
								// onClick={() => {
								// 	window.location.href = constants.BaseUrl + "/home";
								// }}
								onClick={()=>{
									history.push("/home");
								}}
							>
								Home
							</Menu.Item>
							<SubMenu key="Help" title="Help">
								<Menu.Item key="/faq" onClick={()=>{	history.push("/faq");}}>FAQs</Menu.Item>
								<Menu.Item key="References" onClick={()=>{window.open(constants.SiteUrl+constants.ReferenceLink, '_blank');}}>
									References
								</Menu.Item>
								<Menu.Item key="Acknowledgements" onClick={()=>{window.open(constants.SiteUrl+constants.AcknowledgementsLink, '_blank');}}>
									Acknowledgements
								</Menu.Item>
								<Menu.Item key="APSLP-ETS" onClick={()=>{window.open(constants.SiteUrl+constants.AboutPraxisLink, '_blank');}}>
									About the Praxis SLP - ETS
								</Menu.Item>
								<Menu.Item key="TPPSLP" onClick={()=>{window.open(constants.TipsPassLink, '_blank');}}>
									Tips to Pass the Praxis SLP
								</Menu.Item>							
							</SubMenu>
							<SubMenu key="Tests" title="Tests">
								<Menu.Item key="/instructions" onClick={()=>{	history.push("/instructions");}}>Take a Test</Menu.Item>
								<Menu.Item key="/my-tests" onClick={()=>{	history.push("/my-tests");}}>My Tests</Menu.Item>
							</SubMenu>
							<SubMenu key="Learn" title="Learn">
								<Menu.Item key="/select-tutorial" onClick={()=>{	history.push("/select-tutorial");}}>Tutorials</Menu.Item>
								<Menu.Item key="/flashcard-list" onClick={()=>{	history.push("/flashcard-list");}}>Flashcards</Menu.Item>
							</SubMenu>
							<SubMenu
								key="Profile"
								title={
									<Space>
										<Avatar className="avatar">
											{" "}
											{name && name.firstName && name.firstName.charAt(0)}
										</Avatar>
										<span className="navy">
											{" "}
											{(name && name.firstName) +
												" " +
												(name && name.lastName && name.lastName.charAt(0))}
										</span>
										<DownOutlined/>
									</Space>
								}
							>
								<Menu.Item
									key="/myprofile"
									icon={<UserOutlined/>}
									onClick={() => {
										history.push("myprofile");
									}}
								>
									My Profile
								</Menu.Item>
								<Menu.Item
									key="logout"
									icon={<PoweroffOutlined/>}
									onClick={handleLogout}
								>
									Logout
								</Menu.Item>
							</SubMenu>
						</Menu>
					</div>
					{/* Mobile Menu Start */}
					<div className="mobile-menu-div">
						<Drawer
							title="SLP Praxis Review"
							placement="left"
							className="mobile-menu"
							onClose={closeMobileMenu}
							visible={mobileMenu}
						>
							<Menu mode="inline" onClick={handleClick} defaultSelectedKeys={["/home"]} selectedKeys={[current]}>
								<Menu.Item
									key="/home"
									// onClick={() => {
									// 	window.location.href = constants.BaseUrl + "/home";
									// }}
									onClick={()=>{
										history.push("/home");
									}}
								>
								Home
								</Menu.Item>
								<SubMenu key="Help" title="Help">
									<Menu.Item key="/faq" onClick={()=>{	history.push("/faq");}}>FAQs</Menu.Item>
									<Menu.Item key="References" onClick={()=>{window.open(constants.SiteUrl+constants.ReferenceLink, '_blank');}}>
									References
									</Menu.Item>
									<Menu.Item key="Acknowledgements" onClick={()=>{window.open(constants.SiteUrl+constants.AcknowledgementsLink, '_blank');}}>
									Acknowledgements
									</Menu.Item>
									<Menu.Item key="APSLP-ETS" onClick={()=>{window.open(constants.SiteUrl+constants.AboutPraxisLink, '_blank');}}>
									About the Praxis SLP - ETS
									</Menu.Item>
									<Menu.Item key="TPPSLP" onClick={()=>{window.open(constants.TipsPassLink, '_blank');}}>
									Tips to Pass the Praxis SLP
									</Menu.Item>							
								</SubMenu>
								<SubMenu key="Tests" title="Tests">
									<Menu.Item key="/instructions" onClick={()=>{	history.push("/instructions");}}>Take a Test</Menu.Item>
									<Menu.Item key="/my-tests" onClick={()=>{	history.push("/my-tests");}}>My Tests</Menu.Item>
								</SubMenu>
								<SubMenu key="Learn" title="Learn">
									<Menu.Item key="/select-tutorial" onClick={()=>{	history.push("/select-tutorial");}}>Tutorials</Menu.Item>
									<Menu.Item key="/flashcard-list" onClick={()=>{	history.push("/flashcard-list");}}>Flashcards</Menu.Item>
								</SubMenu>
								<SubMenu
									key="Profile"
									title={
										<Space>
											<Avatar className="avatar">
												{name && name.firstName}
											</Avatar>
											<span className="navy">
												{(name && name.firstName) +
													" " +
													(name && name.lastName)}
											</span>
										</Space>
									}
								>
									<Menu.Item
										key="/myprofile"
										icon={<UserOutlined/>}
										onClick={() => {
											history.push("myprofile");
										}}
									>
										My Profile
									</Menu.Item>
									<Menu.Item key="logout" icon={<PoweroffOutlined/>} onClick={handleLogout}>
										Logout
									</Menu.Item>
								</SubMenu>
							</Menu>
						</Drawer>
					</div>
					{/* Mobile Menu End */}
				</div>
			) : (
				<div>
					{/* Login header start */}
					<div className="logo bookname mt-3">
						<Space>
							<div className="mobile-menu-div">
								<Button
									type="primary"
									shape="circle"
									icon={<MenuOutlined/>}
									onClick={showMobileMenu}
									className="menu-btn"
								/>
							</div>
							<h3 className="mb-0 pointer" onClick={() => {
								window.open(constants.SiteUrl);
							}}>
								An Advanced Review of Speech-Language Pathology, 6th Edition:
								Practice Examinations
							</h3>
						</Space>
					</div>
					<div className="pc-navbar">
						<Menu mode="horizontal">
							<Menu.Item key="author1" onClick={()=>{window.open(constants.SiteUrl+'/celeste-roseberry-mckibbin/', '_blank');}}>
								Celeste Roseberry-McKibbin, Ph.D. 
							</Menu.Item>
							<Menu.Item key="author2" onClick={()=>{window.open(constants.SiteUrl+'/m-n-giri-hegde/', '_blank');}}>M. N. Hegde, Ph.D.</Menu.Item>
							<Menu.Item key="author3" onClick={()=>{window.open(constants.SiteUrl+'/dr-glen-tellis/', '_blank');}}>Glen M. Tellis, Ph.D.</Menu.Item>
						</Menu>
					</div>
					{/* Mobile Menu Start */}
					<div className="mobile-menu-div">
						<Drawer
							title="PRAXIS - SLP Review"
							placement="left"
							className="mobile-menu"
							onClose={closeMobileMenu}
							visible={mobileMenu}
						>
							<Menu mode="inline">
								<Menu.Item key="author1">Glen M. Tellis, PhD</Menu.Item>
								<Menu.Item key="author2">M. N. Hegde, PhD</Menu.Item>
								<Menu.Item key="author3">
									Celeste Roseberry-McKibbin, PhD
								</Menu.Item>
							</Menu>
						</Drawer>
					</div>
					{/* Mobile Menu End */}
					{/* Login header end */}
				</div>
			)}
		</Header>
	);
};
export default Headers;
