import React,{ useState,useEffect } from 'react';
import { Form, Input, Breadcrumb, Row, Col, Card, Space, Table,DatePicker, Tooltip } from "antd";
import moment from "moment";
import { fetchAdminApi } from "../../../services/api";
import { useHistory } from "react-router";
const FeedbackComments = () => {
	const history = useHistory();
	const [endDate, setEndDate] = useState("");
	const [tableData, setTableData] = useState([]);
	const [startDate, setStartDate] = useState("");
	const [filterTable, setFilterTable] = useState([]);
	const from = (_date, dateString) => {
		setStartDate(dateString);
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const column = [
		{
			title: "Date",
			key: "date",
			align: 'center',
			render: (record) => {
				return <span>{ record&&record.date ? record&&record.date : "" }</span>;
			},
			sorter: (a, b) => new Date(a.Date) - new Date(b.Date),
			width:100
		},
		{
			title: "Name",
			key: "name",
			dataIndex: "name",
			sorter: (a, b) => {
				a = a.name || '';
				b = b.name || '';
				return a.localeCompare(b);
			},
			render: (record,object) => {
				if(object.is_active==="4"){
					return <span>{"Stud - "+object.stud_id}</span>;
				}else{
					let text = record;
					const myArray = text.split(" ");
					let firstName = myArray[0];
					let lastName = myArray[1];
					let lastNamefirstChart = lastName.charAt(0);
					return <span>{record ? firstName + ", " + lastNamefirstChart : ""}</span>;
				}
			},
			width:100
		},
		{
			title: "Country",
			key: "country",
			dataIndex: "country",
			sorter: (a, b) => {
				a = a.country || '';
				b = b.country || '';
				return a.localeCompare(b);
			},
		},
		{
			title: "State",
			key: "state",
			dataIndex: "state",
			sorter: (a, b) => {
				a = a.state || '';
				b = b.state || '';
				return a.localeCompare(b);
			},
		},
		{
			title: "University",
			key: "university",
			dataIndex: "University",
			sorter: (a, b) => {
				a = a.university || '';
				b = b.university || '';
				return a.localeCompare(b);
			},
		},
		{
			title: 'Comments',
			key: 'comments',
			width:500,
			render: (record) => {
				let comment = record.Comments;
				if(comment.length > 50){
					return <Tooltip title={comment}><span>{comment.slice(0, 50)+"..."}</span></Tooltip>;
				}else{
					return <span>{comment}</span>;
				}
			},
		},
		{
			title: "Date Reply",
			key: "date",
			align: 'center',
			render: (record) => {
				return <span>{ record&&record.dt_reply ? record&&record.dt_reply : "" }</span>;
			},
			sorter: (a, b) => new Date(a.dt_reply) - new Date(b.dt_reply),
		},
	];
	const onSearch = (event) =>{
		let search = event.target.value;
		if(search !== null|| search !== ""){
			let tempData = filterTable.length > 0? filterTable.filter((searchField) =>
				searchField&&searchField.name&&searchField.name.toLowerCase().includes(search.toLowerCase())||
				searchField&&searchField.country&&searchField.country.toLowerCase().includes(search.toLowerCase())||
				searchField&&searchField.state&&searchField.state.toLowerCase().includes(search.toLowerCase())||
				searchField&&searchField.University&&searchField.University.toLowerCase().includes(search.toLowerCase())||
				searchField&&searchField.Comments&&searchField.Comments.toLowerCase().includes(search.toLowerCase())
			):[];
			setTableData(tempData);
		}
	};
	const fetchFeedbackComments = () =>{
		fetchAdminApi(startDate && endDate ? `/feedback-comments?start_date=${moment(startDate).format("YYYY-MM-DD")}&end_date=${moment(endDate).format("YYYY-MM-DD")}`:'/feedback-comments', "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					setTableData(res.data);
					setFilterTable(res.data);
				}
			}else{
				setTableData([]);
			}
		});
	};
	useEffect(() => {
		fetchFeedbackComments();
	}, [startDate,endDate]);
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/feedback-analysis" });
						}}>Feedback Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>Feedback Comments</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" title={<span className="text-uppercase">Feedback Comments</span>} extra={
								<Form layout="horizontal">
									<Space>
										<Form.Item className="label-default mb-0" label="From:" name = "from">
											<DatePicker onChange={from} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0" label="To:" name = "to">
											<DatePicker onChange={to} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
									</Space>
								</Form>
							}>
								<div className="text-right">
									<Space>
										<Form.Item label="Search" className="label-default mb-0">
											<Input style={{ width: "200px" }} size="small" onChange ={onSearch}/>
										</Form.Item>
									</Space>
								</div>
								<Table className="admin-table"
									locale={{ emptyText: 'No Records Found' }}
									dataSource={tableData}
									columns={column}
									pagination={{ showSizeChanger:true }}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default FeedbackComments;