/*
File Name: EditQuestion.js
Author: Akshay Lotlikar
Modified On: 18/03/2024
Description: Add/Edit Question UI.
*/
import React, { useState,useEffect } from 'react';
import { Row, Col, Breadcrumb, Card, Button, Form, Select, Checkbox, Input,Typography, message, Upload, Divider, Space, Radio,Popconfirm, InputNumber } from "antd";
import { FileSearchOutlined, CloseOutlined,LoadingOutlined, PlusOutlined, SaveOutlined, StopOutlined, CloseCircleFilled } from '@ant-design/icons';
import constants from "../../../constants/constants";
import { fetchAdminApi } from "../../../services/api";
import { useHistory } from "react-router";
import ReactQuill from 'react-quill';
import { COOKIE, getCookie } from '../../../services/cookie';
import axios from 'axios';
const { Option } = Select;
const { Text } = Typography;
const beforeUpload = (file) => {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
};
const dummyRequest = ({ onSuccess }) => {
	setTimeout(() => {
		onSuccess("ok");
	}, 0);
};
const EditQuestion = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [status, setStatus] = useState("0");
	const [caseStudyData, setCaseStudyData] = useState(null);
	const [selectedValue, setSelectedValue] = useState([]);
	const [options, setOptions] = useState([]);
	const [selectedMultiSelect, setSelectedMultiSelect] = useState([]);
	const [testOptionId, setTestOptionId] = useState([]);
	const [topicOptionId, setTopicOptionId] = useState([]);
	const [categoryOptionId, setCategoryOptionId] = useState([]);
	const [questionId] = useState(props?.location?.state?.questionId?
		props.location.state.questionId:
		sessionStorage.getItem("questionId"));
	const [questionDetails, setQuestionDetails] = useState([]);
	const [correctOption, setCorrectOption] = useState(null);
	const [testId, setTestId] = useState(null);
	const [categoryId, setCategoryId] = useState(null);
	const [topicId, setTopicId] = useState(null);
	const [featuredImg, setFeaturedImg] = useState(null);
	// const [sequenceAnswer,setSequenceAnswer]=useState([]);
	const [questionInfo, setQuestionInfo] = useState({ 
		question: '',
		answer: '', 
	});
	const [displayCloseButton,setDisplayCloseButton] = useState(false);
	const [disableAddButton,setDisableAddButton] = useState(true);
	const [disableAddButtonOption,setDisableAddButtonOption] = useState(true);
	const [disableAddButtonAnswes,setDisableAddButtonAnswer] = useState(true);
	const [optionMessage, setoptionMessage] = useState("");
	const [drageOption, setDrageOption] = useState([]);
	const [drageAnswer, setDrageAnswer] = useState([]);
	const [drageBlankOption, setDrageBlankOption] = useState([]);
	const [drageCorrectAnsOption, setDrageCorrectAnsOption] = useState([]);
	const [optionAnswerCheckBox,setOptionAnswerCheckBox] = useState([]);
	const [reload, setReload] = useState(false);
	const [answerSequence,setAnswerSequence]=useState([1,2,3,4,5,6,7,8]);
	const [isExactDragAndDrop,setIsExactDragAndDrop]=useState(false);
	const [isLoading,setIsLoading]=useState(false);
	// Image upload
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState("");
	useEffect(() => {
		if(props?.location?.state){
			fetchTestTopicCategoryList();
			if(props.location.state?.qType){
				arrayOption(null,"add");
			}else if(props.location.state?.questionId){
				fetchQuestionDetails();
				sessionStorage.setItem("questionId", props.location.state?.questionId);
				sessionStorage.setItem("questionImage", props.location.state?.questionImage);
			}
		}
	}, []);
	const handleFeaturedImageChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if(info.file.status === 'done') {
			const file = info.file.originFileObj;
			const url = URL.createObjectURL(file);
			if(url) {
				setLoading(false);
				setImageUrl(url);
				setFeaturedImg(file);
			} 
		}
	};
	const uploadButtonFeatured = (
		<div>
			{loading ? <LoadingOutlined/> : <PlusOutlined/>}
			<h5
				style={{ marginTop: 8, }}
			>
				Featured Image
			</h5>
		</div>
	);
	const listOptions = (key,value,optionValue) =>{
		return <Option key={key} value={value}>{optionValue}</Option>;
	};
	const fetchTestTopicCategoryList = () =>{
		// fetching list of category's.
		fetchAdminApi("/test-listing?type=category_options", "get").then((res) => {
			if (res && res.data) {
				setCategoryOptionId(res.data);
			}
			else {
				setCategoryOptionId([]);
			}
		});
		// fetching list of topic's.
		fetchAdminApi("/test-listing?type=topic_options", "get").then((res) => {
			if (res && res.data) {
				setTopicOptionId(res.data);
			}
			else {
				setTopicOptionId([]);
			}
		});
		fetchAdminApi("/test-listing?type=test_options", "get").then((res) => {
			// fetching list of test's.
			if (res && res.data) {
				setTestOptionId(res.data);
			}
			else {
				setTestOptionId([]);
			}
		});
	};
	const fetchQuestionDetails = () =>{
		fetchAdminApi(`/get-questions/${questionId}`, "get").then((res) => {
			// fetching list of test's.
			if (res && res.data && res.data[0].questions&&res.data[0].questions.length>0) {
				setQuestionDetails(res.data[0].questions);
				setCorrectOption(res.data[0].questions[0].correct_option);
				includeinSelectedValue(res.data[0].questions[0]);
				arrayOption(res.data[0].questions);
				setCaseStudyData(res.data[0].questions[0].case_study_content);
				setImageUrl(res.data[0].questions[0].featured_img_data);
				if(res.data[0].questions[0].q_type==="4"){
					if(res.data[0].questions[0].correct_option&&res.data[0].questions[0].correct_option.length>0){
						for (let i = 0; i < res.data[0].questions[0].correct_option.length; i++) {
							for (let j = 0; j < answerSequence.length; j++) {
								if(Number(res.data[0].questions[0].correct_option[i])===answerSequence[j]){
									answerSequence.splice(j,1);
								}
							}
						}
						setAnswerSequence(answerSequence);
					}
				}else if(res.data[0].questions[0].q_type==="2"){
					setSelectedMultiSelect(res.data[0].questions[0].correct_option);
				}else if(res.data[0].questions[0].q_type === "5"){
					setoptionMessage("(Select the Blank Option)");
					setDrageCorrectAnsOption(res.data[0].questions[0].correct_option[2].split(","));
					setDrageBlankOption(res.data[0].questions[0].correct_option[0].split(","));
					if(res.data[0].questions[0].correct_option&&res.data[0].questions[0].correct_option.length>0){
						for (let i = 0; i < res.data[0].questions[0].correct_option.length; i++) {
							if(res.data[0].questions[0].correct_option[i].includes("/")){
								setIsExactDragAndDrop(true);
								break;
							}
						}
						setAnswerSequence(answerSequence);
					}
				}
				// dragAndDropInitailData(res.data);
				form.setFieldsValue({ 
					test: res.data[0].questions[0].test_id,
					category: res.data[0].questions[0].category_id,
					topic: res.data[0].questions[0].topic_id,
					quillquestion: res.data[0].questions[0].question_text,
					explanation: res.data[0].questions[0].explanation?.replace( /(<([^>]+)>)/ig, ''), 
					caseStudy:res.data[0].questions[0].case_study_content?res.data[0].case_study_content?.replace( /(<([^>]+)>)/ig, ''):'None'
				});
			}
			else {
				setQuestionDetails([]);
			}
		});
	};
	const includeinSelectedValue = (data) =>{
		const keysMap = {
			is_full_length_test: "fullLength",
			is_tutorial: "tutorial",
			is_short_length_test: "shortLength",
			is_qod: "qod",
			is_mock: "MockTest",
		};
		const array = Object.keys(data)
			.filter(key => data[key] === "t" && keysMap[key])
			.map(key => keysMap[key]);
		setSelectedValue(array);
	};
	const arrayOption =(data,type)=>{
		let optionArray = [];
		if(type==="add"){
			if(props.location.state?.qType==="4"){
				for (let index = 0; index < 4; index++) {
					optionArray.push({
						option:"",
						answer:null,
					});
				}
			}else{
				for (let index = 0; index < 4; index++) {
					optionArray.push("");
				}
			}
			drageAndDropInitialValues(data,optionArray);
			setOptions(optionArray);
		}else{
			if(data[0].q_type&&data[0].q_type==="4"){
				for (let i = 0; i < constants.optionKeys.length; i++) {
					const optionKey = constants.optionKeys[i];
					if (data[0][optionKey]) {
						optionArray.push({
							option: data[0][optionKey],
							answer: data[0].correct_option && data[0].correct_option.length > i ? 
								Number(data[0].correct_option[i]) : null
						});
					}
				}
			}else{
				for (let i = 0; i < constants.optionKeys.length; i++) {
					const optionKey = constants.optionKeys[i];
					if (data[0][optionKey]) {
						optionArray.push(data[0][optionKey]);
					}
				}
			}
			drageAndDropInitialValues(data,optionArray);
			setOptions(optionArray);
		}
	};
	const drageAndDropInitialValues = (data,optionArray) =>{
		if(props?.location?.state?.qType==="5"){
			dragAndDropOptions("add",optionArray,data);
		}else if (data&&data[0]?.q_type ==="5"){
			dragAndDropOptions("edit",optionArray,data);
		} 
	};
	const dragAndDropOptions=(type,optionArray,data)=>{
		const optionArrayA = [];
		const optionArrayB = [];
		let tempArray = [];// just to check
		for (let i = 0; i < optionArray.length ; i++) {
			let text = optionArray[i];
			let answer_array = type==="edit"?text.split("|"):"";// converting one array into two arrays for options and answers 
			let option_array = type==="edit"?answer_array.splice(0,1):"";
			let tempObj = { isBlanck:type==="edit"?option_array[0].replace( /(<([^>]+)>)/ig, ''):"" };// just to check
			tempArray.push(tempObj);// just to check
			optionArrayA.push(answer_array);//right main array(correct annswers)
			if(type==="edit"){
				if (option_array[0].replace( /(<([^>]+)>)/ig, '') === "~"){// checking if option array has ~ than sending "blanck optopn" to display blank option
					option_array[0] = "Blank Option";
				}
			}
			optionArrayB.push(option_array);//left main array(options)
		}
		let count = 0;
		let correct_option = type==="add"?"":data[0]?.correct_option[1]?.split(",");// just to check
		for (let i = 0; i < tempArray.length ; i++) {
			if(tempArray[i].isBlanck === "~"){
				let tempCorrect = correct_option[count].length > 1 ? correct_option[count].split("/"):[correct_option[count]];
				tempArray[i].correct = tempCorrect;
				count = count +1;
			}else{
				tempArray[i].correct = [];
			}
			// tempArray[i].option = data[0].correct_option[2].split(",");
		}
		setOptionAnswerCheckBox(tempArray);
		setDrageAnswer(optionArrayA.flat());
		setDrageOption(optionArrayB.flat());
	};
	const questionChange = (value) => {
		setQuestionInfo({
			...questionInfo,
			question:value
		});
	};
	const onchangeTest = (testValue) =>{
		setTestId(testValue);
	};
	const onchangeTopic = (topicValue) =>{
		setTopicId(topicValue);
	};
	const onchangeCategory = (categoryValue) =>{
		setCategoryId(categoryValue);
	};
	//Validation Checks 
	const handleQuestionValidation=(_rule,value,callback)=>{
		try {
			if(value){
				if(value.trim()===""||value.trim()==="<p><br></p>"){
					throw new Error(constants.QuestionRequired);
				}
			}else{
				throw new Error(constants.QuestionRequired);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleAnswerValidation=(_rule,value,callback)=>{
		try {
			if(value){
				if(value.trim()===""||value.trim()==="<p><br></p>"){
					throw new Error(constants.AnswerRequired);
				}
			}else{
				throw new Error(constants.AnswerRequired);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const onChangeIncludeIn = (checkedValues) =>{
		setSelectedValue(checkedValues);
	};
	const onChangeMultiSelect = (checkedValues) =>{
		setSelectedMultiSelect(checkedValues);
		setCorrectOption(checkedValues);
	};
	const onChangeAnswer = (e) => {
		setCorrectOption(e.target.value);
	};
	const oneAnswerOption = (disable) => {
		return <ReactQuill readOnly = {disable} theme="snow" modules={constants.ReactQuillModules} formats={constants.ReactQuillFormats}/>;
	};
	const singleSelect = (f,i,field,remove) =>{
		return <Card 	
			size="small"
			title={<Radio value={f.toString()}>{String.fromCharCode(64 + i)}</Radio>}
			extra={
				displayCloseButton &&(<Popconfirm title="Are You Sure to delete?" onConfirm={() => remove(field.name)}>
					<Button type="text" danger icon={<CloseOutlined/>}></Button>
				</Popconfirm>)
			}><Form.Item 	rules={[
				{
					required: true,
					message: "",
				},
				{ validator: handleAnswerValidation },
			]} {...field}  >{oneAnswerOption()}</Form.Item>
		</Card>;
	};
	const multiSelect = (f,i,field,remove)=>{
		return <Card 	
			size="small"
			title={<Checkbox value={f.toString()}>{String.fromCharCode(64 + i)}</Checkbox>}
			extra={
				displayCloseButton &&(<Popconfirm title="Are You Sure to delete?" onConfirm={() => remove(field.name)}>
					<Button type="text" danger icon={<CloseOutlined/>}></Button>
				</Popconfirm>)
			}>
			<Form.Item rules={[
				{
					required: true,
					message: "",
				},
				{ validator: handleAnswerValidation },
			]} {...field}  >{oneAnswerOption()}
			</Form.Item>
		</Card>;
	};
	const arrangeSequence = (f,i,field,remove)=>{
		let optionsValue=form.getFieldValue("options");
		return <Card 	
			size="small"
			title={String.fromCharCode(64 + i)}
			extra={
				displayCloseButton &&(<Popconfirm title="Are You Sure to delete?" onConfirm={() => remove(field.name)}>
					<Button type="text" danger icon={<CloseOutlined/>}></Button>
				</Popconfirm>)
			}>
			<Form.Item rules={[
				{
					required: true,
					message: "",
				},
				{ validator: handleAnswerValidation },
			]} {...field}name={[field.name, "option"]}
			fieldKey={[field.fieldKey, "option"]} initialValue='' >{oneAnswerOption()}
			</Form.Item>
			<Form.Item 
				name={[field.name, "answer"]}
				fieldKey={[field.fieldKey, "answer"]} 
				rules={[
					{
						required: false,
						message: "",
					},
				]}initialValue=''><Input disabled={optionsValue&&optionsValue.length>0&&optionsValue[field.fieldKey]&&optionsValue[field.fieldKey].answer?true:false} onClick={()=>{handleClickSequenceCorrectOption(field);}}/>
			</Form.Item>
		</Card>;
	};
	const handleClickSequenceCorrectOption=(field)=>{
		let optionsValue=form.getFieldValue("options");
		for (let j = 0; j < answerSequence.length; j++) {
			if(optionsValue[field.key]){
				if(optionsValue[field.key].answer!==answerSequence[j]){
					optionsValue[field.key].answer=answerSequence[j];
					answerSequence.splice(j,1);
					setAnswerSequence(answerSequence);
					break;
				}
			}
		}
		form.setFieldsValue({ options:optionsValue });
		setOptions(optionsValue);
		setReload(!reload);
	};
	const onChangeDrageAndDrop = (checkedValues) =>{
		setDrageBlankOption(checkedValues);
	};
	const check = (e,key) =>{
		if (e.target.checked === false){
			form.setFieldsValue({ dragOptions:drageOption });
			drageOption[key] = "";
			optionAnswerCheckBox[key].isBlanck = "";
		}else{
			drageOption[key] = "Blank Option";
			optionAnswerCheckBox[key].isBlanck = "~";
			form.setFieldsValue({ dragOptions:drageOption });
		}
	};
	const onChangeCorrectAnswerOption = (checkedValues,key) => {
		setReload(!reload);
		let temp = optionAnswerCheckBox;
		temp[key].correct = checkedValues;
		let match =false;
		for (let index = 0; index < temp.length; index++) {
			if(temp[index].correct.length>1){
				match=true;
				break;
			}
		}
		if(match){
			setIsExactDragAndDrop(true);
		}else{
			setIsExactDragAndDrop(false);
		}
		setOptionAnswerCheckBox(temp);
	};
	const onChangeDrageCorrectAnsOption = (checkedValues) =>{
		setReload(!reload);
		setDrageCorrectAnsOption(checkedValues);
	};
	const addOption =(add) =>{
		let array ={
			isBlanck: '',
			correct: []
		};
		optionAnswerCheckBox.push(array);
		add(null);
	};
	const dragAndDrop = ()=>{
		return <> 
			<Row gutter ={24}>
				<Col span ={12}>
					<Form.List name="dragOptions">
						{(fields, { add, remove }) => {
							return <>
								<Checkbox.Group className="w-100" value = {drageBlankOption} onChange = {onChangeDrageAndDrop}>
									{fields.map(( field,index) => {
										let drage = drageOption[field.key];
										let fieldValue = field.key + 1;
										let indexValue = index +1;
										if(indexValue === 8){
											setDisableAddButtonOption(false);
										}else{
											setDisableAddButtonOption(true);
										}
										return <Form.Item key={fieldValue}>
											<Card 	
												size="small"
												title={<Checkbox onChange = { (e) =>check(e,field.key)} value={fieldValue.toString()}>{String.fromCharCode(64 + indexValue)}</Checkbox>}
												extra={
													indexValue > questionDetails[0]?.opt_cnt.charAt(0) && indexValue === fields.length || indexValue>drageOption.length&& indexValue === fields.length ? (<Popconfirm title="Are You Sure to delete?" onConfirm={() => remove(field.name)}>
														<Button type="text" danger icon={<CloseOutlined/>}></Button>
													</Popconfirm>):null
												}>
												<Form.Item rules={[
													{
														required: true,
														message: "",
													},
													{ validator: handleAnswerValidation },
												]} {...field}>{drage === "Blank Option" ? <Input disabled = {true}/>:oneAnswerOption(drage?.includes("~")?true:false)}
												</Form.Item>
												{drage === "Blank Option" &&(
													<Checkbox.Group options = {drageCorrectAnsOption} value = {optionAnswerCheckBox[index]?.correct} onChange = {(e) =>{onChangeCorrectAnswerOption(e,index);}}/>
												)}
											</Card>
										</Form.Item>;
									})}
								</Checkbox.Group>
								<Form.Item className="text-right">
									{ disableAddButtonOption && (<Button onClick={() => {addOption(add);}} icon={<PlusOutlined/>}/>)}
								</Form.Item>
							</>;
						}}
					</Form.List>
				</Col>
				<Col span ={12}>
					<Form.List name="dragAnswer">
						{(fields, { add, remove }) => (
							<>
								<Checkbox.Group value ={drageCorrectAnsOption} className="w-100" onChange = {onChangeDrageCorrectAnsOption}>
									{fields.map((field,index) => {
										let fieldValue = field.key + 1;
										let indexValue = index +1;
										if(indexValue === 8){
											setDisableAddButtonAnswer(false);
										}else{
											setDisableAddButtonAnswer(true);
										}
										return <Form.Item key={fieldValue} >
											<Card 	
												size="small"
												title={<Checkbox value={fieldValue.toString()}>{String.fromCharCode(64 + indexValue)}</Checkbox>}
												extra={
													indexValue > questionDetails[0]?.opt_cnt.charAt(2) && indexValue === fields.length || indexValue>drageAnswer.length&& indexValue === fields.length  ?(<Popconfirm title="Are You Sure to delete?" onConfirm={() => remove(field.name)}>
														<Button type="text" danger icon={<CloseOutlined/>}></Button>
													</Popconfirm>):null
												}>
												<Form.Item rules={[
													{
														required: true,
														message: "",
													},
													{ validator: handleAnswerValidation },
												]} {...field}  >{oneAnswerOption()}
												</Form.Item>
											</Card>
										</Form.Item>;
									})}
								</Checkbox.Group>
								<Form.Item className="text-right">
									{ disableAddButtonAnswes && (<Button onClick={() => add(null)} icon={<PlusOutlined/>}/>)}
								</Form.Item>
							</>
						)}
					</Form.List>  
				</Col>
			</Row>
		</>;
	};
	const optionType = (f,i,field,remove) =>{
		switch (props?.location?.state?.qType?props.location.state.qType:questionDetails[0]?.q_type){
		case "1":
			setoptionMessage("(Select only one answer.)");
			return singleSelect(f,i,field,remove);
		case "2":
			setoptionMessage("(Select one or more answers.)");
			return multiSelect(f,i,field,remove);
		case "5":
			setoptionMessage("(Select the Blank Option)");
			break;
		case "4":
			setDisplayCloseButton(true);
			setoptionMessage("(Select the Options in order)");
			return arrangeSequence(f,i,field,remove);
		}
	};
	const handleResetAnswerOfArrangeSequence=()=>{
		for (let i = 0; i < options.length; i++) {
			options[i].answer=null;			
		}
		setOptions(options);
		setAnswerSequence([1,2,3,4,5,6,7,8]);
		setReload(!reload);
	};
	const onFinishAddOrEditQuestion=(values)=>{
		setIsLoading(true);
		let correctDrageOption = [];
		if(optionAnswerCheckBox.length > 0){
			for (let i = 0;i < optionAnswerCheckBox.length; i++){
				if (optionAnswerCheckBox[i].isBlanck === "~" ) {
					correctDrageOption = correctDrageOption.concat(optionAnswerCheckBox[i].correct.join("/")); 
				}
			}
		}
		let finalOtionArray = [];
		let drageOptionCount =null;
		let finalCorrectOptionArray = [];
		if (questionDetails[0]?.q_type === "5"||props?.location?.state?.qType==="5"){
			finalCorrectOptionArray[0] = drageBlankOption.toString();
			finalCorrectOptionArray[1] = correctDrageOption.toString();
			finalCorrectOptionArray[2] = drageCorrectAnsOption.toString();
			let option = values.dragAnswer;
			let correctAnswer = values.dragOptions;
			let opthcount1 = correctAnswer.length;
			let opthcount2 = option.length;
			drageOptionCount = `${opthcount1.toString()},${opthcount2.toString()}`;
			let temp = [];
			for (let i = 0;i < correctAnswer.length; i++){
				if (correctAnswer[i] === "Blank Option"){
					temp[i] = "~";
					finalOtionArray.push(temp[i]+"|"+option[i]);
				}else{
					finalOtionArray.push(correctAnswer[i]+"|"+option[i]);
				}
			}
		}else if(questionDetails[0]?.q_type === "4"||props?.location?.state?.qType==="4"){
			if(values.options){
				for (let i = 0; i < values.options.length; i++) {
					if(values.options[i].answer){
						finalCorrectOptionArray.push(values.options[i].answer.toString());
					}
				}
			}
		}
		let payload = null;
		payload={
			question_id:questionId,
			category_id: categoryId !== null ? categoryId: values?.category,
			topic_id:topicId !== null ? topicId:values?.topic,
			test_id:testId !== null ? testId:values?.test,
			explanation:values?.explanation,
			question_text:values?.quillquestion, 
			option_first:finalOtionArray.length > 0 ? finalOtionArray[0]: questionDetails[0]?.q_type==="4"||props.location.state?.qType==="4"?values.options[0]?values.options[0].option?values.options[0].option:"":"":values.options[0],
			option_second:finalOtionArray.length > 0 ? finalOtionArray[1]: questionDetails[0]?.q_type==="4"||props.location.state?.qType==="4"?values.options[1]?values.options[1].option?values.options[1].option:"":"":values.options[1],
			option_third:finalOtionArray.length > 0 ? finalOtionArray[2]: questionDetails[0]?.q_type==="4"||props.location.state?.qType==="4"?values.options[2]?values.options[2].option?values.options[2].option:"":"":values.options[2],
			option_fourth:finalOtionArray.length > 0 ? finalOtionArray[3]: questionDetails[0]?.q_type==="4"||props.location.state?.qType==="4"?values.options[3]?values.options[3].option?values.options[3].option:"":"":values.options[3],
			option_fifth:finalOtionArray.length > 0 ? finalOtionArray[4]: questionDetails[0]?.q_type==="4"||props.location.state?.qType==="4"?values.options[4]?values.options[4].option?values.options[4].option:"":"":values.options[4],
			option_sixth:finalOtionArray.length > 0 ? finalOtionArray[5]: questionDetails[0]?.q_type==="4"||props.location.state?.qType==="4"?values.options[5]?values.options[5].option?values.options[5].option:"":"":values.options[5],
			option_seventh:finalOtionArray.length > 0 ? finalOtionArray[6]: questionDetails[0]?.q_type==="4"||props.location.state?.qType==="4"?values.options[6]?values.options[6].option?values.options[6].option:"":"":values.options[6],
			option_eighth:finalOtionArray.length > 0 ? finalOtionArray[7]: questionDetails[0]?.q_type==="4"||props.location.state?.qType==="4"?values.options[7]?values.options[7].option?values.options[7].option:"":"":values.options[7],
			correct_option:finalCorrectOptionArray.length > 0 ? finalCorrectOptionArray: correctOption,
			q_type:props?.location?.state?.qType?props.location.state.qType:questionDetails[0]?.q_type,
			opt_cnt:drageOptionCount !== null ? drageOptionCount : values?.options.length,
			is_full_length_test:selectedValue.includes("fullLength") === true?"t":"f",
			is_mock:selectedValue.includes("MockTest") === true?"t":"f",
			is_short_length_test:selectedValue.includes("shortLength") === true?"t":"f",
			is_tutorial:selectedValue.includes("tutorial") === true?"t":"f",
			is_qod:selectedValue.includes("qod") === true?"t":"f",
			is_verified:status,
			is_archived:props?.location?.state?.qType?"f":questionDetails[0]?.is_archived
		};
		let url=``;
		let method="";
		if(props?.location?.state?.qType){
			let userId = getCookie(COOKIE.UserId);
			payload.created_by=userId;
			url=`/add-questions`;
			method="POST";
		}else{
			url=`/edit-questions/${questionId}`;
			method="PUT";
		}
		fetchAdminApi(url, method, payload).then(
			(res) => {
				if (res && res.code === 200) {
					if(featuredImg){
						let qId=props?.location?.state?.qType?res.Question_id:questionId;
						saveFeaturedImage(qId,res.message);
					}else{
						setIsLoading(false);
						message.success(res.message);
						history.push({ pathname: "/question-list" });
					}
				}else{
					setIsLoading(false);
					if(props?.location?.state?.qType){
						message.error(constants.saveFailedAddQuestion);
					}else{
						message.error(constants.saveFailedEditQuestion);
					}
				}
			}
		).catch(()=>{
			setIsLoading(false);
		});
	};
	const onChangeIsExactCheck=(e)=>{
		setIsExactDragAndDrop(e.target.checked);
	};
	const textOptionMessage = () =>{
		return <p><Text>Options <Text type="secondary">{optionMessage}</Text></Text></p>;
	};
	const messageOption = () => {
		switch (props?.location?.state?.qType||questionDetails[0]?.q_type){
		case "1":
			return textOptionMessage();
		case "2":
			return textOptionMessage();
		case "4":
			return <div className = "justify-between">
				{textOptionMessage()}
				<Button onClick={()=>{handleResetAnswerOfArrangeSequence();}}>Reset</Button>
			</div>;
		case "5":
			return <div className = "justify-between">
				{textOptionMessage()}
				<p><Text type="secondary">(Select the Correct Answer)</Text></p>
			</div>;
		}
	};
	const initialFormValues = () =>{
		let obj = {};
		if(props?.location?.state?.qType==="5"||questionDetails&&questionDetails[0]?.q_type === "5"){
			obj.dragOptions = drageOption;
			obj.dragAnswer = drageAnswer;
		}else{
			obj.options = options;
		}
		return obj;
	};
	const saveFeaturedImage = async (questionId,messageData) => {
		let URL = `${constants.AdminApiUrl}/upload-question-image/${questionId}`;
		let formData = new FormData();
		formData.append("q_image", featuredImg);
		axios({
			method: "POST",
			url: URL,
			headers:{
				accept: "application/json",
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		}).then((res) =>{
			if(res?.data?.code===200){
				setIsLoading(false);
				message.success(messageData);
				history.push({ pathname: "/question-list" });
			}else{
				setIsLoading(false);
				if(props?.location?.state?.qType){
					message.error(constants.saveFailedAddQuestion);
				}else{
					message.error(constants.saveFailedEditQuestion);
				}
			}
		});
	};
	return (
		<div className='gray-bg'>
			<div className="p-3">
				<Breadcrumb>
					<Breadcrumb.Item><a onClick={() => {
						history.push({ pathname: "/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick = { () => {history.push("/test-list");}}>Test List</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick = { () => {history.push("/question-list");}}>Question List</a></Breadcrumb.Item>
					<Breadcrumb.Item>{props?.location?.state?.qType?constants.AddQuestion:constants.EditQuestion}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<div className='px-3'>
				<Card
					size="small"
					title={props?.location?.state?.qType?constants.AddQuestion:constants.EditQuestion+"- Question Id: "+questionId}
					extra={questionId&&<Button type="primary" icon={<FileSearchOutlined/>}
						onClick={()=>history.push({
							pathname: "/preview-question",
							state: { questionId:questionId },
						})}
					>Preview Question</Button>}
					// extra={<Button type="primary" icon={<FileSearchOutlined/>}>Preview Question</Button>}
					bodyStyle={{ padding: 0 }}
				>
					{options.length>0 &&(
						<Form
							layout="vertical"
							form={form}
							onFinish={onFinishAddOrEditQuestion}
							initialValues={initialFormValues()}
						>
							<Row gutter={[16, 16]} className='px-3 pt-3'>
								<Col xs={24} lg={15} xl={15}>
									<Row gutter={[16, 16]}>
										<Col xs={24} lg={6} xl={6}>
											<Form.Item label="Test" name="test">
												<Select
													onChange={onchangeTest}
												>
													<Option value={""}>--Test--</Option>
													{testOptionId.map(option => {
														return listOptions(option.test_id,option.test_id,option.test_title);
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={24} lg={6} xl={6}>
											<Form.Item label="Category" name = "category">
												<Select
													onChange={onchangeCategory}
												>
													<Option value={""}>--Category--</Option>
													{categoryOptionId.map(option => {
														return listOptions(option.category_id,option.category_id,option.category_abbreviation);												
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={24} lg={12} xl={12}>
											<Form.Item label="Topic" name="topic">
												<Select
													onChange={onchangeTopic}
												>
													<Option value={""}>--Topic--</Option>
													{topicOptionId.map(option => {
														return listOptions(option.topic_id,option.topic_id,option.topic_abbreviation);
													})}
												</Select>
											</Form.Item>
										</Col>
									</Row>
								</Col>
								<Col xs={24} lg={9} xl={9}>
									<Form.Item label="Include in">
										<Checkbox.Group value={selectedValue} onChange={onChangeIncludeIn} >
											<Row>
												<Col span={8}>
													<Checkbox value="fullLength">{constants.FullLengthTests}</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox  value="shortLength">Short tests</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox  value="tutorial">{constants.Tutorials}</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox value="qod">QOD</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox  value="MockTest">Mock Tests</Checkbox>
												</Col>
											</Row>
										</Checkbox.Group>
									</Form.Item>
								</Col>
								<Col xs={24} lg={15} xl={15}></Col>
								{questionDetails&&questionDetails.length>0&&questionDetails[0].q_type&&questionDetails[0].q_type==="5"||
								props?.location?.state?.qType==="5"?							
									<Col xs={24} lg={9} xl={9}>
										<Checkbox onChange={onChangeIsExactCheck} checked={isExactDragAndDrop}>Exact</Checkbox>
									</Col>:""}
								<Col xs={24} lg={24} xl={24}>
									<Form.Item label={constants.CaseStudyPopupHeader} name="caseStudy">
										<Select
											defaultValue="None"
										>
											<Option value="None">None</Option>
											{caseStudyData !== null &&(
												<Option title={caseStudyData.replace( /(<([^>]+)>)/ig, '')} value={caseStudyData.replace( /(<([^>]+)>)/ig, '')}>{caseStudyData && caseStudyData.slice(0, 200).replace( /(<([^>]+)>)/ig, '')}...</Option>
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} lg={24} xl={24}>
									<Form.Item
										label="Question"
										name="quillquestion"
										rules={[
											{
												required: false,
												message: "",
											},
											{ validator: handleQuestionValidation },
										]}
										initialValue=''
									>
										<ReactQuill theme="snow" value={questionInfo.question} onChange={questionChange} modules={constants.ReactQuillModules} formats={constants.ReactQuillFormats}/>
									</Form.Item>
								</Col>
								<Col xs={24} lg={24} xl={24}>
									{messageOption()}
									{questionDetails[0]?.q_type === "5"||props?.location?.state?.qType==="5"?
										dragAndDrop():
										<Form.List name="options">
											{(fields, { add, remove }) => (
												<>
													<Radio.Group onChange={onChangeAnswer} value={correctOption} className = "w-100">
														<Checkbox.Group value ={selectedMultiSelect} onChange={onChangeMultiSelect} className="w-100">
															{fields.map((field,index) => {
																let f = field.key + 1;
																let i = index +1;
																return <Form.Item key={f} >
																	{props?.location?.state?.qType?
																		fields.length  > options.length ? setDisplayCloseButton(true):setDisplayCloseButton(false):
																		fields.length  > questionDetails[0]?.opt_cnt ? setDisplayCloseButton(true):setDisplayCloseButton(false) }
																	{fields.length === 8 ? setDisableAddButton(false):setDisableAddButton(true) }
																	{
																		optionType(f,i,field,remove)
																	}
																</Form.Item>;
															})}
														</Checkbox.Group>
													</Radio.Group>
													<Form.Item className="text-right">
														{ disableAddButton && (<Button onClick={() => add(null)} icon={<PlusOutlined/>}/>)}
													</Form.Item>
												</>
											)}
										</Form.List>
									}	
								</Col>
								{questionDetails[0]?.q_type === "4"||props?.location?.state?.qType==="4"?
									<Col xs={24} lg={24} xl={24}>
										<Space>
											<span className='mr-4'>Sequence:</span>
											<Card size='small'>
												{options&&options.length>0&&options.map((option,index)=>{
													return(<InputNumber className="input-width"key={index} bordered={false} readOnly value={option.answer?option.answer:""}/>);
												})}
											</Card>
										</Space>
									</Col>
									:""}
								<Col xs={24} lg={12} xl={12}>
									<div className='place-center text-center'>
										<Upload
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											beforeUpload={beforeUpload}
											onChange={handleFeaturedImageChange}
											customRequest={dummyRequest}
										>
											{imageUrl ? (
												<img
													src={imageUrl}
													alt="avatar"
													style={{ width: '100%' }}
												/>
											) : (
												uploadButtonFeatured
											)}
										</Upload>
										<p>Featured Image</p>
									</div>
								</Col>
								<Col xs={24} lg={24} xl={24}>
									<Form.Item label="Explanation" name="explanation" initialValue=''>
										<ReactQuill theme="snow" value="" /* onChange={questionChange} */ modules={constants.ReactQuillModules} formats={constants.ReactQuillFormats}/>
									</Form.Item>
								</Col>
							</Row>
							<Divider/>
							<Row gutter={[16, 16]} className='px-3'>
								<Col xs={24} lg={24} xl={24}>
									<Form.Item>
										<div className='text-right'>
											<Space>
												{/* <Button type="primary" icon={<FileSearchOutlined/>}>Preview</Button> */}
												<Button loading={isLoading} type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
												<Button type="primary"  onClick={()=>{history.push({ pathname: "/question-list" });}} icon={<StopOutlined/>}>{constants.CancelBTN}</Button>
												<Button type="primary" htmlType="submit" onClick = {()=>{setStatus("1");}} icon={<FileSearchOutlined/>} className="btn-submit">Verified</Button>
												<Button type="primary" htmlType="submit" onClick = {()=>{setStatus("3");}} icon={<CloseCircleFilled/>} className="navy-btn">Rejected</Button>
												{/* <Button type="primary" icon={<DeleteOutlined/>} danger>Delete</Button> */}
											</Space>
										</div>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					)}
				</Card>
			</div>
		</div>
	);
};
export default EditQuestion;
