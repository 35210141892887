/* eslint-disable no-unused-vars */
/*
File Name: PreviewQuestion.js
Author: Akshay Lotlikar
Modified On: 06/09/2022
Description: This page includes the code to preview the question of all types.
API's used:
1) /get-questions/${questionId}
*/
import React, { useState,useEffect } from "react";
import { Col, Row, Button, Card, Radio, Space, Tooltip, Divider, Drawer, Table, message,Checkbox, Spin, Badge, Modal, Form,Input, AutoComplete, Breadcrumb  } from 'antd';
import { HighlightOutlined, DragOutlined, FlagFilled,LeftOutlined,StrikethroughOutlined, FlagOutlined, ProfileOutlined,EyeInvisibleOutlined,EyeOutlined,SaveOutlined,RightOutlined } from '@ant-design/icons';
import '../../../styles/test.css';
import '../../../styles/pausetest.css';
import constants from "../../../constants/constants";
import Draggables from 'react-draggable';
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from "react-sortable-hoc";
import CustomIcons from "../../../assets/CustomIcons";
import Icon from '@ant-design/icons';
import { Chart } from "react-google-charts";
// import{ COOKIE,getCookie } from "../../services/cookie";
import { fetchAdminApi } from "../../../services/api";
import arrayMove from "array-move";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHistory } from "react-router";
import $ from "jquery";
import { featuredImageUrl, testModalPopup } from "../../../globalFunctions/GlobalFunctions";
import { cloneDeep } from "lodash";
import Loader from "../../Common/Cred/Loader";
const { TextArea } = Input;
const Comment = (props) => (<Icon component={CustomIcons.getsvg("Comment")} {...props}/>);
const DragHandle = sortableHandle(() => (
	<span>
		<DragOutlined className='drag-icon drag-icon-size'/>
	</span>
));
const SortableItem = sortableElement(({ value,indexPos }) => (
	<ul className="test-sortable">
		<li className="flex-vertical-middle">
			<span className="seq-counter">{constants.OptionstoDisplay[indexPos]}</span>
			<DragHandle/>
			<p id={indexPos} className='pl-2 list-item' dangerouslySetInnerHTML={{ __html:value }}></p>
		</li>
	</ul>
));
//Drag area
const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});
const horizontalLayout = {
	labelCol: { span: 10, },
	wrapperCol: { span: 14, },
};
const PreviewQuestion = (props) => {
	const history = useHistory();
	const [count, setCount] = useState(0);
	const zbminus = () => {
		if (count < 2) {
			setCount(count + 1);
			if (count === 0) {
				document.body.classList.add('decreaseFont');
			}
			if (count === 1) {
				document.body.classList.add('decreaseFontsmall');
				document.body.classList.remove('decreaseFont');
			}
		}	
	};
	useEffect(() => {
	}, [count]);
	const zb = () => {
		if (count >=0 ) {
			setCount(count - 1);
			if (count === 2) {
				document.body.classList.remove('decreaseFontsmall');
				document.body.classList.add('decreaseFont');
			}
			if (count === 1) {
				document.body.classList.remove('decreaseFont');
			}
		}
	};
		// eye icon toggle
	const [viewTimer,setViewTimer]=useState(true);
	const toggleTimer = () => { 
		setViewTimer(!viewTimer);
	};
	// drawer
	const [visible, setvisible] = useState(false);
	const onClose = () => {
		setvisible(false);
	};
		// notes modal
	const [notesVisible, setnotesVisible] = useState(false);
	const showModal = () => {
		setnotesVisible(true);
		let itemTemp=listQuestions[questionIndex];
		if (itemTemp.notes!==undefined&&itemTemp.notes!==null){
			setNoteValue(itemTemp.notes);
		}
	};
	const hideModal = () => {
		noteForm.resetFields();
		setnotesVisible(false);
	};
	//To set note form
	const setNoteValue=(valueText)=>{
		noteForm.setFieldsValue({ note:valueText });
	}; 
	const [disabled,setIsDisabled]=React.useState(true);
	const [bounds,setBounds]=React.useState({
		left: 0,
		top: 0,
		bottom: 0,
		right: 0 
	});
	//Columns to display the drag and drop type questions
	let columnsOfDragAndDrop = {
		[1]: { items: [] },
		[2]: { items: [] },
		[3]: { items: [] },
		[4]: { items: [] },
		[5]: { items: [] },
		[6]: { items: [] },
		[7]: { items: [] },
		[8]: { items: [] },
		[9]: { items: [] },
	};
	//end of drap and drop code
	const [listQuestions,setQuestions]=useState([]);
	const [questionIndex,setQuestionIndex]=useState(0);
	const [sortitems, setSortItems] = useState([]);
	const [refresh,setRefresh]=useState(false);
	const [categoryDetails,setCategoryDetails]=useState({});
	const [topicDetails,setTopicDetails]=useState({});
	const [columns, setColumns] = useState(columnsOfDragAndDrop);
	const [isSearch,setIsSearch]=useState("false");
	const [displaySearch,setDisplaySearch]=useState("false");
	const [questionIndexList,setQuestionIndexList]=useState([]);//Used for Question Index drawer.
	const [isQuestionIndexOpened,setIsQuestionIndexOpened]=useState("false");
	const [selectedSequenceOption,setSelectedSequenceOption]=useState("");
	const [selectedDragOption,setSelectedDragOption]=useState(null);
	const [timerDisplay,setTimerDisplay]=useState(null);
	const [pauseTest,setPauseTest]=useState(false);
	const [nextAndPreviousLoading,setNextAndPreviousLoading]=useState(false);
	const [completeTest,setCompleteTest]=useState(false);
	const [pauseTestLoading,setPauseTestLoading]=useState(false);
	const draggleRef = React.createRef();
	const [noteForm] = Form.useForm();
	const [ratingsData,setRatingsData]=useState(null);
	const [charData,setChartdata]=useState([]);
	const [correctAnswerList,setCorrectAnswerList]=useState(null);
	const [questionId] = useState(props && props.location && props.location.state ? props.location.state.questionId :sessionStorage.getItem("questionId"));
	useEffect(() => {
		if(document.body.classList.contains('decreaseFont')){
			document.body.classList.remove('decreaseFont');
		}
		if(document.body.classList.contains('decreaseFontsmall')){
			document.body.classList.remove('decreaseFontsmall');
		}
		sessionStorage.setItem("quitTest","false");
		sessionStorage.setItem("isFullLengthTestvalue","false");
		// $(document).keydown(function(event){
		// 	if(event.keyCode===123||event.ctrlKey&&event.shiftKey&&event.keyCode===73||event.ctrlKey&&event.keyCode===67){
		// 		return false;
		// 	}
		// 	else if(event.ctrlKey&&event.shiftKey&&event.keyCode===73){
		// 		return false;
		// 	}
		// 	else if(event.ctrlKey&&event.keyCode===67){
		// 		return false;
		// 	}
		// });
		// document.addEventListener('copy',disableCopy);
		document.addEventListener('contextmenu',disableContextMenu);
		// let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		// if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
		// 	fetchFromLocalStorage();
		// }else{
		if(props&&props.location&&props.location.state&&props.location.state.questionId ){
			let topicDetails={
				questionId:props.location.state.questionId,
				attempt:1,
				topicTitle:"PIET",
				count:25,
				isRetake:false,
				isTimed:true,
			};
			setTopicDetails(topicDetails);
			sessionStorage.setItem("topicDetails", JSON.stringify(topicDetails ));
			fetchStartTestData();
		}
		// }
	}, []);
	useEffect(() => {
		return () => {
			document.removeEventListener('contextmenu',disableContextMenu);
			// document.removeEventListener('copy',disableCopy);
			$(document).off('keydown');
			let testCount=sessionStorage.getItem("totalTestsTaken");
			let viewedQod=sessionStorage.getItem("viewedQOD");
			let pay =JSON.parse(sessionStorage.getItem("pay"));
			let feedbackpopupshow=sessionStorage.getItem("feedbackShown");
			sessionStorage.clear();
			sessionStorage.setItem("totalTestsTaken",testCount);
			sessionStorage.setItem("viewedQOD",viewedQod);
			sessionStorage.setItem("pay", JSON.stringify(pay));
			sessionStorage.setItem("feedbackShown",feedbackpopupshow);
		};
	}, []);
	// function disableCopy(e) {
	// 	e.preventDefault();
	// }
	function disableContextMenu(e) {
		e.preventDefault();
	}
	// arrange the sequence
	const onSortEnd = ({ oldIndex, newIndex }) => {
		setSortItems(arrayMove(sortitems, oldIndex, newIndex));
		sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
		setQuestions(listQuestions);
		setRefresh(!refresh);
	};
	useEffect(() => {
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="4"){
			if(isSearch==="true"||isQuestionIndexOpened==="true"){
				listQuestions[questionIndex].selectedAnswer=sortitems;
			}else{
				listQuestions[questionIndex].selectedAnswer=sortitems;
				listQuestions[questionIndex].oldSelectedAnswer=sortitems;
			}
			setQuestions(listQuestions);
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
		}
	}, [sortitems]);
	//end of arrange the sequence code
	//To fetch the Questions to answer the test of the category type.
	const fetchStartTestData=()=>{
		setNextAndPreviousLoading(true);
		fetchAdminApi(`/get-questions/${questionId}`, "get").then((res) => {
			if (res && res.code && res.code === 200) {
				if(res.data&&res.data&&res.data.length>0){
					for (let i = 0; i < res.data[0].questions.length; i++) {
						res.data[0].questions[i].isUpdatedToApi=false;
						res.data[0].questions[i].selectedAnswer=[];
						res.data[0].questions[i].markedForLater=false;
						let correct_option=[];
						if(res.data[i].q_type==="5"){
							correct_option=res.data[0].questions[i].correct_option;
						}else{
							for (let j = 0; j < res.data[0].questions[i].correct_option.length; j++) {
								correct_option.push(Number(res.data[0].questions[i].correct_option[j]));
							}
						}
						res.data[0].questions[i].correct_option=correct_option;
						if(res.data[0].questions[i].q_type==="5"){
							if(res.data[0].questions[i].option_first){
								res.data[0].questions[i].option_one=res.data[0].questions[i].option_first.substring(res.data[0].questions[i].option_first.indexOf("|")+1);
							}
							if(res.data[i].option_second){
								res.data[i].option_two=res.data[0].questions[i].option_second.substring(res.data[0].questions[i].option_second.indexOf("|")+1); 
							}
							if(res.data[i].option_third){
								res.data[i].option_three=res.data[0].questions[i].option_third.substring(res.data[0].questions[i].option_third.indexOf("|")+1);
							}
							if(res.data[i].option_fourth){
								res.data[i].option_four=res.data[0].questions[i].option_fourth.substring(res.data[0].questions[i].option_fourth.indexOf("|")+1);
							}
							if(res.data[i].option_fifth){
								res.data[i].option_five=res.data[0].questions[i].option_fifth.substring(res.data[0].questions[i].option_fifth.indexOf("|")+1); 
							}
							if(res.data[i].option_sixth){
								res.data[i].option_six=res.data[0].questions[i].option_sixth.substring(res.data[0].questions[i].option_sixth.indexOf("|")+1); 
							}
							if(res.data[i].option_seventh){
								res.data[i].option_seven=res.data[0].questions[i].option_seventh.substring(res.data[0].questions[i].option_seventh.indexOf("|")+1); 
							}
							if(res.data[i].option_eighth){
								res.data[i].option_eight=res.data[0].questions[i].option_eighth.substring(res.data[0].questions[i].option_eighth.indexOf("|")+1);
							}
						}
					}
					sessionStorage.setItem("listQuestions", JSON.stringify(res.data[0].questions));
					sessionStorage.setItem("questionIndex",0);
					sessionStorage.setItem("questionIndexCount",0);
					sessionStorage.setItem("checkAnswer","false");
					sessionStorage.setItem("checkMarkLater","false");
					sessionStorage.setItem("displaySearch","false");
					sessionStorage.setItem("isSearch","false");
					sessionStorage.setItem("isQuestionIndexOpened","false");
					sessionStorage.setItem("testType","shortLength");
					let hour="0"+0;
					let minute="0"+0;
					let seconds="0"+5;
					setTimerDisplay(hour + ":" + minute + ":" + seconds);
					let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
					setQuestions(listQuestionsFromSession);
					checkQuestionType(res.data[0].questions);
					setNextAndPreviousLoading(false);
					setRatingsData(res.data[0].ratings);
					let tempCorrect="";
					if(res.data[0].questions[0].correct_option.length>1){
						res.data[0].questions[0].correct_option.map((item,index)=>{
							if(tempCorrect===""){
								tempCorrect=tempCorrect+convertToAlphabet(parseInt(item));
							}else{
								tempCorrect=tempCorrect+", "+convertToAlphabet(parseInt(item));
							}
						});
					}else{
						tempCorrect= convertToAlphabet(parseInt(res.data[0].questions[0].correct_option));
					}
					setCorrectAnswerList(tempCorrect);
					let chartData = [
						["Range", "Total"],
					];
					chartData.push(
						['Easy',Number(res.data[0]?.ratings[0]?.rating_1)],
						['Medium',Number(res.data[0]?.ratings[0]?.rating_2)],
						['Difficult',Number(res.data[0]?.ratings[0]?.rating_3)],
					);
					console.log("chart data",chartData);
					setChartdata(chartData);
				}
			} else {
				history.push("/test-list"); 
			}
		});
	};
		//notes modal
	const onStart = (event, uiData) => {
		const { clientWidth, clientHeight } = window?.document?.documentElement;
		const targetRect = draggleRef?.current?.getBoundingClientRect();
		let boundsForNotesModal={
			left: -targetRect?.left + uiData?.x,
			right: clientWidth - (targetRect?.right - uiData?.x),
			top: -targetRect?.top + uiData?.y,
			bottom: clientHeight - (targetRect?.bottom - uiData?.y),
		};
		setBounds(boundsForNotesModal);
	};
	// const fetchFromLocalStorage=()=>{
	// 	// let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
	// 	// let questionIndexFromSession=sessionStorage.getItem("questionIndex");
	// 	let categoryDetailsFromSession=JSON.parse(sessionStorage.getItem("categoryDetails")||"null");
	// 	let topicDetailsFromSession=JSON.parse(sessionStorage.getItem("topicDetails")||"null");
	// 	let isSearchFromSession=sessionStorage.getItem("isSearch");
	// 	let questionIndexListFromSession=JSON.parse(sessionStorage.getItem("questionIndexList")||"[]");
	// 	let isQuestionIndexOpenedFromSession=sessionStorage.getItem("isQuestionIndexOpened");
	// 	let displaySearchFromSession=sessionStorage.getItem("displaySearch");
	// 	setDisplaySearch(displaySearchFromSession);
	// 	setIsQuestionIndexOpened(isQuestionIndexOpenedFromSession);
	// 	setQuestionIndexList(questionIndexListFromSession);
	// 	setIsSearch(isSearchFromSession);
	// 	setCategoryDetails(categoryDetailsFromSession);
	// 	setTopicDetails(topicDetailsFromSession);
	// };
	//Function to check if question type is arrange sequence or drag and drop to display options on page load
	const checkQuestionType=(listOfQuestions)=>{
		let questionIndexFromSession=Number(sessionStorage.getItem("questionIndex"));
		if(listOfQuestions&&listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].q_type==="4"){
			let sortItems=[];
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first){
				sortItems.push({
					index:1,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second){
				sortItems.push({
					index:2,
					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndexFromSession].option_second 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third){
				sortItems.push({
					index:3,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth){
				sortItems.push({
					index:4,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth){
				sortItems.push({
					index:5,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth){
				sortItems.push({
					index:6,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh){
				sortItems.push({
					index:7,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth){
				sortItems.push({
					index:8,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth 
				});
			}
			setSortItems(sortItems);
			setRefresh(!refresh);
		}
		else if(listOfQuestions&&listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].q_type==="5"){
			let Items=[];
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first){
				Items.push({
					id:`${1}`,
					content:`${listOfQuestions[questionIndexFromSession].option_one }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second){
				Items.push({
					id:`${2}`,
					content:`${listOfQuestions[questionIndexFromSession].option_two }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third){
				Items.push({
					id:`${3}`,
					content:`${listOfQuestions[questionIndexFromSession].option_three }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth){
				Items.push({
					id:`${4}`,
					content:`${listOfQuestions[questionIndexFromSession].option_four }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth){
				Items.push({
					id:`${5}`,
					content:`${listOfQuestions[questionIndexFromSession].option_five}`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth){
				Items.push({
					id:`${6}`,
					content:`${listOfQuestions[questionIndexFromSession].option_six}`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh){
				Items.push({
					id:`${7}`,
					content:`${listOfQuestions[questionIndexFromSession].option_seven }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth){
				Items.push({
					id:`${8}`,
					content:`${listOfQuestions[questionIndexFromSession].option_eight}`
				});
			}
			columns[1].items=Items;
			setColumns(columns);
			setRefresh(!refresh);
		}
	};
	//Executes on state change of questionIndex state.
	useEffect(() => {
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="4"){
			let sortItems=[];
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
				sortItems=listQuestions[questionIndex].selectedAnswer;
			}else{
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
					sortItems.push({
						index:1,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
					sortItems.push({
						index:2,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
					sortItems.push({
						index:3,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
					sortItems.push({
						index:4,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
					sortItems.push({
						index:5,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
					sortItems.push({
						index:6,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
					sortItems.push({
						index:7,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
					sortItems.push({
						index:8,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth 
					});
				}
			}
			setSortItems(sortItems);
			setRefresh(!refresh);
		}else if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="5"){
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
				setColumns(listQuestions[questionIndex].columns);
			}else{
				let Items=[];
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
					Items.push({
						id:`${1}`,
						content:`${listQuestions[questionIndex].option_one }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
					Items.push({
						id:`${2}`,
						content:`${listQuestions[questionIndex].option_two }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
					Items.push({
						id:`${3}`,
						content:`${listQuestions[questionIndex].option_three }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
					Items.push({
						id:`${4}`,
						content:`${listQuestions[questionIndex].option_four }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
					Items.push({
						id:`${5}`,
						content:`${listQuestions[questionIndex].option_five}`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
					Items.push({
						id:`${6}`,
						content:`${listQuestions[questionIndex].option_six}`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
					Items.push({
						id:`${7}`,
						content:`${listQuestions[questionIndex].option_seven }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
					Items.push({
						id:`${8}`,
						content:`${listQuestions[questionIndex].option_eight}`
					});
				}
				let columnsObject={
					[1]: { items: [] },
					[2]: { items: [] },
					[3]: { items: [] },
					[4]: { items: [] },
					[5]: { items: [] },
					[6]: { items: [] },
					[7]: { items: [] },
					[8]: { items: [] },
					[9]: { items: [] },
				};
				columnsObject[1].items=Items;
				setColumns(columnsObject);
				setRefresh(!refresh);
			}
		}
	}, [questionIndex]);
	//drag and drop
	// a function to help us with reordering the result
	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		// userSelect: "none",
		width: "calc(50% - 12px)",
		minWidth: '300px',
		marginRight: "12px",		// margin: '1%',
		marginBottom: "15px",
		alignItems: "center",
		flexWrap: "wrap",
		// change background colour if dragging
		background: "white",
		display: "inline-flex",
		border: isDragging ? "none" : "1px solid lightgrey",
		overflow: "hidden",
		borderImage: "none",
		borderRadius: "30px",
		position: 'relative',
		// styles we need to apply on draggables
		...draggableStyle,
	});
	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightgrey" : "white",
		padding: "grid 0",
		marginRight: "-12px",
	});
	const onDragEnd = (result, columnsObject, setColumns) => {
		if (!result.destination) return;
		const { source, destination } = result;
		if (source.droppableId !== destination.droppableId) {
			if(columnsObject[Number(result.destination.droppableId)].items.length===1){
				columnsObject[1].items.push(columns[Number(result.destination.droppableId)].items[0]);
				columnsObject[Number(result.destination.droppableId)].items.splice(0,1);
			}
			const sourceColumn = columnsObject[source.droppableId];
			const destColumn = columnsObject[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			for (let index = 0; index < sourceItems.length; index++) {
				if(sourceItems[index]===null||sourceItems[index]===undefined){
					sourceItems.splice(index,1);
				}
			}
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);
			setColumns({
				...columnsObject,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems
				}
			});
			setRefresh(!refresh);
			let selectedOption={
				...columnsObject,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems
				}
			};
			let selectedAnswer=[];
			Object.entries(selectedOption).forEach(([key, value]) => {
				if(key>1){
					if(value.items&&value.items.length>0){
						for (let j = 0; j < value.items.length; j++) {
							selectedAnswer.push(value.items[j]);
						}
					}
				}
			});
			listQuestions[questionIndex].selectedAnswer=selectedAnswer;
			listQuestions[questionIndex].columns=selectedOption;
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
			setRefresh(!refresh);
		} else {
			return;
		}
	};
	//end of drag and drop code
	//On check function for single select question options.
	const handleSingleSelectAnswer = e => {
		if(e.target.value){
			if(listQuestions&&listQuestions.length>0){
				if(isSearch==="true"||isQuestionIndexOpened==="true"){
					listQuestions[questionIndex].selectedAnswer=[e.target.value];
				}else{
					listQuestions[questionIndex].selectedAnswer=[e.target.value];
					listQuestions[questionIndex].oldSelectedAnswer=[e.target.value];
				}
				let option=constants.OptionNumbers[e.target.value];
				if(listQuestions[questionIndex][option]&&listQuestions[questionIndex][option].includes("<strike>")){
					listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("<strike>","");
					listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("</strike>","");
				}
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				setRefresh(!refresh);
			}
		}
	};
	//On check function for multi select question options.
	const onChangeMultipleChoice=(checkedValues)=>{
		let sortedAnswer=[];
		let unSortedAnswer=cloneDeep(checkedValues);
		sortedAnswer=checkedValues.sort();
		if(listQuestions&&listQuestions.length>0){
			if(isSearch==="true"||isQuestionIndexOpened==="true"){
				listQuestions[questionIndex].selectedAnswer=sortedAnswer;
			}else{
				listQuestions[questionIndex].selectedAnswer=sortedAnswer;
				listQuestions[questionIndex].oldSelectedAnswer=sortedAnswer;
			}
			let option=constants.OptionNumbers[unSortedAnswer[unSortedAnswer.length-1]];
			if(listQuestions[questionIndex][option]&&listQuestions[questionIndex][option].includes("<strike>")){
				listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("<strike>","");
				listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("</strike>","");
			}
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
			setRefresh(!refresh);
		}
	};
	//Function to select the arrange the sequence option on click
	const	handleSequence=(e)=>{
		setSelectedSequenceOption(e);
	};
	// Function to select the Drag and drop option on click
	const handleDragDropOption=(e)=>{
		setSelectedDragOption(e);
	};
		//Function to handle Strike through options of all types of questions.
	const handleStrikeThrough=()=>{
		if(listQuestions&&listQuestions.length>0){
			let optionNumver=constants.OptionNumbers;
			if(listQuestions[questionIndex].q_type==="1"){
				let ddd=optionNumver[listQuestions[questionIndex].selectedAnswer[0]];
				if(listQuestions[questionIndex][ddd].includes("<strike>")){
					listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
					listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
				}else{
					listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
					listQuestions[questionIndex].selectedAnswer=null;
				}
			}else if(listQuestions[questionIndex].q_type==="2"){
				for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
					let ddd=optionNumver[listQuestions[questionIndex].selectedAnswer[i]];
					if(listQuestions[questionIndex][ddd].includes("<strike>")){
						listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
						listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
					}else{
						listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
						if(listQuestions[questionIndex].selectedAnswer[i]===listQuestions[questionIndex].selectedAnswer[listQuestions[questionIndex].selectedAnswer.length-1]){
							listQuestions[questionIndex].selectedAnswer.splice(i,1);
						}
					}
				}
				listQuestions[questionIndex].selectedAnswer=null;
			}else if(listQuestions[questionIndex].q_type==="4"){
				for (let i = 0; i < sortitems.length; i++) {
					if(sortitems[i].index===selectedSequenceOption){
						let ddd=optionNumver[sortitems[i].index];
						if(sortitems[i].value.includes("<strike>")){
							sortitems[i].value=sortitems[i].value.replace("<strike>","");
							sortitems[i].value=sortitems[i].value.replace("</strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
							setSelectedSequenceOption(sortitems[i].index);
						}else{
							sortitems[i].value=`<strike>${sortitems[i].value}</strike>`;
							listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
							setSelectedSequenceOption(sortitems[i].index);
						}
					}
				}
			}else if(listQuestions[questionIndex].q_type==="5"){
				let dragAndDropOptions=constants.OptionNumberDragAndDrop;
				for (let i = 0; i < columns[1].items.length; i++) {
					if(columns[1].items[i].id===selectedDragOption){
						let ddd=dragAndDropOptions[columns[1].items[i].id];
						if(columns[1].items[i].content.includes("<strike>")){
							columns[1].items[i].content=columns[1].items[i].content.replace("<strike>","");
							columns[1].items[i].content=columns[1].items[i].content.replace("</strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
						}else{
							columns[1].items[i].content=`<strike>${columns[1].items[i].content}</strike>`;
							listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
						}
					}
				}
				setColumns(columns);
				listQuestions[questionIndex].columns=columns;
				// listQuestions[questionIndex].selectedOption=items;
			}
			setQuestions(listQuestions);
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setRefresh(!refresh);
		}
		setRefresh(!refresh);
	};
	const handleMarkForLater=()=>{
		if(listQuestions&&listQuestions.length>0){
			let marked=listQuestions[questionIndex].markedForLater;
			listQuestions[questionIndex].markedForLater=!marked;
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
			setRefresh(!refresh);
		}
	};
	const showDrawer = () => {
		setvisible(true);		
	};
	const createNote=(values)=>{
		hideModal();
	};
	const columnsQuestionIndex = [
		{
			title: "Q.",
			render: (record) => {
				let index=null;
				if(record){
					index=record.index+1;
				}
				return <span><Button type="link" style={{ color:"black" }} onClick={()=>{handleClickQuestionIndex(record);}}>{index}</Button></span>;
			},
			key: 'index',
			align: "center",
			width: 60,
		},
		{
			title: "Category",
			render: (record) => (
				<span className="pointer" onClick={()=>{handleClickQuestionIndex(record);}}>{record&&record.category?record.category:""}</span>
			),
			key: 'value',
			ellipsis: true,
			width: 60,
		},
		{
			title: "Topic",
			render: (record) => (
				<Tooltip title={record&&record.value?record.value:""}>
					<span className="pointer" onClick={()=>{handleClickQuestionIndex(record);}}>{record&&record.value?record.value.length>constants.minimumCharactersQuestionIndex?record.topic_abbreviation:record.value:""}</span>
				</Tooltip>
			),
			key: 'value',
			ellipsis: true,
		},
		{
			title: false,
			key: 'questions',
			align: "center",
			width: 60,
			render: (record) => (
				<>
					<Space>
						{record&&record.notes!==null&&record.notes!==""?
							<Tooltip title={constants.NoteToottip}><Comment/></Tooltip>:null}
						{record&&record.markedForLater===true&&(
							<Tooltip title={constants.MarkLaterTooltip}><FlagFilled className="flag"/></Tooltip>
						)}
					</Space>
				</>
			),
		},
	];
	const getColorForTimer=(value)=>{
		if(value){
			let timeOfTest=value.toString();
			var splittedTime = timeOfTest.split(':'); // split it at the colons
			// minutes are worth 60 seconds. Hours are worth 60 minutes.
			var seconds = (+splittedTime[0]) * 60 * 60 + (+splittedTime[1]) * 60 + (+splittedTime[2]); 
			if(seconds<=300){
				return "red-color-time";
			}else{
				return "black-color-time";
			}
		}
	};
	const completeTestApi=()=>{
		setCompleteTest(false);
	};
	const completeTestDisplay=()=>{
		setCompleteTest(true);
	};
		//Executes score_card PUT method API to pause the test
	const pauseTestAnswer=()=>{
		setPauseTestLoading(false);
		setPauseTest(false);
	};
	const saveNotes=()=>{
		setCompleteTest(false);
	};
	const singleSelect = (
		<Card className="testSection">
			<div className='d-flex mb-3'>
				<p className="test-question" id="99"  dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}
			</div>
			<Radio.Group className="test-options w-100 options-counter"
				onChange={handleSingleSelectAnswer} 
				value={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0&&listQuestions[questionIndex].selectedAnswer[0]}
			>
				<Space direction="vertical" className="w-100">
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?<Radio value={1} id="option1" className="options options-inner" ><p id="1" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?<Radio value={2} id="option2" className="options options-inner"><p id="2" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?<Radio value={3} id="option3" className="options options-inner"><p  id="3" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?<Radio value={4} id="option4" className="options options-inner"><p id="4" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?<Radio value={5} id="option5" className="options options-inner" ><p id="5" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?<Radio value={6} id="option6" className="options options-inner"><p  id="6" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?<Radio value={7} id="option7" className="options options-inner"><p id="7" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?<Radio value={8} id="option8" className="options options-inner"><p  id="8" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></p></Radio>:""}
				</Space>
			</Radio.Group>
		</Card>
	);
	const multipleChoice = (
		<Card className="testSection">
			<div className='d-flex mb-3'>
				<p className="test-question" id="99" dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}
			</div>
			<Checkbox.Group
				style={{ width: "100%" }}
				onChange={onChangeMultipleChoice}
				value={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0&&listQuestions[questionIndex].selectedAnswer}
				className="test-options w-100 options-counter"
			>
				<Space direction="vertical" className="w-100">
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?
						<Checkbox className="options options-inner" value={1} id="option1">
							<p id="1" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?
						<Checkbox className="options options-inner" value={2} id="option2">
							<p id="2" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></p>
						</Checkbox>:""}     
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?
						<Checkbox className="options options-inner" value={3} id="option3">
							<p id="3" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?
						<Checkbox className="options options-inner" value={4} id="option4">
							<p id="4" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?
						<Checkbox className="options options-inner" value={5} id="option5">
							<p id="5" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?
						<Checkbox className="options options-inner" value={6} id="option6">
							<p id="6" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?
						<Checkbox className="options options-inner" value={7} id="option7">
							<p id="7" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?
						<Checkbox className="options options-inner" value={8} id="option8">
							<p id="8" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></p>
						</Checkbox>:""}
				</Space>
			</Checkbox.Group>
		</Card>
	);
	const arrangeSequence = (
		<Card className="testSection">
			<div className='d-flex mb-3'>
				<p className="test-question" id="99" dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
			</div>			
			<div>
				<p>* Reorder the correct sequence.</p>				{/* <SortableList sortitems={sortitems} onSortEnd={onSortEnd}shouldCancelStart={handleSequence}/> */}
				<SortableContainer onSortEnd={onSortEnd} useDragHandle>
					<ul className="test-sortable">
						{sortitems.map((item,index) => (
							<SortableItem key={item.index} index={index} value={item.value} indexPos={item.index}/>
						))}
					</ul>
				</SortableContainer>
			</div>
		</Card>
	);
	{/* Component used to display the options draggable component for drag and drop type questions */}
	const draggableComponent=(id)=>{
		return(					
			<div style={{ margin: 8 }}>
				<Droppable droppableId={id}>
					{(provided, snapshot) => {
						return (
							<div
								{...provided.droppableProps}
								className="droppable"
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								{columns&&columns[id]&&columns[id].items&&columns[id].items.length>0&&columns[id].items.map((item, index) => {
									return (
										<Draggable
											key={item&&item.id&&item.id}
											draggableId={item&&item.id&&item.id}
											index={index}
										>
											{(providedProp) => {
												return (
													<div
														ref={providedProp.innerRef}
														{...providedProp.draggableProps}
														{...providedProp.dragHandleProps}
														// style={getItemStyle(
														// 	snapshot.isDragging,
														// 	provided.draggableProps.style
														// )}
													>
														<div className="dnd-item" dangerouslySetInnerHTML={{ __html:item.content }}></div>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</div>
		);
	};
	{/* drag and drop */}
	const dragAndDrop=(
		<Col span={24}>
			<Card className="testSection">
				<DragDropContext
					onDragEnd={result => onDragEnd(result, columns, setColumns)}
				>
					<div className='d-flex d-flex-wrap mb-3 flex-direction-col'>
						<p className="test-question" id="99" dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
						{listQuestions&&listQuestions.length>0&&
					<span className="test-question drag-question">
						<Space wrap>
							{listQuestions[questionIndex].option_first?
								listQuestions[questionIndex].option_first.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_first.indexOf("~")) }}></p>{draggableComponent(2)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_first.indexOf("|")) }}></p>)
								:""
							}
							{listQuestions[questionIndex].option_second?
								listQuestions[questionIndex].option_second.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second.substring(0,listQuestions[questionIndex].option_second.indexOf("~")) }}></p>{draggableComponent(3)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second.substring(0,listQuestions[questionIndex].option_second.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_third?
								listQuestions[questionIndex].option_third.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third.substring(0,listQuestions[questionIndex].option_third.indexOf("~")) }}></p>{draggableComponent(4)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third.substring(0,listQuestions[questionIndex].option_third.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_fourth?
								listQuestions[questionIndex].option_fourth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth.substring(0,listQuestions[questionIndex].option_fourth.indexOf("~")) }}></p>{draggableComponent(5)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth.substring(0,listQuestions[questionIndex].option_fourth.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_fifth?
								listQuestions[questionIndex].option_fifth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth.substring(0,listQuestions[questionIndex].option_fifth.indexOf("~")) }}></p>{draggableComponent(6)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth.substring(0,listQuestions[questionIndex].option_fifth.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_sixth?
								listQuestions[questionIndex].option_sixth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth.substring(0,listQuestions[questionIndex].option_sixth.indexOf("~")) }}></p>{draggableComponent(7)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth.substring(0,listQuestions[questionIndex].option_sixth.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_seventh?
								listQuestions[questionIndex].option_seventh.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh.substring(0,listQuestions[questionIndex].option_seventh.indexOf("~")) }}></p>{draggableComponent(8)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh.substring(0,listQuestions[questionIndex].option_seventh.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_eighth?
								listQuestions[questionIndex].option_eighth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_eighth.indexOf("~")) }}></p>{draggableComponent(9)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth.substring(0,listQuestions[questionIndex].option_eighth.indexOf("|")) }}></p>)
								:""					
							}
						</Space>
					</span>
						}
						<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
					</div>
					<Droppable droppableId={"1"}>
						{(provided, snapshot) => {
							return (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{columns&&columns[1]&&columns[1].items&&columns[1].items.length>0&&columns[1].items.map((item, index) => {
										return (
											<Draggable
												key={item&&item.id&&item.id}
												draggableId={item&&item.id&&item.id}
												index={index}
											>
												{(providedProp, snapshots) => {
													return (
														<div
															style={getItemStyle(
																snapshots.isDragging,
																providedProp.draggableProps.style
															)}>
															<div
																ref={providedProp.innerRef}
																{...providedProp.draggableProps}
																{...providedProp.dragHandleProps}
															>
																<DragOutlined className="test-dnd-icon"/>
															</div>
															<span className="dnd-counter">{constants.OptionstoDisplay[item&&item.id&&item.id]}</span>
															<div className="drag-option" id={item.id} dangerouslySetInnerHTML={{ __html:item.content }}></div>
														</div>
													);
												}}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							);
						}}
					</Droppable>
				</DragDropContext>
			</Card>
		</Col>
	);
	const handleClickQuestionIndex=(record)=>{
		setIsQuestionIndexOpened("true");
		sessionStorage.setItem("tempQuestionIndex",questionIndex);
		sessionStorage.setItem("isQuestionIndexOpened","true");
		setQuestionIndex(record.index);
		setvisible(false);
	};
	// const fontDecrease = () => {
	// 	document.body.classList.add('decreaseFont');
	// };
	const checkAnswers=()=>{};
	const options = {
		title: "",
		is3D: false,
	};
		//Function to convert Number to Alphabets
	const convertToAlphabet=(value)=>{
		let covertedValue=(value + 9).toString(36).toUpperCase();
		return covertedValue;
	};
	const noDataAvailableText=()=>{
		return(
			<>							
				<div className="nograph-div">
					<span>No Data Available.</span>
				</div>
			</>
		);
	};
	return (
		<div className='gray-bg'>
			<div className="p-3">
				<Breadcrumb>
					<Breadcrumb.Item><a onClick={() => {
						history.push({ pathname: "/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/masters-menu" });
					}}>Masters</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick = { () => {history.push("/test-list");}}>Test List</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick = { () => {history.push("/question-list");}}>Question List</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick = { () => {history.push("/edit-question");}}>{constants.EditQuestion}</a></Breadcrumb.Item>
					<Breadcrumb.Item>Preview Question</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<div className='px-3'>
				<Card>
					{/* <Button onClick={fontDecrease}>A-</Button> */}
					<Row  gutter={[{
						xs: 0,
						sm: 0,
						md: 24,
						lg: 24 
					}, {
						xs: 24,
						sm: 24,
						md: 24,
						lg: 24 
					}]}>
						<Col span={24} >
							<Row justify="space-between" className="pb-4">
								<Col>
									<h3 className="mainHeading mb-0">
								Test - {listQuestions&&listQuestions.length>0&&listQuestions[0].category_abbreviation} (1st Attempt)
									</h3>
								</Col>
								<Col>
									<AutoComplete
										style={{ width: 300 }}
										className="custom-placeholder"
										placeholder="Search"
									>
									</AutoComplete>
								</Col>
							</Row>
							<Divider className="my-1"/>
						</Col>
						<Col sm={12} className="test-question-details">
							<Space size={'large'} className="flex-vertical-middle">
								{listQuestions&&listQuestions.length>0&&displaySearch==="false"?<span><span>
									<span  /*onClick={showDrawer} className="qust-title"*/>
										{constants.Questions}
									</span> : <span>1 of 25</span>
								</span>
								{ visible&& <Drawer
									title={					
										<div>
											{"Test - PIET (1st Attempt)"}
										</div>}
									placement='left'
									closable={true}
									onClose={onClose}
									width={400}
									visible={visible}
									// key={placement}
									className="test-drawer"
								> 
									<Table
										locale={constants.locale}
										columns={columnsQuestionIndex} 
										dataSource={questionIndexList} 
										bordered
										className="customTable grey-custom-table"
										pagination={false}
									/>
								</Drawer>}</span>:""}
								{listQuestions&&listQuestions.length>0?(<span>
									{topicDetails&&topicDetails.isTimed!==null&&
							topicDetails.isTimed===true ?
										<Button className="timerIcon" 
											onClick={toggleTimer} 
											icon={viewTimer ? 
												<Tooltip title={constants.HideTimer}>
													<EyeOutlined/>
												</Tooltip> : 
												<Tooltip title={constants.ShowTimer}>
													<EyeInvisibleOutlined/> 
												</Tooltip>}>
										</Button>:""
									}
									{viewTimer ? <span className={getColorForTimer(timerDisplay)}>{timerDisplay}</span>: ""}</span>):""}
							</Space>
						</Col>
						<Col sm={12} className="text-right test-icon-container">
							{listQuestions&&listQuestions.length>0&&displaySearch==="false"?<Space size={'middle'}>
								<Tooltip title="Strikethrough"><Button type="text" 
									// onClick={()=>{handleStrikeThrough();}}
									icon={<StrikethroughOutlined className="strike"/>}></Button></Tooltip>
								{listQuestions&&listQuestions.length>0&&<Tooltip title="Mark for Later">
									<Button type="text"
										// onClick={()=>{handleMarkForLater();}}
										icon={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].markedForLater===true?
											<FlagFilled className="flag"/>:<FlagOutlined className="flag"/>}>
									</Button></Tooltip>}
								<Tooltip title="Highlight"><Button type="text" onTouchStart={(e)=>e.preventDefault()} 
									// onTouchEnd={()=>handleHighlightText()} 
									// onClick={()=>{handleHighlightText();}} 
									icon={<HighlightOutlined className="highlight"/>}></Button></Tooltip>
								<Tooltip title="Question Index"><Button type="text" 
									// onClick={showDrawer} 
									icon={<ProfileOutlined/>}></Button></Tooltip>
								{listQuestions.length>0
									?listQuestions[questionIndex].notes!==null
						&&listQuestions[questionIndex].notes!==undefined
						&&listQuestions[questionIndex].notes!==""?
										<Tooltip title="Notes"><Button type="text" icon={<Badge dot color={"blue"}><Comment/></Badge>} 
											// onClick={showModal}
										></Button></Tooltip>
										:
										<Tooltip title="Notes"><Button type="text" icon={<Comment/>} 
											// onClick={showModal}
										></Button></Tooltip>
									:null}
								<Tooltip title="Decrease font"><Button 
									// onClick={()=>{zbminus();}} 
									className={count>=2? "zoom-btn iconDisable": "zoom-btn"}>A-</Button></Tooltip>
								<Tooltip title="Increase font" placement="topRight"><Button 
									// onClick={()=>{zb();}} 
									className={count<=0? "zoom-btn iconDisable": "zoom-btn"}>A+</Button></Tooltip>
							</Space>:null}
							<Modal
								title=
									{
										<div
											style={{
												width: '100%',
												cursor: 'move', 
											}}
											onMouseOver={() => {
												if (disabled) {
													setIsDisabled(false);
												}
											}}
											onMouseOut={() => {
												setIsDisabled(true);
											}}onFocus={() => {}}
											onBlur={() => {}}
										>
								Notes
										</div>
									}
								visible={notesVisible}
								onOk={hideModal}
								onCancel={hideModal}
								className="notes-modal"
								footer={null						}
								modalRender={modal => (
									<Draggables
										disabled={disabled}
										bounds={bounds}
										onStart={(event, uiData) => onStart(event, uiData)}
									>
										<div ref={draggleRef}>{modal}</div>
									</Draggables>
								)}
							>
								<Form form={noteForm} onFinish={createNote}>
									<Form.Item name="note">
										<TextArea 
											rows={4} 
											placeholder=" Write a note"
											className="p-0"
											onPaste={(e)=>{
												e.preventDefault();
												return false;
											}} onCopy={(e)=>{
												e.preventDefault();
												return false;
											}}
										/>
									</Form.Item>
									<Form.Item className="mb-0">
										<Button className="btn-submit min-width-100" htmlType="submit"><SaveOutlined/> Save</Button>
									</Form.Item>
								</Form>
							</Modal>
						</Col>
						<Col span={24}>
							<Spin spinning={false} size="large" indicator={<Loader/>}>
								{listQuestions&&
						listQuestions.length>0?
									listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="1"?singleSelect:
										listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="2"?multipleChoice:listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="4"?arrangeSequence:listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="5"?dragAndDrop:""
									:""}
							</Spin>
						</Col>
						<Col span={24} className="test-btn-container">
							<Space size={'small'} wrap>
								{listQuestions&&listQuestions.length>0?
									<>
										<Button className="btnDb min-width-100"><LeftOutlined/>{constants.Previous}</Button>
										<Button className="btnDb min-width-100">{constants.Next}<RightOutlined/></Button>
										{/* <Button className="btnDb min-width-100" 
											// onClick={()=>{setPauseTest(true);}}
										>Pause Test</Button>
										<Button className="btnDb min-width-100">{constants.ResumeTest}</Button>
										<Button className="btn-submit min-width-100">{constants.Save}</Button>
										<Button className="btn-submit min-width-100" 
											// onClick={()=>{completeTestDisplay();}}
										>{constants.SubmitTest}</Button> */}
									</>
									:""
								}
								{listQuestions&&listQuestions.length>0&&displaySearch==="false"?<Space size={'middle'} className="purple-border">
									<Tooltip title="Strikethrough"><Button type="text" 
										// onClick={()=>{handleStrikeThrough();}}
										icon={<StrikethroughOutlined className="strike"/>}></Button></Tooltip>
									{listQuestions&&listQuestions.length>0&&<Tooltip title="Mark for Later">
										<Button type="text"
											// onClick={()=>{handleMarkForLater();}}
											icon={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].markedForLater===true?
												<FlagFilled className="flag"/>:<FlagOutlined className="flag"/>}>
										</Button></Tooltip>}
									<Tooltip title="Highlight"><Button type="text" onTouchStart={(e)=>e.preventDefault()} 
										// onTouchEnd={()=>handleHighlightText()} 
										// onClick={()=>{handleHighlightText();}} 
										icon={<HighlightOutlined className="highlight"/>}></Button></Tooltip>
									<Tooltip title="Question Index"><Button type="text" 
										// onClick={showDrawer} 
										icon={<ProfileOutlined/>}></Button></Tooltip>
									{listQuestions.length>0
										?listQuestions[questionIndex].notes!==null
						&&listQuestions[questionIndex].notes!==undefined
						&&listQuestions[questionIndex].notes!==""?
											<Tooltip title="Notes"><Button type="text" icon={<Badge dot color={"blue"}><Comment/></Badge>} 
												// onClick={showModal}
											></Button></Tooltip>
											:
											<Tooltip title="Notes"><Button type="text" icon={<Comment/>} 
												// onClick={showModal}
											></Button></Tooltip>
										:null}
									<Tooltip title="Decrease font"><Button 
										// onClick={()=>{zbminus();}}
										className={count>=2? "zoom-btn iconDisable": "zoom-btn"}>A-</Button></Tooltip>
									<Tooltip title="Increase font"><Button 
										// onClick={()=>{zb();}}
										className={count<=0? "zoom-btn iconDisable": "zoom-btn"}>A+</Button></Tooltip>
								</Space>:null}
							</Space>
						</Col>
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].explanation&&
					<Col xs={24} sm={24} md={24} lg={24}>
						<Card title={<span>Explanation (Correct Option: {correctAnswerList})</span>} bordered={false} className="explanation testSection">
							{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].explanation?<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].explanation }}></div>:""}
						</Card>
					</Col>
						}
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card title={<span className="text-center position-relative">Confidential info for office use only</span>}>
								<Row>
									<Col xs={24} sm={24} md={10} lg={10}>
										<Form.Item
											labelAlign="left"
											colon={false}
											{...horizontalLayout}
											label="Displayed"
											name="displayed"
										>
											<span ><strong>:</strong>{" "}{ratingsData&&ratingsData[0].displayed_times} </span>
										</Form.Item>
										<Form.Item
											labelAlign="left"
											colon={false}
											{...horizontalLayout}
											label="Answered correctly"
											name="answered"
										>
											<span ><strong>:</strong>{" "}{ratingsData&&ratingsData[0].is_answered_correctly} ({Number((Number(ratingsData&&ratingsData[0].is_answered_correctly)*100)/Number(ratingsData&&ratingsData[0].displayed_times)).toFixed(1)}%)</span>
										</Form.Item>
										<Form.Item
											labelAlign="left"
											colon={false}
											{...horizontalLayout}
											label="Rated Easy"
											name="easy"
										>
											<span ><strong>:</strong>{" "}{ratingsData&&ratingsData[0].rating_1} ({Number((Number(ratingsData&&ratingsData[0].rating_1)*100)/(Number(ratingsData&&ratingsData[0].rating_1)+Number(ratingsData&&ratingsData[0].rating_2)+Number(ratingsData&&ratingsData[0].rating_3))).toFixed(1)}%)</span>
										</Form.Item>
										<Form.Item
											colon={false}
											labelAlign="left"
											{...horizontalLayout}
											label="Rated Medium"
											name="medium"
										>
											<span ><strong>:</strong>{" "}{ratingsData&&ratingsData[0].rating_2} ({Number((Number(ratingsData&&ratingsData[0].rating_2)*100)/(Number(ratingsData&&ratingsData[0].rating_1)+Number(ratingsData&&ratingsData[0].rating_2)+Number(ratingsData&&ratingsData[0].rating_3))).toFixed(1)}%)</span>
										</Form.Item>
										<Form.Item
											colon={false}
											labelAlign="left"
											{...horizontalLayout}
											label="Rated Difficult"
											name="difficult"
										>
											<span ><strong>:</strong>{" "}{ratingsData&&ratingsData[0].rating_3} ({Number((Number(ratingsData&&ratingsData[0].rating_3)*100)/(Number(ratingsData&&ratingsData[0].rating_1)+Number(ratingsData&&ratingsData[0].rating_2)+Number(ratingsData&&ratingsData[0].rating_3))).toFixed(1)}%)</span>
										</Form.Item>
										<Form.Item
											colon={false}
											labelAlign="left"
											{...horizontalLayout}
											label="Not Rated"
											name="notrated"
										>
											<span ><strong>:</strong>{" "}{ratingsData&&ratingsData[0].not_rated}</span>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={14} lg={14}>
										{ratingsData&&ratingsData[0].rating_1==="0"&&ratingsData[0].rating_2==="0"&&ratingsData[0].rating_3==="0"?
											noDataAvailableText()
											:
											<div>
												<div className='text-center'><strong>Rating Analysis</strong></div>
												<Chart
													chartType="PieChart"
													width="100%"
													height="350px"
													data={charData}
													options={options}
												/>
											</div>
										}
										{/* <span className="text-center position-relative">Correct Option: {correctAnswerList}</span> */}
									</Col>
								</Row>
							</Card>
						</Col>
						<Col span={24} className="test-btn-container">
							<Space size={'small'}>
								<Button className="btnDb min-width-100" onClick={()=>{history.push("/question-list");}}><LeftOutlined/>{constants.returnToQuestionList}</Button>
							</Space>
						</Col>
					</Row>
					{testModalPopup(
						pauseTest,
						completeTest,
						categoryDetails,
						topicDetails,
						viewTimer,
						timerDisplay,
						toggleTimer,
						/* attemptedQuestions */1,
						pauseTestAnswer,
						saveNotes,
						pauseTestLoading,
						setPauseTest,
						{},
						checkAnswers,
						completeTestApi,
						false,
						/* lastTenMinutes */null,
						/* setLastTenMinutes */null,
						/* markedLaterVisible */null,
						/* handleOkMarkForLaterModal */null,
						/* visibleTimeOut */null,
						/* handleTimeOutOk */null,
						null,
						null)}
				</Card>
			</div>
		</div>
	);
};
export default PreviewQuestion;
