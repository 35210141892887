import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import {
	Row,
	Col,
	Card,
	BackTop,
	Table,
	Tooltip,
	Space,
	Breadcrumb
} from "antd";
import { fetchAdminApi, } from "../../../services/api";
import moment from 'moment';
import constants from '../../../constants/constants';
import { useHistory } from "react-router";
const StudentAllActivities = () => {
	const history = useHistory();
	const [studentActivitiesData, setStudentActivitiesData] = useState();
	const [totalPoints, setTotalPoints] = useState(0);
	const [tokenValue,setTokenValue]=useState();
	const [testTypeTotal, setTestTypeTotal] = useState({
		total_tests_points:0,
		total_tutorials_points:0,
		total_flashcards_points:0,
		total_feedback_points:0,
		total_qod_points:0
	});
	console.log("studentActivitiesData",studentActivitiesData);
	const pagination = { showSizeChanger: true, };
	const studentActivitiesColumn = [
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'date',
			width: '150px',
			align: 'left',
			render: (record) => {
				return (<span>{moment(record).format(constants.dateFormat)}</span>);
			},
		},
		{
			title: 'Question ID',
			key: 'qod_qid',
			dataIndex: 'qod_qid',
			align: 'center',
			render:(record) =>{
				if(record !== null){
					return <span>{record}</span>;
				}
			}
		},
		{
			title: 'Activity Type',
			key: 'Tests',
			render: (record) => {
				let activityType = "";
				if(record?.type === "0"){
					if(record?.test_type === "TOPIC"){
						console.log(record);
						activityType = "Test - Topic";
					}
					else if(record?.test_type === "TEST"){
						console.log("2");
						activityType = "Test - Full Length";
					}
					else {
						activityType = "Test - Category";
					}
				}else if(record?.type === "1"){
					if(record?.test_type === "TOPIC"){
						activityType = "Tutorial - Topic";
					}else{
						activityType = "Tutorial - Category";
					}
				}else if(record?.type === "2"){
					if(record?.topic_id){
						activityType = "Flashcard - Topic";
					}else{
						activityType = "Flashcard - Textbook";
					}
				}else if(record?.type === "3"){
					activityType = "Feeback";
				}else if(record?.type === "4"){
					activityType = "QOD";
				}
				return <span>{activityType}</span>;
			},
		},
		{
			title: 'Test Type',
			key: 'Type',
			render: (record) => {
				let testType="";
				let title = "";
				if(record?.type === "0"){
					if(record?.test_type === "TOPIC"){
						testType = record?.topic_abb;
						title = record?.topic;
					}else if(record?.test_type === "TEST"){
						testType="Full Length #"+record?.attempts;
					}
					else{
						testType = record?.test_type+" #"+record?.attempts;
					}
					// return <Tooltip title={title}>
					// 	<span>{record?.points}</span>
					// </Tooltip>;
				}
				else if(record?.type === "1"){
					if(record?.test_type === "TOPIC"){
						testType = record?.topic_abb;
						title = record?.topic;
					}else{
						if(record.test_type==="MARK FOR LATER"||record.test_type==="INCORRECT ANS"){
							testType = record?.test_type;
						}else{
							testType = record?.test_type+" #"+record?.attempts;
						}
					}
				}
				else if(record?.type === "2"){
					if(record?.topic_id){
						testType = record?.topic_abb;
						title = record?.topic;
					}else{
						testType = record?.chapter_abb?record.chapter_abb:record.chapter;
						title = record?.chapter;
					}
				}
				else if(record?.type === "3"){
					testType = "Feedback";
				}
				else if(record?.type === "4"){
					testType = record?.question?.length<35?record.question:record.question.substring(0,35)+"...";
					title = record?.question;
				}
				return <Tooltip title={ title }>
					<span style = {{ width:"90px" }}>{testType}</span>
				</Tooltip>;
			},
		},
		{
			title: 'Points',
			key: 'points',
			align: 'center',
			dataIndex: 'points',
			render:(record) =>{
				if(record !=="0"){
					return <span>{record}</span>;
				}
			}
		},
		{
			title: 'Attempt',
			key: 'attempts',
			align: 'center',
			dataIndex: 'attempts',
			render:(text,record) =>{
				if(text !=="0"){
					if(record?.is_retake==="t"){
						return <span>2</span>;
					}else{
						return <span>1</span>;
					}
				}
			}
		},
		{
			title: 'Retake',
			key: 'retake',
			align: 'center',
			dataIndex :"is_retake",
			render: (record) => {
				if(record === "t"){
					return <span>Yes</span>;
				}else{
					return (<span>No</span>);
				}
			},
		},
		// {
		// 	title: 'Points Earned',
		// 	key: 'points',
		// 	dataIndex: 'points',
		// 	className: 'td-right',
		// 	align: 'center',
		// 	render: (record) => {
		// 		return (<span>{record}</span>);
		// 	},
		// },
	];
	useEffect(() => {
		let queryString = window.location.href;
		let token = queryString.substring(queryString.lastIndexOf('?') + 1);
		setTokenValue(token);
		fetchStudentActivitesdata(token);
	}, []);
	const fetchStudentActivitesdata =(token)=>{
		fetchAdminApi(`/total-activities-points/${token}`, "get").then((res) => {
			if (res && res.data) {
				console.log(res?.data?.result[0]?.total_points);
				console.log(res?.data?.total_tests_points,"testPoints");
				setStudentActivitiesData(res?.data);
				setTotalPoints(res?.data?.result[0]?.total_points);
				setTestTypeTotal({ 
					...testTypeTotal,
					total_tests_points:res?.data?.total_tests_points,
					total_tutorials_points:res?.data?.total_tutorials_points,
					total_flashcards_points:res?.data?.total_flashcards_points,
					total_feedback_points:res?.data?.total_feedback_points,
					total_qod_points:res?.data?.total_qod_points,
				});
			}else{
				setStudentActivitiesData([]);
			}
		});
	};
	return (
		<>
			<div className='gray-bg'>
				<div className='p-4'>
					<BackTop/>
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item><a onClick={() => {
							sessionStorage.setItem("showEditSaveButton","show");
							history.push({ pathname:"/edit-student", });
						}}>Edit Student User</a></Breadcrumb.Item>
						<Breadcrumb.Item>
						Student Activities</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Row gutter={[24, 24]} >
					<Col xs={24} sm={24} md={24} lg={24}>
						<Card size="small" title={<><span className="text-uppercase">Student Activities - {studentActivitiesData?.name}</span>
							<span style={{ visibility:"hidden" }}>{" "}Student ID:{tokenValue}</span></>
						} 
						extra={<span>Username: {studentActivitiesData?.login_username}</span>}
						>						
							<p>Total Points Earned: {totalPoints}</p>
							<Table className="admin-gap-table" 
								locale={{ emptyText: 'No Records Found' }}
								dataSource={studentActivitiesData?.result} 
								columns={studentActivitiesColumn}
								pagination={pagination}
							/>
							<div className="div-below-grid">
								<Space direction="horizontal">
									<span>Total Test Points: <b>{testTypeTotal?.total_tests_points}</b></span>
									<span>Total Tutorial Points: <b>{testTypeTotal?.total_tutorials_points}</b></span>
									<span>Total Flashcard Points: <b>{testTypeTotal?.total_flashcards_points}</b></span>
									<span>Total Feedback Points: <b>{testTypeTotal?.total_feedback_points}</b></span>
									<span>Total QOD Points: <b>{testTypeTotal?.total_qod_points}</b></span>
								</Space>
							</div>
						</Card>
					</Col>
				</Row> 
			</div>
		</>
	);
};
export default StudentAllActivities;
