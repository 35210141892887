import { Col, Row,Button, Space, Tooltip } from "antd";
import "../../styles/test.css";
import constants from "../../constants/constants";
import React, { useState, useEffect } from "react";
import { fetchApi } from "../../services/api";
import{ COOKIE,getCookie } from "../../services/cookie";
import { useHistory } from "react-router";
const CategoryTest = () => {
	const history = useHistory();
	const [fppData,setFppData]=useState([]);
	const [saedData,setSaedData]=useState([]);
	const [pietData,setPietData]=useState([]);
	const [isApiLoad,setIsApiLoad]=useState(false);
	useEffect(() => {
		fetchCategoryListData();
	}, []);
	//To fetch category list data
	const fetchCategoryListData = () => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/tutorials/history?type=category&student_id=${userId}`, "get").then((res) => {
			if (res && res.data && res.data!==null&& res.data!==undefined) {
				setFppData(res.data.FPP!==null?res.data.FPP:[]);
				setPietData(res.data.PIET!==null?res.data.PIET:[]);
				setSaedData(res.data.SAED!==null?res.data.SAED:[]);
				setIsApiLoad(true);
			} else {
				setFppData([]);
				setPietData([]);
				setSaedData([]);
				setIsApiLoad(true);
			}
		});
	};
	//To redirect and start test
	const redirectToTest=(type,catId,catType,attempt)=>{
		let categoryDetails={};
		categoryDetails.type=type;
		categoryDetails.catId=catId;
		categoryDetails.catType=catType;
		categoryDetails.attempt=attempt;
		categoryDetails.count=10;
		categoryDetails.isTimed=false;
		history.push({
			pathname:"/category-tutorial",
			state:{ categoryDetails:categoryDetails } 
		});
	};
	const handleReviewTutorial=(tutorial)=>{
		let reviewTutorial={};
		reviewTutorial.scoreCardId=tutorial.scorecard_id;
		reviewTutorial.categoryId=tutorial.category_id;
		reviewTutorial.review="tutorial";
		reviewTutorial.type="category";
		history.push({
			pathname:"/review-incorrect",
			state:{ reviewTutorial:reviewTutorial } 
		});
	};
	return (
		<div className="p-4 container category-list-container">
			{isApiLoad?
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24 
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 40 
				}]}>
					<Col xs={24} sm={24} md={24} lg={24}>
						<h3 className="mainHeading">Category Tutorials</h3>
						<p className="subHeading">{constants.SubTitle}</p>
					</Col>
					<Col xs={24} sm={24} md={24} 
						lg={{
							span: 22, 
							offset: 2,
						}}>
						<Row gutter={[32, 16]} justify="center" align="middle" className="category-outer">
							<Col xs={24} md={11}>
								<div className="categories-list flex-middle">{constants.CategoryFPP}</div>
							</Col>
							<Col xs={24} md={13} className="categoryDetails">
								{fppData&&fppData.length>0?
									<div>
										{fppData.map((fppItem,index)=>{
											return(<Space key={fppItem.scorecard_id}><span className="nowrap">Tutorial {index+1}:</span> <span><span className="nowrap">Attempted on</span><Tooltip title={fppItem.points?fppItem.points+(fppItem.points>1?" points earned":" point earned"):""}> {fppItem.date}.</Tooltip></span> {fppItem.points!==null?<Button type="link" onClick={()=>{handleReviewTutorial(fppItem);}}>View again.</Button>:<span className="orange px-3">{constants.InProgress}</span>}</Space>);
										})}
										{fppData.length===1?
											<div>Tutorial 2:<Button type="link" onClick={()=>{redirectToTest("tutor",1,"FPP",2);}}>{ constants.ClickToStartBTN }.</Button></div>:null}
									</div>
									:<div>2 tutorials remaining. <Button type="link" onClick={()=>{redirectToTest("tutor",1,"FPP",1);}}>{ constants.ClickToStartBTN }.</Button></div>
								}
							</Col>
							<Col xs={24} md={11}>
								<div className="categories-list flex-middle">{constants.CategorySAED}</div>
							</Col>
							<Col xs={24} md={13} className="categoryDetails">
								{saedData&&saedData.length>0?
									<div>
										{saedData.map((saedItem,index)=>{
											return(<Space key={saedItem.scorecard_id}><span className="nowrap">Tutorial {index+1}:</span> <span><span className="nowrap">Attempted on</span><Tooltip title={saedItem.points?saedItem.points+(saedItem.points>1?" points earned":" point earned"):""}> {saedItem.date}.</Tooltip></span> {saedItem.points!==null?<Button type="link" onClick={()=>{handleReviewTutorial(saedItem);}}>View again.</Button>:<span className="orange px-3">{constants.InProgress}</span>}</Space>);
										})}
										{saedData.length===1?
											<div>Tutorial 2: <Button type="link" onClick={()=>{redirectToTest("tutor",2,"SAED",2);}}>{ constants.ClickToStartBTN }.</Button></div>:null}
									</div>
									:<div>2 tutorials remaining. <Button type="link" onClick={()=>{redirectToTest("tutor",2,"SAED",1);}}>{ constants.ClickToStartBTN }.</Button></div>
								}
							</Col>
							<Col xs={24} md={11}>
								<div className="categories-list flex-middle">{constants.CategoryPIET}</div>
							</Col>
							<Col xs={24} md={13} className="categoryDetails">
								{pietData&&pietData.length>0?
									<div>
										{pietData.map((pietItem,index)=>{
											return(<Space key={pietItem.scorecard_id}><span className="nowrap">Tutorial {index+1}:</span> <span><span className="nowrap">Attempted on</span> <Tooltip title={pietItem.points?pietItem.points+(pietItem.points>1?" points earned":" point earned"):""}> {pietItem.date}.</Tooltip></span> {pietItem.points!==null?<Button type="link" onClick={()=>{handleReviewTutorial(pietItem);}}>View again.</Button>:<span className="orange px-3">{constants.InProgress}</span>}</Space>);
										})}
										{pietData.length===1?
											<div>Tutorial 2: <Button type="link" onClick={()=>{redirectToTest("tutor",3,"PIET",2);}}>{ constants.ClickToStartBTN }.</Button></div>:null}
									</div>
									:<div>2 tutorials remaining. <Button type="link" onClick={()=>{redirectToTest("tutor",3,"PIET",1);}}>{ constants.ClickToStartBTN }.</Button></div>
								}
							</Col>
						</Row>
					</Col>
				</Row>
				:null}
		</div>
	);
};
export default CategoryTest;
