/*
File Name: FlashcardList.js
Author: Mevito Gonsalves
Modified On: 03/05/2022
Description: To add a flashcard.
API's used:
1) /flashcards-list/
2)/insert-flashcard/${categoryTypeId}
*/
import React, { useEffect, useState } from "react";
import { Breadcrumb, Row, Col, Card,Form,Select,Button,Space,message } from 'antd';
import constants from "../../../constants/constants";
import { fetchAdminApi, } from "../../../services/api";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from "react-router";
const { Option } = Select;
const AddFlashcard = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [chapterList,setChapterList]=useState([]);
	const [topicList,setTopicList]=useState([]);
	const [categoryTypeId,]=useState((props && props.history && props.history.location && props.history.location.state ?props.history.location.state.categoryType:parseInt(localStorage.getItem("categoryType"))));
	const [chapterTopicId,]=useState((props && props.history && props.history.location && props.history.location.state ?props.history.location.state.chapterTopic:localStorage.getItem("chapterTopic")));
	const [questionInfo, setQuestionInfo] = useState({
		question: '',
		answer: '',
	});
	const [isVerify,setIsVerify]=useState(false);
	const modules = {
		toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline','strike', 'blockquote'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, ],
			['link', ],
		],
	};
	const formats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 
		'link', 
	];
	const questionChange = (value) => {
		setQuestionInfo({
			...questionInfo,
			question:value
		});
	} ;
	const answerChange = (value) => {
		setQuestionInfo({ 
			...questionInfo,
			answer:value
		});
	} ;
	//To fetch the chapter list
	const fetchChapterList = async() => {
		fetchAdminApi(`/flashcards-list/0`, "get").then(
			(res) => {
				if (res && res.data) {
					setChapterList(res.data);
				} else {
					setChapterList([]);
				}
			}
		);
	};
	//To fetch the topic list
	const fetchTopicList = async() => {
		fetchAdminApi(`/flashcards-list/1`, "get").then(
			(res) => {
				if (res && res.data) {
					setTopicList(res.data);
				} else {
					setTopicList([]);
				}
			}
		);
	};
	//Validation Checks 
	const handleQuestionValidation=(rule,value,callback)=>{
		try {
			if(value){
				if(value.trim()===""||value.trim()==="<p><br></p>"){
					throw new Error(constants.QuestionRequired);
				}
			}else{
				throw new Error(constants.QuestionRequired);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleAnswerValidation=(rule,value,callback)=>{
		try {
			if(value){
				if(value.trim()===""||value.trim()==="<p><br></p>"){
					throw new Error(constants.AnswerRequired);
				}
			}else{
				throw new Error(constants.AnswerRequired);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//Reset form fields
	const resetFormCancel=()=>{
		setQuestionInfo({
			question: '',
			answer: '',
		});
		form.resetFields();
		if(categoryTypeId==0){
			form.setFieldsValue({ Chapter:chapterTopicId, });
		}else{
			form.setFieldsValue({ topic:chapterTopicId, });
		}
	};
	//on add flashcard submit
	const onFinishAddFlashcard=(values)=>{
		let payload=null;
		payload={
			question:String( values.question),
			answer:String (values.answer),
			categories:values.category?values.category:null,
			topics:values.topic?values.topic:null,
			chapters:values.Chapter?values.Chapter:null,
		};
		if(isVerify===true){
			payload.verify=1;
		}else{
			payload.normal_submit=1;
		}
		fetchAdminApi(`/insert-flashcard/${categoryTypeId}`, "post", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.message);
						history.push({ pathname: "/list-flashcard-chapters" });
					} else {
						message.error(res.message);
					}
				} else {
					message.error(constants.saveFailedError);
				}
			}
		);
	};
	useEffect(() => {
		fetchChapterList();
		fetchTopicList();
		if (props && props.history && props.history.location && props.history.location.state) {
			if(props.history.location.state.categoryType==0){
				form.setFieldsValue({ Chapter:props.history.location.state.chapterTopic, });
			}else{
				form.setFieldsValue({ topic:props.history.location.state.chapterTopic, });
			}
			localStorage.setItem("categoryType", props.history.location.state.categoryType);
			localStorage.setItem("chapterTopic", props.history.location.state.chapterTopic);
		}else{
			if(localStorage.getItem("categoryType")==0){
				form.setFieldsValue({ Chapter:localStorage.getItem("chapterTopic"), });
			}else{
				form.setFieldsValue({ topic:localStorage.getItem("chapterTopic"), });
			}
		}
	}, [props]);
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/masters-menu" });
						}}>Masters</a></Breadcrumb.Item>
						<Breadcrumb.Item><a onClick={() => {
							history.push({ pathname: "/list-flashcard-chapters" });
						}}>Flashcard List</a></Breadcrumb.Item>
						<Breadcrumb.Item><a >Add Flashcard</a></Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">Add Flashcard - {categoryTypeId==0?"TextBook":"Practice Tests"}</span>}>								
								<Form form={form} layout="vertical" onFinish={onFinishAddFlashcard}>
									<Col span={12}>
										{categoryTypeId==0?
											<Form.Item
												label="Chapter"
												name="Chapter"
												rules={[
													{
														required: true,
														message: "Select chapter",
													},
												]}
											>
												<Select
													showSearch
													placeholder="Select Chapter"
													filterOption={(input, option) =>
														option.props.children
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0 ||
														option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
												>
													{chapterList &&
														chapterList.map((chapter) => (
															<Option key={chapter.chapter_id} value={chapter.chapter_id}>
																{chapter.chapters}
															</Option>
														))}
												</Select>
											</Form.Item>
											:
											<Form.Item
												label="Topic"
												name="topic"
												rules={[
													{
														required: true,
														message: "Select topic",
													},
												]}
											>
												<Select
													showSearch
													placeholder="Select topic"
													filterOption={(input, option) =>
														option.props.children
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0 ||
														option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
												>
													{topicList &&
														topicList.map((topic) => (
															<Option key={topic.topic_id} value={topic.topic_id}>
																{topic.Topics}
															</Option>
														))}
												</Select>
											</Form.Item>
										}
										<Form.Item
											label="Category"
											name="category"
										>
											<Select
												showSearch
												placeholder="Select category"
												filterOption={(input, option) =>
													option.props.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0 ||
														option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{constants.CategoryList &&
														constants.CategoryList.map((category) => (
															<Option key={category.category_id} value={category.category_id}>
																{category.category_name}
															</Option>
														))}
											</Select>
										</Form.Item>
										{categoryTypeId==0?
											<Form.Item
												label="Topic"
												name="topic"
											>
												<Select
													showSearch
													placeholder="Select topic"
													filterOption={(input, option) =>
														option.props.children
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0 ||
														option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
												>
													{topicList &&
														topicList.map((topic) => (
															<Option key={topic.topic_id} value={topic.topic_id}>
																{topic.Topics}
															</Option>
														))}
												</Select>
											</Form.Item>
											:
											<Form.Item
												label="Chapter"
												name="Chapter"
											>
												<Select
													showSearch
													placeholder="Select Chapter"
													filterOption={(input, option) =>
														option.props.children
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0 ||
														option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
												>
													{chapterList &&
														chapterList.map((chapter) => (
															<Option key={chapter.chapter_id} value={chapter.chapter_id}>
																{chapter.chapters}
															</Option>
														))}
												</Select>
											</Form.Item>
										}
									</Col>
									<Form.Item
										label="Question"
										name="question"
										rules={[
											{
												required: true,
												message: "",
											},
											{ validator: handleQuestionValidation },
										]}
										initialValue=''
									>
										<ReactQuill theme="snow" value={questionInfo.question} onChange={questionChange} modules={modules} formats={formats}/>
									</Form.Item>
									<Form.Item
										label="Answer"
										name="answer"
										rules={[
											{
												required: true,
												message: "",
											},
											{ validator: handleAnswerValidation },
										]}
										initialValue=''
									>
										<ReactQuill theme="snow" value={questionInfo.answer} onChange={answerChange} modules={modules} formats={formats}/>
									</Form.Item>
									<div className="text-right">
										<Space>
											<Form.Item>
												<Button type="primary" htmlType="submit" onClick={()=>setIsVerify(true)} className="lavender-btn">Verify</Button>
											</Form.Item>
											<Form.Item>
												<Button type="primary" onClick={()=>setIsVerify(false)} htmlType="submit" className="navy-btn">Save</Button>
											</Form.Item>
											<Form.Item>
												<Button onClick={resetFormCancel}>Cancel</Button>
											</Form.Item>										
										</Space>
									</div>
								</Form>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default AddFlashcard;
