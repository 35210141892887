import { Tabs, Skeleton, Button } from 'antd';
import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import constants from '../../constants/constants';
import { fetchApi } from "../../services/api";
import { COOKIE, getCookie } from "../../services/cookie";
import { useHistory } from "react-router";
const { TabPane } = Tabs;
const CategoryChart = () => {
	const history = useHistory();
	const [fppData,setFppData]=useState([]);
	const [saedData,setSaedData]=useState([]);
	const [pietData,setPietData]=useState([]);
	const [mixedData,setMixedData]=useState([]);
	useEffect(() => {
		fetchScoreCards();
	}, []);
	const fetchScoreCards = async() => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/compare-score?stud_id=${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				let chartData=["Tests", "1st attempt", "2nd attempt"];
				let fpp=[];
				let saed=[];
				let piet=[];
				let mixed=[];
				fpp.push(chartData);
				saed.push(chartData);
				piet.push(chartData);
				mixed.push(chartData);
				let responseData={};
				if(res.data.Categories){
					for (let index = 0; index < res.data.Categories.length; index++) {
						if(!responseData[res.data.Categories[index].test]){
							responseData[res.data.Categories[index].test]={};
							responseData[res.data.Categories[index].test]["attempt"+res.data.Categories[index].attempt.toString()]=res.data.Categories[index];
						}else{
							responseData[res.data.Categories[index].test]["attempt"+res.data.Categories[index].attempt.toString()]=res.data.Categories[index];
						}
					}
					Object.entries(responseData).forEach(([key, value]) => {
						let eachObjArr=[];
						if(key.includes("FPP")){
							eachObjArr.push(key,value.attempt1?Number(value.attempt1.total_corr_ans):0,value.attempt2?Number(value.attempt2.total_corr_ans):0);
							fpp.push(eachObjArr);
						}
						if(key.includes("SAED")){
							eachObjArr.push(key,value.attempt1?Number(value.attempt1.total_corr_ans):0,value.attempt2?Number(value.attempt2.total_corr_ans):0);
							saed.push(eachObjArr);
						}
						if(key.includes("PIET")){
							eachObjArr.push(key,value.attempt1?Number(value.attempt1.total_corr_ans):0,value.attempt2?Number(value.attempt2.total_corr_ans):0);
							piet.push(eachObjArr);
						}
						if(key.includes("Mixed")){
							eachObjArr.push(key,value.attempt1?Number(value.attempt1.total_corr_ans):0,value.attempt2?Number(value.attempt2.total_corr_ans):0);
							mixed.push(eachObjArr);
						}
					});
					setFppData(fpp);
					setSaedData(saed);
					setPietData(piet);
					setMixedData(mixed);
				}
			}
		});
	};
	let optionsFPP = { 
		colors: [constants.FPPDarkColor, constants.FPPLightColor], 
		vAxis: { 
			title: "Test Score",
			titleTextStyle: { italic: false },
			viewWindow: { min: 0 }
		},
		seriesType: "bars",
		legend: { 
			position: 'top',
			alignment: 'start' 
		},
	};
	let optionsSAED = { 
		colors: [constants.SAEDarkColor, constants.SAEDLightColor], 
		vAxis: { 
			title: "Test Score" ,
			titleTextStyle: { italic: false }, 
			viewWindow: { min: 0 }
		},
		seriesType: "bars",
		legend: { 
			position: 'top',
			alignment: 'start' 
		},
	};
	let optionsPIET = { 
		colors: [constants.PIETDarkColor, constants.PIETLightColor], 
		vAxis: { 
			title: "Test Score" ,
			titleTextStyle: { italic: false },
			viewWindow: { min: 0 }
		},
		seriesType: "bars",
		legend: { 
			position: 'top',
			alignment: 'start' 
		},
	};
	let optionsMIXED = { 
		colors: [constants.MIXEDDarkColor, constants.MIXEDLightColor], 
		vAxis: { 
			title: "Test Score" ,
			titleTextStyle: { italic: false },
			viewWindow: { min: 0 }
		},
		seriesType: "bars",
		legend: { 
			position: 'top',
			alignment: 'start' 
		},
	};
	return (
		<Tabs defaultActiveKey="1" centered>
			<TabPane tab={constants.CategoryFPP} key="1">
				{ fppData&&fppData.length>1 ? 
					<>
						<Chart
							chartType="ComboChart"
							loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
							width="100%"
							height="400px"
							data={fppData}
							options={optionsFPP}
						/>
					</>
					: 
					<>							
						<div className="nograph-div">
							<span>You have not taken any {constants.CategoryShortFPP} tests.</span>
							<span>Would you like to start now? </span>
							<span><Button type="primary" className="navy-btn mt-3" onClick={()=>{	history.push("/instructions");}}>{constants.TakeTest}</Button></span>
						</div>
					</>
				}
			</TabPane>
			<TabPane tab={constants.CategorySAED} key="2">
				{ saedData&&saedData.length>1 ? 
					<>
						<Chart
							chartType="ComboChart"
							loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
							width="100%"
							height="400px"
							data={saedData}
							options={optionsSAED}
						/>
					</>
					: 
					<>							
						<div className="nograph-div">
							<span>You have not taken any {constants.CategoryShortSAED} tests.</span>
							<span>Would you like to start now? </span>
							<span><Button type="primary" className="navy-btn mt-3" onClick={()=>{	history.push("/instructions");}}>{constants.TakeTest}</Button></span>
						</div>
					</>
				}
			</TabPane>
			<TabPane tab={constants.CategoryPIET} key="3">
				{ pietData&&pietData.length>1 ? 
					<>
						<Chart
							chartType="ComboChart"
							loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
							width="100%"
							height="400px"
							data={pietData}
							options={optionsPIET}
						/>
					</>
					: 
					<>							
						<div className="nograph-div">
							<span>You have not taken any {constants.CategoryShortPIET} tests.</span>
							<span>Would you like to start now? </span>
							<span><Button type="primary" className="navy-btn mt-3" onClick={()=>{	history.push("/instructions");}}>{constants.TakeTest}</Button></span>
						</div>
					</>
				}
			</TabPane>
			<TabPane tab={constants.CategoryMixed} key="4">
				{ mixedData&&mixedData.length>1 ? 
					<>
						<Chart
							chartType="ComboChart"
							loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
							width="100%"
							height="400px"
							data={mixedData}
							options={optionsMIXED}
						/>
					</>
					: 
					<>							
						<div className="nograph-div">
							<span>You have not taken any {constants.CategoryMixed} tests.</span>
							<span>Would you like to start now? </span>
							<span><Button type="primary" className="navy-btn mt-3" onClick={()=>{	history.push("/instructions");}}>{constants.TakeTest}</Button></span>
						</div>
					</>
				}
			</TabPane>
		</Tabs>
	);
};
export default CategoryChart;
