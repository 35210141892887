import React, { useState, useEffect } from "react";
import { Row, Col, BackTop, Card, Table, Tooltip, Space , Avatar , Skeleton, Button, Tabs } from 'antd';
import { InfoCircleOutlined, TrophyOutlined, StarFilled } from '@ant-design/icons';
import Chart from "react-google-charts";
import { fetchApi } from "../../services/api";
import CustomIcons from "../../assets/CustomIcons";
import Icon from '@ant-design/icons';
import { COOKIE, getCookie } from "../../services/cookie";
import constants from '../../constants/constants';
import { useHistory } from "react-router";
import '../../styles/test.css';
import CategoryChart from '../CompareScore/CategoryChart';
import TopicChart from '../CompareScore/TopicChart';
import QODChart from '../CompareScore/QODChart';
// Custom icons
const Certificate = (props) => (<Icon component={CustomIcons.getsvg("Certificate")} {...props}/>);
const WheatStar = (props) => (<Icon component={CustomIcons.getsvg("WheatStar")} {...props}/>);
const Graduation = (props) => (<Icon component={CustomIcons.getsvg("Graduation")} {...props}/>);
const { TabPane } = Tabs;
const CompareScore = () => {
	const history = useHistory();
	const [leaderBoardScore, setLeaderBoardScore] = useState([]);
	const [scoreCard, setScoreCards] = useState(null);
	const [arrgraph, setArrgraph] = useState({});
	const [graph, setGraph] = useState(true);
	const [latestScore, setLatestScore] = useState([]);
	useEffect(() => {
		fetchLatestScores();
		leaderboardScores();
		fetchScoreCards();
	}, []);
	const fetchScoreCards = async() => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/student/test-score?student_id=${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				setScoreCards( res.data );
				setGraph( true );
				console.log(scoreCard, f, x);
				var a = [];
				var b = [];
				var x = [];
				var numberArray = [];
				var arr = [];
				let resDataLength = res.data.length;
				for (var i = 1; i < resDataLength; i++) {
					a = res.data[i];
					b = a.shift();
					// b = b;
					numberArray = res.data[i].map(Number);
					var f = numberArray.unshift(b);
					arr.push(numberArray);
				}
				var arrgraph = [];
				x = arr.unshift(res.data[0]);
				arrgraph.graph = arr;
				setArrgraph(arr);
				// x = arr.unshift(res.data[0]);
				return true;
			}else{
				setScoreCards([]);
				setGraph( false );
			}
		});
	};
	// To Fetch the data for test taken by the user
	const fetchLatestScores = async() => {
		fetchApi(`/student/latest-top-ten-score`, "get").then((res) => {
			if (res && res.code === 200) {
				let tempMark=null;
				let count=0;
				res.data.map((item)=>{
					if(item.marks_scored===tempMark){
						item.srNum=count;
					}else{
						item.srNum=count+1;
						count=count+1;
					}
					tempMark=item.marks_scored;
				});
				setLatestScore( res.data );
			}else{
				setLatestScore([]);
			}
		});
	};
	const leaderboardScores = async() => {
		fetchApi(`/leaderboard?type=student`, "get").then((res) => {
			if (res && res.code === 200) {
				let tempMark=null;
				let count=0;
				res.data.map((item)=>{
					if(item.total_points===tempMark){
						item.srNum=count;
					}else{
						item.srNum=count+1;
						count=count+1;
					}
					tempMark=item.total_points;
				});
				setLeaderBoardScore( res.data );
			}else{
				setLeaderBoardScore([]);
			}
		});
	};
	// Self comparison chart data
	let options = {
		colors: [constants.TestScoreColor, constants.FPPDarkColor, constants.SAEDarkColor, constants.PIETDarkColor],
		vAxes: [
			{
				title: "Test Score",
				titleTextStyle: { italic: false },
				minValue: 0,
				ticks: [0 ,50, 100, 150, 200],
			},
			{
				title: "Correct Answers By Category",
				titleTextStyle: { italic: false },
				gridlines: { color: 'transparent', },
				minValue: 0,
				ticks: [0 ,12, 24, 36, 48],
			},
		],
		vAxis: { 
			gridlines: { minValue: 0, },
			minorGridlines: { 
				count: 0,
				color: 'transparent',
			},
		},
		hAxis: {
			title: "",
			titleTextStyle: { italic: false } ,
		},
		seriesType: "bars",
		series: {
			1: { targetAxisIndex: 1 }, // Bind series 0 to an axis named 'distance'.
			2: { targetAxisIndex: 1 },
			3: { targetAxisIndex: 1 }, // Bind series 1 to an axis named 'brightness'.
			0: { 
				type: "line",
				targetAxisIndex: 0,
				gridlines: { color: 'transparent', },
			}
		},
		axes: {
			y: {
				0: { 
					label: 'leftyaxis', 				
					minValue: 0,
					gridlines: { color: '#f3f3f3', },
				}, // Left y-axis.
				1: {
					side: 'left',
					label: 'rightyaxis',
					minValue: 0,
					gridlines: { color: 'transparent', },
				} // Right y-axis.
			}
		}
	};
	// Top ten table
	const topTenColumns = [
		{
			title: '#',
			key: 'srNum',
			dataIndex:'srNum',
			align:'center',
			className: 'td-right',
			// render: (value) => value,
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				const x = record.name;
				const myArray = x.split(" ");
				const initials = x.substring(0, 1).toUpperCase();
				const y = myArray[1];
				const initial2 = y.substring(0, 1).toUpperCase();
				return (
					<>
						<Space size="middle">
							<Avatar className="avatar">{initials}{initial2}</Avatar>{/* Don't call "avatar" class if expired sub user */}
							{/* <span>{record&&record.name? myArray[0].charAt(0).toUpperCase()+ myArray[0].slice(1) + "," + " " + initial2:"-"}</span> */}
							<span>{record&&record.name?record.name:"-"}</span>
						</Space>
					</>
				);
			}
		},
		{
			title: 'State',
			key: 'state',
			render: (record) => {
				return (
					<span>{record&&record.state?record.state:"-"}</span>
				);
			}
		},
		{
			title: 'Date',
			key: 'date',
			render: (record) => {
				return (
					<span>{record&&record.date ? (<span>{record.date}</span>) : ("-")}</span>
				);
			}
		},
		{
			title: 'Score',
			key: 'score',
			align:'center',
			className: 'td-right',
			render: (record) => {
				return (
					<span>{record&&record.marks_scored?record.marks_scored + "/" + "200":"-"}</span>
				);
			}
		},
	];
	// Leaderboard table
	const leaderboardColumns = [
		{
			title: '#',
			key: 'srNum',
			dataIndex:'srNum',
			align:'center',
			className: 'td-right',
			// render: (record) =>{
			// 	return(
			// 		<span>{record.srNum}</span>
			// 	);
			// }
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				const x = record.name;
				const myArray = x.split(" ");
				const initials = x.substring(0, 1);
				const y = myArray[1];
				const initial2 = y.substring(0, 1);
				return (
					<>
						<Space size="middle">
							<Avatar className="avatar">{initials}{initial2}</Avatar>{/* Don't call "avatar" class if expired sub user */}
							<span>{record&&record.name? record.name:"-"}</span>
						</Space>
					</>
				);
			}
		},
		{
			title: 'State',
			key: 'state',
			render: (record) => {
				return (
					<span>{record&&record.state?record.state:"-"}</span>
				);
			}
		},
		{
			title: 'Level',
			key: 'level',
			align: 'center',
			render: (record) => {
				let icons = " ";
				switch(record.level ) {
				case 1:
					icons = <div className="hexagon hex-sm level1"><StarFilled/></div>;
					break;
				case 2:
					icons = <div className="hexagon hex-sm level2"><Certificate/></div>;
					break;
				case 3:
					icons = <div className="hexagon hex-sm level3"><Graduation/></div>;
					break;
				case 4:
					icons = <div className="hexagon hex-sm level4"><WheatStar/></div>;
					break;
				case 5:
					icons = <div className="hexagon hex-sm level5"><TrophyOutlined/></div>;
					break;
				default:
					icons = "";
				}
				return (
					<Space size="middle" align="center">
						{icons}
						<span>{record&&record.level?"Level " +  record.level:"-"}</span>
					</Space>
				);
			}
		},
		{
			title: 'Points',
			key: 'points',
			align:'center',
			className: 'td-right',
			render: (record) => {
				return (
					<span>{record&&record.total_points?record.total_points:"-"}</span>
				);
			}
		},
	];
	return (
		<div className="p-4">
			<BackTop/>
			<Row gutter={[24, 24]}>
				<Col span={24}>
					<h1 className="text-center mb-0 text-uppercase">{constants.ViewCompareBTN}</h1>
				</Col>
				<Col span={24}>
					<Tabs defaultActiveKey="1" type="card" size="small" tabBarExtraContent={<Tooltip placement="left" title="The graph displays your performance on each test"><InfoCircleOutlined/></Tooltip>}>
						<TabPane tab={constants.FullLengthTests} key="1">
							{ graph ? 
								<>
									<Chart
										chartType="ComboChart"
										loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
										width="100%"
										height="400px"
										data={arrgraph}
										options={options}
										className="selfcompare-chart"
									/>
								</>
								: 
								<>							
									<div className="nograph-div">
										<span>You have not taken any Full length tests.</span>
										<span>Would you like to start now? </span>
										<span><Button type="primary" className="navy-btn mt-3" onClick={()=>{	history.push("/instructions");}}>{constants.TakeTest}</Button></span>
									</div>
								</>
							}
						</TabPane>
						<TabPane tab={constants.CategoryTest} key="2">
							<CategoryChart/>
						</TabPane>
						<TabPane tab={constants.TopicTest} key="3">
							<TopicChart/>
						</TabPane>
						<TabPane tab={constants.ViewDayQuestion} key="4">
							<QODChart/>
						</TabPane>
					</Tabs>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12}>
					<Card size="small" title={<span className="text-uppercase">{constants.TopTen}</span>} extra={<Space><Tooltip placement="left" title={constants.compareTestsInfoToolTip}><InfoCircleOutlined/></Tooltip></Space>}>
						<Table className="gap-table" columns={topTenColumns} 
							dataSource={latestScore}
							pagination={false}
						/>
						<Col>
							<div className="text-right">
								<a onClick={()=>history.push("/compare-score-more")} className="navy-2">View more</a>
							</div>
						</Col>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12}>
					<Card size="small" title={<span className="text-uppercase">{constants.Leaderboard}</span>} extra={<Space><Tooltip placement="left" title={constants.compareLeaderboardInfoTooltip}><InfoCircleOutlined/></Tooltip></Space>}>
						<Table className="gap-table" columns={leaderboardColumns} dataSource={leaderBoardScore} pagination={false}/>
						<Col>
							<div className="text-right">
								<a onClick={()=>history.push("/leaderboard-more")} className="navy-2">View more</a>
							</div>
						</Col>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default CompareScore;
