import React, { useState, useEffect } from "react";
import { List, Button, Space, Divider, Card, Skeleton, Popconfirm, message,Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import "../../styles/notes.css";
import constants from "../../constants/constants";
import { fetchApi } from "../../services/api";
import{ COOKIE,getCookie } from "../../services/cookie";
import ShowMoreText from "react-show-more-text";
import { useHistory } from "react-router";
const Notes = () => {
	const history = useHistory();
	const [loading,setLoading]=useState(false);
	const [initLoading,setInitLoading]=useState(false);
	const [notesList,setNotesList]=useState([]);
	const [displayNotesList,setdisplayNotesList]=useState([]);
	const [noteId,setNoteId]=useState(null);
	const [apiWait,setApiWait]=useState(false);
	const count = 9;
	document.addEventListener("contextmenu", (evt) => {
		evt.preventDefault();
	}, false);
	document.addEventListener("copy", (evt) => {
		evt.clipboardData.setData("text/plain", "");
		evt.preventDefault();
	}, false);
	const returnInitials=(key)=>{
		switch (key) {
		case "1":	
			return "st";
		case "2":	
			return "nd";
		case "3":	
			return "rd";
		}
	};
	//Button to display more notes
	const onLoadMore = () => {
		setLoading(true);
		let tempMainNotesList=notesList;
		let notesToAdd=tempMainNotesList.slice(displayNotesList.length,notesList.length>=displayNotesList.length+count?displayNotesList.length+count:notesList.length);
		let tempDisplayList=displayNotesList;
		let noteData = tempDisplayList.concat(notesToAdd);	 
		setdisplayNotesList(noteData);
		setLoading(false);
		window.dispatchEvent(new Event('resize'));
	};
	const loadMore =
			!initLoading && !loading ? (
				<div
					style={{
						textAlign: 'center',
						marginTop: 12,
						height: 32,
						lineHeight: '32px',
					}}
				>
					{notesList.length>displayNotesList.length?
						<Button type="primary" className="navy-btn" onClick={onLoadMore}>Load more</Button>
						:null}
				</div>
			) : null;
	useEffect(() => {
		setInitLoading(false);
		fetchNotesListData();
	}, []);
	//To fetch notes list data
	const fetchNotesListData = () => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/student/notes?stud_id=${userId}`, "get").then((res) => {
			if (res && res.data && res.data!==null&& res.data!==undefined) {
				setNotesList(res.data!==null?res.data:[]);
				let filterArrayData= res.data.slice(0,res.data.length>=count?count:res.data.length);
				setdisplayNotesList(filterArrayData);
				setApiWait(true);
			} else {
				setNotesList([]);
				setApiWait(true);
			}
		});
	};	
	//To delete note
	const deleteNote = () => {
		fetchApi(`/student/notes/${noteId}`, "delete").then((res) => {
			if (res && res.code===200) {
				const findNoteIndex=notesList.findIndex(a => a.note_id === noteId);
				let tempArrayNotes=notesList;
				tempArrayNotes.splice(findNoteIndex,1);
				setNotesList(tempArrayNotes);
				let newDisplayList=tempArrayNotes.slice(0,displayNotesList.length<=tempArrayNotes.length?displayNotesList.length:tempArrayNotes.length);
				setdisplayNotesList(newDisplayList);
				message.success(res.message);
			} else {
				message.error(res.message);
			}
		});
	};
	return (
		<>
			<div className="withp-footer-wrapper ">
				<div className="text-center pt-4">
					<h1>{constants.MyNotes}</h1>
				</div>
				<Divider className="mt-0"/>
				{apiWait?
					<div>
						{ displayNotesList.length>0?
							<div className="px-4 pb-4">
								{/* Col xs={24} sm={24} md={24} lg={8} */}
								<List
									grid={{
										gutter:16,
										xs: 1,
										sm: 2,
										md: 2,
										lg: 3,
										xl: 3,
										xxl: 4
									}}
									dataSource={displayNotesList}
									loadMore={loadMore}
									renderItem={item => (
										<List.Item >
											<Skeleton 
												loading={false} 
												active>
												<Card className="note-card" size="small" 
													title={
														<Space>
															<Tooltip title={item.tooltip}>{item.score_card.type.length>23?<b>{item.score_card.type.slice(0,20)}...</b>:<b>{item.score_card.type}</b>}</Tooltip>
															{item.is_tutorial===true?
																<i>[Tutorial]</i>
																:<i>[{item.score_card.attempt!==null?item.score_card.attempt:0}{returnInitials(item.score_card.attempt!==null?item.score_card.attempt:0)} Attempt]</i>
															}
														</Space>} 
													extra={
														<Popconfirm
															title="Are you sure to delete this note?"
															onConfirm={deleteNote}
															okText="Yes"
															cancelText="No"
														>
															<Button type="text" icon={<CloseOutlined/>} onClick={()=>setNoteId(item.note_id)}></Button>
														</Popconfirm>
													}>
													<div className="note-card-body">
														<ShowMoreText
															lines={3}
															more="Read more"
															less="Read less"
															className="ant-typography"
															keepNewLines={true}
															expanded={false}
															truncatedEndingComponent={"... "}
															width={400}
														>
															{item.notes}
														</ShowMoreText>
													</div>
													<div className="note-card-footer text-right">
														<Space>
															<span>{item.dt_created}</span> 
														</Space>
													</div>
												</Card>
											</Skeleton>
										</List.Item>
									)}
								/>
							</div>:
							<div className="nograph-div">
								<span>Notes can be made during the Category/Topic Tests and Tutorials.</span>
								<span>What would you like to do?</span>
								<Space>
									<span>
										<Button type="primary" className="navy-btn mt-3" onClick={()=>{	history.push("/instructions");}}>{constants.TakeTest}</Button></span>
									or
									<span><Button type="primary" className="navy-btn mt-3" onClick={()=>{	history.push("/select-tutorial");}}>Start a Tutorial</Button></span>
								</Space>
							</div> 
						}
					</div>
					:null}
			</div>
			{/* <div>
				{learnFooter()}
			</div> */}
		</>
	);
};
export default Notes;
