import React, { useState, useEffect } from 'react';
import { fetchAdminApi, fetchApi } from "../../../services/api";
import "../../../styles/notes.css";
import constants from "../../../constants/constants";
import { Breadcrumb, Row, Col, Card, Spin, Form, DatePicker, Space, Select, Button, Table, Popover, Tooltip } from 'antd';
import { FilterOutlined, CommentOutlined, SearchOutlined, UndoOutlined, CloseOutlined } from '@ant-design/icons';
import { Chart } from "react-google-charts";
import moment from "moment";
import { useHistory } from "react-router";
import { registrationFilter,displayOtherUniversityAlaska } from "../../../globalFunctions/GlobalFunctions";
import Loader from '../../Common/Cred/Loader';
const { Option, } = Select;
const FeedbackAnalysis = () => {
	const [form] = Form.useForm();
	const history = useHistory();
	const [feedbackList, setFeedbackList] = useState([]);
	const [feedbackListTemp, setFeedbackListTemp] = useState([]);//table data
	const [posFeedback, setposFeedback] = useState(0);
	const [negFeedback, setnegFeedback] = useState(0);
	const [posTempFeedback, setTempposFeedback] = useState(0);
	const [negTempFeedback, setTempnegFeedback] = useState(0);
	const [filterBlock, setFilterBlock] = useState(false);
	const [country, setCountry] = useState([]);
	const [state, setState] = useState([]);
	const [selectState, setSelectState] = useState(false);
	const [stateBlock, setStateBlock] = useState(false);
	const [optionKey, setOptionKey] = useState(null);
	const [university, setUniversity] = useState([]);
	const [uniBlock, setUniBlock] = useState(false);
	const [tempUniversity, setTempUniversity] = useState([]);
	const [graphData, setGraphData] = useState([]);
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [universityName,seUniversityName]= useState(null);
	const [noDataAvailable,setNoDataAvailable]=useState(false);
	const [loader,setLoader]=useState(false);
	// filter
	useEffect(() => {
		fetchFeedbackData();
		fetchFeedbackList();
		fetchCountryList();
		fetchStateList();
		fetchUniversityList();
	}, [startDate, endDate]);
	//To Fetch the data for test taken by the user
	const fetchFeedbackData = () => {
		let sd = moment(startDate).format("YYYY-MM-DD");
		let ed = moment(endDate).format("YYYY-MM-DD");
		let curentData = "/feedback-chart";
		let selectedMonth = `/feedback-chart?start_date=${sd}&end_date=${ed}`;
		fetchAdminApi(startDate && endDate ? selectedMonth : curentData, "get").then((res) => {
			if (res && res.code === 200) {
				let data = res.data;
				//data to display in graph.
				let gfHeader = [];
				gfHeader.push(["How did the students hear about the book?", "No. of Feedbacks"]);
				data.map(obj => gfHeader.push([obj.status_name, parseInt(obj.count)]));
				gfHeader.arr = gfHeader;
				let tempData=res.data.filter((item)=>parseInt(item.count)!==0);
				if(tempData.length>0){
					setNoDataAvailable(false);
				}else{
					setNoDataAvailable(true);
				}
				setGraphData(gfHeader);
			}else{
				setNoDataAvailable(true);
				setGraphData([]);
			}
		});
	};
	const fetchFeedbackList = () => {
		setLoader(true);
		let url = `/feedback?start_date=${constants.startDateOfTheYear}&end_date=${constants.endDateOfTheYear}`;
		if(startDate && endDate){
			url = `/feedback?start_date=${moment(startDate).format("YYYY-MM-DD")}&end_date=${moment(endDate).format("YYYY-MM-DD")}`;
		}
		fetchAdminApi(url,"get").then((res)=> {
			if(res && res.code===200){
				setFeedbackList( res.data.feedback_data );
				setFeedbackListTemp ( res.data.feedback_data );
				setposFeedback(res.data.positive);
				setnegFeedback(res.data.negative);		
				setTempposFeedback(res.data.positive);
				setTempnegFeedback(res.data.negative);		
				setLoader(false); 
			}
			else{
				setFeedbackList([]);
				setLoader(false);
			}
		});
	};
	// country
	const fetchCountryList = () => {
		fetchApi(`/countries`,"get").then((res)=> {
			if(res && res.code===200){
				//removed the US Country by filtering 
				let remainingArr = res.data.filter(data => data.country_id != '238');
				//Adding a US country at zero index
				remainingArr.unshift({
					country_id: "238",
					country_code: "US",
					country_name: "United States"
				});
				setCountry( remainingArr );
			}
			else{
				setCountry([]);
			}
		});
	};
	// state
	const fetchStateList = () => {
		fetchApi(`/states?country_code=US`,"get").then((res)=> {
			if(res && res.code===200){
				setState( res.data );
				setSelectState( res.data );
			}
			else{
				setState([]);
			}
		});
	};
	// university
	const fetchUniversityList = () => {
		fetchApi(`/universities?country_code=US`,"get").then((res)=> {
			if(res && res.code===200){
				Object.entries(res.data).forEach(([key, value]) => {
					displayOtherUniversityAlaska(key,value);
				});
				setUniversity( res.data );
			}
			else{
				setUniversity([]);
			}
		});
	};
	const graphOption = {
		title: `Feedback Chart  ${startDate && endDate ? `(${moment(startDate).format(constants.dateFormat)} to ${moment(endDate).format(constants.dateFormat)})`:`(${moment(constants.startDateOfTheYear).format(constants.dateFormat)} to ${moment(constants.endDateOfTheYear).format(constants.dateFormat)})`}`,
		legend: { position: 'none' },
		colors: ['#000080'],
		hAxis: {
			title: "How did the students hear about the book?",
			slantedText: true ,
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			},
			minorGridlines: { 
				count: 0,
				color: 'transparent',
			},
		},
		vAxis: {
			title: "No. of Feedbacks",
			format: '#',
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false,
			}
		},
		minorGridlines: { 
			count: 0,
			color: 'transparent',
		},
	};
	const from = (date, dateString) => {
		setStartDate(dateString);
	};
	const to = (date, dateString) => {
		setEndDate(dateString);
	};
	// table filter
	const showFilter = () => {
		setFilterBlock(!filterBlock);
	};
	const graphFilter = (array) =>{
		let temp0 = array.filter((data) =>{
			return data?.book_status === "0";
		});
		let temp1 = array.filter((data) =>{
			return data?.book_status === "1";
		});
		let temp2 = array.filter((data) =>{
			return data?.book_status === "2";
		});
		let temp3 = array.filter((data) =>{
			return data?.book_status === "3";
		});
		let temp4 = array.filter((data) =>{
			return data?.book_status === "4";
		});
		let temp5 = array.filter((data) =>{
			return data?.book_status === "5";
		});
		let temp6 = array.filter((data) =>{
			return data?.book_status === "6";
		});
		let temp7 = array.filter((data) =>{
			return data?.book_status === "7";
		});
		let temp8 = array.filter((data) =>{
			return data?.book_status === "8";
		});
		let push = [];//graph data formate
		push.push({
			count :temp0.length,
			book_status:0,
			status_name:constants.HearAbtBookOption1
		},{
			count :temp1.length,
			book_status:1,
			status_name:constants.HearAbtBookOption3
		},{
			count :temp2.length,
			book_status:2,
			status_name:constants.HearAbtBookOption5
		},{
			count :temp3.length,
			book_status:3,
			status_name:constants.HearAbtBookOption2
		},{
			count :temp4.length,
			book_status:4,
			status_name:constants.HearAbtBookOption4
		},{
			count :temp5.length,
			book_status:5,
			status_name:constants.HearAbtBookOption6
		},{
			count :temp6.length,
			book_status:6,
			status_name:constants.HearAbtBookOption8
		},{
			count :temp7.length,
			book_status:7,
			status_name:constants.HearAbtBookOption9
		},{
			count :temp8.length,
			book_status:8,
			status_name:constants.HearAbtBookOption7
		});
		let gfHeader = [];
		gfHeader.push(["How did the students hear about the book?", "No. of Feedbacks"]);
		push.map(obj => gfHeader.push([obj.status_name, parseInt(obj.count)]));
		gfHeader.arr = gfHeader;
		setGraphData(gfHeader);
	};
	const onFinish = (values) => {
		if(values.country && values.university){
			let	array = feedbackList.filter(function (el) {
				return el.country === values.country &&(el.university? el.university.replace(/\s+/g, '').toLowerCase() === universityName.replace(/\s+/g, '').toLowerCase():false);
			});
			graphFilter(array);//filter for graph
			setFeedbackListTemp(array);
			let posList1=array.filter(function(el){
				return el.test_rating!=="3";
			});
			setTempposFeedback(posList1.length);
			setTempnegFeedback(array.length-posList1.length);
		}
		if(values.country && !values.university){
			let	array = feedbackList.filter(function (el) {
				return el.country === values.country;
			});
			graphFilter(array);//filter for graph
			setFeedbackListTemp(array);
			let posList2=array.filter(function(el){
				return el.test_rating!=="3";
			});
			setTempposFeedback(posList2.length);
			setTempnegFeedback(array.length-posList2.length);
		}
		if(optionKey && !values.university ){
			let	array = feedbackList.filter(function (el) {
				return el.country === values.country && el.state === optionKey;
			});
			graphFilter(array);//filter for graph
			setFeedbackListTemp(array);
			let posList3=array.filter(function(el){
				return el.test_rating!=="3";
			});
			setTempposFeedback(posList3.length);
			setTempnegFeedback(array.length-posList3.length);
		}
	};
	const changeState = (e) => {
		form.setFieldsValue({
			state:null,
			university:null
		});
		setOptionKey(null);
		setTempUniversity(null);
		seUniversityName(null);
		if (e==="US") {
			setSelectState(true);
			setStateBlock(true);
			setUniBlock(true);
		}
		else {
			setSelectState(false);
			setStateBlock(false);
			setUniBlock(false);
		}
	};
	const changeUniversity = (e,option) => {
		form.setFieldsValue({ university:null });
		seUniversityName(null);
		Object.entries(university).forEach(([key, value]) => {
			if (key === e) {
				let obj={};
				obj[e]=value;
				setTempUniversity(obj);
				// setUniBlock(true);
			}
			else if ( value === null) {
				setTempUniversity(null);
				// setUniBlock(false);
			} 
			// handals for other university
			// else{
			// 	let obj={};
			// 	let k = "Other University";
			// 	obj[k]=value;
			// 	setTempUniversity(obj);
			// 	console.log("obj",obj);
			// }	
		});
		setOptionKey(option?.key);
	};
	const onReset = () => {
		form.resetFields();
		setFeedbackListTemp(feedbackList);
		setTempUniversity([]);
		setTempnegFeedback(negFeedback);
		setTempposFeedback(posFeedback);
		setOptionKey(null);
		seUniversityName(null);
	};
	const feedbackColumns = [
		{
			title: 'Date',
			key: 'date',
			align: 'center',
			render: (record) => {
				return <span>{ record&&record.dt_created ? moment(record.dt_created).format("MM/DD/YYYY") : "" }</span>;
			},
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				if(record.is_active==="4"){
					return <span>{"Stud - "+record.stud_id}</span>;
				}else{
					return <span>{record && record.stud_firstname && record.stud_lastname ? record.stud_firstname + ", " + record.stud_lastname.charAt(0) : ""}</span>;
				}
			}
		},
		{
			title: 'Country',
			key: 'country',
			sorter: (a, b) => {
				a = a.country || '';
				b = b.country || '';
				return a.localeCompare(b);
			},
			render: (record) =>{
				return <span>{record && record.country ? record.country : "-"}</span>;
			}
		},
		{
			title: 'State',
			key: 'state',
			sorter: (a, b) => {
				a = a.state || '';
				b = b.state || '';
				return a.localeCompare(b);
			},
			render: (record) =>{
				return <span>{record && record.state ? record.state : "-"}</span>;
			}
		},
		{
			title: 'University',
			key: 'university',
			render: (record) =>{
				return <span>{record && record.university ? record.university : ""}</span>;
			}
		},
		{
			title: 'How did you hear about this book?',
			key: 'book_status',
			align:'left',
			render: (record) => {
				let book_status = "";
				if(record.book_status==="0"){
					book_status = <>{constants.HearAbtBookOption1}</>;
				}
				else if (record.book_status==="1") {
					book_status = <>{constants.HearAbtBookOption3}</>;
				}
				else if (record.book_status==="2") {
					book_status = <>{constants.HearAbtBookOption5}</>;
				}
				else if (record.book_status==="8") {
					book_status = <>{constants.HearAbtBookOption7}</>;
				}
				else if (record.book_status==="3") {
					book_status = <>{constants.HearAbtBookOption2}</>;
				}
				else if (record.book_status==="4") {
					book_status = <>{constants.HearAbtBookOption4}</>;
				}
				else if (record.book_status==="5") {
					book_status = <>{constants.HearAbtBookOption6}</>;
				}
				else if (record.book_status==="6") {
					book_status = <>{constants.HearAbtBookOption8}</>;
				}
				else if (record.book_status==="7") {
					book_status = <>{constants.HearAbtBookOption9}</>;
				}
				else { ""; }
				return <span>{record && record.book_status ? book_status : ""}</span>;
			}
		},
		{
			title: 'The tests were challenging',
			key: 'test_status',
			align: 'left',
			filters: [
				{
					text: 'All',
					value: 'All',
				},
				{
					text: <>{constants.TestRatingAgree}</>,
					value: '0',
				},
				{
					text: <>{constants.TestRatingUndecided}</>,
					value: '1',
				},
				{
					text: <>{constants.TestRatingDisagree}</>,
					value: '2',
				},
			],
			onFilter: (value, record) => {
				if (value==="All"){
					return record.test_status.indexOf(value==="1") && record.test_status.indexOf(value==="2") && record.test_status.indexOf(value==="3");
				}
				else {
					return record.test_status.indexOf(value) === 0;
				}
			},
			render: (record) => {
				let test_status = "";
				let feedback_status = "";
				if(record.test_status==="0"){
					test_status = <>{constants.TestRatingAgree}</>;
					feedback_status = "green";
				}
				else if (record.test_status==="1") {
					test_status = <>{constants.TestRatingUndecided}</>;
					feedback_status= "red";
				}
				else if (record.test_status==="2") {
					test_status = <>{constants.TestRatingDisagree}</>;
					feedback_status = "green";
				}
				else {
					test_status = "";
				}
				return <span>{ record&&record.test_status ? <span className={feedback_status}>{test_status}</span> : "" }</span>;
			},
		},
		{
			title: 'Overall rating of the entire product',
			key: 'test_rating',
			align: 'left',
			filters: [
				{
					text: 'All',
					value: 'All',
				},
				{
					text: <>{constants.ProductRatingExcellent}</>,
					value: '0',
				},
				{
					text: <>{constants.ProductRatingVeryGood}</>,
					value: '1',
				},
				{
					text: <>{constants.ProductRatingGood}</>,
					value: '2',
				},
				{
					text: <>{constants.ProductRatingFair}</>,
					value: '3',
				},
			],
			onFilter: (value, record) => {
				if (record.test_rating===value){
					return record.test_rating.includes(value);
				}
				else if (value === 'All'){
					return record.test_rating!==null;
				}
			},
			render: (record) => {
				let pr_rating = "";
				let feedback_status = "";
				if(record.test_rating==="0"){
					pr_rating = <>{constants.ProductRatingExcellent}</>;
					feedback_status = "green";
				}
				else if (record.test_rating==="1") {
					pr_rating = <>{constants.ProductRatingVeryGood}</>;
					feedback_status = "green";
				}
				else if (record.test_rating==="2") {
					pr_rating = <>{constants.ProductRatingGood}</>;
					feedback_status = "green";
				}
				else if (record.test_rating==="3") {
					pr_rating = <>{constants.ProductRatingFair}</>;
					feedback_status= "red";
				}
				else {
					pr_rating = "";
				}
				return <span>{ record&&record.test_rating ? <span className={feedback_status}>{pr_rating}</span> : "" }</span>;
			},
		},
		{
			title: 'Comments',
			key: 'comments',
			align: 'center',
			render: (record) => {
				return <><Popover content={record.test_comments} title={false} overlayClassName	="comment-popover">
					{record&&record.test_comments ? <CommentOutlined/> : ""}
				</Popover>
				</>;
			},
		},
		{
			title: 'Date Reply',
			key: 'date_reply',
			align: 'center',
			render: (record) => {
				return <span>{ record&&record.dt_reply ? record&&record.dt_reply : "" }</span>;
			},
		},
	];
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const onChangeDateFilter = (e) => {
		registrationFilter(e,setStartDateEndDate);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
	};
	const selectFilterOption = (input,option)=>{
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
		// option.key.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.key.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="div">
					<div className="p-3">
						<Breadcrumb>
							<Breadcrumb.Item ><a onClick={() => {
								history.push({ pathname: "/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/analysis-menu" });
							}}>Analysis</a></Breadcrumb.Item>
							<Breadcrumb.Item>Feedback</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<div>
						<Row gutter={[24, 24]}>
							<Col xs={24} sm={24} md={24} lg={24}>
								<Card size="small" title={<span className="text-uppercase"> Feedback Score</span>} extra={
									<Form layout="horizontal" form = {form}>
										<Space>
											<Form.Item className="label-default mb-0" label="From:" name = "from">
												<DatePicker 
													onChange={from}
													format="MM/DD/YYYY" inputReadOnly={true}
													disabledDate={disabledFutureDate}
												/>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="To:" name = "to">
												<DatePicker 
													onChange={to}
													format="MM/DD/YYYY" inputReadOnly={true}
													disabledDate={disabledPastDate}
												/>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Select defaultValue="0" style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
													<Option value="0">This Year</Option>
													<Option value="7">Last Year</Option>
													<Option value="1">This Month</Option>
													<Option value="2">Last Month</Option>
													<Option value="3">Last 3 Months</Option>
													<Option value="4">This Month (Last Year)</Option>
													<Option value="5">Last 3 Months (Last Year)</Option>
													<Option value="6">Previous Years</Option>
												</Select>
											</Form.Item>
										</Space>
									</Form>
								}>
									<div>
										<Row justify="end">
											<Button type="primary" icon={<FilterOutlined/>} size={"middle"} onClick={showFilter} className='mb-3'/>		
										</Row>
									</div>
									{filterBlock ?
										<Card bodyStyle={{ 
											paddingRight: 0, 
											paddingLeft: 0 
										}}>
											<Form form={form} name="control-hooks" onFinish={onFinish}>
												<Row gutter={[48,0]}>
													<Col span={8}>
														<Form.Item className="label-default mb-0" label="Country" name="country">
															<Select placeholder="Country" 
																onChange={changeState} 
																filterOption={selectFilterOption}
																className="w-100" 
																allowClear 
																showSearch
															>
																{country &&
																	country.map((country) => (
																		<Option key={country.country_code} value={country.country_code}>
																			{country.country_name}
																		</Option>
																	))}
															</Select>
														</Form.Item>
													</Col>
													<Col span={8}>
														{
															stateBlock ?
																<Form.Item className="label-default mb-0" label="State" name="state">
																	<Select 
																		placeholder="State" 
																		onChange={changeUniversity}
																		filterOption={selectFilterOption}
																		allowClear 
																		showSearch
																	>
																		{selectState? 
																			state.map((state) => (
																				<Option key={state.region_code} value={state.region_name}>
																					{state.region_name}
																				</Option>
																			)): ''
																		}
																	</Select>
																</Form.Item>
																: ""
														}
													</Col>
													<Col span={8}>
														{uniBlock?
															<Form.Item className="label-default mb-0" label="University"  name="university">
																<Select 
																	placeholder="University" 
																	allowClear 
																	showSearch 
																	onChange={(e,options)=>{ 
																		seUniversityName(options.children);}}
																	filterOption={selectFilterOption}
																>
																	{tempUniversity?
																	// eslint-disable-next-line no-unused-vars
																		Object.entries(tempUniversity).map(([ key,value]) => (
																			<>
																				{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																			</>
																		)):
																		university &&
																		// eslint-disable-next-line no-unused-vars
																		Object.entries(tempUniversity ? tempUniversity : university).map(([ key,value]) => (
																			<>
																				{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																			</>
																		))
																	}
																</Select>
															</Form.Item>
															:""}
													</Col>
													<Col span={24}>
														<div className="justify-end mt-4 mb-0">
															<Space >
																<Button type="primary" htmlType="submit">
																	<SearchOutlined/>Search
																</Button>
																<Button htmlType="button" onClick={onReset} icon={<UndoOutlined/>}>
																	Reset
																</Button>
																<Button htmlType="button" onClick={showFilter} type="primary" danger>
																	<span><CloseOutlined/>	Close</span>
																</Button>
															</Space>
														</div>
													</Col>
												</Row>
											</Form>
										</Card> : ""
									}
									<div>
										{noDataAvailable?
											<>							
												<div className="nograph-div">
													<span>No Data Available.</span>
												</div>
											</>:	
											<Chart
												chartType="ColumnChart"
												width="100%"
												height="400px"
												data={graphData}
												options={graphOption}
											/>
										}
									</div>
									<br/>
									<div>
										<Table className="admin-table" 
											locale={{ emptyText: 'No Records Found' }}
											dataSource={feedbackListTemp} 
											columns={feedbackColumns}
											scroll={{ x: 1200 }}
										/>
									</div>
									<div className="div-below-grid">
										<Space>
											<Tooltip title={constants.positiveFeedback}>
												<span>Positive Feedback: {posTempFeedback} </span>
											</Tooltip>
											<Tooltip title={constants.negetiveFeedback}>
												<span>Negative Feedback: {negTempFeedback}</span>
											</Tooltip>
											<Button onClick = { ()=>{
												history.push({ pathname:"/feedback-comment" });
											}}  type="link">Other Feedback</Button>
										</Space>
									</div>
								</Card>
							</Col>
						</Row>
					</div>;
				</div>
			</Spin>
		</>
	);
};
export default FeedbackAnalysis;
