/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { COOKIE, getCookie } from "../services/cookie";
/**
 *
 * @param {Object} Route parameters
 * @description Route parameters must contain layout, component and other props. Layout has to be the master oage.
 */
export function RouteWithLayout({ layout, auth, component, ...rest }) {
	let userId = getCookie(COOKIE.UserId);
	return (
		<Route
			{...rest}
			render={(props) =>
				userId?
					React.createElement(
						layout,
						{
							...props,
							...rest 
						},
						React.createElement(component, {
							...props,
							...rest 
						})
					):<Redirect to='/'/>
			}
		/>
	);
}
