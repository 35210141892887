/*
File Name: Book.js
Author: Dahlin Carneiro
Modified On: 08/06/2022
Description:Book cover section module.
*/
import { Col, Image } from "antd";
const Book = () => {
	return (
		<Col
			lg={{
				span: 24,
				offset: 0
			}}
			xl={{
				span: 8,
				offset: 1
			}}
			className="book-col flex-middle">
			<div>
				{/* <div className="d-sm-none maintain-txt-m">
					<p
						style={{
							fontSize: "1.25rem",
							color: 'red',
							fontWeight: 'bold'
						}}
					>
						The website will be under maintenance on August 24th from 9pm CST to August 25th 9am CST.
					</p>
					<p
						style={{
							fontSize: "1.25rem",
							color: 'red',
							fontWeight: 'bold'
						}}
					>
						We appreciate your patience as the site may be unavailable or unresponsive during this time.
					</p>
				</div> */}
				<Image src="imgs/slp-6th-edition.png" alt="SLP 6th edition" preview={false}/>
			</div>
		</Col>
	);
};
export default Book;