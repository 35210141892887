import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import {
	Row,
	Col,
	Card,
	BackTop,
	Table,
	Breadcrumb,
} from "antd";
import { fetchAdminApi, } from "../../../services/api";
import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import constants from '../../../constants/constants';
import { useHistory } from "react-router";
const QodAnswered = () => {
	const history = useHistory();
	const [qodAnswereddata, setQodAnswereddata] = useState();
	const [tokenValue,setTokenValue]=useState();
	const pagination = { showSizeChanger: true, };
	const qodAnsweredColumns = [
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'date',
			width: '150px',
			align: 'left',
			render: (record) => {
				return (<span>{moment(record).format(constants.dateFormat)}</span>);
			},
		},
		{
			title: 'Question',
			key: 'question',
			dataIndex: 'question',
			align: 'left',
			render:(record) =>{
				return <span dangerouslySetInnerHTML={{ __html: record }}/>;
			}
		},
		{
			title: 'Correct Answer',
			key: 'correct_ans',
			dataIndex: 'correct_ans',
			// className: 'td-right',
			align: 'center',
			render: (record) => {
				return (<span>{record.join(',')}</span>);
			},
		},
		{
			title: 'User Answer',
			key: 'user_ans',
			dataIndex: 'user_ans',
			// className: 'td-right',
			align: 'center',
			render: (record) => {
				return (<span>{record.join(',')}</span>);
			},
		},
		{
			title: 'Correct',
			key: 'is_correct',
			dataIndex: 'is_correct',
			align: 'center',
			render: (record) => {
				if(record===1)
				{
					return (<span><CheckOutlined twoToneColor="green"/></span>);
				}else{
					return (null);
				}
			},
		},
		{
			title: 'Points Earned',
			key: 'point_earned',
			dataIndex: 'point_earned',
			className: 'td-right',
			align: 'center',
			render: (record) => {
				return (<span>{record}</span>);
			},
		},
	];
	useEffect(() => {
		let queryString = window.location.href;
		var token = queryString.substring(queryString.lastIndexOf('?') + 1);
		setTokenValue(token);
		fetchQodAnswereddata(token);
	}, []);
	const fetchQodAnswereddata =(token)=>{
		fetchAdminApi(`/qod-data/${token}`, "get").then((res) => {
			if (res && res.data) {
				setQodAnswereddata(res.data);
			}else{
				setQodAnswereddata([]);
			}
		});
	};
	return (
		<>
			<div className='gray-bg'>
				<div className='p-4'>
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item><a onClick={() => {
							sessionStorage.setItem("showEditSaveButton","show");
							history.push({ pathname:"/edit-student", });
						}}>Edit Student User</a></Breadcrumb.Item>
						<Breadcrumb.Item>
						Qod Answered</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<BackTop/>
				<Row gutter={[24, 24]} >
					<Col xs={24} sm={24} md={24} lg={24}>
						<Card size="small" title={<><span className="text-uppercase">QOD Answered - {qodAnswereddata?.student_name}</span>
							<span style={{ visibility:"hidden" }}>{" "}Student ID:{tokenValue}</span>
						</>} 
						extra={<span>Username: {qodAnswereddata?.user_name}</span>}
						>						
							<p>Total QOD Answered: {qodAnswereddata&&qodAnswereddata.length}</p>
							<Table className="admin-gap-table" 
								locale={{ emptyText: 'No Records Found' }}
								dataSource={qodAnswereddata?.details} 
								columns={qodAnsweredColumns}
								pagination={pagination}
							/>
						</Card>
					</Col>
				</Row> 
			</div>
		</>
	);
};
export default QodAnswered;
