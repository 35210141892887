/*
File Name: Activation.js
Author: Mevito Gonsalves
Modified On: 13/04/2022
Description:Verifying the registered user.
API's used:
1) /student/register/check-activation-validity/${tokenValue}
2) /student/register/resend-activation-link
*/
import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Card,
	Button
} from "antd";
import "../../../styles/test.css";
import { fetchApi } from "../../../services/api";
import { useHistory } from "react-router";
import constants from "../../../constants/constants";
import Footer from "./../../../footer";
import Headers from "../../../header";
import Book from './Book';
const Activation = () => {
	const history = useHistory();
	const [userToken,setUserToken]=useState("");
	const [displayValidationMessage,setValidationMessage]=useState("");
	const [showLoginButton,setShowLoginButton]=useState(false);
	const [showResendMessage,setresendMessage]=useState(false);
	const [apiWait,setApiWait]=useState(false);
	useEffect(() => {
		fetchUrlParameters();
	}, []);
	const fetchUrlParameters=()=>{
		let queryString = window.location.href;
		var token = queryString.substring(queryString.lastIndexOf('?') + 1);
		setUserToken(token);
		checkActivationvalidity(token);
	}; 
	const checkActivationvalidity=(tokenValue)=>{
		fetchApi(`/student/register/check-activation-validity/${tokenValue}`, "post").then((res) => {
			if (res && res.code ) {
				setValidationMessage(res.message);
				let studentId=res.student_id?res.student_id:null;
				setresendMessage(false);
				if((res.code===400&&studentId===null)||(res.code===200&&studentId===null)){
					setShowLoginButton(true);
				}else{
					setShowLoginButton(false);
				}
				setApiWait(true);
			}else{
				setApiWait(true);
			}
		});
	};
	const resendActivationLink=()=>{
		let payload=null;
		payload={ "token": userToken };
		fetchApi(`/student/register/resend-activation-link`, "post",payload).then((res) => {
			if (res && res.code) {
				setValidationMessage(res.message);
				setresendMessage(true);
				if(res.code==200){
					setShowLoginButton(false);
				}else{
					setShowLoginButton(true);
				}
			}
		});
	};
	return (
		<div>
			<Headers/>
			<div className="login-content flex-vertical-middle">
				<Row gutter={[{
					xs: 8,
					sm: 8,
					md: 48,
					lg: 8,
					xl: 8 
				}, 8]} className="login-px">
					<Book/>
					<Col lg={24} xl={14} className="w-100 flex-middle">
						{apiWait===true?
							<Card className="ml-135">
								<h1 className="purple-bg p-3">Account Activation</h1>
								<>
									{showLoginButton===true||showResendMessage===true?	
										<p >{displayValidationMessage}</p>
										:
										<p >{constants.ActivationLintExpired} Click <a onClick={()=>resendActivationLink()}>here </a> {constants.ActivationLintGenerate}</p>
									}
								</>
								{showLoginButton===true?
									<div className="text-right">
										<Button  className="btnDb" onClick={()=>history.push("/login")}>Go to Login</Button>
									</div>:null}
							</Card>
							:null}
					</Col>
				</Row>
			</div>
			<Footer/>
		</div>
	);
};
export default Activation;