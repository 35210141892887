/*
File Name: StartTutorial.js
Author: Akshay Lotlikar
Modified On: 13/05/2021
Description: Answering the tutorial questions for category (FPP or PIET or SAED) or Topic tutorial. Page also used to display the Question of the day, Mark for later and Answer incorrect tutorial.
API's used:
1) /student/start-test
2) /score_card/${scoreCardId}
3) /student/quit-test
*/
import React, { useState,useEffect } from "react";
import { Col, Row, Button, Card, Radio, Space, Tooltip,Input, Divider, Modal, message,Checkbox, Spin ,Badge,Form, Alert } from 'antd';
import { HighlightOutlined, StrikethroughOutlined, LeftOutlined, RightOutlined,DragOutlined ,SaveOutlined } from '@ant-design/icons';
import '../../styles/test.css';
import constants from "../../constants/constants";
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from "react-sortable-hoc";
import{ COOKIE,getCookie } from "../../services/cookie";
import { fetchApi } from "../../services/api";
import arrayMove from "array-move";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Draggables from 'react-draggable';
import { useHistory } from "react-router";
import $ from "jquery";
import useUnsavedChangesWarning from "../Test/Instructions/UnsavedChanges";
import CustomIcons from "../../assets/CustomIcons";
import Icon from '@ant-design/icons';
import { displayQuestionDetails, featuredImageUrl } from "../../globalFunctions/GlobalFunctions";
import { cloneDeep } from "lodash";
import Loader from "../Common/Cred/Loader";
const Comment = (props) => (<Icon component={CustomIcons.getsvg("Comment")} {...props}/>);
const { TextArea } = Input;
const DragHandle = sortableHandle(() => (
	<span>
		<DragOutlined className='drag-icon drag-icon-size'/>
	</span>
));
const SortableItem = sortableElement(({ value,indexPos,handleSequence,MouseDown,MouseUp,disabled,TouchStart,TouchEnd  }) => (
	<ul className="test-sortable">
		<li className="flex-vertical-middle">
			<span className="seq-counter">{constants.OptionstoDisplay[indexPos]}</span>
			<DragHandle/>
			<p id={indexPos} onClick={()=>{handleSequence(indexPos);}} onMouseDown={(e)=>{MouseDown(e);}} onMouseUp={(e)=>{MouseUp(e,indexPos);}} disabled={disabled==="true"?true:false} onTouchStart={TouchStart} onTouchEnd={TouchEnd} className='pl-2 list-item' dangerouslySetInnerHTML={{ __html:value }}></p>
		</li>
	</ul>
));
//Drag area
const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});
const CategoryTutorial = (props) => {
	const history = useHistory();
	//Columns to display the drag and drop type questions
	let columnsOfDragAndDrop = {
		[1]: { items: [] },
		[2]: { items: [] },
		[3]: { items: [] },
		[4]: { items: [] },
		[5]: { items: [] },
		[6]: { items: [] },
		[7]: { items: [] },
		[8]: { items: [] },
		[9]: { items: [] },
	};
	const [listQuestions,setQuestions]=useState([]);
	const [questionIndex,setQuestionIndex]=useState(0);
	const [sortitems, setSortItems] = useState([]);
	const [refresh,setRefresh]=useState(false);
	const [pauseTest,setPauseTest]=useState(false);
	const [scoreCardId,setScoreCardId]=useState(null);
	const [nextAndPreviousLoading,setNextAndPreviousLoading]=useState(false);
	const [completeTestLoading,setCompleteTestLoading]=useState(false);
	const [quitTestLoading,setQuitTestLoading]=useState(false);
	const [selectedSequenceOption,setSelectedSequenceOption]=useState("");
	const [selectedDragOption,setSelectedDragOption]=useState(null);
	//sessionStorage
	const [categoryDetails,setCategoryDetails]=useState({});
	const [topicDetails,setTopicDetails]=useState({});
	const [customDetails,setCustomDetails]=useState({});
	const [questionOfDayDetails,setQuestionOfDayDetails]=useState({});
	const [markForLaterAndAnswerIncorrectDetails,setMarkForLaterDetails]=useState({});
	const [columns, setColumns] = useState(columnsOfDragAndDrop);
	const [Prompt,setDirty,setPristine]=useUnsavedChangesWarning();
	const [noteForm] = Form.useForm();
	const [disabled,setIsDisabled]=React.useState(true);
	const [notesVisible, setnotesVisible] = useState(false);
	const [mouseUp,setMouseUp]=useState(0);
	const [mouseDown,setMouseDown]=useState(0);
	const [selectedId,setSelectedId]=useState(null);
	const draggleRef = React.createRef();
	const [notelist,]=useState([]);
	const [QODmessage,setQODmessage]=useState("");
	const [QODQuestionAnswered,setQODQuestionAnswwered]=useState("2");
	const [bounds,setBounds]=React.useState({
		left: 0,
		top: 0,
		bottom: 0,
		right: 0 
	});
	const [onMouseDownId,setonMouseDownId]=useState(null);
	const [browserTime,setbrowserTime]=useState("");
	const [serverTime,setServerTime]=useState("");
	const showModal = () => {
		setnotesVisible(true);
		let itemTemp=listQuestions[questionIndex];
		if (itemTemp.notes!==undefined&&itemTemp.notes!==null){
			setNoteValue(itemTemp.notes);
		}
	};
	const hideModal = () => {
		noteForm.resetFields();
		setnotesVisible(false);
	};
	const [count, setCount] = useState(0);
	const zbminus = () => {
		if (count < 2) {
			setCount(count + 1);
			if (count === 0) {
				document.body.classList.add('decreaseFont');
			}
			if (count === 1) {
				document.body.classList.add('decreaseFontsmall');
				document.body.classList.remove('decreaseFont');
			}
		}	
	};
	useEffect(() => {
	}, [count]);
	const zb = () => {
		if (count >=0 ) {
			setCount(count - 1);
			if (count === 2) {
				document.body.classList.remove('decreaseFontsmall');
				document.body.classList.add('decreaseFont');
			}
			if (count === 1) {
				document.body.classList.remove('decreaseFont');
			}
		}
	};
	//To set note form
	const setNoteValue=(valueText)=>{
		noteForm.setFieldsValue({ note:valueText });
	}; 
		//notes modal
	const onStart = (event, uiData) => {
		const { clientWidth, clientHeight } = window?.document?.documentElement;
		const targetRect = draggleRef?.current?.getBoundingClientRect();
		let boundsForNotesModal={
			left: -targetRect?.left + uiData?.x,
			right: clientWidth - (targetRect?.right - uiData?.x),
			top: -targetRect?.top + uiData?.y,
			bottom: clientHeight - (targetRect?.bottom - uiData?.y),
		};
		setBounds(boundsForNotesModal);
	};
	useEffect(() => {
		if(document.body.classList.contains('decreaseFont')){
			document.body.classList.remove('decreaseFont');
		}
		if(document.body.classList.contains('decreaseFontsmall')){
			document.body.classList.remove('decreaseFontsmall');
		}
		if(props&&props.location&&props.location.state&&!props.location.state.questionOfDayDetails){
			sessionStorage.setItem("quitTest","false");
			sessionStorage.setItem("isFullLengthTestvalue","false");
			setDirty();
		}
		$(document).keydown(function(event){
			if(event.keyCode===123||event.ctrlKey&&event.shiftKey&&event.keyCode===73||event.ctrlKey&&event.keyCode===67){
				return false;
			}
		});
		document.addEventListener('copy',disableCopy);
		document.addEventListener('contextmenu',disableContextMenu);
		let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
			fetchFromLocalStorage();
		}else{
			if(props&&props.location&&props.location.state&&props.location.state.categoryDetails){
				sessionStorage.setItem("categoryDetails", JSON.stringify(props.location.state.categoryDetails));
				fetchStartTestData();
			}else if(props&&props.location&&props.location.state&&props.location.state.topicDetails){
				sessionStorage.setItem("topicDetails", JSON.stringify(props.location.state.topicDetails));
				fetchStartTestData();
			}else if(props&&props.location&&props.location.state&&props.location.state.customDetails){
				sessionStorage.setItem("customDetails", JSON.stringify(props.location.state.customDetails));
				fetchStartTestData();
			}else if(props&&props.location&&props.location.state&&props.location.state.questionOfDayDetails){
				sessionStorage.setItem("questionOfDayDetails",JSON.stringify(props.location.state.questionOfDayDetails));
				fetchStartTestData();
			}else if(props&&props.location&&props.location.state&&props.location.state.markForLaterAndAnswerIncorrectDetails){
				sessionStorage.setItem("markForLaterAndAnswerIncorrectDetails",JSON.stringify(props.location.state.markForLaterAndAnswerIncorrectDetails));
				fetchStartTestData();
			}
			else{
				let queryString = window.location.href;
				var routeValue = queryString.substring(queryString.lastIndexOf('/') + 1);
				if(routeValue==="question-of-the-day"){
					history.push("/home");
				}else if(routeValue==="category-tutorial"){
					history.push("/select-category-tutorial");
				}else if(routeValue==="topic-tutorial"){
					history.push("/select-topic-tutorial");
				}else if(routeValue==="custom-tutorial"){
					history.push("/select-custom-tutorial");
				}else{
					history.push("/select-tutorial");
				}
			}
		}
	}, []);
	useEffect(() => {
		return () => {
			$(document).off('keydown');
			document.removeEventListener('contextmenu',disableContextMenu);
			document.removeEventListener('copy',disableCopy);
			let testCount=sessionStorage.getItem("totalTestsTaken");
			let viewedQod=sessionStorage.getItem("viewedQOD");
			let crashTest= sessionStorage.getItem("crash");
			let feedbackpopupshow=sessionStorage.getItem("feedbackShown");
			sessionStorage.clear();
			sessionStorage.setItem("crash",crashTest);
			sessionStorage.setItem("totalTestsTaken",testCount);
			sessionStorage.setItem("viewedQOD",viewedQod);
			sessionStorage.setItem("feedbackShown",feedbackpopupshow);
		};
	}, []);
	function disableCopy(e) {
		e.preventDefault();
	}
	function disableContextMenu(e) {
		e.preventDefault();
	}
	// arrange the sequence
	const onSortEnd = ({ oldIndex, newIndex }) => {
		setSortItems(arrayMove(sortitems, oldIndex, newIndex));
		setRefresh(!refresh);
	};
	useEffect(() => {
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="4"){
			listQuestions[questionIndex].selectedAnswer=sortitems;
			let answer=[];
			for (let index = 0; index < listQuestions[questionIndex].selectedAnswer.length; index++) {
				answer.push(listQuestions[questionIndex].selectedAnswer[index].index);
			}
			listQuestions[questionIndex].matchAns = (answer.length == listQuestions[questionIndex].correct_option.length) && answer.every(function(element, index) {
				return element === listQuestions[questionIndex].correct_option[index]; 
			});
			let user_answer_converted=[];
			for (let j = 0; j < listQuestions[questionIndex].selectedAnswer.length; j++) {
				user_answer_converted.push(constants.OptionstoDisplay[listQuestions[questionIndex].selectedAnswer[j].index]);
			}
			listQuestions[questionIndex].user_answer_converted=user_answer_converted;
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
		}
	}, [sortitems]);
	//end of arrange the sequence code
	//To fetch the Questions to answer the test of the category type.
	const fetchStartTestData=()=>{
		setCategoryDetails(JSON.parse(sessionStorage.getItem("categoryDetails")));
		setTopicDetails(JSON.parse(sessionStorage.getItem("topicDetails")));
		setCustomDetails(JSON.parse(sessionStorage.getItem("customDetails")));
		setQuestionOfDayDetails(JSON.parse(sessionStorage.getItem("questionOfDayDetails")));
		setMarkForLaterDetails(JSON.parse(sessionStorage.getItem("markForLaterAndAnswerIncorrectDetails")));
		setNextAndPreviousLoading(true);
		let userId = getCookie(COOKIE.UserId);
		let payload = {};
		let categoryDetailsFromProps=props.location.state.categoryDetails;
		let topicDetailsFromProps=props.location.state.topicDetails;
		let customDetailsFromProps=props.location.state.customDetails;
		let questionOfDayDetailsFromProps=props.location.state.questionOfDayDetails;
		let markForLaterAndAnswerIncorrectDetailsFromProps=props.location.state.markForLaterAndAnswerIncorrectDetails;
		let url=``;
		let apiMethod="";
		if(categoryDetailsFromProps&&categoryDetailsFromProps.type==="tutor"){
			payload.stud_id=userId;
			payload.type=categoryDetailsFromProps.type;
			payload.cat_id=categoryDetailsFromProps.catId;
			payload.cat_type=categoryDetailsFromProps.catType;
			payload.attempt=categoryDetailsFromProps.attempt;
			payload.count=categoryDetailsFromProps.count;
			payload.is_timed=categoryDetailsFromProps.isTimed;
			url=`/student/start-test`;
			apiMethod="post";
		}else if(topicDetailsFromProps&&topicDetailsFromProps.type==="tutor"){
			payload.stud_id=userId;
			payload.type=topicDetailsFromProps.type;
			payload.topic_id=Number(topicDetailsFromProps.topicId);
			payload.count=topicDetailsFromProps.count;
			payload.attempt=1;
			payload.is_timed=topicDetailsFromProps.isTimed;
			url=`/student/start-test`;
			apiMethod="post";
		}else if(customDetailsFromProps&&customDetailsFromProps.type==="tutor"){
			payload.stud_id=userId;
			payload.type=customDetailsFromProps.type;
			payload.data=customDetailsFromProps.data;
			payload.attempt=customDetailsFromProps.attempt;
			payload.is_timed=customDetailsFromProps.isTimed;
			url=`/student/start-test`;
			apiMethod="post";
		}else if(questionOfDayDetailsFromProps&&questionOfDayDetailsFromProps.type==="qod"){
			url=`/qod/${questionOfDayDetailsFromProps.userId}`;
			apiMethod="get";
		}else if(markForLaterAndAnswerIncorrectDetailsFromProps&&markForLaterAndAnswerIncorrectDetailsFromProps.isMarkedLater===true||markForLaterAndAnswerIncorrectDetailsFromProps&&markForLaterAndAnswerIncorrectDetailsFromProps.isAnsIncorrect===true){
			payload.stud_id=markForLaterAndAnswerIncorrectDetailsFromProps.stud_id;
			payload.type=markForLaterAndAnswerIncorrectDetailsFromProps.type;
			payload.attempt=markForLaterAndAnswerIncorrectDetailsFromProps.attempt;
			payload.is_timed=markForLaterAndAnswerIncorrectDetailsFromProps.isTimed;
			if(markForLaterAndAnswerIncorrectDetailsFromProps&&markForLaterAndAnswerIncorrectDetailsFromProps.isMarkedLater===true){
				payload.is_marked_later=markForLaterAndAnswerIncorrectDetailsFromProps.isMarkedLater;
			}else{
				payload.is_ans_incorrect=markForLaterAndAnswerIncorrectDetailsFromProps.isAnsIncorrect;
			}
			url=`/student/start-tutorials`;
			apiMethod="post";
		}
		fetchApi(url,apiMethod, payload).then((res) => {
			setNextAndPreviousLoading(false);
			if (res && res.code && res.code === 200) {
				setServerTime(res.current_date?res.current_date:"");
				let browerDateTime = new Date();
				setbrowserTime(browerDateTime.toString());
				if(res.data&&res.data.questions&&res.data.questions.length>0){
					setQODmessage(res.data.questions[0].qod_message?res.data.questions[0].qod_message:"");
					setQODQuestionAnswwered(res.data.questions[0].qod?res.data.questions[0].qod:"2");
					for (let i = 0; i < res.data.questions.length; i++) {
						res.data.questions[i].selectedAnswer=[];
						res.data.questions[i].ratings=null;
						res.data.questions[i].matchAns=false;
						let matchAns=true;
						if(questionOfDayDetailsFromProps&&questionOfDayDetailsFromProps.type==="qod"){
							if(res.data.questions[i].is_answered===1){
								res.data.questions[i].ratings=res.data.questions[i].user_rating;
								res.data.questions[i].checkAnswer="true";
								res.data.questions[i].selectedAnswer=res.data.questions[i].user_ans;
								for (let j = 0; j < res.data.questions[i].correct_option.length; j++) {
									let split=res.data.questions[i].correct_option[j].split("/");
									if(res.data.questions[i].user_ans&&res.data.questions[i].user_ans.length>0){
										if (typeof res.data.questions[i].user_ans[j]!== undefined) {
											if(!split.includes(res.data.questions[i].user_ans[j].toString())){
												matchAns=false;
												break;
											}
										}
									}else{
										matchAns=false;
									}
								}
								res.data.questions[i].matchAns = matchAns;
								let user_answer_converted=[];
								for (let j = 0; j < res.data.questions[i].user_ans.length; j++) {
									user_answer_converted.push(constants.OptionstoDisplay[res.data.questions[i].user_ans[j]]);
								}
								//Condition to display the STRIKED options
								if(res.data.questions[i].strike&&res.data.questions[i].strike.length>0){
									let optionNumver={};
									if(res.data.questions[i].q_type==="5"){
										optionNumver=constants.OptionNumberDragAndDrop;
									}else{
										optionNumver=constants.OptionNumbers;
									}
									for (let l = 0; l < res.data.questions[i].strike.length; l++) {
										let op=optionNumver[res.data.questions[i].strike[l]];
										res.data.questions[i][op]=`<strike>${res.data.questions[i][op]}</strike>`;
									}
								}
								res.data.questions[i].user_answer_converted=user_answer_converted;
								//Condition to display the HIGHLIGHTED text for options
								if(res.data.questions[i].highlight&&res.data.questions[i].highlight.length>0){
									let optionNumver={};
									if(res.data.questions[i].q_type==="5"){
										optionNumver=constants.OptionNumberDragAndDrop;
									}else{
										optionNumver=constants.OptionNumbers;
									}
									for (let l = 0; l < res.data.questions[i].highlight.length; l++) {
										let op=optionNumver[res.data.questions[i].highlight[l].opt];
										res.data.questions[i][op]=res.data.questions[i].highlight[l].data;
									}
								}
							}else{
								res.data.questions[i].checkAnswer="false";
								res.data.questions[i].matchAns = false;
							}
						}else{
							res.data.questions[i].checkAnswer="false";
						}
						let correct_option=[];
						let correctOptionConverted=[];
						if(res.data.questions[i].q_type==="5"){
							if(res.data.questions[i].option_first){
								res.data.questions[i].option_one=res.data.questions[i].option_first.substring(res.data.questions[i].option_first.indexOf("|")+1);
							}
							if(res.data.questions[i].option_second){
								res.data.questions[i].option_two=res.data.questions[i].option_second.substring(res.data.questions[i].option_second.indexOf("|")+1); 
							}
							if(res.data.questions[i].option_third){
								res.data.questions[i].option_three=res.data.questions[i].option_third.substring(res.data.questions[i].option_third.indexOf("|")+1);
							}
							if(res.data.questions[i].option_fourth){
								res.data.questions[i].option_four=res.data.questions[i].option_fourth.substring(res.data.questions[i].option_fourth.indexOf("|")+1);
							}
							if(res.data.questions[i].option_fifth){
								res.data.questions[i].option_five=res.data.questions[i].option_fifth.substring(res.data.questions[i].option_fifth.indexOf("|")+1); 
							}
							if(res.data.questions[i].option_sixth){
								res.data.questions[i].option_six=res.data.questions[i].option_sixth.substring(res.data.questions[i].option_sixth.indexOf("|")+1); 
							}
							if(res.data.questions[i].option_seventh){
								res.data.questions[i].option_seven=res.data.questions[i].option_seventh.substring(res.data.questions[i].option_seventh.indexOf("|")+1); 
							}
							if(res.data.questions[i].option_eighth){
								res.data.questions[i].option_eight=res.data.questions[i].option_eighth.substring(res.data.questions[i].option_eighth.indexOf("|")+1);
							}
							correct_option=res.data.questions[i].correct_option;
							for (let j = 0; j < res.data.questions[i].correct_option.length; j++) {
								let splitted=res.data.questions[i].correct_option[j].split("");
								let convertedToAlpha=[];
								for (let k = 0; k < splitted.length; k++) {
									convertedToAlpha.push(constants.OptionstoDisplay[splitted[k]]);
								}
								correctOptionConverted.push(convertedToAlpha.toString().replaceAll(",",""));
							}
						}else{
							for (let j = 0; j < res.data.questions[i].correct_option.length; j++) {
								let converted=Number(res.data.questions[i].correct_option[j]);
								correct_option.push(converted);
								correctOptionConverted.push(constants.OptionstoDisplay[converted]);
							}
						}
						res.data.questions[i].correct_option=correct_option;
						res.data.questions[i].correct_option_converted=correctOptionConverted;
					}
					if(res.data&&res.data.scorecard_id){
						sessionStorage.setItem('scoreCardId',res.data.scorecard_id);
						setScoreCardId(res.data.scorecard_id);
					}
					sessionStorage.setItem("listQuestions", JSON.stringify(res.data.questions));
					sessionStorage.setItem("questionIndex",0);
					sessionStorage.setItem("testType","tutorial");
					let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
					setQuestions(listQuestionsFromSession);
					checkQuestionType(res.data.questions);
					if(questionOfDayDetailsFromProps&&questionOfDayDetailsFromProps.type==="qod"){
						sessionStorage.setItem("testType","qod");
					}else if(markForLaterAndAnswerIncorrectDetailsFromProps&&markForLaterAndAnswerIncorrectDetailsFromProps.isMarkedLater===true||markForLaterAndAnswerIncorrectDetailsFromProps&&markForLaterAndAnswerIncorrectDetailsFromProps.isAnsIncorrect===true){
						sessionStorage.setItem("testType","review");
					}else{
						sessionStorage.setItem("testType","tutorial");
					}
				}
			} else {
				message.error(res.message);
				setPristine();
				sessionStorage.setItem("quitTest","true");
				if(categoryDetailsFromProps&&categoryDetailsFromProps.type==="tutor"){
					history.push("/select-category-tutorial");
				}else if(topicDetailsFromProps&&topicDetailsFromProps.type==="tutor"){
					history.push("/select-topic-tutorial");
				}else if(customDetailsFromProps&&customDetailsFromProps.type==="tutor"){
					history.push("/select-custom-tutorial");
				}else if(questionOfDayDetailsFromProps&&questionOfDayDetailsFromProps.type==="qod"){
					history.push("/home");
				}else if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
					history.push("/select-tutorial");
				}
			}
		});
	};
	const fetchFromLocalStorage=()=>{
		let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		let questionIndexFromSession=sessionStorage.getItem("questionIndex");
		let categoryDetailsFromSession=JSON.parse(sessionStorage.getItem("categoryDetails")||"null");
		let topicDetailsFromSession=JSON.parse(sessionStorage.getItem("topicDetails")||"null");
		let customDetailsFromSession=JSON.parse(sessionStorage.getItem("customDetails")||"null");
		let questionOfDayDetailsFromSession=JSON.parse(sessionStorage.getItem("questionOfDayDetails")||"null");
		let markForLaterAndAnswerIncorrectDetailsFromSession =JSON.parse(sessionStorage.getItem("markForLaterAndAnswerIncorrectDetails")||"null");
		if(categoryDetailsFromSession&&categoryDetailsFromSession.type==="tutor"){
			if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
				setCategoryDetails(categoryDetailsFromSession);
			}else{
				sessionStorage.setItem("quitTest","true");
				setPristine();
				history.push("/select-category-tutorial");
			}
		}else if(topicDetailsFromSession&&topicDetailsFromSession.type==="tutor"){
			if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
				setTopicDetails(topicDetailsFromSession);
			}else{
				sessionStorage.setItem("quitTest","true");
				setPristine();
				history.push("/select-topic-tutorial");
			}
		}else if(customDetailsFromSession&&customDetailsFromSession.type==="tutor"){
			if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
				setCustomDetails(customDetailsFromSession);
			}else{
				sessionStorage.setItem("quitTest","true");
				setPristine();
				history.push("/select-custom-tutorial");
			}
		}else if(questionOfDayDetailsFromSession&&questionOfDayDetailsFromSession.type==="qod"){
			if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
				setQODmessage(listQuestionsFromSession[0].qod_message?listQuestionsFromSession[0].qod_message:"");
				setQODQuestionAnswwered(listQuestionsFromSession[0].qod?listQuestionsFromSession[0].qod:"2");
				setQuestionOfDayDetails(questionOfDayDetailsFromSession);
			}else{
				sessionStorage.setItem("quitTest","true");
				setPristine();
				history.push("/home");
			}
		}else if(markForLaterAndAnswerIncorrectDetailsFromSession&&markForLaterAndAnswerIncorrectDetailsFromSession.isMarkedLater===true||markForLaterAndAnswerIncorrectDetailsFromSession&&markForLaterAndAnswerIncorrectDetailsFromSession.isAnsIncorrect===true){
			if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
				setMarkForLaterDetails(markForLaterAndAnswerIncorrectDetailsFromSession);
			}else{
				sessionStorage.setItem("quitTest","true");
				setPristine();
				history.push("/select-tutorial");
			}
		}else{
			history.push("/home");
		}
		setQuestions(listQuestionsFromSession);
		setScoreCardId(Number(sessionStorage.getItem("scoreCardId")));
		setQuestionIndex(Number(questionIndexFromSession));
		checkQuestionType(listQuestionsFromSession);
	};
	//Function to check if question type is arrange sequence or drag and drop to display options on page load
	const checkQuestionType=(listOfQuestions)=>{
		let questionIndexFromSession=Number(sessionStorage.getItem("questionIndex"));
		if(listOfQuestions&&listOfQuestions.length>0&&listOfQuestions[questionIndex].q_type==="4"){
			let sortItems=[];
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first){
				sortItems.push({
					index:1,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second){
				sortItems.push({
					index:2,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third){
				sortItems.push({
					index:3,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth){
				sortItems.push({
					index:4,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth){
				sortItems.push({
					index:5,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth){
				sortItems.push({
					index:6,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh){
				sortItems.push({
					index:7,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth){
				sortItems.push({
					index:8,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth 
				});
			}
			setSortItems(sortItems);
		}
		else if(listOfQuestions&&listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].q_type==="5"){
			let Items=[];
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first){
				Items.push({
					id:`${1}`,
					content:`${listOfQuestions[questionIndexFromSession].option_one }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second){
				Items.push({
					id:`${2}`,
					content:`${listOfQuestions[questionIndexFromSession].option_two }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third){
				Items.push({
					id:`${3}`,
					content:`${listOfQuestions[questionIndexFromSession].option_three }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth){
				Items.push({
					id:`${4}`,
					content:`${listOfQuestions[questionIndexFromSession].option_four }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth){
				Items.push({
					id:`${5}`,
					content:`${listOfQuestions[questionIndexFromSession].option_five}`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth){
				Items.push({
					id:`${6}`,
					content:`${listOfQuestions[questionIndexFromSession].option_six}`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh){
				Items.push({
					id:`${7}`,
					content:`${listOfQuestions[questionIndexFromSession].option_seven }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth){
				Items.push({
					id:`${8}`,
					content:`${listOfQuestions[questionIndexFromSession].option_eight}`
				});
			}
			let columnsObject={
				[1]: { items: [] },
				[2]: { items: [] },
				[3]: { items: [] },
				[4]: { items: [] },
				[5]: { items: [] },
				[6]: { items: [] },
				[7]: { items: [] },
				[8]: { items: [] },
				[9]: { items: [] },
			};
			columnsObject[1].items=Items;
			setColumns(columnsObject);
			setRefresh(!refresh);
		}
	};
	//Executes on click of Next button.
	const handleNextQuestion=(btnClickType)=>{
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
			if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length){
				message.error(constants.SelectOptionDragAndDropTutorials);
			}else{
				let totalOptions=null;
				let optionNumver={};
				if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"||listQuestions[questionIndex].q_type==="4"){
					totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt):0;
					optionNumver=constants.OptionNumbers;
				}else if(listQuestions[questionIndex].q_type==="5"){
					optionNumver=constants.OptionNumberDragAndDrop;
					totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt.charAt(2)):0;
				}
				var match=false;
				for (let index = 0; index < totalOptions; index++) {
					if(!listQuestions[questionIndex][optionNumver[index+1]].includes(`<strike>`)){
						match=true;
						break;
					}
				}
				if(match===true){
					let payload = {};
					var today = new Date();
					var dd = String(today.getDate()).padStart(2, '0');
					var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
					var yyyy = today.getFullYear();
					today = yyyy + '-'+ mm + '-' + dd;
					if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"){
						var sequence=questionIndex+1;
						payload.test_data={
							seq:sequence,
							q_id:Number(listQuestions[questionIndex].question_id),
							user_ans:listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0?listQuestions[questionIndex].selectedAnswer:[],
							corr_ans:listQuestions[questionIndex].correct_option,
							// answered_on:today,
						};
					}else if(listQuestions[questionIndex].q_type==="4"){
						let answer=[];
						for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
							answer.push(listQuestions[questionIndex].selectedAnswer[i].index);
						}
						var seq=questionIndex+1;
						payload.test_data={
							seq:seq,
							q_id:Number(listQuestions[questionIndex].question_id),
							user_ans:answer&&answer.length>0?answer:[],
							corr_ans:listQuestions[questionIndex].correct_option,
							// answered_on:today,
						};
					}else if(listQuestions[questionIndex].q_type==="5"){
						let answer=[];
						for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
							answer.push(Number(listQuestions[questionIndex].selectedAnswer[i].id));
						}
						var sequ=questionIndex+1;
						payload.test_data={
							seq:sequ,
							q_id:Number(listQuestions[questionIndex].question_id),
							user_ans:answer&&answer.length>0?answer:[],
							corr_ans:listQuestions[questionIndex].correct_option,
							// answered_on:today,
						};
					}
					if(listQuestions[questionIndex].ratings){
						if(!listQuestions[questionIndex].user_rating){
							payload.test_rating_data={
								question_id:Number(listQuestions[questionIndex].question_id),
								rating:listQuestions[questionIndex].ratings
							};
						}
					}
					payload.test_status=0;
					let strikedOptions=[];
					let highlightedOptions=[];
					for (let j = 0; j < totalOptions; j++) {
						let op=optionNumver[j+1];
						if(listQuestions[questionIndex][op].includes("<strike>")){
							strikedOptions.push(j+1);
						}
						if(listQuestions[questionIndex][op].includes("highlighted")){
							highlightedOptions.push({ 
								opt:j+1,
								data:listQuestions[questionIndex][op] 
							});
						}
					}
					if(listQuestions[questionIndex].question_text&&listQuestions[questionIndex].question_text.includes("highlighted")){
						highlightedOptions.push({
							opt:99,
							data:listQuestions[questionIndex].question_text,
						});
					}
					if(strikedOptions&&strikedOptions.length>0||highlightedOptions&&highlightedOptions.length>0){
						payload.highlight_strike_data={
							q_id:Number(listQuestions[questionIndex].question_id),
							strike:strikedOptions,
							highlight:highlightedOptions&&highlightedOptions.length>0?highlightedOptions:[],
						};
					}
					let url=``;
					if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
						url+=`/student/tutorials/update`;
						payload.scorecard_id=scoreCardId;
						payload.question_id=Number(listQuestions[questionIndex].question_id);
						if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
							if(listQuestions[questionIndex].matchAns===true){
								payload.type="ans_incorrect";
								payload.is_answered=true;
								nextQuestionApi(payload,url,btnClickType);
							}else{
								payload.type="ans_incorrect";
								payload.is_answered=false;
								nextQuestionApi(payload,url,btnClickType);
							}
						}else{
							payload.type="marked_for_later";
							payload.is_answered=true;
							nextQuestionApi(payload,url,btnClickType);
						}
					}
					else{
						url+=`/score_card/${scoreCardId}`;
						nextQuestionApi(payload,url,btnClickType);
					}
				}else{
					message.error(constants.SelectOptionOnly);
				}
			}
		}else{
			message.error(constants.SelectOptionOnly);
		}
	};
	const nextQuestionApi=(payload,url,btnClickType)=>{
		setNextAndPreviousLoading(true);
		fetchApi(url, "put", payload)
			.then((res) => {
				setServerTime(res.current_date?res.current_date:"");
				let browerDateTime = new Date();
				setbrowserTime(browerDateTime.toString());
				setNextAndPreviousLoading(false);
				if (res && res.code && res.code === 200) {
					setColumns(constants.columnsOfDragAndDrop);
					setNextAndPreviousLoading(false);
					if(btnClickType==="PREVIOUS"){
						sessionStorage.setItem("questionIndex",questionIndex-1);
						setQuestionIndex(questionIndex-1);
					}else{
						sessionStorage.setItem("questionIndex",questionIndex+1);
						setQuestionIndex(questionIndex+1);
					}
				} else {
					setNextAndPreviousLoading(false);
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setNextAndPreviousLoading(false);
					if(res.error_message==="Invalid token"){
						message.error(constants.LoggedInAnotherBrowser);
					}else{
						if(res.code===600){
							message.error(res.message);
						}else{
							message.error("Failed");
						}
					}
				}
			});
	};
	//Executes on click of Previous button.
	// const handlePreviousQuestion=()=>{
	// 	setNextAndPreviousLoading(true);
	// 	setColumns(constants.columnsOfDragAndDrop);
	// 	sessionStorage.setItem("questionIndex",questionIndex-1);
	// 	setQuestionIndex(questionIndex-1);
	// 	setNextAndPreviousLoading(false);
	// };
	const handlePreviousQuestion=()=>{
		if(listQuestions&&listQuestions.length>0){
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0||listQuestions[questionIndex].markedForLater===true){
				if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length&&listQuestions[questionIndex].markedForLater===false){
					setNextAndPreviousLoading(true);
					setColumns(constants.columnsOfDragAndDrop);
					sessionStorage.setItem("questionIndex",questionIndex-1);
					setQuestionIndex(questionIndex-1);
					setNextAndPreviousLoading(false);
				}else{
					handleNextQuestion("PREVIOUS");
				}
			}else{
				setNextAndPreviousLoading(true);
				setColumns(constants.columnsOfDragAndDrop);
				sessionStorage.setItem("questionIndex",questionIndex-1);
				setQuestionIndex(questionIndex-1);
				setNextAndPreviousLoading(false);
			}
		}
	};
	//Function executes on click of Return to tutorial button
	const completeTestApi=()=>{
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
			let totalOptions=null;
			let optionNumver={};
			if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"||listQuestions[questionIndex].q_type==="4"){
				totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt):0;
				optionNumver=constants.OptionNumbers;
			}else if(listQuestions[questionIndex].q_type==="5"){
				optionNumver=constants.OptionNumberDragAndDrop;
				totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt.charAt(2)):0;
			}
			var match=false;
			for (let index = 0; index < totalOptions; index++) {
				if(!listQuestions[questionIndex][optionNumver[index+1]].includes(`<strike>`)){
					match=true;
					break;
				}
			}
			if(match===true){
				let payload = {};
				var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();
				today = yyyy + '-'+ mm + '-' + dd;
				if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"){
					var sequence=questionIndex+1;
					payload.test_data={
						seq:sequence,
						q_id:Number(listQuestions[questionIndex].question_id),
						user_ans:listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0?listQuestions[questionIndex].selectedAnswer:[],
						corr_ans:listQuestions[questionIndex].correct_option,
						// answered_on:today,
					};
				}else if(listQuestions[questionIndex].q_type==="4"){
					let answer=[];
					for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
						answer.push(listQuestions[questionIndex].selectedAnswer[i].index);
					}
					var seq=questionIndex+1;
					payload.test_data={
						seq:seq,
						q_id:Number(listQuestions[questionIndex].question_id),
						user_ans:answer&&answer.length>0?answer:[],
						corr_ans:listQuestions[questionIndex].correct_option,
						// answered_on:today,
					};
				}else if(listQuestions[questionIndex].q_type==="5"){
					let answer=[];
					for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
						answer.push(Number(listQuestions[questionIndex].selectedAnswer[i].id));
					}
					var sequ=questionIndex+1;
					payload.test_data={
						seq:sequ,
						q_id:Number(listQuestions[questionIndex].question_id),
						user_ans:answer&&answer.length>0?answer:[],
						corr_ans:listQuestions[questionIndex].correct_option,
						// answered_on:today,
					};
				}
				if(listQuestions[questionIndex].ratings){
					if(!listQuestions[questionIndex].user_rating){
						payload.test_rating_data={
							question_id:Number(listQuestions[questionIndex].question_id),
							rating:listQuestions[questionIndex].ratings
						};
					}
				}
				let strikedOptions=[];
				let highlightedOptions=[];
				for (let j = 0; j < totalOptions; j++) {
					let op=optionNumver[j+1];
					if(listQuestions[questionIndex][op].includes("<strike>")){
						strikedOptions.push(j+1);
					}
					if(listQuestions[questionIndex][op].includes("highlighted")){
						highlightedOptions.push({ 
							opt:j+1,
							data:listQuestions[questionIndex][op] 
						});
					}
				}
				if(listQuestions[questionIndex].question_text&&listQuestions[questionIndex].question_text.includes("highlighted")){
					highlightedOptions.push({
						opt:99,
						data:listQuestions[questionIndex].question_text,
					});
				}
				if(strikedOptions&&strikedOptions.length>0||highlightedOptions&&highlightedOptions.length>0){
					payload.highlight_strike_data={
						q_id:Number(listQuestions[questionIndex].question_id),
						strike:strikedOptions,
						highlight:highlightedOptions&&highlightedOptions.length>0?highlightedOptions:[],
					};
				}
				payload.test_status=1;
				const date= new Date();
				payload.dt_completed=date;
				let url=``;
				if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
					url+=`/student/tutorials/update`;
					payload.scorecard_id=scoreCardId;
					payload.question_id=Number(listQuestions[questionIndex].question_id);
					if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
						if(listQuestions[questionIndex].matchAns===true){
							payload.type="ans_incorrect";
							payload.is_answered=true;
							submitTutorialApi(payload,url);
						}else{
							payload.type="ans_incorrect";
							payload.is_answered=false;
							submitTutorialApi(payload,url);
						}
					}else{
						payload.type="marked_for_later";
						payload.is_answered=true;
						submitTutorialApi(payload,url);
					}
				}else{
					url+=`/score_card/${scoreCardId}`;
					submitTutorialApi(payload,url);
				}
			}else{
				message.error(constants.SelectOption);
			}
		}else{
			message.error(constants.SelectOption);
		}
	};
	const submitTutorialApi=(payload,url)=>{
		setCompleteTestLoading(true);
		fetchApi(url, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					setServerTime(res.current_date?res.current_date:"");
					let browerDateTime = new Date();
					setbrowserTime(browerDateTime.toString());
					setCompleteTestLoading(false);
					setColumns(constants.columnsOfDragAndDrop);
					if(categoryDetails&&categoryDetails.type==="tutor"){
						sessionStorage.setItem("quitTest","true");
						setPristine();
						history.push("/select-category-tutorial");
					}else if(topicDetails&&topicDetails.type==="tutor"){
						sessionStorage.setItem("quitTest","true");
						setPristine();
						history.push("/select-topic-tutorial");
					}else if(customDetails&&customDetails.type==="tutor"){
						sessionStorage.setItem("quitTest","true");
						setPristine();
						history.push("/select-custom-tutorial");
					}else if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
						sessionStorage.setItem("quitTest","true");
						setPristine();
						history.push("/select-tutorial");
					}
				} else {
					setCompleteTestLoading(false);
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setNextAndPreviousLoading(false);
					if(res.error_message==="Invalid token"){
						message.error(constants.LoggedInAnotherBrowser);
					}else{
						if(res.code===600){
							message.error(res.message);
						}else{
							message.error("Failed");
						}
					}
				}
				setCompleteTestLoading(false);
			});
	};
	//Function to quit the Full length test and doesn't reduce the attempt
	const quitTestAnswer=()=>{
		sessionStorage.setItem("quitTest","true");
		setPristine();
		setQuitTestLoading(true);
		let payload = {};
		payload.scorecard_id=scoreCardId;
		fetchApi(`/student/quit-test`, "post", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					setQuitTestLoading(false);
					setPristine();
					if(categoryDetails&&categoryDetails.type==="tutor"){
						history.push("/select-category-tutorial");
					}else if(topicDetails&&topicDetails.type==="tutor"){
						history.push("/select-topic-tutorial");
					}else if(customDetails&&customDetails.type==="tutor"){
						history.push("/select-custom-tutorial");
					}else if(markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true){
						history.push("/select-tutorial");
					}
				} else {
					setQuitTestLoading(false);
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setNextAndPreviousLoading(false);
					if(res.error_message==="Invalid token"){
						message.error(constants.LoggedInAnotherBrowser);
					}else{
						if(res.code===600){
							message.error(res.message);
						}else{
							message.error("Failed");
						}
					}
				}
				setQuitTestLoading(false);
			});
	};
	//Executes on state change of questionIndex state.
	useEffect(() => {
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="4"){
			let sortItems=[];
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
				sortItems=listQuestions[questionIndex].selectedAnswer;
			}else{
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
					sortItems.push({
						index:1,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
					sortItems.push({
						index:2,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
					sortItems.push({
						index:3,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
					sortItems.push({
						index:4,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
					sortItems.push({
						index:5,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
					sortItems.push({
						index:6,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
					sortItems.push({
						index:7,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
					sortItems.push({
						index:8,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth 
					});
				}
			}
			setSortItems(sortItems);
		}else if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="5"){
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
				// setSelected(listQuestions[questionIndex].selectedAnswer);
				// setItems(listQuestions[questionIndex].selectedOption);
				setColumns(listQuestions[questionIndex].columns);
			}else{
				let Items=[];
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
					Items.push({
						id:`${1}`,
						content:`${listQuestions[questionIndex].option_one }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
					Items.push({
						id:`${2}`,
						content:`${listQuestions[questionIndex].option_two }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
					Items.push({
						id:`${3}`,
						content:`${listQuestions[questionIndex].option_three }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
					Items.push({
						id:`${4}`,
						content:`${listQuestions[questionIndex].option_four }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
					Items.push({
						id:`${5}`,
						content:`${listQuestions[questionIndex].option_five}`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
					Items.push({
						id:`${6}`,
						content:`${listQuestions[questionIndex].option_six}`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
					Items.push({
						id:`${7}`,
						content:`${listQuestions[questionIndex].option_seven }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
					Items.push({
						id:`${8}`,
						content:`${listQuestions[questionIndex].option_eight}`
					});
				}
				let columnsObject={
					[1]: { items: [] },
					[2]: { items: [] },
					[3]: { items: [] },
					[4]: { items: [] },
					[5]: { items: [] },
					[6]: { items: [] },
					[7]: { items: [] },
					[8]: { items: [] },
					[9]: { items: [] },
				};
				columnsObject[1].items=Items;
				setColumns(columnsObject);
				setRefresh(!refresh);
			}
		}
	}, [questionIndex]);
	//drag and drop
	// a CSS styling function to help us with reordering the result
	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		//userSelect: "none",
		width: "calc(50% - 12px)",
		minWidth: '300px',
		marginRight: "12px",
		marginBottom: "15px",
		alignItems: "center",
		flexWrap: "wrap",
		// change background colour if dragging
		background: "white",
		display: "inline-flex",
		border: isDragging ? "none" : "1px solid lightgrey",
		borderImage: "none",
		borderRadius: "30px",		
		position: 'relative',
		overflow: "hidden",
		// styles we need to apply on draggables
		...draggableStyle,
	});
	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightgrey" : "white",
		padding: "grid 0",
	});
	const onDragEnd = (result, columnsObject, setColumns) => {
		if (!result.destination) return;
		const { source, destination } = result;
		if (source.droppableId !== destination.droppableId) {
			if(columnsObject[Number(result.destination.droppableId)].items.length===1){
				columnsObject[1].items.push(columnsObject[Number(result.destination.droppableId)].items[0]);
				columnsObject[Number(result.destination.droppableId)].items.splice(0,1);
			}
			const sourceColumn = columnsObject[source.droppableId];
			const destColumn = columnsObject[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			for (let index = 0; index < sourceItems.length; index++) {
				if(sourceItems[index]===null||sourceItems[index]===undefined){
					sourceItems.splice(index,1);
				}
			}
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);
			setColumns({
				...columnsObject,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems
				}
			});
			setRefresh(!refresh);
			let selectedOption={
				...columnsObject,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems
				}
			};
			let selectedAnswer=[];
			Object.entries(selectedOption).forEach(([key, value]) => {
				if(key>1){
					if(value.items&&value.items.length>0){
						for (let j = 0; j < value.items.length; j++) {
							selectedAnswer.push(value.items[j]);
						}
					}
				}
			});
			listQuestions[questionIndex].selectedAnswer=selectedAnswer;
			listQuestions[questionIndex].columns=selectedOption;
			let matchAns=true;
			for (let j = 0; j < listQuestions[questionIndex].correct_option.length; j++) {
				let split=listQuestions[questionIndex].correct_option[j].split("/");
				if (typeof listQuestions[questionIndex].selectedAnswer[j].id!== undefined) {
					if(!split.includes(listQuestions[questionIndex].selectedAnswer[j].id.toString())){
						matchAns=false;
						break;
					}
				}
			}
			listQuestions[questionIndex].matchAns = matchAns;
			let user_answer_converted=[];
			for (let j = 0; j < listQuestions[questionIndex].selectedAnswer.length; j++) {
				user_answer_converted.push(constants.OptionstoDisplay[listQuestions[questionIndex].selectedAnswer[j].id]);
			}
			listQuestions[questionIndex].user_answer_converted=user_answer_converted;
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
		} else {
			return;
		}
	};
	//end of drag and drop code
	//On check function for single select question options.
	const handleSingleSelectAnswer = e => {
		if(e.target.value){
			if(listQuestions&&listQuestions.length>0){
				listQuestions[questionIndex].selectedAnswer=[e.target.value];
				listQuestions[questionIndex].matchAns = (listQuestions[questionIndex].selectedAnswer.length == listQuestions[questionIndex].correct_option.length) && listQuestions[questionIndex].selectedAnswer.every(function(element, index) {
					return element === listQuestions[questionIndex].correct_option[index]; 
				});
				let user_answer_converted=[];
				for (let j = 0; j < listQuestions[questionIndex].selectedAnswer.length; j++) {
					user_answer_converted.push(constants.OptionstoDisplay[listQuestions[questionIndex].selectedAnswer[j]]);
				}
				listQuestions[questionIndex].user_answer_converted=user_answer_converted;
				let option=constants.OptionNumbers[e.target.value];
				if(listQuestions[questionIndex][option]&&listQuestions[questionIndex][option].includes("<strike>")){
					listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("<strike>","");
					listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("</strike>","");
				}
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				setRefresh(!refresh);
			}
		}
	};
	//On check function for multi select question options.
	const onChangeMultipleChoice=(checkedValues)=>{
		let sortedAnswer=[];
		let unSortedAnswer=cloneDeep(checkedValues);
		sortedAnswer=checkedValues.sort();
		if(listQuestions&&listQuestions.length>0){
			listQuestions[questionIndex].selectedAnswer=sortedAnswer;
			listQuestions[questionIndex].matchAns = (listQuestions[questionIndex].selectedAnswer.length == listQuestions[questionIndex].correct_option.length) && listQuestions[questionIndex].selectedAnswer.every(function(element, index) {
				return element === listQuestions[questionIndex].correct_option[index]; 
			});
			let user_answer_converted=[];
			for (let j = 0; j < listQuestions[questionIndex].selectedAnswer.length; j++) {
				user_answer_converted.push(constants.OptionstoDisplay[listQuestions[questionIndex].selectedAnswer[j]]);
			}
			listQuestions[questionIndex].user_answer_converted=user_answer_converted;
			let option=constants.OptionNumbers[unSortedAnswer[unSortedAnswer.length-1]];
			if(listQuestions[questionIndex][option]&&listQuestions[questionIndex][option].includes("<strike>")){
				listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("<strike>","");
				listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("</strike>","");
			}
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
			setRefresh(!refresh);
		}
	};
	//Function to handle Strike through options of all types of questions.
	const handleStrikeThrough=()=>{
		if(listQuestions&&listQuestions.length>0){
			let optionNumver=constants.OptionNumbers;
			if(listQuestions[questionIndex].q_type==="1"){
				let ddd=optionNumver[listQuestions[questionIndex].selectedAnswer[0]];
				if(listQuestions[questionIndex][ddd].includes("<strike>")){
					listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
					listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
				}else{
					listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
					listQuestions[questionIndex].selectedAnswer=null;
				}
			}else if(listQuestions[questionIndex].q_type==="2"){
				for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
					let ddd=optionNumver[listQuestions[questionIndex].selectedAnswer[i]];
					if(listQuestions[questionIndex][ddd].includes("<strike>")){
						listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
						listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
					}else{
						listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
					}
				}
				listQuestions[questionIndex].selectedAnswer=null;
			}else if(listQuestions[questionIndex].q_type==="4"){
				for (let i = 0; i < sortitems.length; i++) {
					if(sortitems[i].index===selectedSequenceOption){
						let ddd=optionNumver[sortitems[i].index];
						if(sortitems[i].value.includes("<strike>")){
							sortitems[i].value=sortitems[i].value.replace("<strike>","");
							sortitems[i].value=sortitems[i].value.replace("</strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
							setSelectedSequenceOption(sortitems[i].index);
						}else{
							sortitems[i].value=`<strike>${sortitems[i].value}</strike>`;
							listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
							setSelectedSequenceOption(sortitems[i].index);
						}
					}
				}
			}else if(listQuestions[questionIndex].q_type==="5"){
				let dragAndDropOptions=constants.OptionNumberDragAndDrop;
				for (let i = 0; i < columns[1].items.length; i++) {
					if(columns[1].items[i].id===selectedDragOption){
						let ddd=dragAndDropOptions[columns[1].items[i].id];
						if(columns[1].items[i].content.includes("<strike>")){
							columns[1].items[i].content=columns[1].items[i].content.replace("<strike>","");
							columns[1].items[i].content=columns[1].items[i].content.replace("</strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
						}else{
							columns[1].items[i].content=`<strike>${columns[1].items[i].content}</strike>`;
							listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
						}
					}
				}
				setColumns(columns);
				listQuestions[questionIndex].columns=columns;
				// listQuestions[questionIndex].selectedOption=items;
			}
			setQuestions(listQuestions);
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
		}
		setRefresh(!refresh);
	};
	//Function to select the arrange the sequence option on click
	const	handleSequence=(e)=>{
		setSelectedSequenceOption(e);
	};
	//Function to select the Drag and drop option on click
	const handleDragDropOption=(e)=>{
		setSelectedDragOption(e);
	};
		//Function to set the coordinate of selected text on Mouse down for highlighting the text
	const handleMouseDown=(e,id)=>{
		setonMouseDownId(Number(id));
		setSelectedSequenceOption("");
		if(e.changedTouches&&e.changedTouches[0]&&e.changedTouches[0].pageX){
			setMouseDown(e.changedTouches[0].pageX);
		}else{
			setMouseDown(e.pageX);
		}
		setRefresh(!refresh);
	};
		//Function to set the coordinate of selected text on Mouse up for highlighting the text
	const handleMouseUp=(e,id)=>{
		setSelectedId(Number(id));
		setSelectedSequenceOption("");
		if(e.changedTouches&&e.changedTouches[0]&&e.changedTouches[0].pageX){
			setMouseUp(e.changedTouches[0].pageX);
		}else{
			setMouseUp(e.pageX);
		}
		setRefresh(!refresh);
	};
		//Function to handle Highlight text to options.
		// eslint-disable-next-line no-unused-vars
	const handleHighlightText=()=> {
		if(selectedId===onMouseDownId&&selectedId!==null&&onMouseDownId!==null){
			if(selectedId===1||selectedId===2||selectedId===3||selectedId===4||selectedId===5||selectedId===6||selectedId===7||selectedId===8||selectedId===99){
				var highlighted = false;
				var selection = window.getSelection();
				var selectedText = selection.toString();
				var startPoint = window.getSelection().getRangeAt(0).startOffset;
				var endPoint = window.getSelection().getRangeAt(0).endOffset;
				var anchorTag = selection.anchorNode.parentNode;
				var focusTag = selection.focusNode.parentNode;
				if ((mouseUp - mouseDown) < 0) {
					focusTag = selection.anchorNode.parentNode;
					anchorTag = selection.focusNode.parentNode;
				}
				if (selectedText.length === (endPoint - startPoint)) {
					highlighted = true;
					if (anchorTag.className !== "highlighted") {
						highlightSelection();
					} else {
						var afterText = selectedText + "<span class = 'highlighted'>" + anchorTag.innerHTML.substr(endPoint) + "</span>";
						anchorTag.innerHTML = anchorTag.innerHTML.substr(0, startPoint);
						anchorTag.insertAdjacentHTML('afterend', afterText);
					}
				}else{
					if(anchorTag.className !== "highlighted" && focusTag.className !== "highlighted"){
						highlightSelection();  
						highlighted = true;
					}
				}
				if (anchorTag.className === "highlighted" && focusTag.className === 'highlighted' && !highlighted) {
					highlighted = true;
					var afterHtml = anchorTag.innerHTML.substr(startPoint);
					var outerHtml = selectedText.substr(afterHtml.length, selectedText.length - endPoint - afterHtml.length);
					var anchorInnerhtml = anchorTag.innerHTML.substr(0, startPoint);
					var focusInnerHtml = focusTag.innerHTML.substr(endPoint);
					var focusBeforeHtml = focusTag.innerHTML.substr(0, endPoint);
					selection.deleteFromDocument();
					anchorTag.innerHTML = anchorInnerhtml;
					focusTag.innerHTml = focusInnerHtml;
					var anchorafterHtml = afterHtml + outerHtml + focusBeforeHtml;
					anchorTag.insertAdjacentHTML('afterend', anchorafterHtml);
				}
				if (anchorTag.className === "highlighted" && !highlighted) {
					highlighted = true;
					if(startPoint!==0||endPoint!==0){
						var Innerhtml = anchorTag.innerHTML.substr(0, startPoint);
						var afterHtmll = anchorTag.innerHTML.substr(startPoint);
						var outerHtmll = selectedText.substr(afterHtmll.length, selectedText.length);
						selection.deleteFromDocument();
						if(afterHtml){
							anchorTag.innerHTML = Innerhtml;
							anchorTag.insertAdjacentHTML('afterend', afterHtmll + outerHtmll);
						}else{
							anchorTag.insertAdjacentHTML('afterend', selectedText );
						}
					}
				}
				if (focusTag.className === 'highlighted' && !highlighted) {
					var pointValue=0;
					if(startPoint>endPoint){
						pointValue=startPoint;
					}else{
						pointValue=endPoint;
					}
					highlighted = true;
					var beforeHtml = focusTag.innerHTML.substr(0, endPoint);
					var outerHtmls = selectedText.substr(0, selectedText.length - beforeHtml.length);
					selection.deleteFromDocument();
					focusTag.innerHTml = focusTag.innerHTML.substr(pointValue);
					outerHtmls += beforeHtml;
					focusTag.insertAdjacentHTML('beforebegin', outerHtmls );
				}
				if (!highlighted) {
					highlightSelection();
				}
				$('.highlighted').each(function(){
					if($(this).html() == ''||$(this).html() == '&nbsp;'){
						$(this).remove();
					}
				});
				selection.removeAllRanges();
				// eslint-disable-next-line no-inner-declarations
				function highlightSelection() {
					var selectionText;
					//Get the selected stuff
					if (window.getSelection){
						selectionText = window.getSelection();
					}
					else if (typeof document.selection != "undefined"){
						selectionText = document.selection;
					}
					//Get a the selected content, in a range object
					var range = selectionText.rangeCount >= 1&&selection.getRangeAt(0);
					//If the range spans some text, and inside a tag, set its css class.
					if (range && !selectionText.isCollapsed) {
						if (selectionText.anchorNode.parentNode == selectionText.focusNode.parentNode) {
							var span = document.createElement('span');
							span.className = 'highlighted';
							span.textContent = selectionText.toString();
							selectionText.deleteFromDocument();
							range.insertNode(span);
							//                        range.surroundContents(span);
						}
					}
				}
				if(listQuestions&&listQuestions.length>0){
					if(listQuestions[questionIndex].q_type&&listQuestions[questionIndex].q_type==="1"||
				listQuestions[questionIndex].q_type&&listQuestions[questionIndex].q_type==="2"){
						let optionNumver=constants.OptionNumbers;
						let ddd=optionNumver[selectedId];
						let m =document.getElementById(selectedId).innerHTML;
						listQuestions[questionIndex][ddd]=m;
					}else if(listQuestions[questionIndex].q_type==="4"){
						let optionNumver=constants.OptionNumbers;
						if(selectedId===99){
							let ddd=optionNumver[selectedId];
							let m =document.getElementById(selectedId).innerHTML;
							listQuestions[questionIndex][ddd]=m;
						}else{
							for (let i = 0; i < sortitems.length; i++) {
								if(sortitems[i].index===selectedId){
									let ddd=optionNumver[sortitems[i].index];
									let m =document.getElementById(selectedId).innerHTML;
									sortitems[i].value=m;
									listQuestions[questionIndex][ddd]=m;
								}
							}
						}
					}else if(listQuestions[questionIndex].q_type==="5"){
						let optionNumver=constants.OptionNumberDragAndDrop;
						if(selectedId===99){
							let ddd=optionNumver[selectedId];
							let m =document.getElementById(selectedId).innerHTML;
							listQuestions[questionIndex][ddd]=m;
						}else{
							if(columns&&columns[1].items&&columns[1].items.length>0){
								for (let j = 0; j < columns[1].items.length; j++) {
									if(Number(columns[1].items[j].id)===selectedId){
										let ddd=optionNumver[Number(columns[1].items[j].id)];
										let m =document.getElementById(selectedId).innerHTML;
										columns[1].items[j].content=m;
										listQuestions[questionIndex][ddd]=m;
									}
								}
							}
						}
						setColumns(columns);
					}
					setQuestions(listQuestions);
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				}
			}
		}else{
			if (window.getSelection) {
				if (window.getSelection().empty) {  // Chrome
					window.getSelection().empty();
				} else if (window.getSelection().removeAllRanges) {  // Firefox
					window.getSelection().removeAllRanges();
				}
			} else if (document.selection) {  // IE?
				document.selection.empty();
			}
		}
		setSelectedId(null);
		setRefresh(!refresh);
	};
	const handleRatings=e=>{
		if(e.target.value){
			if(listQuestions&&listQuestions.length>0){
				listQuestions[questionIndex].ratings=e.target.value;
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				setRefresh(!refresh);
			}
		}
	};
	const checkAnswerDisplay=(type)=>{
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0)
		{
			if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length){
				message.error(constants.SelectOptionDragAndDropTutorials);
			}else{
				listQuestions[questionIndex].checkAnswer="true";
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				setRefresh(!refresh);
				if(type&&type==="QOD"){
					saveQuestionOfTheDay();
				}
			}
		}else{
			message.error(constants.SelectOptionOnly);
		}
	};
	//Function executes to save the question of the day on click of check and update the ratings on click of close button.
	const saveQuestionOfTheDay=(type)=>{
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
			if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length){
				message.error(constants.SelectOptionDragAndDrop);
			}else{
				let totalOptions=null;
				let optionNumver={};
				if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"||listQuestions[questionIndex].q_type==="4"){
					totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt):0;
					optionNumver=constants.OptionNumbers;
				}else if(listQuestions[questionIndex].q_type==="5"){
					optionNumver=constants.OptionNumberDragAndDrop;
					totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt.charAt(2)):0;
				}
				var match=false;
				for (let index = 0; index < totalOptions; index++) {
					if(!listQuestions[questionIndex][optionNumver[index+1]].includes(`<strike>`)){
						match=true;
						break;
					}
				}
				if(match===true){
					let payload = {};
					if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"){
						payload.stud_id=questionOfDayDetails&&questionOfDayDetails.userId?questionOfDayDetails.userId:"";
						payload.qod={
							q_id:listQuestions[questionIndex].question_id,
							user_ans:listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0?listQuestions[questionIndex].selectedAnswer:[],
							corr_ans:listQuestions[questionIndex].correct_option,
							is_correct:listQuestions[questionIndex].matchAns===true?1:0,
						};
					}else if(listQuestions[questionIndex].q_type==="4"){
						let answer=[];
						for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
							answer.push(listQuestions[questionIndex].selectedAnswer[i].index);
						}
						payload.stud_id=questionOfDayDetails&&questionOfDayDetails.userId?questionOfDayDetails.userId:"";
						payload.qod={
							q_id:listQuestions[questionIndex].question_id,
							user_ans:answer&&answer.length>0?answer:[],
							corr_ans:listQuestions[questionIndex].correct_option,
							is_correct:listQuestions[questionIndex].matchAns===true?1:0,
						};
					}else if(listQuestions[questionIndex].q_type==="5"){
						let answer=[];
						for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
							answer.push(Number(listQuestions[questionIndex].selectedAnswer[i].id));
						}
						payload.stud_id=questionOfDayDetails&&questionOfDayDetails.userId?questionOfDayDetails.userId:"";
						payload.qod={
							q_id:listQuestions[questionIndex].question_id,
							user_ans:answer&&answer.length>0?answer:[],
							corr_ans:listQuestions[questionIndex].correct_option,
							is_correct:listQuestions[questionIndex].matchAns===true?1:0,
						};
					}
					payload.qod["user_rating"]=listQuestions[questionIndex].ratings;
					let strikedOptions=[];
					let highlightedOptions=[];
					for (let j = 0; j < totalOptions; j++) {
						let op=optionNumver[j+1];
						if(listQuestions[questionIndex][op].includes("<strike>")){
							strikedOptions.push(j+1);
						}
						if(listQuestions[questionIndex][op].includes("highlighted")){
							highlightedOptions.push({ 
								opt:j+1,
								data:listQuestions[questionIndex][op] 
							});
						}
					}
					if(strikedOptions&&strikedOptions.length>0||highlightedOptions&&highlightedOptions.length>0){
						payload.qod["strike"]=strikedOptions&&strikedOptions.length>0?strikedOptions:[];
						payload.qod["highlight"]=highlightedOptions&&highlightedOptions.length>0?highlightedOptions:[];
					}
					let method=null;
					let url=``;
					if(type&&type==="CLOSE"){
						if(listQuestions[questionIndex].user_rating&&listQuestions[questionIndex].user_rating>0){
							setColumns(constants.columnsOfDragAndDrop);
							setNextAndPreviousLoading(false);
							sessionStorage.setItem("quitTest","true");
							setPristine();
							history.push("/home");
						}else{
							method="put";
							let userId=getCookie(COOKIE.UserId);
							url+=`/qod/${userId}`;
							questionOftheDayApi(type,url,method,payload);
						}
					}else{
						method="post";
						url+=`/qod`;
						questionOftheDayApi(type,url,method,payload);
					}
				}else{
					message.error(constants.SelectOption);
				}
			}
		}else{
			message.error(constants.SelectOptionOnly);
		}
	};
	const questionOftheDayApi=(type,url,method,payload)=>{
		setNextAndPreviousLoading(true);
		fetchApi(url, method, payload)
			.then((res) => {
				setNextAndPreviousLoading(false);
				if (res && res.code && res.code === 200) {
					setNextAndPreviousLoading(false);
					if(type&&type==="CLOSE"){
						setColumns(constants.columnsOfDragAndDrop);
						setNextAndPreviousLoading(false);
						sessionStorage.setItem("quitTest","true");
						setPristine();
						history.push("/home");
					}
				} else {
					setNextAndPreviousLoading(false);
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setNextAndPreviousLoading(false);
					if(res.error_message==="Invalid token"){
						message.error(constants.LoggedInAnotherBrowser);
					}else{
						if(res.code===600){
							message.error(res.message);
						}else{
							message.error("Failed");
						}
					}
				}
			});
	};
	const singleSelect = (
		<Card className="testSection"> 
			<div className='d-flex mb-3'>
				<p className="test-question" id="99" onTouchStart={(e)=>{handleMouseDown(e,99);}} onTouchEnd={(e)=>{handleMouseUp(e,99);}} onMouseDown={(e)=>{handleMouseDown(e,99);}} onMouseUp={(e)=>{handleMouseUp(e,99);}} dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
			</div>			
			<Radio.Group className="test-options w-100 options-counter"
				onChange={handleSingleSelectAnswer} 
				value={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0&&listQuestions[questionIndex].selectedAnswer[0]}
			>
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"?
					(<Space direction="vertical" className="w-100 pointer-none">
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?
							<Radio value={1} 
								className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].selectedAnswer?
									listQuestions[questionIndex].selectedAnswer[0]===1&&listQuestions[questionIndex].checkAnswer==="true"?
										listQuestions[questionIndex].selectedAnswer[0]===listQuestions[questionIndex].correct_option[0]?
											"options options-inner review correct-ans"
											:"options options-inner review incorrect-ans"
										:1===listQuestions[questionIndex].correct_option[0]&&listQuestions[questionIndex].checkAnswer==="true"?
											"options options-inner review correct-ans"
											:"options options-inner review"
									:"options options-inner review"}
							>
								<p onTouchStart={(e)=>{handleMouseDown(e,1);}} onTouchEnd={(e)=>{handleMouseUp(e,1);}} onMouseDown={(e)=>{handleMouseDown(e,1);}} onMouseUp={(e)=>{handleMouseUp(e,1);}} id="1" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></p>
							</Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?
							<Radio value={2}
								className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].selectedAnswer?
									listQuestions[questionIndex].selectedAnswer[0]===2&&listQuestions[questionIndex].checkAnswer==="true"?
										listQuestions[questionIndex].selectedAnswer[0]===listQuestions[questionIndex].correct_option[0]?
											"options options-inner review correct-ans"
											:"options options-inner review incorrect-ans"
										:2===listQuestions[questionIndex].correct_option[0]&&listQuestions[questionIndex].checkAnswer==="true"?
											"options options-inner review correct-ans"
											:"options options-inner review"
									:"options options-inner review"}>
								<p onTouchStart={(e)=>{handleMouseDown(e,2);}} onTouchEnd={(e)=>{handleMouseUp(e,2);}} onMouseDown={(e)=>{handleMouseDown(e,2);}} onMouseUp={(e)=>{handleMouseUp(e,2);}} id="2" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></p>
							</Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?
							<Radio value={3}
								className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].selectedAnswer?
									listQuestions[questionIndex].selectedAnswer[0]===3&&listQuestions[questionIndex].checkAnswer==="true"?
										listQuestions[questionIndex].selectedAnswer[0]===listQuestions[questionIndex].correct_option[0]?
											"options options-inner review correct-ans"
											:"options options-inner review incorrect-ans"
										:3===listQuestions[questionIndex].correct_option[0]&&listQuestions[questionIndex].checkAnswer==="true"?
											"options options-inner review correct-ans"
											:"options options-inner review"
									:"options options-inner review"}>
								<p onTouchStart={(e)=>{handleMouseDown(e,3);}} onTouchEnd={(e)=>{handleMouseUp(e,3);}} onMouseDown={(e)=>{handleMouseDown(e,3);}} onMouseUp={(e)=>{handleMouseUp(e,3);}} id="3" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></p>
							</Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?
							<Radio value={4}	
								className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].selectedAnswer?
									listQuestions[questionIndex].selectedAnswer[0]===4&&listQuestions[questionIndex].checkAnswer==="true"?
										listQuestions[questionIndex].selectedAnswer[0]===listQuestions[questionIndex].correct_option[0]?
											"options options-inner review correct-ans"
											:"options options-inner review incorrect-ans"
										:4===listQuestions[questionIndex].correct_option[0]&&listQuestions[questionIndex].checkAnswer==="true"?
											"options options-inner review correct-ans"
											:"options options-inner review"
									:"options options-inner review"}>
								<p onTouchStart={(e)=>{handleMouseDown(e,4);}} onTouchEnd={(e)=>{handleMouseUp(e,4);}} onMouseDown={(e)=>{handleMouseDown(e,4);}} onMouseUp={(e)=>{handleMouseUp(e,4);}} id="4" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></p>
							</Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?
							<Radio value={5}	
								className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].selectedAnswer?
									listQuestions[questionIndex].selectedAnswer[0]===5&&listQuestions[questionIndex].checkAnswer==="true"?
										listQuestions[questionIndex].selectedAnswer[0]===listQuestions[questionIndex].correct_option[0]?
											"options options-inner review correct-ans"
											:"options options-inner review incorrect-ans"
										:5===listQuestions[questionIndex].correct_option[0]&&listQuestions[questionIndex].checkAnswer==="true"?
											"options options-inner review correct-ans"
											:"options options-inner review"
									:"options options-inner review"}>
								<p onTouchStart={(e)=>{handleMouseDown(e,5);}} onTouchEnd={(e)=>{handleMouseUp(e,5);}} onMouseDown={(e)=>{handleMouseDown(e,5);}} onMouseUp={(e)=>{handleMouseUp(e,5);}} id="5" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></p>
							</Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?
							<Radio value={6}	
								className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].selectedAnswer?
									listQuestions[questionIndex].selectedAnswer[0]===6&&listQuestions[questionIndex].checkAnswer==="true"?
										listQuestions[questionIndex].selectedAnswer[0]===listQuestions[questionIndex].correct_option[0]?
											"options options-inner review correct-ans"
											:"options options-inner review incorrect-ans"
										:6===listQuestions[questionIndex].correct_option[0]&&listQuestions[questionIndex].checkAnswer==="true"?
											"options options-inner review correct-ans"
											:"options options-inner review"
									:"options options-inner review"}>
								<p onTouchStart={(e)=>{handleMouseDown(e,6);}} onTouchEnd={(e)=>{handleMouseUp(e,6);}} onMouseDown={(e)=>{handleMouseDown(e,6);}} onMouseUp={(e)=>{handleMouseUp(e,6);}} id="6" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></p>
							</Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?
							<Radio value={7}	
								className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].selectedAnswer?
									listQuestions[questionIndex].selectedAnswer[0]===7&&listQuestions[questionIndex].checkAnswer==="true"?
										listQuestions[questionIndex].selectedAnswer[0]===listQuestions[questionIndex].correct_option[0]?
											"options options-inner review correct-ans"
											:"options options-inner review incorrect-ans"
										:7===listQuestions[questionIndex].correct_option[0]&&listQuestions[questionIndex].checkAnswer==="true"?
											"options options-inner review correct-ans"
											:"options options-inner review"
									:"options options-inner review"}>
								<p onTouchStart={(e)=>{handleMouseDown(e,7);}} onTouchEnd={(e)=>{handleMouseUp(e,7);}} onMouseDown={(e)=>{handleMouseDown(e,7);}} onMouseUp={(e)=>{handleMouseUp(e,7);}} id="7" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></p>
							</Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?
							<Radio value={8}	
								className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].selectedAnswer?
									listQuestions[questionIndex].selectedAnswer[0]===8&&listQuestions[questionIndex].checkAnswer==="true"?
										listQuestions[questionIndex].selectedAnswer[0]===listQuestions[questionIndex].correct_option[0]?
											"options options-inner review correct-ans"
											:"options options-inner review incorrect-ans"
										:8===listQuestions[questionIndex].correct_option[0]&&listQuestions[questionIndex].checkAnswer==="true"?
											"options options-inner review correct-ans"
											:"options options-inner review"
									:"options options-inner review"}>
								<p onTouchStart={(e)=>{handleMouseDown(e,8);}} onTouchEnd={(e)=>{handleMouseUp(e,8);}} onMouseDown={(e)=>{handleMouseDown(e,8);}} onMouseUp={(e)=>{handleMouseUp(e,8);}} id="8" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></p>
							</Radio>:""}
					</Space>):
					(<Space direction="vertical" className="w-100">
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?<Radio value={1} id="option1" className="options options-inner" ><p onTouchStart={(e)=>{handleMouseDown(e,1);}} onTouchEnd={(e)=>{handleMouseUp(e,1);}} onMouseDown={(e)=>{handleMouseDown(e,1);}} onMouseUp={(e)=>{handleMouseUp(e,1);}} id="1" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?<Radio value={2} id="option2" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,2);}} onTouchEnd={(e)=>{handleMouseUp(e,2);}} onMouseDown={(e)=>{handleMouseDown(e,2);}} onMouseUp={(e)=>{handleMouseUp(e,2);}} id="2" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?<Radio value={3} id="option3" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,3);}} onTouchEnd={(e)=>{handleMouseUp(e,3);}} onMouseDown={(e)=>{handleMouseDown(e,3);}} onMouseUp={(e)=>{handleMouseUp(e,3);}} id="3" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?<Radio value={4} id="option4" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,4);}} onTouchEnd={(e)=>{handleMouseUp(e,4);}} onMouseDown={(e)=>{handleMouseDown(e,4);}} onMouseUp={(e)=>{handleMouseUp(e,4);}} id="4" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?<Radio value={5} id="option5" className="options options-inner" ><p onTouchStart={(e)=>{handleMouseDown(e,5);}} onTouchEnd={(e)=>{handleMouseUp(e,5);}} onMouseDown={(e)=>{handleMouseDown(e,5);}} onMouseUp={(e)=>{handleMouseUp(e,5);}} id="5" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?<Radio value={6} id="option6" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,6);}} onTouchEnd={(e)=>{handleMouseUp(e,6);}} onMouseDown={(e)=>{handleMouseDown(e,6);}} onMouseUp={(e)=>{handleMouseUp(e,6);}} id="6" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?<Radio value={7} id="option7" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,7);}} onTouchEnd={(e)=>{handleMouseUp(e,7);}} onMouseDown={(e)=>{handleMouseDown(e,7);}} onMouseUp={(e)=>{handleMouseUp(e,7);}} id="7" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?<Radio value={8} id="option8" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,8);}} onTouchEnd={(e)=>{handleMouseUp(e,8);}} onMouseDown={(e)=>{handleMouseDown(e,8);}} onMouseUp={(e)=>{handleMouseUp(e,8);}} id="8" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></p></Radio>:""}
					</Space>)}
			</Radio.Group>
		</Card>
	);
	const multipleChoice = (
		<Card className="testSection">
			<div className='d-flex mb-3'>
				<p className="test-question" id="99" onTouchStart={(e)=>{handleMouseDown(e,99);}} onTouchEnd={(e)=>{handleMouseUp(e,99);}} onMouseDown={(e)=>{handleMouseDown(e,99);}} onMouseUp={(e)=>{handleMouseUp(e,99);}} dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
			</div>
			{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"?
				(<Radio.Group className="test-options w-100 review-counter">
					<Space direction="vertical" className="w-100">
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?<Radio value={1} className="options options-inner review" ><p onTouchStart={(e)=>{handleMouseDown(e,1);}} onTouchEnd={(e)=>{handleMouseUp(e,1);}} onMouseDown={(e)=>{handleMouseDown(e,1);}} onMouseUp={(e)=>{handleMouseUp(e,1);}} id="1" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?<Radio value={2} className="options options-inner review"><p onTouchStart={(e)=>{handleMouseDown(e,2);}} onTouchEnd={(e)=>{handleMouseUp(e,2);}} onMouseDown={(e)=>{handleMouseDown(e,2);}} onMouseUp={(e)=>{handleMouseUp(e,2);}} id="2" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?<Radio value={3} className="options options-inner review"><p onTouchStart={(e)=>{handleMouseDown(e,3);}} onTouchEnd={(e)=>{handleMouseUp(e,3);}} onMouseDown={(e)=>{handleMouseDown(e,3);}} onMouseUp={(e)=>{handleMouseUp(e,3);}} id="3" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?<Radio value={4} className="options options-inner review"><p onTouchStart={(e)=>{handleMouseDown(e,4);}} onTouchEnd={(e)=>{handleMouseUp(e,4);}} onMouseDown={(e)=>{handleMouseDown(e,4);}} onMouseUp={(e)=>{handleMouseUp(e,4);}} id="4" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?<Radio value={5} className="options options-inner review" ><p onTouchStart={(e)=>{handleMouseDown(e,5);}} onTouchEnd={(e)=>{handleMouseUp(e,5);}} onMouseDown={(e)=>{handleMouseDown(e,5);}} onMouseUp={(e)=>{handleMouseUp(e,5);}} id="5" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?<Radio value={6} className="options options-inner review"><p onTouchStart={(e)=>{handleMouseDown(e,6);}} onTouchEnd={(e)=>{handleMouseUp(e,6);}} onMouseDown={(e)=>{handleMouseDown(e,6);}} onMouseUp={(e)=>{handleMouseUp(e,6);}} id="6" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?<Radio value={7} className="options options-inner review"><p onTouchStart={(e)=>{handleMouseDown(e,7);}} onTouchEnd={(e)=>{handleMouseUp(e,7);}} onMouseDown={(e)=>{handleMouseDown(e,7);}} onMouseUp={(e)=>{handleMouseUp(e,7);}} id="7" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></p></Radio>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?<Radio value={8} className="options options-inner review"><p onTouchStart={(e)=>{handleMouseDown(e,8);}} onTouchEnd={(e)=>{handleMouseUp(e,8);}} onMouseDown={(e)=>{handleMouseDown(e,8);}} onMouseUp={(e)=>{handleMouseUp(e,8);}} id="8" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></p></Radio>:""}
					</Space>
				</Radio.Group>)
				:			
				(<Checkbox.Group
					style={{ width: "100%" }}
					onChange={onChangeMultipleChoice}
					value={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0&&listQuestions[questionIndex].selectedAnswer}
					className="test-options w-100 options-counter"
				//className={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"?"d-none test-options w-100 options-counter":"test-options w-100 options-counter"}
				// disabled={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"?true:false}
				>
					<Space direction="vertical" className="w-100">
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?
							<Checkbox className="options options-inner" value={1}>
								<p onTouchStart={(e)=>{handleMouseDown(e,1);}} onTouchEnd={(e)=>{handleMouseUp(e,1);}} onMouseDown={(e)=>{handleMouseDown(e,1);}} onMouseUp={(e)=>{handleMouseUp(e,1);}} id="1" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></p>
							</Checkbox>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?
							<Checkbox className="options options-inner" value={2}>
								<p onTouchStart={(e)=>{handleMouseDown(e,2);}} onTouchEnd={(e)=>{handleMouseUp(e,2);}} onMouseDown={(e)=>{handleMouseDown(e,2);}} onMouseUp={(e)=>{handleMouseUp(e,2);}} id="2" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></p>
							</Checkbox>:""}		
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?
							<Checkbox className="options options-inner" value={3}>
								<p onTouchStart={(e)=>{handleMouseDown(e,3);}} onTouchEnd={(e)=>{handleMouseUp(e,3);}} onMouseDown={(e)=>{handleMouseDown(e,3);}} onMouseUp={(e)=>{handleMouseUp(e,3);}} id="3" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></p>
							</Checkbox>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?
							<Checkbox className="options options-inner" value={4}>
								<p onTouchStart={(e)=>{handleMouseDown(e,4);}} onTouchEnd={(e)=>{handleMouseUp(e,4);}} onMouseDown={(e)=>{handleMouseDown(e,4);}} onMouseUp={(e)=>{handleMouseUp(e,4);}} id="4" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></p>
							</Checkbox>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?
							<Checkbox className="options options-inner" value={5}>
								<p onTouchStart={(e)=>{handleMouseDown(e,5);}} onTouchEnd={(e)=>{handleMouseUp(e,5);}} onMouseDown={(e)=>{handleMouseDown(e,5);}} onMouseUp={(e)=>{handleMouseUp(e,5);}} id="5" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></p>
							</Checkbox>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?
							<Checkbox className="options options-inner" value={6}>
								<p onTouchStart={(e)=>{handleMouseDown(e,6);}} onTouchEnd={(e)=>{handleMouseUp(e,6);}} onMouseDown={(e)=>{handleMouseDown(e,6);}} onMouseUp={(e)=>{handleMouseUp(e,6);}} id="6" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></p>
							</Checkbox>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?
							<Checkbox className="options options-inner" value={7}>
								<p onTouchStart={(e)=>{handleMouseDown(e,7);}} onTouchEnd={(e)=>{handleMouseUp(e,7);}} onMouseDown={(e)=>{handleMouseDown(e,7);}} onMouseUp={(e)=>{handleMouseUp(e,7);}} id="7" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></p>
							</Checkbox>:""}
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?
							<Checkbox className="options options-inner" value={8}>
								<p onTouchStart={(e)=>{handleMouseDown(e,8);}} onTouchEnd={(e)=>{handleMouseUp(e,8);}} onMouseDown={(e)=>{handleMouseDown(e,8);}} onMouseUp={(e)=>{handleMouseUp(e,8);}} id="8" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></p>
							</Checkbox>:""}
					</Space>
				</Checkbox.Group>)}
		</Card>
	);
	const arrangeSequence = (
		<Card className="testSection">
			<div className='d-flex mb-3'>
				<p className="test-question" id="99" onTouchStart={(e)=>{handleMouseDown(e,99);}} onTouchEnd={(e)=>{handleMouseUp(e,99);}} onMouseDown={(e)=>{handleMouseDown(e,99);}} onMouseUp={(e)=>{handleMouseUp(e,99);}} dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
			</div>			
			<div>
				<p onTouchStart={(e)=>{handleMouseDown(e,101);}} onTouchEnd={(e)=>{handleMouseUp(e,101);}} onMouseDown={(e)=>{handleMouseDown(e,101);}} onMouseUp={(e)=>{handleMouseUp(e,101);}}>* Reorder the correct sequence.</p>				<SortableContainer onSortEnd={onSortEnd} useDragHandle>
					<ul className="test-sortable">
						{sortitems.map((item,index) => (
							<SortableItem key={item.index} index={index} value={item.value} indexPos={item.index} handleSequence={handleSequence} MouseDown={(e)=>handleMouseDown(e,item.index)} MouseUp={(e)=>handleMouseUp(e,item.index)} disabled={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"} TouchStart={(e)=>handleMouseDown(e,item.index)} TouchEnd={(e)=>handleMouseUp(e,item.index)}
							/>
						))}
					</ul>
				</SortableContainer>
			</div>
		</Card>
	);
	{/* Component used to display the options draggable component for drag and drop type questions */}
	const draggableComponent=(id)=>{
		return(					
			<div style={{ margin: 8 }}>
				<Droppable droppableId={id}>
					{(provided, snapshot) => {
						return (
							<div
								{...provided.droppableProps}
								className="droppable"
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								{columns&&columns[id]&&columns[id].items&&columns[id].items.length>0&&columns[id].items.map((item, index) => {
									return (
										<Draggable
											key={item&&item.id&&item.id}
											draggableId={item&&item.id&&item.id}
											index={index}
											isDragDisabled={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"?true:false}
										>
											{(providedProp) => {
												return (
													<div
														ref={providedProp.innerRef}
														{...providedProp.draggableProps}
														{...providedProp.dragHandleProps}
														// style={getItemStyle(
														// 	snapshot.isDragging,
														// 	provided.draggableProps.style
														// )}
													>
														<div className="dnd-item" dangerouslySetInnerHTML={{ __html:item.content }}></div>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</div>
		);
	};
	{/* drag and drop */}
	const dragAndDrop=(
		<Col span={24}>
			<Card className="testSection">
				<DragDropContext
					onDragEnd={result => onDragEnd(result, columns, setColumns)}
				>
					<div className='d-flex d-flex-wrap mb-3 flex-direction-col'>
						<p className="test-question" id="99" onTouchStart={(e)=>{handleMouseDown(e,99);}} onTouchEnd={(e)=>{handleMouseUp(e,99);}} onMouseDown={(e)=>{handleMouseDown(e,99);}} onMouseUp={(e)=>{handleMouseUp(e,99);}} dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
						{listQuestions&&listQuestions.length>0&&
					<span className="test-question drag-question">
						<Space wrap>
							{listQuestions[questionIndex].option_first?
								listQuestions[questionIndex].option_first.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_first.indexOf("~")) }}></p>{draggableComponent(2)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_first.indexOf("|")) }}></p>)
								:""
							}
							{listQuestions[questionIndex].option_second?
								listQuestions[questionIndex].option_second.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second.substring(0,listQuestions[questionIndex].option_second.indexOf("~")) }}></p>{draggableComponent(3)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second.substring(0,listQuestions[questionIndex].option_second.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_third?
								listQuestions[questionIndex].option_third.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third.substring(0,listQuestions[questionIndex].option_third.indexOf("~")) }}></p>{draggableComponent(4)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third.substring(0,listQuestions[questionIndex].option_third.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_fourth?
								listQuestions[questionIndex].option_fourth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth.substring(0,listQuestions[questionIndex].option_fourth.indexOf("~")) }}></p>{draggableComponent(5)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth.substring(0,listQuestions[questionIndex].option_fourth.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_fifth?
								listQuestions[questionIndex].option_fifth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth.substring(0,listQuestions[questionIndex].option_fifth.indexOf("~")) }}></p>{draggableComponent(6)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth.substring(0,listQuestions[questionIndex].option_fifth.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_sixth?
								listQuestions[questionIndex].option_sixth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth.substring(0,listQuestions[questionIndex].option_sixth.indexOf("~")) }}></p>{draggableComponent(7)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth.substring(0,listQuestions[questionIndex].option_sixth.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_seventh?
								listQuestions[questionIndex].option_seventh.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh.substring(0,listQuestions[questionIndex].option_seventh.indexOf("~")) }}></p>{draggableComponent(8)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh.substring(0,listQuestions[questionIndex].option_seventh.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_eighth?
								listQuestions[questionIndex].option_eighth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_eighth.indexOf("~")) }}></p>{draggableComponent(9)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth.substring(0,listQuestions[questionIndex].option_eighth.indexOf("|")) }}></p>)
								:""					
							}
						</Space>
					</span>
						}
						<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
					</div>
					<Droppable droppableId={"1"}>
						{(provided, snapshot) => {
							return (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{columns&&columns[1]&&columns[1].items&&columns[1].items.length>0&&columns[1].items.map((item, index) => {
										return (
											<Draggable
												key={item&&item.id&&item.id}
												draggableId={item&&item.id&&item.id}
												index={index}
												isDragDisabled={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"?true:false}
											>
												{(providedProp, snapshots) => {
													return (
														<div
															style={getItemStyle(
																snapshots.isDragging,
																providedProp.draggableProps.style
															)}>
															<div
																ref={providedProp.innerRef}
																{...providedProp.draggableProps}
																{...providedProp.dragHandleProps}
															>
																<DragOutlined className="test-dnd-icon"/>
															</div>
															<span className="dnd-counter">{constants.OptionstoDisplay[item&&item.id&&item.id]}</span>
															<div className="drag-option d-inlineblock" onClick={()=>{handleDragDropOption(item&&item.id&&item.id);}} onMouseDown={(e)=>{handleMouseDown(e,item.id);}} onMouseUp={(e)=>{handleMouseUp(e,item.id);}} onTouchStart={(e)=>{handleMouseDown(e,item.id);}} onTouchEnd={(e)=>{handleMouseUp(e,item.id);}} id={item.id} dangerouslySetInnerHTML={{ __html:item.content }}></div>
														</div>
													);
												}}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							);
						}}
					</Droppable>
				</DragDropContext>
			</Card>
		</Col>
	);
	//function to create a note
	const createNote=(values)=>{
		if(values.note!==undefined&&values.note!==null){
			let tempValueNote=values.note.trim();
			let tempNote=tempValueNote.replace( /^\s*[\r\n]/gm, "\n" );
			let tempNoteID=listQuestions[questionIndex].note_id;
			let indexTemp=notelist.findIndex((item)=>item.question_id===listQuestions[questionIndex].question_id);
			if(tempNote!==""){
				listQuestions[questionIndex].notes=tempNote;
				let tempObj={
					note:tempNote,
					question_id:listQuestions[questionIndex].question_id
				};
				if(indexTemp===-1){
					notelist.push(tempObj);
				}else{
					notelist[indexTemp].note=tempNote;
				}
			}else{
				if(indexTemp!==-1&&tempNote===""){
					notelist.splice(indexTemp, 1);
				}
				listQuestions[questionIndex].notes=null;
			}
			if(tempNote!==""&&listQuestions[questionIndex].note_id!==null){
				let userId = getCookie(COOKIE.UserId);
				let payload=null;
				payload={
					stud_id:userId,
					notes:tempNote,
					score_card_id:scoreCardId,
					question_id:listQuestions[questionIndex].question_id
				};
				fetchApi(`/student/notes/${tempNoteID}`,"put",payload).then((res) => {
					if (res && res.code===200) {
					// message.success(res.message);
					} else {
						message.error(res.message);
					}
				});
			}
			if(tempNote===""&&listQuestions[questionIndex].note_id!==null){
				fetchApi(`/student/notes/${tempNoteID}`, "delete").then((res) => {
					if (res && res.code===200) {
					//	message.success(res.message);
					} else {
						message.error(res.message);
					}
				});
			}
			hideModal();
		}
	};
	const saveNotes=()=>{
		if(notelist.length>0){
			let userId = getCookie(COOKIE.UserId);
			let payload=null;
			let scoreCardIdFromSession=Number(sessionStorage.getItem("scoreCardId"));
			payload={
				stud_id:userId,
				notes:notelist,
				score_card_id:scoreCardIdFromSession,
			};
			fetchApi(`/student/notes`,"post",payload).then((res) => {
				if (res && res.code===200) {
					// message.success(res.message);
				} else {
					message.error(res.message);
				}
			});
		}
	};
	return (
		<div className="p-4 full-length-test-container tutorial-ans">
			<Row  gutter={[{
				xs: 0,
				sm: 0,
				md: 24,
				lg: 24 
			}, {
				xs: 24,
				sm: 24,
				md: 24,
				lg: 24 
			}]}>
				<Col xs={24} sm={24} md={24} lg={24}>{/* Title of the page */}
					{categoryDetails&&categoryDetails.type==="tutor"?(<h3 className="mainHeading">Category Tutorial</h3>):""}
					{topicDetails&&topicDetails.type==="tutor"?(<h3 className="mainHeading">Topic Tutorial</h3>):""}
					{customDetails&&customDetails.type==="tutor"?(<h3 className="mainHeading">Custom Tutorial</h3>):""}
					{questionOfDayDetails&&questionOfDayDetails.type==="qod"?(<h3 className="mainHeading text-uppercase">Question of the Day</h3>):""}
					{questionOfDayDetails&&questionOfDayDetails.type==="qod"?
						QODQuestionAnswered==="2"?
							<Alert message={QODmessage} type="info" showIcon/>
							:
							QODQuestionAnswered==="0"?
								<Alert message={QODmessage} type="warning" showIcon/>
								:
								<Alert message={QODmessage} type="success" showIcon/>
						:""}
					{markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true?(<h3 className="mainHeading">Mark for Later Tutorial</h3>):""}
					{markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true?(<h3 className="mainHeading">Incorrect Answers Tutorial</h3>):""}
					<Divider className="mb-0"/>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24}>{/* Questions count and current question number and strike through, highlight and notes icon */}
					<Row gutter={[8, 0]}>
						{categoryDetails&&categoryDetails.type==="tutor"||
						topicDetails&&topicDetails.type==="tutor"||
						customDetails&&customDetails.type==="tutor"||
						markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||
						markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true?
							(<Col sm={8} className="test-question-details"><span>Question</span> : <span>{questionIndex+1} of{" "} 
								{listQuestions&&listQuestions.length>0?
									listQuestions.length:
									categoryDetails&&categoryDetails.type==="tutor"||
									customDetails&&customDetails.type==="tutor"?
										10:topicDetails&&topicDetails.type==="tutor"?
											5:15}
							</span>
							</Col>)
							:null
						}
						{questionOfDayDetails&&questionOfDayDetails.type==="qod"&&listQuestions&&listQuestions.length>0?(<Col sm={12}  className="test-question-details text-left "><span className="cat-title">Topic:{" "}</span>
							{listQuestions[questionIndex].topic_title?listQuestions[questionIndex].topic_title:""}
						</Col>):""}
						{categoryDetails&&categoryDetails.type==="tutor"?(<Col sm={8}  className="test-question-details text-center "><span className="cat-title">Category:{" "}</span>{categoryDetails.catType}</Col>):""}
						{topicDetails&&topicDetails.type==="tutor"?(<Col sm={8}  className="test-question-details text-center "><span className="cat-title">Topic:{" "}</span>
							{topicDetails.topicName?topicDetails.topicName:""}
						</Col>):""}
						{customDetails&&customDetails.type==="tutor"||
						markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||
						markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true?
							listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].topic_title?
								(<Col sm={8}  className="test-question-details text-center ">
									<span className="cat-title">Topic:{" "}</span>{listQuestions[questionIndex].topic_title}
								</Col>)
								:"":""
						}
						{listQuestions&&listQuestions.length>0&&
						<Col sm={categoryDetails&&categoryDetails.type==="tutor"||topicDetails&&topicDetails.type==="tutor"||customDetails&&customDetails.type==="tutor"||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true?8:12} className="text-right">
							<Space size={'middle'} className="text-right test-icon-container">
								{/* Strikethrough and Highlight icons are hidden if the user has pressed the Check button for a question */}
								{categoryDetails&&categoryDetails.type==="tutor"||
								topicDetails&&topicDetails.type==="tutor"||
								customDetails&&customDetails.type==="tutor"||
								markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||
								markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true||
								questionOfDayDetails&&questionOfDayDetails.type==="qod"?
									listQuestions&&
									listQuestions.length>0&&
									listQuestions[questionIndex].checkAnswer&&
									listQuestions[questionIndex].checkAnswer==="false"?
										(<>
											<Tooltip title="Strikethrough"><Button type="text" onClick={()=>{handleStrikeThrough();}} icon={<StrikethroughOutlined className="strike"/>}></Button></Tooltip>
											<Tooltip title="Highlight"><Button type="text" onTouchStart={(e)=>e.preventDefault()} onTouchEnd={()=>handleHighlightText()} onClick={()=>{handleHighlightText();}} icon={<HighlightOutlined className="highlight"/>}></Button></Tooltip>
										</>
										):null
									:null}
								{
									listQuestions&&listQuestions.length>0&&categoryDetails&&categoryDetails.type==="tutor"||topicDetails&&topicDetails.type==="tutor"||customDetails&&customDetails.type==="tutor"||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||
									markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true
										?listQuestions[questionIndex].notes!==null&&listQuestions[questionIndex].notes!==undefined&&listQuestions[questionIndex].notes!==""?
											<Tooltip title="Notes"><Button type="text" icon={<Badge dot color={"blue"}><Comment/></Badge>} onClick={showModal}></Button></Tooltip>
											:
											<Tooltip title="Notes"><Button type="text" icon={<Comment/>} onClick={showModal}></Button></Tooltip>
										:null	}
								<Tooltip title="Decrease font"><Button onClick={()=>{zbminus();}} className={count>=2? "zoom-btn iconDisable": "zoom-btn"}>A-</Button></Tooltip>
								<Tooltip title="Increase font" placement="topRight"><Button onClick={()=>{zb();}} className={count<=0? "zoom-btn iconDisable": "zoom-btn"}>A+</Button></Tooltip>
							</Space>
						</Col>}
					</Row>
				</Col>
				<Col span={24}>
					<Spin className="flex-middle h-100vh" spinning={nextAndPreviousLoading} size="large" indicator={<Loader/>}>
						{listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="1"?singleSelect:
							listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="2"?multipleChoice:listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="4"?arrangeSequence:listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="5"?dragAndDrop:""
						}
					</Spin>
				</Col>
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].checkAnswer==="false"&&(<Col xs={24} sm={24} md={24} lg={24}>
					<Space size={'middle'} wrap>
						{questionOfDayDetails&&questionOfDayDetails.type==="qod"?
							listQuestions[questionIndex].is_answered!==null&&listQuestions[questionIndex].is_answered===0?
								<Button className="btnDb min-width-100" onClick={()=>{checkAnswerDisplay("QOD");}}>Check</Button>
								:null
							:<Button className="btnDb min-width-100" onClick={()=>{checkAnswerDisplay();}}>Check</Button>}
						{listQuestions&&listQuestions.length>0&&
							<Space size={'middle'} className="purple-border">
								{/* Strikethrough and Highlight icons are hidden if the user has pressed the Check button for a question */}
								{categoryDetails&&categoryDetails.type==="tutor"||
								topicDetails&&topicDetails.type==="tutor"||
								customDetails&&customDetails.type==="tutor"||
								markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||
								markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true||
								questionOfDayDetails&&questionOfDayDetails.type==="qod"?
									listQuestions&&
									listQuestions.length>0&&
									listQuestions[questionIndex].checkAnswer&&
									listQuestions[questionIndex].checkAnswer==="false"?
										(<>
											<Tooltip title="Strikethrough"><Button type="text" onClick={()=>{handleStrikeThrough();}} icon={<StrikethroughOutlined className="strike"/>}></Button></Tooltip>
											<Tooltip title="Highlight"><Button type="text" onTouchStart={(e)=>e.preventDefault()} onTouchEnd={()=>handleHighlightText()} onClick={()=>{handleHighlightText();}} icon={<HighlightOutlined className="highlight"/>}></Button></Tooltip>
										</>
										):null
									:null}
								{
									listQuestions&&listQuestions.length>0&&categoryDetails&&categoryDetails.type==="tutor"||topicDetails&&topicDetails.type==="tutor"||customDetails&&customDetails.type==="tutor"||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||
									markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true
										?listQuestions[questionIndex].notes!==null&&listQuestions[questionIndex].notes!==undefined&&listQuestions[questionIndex].notes!==""?
											<Tooltip title="Notes"><Button type="text" icon={<Badge dot color={"blue"}><Comment/></Badge>} onClick={showModal}></Button></Tooltip>
											:
											<Tooltip title="Notes"><Button type="text" icon={<Comment/>} onClick={showModal}></Button></Tooltip>
										:null	}
								<Tooltip title="Decrease font"><Button onClick={()=>{zbminus();}} className={count>=2? "zoom-btn iconDisable": "zoom-btn"}>A-</Button></Tooltip>
								<Tooltip title="Increase font"><Button onClick={()=>{zb();}} className={count<=0? "zoom-btn iconDisable": "zoom-btn"}>A+</Button></Tooltip>
							</Space>
						}
					</Space>
				</Col>)}
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type&&listQuestions[questionIndex].q_type!=="1"&&listQuestions[questionIndex].checkAnswer==="true"?(<Col span={8}>
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].matchAns!==null&&
					listQuestions[questionIndex].matchAns===true?
						<Alert message="Correct" type="success" showIcon/>:<Alert message="Incorrect" type="error" showIcon/>}
				</Col>):""}
				{listQuestions&&
						listQuestions.length>0&&listQuestions[questionIndex].q_type&&listQuestions[questionIndex].q_type!=="1"&&listQuestions[questionIndex].checkAnswer==="true"
					?(
						<Col span={24}>
							<Card bordered={false} className="ans-display">
								<Row gutter={[0, 12]}>
									<Col span={24}><b>Your Answer: </b> {
										listQuestions&&
										listQuestions.length>0&&
										listQuestions[questionIndex].user_answer_converted&&
										listQuestions[questionIndex].user_answer_converted.length>0?
											listQuestions[questionIndex].user_answer_converted.join(", "):""}</Col>
									<br/>
									<Col span={24}><b>Correct Answer: </b> {listQuestions&&
										listQuestions.length>0&&
										listQuestions[questionIndex].correct_option_converted&&
										listQuestions[questionIndex].correct_option_converted.length>0?
										listQuestions[questionIndex].correct_option_converted[0].includes("/")?listQuestions[questionIndex].correct_option_converted[0].replaceAll("/",", "):
											listQuestions[questionIndex].correct_option_converted.join(", ")
										:listQuestions&&
										listQuestions.length>0&&
										listQuestions[questionIndex].correct_option_converted&&listQuestions[questionIndex].correct_option_converted}</Col>
								</Row>
							</Card></Col>)
					:""
				}
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"&&listQuestions[questionIndex].matchAns!==null&&
					listQuestions[questionIndex].matchAns===false?
					<Col xs={24} sm={24} md={24} lg={24}>
						<Card title="Explanation" bordered={false} className="explanation testSection">
							{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].explanation?<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].explanation }}></div>:""}
						</Card>
					</Col>:""
				}
				{listQuestions&&listQuestions.length>0&&!listQuestions[questionIndex].user_rating&&listQuestions[questionIndex].checkAnswer==="true"&&(<Col span={24} className="">
					<span>The question was : </span>
					<Radio.Group 
						value={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].ratings} 
						buttonStyle="solid"
						onChange={handleRatings}>
						<Radio.Button value={1}>Easy</Radio.Button>
						<Radio.Button value={2}>Medium</Radio.Button>
						<Radio.Button value={3}>Difficult</Radio.Button>
					</Radio.Group>
				</Col>)}
				<Col xs={24} sm={24} md={24} lg={24} className="justify-between test-btn-container">
					<Space size={'middle'} wrap>
						{questionIndex>0&&
						<Button className="btnDb min-width-100" onClick={()=>{handlePreviousQuestion();}} disabled={nextAndPreviousLoading} ><LeftOutlined/>{constants.Previous}</Button>}
						{listQuestions&&questionIndex < listQuestions.length - 1&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"&&
						(<Button className="btnDb min-width-100" onClick={()=>{handleNextQuestion();}} disabled={nextAndPreviousLoading}>{constants.Next}<RightOutlined/></Button>
						)}
						{/* {listQuestions&&questionIndex === listQuestions.length - 1&&listQuestions[questionIndex].checkAnswer&&listQuestions[questionIndex].checkAnswer==="true"&&!questionOfDayDetails&&(
							<Button className="btnDb min-width-100" onClick={()=>{saveNotes();completeTestApi();}} loading={completeTestLoading}>{constants.SubmitBTN}</Button>
						)} */}
						{listQuestions&&
						listQuestions.length>0&&
						questionIndex === listQuestions.length - 1&&
						listQuestions[questionIndex].checkAnswer&&
						listQuestions[questionIndex].checkAnswer==="true"?
							categoryDetails&&categoryDetails.type==="tutor"||topicDetails&&topicDetails.type==="tutor"||customDetails&&customDetails.type==="tutor"||
							markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true?(
									<>
										<Button className="btnDb min-width-100" onClick={()=>{saveNotes();completeTestApi();}} loading={completeTestLoading}>
											{constants.ReturnToTutorials}
										</Button>
										<span>You answered {listQuestions.filter(x => x.matchAns === true).length}/{listQuestions.length} questions correctly</span>
									</>
								):null
							:null}
						{listQuestions&&listQuestions.length>0&&questionOfDayDetails&&questionOfDayDetails.type==="qod"&&listQuestions[questionIndex].checkAnswer==="true"&&(
							<Button className="btnDb min-width-100" onClick={()=>{saveQuestionOfTheDay("CLOSE");}} loading={completeTestLoading}>Close</Button>
						)}
					</Space>
				</Col>
			</Row>
			<Row>
				<Col span={24} style={{ visibility:"hidden" }}>
					<b>QOD Date and Time: </b>{listQuestions&&listQuestions.length>0&&listQuestions[0].current_date_time?listQuestions[0].current_date_time:""}
				</Col>
			</Row>
			<Row>
				{listQuestions&&listQuestions.length>0?
					displayQuestionDetails(listQuestions,questionIndex)
					:""}
			</Row>
			<Row>
				<Col span={24} style={{ visibility:"hidden" }}>
					<b>Server time:</b> {serverTime?serverTime:""}{"           "}
					<b>Browser time:</b> {browserTime?browserTime:""}
				</Col>
			</Row>
			<Modal
				title=
					{
						<div
							style={{
								width: '100%',
								cursor: 'move', 
							}}
							onMouseOver={() => {
								if (disabled) {
									setIsDisabled(false);
								}
							}}
							onMouseOut={() => {
								setIsDisabled(true);
							}}onFocus={() => {}}
							onBlur={() => {}}
						>
								Notes
						</div>
					}
				visible={notesVisible}
				onOk={hideModal}
				onCancel={hideModal}
				className="notes-modal"
				footer={null						}
				modalRender={modal => (
					<Draggables
						disabled={disabled}
						bounds={bounds}
						onStart={(event, uiData) => onStart(event, uiData)}
					>
						<div ref={draggleRef}>{modal}</div>
					</Draggables>
				)}
			>
				<Form form={noteForm} onFinish={createNote}>
					<Form.Item name="note">
						<TextArea 
							rows={4} 
							placeholder=" Write a note"
							className="p-0"
							onPaste={(e)=>{
								e.preventDefault();
								return false;
							}} onCopy={(e)=>{
								e.preventDefault();
								return false;
							}}
						/>
					</Form.Item>
					<Form.Item className="mb-0">
						<Button className="btn-submit min-width-100"  htmlType="submit"><SaveOutlined/> Save</Button>
					</Form.Item>
				</Form>
			</Modal>
			{/* Quit test modal popup */}
			<Modal visible={pauseTest} 
				title={"Quit Test"}
				closable={false}
				footer={[
					<Button key="" className="navy-btn" onClick={()=>{quitTestAnswer();}} loading={quitTestLoading}>
						Quit
					</Button>,
					<Button key="" className="navy-btn" onClick={()=>{setPauseTest(false);}}>
						Cancel
					</Button>,
				]}
			>
				<span>
					{constants.QuitTest}
				</span>
			</Modal>
			{categoryDetails&&categoryDetails.type==="tutor"||topicDetails&&topicDetails.type==="tutor"||customDetails&&customDetails.type==="tutor"||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isMarkedLater===true||markForLaterAndAnswerIncorrectDetails&&markForLaterAndAnswerIncorrectDetails.isAnsIncorrect===true?
				Prompt
				:null}
		</div>
	);
};
export default CategoryTutorial;
