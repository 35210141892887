import React,{ useEffect,useState } from 'react';
import { Form, Breadcrumb, Row, Col, Card,Select,Button, Space,message  } from "antd";
import { fetchAdminApi } from "../../../services/api";
import constants from "../../../constants/constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../styles/flashcard.css";
import { useHistory } from "react-router";
// import "../../styles/flashcard.css";
// import { useQuill } from "react-quilljs";
// import "quill/dist/quill.snow.css";
const EditFlashcard =(props) => {
	// const inputElement = useRef();
	// const { Meta } = Card;
	const [form] = Form.useForm();
	const history = useHistory();
	const [verify, setVerify] = useState(false);
	const [saveButton, setSaveButton] = useState(false);
	const { Option } = Select;
	const [isFlipped,setisFlipped] = useState(false);
	const [preview, setPreview] = useState(true);
	const [flashcardType,setFlashcardType] = useState(props && props.history && props.history.location && props.history.location.state && props.history.location.state.flashcardType ? props.history.location.state.flashcardType :sessionStorage.getItem("flashcardType"));
	const [flashcardPayload] = useState(props && props.history && props.history.location && props.history.location.state && props.history.location.state.record ? props.history.location.state.record :JSON.parse(sessionStorage.getItem("editflashcard")));
	// const [Title] = useState(props && props.history && props.history.location && props.history.location.state && props.history.location.state.flashcardTitle ? props.history.location.state.flashcardTitle :sessionStorage.getItem("flashcardTitle"));
	const [payload] = useState(JSON.parse(sessionStorage.getItem("flashcardPayloads")));
	useEffect(() => {
		fetchChapterList();
		fetchTopicList();
		// setFormFieldsValue();
		if (props && props.history && props.history.location && props.history.location.state) {
			sessionStorage.setItem("editflashcard", JSON.stringify(props.history.location.state.record));
			sessionStorage.setItem("flashcardType",(props.history.location.state && props.history.location.state.flashcardType));
			// sessionStorage.setItem("flashcardTitle", JSON.stringify(props.history.location.state.flashcardTitle));
		}
	}, []);
	const handleClick=()=>{
		setisFlipped(!isFlipped);
	};
	// const onFinishVerify = (record) =>{
	// 	let payload = null;
	// 	payload = {
	// 		type_id:flashcardType,
	// 		chapters:record&&record.Chapter,
	// 		question:record&&record.question,
	// 		answer:record&&record.answer,
	// 		is_deleted:false,
	// 		// normal_submit:0,
	// 		categories:record&&record.category,
	// 		topics:record&&record.topic,
	// 		verify:1
	// 	};
	// 	fetchAdminApi(`/edit-flashcard/${flashcardType}/${flashcardPayload && flashcardPayload.Id}`, "put", payload).then(
	// 		(res) => {
	// 			if (res && res.code) {
	// 				if (res.code === 200) {
	// 					message.success(res && res.message);
	// 					history.push("/list-flashcard-chapters");
	// 				} else {
	// 					message.error(res && res.message);
	// 				}
	// 			} else {
	// 				message.error("Failed to edit");
	// 			}
	// 		}
	// 	);
	// };
	const onFinishEdit = (record) =>{
		setSaveButton(true);
		let payload = null;
		let editPayload = {
			type_id:flashcardType,
			chapters:record&&record.Chapter,
			question:record&&record.question,
			answer:record&&record.answer,
			is_deleted:false,
			normal_submit:1,
			categories:record&&record.category,
			topics:record&&record.topic
		};
		let verifyPayload = {
			type_id:flashcardType,
			chapters:record&&record.Chapter,
			question:record&&record.question,
			answer:record&&record.answer,
			is_deleted:false,
			// normal_submit:0,
			categories:record&&record.category,
			topics:record&&record.topic,
			verify:1
		};
		if(verify === true){
			payload = verifyPayload;
		}else{
			payload = editPayload;
		}
		fetchAdminApi(`/edit-flashcard/${flashcardType}/${flashcardPayload && flashcardPayload.Id}`, "put", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.message);
						history.push("/list-flashcard-chapters");
					} else {
						message.error(res && res.message);
						setSaveButton(false);
					}
				} else {
					message.error(res && res.message);
				}
			}
		);
	};
	const onFinishFailed = () =>{
	};
	const [chapterList,setChapterList]=useState([]);
	const [topicList,setTopicList]=useState([]);
	const [userInfo, setuserInfo] = useState({ description: '' });
	const ondescription = (value) => {
		console.log("onchange",value);
		setuserInfo({ 
			...userInfo,
			description:value
		});
	};
	// const { quill, quillRef } = useQuill();
	// console.log("quill",quill);
	// const [editor, setEditor] = useState(reactQuillRef);
	// const handleChange = (value) => {
	// 	console.log("value", value);
	// 	setEditor(value);
	// };
	// const unprivilegedEditor = reactQuillRef.makeUnprivilegedEditor();
	// unprivilegedEditor.getContents();
	// const reactQuillRef = null;
	// const editor = reactQuillRef.getEditor();
	// const unprivilegedEditor = reactQuillRef.makeUnprivilegedEditor(editor);
	// // You may now use the unprivilegedEditor proxy methods
	// unprivilegedEditor.getContents();
	// const handleChange= (html)=> {
	// 	console.log("html",html);
	// 	setText(html);
	// };
	const modules = {
		toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline','strike', 'blockquote'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, ],
			['link', ],
		],
	};
	const formats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet',
		'link',
	];
	//To fetch the chapter list
	const fetchChapterList = async() => {
		fetchAdminApi(`/flashcards-list/0`, "get").then(
			(res) => {
				if (res && res.data) {
					setChapterList(res.data);
				} else {
					setChapterList([]);
				}
			}
		);
	};
	//To fetch the topic list
	const fetchTopicList = async() => {
		fetchAdminApi(`/flashcards-list/1`, "get").then(
			(res) => {
				if (res && res.data) {
					setTopicList(res.data);
					setFormFieldsValue();
				} else {
					setTopicList([]);
				}
			}
		);
	};
	const setFormFieldsValue = () =>{
		setFlashcardType(props && props.history && props.history.location && props.history.location.state && props.history.location.state.flashcardType ? props.history.location.state.flashcardType :sessionStorage.getItem("flashcardType"));
		let categoryId = null;
		if(flashcardPayload && flashcardPayload.Category == "FPP"){
			categoryId = 1;
		}else if(flashcardPayload && flashcardPayload.Category == "SAED"){
			categoryId = 2;
		}else if (flashcardPayload && flashcardPayload.Category  =="PIET"){
			categoryId = 3;
		}else{
			categoryId = null;
		}
		form.setFieldsValue({
			Chapter:flashcardPayload && flashcardPayload.chapter_id ? flashcardPayload.chapter_id: payload && payload.chapterId,
			topic: flashcardPayload && flashcardPayload.topic_id ? flashcardPayload.topic_id:payload && payload.topicId,
			category:categoryId,
			question:flashcardPayload && flashcardPayload.question,
			answer:flashcardPayload && flashcardPayload.answer
		});
	};
	return (
		<div className="div">
			<div className="p-3" >
				<Breadcrumb>
					<Breadcrumb.Item ><a onClick={() => {
						history.push({ pathname: "/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/masters-menu" });
					}}>Masters</a></Breadcrumb.Item>
					<Breadcrumb.Item ><a onClick={() => {
						history.push({ pathname: "/flashcard-list-chapter" });
					}}>{constants.FlashcardchapterTitle}</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick={() => {
						history.push({ pathname: "/list-flashcard-chapters" });
					}}>Flashcard Listing</a></Breadcrumb.Item>
					<Breadcrumb.Item>Edit Flashcard</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<Row gutter={[24, 24]}>
				<Col xs={24} sm={24} md={24} lg={24}>
					{/* <Card size="default" title={<Meta title={<span className="text-uppercase">{constants.FlashcardchapterTitle}</span>} description={<h2 style={{ fontSize: "15px" }}>{chapterTitle}</h2>}/>} extra={ */}
					<Card size="small" title={`Edit Flashcard - ${flashcardType === 0||flashcardType === "0"? "TEXTBOOK":"TOPICS"} `} >
						<Form form={form}  layout="vertical" onFinish={onFinishEdit } onFinishFailed={onFinishFailed}>
							{flashcardType === 0 ||flashcardType === "0"?
								<Form.Item
									label="Chapter"
									name="Chapter"
									rules={[
										{ required: true, },
									]}
								>
									<Select
										showSearch
										placeholder="Select Chapter"
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
													option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									>
										{chapterList &&
											chapterList.map((chapter) => (
												<Option key={chapter.chapter_id} value={chapter.chapter_id}>
													{chapter.chapters}
												</Option>
											))}
									</Select>
								</Form.Item>
								:
								<Form.Item
									label="Topic"
									name="topic"
									rules={[
										{ required: true, },
									]}
								>
									<Select
										showSearch
										placeholder="Select topic"
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									>
										{topicList &&
												topicList.map((topic) => (
													<Option key={topic.topic_id} value={topic.topic_id}>
														{topic.Topics}
													</Option>
												))}
									</Select>
								</Form.Item>
							}
							<Form.Item
								label="Category"
								name="category"
							>
								<Select
									showSearch
									placeholder="Select category"
									filterOption={(input, option) =>
										option.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0 ||
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{constants.CategoryList &&
														constants.CategoryList.map((category) => (
															<Option key={category.category_id} value={category.category_id}>
																{category.category_name}
															</Option>
														))}
								</Select>
							</Form.Item>
							{ flashcardType === 0||flashcardType === "0" ?
								<Form.Item
									label="Topic"
									name="topic"
								>
									<Select
										showSearch
										placeholder="Select topic"
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
													option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									>
										{topicList &&
														topicList.map((topic) => (
															<Option key={topic.topic_id} value={topic.topic_id}>
																{topic.Topics}
															</Option>
														))}
									</Select>
								</Form.Item>:
								<Form.Item
									label="Chapter"
									name="Chapter"
								>
									<Select
										showSearch
										placeholder="Select Chapter"
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
													option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									>
										{chapterList &&
											chapterList.map((chapter) => (
												<Option key={chapter.chapter_id} value={chapter.chapter_id}>
													{chapter.chapters}
												</Option>
											))}
									</Select>
								</Form.Item>
							}
							<Form.Item
								label="Question"
								name="question"
								initialValue = ""
								rules={[
									{
										required: true,
										message: constants.QuestionRequired,
									},
								]}
							>
								<ReactQuill
									className = "react-qill-editor"
									theme="snow"
									name="answer"
									value={userInfo.description || ''}
									onChange={ondescription}
									placeholder={""}
									modules={modules}
									formats={formats}
								/>
								{/* <br/> */}
								{/* <div style={{ 
									width: "100%", 
									height: 300 
								}}>
									<div ref={quillRef}/>
								</div> */}
							</Form.Item>
							<Form.Item
								label="Answer"
								name="answer"
								initialValue = ""
								rules={[
									{
										required: true,
										message: constants.AnswerRequired,
									},
								]}
							>
								<ReactQuill
									className = "react-qill-editor"
									theme="snow"
									name="answer"
									value={userInfo.description || ''}
									onChange={ondescription}
									placeholder={""}
									modules={modules}
									formats={formats}
								/>
							</Form.Item>
							<Row>
								<Col span = {12}>
									<Button className="edit-butoon" onClick = {() => {setPreview(!preview);}}>Preview</Button>
								</Col>
								<Col span = {12}>
									<div className="text-right">
										<Space>
											<Form.Item>
												<Button htmlType="submit" onClick = {()=>{setVerify(true);}} className = "edit-butoon">Verify</Button>
											</Form.Item>
											<Form.Item>
												<Button htmlType="submit" className="btn-submit" disabled = {saveButton}>Save</Button>
											</Form.Item>
											<Form.Item>
												<Button onClick = {()=>{history.push("/list-flashcard-chapters");}}>Cancel</Button>
											</Form.Item>										
										</Space>
									</div>
								</Col>
							</Row>
							<div className = "flashcards ant-card-flashcard">
								{preview &&(
									isFlipped===false?
										<Card
											title="Q" 
											onClick={handleClick}
											size="small"
											// className="admin-flashcard-items-center"
											className ="flashcard-items-center"
											extra = {
												<span style = {{ fontSize : "25px" }}>#{flashcardPayload && flashcardPayload.Id}</span>
											}
										>
											{/* <span style = {{ 
												fontSize : "25px",
												fontFamily: "-webkit-body"
											}}>{flashcardPayload && flashcardPayload.question}</span>	 */}
											<div
												dangerouslySetInnerHTML={{ __html: flashcardPayload && flashcardPayload.question }}
											/>
											<div className = "div-bottom"><span style = {{ fontSize:"medium" }}><p>{flashcardPayload && flashcardPayload.Category}-{flashcardPayload&&flashcardPayload.Topic?flashcardPayload.Topic:payload && payload.title}</p><p>{payload && payload.type == 0 ? payload.title: flashcardPayload && flashcardPayload.Chapter}</p></span></div>
											{/* <Meta className ="" description= {<span style = {{ fontSize:"medium" }}><p>{flashcardPayload && flashcardPayload.Category}-{flashcardPayload&&flashcardPayload.Topic?flashcardPayload.Topic:payload && payload.title}</p><p>{payload && payload.type == 0 ? payload.title: flashcardPayload && flashcardPayload.Chapter}</p></span>}/>													 */}
										</Card>
										:
										<Card 
											title="A" 
											onClick={handleClick}
											size="small"
											className ="flashcard-items-center"
										>	
											{/* <span style = {{ 
												fontSize : "25px",
												fontFamily: "-webkit-body"
											}}>{flashcardPayload && flashcardPayload.answer}</span>			 */}
											<div
												dangerouslySetInnerHTML={{ __html: flashcardPayload && flashcardPayload.answer }}
											/>										
										</Card>			
								)}
							</div>
						</Form>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default EditFlashcard;