/*
File Name: CustomTutorial.js
Author: Mevito Gonsalves
Modified On: 17/12/2021
Description: Selecting Category/Topics to start a tutorial test.
API's used:
1) /tutorials/history?type=topic&student_id=${studentId}
2) /tutorials/custom
*/
import React, { useState , useEffect } from 'react';
import { Table, Button,  Col, Row, Tag, List, Tooltip  } from 'antd';
import '../../styles/test.css';
import constants from '../../constants/constants';
import { fetchApi } from "../../services/api";
import{ COOKIE,getCookie } from "../../services/cookie";
import { useHistory } from "react-router";
const CustomTutorial = () => {
	const history = useHistory();  
	const [customTutorialData, setCustomTutorialData] = useState([]);
	const [ffpData,setFppData]=useState([]);
	const [pietData,setPietData]=useState([]);
	const [saedData,setSaeddata]=useState([]);
	const [testAttempt,setTestAttempt]=useState(1);
	const [startTestDisable,setStartTestDisable]=useState(true);
	const [fppQuestionCount,setFppQuestionCount]=useState(0);
	const [pietQuestionCount,setPietQuestionCount]=useState(0);
	const [saedQuestionCount,setSaedQuestionCount]=useState(0);
	const [finalTopicIds,setFinalTopicIds]=useState([]);
	const [finalCategoryIds,setFinalCategoryIds]=useState([]);
	const [fppTopicIds,setFppTopicIds]=useState([]);
	const [pietTopicIds,setPietTopicIds]=useState([]);
	const [saedTopicIds,setSaedTopicIds]=useState([]);
	const [fppCategoryIds,setFppCategoryIds]=useState([]);
	const [pietCategoryIds,setPietCategoryIds]=useState([]);
	const [saedCategoryIds,setSaedCategoryIds]=useState([]);
	const userId = getCookie(COOKIE.UserId);	
	const data= [
		{ "tutorial": null },
		{ "tutorial": null },
		{ "tutorial": null }
	];
	const columnsFpp = [
		{
			title: [constants.CategoryFPP, <span key={constants.CategoryFPP}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>],
			dataIndex:"topic",
			key:"topic"
		},
	];
	const columnsPiet = [
		{
			title: constants.CategoryPIET,
			dataIndex:"topic",
			key:"topic"
		},	
	];
	const columnsSaed = [
		{
			title: constants.CategorySAED,
			dataIndex:"topic",
			key:"topic"
		},	
	];
	useEffect(() => {
		fetchTopicQuestionList();
		fetchCustomTutorialData();
	}, []);
	//To fetch tutorials attempted data
	const fetchCustomTutorialData = async() => {
		fetchApi(`/tutorials/history?type=custom&student_id=${userId}`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setCustomTutorialData(res.data);
				let testTempAttempt=res.data.filter(item=>item.tutorial!==null);
				setTestAttempt(testTempAttempt.length+1);
			} else {
				setCustomTutorialData(data);
			}
		});
	};	
	//To redirect and start test
	const onStartTest=()=>{
		let tempFinalTopisIds=Array.from(new Set(finalTopicIds));
		let customDetails={};
		customDetails.attempt=testAttempt;
		customDetails.data={
			category:finalCategoryIds,
			topic:tempFinalTopisIds
		};
		customDetails.type="tutor";
		customDetails.isTimed=false;
		history.push({
			pathname:"/custom-tutorial",
			state:{ customDetails:customDetails } 
		});
	};
	//To fetch topics with remaining attempts 
	const fetchTopicQuestionList = async() => {
		fetchApi(`/tutorials/custom?student_id=${userId}`, "get").then((res) => {
			if (res && res.data ) {
				setFppData(res.data.FPP);
				setSaeddata(res.data.SAED);
				setPietData(res.data.PIET);
			} else {
				setFppData([]);
				setPietData([]);
				setSaeddata([]);
			}
		});
	};
	const rowSelection = {
		onChange: (_selectedRowKeys, selectedRows) => {
			let fppQuestions=0;
			let tempTopicIds=[];
			selectedRows.map((item)=>{
				if(item.topicId!==null){
					tempTopicIds=[...tempTopicIds,item.topic_id];
				}
			});
			fppQuestions=selectedRows.length;
			setFppTopicIds(tempTopicIds);
			setFppCategoryIds(selectedRows.length>0?[selectedRows[0].category_id]:[]);
			let otherTopicIds=[];
			let totalTopicIds=[];
			otherTopicIds=[...pietTopicIds,...saedTopicIds];
			totalTopicIds=[...tempTopicIds,...otherTopicIds];
			setFinalTopicIds(totalTopicIds);
			let otherCategoryIds=[];
			let totalCategoryIds=[];
			let tempCategoryIds=selectedRows.length>0?[selectedRows[0].category_id]:[];
			otherCategoryIds=[...pietCategoryIds,...saedCategoryIds];
			totalCategoryIds=[...tempCategoryIds,...otherCategoryIds];
			setFinalCategoryIds(totalCategoryIds);
			setFppQuestionCount(fppQuestions);
			let total=0;
			total=fppQuestions+pietQuestionCount+saedQuestionCount;
			setStartTestDisable(total>constants.number_custom_questions_required?false:true);
		},
	};
	const rowSelectionpiet = {
		onChange: (_selectedRowKeys, selectedRows) => {
			let pietQuestions=0;
			let tempTopicIdsPiet=[];
			selectedRows.map((item)=>{
				if(item.topicId!==null){
					tempTopicIdsPiet=[...tempTopicIdsPiet,item.topic_id];
				}
			});
			pietQuestions=selectedRows.length;
			setPietTopicIds(tempTopicIdsPiet);
			setPietCategoryIds(selectedRows.length>0?[selectedRows[0].category_id]:[]);
			let otherTopicIds=[];
			let totalTopicIds=[];
			otherTopicIds=[...fppTopicIds,...saedTopicIds];
			totalTopicIds=[...tempTopicIdsPiet,...otherTopicIds];
			setFinalTopicIds(totalTopicIds);
			let otherCategoryIds=[];
			let totalCategoryIds=[];
			let tempCategoryIds=selectedRows.length>0?[selectedRows[0].category_id]:[];
			otherCategoryIds=[...fppCategoryIds,...saedCategoryIds];
			totalCategoryIds=[...tempCategoryIds,...otherCategoryIds];
			setFinalCategoryIds(totalCategoryIds);
			setPietQuestionCount(pietQuestions);
			let total=0;
			total=fppQuestionCount+pietQuestions+saedQuestionCount;
			setStartTestDisable(total>constants.number_custom_questions_required?false:true);
		},
	};
	const rowSelectionsaed = {
		onChange: (_selectedRowKeys, selectedRows) => {
			let saedQuestions=0;
			let tempTopicIdsSaed=[];
			selectedRows.map((item)=>{
				if(item.topicId!==null){
					tempTopicIdsSaed=[...tempTopicIdsSaed,item.topic_id];
				}
			});
			saedQuestions=selectedRows.length;
			setSaedTopicIds(tempTopicIdsSaed);
			setSaedCategoryIds(selectedRows.length>0?[selectedRows[0].category_id]:[]);
			let otherTopicIds=[];
			let totalTopicIds=[];
			otherTopicIds=[...fppTopicIds,...pietTopicIds];
			totalTopicIds=[...tempTopicIdsSaed,...otherTopicIds];
			setFinalTopicIds(totalTopicIds);
			let otherCategoryIds=[];
			let totalCategoryIds=[];
			let tempCategoryIds=selectedRows.length>0?[selectedRows[0].category_id]:[];
			otherCategoryIds=[...fppCategoryIds,...pietCategoryIds];
			totalCategoryIds=[...tempCategoryIds,...otherCategoryIds];
			setFinalCategoryIds(totalCategoryIds);
			setSaedQuestionCount(saedQuestions);
			let total=0;
			total=fppQuestionCount+pietQuestionCount+saedQuestions;
			setStartTestDisable(total>constants.number_custom_questions_required?false:true);
		},
	};
	const handleReviewTutorial=(tutorial)=>{
		let reviewTutorial={};
		reviewTutorial.scoreCardId=tutorial.scorecard_id;
		reviewTutorial.review="tutorial";
		reviewTutorial.type="custom";
		history.push({
			pathname:"/review-incorrect",
			state:{ reviewTutorial:reviewTutorial } 
		});
	};
	return (
		<div className="p-4 custom-tutorial-container">
			{customTutorialData&&customTutorialData.length>0?
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24 
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 24 
				}]}>
					<Col xs={24} sm={24} md={24} lg={24}>
						<h3 className="mainHeading">Custom Tutorials</h3>
						<p className="subHeading">{constants.custom_tutorial_subheading}</p>
						<p className="subHeading">{constants.custom_tutorial_instructions}</p>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24}>
						<div className="tutorial-info">
							<div>
								<List
									dataSource={customTutorialData}
									renderItem={(item,index) => (
										<List.Item>
											{item.tutorial!==null?
												<p><Tag color="var(--darkblue)">Tutorial {index+1}</Tag> 
													{item&&item.tutorial&&item.tutorial.points!==null?<>
												Attempted on <Tooltip title={item&&item.tutorial&&item.tutorial.points?item&&item.tutorial&&item.tutorial.points+(item.tutorial.points>1?" points earned":" point earned"):""}>{item.tutorial.date}.</Tooltip>
														<Button type="link" onClick={()=>{handleReviewTutorial(item.tutorial);}}>{constants.ViewAgain}</Button>
													</>:<span className="orange px-3">{constants.InProgress}</span>}</p>
												:
												<p><Tag color="var(--darkblue)">Tutorial {index+1}</Tag> Available. Select Category/Topics to start.</p>
											}
										</List.Item>
									)}
								/>
							</div>
						</div>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24}>
						{/* <Space direction="vertical" size="small" className="custom-radio-wrapper">
						<div className="custom-radio-group">
							<span className="pr-3">Questions to be included :</span>
							<Radio.Group onChange={OnQustionTypeChange}>
								<Radio value={1}>New</Radio>
								<Radio value={2}>Attempted</Radio>
								<Radio value={3}>Answered incorrectly</Radio>
								<Radio value={4}>New & Answered incorrectly</Radio>
								<Radio value={5}>Any</Radio>
							</Radio.Group>
						</div>
						{attemptValue?
							<div className="custom-radio-group">
								<span className="pr-3">Number of times previously attempted :</span>
								<Radio.Group>
									<Radio value={1}>1</Radio>
									<Radio value={2}>2</Radio>
									<Radio value={3}>3</Radio>
									<Radio value={4}>4</Radio>
									<Radio value={5}>5 or more</Radio>
								</Radio.Group>
							</div>
							:null}
					</Space> */}
					</Col>
					<Col xs={24} sm={24} md={24} lg={24}>
						<Row gutter={[{
							xs: 0,
							sm: 0,
							md: 16,
							lg: 16 
						}, {
							xs: 8,
							sm: 16,
							md: 16,
							lg: 32 
						}]} className="w-100 custom-table-container">
							{testAttempt>constants.number_custom_tests?null:
								<>
									<Col span={24} lg={8}>
										<Table 
											columns={columnsFpp} 
											dataSource={ffpData} 
											bordered
											className="customTable custom-tutorial grey-custom-table "
											pagination={false}
											rowSelection={{
												type: 'checkbox',
												...rowSelection,
											}}
										/>
									</Col>
									<Col span={24} lg={8}>
										<Table 
											columns={columnsSaed} 
											dataSource={saedData} 
											bordered
											className="customTable custom-tutorial grey-custom-table "
											pagination={false}
											rowSelection={{
												type: 'checkbox',
												...rowSelectionsaed,
											}}
										/>
									</Col>
									<Col span={24} lg={8}>
										<Table 
											columns={columnsPiet} 
											dataSource={pietData} 
											bordered
											className="customTable custom-tutorial grey-custom-table "
											pagination={false}
											rowSelection={{
												type: 'checkbox',
												...rowSelectionpiet,
											}}
										/>
									</Col>
								</>
							}
						</Row>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24}>
						<div className="text-center">
							{testAttempt>constants.number_custom_tests?null:
								<Button className="btnDb" onClick={onStartTest} disabled={startTestDisable}>Start</Button>}
						</div>
					</Col>
				</Row>
				:null}
		</div>
	); 
};
export default CustomTutorial;
