import React, { useState, useEffect } from 'react';
import { fetchAdminApi } from "../../../services/api";
import { Breadcrumb, Row, Col, Card, Form, DatePicker, Space, Table, Select } from 'antd';
import { Chart } from "react-google-charts";
import { BankOutlined } from '@ant-design/icons';
import moment from "moment";
import { useHistory } from "react-router";
import constants from '../../../constants/constants';
import { registrationFilter } from "../../../globalFunctions/GlobalFunctions";
const { Option } = Select;
const RegistrationByUniversity = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [endDate, setEndDate] = useState(props && props.history && props.history.location && props.history.location.state ?props.history.location.state.ed:"");
	const [startDate, setStartDate] = useState(props && props.history && props.history.location && props.history.location.state ?props.history.location.state.sd:"");
	const [tableData, setTableData] = useState([]);
	const [graphData, setGraphData] = useState([]);
	const [studentCount, setStudentCount] = useState(null);
	const [professionalCount, setProfessionalCount] = useState(null);
	const [professorsCount, setProfessorsCount] = useState(null);
	const [othersCount, setOthersCount] = useState(null);
	const [totalCount, setTotalCount] = useState(null);
	const [filterValue, setfilterValue] = useState("");
	const payload = {
		sd:startDate,
		ed:endDate,
		more:1,
		filterValue: filterValue ? filterValue : "0"
	};
	const [sortedInfo, setsortedInfo] = useState({
		order: 'ascend',
		columnKey: 'srNumber',
	});
	const handleChange = (_pagination, _filters, sorter) => {
		setsortedInfo(sorter);
	};
	useEffect(() => {
		fetchRegistrationByUniversity();
		sessionStorage.removeItem("more");
	}, [startDate, endDate]);
	useEffect(() => {
		if(props && props.history && props.history.location && props.history.location.state){
			form.setFieldsValue({
				fromDate:props.history.location.state.sd?moment(props.history.location.state.sd):moment(constants.startDateOfTheYear),
				toDate:props.history.location.state.ed?moment(props.history.location.state.ed):moment(constants.endDateOfTheYear)
			});
		}
		// return () => {
		// 	if (history.action === "POP") {
		// 		history.push("/admin-dashboard");
		// 	}
		// };
	}, [history]);
	const fetchRegistrationByUniversity = () => {
		const sd = moment(startDate).format("YYYY-MM-DD");
		const ed = moment(endDate).format("YYYY-MM-DD");
		const selectedDate = `/students-by-university?start_date=${sd}&end_date=${ed}&limit=10`;
		const year = "/students-by-university?limit=10";
		fetchAdminApi(startDate && endDate  ? selectedDate : year, "get").then((res) => {
			if (res && res.data && res.data !== null && res.data !== undefined) {
				const data = res.data;
				const studentsCount = data.map(item => parseInt(item.student_count)).reduce((prev, curr) => prev + curr, 0);
				setStudentCount(studentsCount);
				const professionalsCount = data.map(item => parseInt(item.professional_count)).reduce((prev, curr) => prev + curr, 0);
				setProfessionalCount(professionalsCount);
				const professorCount = data.map(item => parseInt(item.faculty_count)).reduce((prev, curr) => prev + curr, 0);
				setProfessorsCount(professorCount);
				const otherCount = data.map(item => parseInt(item.other_count)).reduce((prev, curr) => prev + curr, 0);
				setOthersCount(otherCount);
				const totalCounts = data.map(item => parseInt(item.total_count)).reduce((prev, curr) => prev + curr, 0);
				setTotalCount(totalCounts);
				setTableData(data);
				//data to display in graph.
				let gfHeader = [];
				gfHeader.push(["Universities", "Students", "Professionals", "Faculty", "Others"]);
				data.map(obj => gfHeader.push([obj.university_name, parseInt(obj.student_count), parseInt(obj.professional_count), parseInt(obj.faculty_count), parseInt(obj.other_count)]));
				setGraphData(gfHeader);
			} else {
				setTableData([]);
				setStudentCount(null);
				setProfessionalCount(null);
				setProfessorsCount(null);
				setOthersCount(null);
				setTotalCount(null);
				setGraphData([]);
				let dat = [];
				dat.push(["Universities", "Students", "Professionals", "Professors", "Others"]);
				dat.push(['', 0, 0, 0, 0]);
				setGraphData(dat);
			}
		});
	};
	const from = (_date, dateString) => {
		setStartDate(dateString);
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const registedStudent = (record,type) =>{
		let role = "";
		if(type ===0){
			role = 'Student,';
		}else if ( type === 1){
			role = "Professional,";
		}else if ( type === 2){
			role = "Facultie,";
		}else if ( type === 3){
			role = "Other,";
		}
		let payloads = {};
		payloads.sd = startDate ? moment(startDate).format("YYYY-MM-DD")  : constants.startDateOfTheYear;
		payloads.ed = endDate ? moment(endDate).format("YYYY-MM-DD") : constants.endDateOfTheYear;
		payloads.sded = startDate&&endDate ? 1 : 2;
		payloads.state_id = record.state_id;
		payloads.university_id = parseInt(record.university_id);
		// payloads.type = type;
		payloads.state = record.region_code;
		payloads.title = 'Top 10 Universities Registrations';
		payloads.push = '/registration-by-university';
		payloads.roleId = type.toString();
		payloads.university_name = record.university_name;
		payloads.role = role.slice(0, -1);
		payloads.country_id=constants.usCountryId;
		payloads.serchCriteria = `Role: ${role} University: ${record.university_name}, Country: ${constants.usCountryCode}, State: ${record.region_code}, ${`Registrations from ${moment(payloads.sd).format('MM/DD/YYYY')} to ${moment(payloads.ed).format('MM/DD/YYYY')}`}`;
		history.push({
			pathname: "/registered-students",
			state: payloads
		});
	};
	const column = [
		{
			title: "University",
			key: "university",
			dataIndex: "university_name",
			width: 200,
			sorter: (a, b) => a.university_name.localeCompare(b.university_name),
			sortOrder: sortedInfo.columnKey === 'university' && sortedInfo.order,
		},
		{
			title: "State",
			key: "state",
			dataIndex: "region_code",
			width: 50,
			align: "center",
			sorter: (a, b) => a.region_code.localeCompare(b.region_code),
			sortOrder: sortedInfo.columnKey === 'state' && sortedInfo.order,
		},
		{
			title: "Students",
			key: "students",
			dataIndex: "student_count",
			width: 100,
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				let occupation_type = 0;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative">{record.student_count}</span></a></span>;
			},
			sorter: (a, b) => a.student_count - b.student_count,
			sortOrder: sortedInfo.columnKey === 'students' && sortedInfo.order,
		},
		{
			title: "Professionals",
			key: "professionals",
			dataIndex: "professional_count",
			width: 100,
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				let occupation_type = 1;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative" style={{ width: "75px" }}>{record.professional_count}</span></a></span>;
			},
			sorter: (a, b) => a.professional_count - b.professional_count,
			sortOrder: sortedInfo.columnKey === 'professionals' && sortedInfo.order,
		},
		{
			title: "Faculty",
			key: "professors",
			dataIndex: "faculty_count",
			width: 100,
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				let occupation_type = 2;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative" style={{ width: "35px" }}>{record.faculty_count}</span></a></span>;
			},
			sorter: (a, b) => a.faculty_count - b.faculty_count,
			sortOrder: sortedInfo.columnKey === 'professors' && sortedInfo.order,
		},
		{
			title: "Other",
			key: "other",
			dataIndex: "other_count",
			width: 100,
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				let occupation_type = 3;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative" style={{ width: "35px" }}>{record.other_count}</span></a></span>;
			},
			sorter: (a, b) => a.other_count - b.other_count,
			sortOrder: sortedInfo.columnKey === 'other' && sortedInfo.order,
		},
		{
			title: "Total",
			key: "total",
			dataIndex: "total_count",
			align: "center",
			className: 'td-right',
			render: (_text, record) => {
				return <span className="p-relative" style={{ 
					width: "35px",
					color: "black"
				}}>{record.total_count}</span>;
			},
			width: 50,
			sorter: (a, b) => a.total_count - b.total_count,
			sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
		}
	];
	const graphOption = {
		title: `Top 10 Universities Registrations - ${startDate && endDate ? `${startDate} to ${endDate}` :`${constants.startDateOfTheYear} to ${constants?.endDateOfTheYear}`}`,
		isStacked: true,
		vAxis: {
			title: "",
			format: '#',
			minValue: 0,
		},
		legend: {
			position: 'top',
			maxLines: 3,
			alignment: 'start'
		},
		bars: "vertical",
		colors: ["#FF550A", "#0066BC", "#1B7C31", "#9B51E0"]
	};
	const onChangeDateFilter = (e) => {
		setfilterValue(e);
		registrationFilter(e,setStartDateEndDate);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			fromDate:moment(startDate),
			toDate:moment(endDate)
		});
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>{constants.Top10University}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase"> {<BankOutlined className ="icon-lg"/>}{" "}<span>{constants.Top10University}</span><a className = "text-lowercase" onClick={() => {
								history.push({ 
									pathname: "/registration-in-us-university" ,
									state : payload
								});
							}}> &nbsp;&nbsp;(More...)</a></span>} extra={
								<Form form = {form} layout="horizontal">
									<Space>
										<Form.Item className="label-default mb-0" label="From:" name = "fromDate">
											<DatePicker defaultValue ={moment(constants.startDateOfTheYear)} onChange={from} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0" label="To:" name="toDate">
											<DatePicker defaultValue = {moment(constants.endDateOfTheYear)} onChange={to} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
										</Form.Item>
										<Form.Item className="label-default mb-0">
											<Select defaultValue="0" style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
												<Option value="0">This Year</Option>
												<Option value="7">Last Year</Option>
												<Option value="1">This Month</Option>
												<Option value="2">Last Month</Option>
												<Option value="3">Last 3 Months</Option>
												<Option value="4">This Month (Last Year)</Option>
												<Option value="5">Last 3 Months (Last Year)</Option>
												<Option value="6">Previous Years</Option>
											</Select>
										</Form.Item>
									</Space>
								</Form>
							}>
								<Row>
									<Col xs={24} sm={24} md={24} lg={24}>
										{tableData.length > 0 &&(
											<Chart
												width="100%"
												height="400px"
												chartType="ColumnChart"
												data={graphData}
												options={graphOption}
											/>
										)}
									</Col>
									<Col xs={24} sm={24} md={24} lg={24}>
										<Table className="gap-table"
											onChange={handleChange}
											columns={column}
											dataSource={tableData}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													<Table.Summary.Row style = {{ fontWeight:500 }}>
														<Table.Summary.Cell index={0}><span>Total</span></Table.Summary.Cell>
														<Table.Summary.Cell index={1}><span></span></Table.Summary.Cell>
														<Table.Summary.Cell index={2} className='td-right' ><span className="p-relative">{studentCount}</span></Table.Summary.Cell>
														<Table.Summary.Cell index={3} className='td-right' ><span className="p-relative" style={{ width: "90px" }}>{professionalCount}</span></Table.Summary.Cell>
														<Table.Summary.Cell index={4} className='td-right' ><span className="p-relative" style={{ width: "30px" }}>{professorsCount}</span></Table.Summary.Cell>
														<Table.Summary.Cell index={5} className='td-right' ><span className="p-relative" style={{ width: "35px" }}>{othersCount}</span></Table.Summary.Cell>
														<Table.Summary.Cell index={6} className='td-right'><span className="p-relative" style={{ width: "35px" }}>{totalCount}</span></Table.Summary.Cell>
													</Table.Summary.Row>
												</Table.Summary>
											)}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>;
			</div>
		</>
	);
};
export default RegistrationByUniversity;