import { Col, Row, Button } from "antd";
import { UnorderedListOutlined, FileSearchOutlined, ToolOutlined, DeleteRowOutlined, FlagOutlined } from "@ant-design/icons";
import "../../styles/test.css";
import constants from "../../constants/constants";
import React, { useState, useEffect } from "react";
import { fetchApi } from "../../services/api";
import{ COOKIE,getCookie } from "../../services/cookie";
import { useHistory } from "react-router";
const SelectTutorial = () => {
	const history = useHistory();
	const [categoryTutorialRemaining,setCategoryTutorialRemaining]=useState(6);
	const [topicTutorialRemaining,setTopicTutorialRemaining]=useState(22);
	const [customTutorialRemaining,setcustomTutorialRemaining]=useState(3);
	// const [isTutorialProgress,setIsTutorialProgress]=useState(false);
	const [isTutorialProgressInCorrect,setIsTutorialProgressInCorrect]=useState(false);
	const [isTutorialProgressMFL,setIsTutorialProgressMFL]=useState(false);
	const [markLaterAndReviewIncorrectCount,setMarkLaterAndReviewIncorrectCount]=useState(null);
	const [apiCallwait,setApiCallWait]=useState(false);
	useEffect(() => {
		fetchTestHistory();
		fetchMarkLaterAndReviewIncorrect();
	}, []);
	//To fetch test history data
	const fetchTestHistory = () => {
		let userId = getCookie(COOKIE.UserId);
		setApiCallWait(true);
		fetchApi(`/tutorials/history?type=tutorial&student_id=${userId}`, "get").then((res) => {
			if (res && res.data) {
				setCategoryTutorialRemaining(res.data.category_test_remaining);
				setTopicTutorialRemaining(res.data.topic_test_remaining);
				setcustomTutorialRemaining(res.data.custom_test_remaining);
				// setIsTutorialProgress(res.data.in_progress);
				setIsTutorialProgressInCorrect(res.data.in_progress_incor);
				setIsTutorialProgressMFL(res.data.in_progress_mfl);
				setApiCallWait(false);
			}else{
				setApiCallWait(false);
			} 
		});
	};
	const fetchMarkLaterAndReviewIncorrect = () => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/tutorials/test-data?stud_id=${userId}`, "get").then((res) => {
			if (res && res.code&&res.code===200) {
				setMarkLaterAndReviewIncorrectCount(res.data);
			} 
		});
	};
	//Function to exceute on click of Mark for later or answer incorrect button and redirect to respective instruction page.
	const handleMarkForLaterOrAnswerIncorrect=(type)=>{
		if(type&&type==="MarkLater"){
			let markedForLaterCountt=markLaterAndReviewIncorrectCount&&markLaterAndReviewIncorrectCount.marked_for_later_count?markLaterAndReviewIncorrectCount.marked_for_later_count:0;
			sessionStorage.setItem("markedForLaterCount",markedForLaterCountt);
			sessionStorage.setItem("tutorialType","MarkForLater");
			sessionStorage.removeItem("incorrectAnswersCount");
		}
		else{
			let incorrectAnswersCountt=markLaterAndReviewIncorrectCount&&markLaterAndReviewIncorrectCount.ans_incorrect_count?markLaterAndReviewIncorrectCount.ans_incorrect_count:0;
			sessionStorage.removeItem("markedForLaterCount");
			sessionStorage.setItem("tutorialType","AnsIncorrect");
			sessionStorage.setItem("incorrectAnswersCount",incorrectAnswersCountt);
		}
		history.push("/tutorial-instructions");
	};
	return (
		<>
			<div className="p-4 container withp-footer-wrapper">
				<div className="select-tutorials">
					<h3 className="mainHeading">{constants.SelectTutorialHeading}</h3>
					<p className="subHeading">{constants.SelectTutorialSubheading}</p>
					<Row gutter={[32, 16]} justify="center" align="middle" className="select-tut-outer border-bottom" >
						<Col xs={24} lg={8}>
							<Button icon={ <UnorderedListOutlined/> } className="categories-list w-100" onClick={()=>{	history.push("/select-category-tutorial");}}>
								{constants.CategoryTutorials}
							</Button>
							{apiCallwait?null:
								<p className={categoryTutorialRemaining>1?"remainingtext text-center mt-1":categoryTutorialRemaining==0?"remainingtext text-center greyMsg mt-1":"remainingtext text-center errorMsg mt-1"}>({categoryTutorialRemaining} {categoryTutorialRemaining==1?constants.TutorialRemaining:constants.TutorialsRemaining})</p>
							}
						</Col>
						<Col xs={24} lg={8}>
							<Button icon={ <FileSearchOutlined/> } className="categories-list flex-vertical w-100" onClick={()=>{	history.push("/select-topic-tutorial");}}>
								{constants.TopicTutorials}
							</Button>
							{apiCallwait?null:
								<p className={topicTutorialRemaining>1?"remainingtext text-center mt-1":topicTutorialRemaining==0?"remainingtext text-center greyMsg mt-1":"remainingtext text-center errorMsg mt-1"}>({topicTutorialRemaining} {topicTutorialRemaining==1?constants.TutorialRemaining:constants.TutorialsRemaining})</p>
							}
						</Col>
						<Col xs={24} lg={8}>
							<Button icon={ <ToolOutlined/> }className="categories-list w-100" onClick={()=>{	history.push("/select-custom-tutorial");}}>
								{constants.CustomTutorials}
							</Button>
							{apiCallwait?null:
								<p className={customTutorialRemaining>1?"remainingtext text-center mt-1":customTutorialRemaining==0?"remainingtext text-center greyMsg mt-1":"remainingtext text-center errorMsg mt-1"}>({customTutorialRemaining} {customTutorialRemaining==1?constants.TutorialRemaining:constants.TutorialsRemaining})</p>
							}
						</Col>
					</Row>
					<Row gutter={[32, 16]} justify="center" align="middle" className="select-tut-outer" >
						<Col xs={24} lg={10} xl={8}>
							<Button icon={ <FlagOutlined/> } className="categories-list w-100" onClick={()=>{
								handleMarkForLaterOrAnswerIncorrect("MarkLater");
							}} disabled={isTutorialProgressMFL}>
								<span className="break-spaces">{constants.MarkForLater}</span>
							</Button>
							{apiCallwait?null:
								<p className="remainingtext text-center mt-1">
									{isTutorialProgressMFL?constants.InProgrssTutorial:constants.AsAvailable}
								</p>}
						</Col>
						<Col xs={24} lg={10} xl={8}>
							<Button icon={ <DeleteRowOutlined/> } className="categories-list flex-vertical w-100" onClick={()=>{
								handleMarkForLaterOrAnswerIncorrect("AnsIncorrect");
							}} disabled={isTutorialProgressInCorrect}>
								<span className="break-spaces">{constants.IncorrectAnswers}</span>
							</Button>
							{apiCallwait?null:
								<p className="remainingtext text-center mt-1">
									{isTutorialProgressInCorrect?constants.InProgrssTutorial:constants.AsAvailable}
								</p>}
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default SelectTutorial;
