/*
File Name: ShortLengthTest.js
Author: Akshay Lotlikar
Modified On: 10/03/2021
Description: This page includes the code to start a category test or a topic test.
API's used:
1) /student/start-test
2) /score_card/${scoreCardId}
*/
import React, { useState,useEffect } from "react";
import { Col, Row, Button, Card, Radio, Space, Tooltip, Divider, Input, Drawer, Table, Modal, message,Checkbox, Spin,Form,Badge, AutoComplete,Select  } from 'antd';
import { HighlightOutlined , StrikethroughOutlined, FlagOutlined, LeftOutlined, RightOutlined, EyeOutlined, EyeInvisibleOutlined, SaveOutlined,	DragOutlined, FlagFilled, ProfileOutlined, } from '@ant-design/icons';
import '../../styles/test.css';
import '../../styles/pausetest.css';
import constants from "../../constants/constants";
import Draggables from 'react-draggable';
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from "react-sortable-hoc";
import CustomIcons from "../../assets/CustomIcons";
import Icon from '@ant-design/icons';
import{ COOKIE,getCookie } from "../../services/cookie";
import { fetchApi } from "../../services/api";
import arrayMove from "array-move";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHistory } from "react-router";
import $ from "jquery";
import useUnsavedChangesWarning from "./Instructions/UnsavedChanges";
import { sortArrayOfObjects, searchQuestionTable, displayQuestionDetails, featuredImageUrl,testModalPopup } from "../../globalFunctions/GlobalFunctions";
import { cloneDeep } from "lodash";
import Loader from "../Common/Cred/Loader";
const Comment = (props) => (<Icon component={CustomIcons.getsvg("Comment")} {...props}/>);
const { Option } = Select;
const { TextArea } = Input;
const DragHandle = sortableHandle(() => (
	<span>
		<DragOutlined className='drag-icon drag-icon-size'/>
	</span>
));
const SortableItem = sortableElement(({ value,indexPos,handleSequence,MouseDown,MouseUp,TouchStart,TouchEnd  }) => (
	<ul className="test-sortable">
		<li className="flex-vertical-middle">
			<span className="seq-counter">{constants.OptionstoDisplay[indexPos]}</span>
			<DragHandle/>
			<p id={indexPos} onClick={()=>{handleSequence(indexPos);}} onMouseDown={(e)=>{MouseDown(e);}} onMouseUp={(e)=>{MouseUp(e,indexPos);}} onTouchStart={TouchStart} onTouchEnd={TouchEnd} className='pl-2 list-item' dangerouslySetInnerHTML={{ __html:value }}></p>
		</li>
	</ul>
));
//Drag area
const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});
const ShortLengthTest = (props) => {
	const history = useHistory();
	// eye icon toggle
	const [viewTimer,setViewTimer]=useState(false);
	const toggleTimer = () => { 
		setViewTimer(!viewTimer);
	};
	const [count, setCount] = useState(0);
	const zbminus = () => {
		if (count < 2) {
			setCount(count + 1);
			if (count === 0) {
				document.body.classList.add('decreaseFont');
			}
			if (count === 1) {
				document.body.classList.add('decreaseFontsmall');
				document.body.classList.remove('decreaseFont');
			}
		}	
	};
	useEffect(() => {
	}, [count]);
	const zb = () => {
		if (count >=0 ) {
			setCount(count - 1);
			if (count === 2) {
				document.body.classList.remove('decreaseFontsmall');
				document.body.classList.add('decreaseFont');
			}
			if (count === 1) {
				document.body.classList.remove('decreaseFont');
			}
		}
	};
	// drawer
	const [visible, setvisible] = useState(false);
	const showDrawer = () => {
		if(listQuestions&&listQuestions.length>0){
			let isQuestionIndexOpenedFromSession=sessionStorage.getItem("isQuestionIndexOpened");
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0||listQuestions[questionIndex].markedForLater===true){
				if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length&&listQuestions[questionIndex].markedForLater===false){
					if(isQuestionIndexOpenedFromSession&&isQuestionIndexOpenedFromSession==="true"){
						if(checkAnswer==="true"){
							message.error(constants.SelectOptionDragAndDropTutorials);
						}else{
							message.error(constants.SelectOptionDragAndDrop);
						}
					}else{
						setvisible(true);
					}
				}else{
					handleNextQuestion("QUESTION");
					setvisible(true);
				}
			}else{
				if(isQuestionIndexOpenedFromSession&&isQuestionIndexOpenedFromSession==="true"){
					if(checkAnswer==="true"){
						message.error(constants.SelectOptionOnly);
					}else{
						message.error(constants.SelectOption);
					}
				}else{
					let questionIndexCount=Number(sessionStorage.getItem("questionIndexCount"));
					let questionIndexFromSession=Number(sessionStorage.getItem("questionIndex"));
					if(questionIndexFromSession<questionIndexCount){
						if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length===0||listQuestions[questionIndex].markedForLater===false){
							if(checkAnswer==="true"){
								message.error(constants.SelectOptionOnly);
							}else{
								message.error(constants.SelectOption);
							}
						}else{
							setvisible(true);
						}
					}else{
						setvisible(true);
					}
				}			
			}
		}
	};
	const onClose = () => {
		setvisible(false);
	};
	// notes modal
	const [notesVisible, setnotesVisible] = useState(false);
	const showModal = () => {
		setnotesVisible(true);
		let itemTemp=listQuestions[questionIndex];
		if (itemTemp.notes!==undefined&&itemTemp.notes!==null){
			setNoteValue(itemTemp.notes);
		}
	};
	const hideModal = () => {
		noteForm.resetFields();
		setnotesVisible(false);
	};
		//To set note form
	const setNoteValue=(valueText)=>{
		noteForm.setFieldsValue({ note:valueText });
	}; 
	const [disabled,setIsDisabled]=React.useState(true);
	const [bounds,setBounds]=React.useState({
		left: 0,
		top: 0,
		bottom: 0,
		right: 0 
	});
	//Columns to display the drag and drop type questions
	let columnsOfDragAndDrop = {
		[1]: { items: [] },
		[2]: { items: [] },
		[3]: { items: [] },
		[4]: { items: [] },
		[5]: { items: [] },
		[6]: { items: [] },
		[7]: { items: [] },
		[8]: { items: [] },
		[9]: { items: [] },
	};
	//end of drap and drop code
	const [listQuestions,setQuestions]=useState([]);
	const [questionIndex,setQuestionIndex]=useState(0);
	const [sortitems, setSortItems] = useState([]);
	const [refresh,setRefresh]=useState(false);
	const [timerDisplay,setTimerDisplay]=useState(null);
	const [time,setTime]=useState(null);
	const [completeTest,setCompleteTest]=useState(false);
	const [pauseTest,setPauseTest]=useState(false);
	const [scoreCardId,setScoreCardId]=useState(null);
	const [nextAndPreviousLoading,setNextAndPreviousLoading]=useState(false);
	const [completeTestLoading,setCompleteTestLoading]=useState(false);
	const [pauseTestLoading,setPauseTestLoading]=useState(false);
	const [selectedSequenceOption,setSelectedSequenceOption]=useState("");
	const [selectedDragOption,setSelectedDragOption]=useState(null);
	const [attemptedQuestions,setAttemptedQuestions]=useState(0);
	const draggleRef = React.createRef();
	const [noteForm] = Form.useForm();
	const [notelist,]=useState([]);
	const [categoryDetails,setCategoryDetails]=useState({});
	const [topicDetails,setTopicDetails]=useState({});
	const [lastTenMinutes,setLastTenMinutes]=useState(false);
	const [checkAnswer,setCheckAnswer]=useState("false");
	const [columns, setColumns] = useState(columnsOfDragAndDrop);
	const [markedLaterVisible,setMarkedLaterVisible]=useState(false);
	const [checkMarkLater,setCheckMarkLater]=useState("false");
	const [markedForLaterQuestions,setMarkedForLaterQuestions]=useState([]);
	const [mouseUp,setMouseUp]=useState(0);
	const [mouseDown,setMouseDown]=useState(0);
	const [selectedId,setSelectedId]=useState(null);
	const [visibleTimeOut,setVisibileTimeOut]=useState(false);
	const [searchList,setSearchList]=useState([]);
	let [searchValue,setSearchValue]=useState(null);
	const [isSearch,setIsSearch]=useState("false");
	const [displaySearch,setDisplaySearch]=useState("false");
	const [questionIndexList,setQuestionIndexList]=useState([]);//Used for Question Index drawer.
	const [isQuestionIndexOpened,setIsQuestionIndexOpened]=useState("false");
	const [Prompt,setDirty,setPristine]=useUnsavedChangesWarning();
	const [onMouseDownId,setonMouseDownId]=useState(null);
	const [browserTime,setbrowserTime]=useState("");
	const [serverTime,setServerTime]=useState("");
	var myTimer;
	var timerUp;
	var totalSeconds = 0;
	const clock=(timer)=> {
		myTimer = setInterval(myClock, 1000);
		var c = timer; //Initially set to 1 hour
		timerUp = setInterval(countTimer, 1000);
		function myClock() {
			sessionStorage.setItem("clock",--c);
			var seconds = c % 60; // Seconds that cannot be written in minutes
			var minutes = (c - seconds) / 60; // Gives the seconds that COULD be given in minutes
			var minutesLeft = minutes % 60; // Minutes that cannot be written in hours
			var hours = (minutes - minutesLeft) / 60;
			if(hours < 10)
				hours = "0"+hours;
			if(minutesLeft<10){
				minutesLeft="0"+minutesLeft;
			}
			if(seconds < 10)
				seconds = "0"+seconds;
			// Now in hours, minutes and seconds, you have the time you need.
			sessionStorage.setItem("timerDisplay",hours + ":" + minutesLeft + ":" + seconds);
			setTimerDisplay(hours + ":" + minutesLeft + ":" + seconds);
			if(c==300){
				setLastTenMinutes(true);
			}
			if (c <= 0) {
				saveNotes();
				clearInterval(myTimer);
				clearInterval(timerUp);
				timeOut();
			}
		}
		function countTimer() {
			sessionStorage.setItem("totalSeconds",++totalSeconds);
			var hour = Math.floor(totalSeconds /3600);
			var minute = Math.floor((totalSeconds - hour*3600)/60);
			var seconds = totalSeconds - (hour*3600 + minute*60);
			if(hour < 10)
				hour = "0"+hour;
			if(minute < 10)
				minute = "0"+minute;
			if(seconds < 10)
				seconds = "0"+seconds;
			sessionStorage.setItem("timeTaken",hour + ":" + minute + ":" + seconds);
			setTime( hour + ":" + minute + ":" + seconds);
		}
	};
	useEffect(() => {
		window.history.pushState(null, "", window.location.href);
		if(document.body.classList.contains('decreaseFont')){
			document.body.classList.remove('decreaseFont');
		}
		if(document.body.classList.contains('decreaseFontsmall')){
			document.body.classList.remove('decreaseFontsmall');
		}
		setDirty();
		sessionStorage.setItem("quitTest","false");
		sessionStorage.setItem("isFullLengthTestvalue","false");
		$(document).keydown(function(event){
			if(event.keyCode===123||event.ctrlKey&&event.shiftKey&&event.keyCode===73||event.ctrlKey&&event.keyCode===67){
				return false;
			}
		});
		document.addEventListener('copy',disableCopy);
		document.addEventListener('contextmenu',disableContextMenu);
		let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
			fetchFromLocalStorage();
		}else{
			if(props&&props.location&&props.location.state&&props.location.state.categoryDetails){
				sessionStorage.setItem("categoryDetails", JSON.stringify(props.location.state.categoryDetails));
				fetchStartTestData();
			}else if(props&&props.location&&props.location.state&&props.location.state.topicDetails){
				sessionStorage.setItem("topicDetails", JSON.stringify(props.location.state.topicDetails));
				fetchStartTestData();
			}else{
				let queryString = window.location.href;
				var routeValue = queryString.substring(queryString.lastIndexOf('/') + 1);
				if(routeValue==="short-length-test"){
					history.push("/my-tests");
				}else{
					history.push("/home");
				}
			}
		}
	}, []);
	useEffect(() => {
		return () => {
			clearInterval(myTimer);// Anything in here is fired on component unmount.
			clearInterval(timerUp);
			document.removeEventListener('contextmenu',disableContextMenu);
			document.removeEventListener('copy',disableCopy);
			$(document).off('keydown');
			let testCount=sessionStorage.getItem("totalTestsTaken");
			let viewedQod=sessionStorage.getItem("viewedQOD");
			let crashTest= sessionStorage.getItem("crash");
			let feedbackpopupshow=sessionStorage.getItem("feedbackShown");
			sessionStorage.clear();
			sessionStorage.setItem("crash",crashTest);
			sessionStorage.setItem("totalTestsTaken",testCount);
			sessionStorage.setItem("viewedQOD",viewedQod);
			sessionStorage.setItem("feedbackShown",feedbackpopupshow);
		};
	}, []);
	function disableCopy(e) {
		e.preventDefault();
	}
	function disableContextMenu(e) {
		e.preventDefault();
	}
	// arrange the sequence
	const onSortEnd = ({ oldIndex, newIndex }) => {
		setSortItems(arrayMove(sortitems, oldIndex, newIndex));
		sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
		setQuestions(listQuestions);
		setRefresh(!refresh);
	};
	useEffect(() => {
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="4"){
			if(isSearch==="true"||isQuestionIndexOpened==="true"){
				listQuestions[questionIndex].selectedAnswer=sortitems;
			}else{
				listQuestions[questionIndex].selectedAnswer=sortitems;
				listQuestions[questionIndex].oldSelectedAnswer=sortitems;
			}
			setQuestions(listQuestions);
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
		}
	}, [sortitems]);
	//end of arrange the sequence code
	//notes modal
	const onStart = (event, uiData) => {
		const { clientWidth, clientHeight } = window?.document?.documentElement;
		const targetRect = draggleRef?.current?.getBoundingClientRect();
		let boundsForNotesModal={
			left: -targetRect?.left + uiData?.x,
			right: clientWidth - (targetRect?.right - uiData?.x),
			top: -targetRect?.top + uiData?.y,
			bottom: clientHeight - (targetRect?.bottom - uiData?.y),
		};
		setBounds(boundsForNotesModal);
	};
	//To fetch the Questions to answer the test of the category type.
	const fetchStartTestData=()=>{
		let userId = getCookie(COOKIE.UserId);
		let payload = {};
		if(props&&props.location&&props.location.state&&props.location.state.categoryDetails){
			setCategoryDetails(props.location.state.categoryDetails);
			let categoryDetailsFromProps=props.location.state.categoryDetails;
			if(categoryDetailsFromProps.isTimed===true){
				setViewTimer(true);
				if(categoryDetailsFromProps.count===25){
					clock(constants.TwentyFiveQuestionTimer);
				}
				else if(categoryDetailsFromProps.count===50){
					clock(constants.FiftyQuestionTimer);
				}
			}else if(categoryDetailsFromProps.isTimed===false){
				setViewTimer(false);
			}
			payload.stud_id=userId;
			payload.type=categoryDetailsFromProps.type;
			payload.cat_id=categoryDetailsFromProps.catId;
			payload.cat_type=categoryDetailsFromProps.catType;
			payload.attempt=categoryDetailsFromProps.attempt;
			payload.count=categoryDetailsFromProps.count;
			payload.is_timed=categoryDetailsFromProps.isTimed;
		}else if(props&&props.location&&props.location.state&&props.location.state.topicDetails){
			setTopicDetails(props.location.state.topicDetails);
			let topicDetailsFromProps=props.location.state.topicDetails;
			if(topicDetailsFromProps.isTimed===true){
				setViewTimer(true);
				if(topicDetailsFromProps.count===25){
					clock(constants.TwentyFiveQuestionTimer);
				}
			}else if(topicDetailsFromProps.isTimed===false){
				setViewTimer(false);
			}
			payload.stud_id=userId;
			payload.type=topicDetailsFromProps.type;
			payload.topic_id=topicDetailsFromProps.topicId;
			payload.is_timed=topicDetailsFromProps.isTimed;
			payload.attempt=0;
		}
		setNextAndPreviousLoading(true);
		fetchApi(`/student/start-test`, "post", payload).then((res) => {
			setNextAndPreviousLoading(false);
			if (res && res.code && res.code === 200) {
				setServerTime(res.current_date?res.current_date:"");
				let browerDateTime = new Date();
				setbrowserTime(browerDateTime.toString());
				if(res.data&&res.data.questions&&res.data.questions.length>0){
					for (let i = 0; i < res.data.questions.length; i++) {
						res.data.questions[i].isUpdatedToApi=false;
						res.data.questions[i].selectedAnswer=[];
						res.data.questions[i].markedForLater=false;
						let correct_option=[];
						if(res.data.questions[i].q_type==="5"){
							correct_option=res.data.questions[i].correct_option;
						}else{
							for (let j = 0; j < res.data.questions[i].correct_option.length; j++) {
								correct_option.push(Number(res.data.questions[i].correct_option[j]));
							}
						}
						res.data.questions[i].correct_option=correct_option;
						if(res.data.questions[i].q_type==="5"){
							if(res.data.questions[i].option_first){
								res.data.questions[i].option_one=res.data.questions[i].option_first.substring(res.data.questions[i].option_first.indexOf("|")+1);
							}
							if(res.data.questions[i].option_second){
								res.data.questions[i].option_two=res.data.questions[i].option_second.substring(res.data.questions[i].option_second.indexOf("|")+1); 
							}
							if(res.data.questions[i].option_third){
								res.data.questions[i].option_three=res.data.questions[i].option_third.substring(res.data.questions[i].option_third.indexOf("|")+1);
							}
							if(res.data.questions[i].option_fourth){
								res.data.questions[i].option_four=res.data.questions[i].option_fourth.substring(res.data.questions[i].option_fourth.indexOf("|")+1);
							}
							if(res.data.questions[i].option_fifth){
								res.data.questions[i].option_five=res.data.questions[i].option_fifth.substring(res.data.questions[i].option_fifth.indexOf("|")+1); 
							}
							if(res.data.questions[i].option_sixth){
								res.data.questions[i].option_six=res.data.questions[i].option_sixth.substring(res.data.questions[i].option_sixth.indexOf("|")+1); 
							}
							if(res.data.questions[i].option_seventh){
								res.data.questions[i].option_seven=res.data.questions[i].option_seventh.substring(res.data.questions[i].option_seventh.indexOf("|")+1); 
							}
							if(res.data.questions[i].option_eighth){
								res.data.questions[i].option_eight=res.data.questions[i].option_eighth.substring(res.data.questions[i].option_eighth.indexOf("|")+1);
							}
						}
					}
					if(res.data&&res.data.scorecard_id){
						sessionStorage.setItem('scoreCardId',res.data.scorecard_id);
						setScoreCardId(res.data.scorecard_id);
					}
					sessionStorage.setItem("listQuestions", JSON.stringify(res.data.questions));
					sessionStorage.setItem("questionIndex",0);
					sessionStorage.setItem("questionIndexCount",0);
					sessionStorage.setItem("checkAnswer","false");
					sessionStorage.setItem("checkMarkLater","false");
					sessionStorage.setItem("displaySearch","false");
					sessionStorage.setItem("isSearch","false");
					sessionStorage.setItem("isQuestionIndexOpened","false");
					sessionStorage.setItem("testType","shortLength");
					setCheckAnswer("false");
					let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
					setQuestions(listQuestionsFromSession);
					checkQuestionType(res.data.questions);
				}
			} else {
				sessionStorage.setItem("quitTest","true");
				message.error(res.message);
				setPristine();
				history.push("/choose-a-test");
			}
		});
	};
	const fetchFromLocalStorage=()=>{
		let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		let questionIndexFromSession=sessionStorage.getItem("questionIndex");
		let categoryDetailsFromSession=JSON.parse(sessionStorage.getItem("categoryDetails")||"null");
		let topicDetailsFromSession=JSON.parse(sessionStorage.getItem("topicDetails")||"null");
		let checkAnswerFromSession=sessionStorage.getItem("checkAnswer");
		let checkMarkLaterFromSession=sessionStorage.getItem("checkMarkLater");
		let markedForLaterQuestionsFromSession=JSON.parse(sessionStorage.getItem("markedForLaterQuestions") || "[]");
		let isSearchFromSession=sessionStorage.getItem("isSearch");
		let searchValueFromSession=sessionStorage.getItem("searchValue");
		let searchListFromSession=JSON.parse(sessionStorage.getItem("searchList")||"[]");
		let questionIndexListFromSession=JSON.parse(sessionStorage.getItem("questionIndexList")||"[]");
		let isQuestionIndexOpenedFromSession=sessionStorage.getItem("isQuestionIndexOpened");
		let displaySearchFromSession=sessionStorage.getItem("displaySearch");
		let attemptedQuestionsFromSession=sessionStorage.getItem("attemptedQuestions");
		setAttemptedQuestions(Number(attemptedQuestionsFromSession));
		setDisplaySearch(displaySearchFromSession);
		setIsQuestionIndexOpened(isQuestionIndexOpenedFromSession);
		setQuestionIndexList(questionIndexListFromSession);
		setSearchList(searchListFromSession);
		setSearchValue(searchValueFromSession);
		setIsSearch(isSearchFromSession);
		setMarkedForLaterQuestions(markedForLaterQuestionsFromSession);
		setCheckMarkLater(checkMarkLaterFromSession);
		setCheckAnswer(checkAnswerFromSession);
		setCategoryDetails(categoryDetailsFromSession);
		setTopicDetails(topicDetailsFromSession);
		if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
			if(categoryDetailsFromSession){
				if(categoryDetailsFromSession.isTimed===true){
					setViewTimer(true);
					let clocks=sessionStorage.getItem("clock");
					clock(Number(clocks));
					totalSeconds=sessionStorage.getItem("totalSeconds");
					setTime(sessionStorage.getItem("timeTaken"));
					if(Number(clocks)===0){
						clearInterval(myTimer);
						clearInterval(timerUp);
						setVisibileTimeOut(true);
						sessionStorage.setItem("quitTest","true");
						setPristine();
						let timerDisplayFromSession=sessionStorage.getItem("timerDisplay");
						setTimerDisplay(timerDisplayFromSession);
					}
				}else if(categoryDetailsFromSession.isTimed===false){
					setViewTimer(false);
				}
			}
			else if(topicDetailsFromSession){
				if(topicDetailsFromSession.isTimed===true){
					setViewTimer(true);
					let clocks=sessionStorage.getItem("clock");
					clock(Number(clocks));
					totalSeconds=sessionStorage.getItem("totalSeconds");
					setTime(sessionStorage.getItem("timeTaken"));
					if(Number(clocks)===0){
						clearInterval(myTimer);
						clearInterval(timerUp);
						setVisibileTimeOut(true);
						sessionStorage.setItem("quitTest","true");
						setPristine();
						let timerDisplayFromSession=sessionStorage.getItem("timerDisplay");
						setTimerDisplay(timerDisplayFromSession);
					}
				}else if(topicDetailsFromSession.isTimed===false){
					setViewTimer(false);
				}
			}
			setQuestions(listQuestionsFromSession);
			setScoreCardId(Number(sessionStorage.getItem("scoreCardId")));
			setQuestionIndex(Number(questionIndexFromSession));
			checkQuestionType(listQuestionsFromSession);
		}else{
			sessionStorage.setItem("quitTest","true");
			setPristine();
			history.push("/my-tests");
		}
	};
	//Function to check if question type is arrange sequence or drag and drop to display options on page load
	const checkQuestionType=(listOfQuestions)=>{
		let questionIndexFromSession=Number(sessionStorage.getItem("questionIndex"));
		if(listOfQuestions&&listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].q_type==="4"){
			let sortItems=[];
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first){
				sortItems.push({
					index:1,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second){
				sortItems.push({
					index:2,
					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndexFromSession].option_second 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third){
				sortItems.push({
					index:3,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth){
				sortItems.push({
					index:4,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth){
				sortItems.push({
					index:5,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth){
				sortItems.push({
					index:6,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh){
				sortItems.push({
					index:7,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh 
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth){
				sortItems.push({
					index:8,
					value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth 
				});
			}
			setSortItems(sortItems);
			setRefresh(!refresh);
		}
		else if(listOfQuestions&&listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].q_type==="5"){
			let Items=[];
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first){
				Items.push({
					id:`${1}`,
					content:`${listOfQuestions[questionIndexFromSession].option_one }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second){
				Items.push({
					id:`${2}`,
					content:`${listOfQuestions[questionIndexFromSession].option_two }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third){
				Items.push({
					id:`${3}`,
					content:`${listOfQuestions[questionIndexFromSession].option_three }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth){
				Items.push({
					id:`${4}`,
					content:`${listOfQuestions[questionIndexFromSession].option_four }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth){
				Items.push({
					id:`${5}`,
					content:`${listOfQuestions[questionIndexFromSession].option_five}`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth){
				Items.push({
					id:`${6}`,
					content:`${listOfQuestions[questionIndexFromSession].option_six}`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh){
				Items.push({
					id:`${7}`,
					content:`${listOfQuestions[questionIndexFromSession].option_seven }`
				});
			}
			if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth){
				Items.push({
					id:`${8}`,
					content:`${listOfQuestions[questionIndexFromSession].option_eight}`
				});
			}
			columns[1].items=Items;
			setColumns(columns);
			setRefresh(!refresh);
		}
	};
	//Executes on click of Next button.
	const handleNextQuestion=(btnClickType)=>{
		if(listQuestions&&listQuestions.length>0){
			let displaySearchFromSession=sessionStorage.getItem("displaySearch");
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0||listQuestions[questionIndex].markedForLater===true){
				if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length&&listQuestions[questionIndex].markedForLater===false){
					if(displaySearchFromSession==="false"){
						if(checkAnswer==="true"){
							message.error(constants.SelectOptionDragAndDropTutorials);
						}else{
							message.error(constants.SelectOptionDragAndDrop);
						}
					}
				}else{
					let totalOptions=null;
					let optionNumver={};
					if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"||listQuestions[questionIndex].q_type==="4"){
						totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt):0;
						optionNumver=constants.OptionNumbers;
					}else if(listQuestions[questionIndex].q_type==="5"){
						optionNumver=constants.OptionNumberDragAndDrop;
						totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt.charAt(2)):0;
					}
					var match=false;
					for (let index = 0; index < totalOptions; index++) {
						if(!listQuestions[questionIndex][optionNumver[index+1]].includes(`<strike>`)){
							match=true;
							break;
						}
					}
					if(match===true||listQuestions[questionIndex].markedForLater===true){
						let payload = {};
						var today = new Date();
						var dd = String(today.getDate()).padStart(2, '0');
						var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
						var yyyy = today.getFullYear();
						today = yyyy + '-'+ mm + '-' + dd;
						if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"){
							var sequence=questionIndex+1;
							payload.test_data={
								seq:sequence,
								q_id:Number(listQuestions[questionIndex].question_id),
								user_ans:listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0?listQuestions[questionIndex].selectedAnswer:[],
								corr_ans:listQuestions[questionIndex].correct_option,
								marked_for_later:listQuestions[questionIndex].markedForLater,
								// answered_on:today,
							};
						}else if(listQuestions[questionIndex].q_type==="4"){
							let answer=[];
							for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
								answer.push(listQuestions[questionIndex].selectedAnswer[i].index);
							}
							var seq=questionIndex+1;
							payload.test_data={
								seq:seq,
								q_id:Number(listQuestions[questionIndex].question_id),
								user_ans:answer&&answer.length>0?answer:[],
								corr_ans:listQuestions[questionIndex].correct_option,
								marked_for_later:listQuestions[questionIndex].markedForLater,
								// answered_on:today,
							};
						}else if(listQuestions[questionIndex].q_type==="5"){
							let answer=[];
							for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
								answer.push(Number(listQuestions[questionIndex].selectedAnswer[i].id));
							}
							var sequ=questionIndex+1;
							payload.test_data={
								seq:sequ,
								q_id:Number(listQuestions[questionIndex].question_id),
								user_ans:answer&&answer.length>0?answer:[],
								corr_ans:listQuestions[questionIndex].correct_option,
								marked_for_later:listQuestions[questionIndex].markedForLater,
								// answered_on:today,
							};
						}
						payload.test_status=0;
						if((categoryDetails&&categoryDetails.isTimed!==null&&categoryDetails.isTimed===true)||
						(topicDetails&&topicDetails.isTimed!==null&&topicDetails.isTimed===true)){
							payload.total_time_taken=time;
						}
						// else if(topicDetails&&topicDetails.isTimed!==null&&topicDetails.isTimed===true){
						// 	payload.total_time_taken=time;
						// }
						let strikedOptions=[];
						let highlightedOptions=[];
						for (let j = 0; j < totalOptions; j++) {
							let op=optionNumver[j+1];
							if(listQuestions[questionIndex][op].includes("<strike>")){
								strikedOptions.push(j+1);
							}
							if(listQuestions[questionIndex][op].includes("highlighted")){
								highlightedOptions.push({ 
									opt:j+1,
									data:listQuestions[questionIndex][op] 
								});
							}
						}
						if(listQuestions[questionIndex].question_text&&listQuestions[questionIndex].question_text.includes("highlighted")){
							highlightedOptions.push({
								opt:99,
								data:listQuestions[questionIndex].question_text,
							});
						}
						if(strikedOptions&&strikedOptions.length>0||highlightedOptions&&highlightedOptions.length>0){
							payload.highlight_strike_data={
								q_id:Number(listQuestions[questionIndex].question_id),
								strike:strikedOptions,
								highlight:highlightedOptions&&highlightedOptions.length>0?highlightedOptions:[],
							};
						}
						if(checkMarkLater==="true"){
							if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
								if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length){
									if(displaySearchFromSession==="false"){
										if(checkAnswer==="true"){
											message.error(constants.SelectOptionDragAndDropTutorials);
										}else{
											message.error(constants.SelectOptionDragAndDrop);
										}
									}
								}else{
									nextQuestionApi(payload,btnClickType);
								}
							}else{
								message.error(constants.SelectOptionOnly);
							}
						}else{
							nextQuestionApi(payload,btnClickType);
						}
					}else{
						if(displaySearchFromSession==="false"){
							if(checkAnswer==="true"){
								message.error(constants.SelectOptionOnly);
							}else{
								message.error(constants.SelectOption);
							}
						}
					}
				}
			}else{
				if(displaySearchFromSession==="false"){
					if(checkAnswer==="true"){
						message.error(constants.SelectOptionOnly);
					}else{
						message.error(constants.SelectOption);
					}
				}
			}
		}
	};
	const nextQuestionApi=(payload,btnClickType)=>{
		setNextAndPreviousLoading(true);
		fetchApi(`/score_card/${scoreCardId}`, "put", payload)
			.then((res) => {
				// setNextAndPreviousLoading(false);
				setServerTime(res.current_date?res.current_date:"");
				let browerDateTime = new Date();
				setbrowserTime(browerDateTime.toString());
				if (res && res.code && res.code === 200) {
					listQuestions[questionIndex].isUpdatedToApi=true;
					questionIndexData();
					if(searchList&&searchList.length>0){
						var found = false;
						for(var i = 0; i < searchList.length; i++) {
							if (searchList[i].value ===listQuestions[questionIndex].topic_title) {
								found = true;
								break;
							}
						}
						if(!found){
							searchList.push({ 
								label:listQuestions[questionIndex].topic_title,
								value:listQuestions[questionIndex].topic_title,	
							});
						}
					}else{
						searchList.push({ 
							label:listQuestions[questionIndex].topic_title,
							value:listQuestions[questionIndex].topic_title,	
						});
					}
					sortArrayOfObjects(searchList,"value");
					setSearchList(searchList);
					sessionStorage.setItem("searchList", JSON.stringify(searchList));
					calculateAttemptedQuestions();
					if(questionIndex<listQuestions.length-1){
						if(checkAnswer==="true"&&btnClickType==="SUBMIT"){
							setCompleteTest(true);
						}else{
							if(checkMarkLater==="true"){
								var match=false;
								for (let index = questionIndex; index < listQuestions.length; index++) {
									if(listQuestions[index].markedForLater===true){
										setColumns(constants.columnsOfDragAndDrop);
										setQuestionIndex(index);
										sessionStorage.setItem("questionIndex",index);
										sessionStorage.setItem("questionIndexCount",index);
										listQuestions[index].markedForLater=false;
										sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
										setQuestions(listQuestions);
										match=true;
										break;
									}
								}
								if(match===false){
									if(btnClickType&&btnClickType==="PREVIOUS"){
										sessionStorage.setItem("questionIndex",questionIndex-1);
										setQuestionIndex(questionIndex-1);
									}else if(btnClickType&&btnClickType==="QUESTION"){
										sessionStorage.setItem("questionIndex",questionIndex);
										setQuestionIndex(questionIndex);
									}else{
										setCompleteTest(true);
									}
								}
							}else{
								if(btnClickType!=="SAVE"){
									if(btnClickType==="PREVIOUS"){
										setColumns(constants.columnsOfDragAndDrop);
										sessionStorage.setItem("questionIndex",questionIndex-1);
										setQuestionIndex(questionIndex-1);
									}else if(btnClickType==="QUESTION"){
										sessionStorage.setItem("questionIndex",questionIndex);
										setQuestionIndex(questionIndex);
									}else{
										setColumns(constants.columnsOfDragAndDrop);
										sessionStorage.setItem("questionIndex",questionIndex+1);
										sessionStorage.setItem("questionIndexCount",questionIndex+1);
										setQuestionIndex(questionIndex+1);
									}
								}else{
									listQuestions[questionIndex].oldSelectedAnswer=listQuestions[questionIndex].selectedAnswer;
									sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
									setQuestions(listQuestions);
								}
							}
						}
					}else{
						let markedForLaterQuestionsList=[];
						for (let index = 0; index < listQuestions.length; index++) {
							if(listQuestions&&listQuestions[index].markedForLater===true){
								markedForLaterQuestionsList.push(listQuestions[index].question_id);
							}
						}
						sessionStorage.setItem("markedForLaterQuestions", JSON.stringify(markedForLaterQuestionsList));
						setMarkedForLaterQuestions(markedForLaterQuestionsList);
						var matched=false;
						for (let index = 0; index < listQuestions.length; index++) {
							if(listQuestions[index].markedForLater===true){
								matched=true;
								break;
							}
						}
						if(matched===true){
							if(btnClickType==="SUBMIT"){
								setCompleteTest(true);
							}else{
								if(btnClickType&&btnClickType==="PREVIOUS"){
									sessionStorage.setItem("questionIndex",questionIndex-1);
									setQuestionIndex(questionIndex-1);
								}else if(btnClickType&&btnClickType==="QUESTION"){
									sessionStorage.setItem("questionIndex",questionIndex);
									setQuestionIndex(questionIndex);
								}else{
									setMarkedLaterVisible(true);
								}
							}
						}else{
							if(btnClickType&&btnClickType==="PREVIOUS"){
								sessionStorage.setItem("questionIndex",questionIndex-1);
								setQuestionIndex(questionIndex-1);
							}else if(btnClickType&&btnClickType==="QUESTION"){
								sessionStorage.setItem("questionIndex",questionIndex);
								setQuestionIndex(questionIndex);
							}else{
								setCompleteTest(true);
							}
						}
					}
					setNextAndPreviousLoading(false);
					setQuestions(listQuestions);
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				} else {
					setNextAndPreviousLoading(false);
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setNextAndPreviousLoading(false);
					if(res.error_message==="Invalid token"){
						message.error(constants.LoggedInAnotherBrowser);
					}else{
						if(res.code===600){
							message.error(res.message);
						}else{
							message.error("Failed");
						}
					}
				}
			});
	};
	//Function to calculate the count of attempted questions to show on Pause test popup
	const calculateAttemptedQuestions=()=>{
		if(listQuestions&&listQuestions.length>0){
			var attemptedQuestionsCount=0;
			for (let index = 0; index < listQuestions.length; index++) {
				if(listQuestions[index].selectedAnswer&&listQuestions[index].selectedAnswer.length>0||listQuestions[index].markedForLater===true){
					attemptedQuestionsCount=attemptedQuestionsCount+1;
				}
			}
			setAttemptedQuestions(attemptedQuestionsCount);
			sessionStorage.setItem("attemptedQuestions",attemptedQuestionsCount);
			setRefresh(!refresh);
		}
	};
	//Executes on click of Previous button.
	const handlePreviousQuestion=()=>{
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0||listQuestions[questionIndex].markedForLater===true){
			if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length&&listQuestions[questionIndex].markedForLater===false){
				setNextAndPreviousLoading(true);
				setColumns(constants.columnsOfDragAndDrop);
				sessionStorage.setItem("questionIndex",questionIndex-1);
				setQuestionIndex(questionIndex-1);
				setNextAndPreviousLoading(false);
			}else{
				handleNextQuestion("PREVIOUS");
			}
		}else{
			if(listQuestions[questionIndex].isUpdatedToApi===true){
				if(checkAnswer==="true"){
					message.error(constants.SelectOptionOnly);
				}else{
					message.error(constants.SelectOption);
				}
			}else{
				setNextAndPreviousLoading(true);
				setColumns(constants.columnsOfDragAndDrop);
				sessionStorage.setItem("questionIndex",questionIndex-1);
				setQuestionIndex(questionIndex-1);
				setNextAndPreviousLoading(false);
			}
		}
	};
	const completeTestApi=()=>{
		let payload = {};
		payload.test_status=1;
		if((categoryDetails&&categoryDetails.isTimed!==null&&categoryDetails.isTimed===true)||
		(topicDetails&&topicDetails.isTimed!==null&&topicDetails.isTimed===true)){
			payload.total_time_taken=time;
		}
		// else if(topicDetails&&topicDetails.isTimed!==null&&
		// 	topicDetails.isTimed===true){
		// 	payload.total_time_taken=time;
		// }
		const date= new Date();
		payload.dt_completed=date;
		if(markedForLaterQuestions&&markedForLaterQuestions.length>0){
			payload.marked_for_later=markedForLaterQuestions;
		}else{
			payload.marked_for_later=[];
		}
		setMarkedLaterVisible(false);
		setCompleteTestLoading(true);
		fetchApi(`/score_card/${scoreCardId}`, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					setServerTime(res.current_date?res.current_date:"");
					let browerDateTime = new Date();
					setbrowserTime(browerDateTime.toString());
					let testCount=sessionStorage.getItem("totalTestsTaken");
					let totalTestCount=parseInt(testCount)+1;
					sessionStorage.setItem("totalTestsTaken",totalTestCount);
					listQuestions[questionIndex].isUpdatedToApi=true;
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setCompleteTest(false);
					clearInterval(myTimer);
					clearInterval(timerUp);
					setColumns(constants.columnsOfDragAndDrop);
					calculateAttemptedQuestions();
					setCompleteTestLoading(false);
					let testCategory=null;
					let resultTypeFull=null;
					let isTopic=null;
					if(categoryDetails&&categoryDetails.type==="normal"){
						if(categoryDetails.catType&&categoryDetails.catType==="MIXED"){
							resultTypeFull=false;
						}else{
							resultTypeFull=true;
						}
						testCategory=categoryDetails.catType;
						isTopic=false;
					}
					if(topicDetails&&topicDetails.type==="normal"){
						resultTypeFull=false;
						testCategory=null;
						isTopic=true;
					}
					history.push({
						pathname: "/result-page",
						state: { 
							scoreCardId:scoreCardId,
							resultTypeFull:resultTypeFull,
							testCategory:testCategory,
							isTopic:isTopic,
						}
					});
				} else {
					setCompleteTestLoading(false);
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setNextAndPreviousLoading(false);
					if(res.error_message==="Invalid token"){
						message.error(constants.LoggedInAnotherBrowser);
					}else{
						if(res.code===600){
							message.error(res.message);
						}else{
							message.error("Failed");
						}
					}
				}
				setCompleteTestLoading(false);
			});
	};
	//Executes score_card PUT method API to pause the test
	const pauseTestAnswer=()=>{
		setPauseTestLoading(true);
		let payload = {};
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		today = yyyy + '-'+ mm + '-' + dd;
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&
			listQuestions[questionIndex].selectedAnswer.length>0||
			listQuestions[questionIndex].markedForLater===true||
			listQuestions[questionIndex].markedForLater===false){
			if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"){
				var sequence=questionIndex+1;
				payload.test_data={
					seq:sequence,
					q_id:Number(listQuestions[questionIndex].question_id),
					user_ans:listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0?listQuestions[questionIndex].selectedAnswer:[],
					corr_ans:listQuestions[questionIndex].correct_option,
					marked_for_later:listQuestions[questionIndex].markedForLater,
					// answered_on:today,
				};
			}else if(listQuestions[questionIndex].q_type==="4"){
				let answer=[];
				for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
					answer.push(listQuestions[questionIndex].selectedAnswer[i].index);
				}
				var seq=questionIndex+1;
				payload.test_data={
					seq:seq,
					q_id:Number(listQuestions[questionIndex].question_id),
					user_ans:answer&&answer.length>0?answer:[],
					corr_ans:listQuestions[questionIndex].correct_option,
					marked_for_later:listQuestions[questionIndex].markedForLater,
					// answered_on:today,
				};
			}else if(listQuestions[questionIndex].q_type==="5"){
				let answer=[];
				for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
					answer.push(Number(listQuestions[questionIndex].selectedAnswer[i].id));
				}
				var sequ=questionIndex+1;
				payload.test_data={
					seq:sequ,
					q_id:Number(listQuestions[questionIndex].question_id),
					user_ans:answer&&answer.length>0?answer:[],
					corr_ans:listQuestions[questionIndex].correct_option,
					marked_for_later:listQuestions[questionIndex].markedForLater,
					// answered_on:today,
				};
			}
			if((categoryDetails&&categoryDetails.isTimed!==null&&categoryDetails.isTimed===true)||
			(topicDetails&&topicDetails.isTimed!==null&&topicDetails.isTimed===true)){
				payload.total_time_taken=time;
			}
			// else if(topicDetails&&topicDetails.isTimed!==null&&
			// 	topicDetails.isTimed===true){
			// 	payload.total_time_taken=time;
			// }
			let totalOptions=null;
			let optionNumver={};
			if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"||listQuestions[questionIndex].q_type==="4"){
				totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt):0;
				optionNumver=constants.OptionNumbers;
			}else if(listQuestions[questionIndex].q_type==="5"){
				optionNumver=constants.OptionNumberDragAndDrop;
				totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt.charAt(2)):0;
			}
			let strikedOptions=[];
			let highlightedOptions=[];
			for (let j = 0; j < totalOptions; j++) {
				let op=optionNumver[j+1];
				if(listQuestions[questionIndex][op].includes("<strike>")){
					strikedOptions.push(j+1);
				}
				if(listQuestions[questionIndex][op].includes("highlighted")){
					highlightedOptions.push({ 
						opt:j+1,
						data:listQuestions[questionIndex][op] 
					});
				}
			}
			if(listQuestions[questionIndex].question_text&&listQuestions[questionIndex].question_text.includes("highlighted")){
				highlightedOptions.push({
					opt:99,
					data:listQuestions[questionIndex].question_text,
				});
			}
			if(strikedOptions&&strikedOptions.length>0||highlightedOptions&&highlightedOptions.length>0){
				payload.highlight_strike_data={
					q_id:Number(listQuestions[questionIndex].question_id),
					strike:strikedOptions,
					highlight:highlightedOptions&&highlightedOptions.length>0?highlightedOptions:[],
				};
			}
		}else{
			if((categoryDetails&&categoryDetails.isTimed!==null&&categoryDetails.isTimed===true)||(topicDetails&&topicDetails.isTimed!==null&&
				topicDetails.isTimed===true)){
				payload.total_time_taken=time;
			}
			// else if(topicDetails&&topicDetails.isTimed!==null&&
			// 	topicDetails.isTimed===true){
			// 	payload.total_time_taken=time;
			// }
		}
		payload.test_status=2;
		let markedForLaterQuestionsList=[];
		for (let index = 0; index < listQuestions.length; index++) {
			if(listQuestions&&listQuestions[index].markedForLater===true){
				markedForLaterQuestionsList.push(listQuestions[index].question_id);
			}
		}
		sessionStorage.setItem("markedForLaterQuestions", JSON.stringify(markedForLaterQuestionsList));
		setMarkedForLaterQuestions(markedForLaterQuestionsList);
		if(markedForLaterQuestionsList&&markedForLaterQuestionsList.length>0){
			payload.marked_for_later=markedForLaterQuestionsList;
		}else{
			payload.marked_for_later=[];
		}
		fetchApi(`/score_card/${scoreCardId}`, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					listQuestions[questionIndex].isUpdatedToApi=true;
					sessionStorage.setItem("quitTest","true");
					setCompleteTest(false);
					clearInterval(myTimer);
					clearInterval(timerUp);
					setColumns(constants.columnsOfDragAndDrop);
					setPauseTestLoading(false);
					setPristine();
					history.push("/my-tests");
				} else {
					setPauseTestLoading(false);
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setNextAndPreviousLoading(false);
					if(res.error_message==="Invalid token"){
						message.error(constants.LoggedInAnotherBrowser);
					}else{
						if(res.code===600){
							message.error(res.message);
						}else{
							message.error("Failed");
						}
					}
				}
				setPauseTestLoading(false);
			});
	};
		//Executes score_card PUT method API when time runs out
	const timeOut=()=>{
		let scoreCardIdFromSession=Number(sessionStorage.getItem("scoreCardId"));
		let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		let questionIndexFromSession=Number(sessionStorage.getItem("questionIndex"));
		let categoryDetailsFromSession=JSON.parse(sessionStorage.getItem("categoryDetails")||"null");
		let topicDetailsFromSession=JSON.parse(sessionStorage.getItem("topicDetails")||"null");
		setCompleteTestLoading(true);
		let payload = {};
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		today = yyyy + '-'+ mm + '-' + dd;
		if(listQuestionsFromSession&&listQuestionsFromSession.length>0&&listQuestionsFromSession[questionIndexFromSession].selectedAnswer&&listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length>0||
			listQuestionsFromSession[questionIndexFromSession].markedForLater===true||
			listQuestionsFromSession[questionIndexFromSession].markedForLater===false){
			if(listQuestionsFromSession[questionIndexFromSession].q_type==="1"||listQuestionsFromSession[questionIndexFromSession].q_type==="2"){
				var sequence=questionIndexFromSession+1;
				payload.test_data={
					seq:sequence,
					q_id:Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					user_ans:listQuestionsFromSession[questionIndexFromSession].selectedAnswer&&listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length>0?listQuestionsFromSession[questionIndexFromSession].selectedAnswer:[],
					corr_ans:listQuestionsFromSession[questionIndexFromSession].correct_option,
					marked_for_later:listQuestionsFromSession[questionIndexFromSession].markedForLater,
					// answered_on:today,
				};
			}else if(listQuestionsFromSession[questionIndexFromSession].q_type==="4"){
				let answer=[];
				for (let i = 0; i < listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length; i++) {
					answer.push(listQuestionsFromSession[questionIndexFromSession].selectedAnswer[i].index);
				}
				var seq=questionIndexFromSession+1;
				payload.test_data={
					seq:seq,
					q_id:Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					user_ans:answer&&answer.length>0?answer:[],
					corr_ans:listQuestionsFromSession[questionIndexFromSession].correct_option,
					marked_for_later:listQuestionsFromSession[questionIndexFromSession].markedForLater,
					// answered_on:today,
				};
			}else if(listQuestionsFromSession[questionIndexFromSession].q_type==="5"){
				let answer=[];
				for (let i = 0; i < listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length; i++) {
					answer.push(Number(listQuestionsFromSession[questionIndexFromSession].selectedAnswer[i].id));
				}
				var sequ=questionIndexFromSession+1;
				payload.test_data={
					seq:sequ,
					q_id:Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					user_ans:answer&&answer.length>0?answer:[],
					corr_ans:listQuestionsFromSession[questionIndexFromSession].correct_option,
					marked_for_later:listQuestionsFromSession[questionIndexFromSession].markedForLater,
					// answered_on:today,
				};
			}
			if((categoryDetailsFromSession&&categoryDetailsFromSession.isTimed!==null&&categoryDetailsFromSession.isTimed===true)||
			(topicDetailsFromSession&&topicDetailsFromSession.isTimed!==null&&topicDetailsFromSession.isTimed===true)){
				payload.total_time_taken=sessionStorage.getItem("timeTaken");
			}
			let totalOptions=null;
			let optionNumver={};
			if(listQuestionsFromSession[questionIndexFromSession].q_type==="1"||listQuestionsFromSession[questionIndexFromSession].q_type==="2"||listQuestionsFromSession[questionIndexFromSession].q_type==="4"){
				totalOptions=listQuestionsFromSession[questionIndexFromSession].opt_cnt?Number(listQuestionsFromSession[questionIndexFromSession].opt_cnt):0;
				optionNumver=constants.OptionNumbers;
			}else if(listQuestionsFromSession[questionIndexFromSession].q_type==="5"){
				optionNumver=constants.OptionNumberDragAndDrop;
				totalOptions=listQuestionsFromSession[questionIndexFromSession].opt_cnt?Number(listQuestionsFromSession[questionIndexFromSession].opt_cnt.charAt(2)):0;
			}
			let strikedOptions=[];
			let highlightedOptions=[];
			for (let j = 0; j < totalOptions; j++) {
				let op=optionNumver[j+1];
				if(listQuestionsFromSession[questionIndexFromSession][op].includes("<strike>")){
					strikedOptions.push(j+1);
				}
				if(listQuestionsFromSession[questionIndexFromSession][op].includes("highlighted")){
					highlightedOptions.push({ 
						opt:j+1,
						data:listQuestionsFromSession[questionIndexFromSession][op] 
					});
				}
			}
			if(listQuestionsFromSession[questionIndexFromSession].question_text&&listQuestionsFromSession[questionIndexFromSession].question_text.includes("highlighted")){
				highlightedOptions.push({
					opt:99,
					data:listQuestionsFromSession[questionIndexFromSession].question_text,
				});
			}
			if(strikedOptions&&strikedOptions.length>0||highlightedOptions&&highlightedOptions.length>0){
				payload.highlight_strike_data={
					q_id:Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					strike:strikedOptions,
				};
			}
		}else{
			if((categoryDetailsFromSession&&categoryDetailsFromSession.isTimed!==null&&categoryDetailsFromSession.isTimed===true)||
			(topicDetailsFromSession&&topicDetailsFromSession.isTimed!==null&&topicDetailsFromSession.isTimed===true)){
				payload.total_time_taken=sessionStorage.getItem("timeTaken");
			}
		}
		const date= new Date();
		payload.dt_completed=date;
		payload.test_status=1;
		fetchApi(`/score_card/${scoreCardIdFromSession}`, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					listQuestionsFromSession[questionIndexFromSession].isUpdatedToApi=true;
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setCompleteTest(false);
					// clearInterval(myTimer);
					// clearInterval(timerUp);
					setCompleteTestLoading(false);
					setVisibileTimeOut(true);
				} else {
					setCompleteTestLoading(false);
					sessionStorage.setItem("quitTest","true");
					setPristine();
					setNextAndPreviousLoading(false);
					if(res.error_message==="Invalid token"){
						message.error(constants.LoggedInAnotherBrowser);
					}else{
						if(res.code===600){
							message.error(res.message);
						}else{
							message.error("Failed");
						}
					}
				}
				setCompleteTestLoading(false);
			});
	};
	const handleTimeOutOk=()=>{
		let testCategory=null;
		let resultTypeFull=null;
		let isTopic=null;
		sessionStorage.setItem("quitTest","true");
		setPristine();
		if(categoryDetails&&categoryDetails.type==="normal"){
			if(categoryDetails.catType&&categoryDetails.catType==="MIXED"){
				resultTypeFull=false;
				testCategory=categoryDetails.catType;
				isTopic=false;
			}else{
				resultTypeFull=true;
				testCategory=categoryDetails.catType;
				isTopic=false;
			}
		}
		if(topicDetails&&topicDetails.type==="normal"){
			resultTypeFull=false;
			testCategory=null;
			isTopic=true;
		}
		history.push({
			pathname: "/result-page",
			state: { 
				scoreCardId:scoreCardId,
				resultTypeFull:resultTypeFull,
				testCategory:testCategory,
				isTopic:isTopic,
			}
		});
	};
	//Executes on state change of questionIndex state.
	useEffect(() => {
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="4"){
			let sortItems=[];
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
				sortItems=listQuestions[questionIndex].selectedAnswer;
			}else{
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
					sortItems.push({
						index:1,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
					sortItems.push({
						index:2,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
					sortItems.push({
						index:3,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
					sortItems.push({
						index:4,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
					sortItems.push({
						index:5,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
					sortItems.push({
						index:6,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
					sortItems.push({
						index:7,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
					sortItems.push({
						index:8,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth 
					});
				}
			}
			setSortItems(sortItems);
			setRefresh(!refresh);
		}else if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="5"){
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
				setColumns(listQuestions[questionIndex].columns);
			}else{
				let Items=[];
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
					Items.push({
						id:`${1}`,
						content:`${listQuestions[questionIndex].option_one }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
					Items.push({
						id:`${2}`,
						content:`${listQuestions[questionIndex].option_two }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
					Items.push({
						id:`${3}`,
						content:`${listQuestions[questionIndex].option_three }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
					Items.push({
						id:`${4}`,
						content:`${listQuestions[questionIndex].option_four }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
					Items.push({
						id:`${5}`,
						content:`${listQuestions[questionIndex].option_five}`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
					Items.push({
						id:`${6}`,
						content:`${listQuestions[questionIndex].option_six}`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
					Items.push({
						id:`${7}`,
						content:`${listQuestions[questionIndex].option_seven }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
					Items.push({
						id:`${8}`,
						content:`${listQuestions[questionIndex].option_eight}`
					});
				}
				let columnsObject={
					[1]: { items: [] },
					[2]: { items: [] },
					[3]: { items: [] },
					[4]: { items: [] },
					[5]: { items: [] },
					[6]: { items: [] },
					[7]: { items: [] },
					[8]: { items: [] },
					[9]: { items: [] },
				};
				columnsObject[1].items=Items;
				setColumns(columnsObject);
				setRefresh(!refresh);
			}
		}
	}, [questionIndex]);
	//drag and drop
	// a function to help us with reordering the result
	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		// userSelect: "none",
		width: "calc(50% - 12px)",
		minWidth: '300px',
		marginRight: "12px",		// margin: '1%',
		marginBottom: "15px",
		alignItems: "center",
		flexWrap: "wrap",
		// change background colour if dragging
		background: "white",
		display: "inline-flex",
		border: isDragging ? "none" : "1px solid lightgrey",
		overflow: "hidden",
		borderImage: "none",
		borderRadius: "30px",
		position: 'relative',
		// styles we need to apply on draggables
		...draggableStyle,
	});
	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightgrey" : "white",
		padding: "grid 0",
		marginRight: "-12px",
	});
	const onDragEnd = (result, columnsObject, setColumns) => {
		if (!result.destination) return;
		const { source, destination } = result;
		if (source.droppableId !== destination.droppableId) {
			if(columnsObject[Number(result.destination.droppableId)].items.length===1){
				columnsObject[1].items.push(columns[Number(result.destination.droppableId)].items[0]);
				columnsObject[Number(result.destination.droppableId)].items.splice(0,1);
			}
			const sourceColumn = columnsObject[source.droppableId];
			const destColumn = columnsObject[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			for (let index = 0; index < sourceItems.length; index++) {
				if(sourceItems[index]===null||sourceItems[index]===undefined){
					sourceItems.splice(index,1);
				}
			}
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);
			setColumns({
				...columnsObject,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems
				}
			});
			setRefresh(!refresh);
			let selectedOption={
				...columnsObject,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems
				}
			};
			let selectedAnswer=[];
			Object.entries(selectedOption).forEach(([key, value]) => {
				if(key>1){
					if(value.items&&value.items.length>0){
						for (let j = 0; j < value.items.length; j++) {
							selectedAnswer.push(value.items[j]);
						}
					}
				}
			});
			listQuestions[questionIndex].selectedAnswer=selectedAnswer;
			listQuestions[questionIndex].columns=selectedOption;
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
			setRefresh(!refresh);
		} else {
			return;
		}
	};
	//end of drag and drop code
	//On check function for single select question options.
	const handleSingleSelectAnswer = e => {
		if(e.target.value){
			if(listQuestions&&listQuestions.length>0){
				if(isSearch==="true"||isQuestionIndexOpened==="true"){
					listQuestions[questionIndex].selectedAnswer=[e.target.value];
				}else{
					listQuestions[questionIndex].selectedAnswer=[e.target.value];
					listQuestions[questionIndex].oldSelectedAnswer=[e.target.value];
				}
				let option=constants.OptionNumbers[e.target.value];
				if(listQuestions[questionIndex][option]&&listQuestions[questionIndex][option].includes("<strike>")){
					listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("<strike>","");
					listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("</strike>","");
				}
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				setRefresh(!refresh);
			}
		}
	};
	//On check function for multi select question options.
	const onChangeMultipleChoice=(checkedValues)=>{
		let sortedAnswer=[];
		let unSortedAnswer=cloneDeep(checkedValues);
		sortedAnswer=checkedValues.sort();
		if(listQuestions&&listQuestions.length>0){
			if(isSearch==="true"||isQuestionIndexOpened==="true"){
				listQuestions[questionIndex].selectedAnswer=sortedAnswer;
			}else{
				listQuestions[questionIndex].selectedAnswer=sortedAnswer;
				listQuestions[questionIndex].oldSelectedAnswer=sortedAnswer;
			}
			let option=constants.OptionNumbers[unSortedAnswer[unSortedAnswer.length-1]];
			if(listQuestions[questionIndex][option]&&listQuestions[questionIndex][option].includes("<strike>")){
				listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("<strike>","");
				listQuestions[questionIndex][option]=listQuestions[questionIndex][option].replace("</strike>","");
			}
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
			setRefresh(!refresh);
		}
	};
	//Function to check answers on click of check button on complete test popup
	const checkAnswers=()=>{
		sessionStorage.setItem("checkAnswer","true");
		sessionStorage.setItem("checkMarkLater","false");
		setCheckMarkLater("false");
		setCheckAnswer("true");
		setCompleteTest(false);
		setQuestionIndex(0);
		sessionStorage.setItem("questionIndex",0);
	};
	//Function to mark the question as mark for later.
	const handleMarkForLater=()=>{
		if(listQuestions&&listQuestions.length>0){
			let marked=listQuestions[questionIndex].markedForLater;
			listQuestions[questionIndex].markedForLater=!marked;
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
			setRefresh(!refresh);
			questionIndexData();
		}
	};
	//Function to hide popup of Mark for later on click of Ok button.
	const handleOkMarkForLaterModal=()=>{
		sessionStorage.setItem("checkMarkLater","true");
		//Display marked for later questions on clic of OK.
		if(listQuestions&&listQuestions.length>0){
			for (let index = 0; index < listQuestions.length; index++) {
				if(listQuestions[index].markedForLater===true){
					setQuestionIndex(index);
					listQuestions[index].markedForLater=false;
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
					setQuestions(listQuestions);
					sessionStorage.setItem("questionIndex",index);
					break;
				}
			}
		}
		setCheckMarkLater("true");
		setMarkedLaterVisible(false);
	};
	//Function to handle Strike through options of all types of questions.
	const handleStrikeThrough=()=>{
		if(listQuestions&&listQuestions.length>0){
			let optionNumver=constants.OptionNumbers;
			if(listQuestions[questionIndex].q_type==="1"){
				let ddd=optionNumver[listQuestions[questionIndex].selectedAnswer[0]];
				if(listQuestions[questionIndex][ddd].includes("<strike>")){
					listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
					listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
				}else{
					listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
					listQuestions[questionIndex].selectedAnswer=null;
				}
			}else if(listQuestions[questionIndex].q_type==="2"){
				for (let i = 0; i < listQuestions[questionIndex].selectedAnswer.length; i++) {
					let ddd=optionNumver[listQuestions[questionIndex].selectedAnswer[i]];
					if(listQuestions[questionIndex][ddd].includes("<strike>")){
						listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
						listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
					}else{
						listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
						if(listQuestions[questionIndex].selectedAnswer[i]===listQuestions[questionIndex].selectedAnswer[listQuestions[questionIndex].selectedAnswer.length-1]){
							listQuestions[questionIndex].selectedAnswer.splice(i,1);
						}
					}
				}
				listQuestions[questionIndex].selectedAnswer=null;
			}else if(listQuestions[questionIndex].q_type==="4"){
				for (let i = 0; i < sortitems.length; i++) {
					if(sortitems[i].index===selectedSequenceOption){
						let ddd=optionNumver[sortitems[i].index];
						if(sortitems[i].value.includes("<strike>")){
							sortitems[i].value=sortitems[i].value.replace("<strike>","");
							sortitems[i].value=sortitems[i].value.replace("</strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
							setSelectedSequenceOption(sortitems[i].index);
						}else{
							sortitems[i].value=`<strike>${sortitems[i].value}</strike>`;
							listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
							setSelectedSequenceOption(sortitems[i].index);
						}
					}
				}
			}else if(listQuestions[questionIndex].q_type==="5"){
				let dragAndDropOptions=constants.OptionNumberDragAndDrop;
				for (let i = 0; i < columns[1].items.length; i++) {
					if(columns[1].items[i].id===selectedDragOption){
						let ddd=dragAndDropOptions[columns[1].items[i].id];
						if(columns[1].items[i].content.includes("<strike>")){
							columns[1].items[i].content=columns[1].items[i].content.replace("<strike>","");
							columns[1].items[i].content=columns[1].items[i].content.replace("</strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("<strike>","");
							listQuestions[questionIndex][ddd]=listQuestions[questionIndex][ddd].replace("</strike>","");
						}else{
							columns[1].items[i].content=`<strike>${columns[1].items[i].content}</strike>`;
							listQuestions[questionIndex][ddd]=`<strike>${listQuestions[questionIndex][ddd]}</strike>`;
						}
					}
				}
				setColumns(columns);
				listQuestions[questionIndex].columns=columns;
				// listQuestions[questionIndex].selectedOption=items;
			}
			setQuestions(listQuestions);
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setRefresh(!refresh);
		}
		setRefresh(!refresh);
	};
	//Function to select the arrange the sequence option on click
	const	handleSequence=(e)=>{
		setSelectedSequenceOption(e);
	};
	//Function to select the Drag and drop option on click
	const handleDragDropOption=(e)=>{
		setSelectedDragOption(e);
	};
		//Function to set the coordinate of selected text on Mouse down for highlighting the text
	const handleMouseDown=(e,id)=>{
		setonMouseDownId(Number(id));
		setSelectedSequenceOption("");
		if(e.changedTouches&&e.changedTouches[0]&&e.changedTouches[0].pageX){
			setMouseDown(e.changedTouches[0].pageX);
		}else{
			setMouseDown(e.pageX);
		}
		setRefresh(!refresh);
	};
		//Function to set the coordinate of selected text on Mouse up for highlighting the text
	const handleMouseUp=(e,id)=>{
		setSelectedId(Number(id));
		setSelectedSequenceOption("");
		if(e.changedTouches&&e.changedTouches[0]&&e.changedTouches[0].pageX){
			setMouseUp(e.changedTouches[0].pageX);
		}else{
			setMouseUp(e.pageX);
		}
		setRefresh(!refresh);
	};
		//Function to handle Highlight text to options.
	// eslint-disable-next-line no-unused-vars
	const handleHighlightText=()=> {
		if(selectedId===onMouseDownId&&selectedId!==null&&onMouseDownId!==null){
			if(selectedId===1||selectedId===2||selectedId===3||selectedId===4||selectedId===5||selectedId===6||selectedId===7||selectedId===8||selectedId===99){
				var highlighted = false;
				var selection = window.getSelection();
				var selectedText = selection.toString();
				var startPoint = window.getSelection().getRangeAt(0).startOffset;
				var endPoint = window.getSelection().getRangeAt(0).endOffset;
				var anchorTag = selection.anchorNode.parentNode;
				var focusTag = selection.focusNode.parentNode;
				if ((mouseUp - mouseDown) < 0) {
					focusTag = selection.anchorNode.parentNode;
					anchorTag = selection.focusNode.parentNode;
				}
				if (selectedText.length === (endPoint - startPoint)) {
					highlighted = true;
					if (anchorTag.className !== "highlighted") {
						highlightSelection();
					} else {
						var afterText = selectedText + "<span class = 'highlighted'>" + anchorTag.innerHTML.substr(endPoint) + "</span>";
						anchorTag.innerHTML = anchorTag.innerHTML.substr(0, startPoint);
						anchorTag.insertAdjacentHTML('afterend', afterText);
					}
				}else{
					if(anchorTag.className !== "highlighted" && focusTag.className !== "highlighted"){
						highlightSelection();  
						highlighted = true;
					}
				}
				if (anchorTag.className === "highlighted" && focusTag.className === 'highlighted' && !highlighted) {
					highlighted = true;
					var afterHtml = anchorTag.innerHTML.substr(startPoint);
					var outerHtml = selectedText.substr(afterHtml.length, selectedText.length - endPoint - afterHtml.length);
					var anchorInnerhtml = anchorTag.innerHTML.substr(0, startPoint);
					var focusInnerHtml = focusTag.innerHTML.substr(endPoint);
					var focusBeforeHtml = focusTag.innerHTML.substr(0, endPoint);
					selection.deleteFromDocument();
					anchorTag.innerHTML = anchorInnerhtml;
					focusTag.innerHTml = focusInnerHtml;
					var anchorafterHtml = afterHtml + outerHtml + focusBeforeHtml;
					anchorTag.insertAdjacentHTML('afterend', anchorafterHtml);
				}
				if (anchorTag.className === "highlighted" && !highlighted) {
					highlighted = true;
					if(startPoint!==0||endPoint!==0){
						var Innerhtml = anchorTag.innerHTML.substr(0, startPoint);
						var afterHtmll = anchorTag.innerHTML.substr(startPoint);
						var outerHtmll = selectedText.substr(afterHtmll.length, selectedText.length);
						selection.deleteFromDocument();
						if(afterHtml){
							anchorTag.innerHTML = Innerhtml;
							anchorTag.insertAdjacentHTML('afterend', afterHtmll + outerHtmll);
						}else{
							anchorTag.insertAdjacentHTML('afterend', selectedText );
						}
					}
				}
				if (focusTag.className === 'highlighted' && !highlighted) {
					var pointValue=0;
					if(startPoint>endPoint){
						pointValue=startPoint;
					}else{
						pointValue=endPoint;
					}
					highlighted = true;
					var beforeHtml = focusTag.innerHTML.substr(0, endPoint);
					var outerHtmls = selectedText.substr(0, selectedText.length - beforeHtml.length);
					selection.deleteFromDocument();
					focusTag.innerHTml = focusTag.innerHTML.substr(pointValue);
					outerHtmls += beforeHtml;
					focusTag.insertAdjacentHTML('beforebegin', outerHtmls );
				}
				if (!highlighted) {
					highlightSelection();
				}
				$('.highlighted').each(function(){
					if($(this).html() == ''||$(this).html() == '&nbsp;'){
						$(this).remove();
					}
				});
				selection.removeAllRanges();
				// eslint-disable-next-line no-inner-declarations
				function highlightSelection() {
					var selectionText;
					//Get the selected stuff
					if (window.getSelection){
						selectionText = window.getSelection();
					}
					else if (typeof document.selection != "undefined"){
						selectionText = document.selection;
					}
					//Get a the selected content, in a range object
					var range = selectionText.rangeCount >= 1&&selectionText.getRangeAt(0);
					//If the range spans some text, and inside a tag, set its css class.
					if (range && !selectionText.isCollapsed) {
						if (selectionText.anchorNode.parentNode == selectionText.focusNode.parentNode) {
							var span = document.createElement('span');
							span.className = 'highlighted';
							span.textContent = selectionText.toString();
							selectionText.deleteFromDocument();
							range.insertNode(span);
							//                        range.surroundContents(span);
						}
					}
				}
				if(listQuestions&&listQuestions.length>0){
					if(listQuestions[questionIndex].q_type&&listQuestions[questionIndex].q_type==="1"||
				listQuestions[questionIndex].q_type&&listQuestions[questionIndex].q_type==="2"){
						let optionNumver=constants.OptionNumbers;
						let ddd=optionNumver[selectedId];
						let m =document.getElementById(selectedId).innerHTML;
						listQuestions[questionIndex][ddd]=m;
					}else if(listQuestions[questionIndex].q_type==="4"){
						let optionNumver=constants.OptionNumbers;
						if(selectedId===99){
							let ddd=optionNumver[selectedId];
							let m =document.getElementById(selectedId).innerHTML;
							listQuestions[questionIndex][ddd]=m;
						}else{
							for (let i = 0; i < sortitems.length; i++) {
								if(sortitems[i].index===selectedId){
									let ddd=optionNumver[sortitems[i].index];
									let m =document.getElementById(selectedId).innerHTML;
									sortitems[i].value=m;
									listQuestions[questionIndex][ddd]=m;
								}
							}
						}
					}else if(listQuestions[questionIndex].q_type==="5"){
						let optionNumver=constants.OptionNumberDragAndDrop;
						if(selectedId===99){
							let ddd=optionNumver[selectedId];
							let m =document.getElementById(selectedId).innerHTML;
							listQuestions[questionIndex][ddd]=m;
						}else{
							if(columns&&columns[1].items&&columns[1].items.length>0){
								for (let j = 0; j < columns[1].items.length; j++) {
									if(Number(columns[1].items[j].id)===selectedId){
										let ddd=optionNumver[Number(columns[1].items[j].id)];
										let m =document.getElementById(selectedId).innerHTML;
										columns[1].items[j].content=m;
										listQuestions[questionIndex][ddd]=m;
									}
								}
							}
						}
						setColumns(columns);
					}
					setQuestions(listQuestions);
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				}
			}
		}else{
			if (window.getSelection) {
				if (window.getSelection().empty) {  // Chrome
					window.getSelection().empty();
				} else if (window.getSelection().removeAllRanges) {  // Firefox
					window.getSelection().removeAllRanges();
				}
			} else if (document.selection) {  // IE?
				document.selection.empty();
			}
		}
		setSelectedId(null);
		setRefresh(!refresh);
	};
	const onSearch=(value)=>{
		setIsQuestionIndexOpened("false");
		sessionStorage.setItem("isQuestionIndexOpened","false");
		if(value){
			searchValue=value;
		}
		if(searchValue){
			if(listQuestions&&listQuestions.length>0){
				let questionIndexCount=Number(sessionStorage.getItem("questionIndexCount"));
				let questionIndexFromSession=Number(sessionStorage.getItem("questionIndex"));
				if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0||listQuestions[questionIndex].markedForLater===true){
					if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length&&listQuestions[questionIndex].markedForLater===false){
						if(checkAnswer==="true"){
							message.error(constants.SelectOptionDragAndDropTutorials);
						}else{
							message.error(constants.SelectOptionDragAndDrop);
						}
					}else{
						onSearchUpdate(searchValue);
					}
				}else{
					// message.error(constants.SelectOption);
					if(questionIndexFromSession<questionIndexCount){
						if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length===0||listQuestions[questionIndex].markedForLater===false){
							if(checkAnswer==="true"){
								message.error(constants.SelectOptionOnly);
							}else{
								message.error(constants.SelectOption);
							}
						}else{
							onSearchUpdate(searchValue);
						}
					}else{
						onSearchUpdate(searchValue);
					}
				}
			}
		}
	};
	const onSearchUpdate=(searchValue)=>{
		setIsSearch("true");
		sessionStorage.setItem("isSearch","true");
		setDisplaySearch("true");
		sessionStorage.setItem("displaySearch","true");
		if(listQuestions&&listQuestions.length>0){
			let searchQuestions=[];
			for (let index = 0; index < listQuestions.length; index++) {
				if(listQuestions[index].topic_title&&searchValue===listQuestions[index].topic_title){
					if(listQuestions[index].selectedAnswer&&listQuestions[index].selectedAnswer.length>0||listQuestions[index].markedForLater===true){
						let questionText="";
						let questionTextOfDragAndDrop="";
						if(listQuestions[index].q_type&&listQuestions[index].q_type==="5"){
							questionText+=listQuestions[index].question_text;
							// eslint-disable-next-line no-unused-vars
							Object.entries(constants.OptionNumbers).forEach(([key, valueData]) => {
								if(listQuestions[index][valueData]){
									if(listQuestions[index][valueData].includes("~")){
										questionTextOfDragAndDrop+="_____________  ";
									}else{
										questionTextOfDragAndDrop+=listQuestions[index][valueData].substring(0,listQuestions[index][valueData].indexOf("|"));
									}
								}
							});
						}else{
							questionText=listQuestions[index].question_text;
						}
						searchQuestions.push({
							questionId:listQuestions[index].question_id,
							questionIndex:index,
							questionText:questionText,
							questionTextOfDragAndDrop:questionTextOfDragAndDrop
						});
					}
				}
			}
			sessionStorage.setItem("searchQuestions",JSON.stringify(searchQuestions));
		}
	};
	const resumeTest=()=>{
		if(listQuestions&&listQuestions.length>0){
			let displaySearchFromSession=sessionStorage.getItem("displaySearch");
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0||listQuestions[questionIndex].markedForLater===true||displaySearchFromSession==="true"){
				if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length&&listQuestions[questionIndex].markedForLater===false&&displaySearchFromSession==="false"){
					if(checkAnswer==="true"){
						message.error(constants.SelectOptionDragAndDropTutorials);
					}else{
						message.error(constants.SelectOptionDragAndDrop);
					}
				}else{
					// if(listQuestions[questionIndex].oldSelectedAnswer){
					// 	if(listQuestions[questionIndex].q_type==="4"){
					// 		var match=true;
					// 		for (let index = 0; index < listQuestions[questionIndex].oldSelectedAnswer.length; index++) {
					// 			for (let j = 0; j < listQuestions[questionIndex].selectedAnswer.length; j++) {
					// 				if(listQuestions[questionIndex].oldSelectedAnswer[j].index!==listQuestions[questionIndex].selectedAnswer[j].index){
					// 					match=false;
					// 					break;
					// 				}
					// 			}
					// 		}
					// 		if(match===false){
					// 			listQuestions[questionIndex].selectedAnswer=listQuestions[questionIndex].oldSelectedAnswer;
					// 		}
					// 	}else if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"){
					// 		if(listQuestions[questionIndex].oldSelectedAnswer.toString()!==listQuestions[questionIndex].selectedAnswer.toString()){
					// 			listQuestions[questionIndex].selectedAnswer=listQuestions[questionIndex].oldSelectedAnswer;
					// 		}
					// 	}
					// }
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
					setQuestions(listQuestions);
					handleNextQuestion("SAVE");
					if(checkAnswer==="true"){
						let tempQuestionIndex=Number(sessionStorage.getItem("tempQuestionIndex"));
						setQuestionIndex(tempQuestionIndex);
						sessionStorage.setItem("questionIndex",tempQuestionIndex);
						sessionUpdateAfterResume();
					}else{
						var matchQuestion=false;
						for (let index = 0; index < listQuestions.length; index++) {
							if(listQuestions[index].isUpdatedToApi===false){
								setQuestionIndex(index);
								sessionStorage.setItem("questionIndex",index);
								sessionStorage.setItem("questionIndexCount",index);
								sessionUpdateAfterResume();
								matchQuestion=true;
								break;
							}
						}
						if(matchQuestion===false){
							let tempQuestionIndex=Number(sessionStorage.getItem("tempQuestionIndex"));
							setQuestionIndex(tempQuestionIndex);
							sessionStorage.setItem("questionIndex",tempQuestionIndex);
							sessionUpdateAfterResume();
						}
					}
				}
			}else{
				if(checkAnswer==="true"){
					message.error(constants.SelectOptionOnly);
				}else{
					message.error(constants.SelectOption);
				}
			}
		}
	};
	//Function to update the session storage variables after clicking on the Resume test button.
	const sessionUpdateAfterResume=()=>{
		questionIndexData();
		setIsSearch("false");
		sessionStorage.setItem("isSearch","false");
		setDisplaySearch("false");
		sessionStorage.setItem("displaySearch","false");
		setIsQuestionIndexOpened("false");
		sessionStorage.setItem("isQuestionIndexOpened","false");
		setSearchValue(null);
		sessionStorage.removeItem("searchValue");
	};
	//Function to select topic in search box
	const onSelect = (value) => {
		if(listQuestions&&listQuestions.length>0){
			let questionIndexCount=Number(sessionStorage.getItem("questionIndexCount"));
			let questionIndexFromSession=Number(sessionStorage.getItem("questionIndex"));
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0||listQuestions[questionIndex].markedForLater===true){
				if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length&&listQuestions[questionIndex].markedForLater===false){
					setSearchValue(value);
					sessionStorage.setItem("searchValue",value);
					onSearch(value);
				}else{
					setSearchValue(value);
					handleNextQuestion("QUESTION");
					sessionStorage.setItem("searchValue",value);
					onSearch(value);
				}
			}else{
				if(questionIndexFromSession<questionIndexCount){
					if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length===0||listQuestions[questionIndex].markedForLater===false){
						if(checkAnswer==="true"){
							message.error(constants.SelectOptionOnly);
						}else{
							message.error(constants.SelectOption);
						}
					}else{
						setSearchValue(value);
						sessionStorage.setItem("searchValue",value);
						onSearch(value);
					}
				}else{
					setSearchValue(value);
					sessionStorage.setItem("searchValue",value);
					onSearch(value);
				}
			}
		}
	};
	const columnsQuestions=[
		{
			title: false,
			key: 'questions',
			align: "center",
			width: '80px',
			render: (record) => (
				<div className="d-flex d-flex-wrap" onClick={()=>{handleClickSearchQuestion(record);}}>
					<Button type="link" className="search-link">
						<b><span className="test-question">Q{record&&record.questionIndex!==null?(record.questionIndex+1):""}{". "}</span></b>
						<span>
							<span onCopy={()=>{return false;}} className="test-question"  dangerouslySetInnerHTML={{ __html:record&&record.questionText?record.questionText:"" }}></span>
							{record.questionTextOfDragAndDrop?(<span onCopy={()=>{return false;}} className="test-question d-flex"  dangerouslySetInnerHTML={{ __html:record&&record.questionTextOfDragAndDrop?record.questionTextOfDragAndDrop:"" }}></span>):""}
						</span>
					</Button>
				</div>
			),
		},
	];
	const handleClickSearchQuestion=(record)=>{
		sessionStorage.setItem("tempQuestionIndex",questionIndex);
		setQuestionIndex(Number(record.questionIndex));
		sessionStorage.setItem("questionIndex",Number(record.questionIndex));
		setDisplaySearch("false");
		sessionStorage.setItem("displaySearch","false");
	};
	const questionIndexData=()=>{
		if(questionIndexList&&questionIndexList.length>0){
			var found = false;
			for(var i = 0; i < questionIndexList.length; i++) {
				if (questionIndexList[i].index ===questionIndex) {
					found = true;
					questionIndexList[i].index=questionIndex;
					questionIndexList[i].category=listQuestions[questionIndex].category_abbreviation;
					questionIndexList[i].value=listQuestions[questionIndex].topic_title;
					questionIndexList[i].markedForLater=listQuestions[questionIndex].markedForLater;
					questionIndexList[i].notes=listQuestions[questionIndex].notes;
					questionIndexList[i].topic_abbreviation=listQuestions[questionIndex].topic_abbreviation;
					break;
				}
			}
			if(!found){
				questionIndexList.push({ 
					index:questionIndex,
					category:listQuestions[questionIndex].category_abbreviation,
					value:listQuestions[questionIndex].topic_title,
					markedForLater:listQuestions[questionIndex].markedForLater,
					notes:listQuestions[questionIndex].notes,
					topic_abbreviation:listQuestions[questionIndex].topic_abbreviation,
				});
			}
		}else{
			questionIndexList.push({ 
				index:questionIndex,
				value:listQuestions[questionIndex].topic_title,
				category:listQuestions[questionIndex].category_abbreviation,
				markedForLater:listQuestions[questionIndex].markedForLater,
				notes:listQuestions[questionIndex].notes,
				topic_abbreviation:listQuestions[questionIndex].topic_abbreviation,
			});
		}
		sortArrayOfObjects(questionIndexList,"index",null,true);
		setQuestionIndexList(questionIndexList);
		sessionStorage.setItem("questionIndexList",JSON.stringify(questionIndexList));
	};
	const singleSelect = (
		<Card className="testSection">
			<div className='d-flex mb-3'>
				<p className="test-question" id="99" onTouchStart={(e)=>{handleMouseDown(e,99);}} onTouchEnd={(e)=>{handleMouseUp(e,99);}}  onMouseDown={(e)=>{handleMouseDown(e,99);}} onMouseUp={(e)=>{handleMouseUp(e,99);}}  dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}
			</div>
			<Radio.Group className="test-options w-100 options-counter"
				onChange={handleSingleSelectAnswer} 
				value={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0&&listQuestions[questionIndex].selectedAnswer[0]}
			>
				<Space direction="vertical" className="w-100">
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?<Radio value={1} id="option1" className="options options-inner" ><p onTouchStart={(e)=>{handleMouseDown(e,1);}} onTouchEnd={(e)=>{handleMouseUp(e,1);}} onMouseDown={(e)=>{handleMouseDown(e,1);}} onMouseUp={(e)=>{handleMouseUp(e,1);}} id="1" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?<Radio value={2} id="option2" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,2);}} onTouchEnd={(e)=>{handleMouseUp(e,2);}} onMouseDown={(e)=>{handleMouseDown(e,2);}} onMouseUp={(e)=>{handleMouseUp(e,2);}} id="2" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?<Radio value={3} id="option3" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,3);}} onTouchEnd={(e)=>{handleMouseUp(e,3);}} onMouseDown={(e)=>{handleMouseDown(e,3);}} onMouseUp={(e)=>{handleMouseUp(e,3);}} id="3" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?<Radio value={4} id="option4" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,4);}} onTouchEnd={(e)=>{handleMouseUp(e,4);}} onMouseDown={(e)=>{handleMouseDown(e,4);}} onMouseUp={(e)=>{handleMouseUp(e,4);}} id="4" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?<Radio value={5} id="option5" className="options options-inner" ><p onTouchStart={(e)=>{handleMouseDown(e,5);}} onTouchEnd={(e)=>{handleMouseUp(e,5);}} onMouseDown={(e)=>{handleMouseDown(e,5);}} onMouseUp={(e)=>{handleMouseUp(e,5);}} id="5" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?<Radio value={6} id="option6" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,6);}} onTouchEnd={(e)=>{handleMouseUp(e,6);}} onMouseDown={(e)=>{handleMouseDown(e,6);}} onMouseUp={(e)=>{handleMouseUp(e,6);}} id="6" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?<Radio value={7} id="option7" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,7);}} onTouchEnd={(e)=>{handleMouseUp(e,7);}} onMouseDown={(e)=>{handleMouseDown(e,7);}} onMouseUp={(e)=>{handleMouseUp(e,7);}} id="7" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></p></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?<Radio value={8} id="option8" className="options options-inner"><p onTouchStart={(e)=>{handleMouseDown(e,8);}} onTouchEnd={(e)=>{handleMouseUp(e,8);}} onMouseDown={(e)=>{handleMouseDown(e,8);}} onMouseUp={(e)=>{handleMouseUp(e,8);}} id="8" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></p></Radio>:""}
				</Space>
			</Radio.Group>
		</Card>
	);
	const multipleChoice = (
		<Card className="testSection">
			<div className='d-flex mb-3'>
				<p className="test-question" id="99" onTouchStart={(e)=>{handleMouseDown(e,99);}} onTouchEnd={(e)=>{handleMouseUp(e,99);}}  onMouseDown={(e)=>{handleMouseDown(e,99);}} onMouseUp={(e)=>{handleMouseUp(e,99);}} dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}
			</div>
			<Checkbox.Group
				style={{ width: "100%" }}
				onChange={onChangeMultipleChoice}
				value={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0&&listQuestions[questionIndex].selectedAnswer}
				className="test-options w-100 options-counter"
			>
				<Space direction="vertical" className="w-100">
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?
						<Checkbox className="options options-inner" value={1} id="option1">
							<p onTouchStart={(e)=>{handleMouseDown(e,1);}} onTouchEnd={(e)=>{handleMouseUp(e,1);}} onMouseDown={(e)=>{handleMouseDown(e,1);}} onMouseUp={(e)=>{handleMouseUp(e,1);}} id="1" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?
						<Checkbox className="options options-inner" value={2} id="option2">
							<p onTouchStart={(e)=>{handleMouseDown(e,2);}} onTouchEnd={(e)=>{handleMouseUp(e,2);}} onMouseDown={(e)=>{handleMouseDown(e,2);}} onMouseUp={(e)=>{handleMouseUp(e,2);}} id="2" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></p>
						</Checkbox>:""}     
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?
						<Checkbox className="options options-inner" value={3} id="option3">
							<p onTouchStart={(e)=>{handleMouseDown(e,3);}} onTouchEnd={(e)=>{handleMouseUp(e,3);}} onMouseDown={(e)=>{handleMouseDown(e,3);}} onMouseUp={(e)=>{handleMouseUp(e,3);}} id="3" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?
						<Checkbox className="options options-inner" value={4} id="option4">
							<p onTouchStart={(e)=>{handleMouseDown(e,4);}} onTouchEnd={(e)=>{handleMouseUp(e,4);}} onMouseDown={(e)=>{handleMouseDown(e,4);}} onMouseUp={(e)=>{handleMouseUp(e,4);}} id="4" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?
						<Checkbox className="options options-inner" value={5} id="option5">
							<p onTouchStart={(e)=>{handleMouseDown(e,5);}} onTouchEnd={(e)=>{handleMouseUp(e,5);}} onMouseDown={(e)=>{handleMouseDown(e,5);}} onMouseUp={(e)=>{handleMouseUp(e,5);}} id="5" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?
						<Checkbox className="options options-inner" value={6} id="option6">
							<p onTouchStart={(e)=>{handleMouseDown(e,6);}} onTouchEnd={(e)=>{handleMouseUp(e,6);}} onMouseDown={(e)=>{handleMouseDown(e,6);}} onMouseUp={(e)=>{handleMouseUp(e,6);}} id="6" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?
						<Checkbox className="options options-inner" value={7} id="option7">
							<p onTouchStart={(e)=>{handleMouseDown(e,7);}} onTouchEnd={(e)=>{handleMouseUp(e,7);}} onMouseDown={(e)=>{handleMouseDown(e,7);}} onMouseUp={(e)=>{handleMouseUp(e,7);}} id="7" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></p>
						</Checkbox>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?
						<Checkbox className="options options-inner" value={8} id="option8">
							<p onTouchStart={(e)=>{handleMouseDown(e,8);}} onTouchEnd={(e)=>{handleMouseUp(e,8);}} onMouseDown={(e)=>{handleMouseDown(e,8);}} onMouseUp={(e)=>{handleMouseUp(e,8);}} id="8" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></p>
						</Checkbox>:""}
				</Space>
			</Checkbox.Group>
		</Card>
	);
	const arrangeSequence = (
		<Card className="testSection">
			<div className='d-flex mb-3'>
				<p className="test-question" id="99" onTouchStart={(e)=>{handleMouseDown(e,99);}} onTouchEnd={(e)=>{handleMouseUp(e,99);}} onMouseDown={(e)=>{handleMouseDown(e,99);}} onMouseUp={(e)=>{handleMouseUp(e,99);}} dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
			</div>			
			<div>
				<p onTouchStart={(e)=>{handleMouseDown(e,101);}} onTouchEnd={(e)=>{handleMouseUp(e,101);}} onMouseDown={(e)=>{handleMouseDown(e,101);}} onMouseUp={(e)=>{handleMouseUp(e,101);}}>* Reorder the correct sequence.</p>				{/* <SortableList sortitems={sortitems} onSortEnd={onSortEnd}shouldCancelStart={handleSequence}/> */}
				<SortableContainer onSortEnd={onSortEnd} useDragHandle>
					<ul className="test-sortable">
						{sortitems.map((item,index) => (
							<SortableItem key={item.index} index={index} value={item.value} indexPos={item.index} handleSequence={handleSequence} MouseDown={(e)=>handleMouseDown(e,item.index)} MouseUp={(e)=>handleMouseUp(e,item.index)} TouchStart={(e)=>handleMouseDown(e,item.index)} TouchEnd={(e)=>handleMouseUp(e,item.index)}/>
						))}
					</ul>
				</SortableContainer>
			</div>
		</Card>
	);
	{/* Component used to display the options draggable component for drag and drop type questions */}
	const draggableComponent=(id)=>{
		return(					
			<div style={{ margin: 8 }}>
				<Droppable droppableId={id}>
					{(provided, snapshot) => {
						return (
							<div
								{...provided.droppableProps}
								className="droppable"
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								{columns&&columns[id]&&columns[id].items&&columns[id].items.length>0&&columns[id].items.map((item, index) => {
									return (
										<Draggable
											key={item&&item.id&&item.id}
											draggableId={item&&item.id&&item.id}
											index={index}
										>
											{(providedProp) => {
												return (
													<div
														ref={providedProp.innerRef}
														{...providedProp.draggableProps}
														{...providedProp.dragHandleProps}
														// style={getItemStyle(
														// 	snapshot.isDragging,
														// 	provided.draggableProps.style
														// )}
													>
														<div className="dnd-item" dangerouslySetInnerHTML={{ __html:item.content }}></div>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</div>
		);
	};
	{/* drag and drop */}
	const dragAndDrop=(
		<Col span={24}>
			<Card className="testSection">
				<DragDropContext
					onDragEnd={result => onDragEnd(result, columns, setColumns)}
				>
					<div className='d-flex d-flex-wrap mb-3 flex-direction-col'>
						<p className="test-question" id="99" onTouchStart={(e)=>{handleMouseDown(e,99);}} onTouchEnd={(e)=>{handleMouseUp(e,99);}}  onMouseDown={(e)=>{handleMouseDown(e,99);}} onMouseUp={(e)=>{handleMouseUp(e,99);}} dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
						{listQuestions&&listQuestions.length>0&&
					<span className="test-question drag-question">
						<Space wrap>
							{listQuestions[questionIndex].option_first?
								listQuestions[questionIndex].option_first.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_first.indexOf("~")) }}></p>{draggableComponent(2)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_first.indexOf("|")) }}></p>)
								:""
							}
							{listQuestions[questionIndex].option_second?
								listQuestions[questionIndex].option_second.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second.substring(0,listQuestions[questionIndex].option_second.indexOf("~")) }}></p>{draggableComponent(3)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second.substring(0,listQuestions[questionIndex].option_second.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_third?
								listQuestions[questionIndex].option_third.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third.substring(0,listQuestions[questionIndex].option_third.indexOf("~")) }}></p>{draggableComponent(4)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third.substring(0,listQuestions[questionIndex].option_third.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_fourth?
								listQuestions[questionIndex].option_fourth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth.substring(0,listQuestions[questionIndex].option_fourth.indexOf("~")) }}></p>{draggableComponent(5)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth.substring(0,listQuestions[questionIndex].option_fourth.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_fifth?
								listQuestions[questionIndex].option_fifth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth.substring(0,listQuestions[questionIndex].option_fifth.indexOf("~")) }}></p>{draggableComponent(6)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth.substring(0,listQuestions[questionIndex].option_fifth.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_sixth?
								listQuestions[questionIndex].option_sixth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth.substring(0,listQuestions[questionIndex].option_sixth.indexOf("~")) }}></p>{draggableComponent(7)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth.substring(0,listQuestions[questionIndex].option_sixth.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_seventh?
								listQuestions[questionIndex].option_seventh.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh.substring(0,listQuestions[questionIndex].option_seventh.indexOf("~")) }}></p>{draggableComponent(8)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh.substring(0,listQuestions[questionIndex].option_seventh.indexOf("|")) }}></p>)
								:""					
							}
							{listQuestions[questionIndex].option_eighth?
								listQuestions[questionIndex].option_eighth.includes("~")?
									<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_eighth.indexOf("~")) }}></p>{draggableComponent(9)}</>:
									(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth.substring(0,listQuestions[questionIndex].option_eighth.indexOf("|")) }}></p>)
								:""					
							}
						</Space>
					</span>
						}
						<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
					</div>
					<Droppable droppableId={"1"}>
						{(provided, snapshot) => {
							return (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{columns&&columns[1]&&columns[1].items&&columns[1].items.length>0&&columns[1].items.map((item, index) => {
										return (
											<Draggable
												key={item&&item.id&&item.id}
												draggableId={item&&item.id&&item.id}
												index={index}
											>
												{(providedProp, snapshots) => {
													return (
														<div
															style={getItemStyle(
																snapshots.isDragging,
																providedProp.draggableProps.style
															)}>
															<div
																ref={providedProp.innerRef}
																{...providedProp.draggableProps}
																{...providedProp.dragHandleProps}
															>
																<DragOutlined className="test-dnd-icon"/>
															</div>
															<span className="dnd-counter">{constants.OptionstoDisplay[item&&item.id&&item.id]}</span>
															<div className="drag-option" onClick={()=>{handleDragDropOption(item&&item.id&&item.id);}} onTouchStart={(e)=>{handleMouseDown(e,item.id);}} onTouchEnd={(e)=>{handleMouseUp(e,item.id);}}  onMouseDown={(e)=>{handleMouseDown(e,item.id);}} onMouseUp={(e)=>{handleMouseUp(e,item.id);}} id={item.id} dangerouslySetInnerHTML={{ __html:item.content }}></div>
														</div>
													);
												}}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							);
						}}
					</Droppable>
				</DragDropContext>
			</Card>
		</Col>
	);
	const createNote=(values)=>{
		if(values.note!==undefined&&values.note!==null){
			let tempValueNote=values.note.trim();
			let tempNote=tempValueNote.replace( /^\s*[\r\n]/gm, "\n" );
			let tempNoteID=listQuestions[questionIndex].note_id;
			let indexTemp=notelist.findIndex((item)=>item.question_id===listQuestions[questionIndex].question_id);
			if(tempNote!==""&&listQuestions[questionIndex].note_id===null){
				listQuestions[questionIndex].notes=tempNote;
				let tempObj={
					note:tempNote,
					question_id:listQuestions[questionIndex].question_id
				};
				if(indexTemp===-1){
					notelist.push(tempObj);
				}else{
					notelist[indexTemp].note=tempNote;
				}
			}else{
				if(indexTemp!==-1&&tempNote===""){
					notelist.splice(indexTemp, 1);
				}
				listQuestions[questionIndex].notes=null;
			}
			if(tempNote!==""&&listQuestions[questionIndex].note_id!==null){
				listQuestions[questionIndex].notes=tempNote;
				let userId = getCookie(COOKIE.UserId);
				let payload=null;
				payload={
					stud_id:userId,
					notes:tempNote,
					score_card_id:scoreCardId,
					question_id:listQuestions[questionIndex].question_id
				};
				fetchApi(`/student/notes/${tempNoteID}`,"put",payload).then((res) => {
					if (res && res.code===200) {
					// message.success(res.message);
					} else {
						message.error(res.message);
					}
				});
			}
			if(tempNote===""&&listQuestions[questionIndex].note_id!==null){
				fetchApi(`/student/notes/${tempNoteID}`, "delete").then((res) => {
					if (res && res.code===200) {
					//	message.success(res.message);
						listQuestions[questionIndex].notes=null;
						listQuestions[questionIndex].note_id=null;
					} else {
						message.error(res.message);
					}
				});
			}
			hideModal();
		}
	};
	const saveNotes=()=>{
		if(notelist.length>0){
			let userId = getCookie(COOKIE.UserId);
			let payload=null;
			let scoreCardIdFromSession=Number(sessionStorage.getItem("scoreCardId"));
			payload={
				stud_id:userId,
				notes:notelist,
				score_card_id:scoreCardIdFromSession,
			};
			fetchApi(`/student/notes`,"post",payload).then((res) => {
				if (res && res.code===200) {
					// message.success(res.message);
				} else {
					message.error(res.message);
				}
			});
		}
	};
	const getColorForTimer=(value)=>{
		if(value){
			let timeOfTest=value.toString();
			var splittedTime = timeOfTest.split(':'); // split it at the colons
			// minutes are worth 60 seconds. Hours are worth 60 minutes.
			var seconds = (+splittedTime[0]) * 60 * 60 + (+splittedTime[1]) * 60 + (+splittedTime[2]); 
			if(seconds<=300){
				return "red-color-time";
			}else{
				return "black-color-time";
			}
		}
	};
	const handleClickQuestionIndex=(record)=>{
		setIsQuestionIndexOpened("true");
		sessionStorage.setItem("tempQuestionIndex",questionIndex);
		sessionStorage.setItem("isQuestionIndexOpened","true");
		setQuestionIndex(record.index);
		sessionStorage.setItem("questionIndex",record.index);
		setvisible(false);
	};
	// const fontDecrease = () => {
	// 	document.body.classList.add('decreaseFont');
	// };
	const columnsQuestionIndex = [
		{
			title: "Q.",
			render: (record) => {
				let index=null;
				if(record){
					index=record.index+1;
				}
				return <span><Button type="link" style={{ color:"black" }} onClick={()=>{handleClickQuestionIndex(record);}}>{index}</Button></span>;
			},
			key: 'index',
			align: "center",
			width: 60,
		},
		{
			title: "Category",
			render: (record) => (
				<span className="pointer" onClick={()=>{handleClickQuestionIndex(record);}}>{record&&record.category?record.category:""}</span>
			),
			key: 'value',
			ellipsis: true,
			width: 60,
		},
		{
			title: "Topic",
			render: (record) => (
				<Tooltip title={record&&record.value?record.value:""}>
					<span className="pointer" onClick={()=>{handleClickQuestionIndex(record);}}>{record&&record.value?record.value.length>constants.minimumCharactersQuestionIndex?record.topic_abbreviation:record.value:""}</span>
				</Tooltip>
			),
			key: 'value',
			ellipsis: true,
		},
		{
			title: false,
			key: 'questions',
			align: "center",
			width: 60,
			render: (record) => (
				<>
					<Space>
						{record&&record.notes!==null&&record.notes!==""?
							<Tooltip title={constants.NoteToottip}><Comment/></Tooltip>:null}
						{record&&record.markedForLater===true&&(
							<Tooltip title={constants.MarkLaterTooltip}><FlagFilled className="flag"/></Tooltip>
						)}
					</Space>
				</>
			),
		},
	];
	return (
		<div className="p-4 full-length-test-container" onCopy={false}>
			{/* <Button onClick={fontDecrease}>A-</Button> */}
			<Row  gutter={[{
				xs: 0,
				sm: 0,
				md: 24,
				lg: 24 
			}, {
				xs: 24,
				sm: 24,
				md: 24,
				lg: 24 
			}]}>
				<Col span={24} >
					<Row justify="space-between" className="pb-4">
						<Col>
							<h3 className="mainHeading mb-0">
								Test - {categoryDetails&&categoryDetails!=={}&&categoryDetails!==null?
									categoryDetails.catType?
										categoryDetails.catType:""
									:""
								}
								{topicDetails&&topicDetails!=={}&&topicDetails!==null?
									topicDetails.topicTitle?
										topicDetails.topicTitle+" (1st Attempt)":""
									:""}
								{categoryDetails&&categoryDetails.attempt!==null?
									categoryDetails.count===25?
										categoryDetails.attempt===1?" #1 (1st Attempt)":categoryDetails.attempt===2?" #2 (1st Attempt)":categoryDetails.attempt===3?" #3 (1st Attempt)":categoryDetails.attempt===4?" #4 (1st Attempt)":categoryDetails.attempt===5?" #5 (1st Attempt)":categoryDetails.attempt===6?" #6 (1st Attempt)":""
										:categoryDetails.count===50?
											categoryDetails.attempt===1?" #3 (1st Attempt)":categoryDetails.attempt===2?" #4 (1st Attempt)":""
											:""
									:""}
							</h3>
						</Col>
						{checkMarkLater==="false"?(<Col>
							<AutoComplete
								style={{ width: 300 }}
								filterOption={(inputValue, option) =>
									option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
								}
								value={searchValue?searchValue:""}
								className="custom-placeholder"
								placeholder="Search"
								onSelect={onSelect}
							>
								{searchList&&searchList.length>0&&searchList.map((email) => (
									<Option key={email.value} value={email.value}>
										{email.value}
									</Option>
								))}
							</AutoComplete>
						</Col>):null}
					</Row>
					<Divider className="my-1"/>
				</Col>
				<Col sm={12} className="test-question-details">
					<Space size={'large'} className="flex-vertical-middle">
						{listQuestions&&listQuestions.length>0&&displaySearch==="false"?<span><span>
							<span  /*onClick={showDrawer} className="qust-title"*/>
								{constants.Questions}
							</span> : <span>{questionIndex+1} of {listQuestions&&listQuestions.length}</span>
						</span>
						{ visible&& <Drawer
							title={					
								<div>
								Test - {categoryDetails&&categoryDetails!=={}&&categoryDetails!==null?
										categoryDetails.catType?
											categoryDetails.catType:""
										:""
									}
									{topicDetails&&topicDetails!=={}&&topicDetails!==null?
										topicDetails.topicTitle?
											topicDetails.topicTitle+" (1st Attempt)":""
										:""}
									{categoryDetails&&categoryDetails.attempt!==null?
										categoryDetails.count===25?
											categoryDetails.attempt===1?" #1 (1st Attempt)":categoryDetails.attempt===2?" #2 (1st Attempt)":categoryDetails.attempt===3?" #3 (1st Attempt)":categoryDetails.attempt===4?" #4 (1st Attempt)":categoryDetails.attempt===5?" #5 (1st Attempt)":categoryDetails.attempt===6?" #6 (1st Attempt)":""
											:categoryDetails.count===50?
												categoryDetails.attempt===1?" #3 (1st Attempt)":categoryDetails.attempt===2?" #4 (1st Attempt)":""
												:""
										:""}
								</div>}
							placement='left'
							closable={true}
							onClose={onClose}
							width={400}
							visible={visible}
							// key={placement}
							className="test-drawer"
						> 
							<Table
								locale={constants.locale}
								columns={columnsQuestionIndex} 
								dataSource={questionIndexList} 
								bordered
								className="customTable grey-custom-table"
								pagination={false}
							/>
						</Drawer>}</span>:""}
						{listQuestions&&listQuestions.length>0?(<span>
							{categoryDetails&&categoryDetails.isTimed!==null&&
							categoryDetails.isTimed===true ?
								<Button className="timerIcon" 
									onClick={toggleTimer} 
									icon={viewTimer ? 
										<Tooltip title={constants.HideTimer}>
											<EyeOutlined/>
										</Tooltip> : 
										<Tooltip title={constants.ShowTimer}>
											<EyeInvisibleOutlined/> 
										</Tooltip>}>
								</Button>:""
							}
							{topicDetails&&topicDetails.isTimed!==null&&
							topicDetails.isTimed===true ?
								<Button className="timerIcon" 
									onClick={toggleTimer} 
									icon={viewTimer ? 
										<Tooltip title={constants.HideTimer}>
											<EyeOutlined/>
										</Tooltip> : 
										<Tooltip title={constants.ShowTimer}>
											<EyeInvisibleOutlined/> 
										</Tooltip>}>
								</Button>:""
							}
							{viewTimer ? <span className={getColorForTimer(timerDisplay)}>{timerDisplay}</span>: ""}</span>):""}
					</Space>
				</Col>
				<Col sm={12} className="text-right test-icon-container">
					{listQuestions&&listQuestions.length>0&&displaySearch==="false"?<Space size={'middle'}>
						<Tooltip title="Strikethrough"><Button type="text" onClick={()=>{handleStrikeThrough();}}icon={<StrikethroughOutlined className="strike"/>}></Button></Tooltip>
						{listQuestions&&listQuestions.length>0&&checkAnswer==="false"&&checkMarkLater==="false"&&<Tooltip title="Mark for Later">
							<Button type="text"
								onClick={()=>(checkMarkLater==="false"&&checkAnswer==="false"?handleMarkForLater():null)}
								icon={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].markedForLater===true?
									<FlagFilled className="flag"/>:<FlagOutlined className="flag"/>}>
							</Button></Tooltip>}
						<Tooltip title="Highlight"><Button type="text" onTouchStart={(e)=>e.preventDefault()} onTouchEnd={()=>handleHighlightText()} onClick={()=>{handleHighlightText();}} icon={<HighlightOutlined className="highlight"/>}></Button></Tooltip>
						{checkMarkLater==="false"&&<Tooltip title="Question Index"><Button type="text" onClick={showDrawer} icon={<ProfileOutlined/>}></Button></Tooltip>}
						{listQuestions.length>0
							?listQuestions[questionIndex].notes!==null
						&&listQuestions[questionIndex].notes!==undefined
						&&listQuestions[questionIndex].notes!==""?
								<Tooltip title="Notes"><Button type="text" icon={<Badge dot color={"blue"}><Comment/></Badge>} onClick={showModal}></Button></Tooltip>
								:
								<Tooltip title="Notes"><Button type="text" icon={<Comment/>} onClick={showModal}></Button></Tooltip>
							:null}
						<Tooltip title="Decrease font"><Button onClick={()=>{zbminus();}} className={count>=2? "zoom-btn iconDisable": "zoom-btn"}>A-</Button></Tooltip>
						<Tooltip title="Increase font" placement="topRight"><Button onClick={()=>{zb();}} className={count<=0? "zoom-btn iconDisable": "zoom-btn"}>A+</Button></Tooltip>
					</Space>:null}
					<Modal
						title=
							{
								<div
									style={{
										width: '100%',
										cursor: 'move', 
									}}
									onMouseOver={() => {
										if (disabled) {
											setIsDisabled(false);
										}
									}}
									onMouseOut={() => {
										setIsDisabled(true);
									}}onFocus={() => {}}
									onBlur={() => {}}
								>
								Notes
								</div>
							}
						visible={notesVisible}
						onOk={hideModal}
						onCancel={hideModal}
						className="notes-modal"
						footer={null						}
						modalRender={modal => (
							<Draggables
								disabled={disabled}
								bounds={bounds}
								onStart={(event, uiData) => onStart(event, uiData)}
							>
								<div ref={draggleRef}>{modal}</div>
							</Draggables>
						)}
					>
						<Form form={noteForm} onFinish={createNote}>
							<Form.Item name="note">
								<TextArea 
									rows={4} 
									placeholder=" Write a note"
									className="p-0"
									onPaste={(e)=>{
										e.preventDefault();
										return false;
									}} onCopy={(e)=>{
										e.preventDefault();
										return false;
									}}
								/>
							</Form.Item>
							<Form.Item className="mb-0">
								<Button className="btn-submit min-width-100" htmlType="submit"><SaveOutlined/> Save</Button>
							</Form.Item>
						</Form>
					</Modal>
				</Col>
				<Col span={24}>
					<Spin className="flex-middle h-100vh" spinning={nextAndPreviousLoading} size="large" indicator={<Loader/>}>
						{listQuestions&&
						listQuestions.length>0?
							displaySearch==="true"?searchQuestionTable(columnsQuestions,JSON.parse(sessionStorage.getItem("searchQuestions") || "[]")):
								listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="1"?singleSelect:
									listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="2"?multipleChoice:listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="4"?arrangeSequence:listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="5"?dragAndDrop:""
							:""}
					</Spin>
				</Col>
				<Col span={24} className="test-btn-container">
					<Space size={'small'} wrap>
						{questionIndex>0&&checkMarkLater==="false"&&displaySearch==="false"&&isSearch==="false"&&isQuestionIndexOpened==="false"&&
						<Button className="btnDb min-width-100" onClick={()=>{handlePreviousQuestion();}} disabled={nextAndPreviousLoading}>
							<LeftOutlined/>
							{constants.Previous}
						</Button>}
						{listQuestions&&listQuestions.length>0&&questionIndex < listQuestions.length - 1&&displaySearch==="false"&&isSearch==="false"&&isQuestionIndexOpened==="false"&&checkMarkLater==="false"?
							(<Button className="btnDb min-width-100" onClick={()=>{handleNextQuestion();}} disabled={nextAndPreviousLoading}>
								{constants.Next}<RightOutlined/>
							</Button>
							):null}
						{displaySearch==="false"&&isSearch==="true"?(<Button className="btnDb min-width-100" onClick={()=>{onSearch();}}>
							{constants.BackToSearch}
						</Button>):null}
						{displaySearch==="false"&&isSearch==="true"||isQuestionIndexOpened==="true"?(
							<Button className="btn-submit min-width-100" onClick={()=>{handleNextQuestion("SAVE");}}>
								{constants.Save}
							</Button>
						):""}
						{isSearch==="true"&&listQuestions&&listQuestions.length>0&&questionIndex < listQuestions.length - 1||
						isQuestionIndexOpened==="true"&&listQuestions&&listQuestions.length>0&&questionIndex < listQuestions.length - 1?(
								<Button className="btnDb min-width-100" onClick={()=>{resumeTest();}}>
									{constants.ResumeTest}
								</Button>
							):""}
						{listQuestions&&listQuestions.length>0&&displaySearch==="false"&&isSearch==="false"?
							checkAnswer==="false"&&checkMarkLater==="false"?listQuestions&&listQuestions.length>0&&questionIndex < listQuestions.length - 1&&(<Button 
								className="btnDb min-width-100" 
								onClick={()=>{
									if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
										calculateAttemptedQuestions();
									}
									let totalOptions=null;
									let optionNumver={};
									if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"||listQuestions[questionIndex].q_type==="4"){
										totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt):0;
										optionNumver=constants.OptionNumbers;
									}else if(listQuestions[questionIndex].q_type==="5"){
										optionNumver=constants.OptionNumberDragAndDrop;
										totalOptions=listQuestions[questionIndex].opt_cnt?Number(listQuestions[questionIndex].opt_cnt.charAt(2)):0;
									}
									var match=false;
									for (let index = 0; index < totalOptions; index++) {
										if(!listQuestions[questionIndex][optionNumver[index+1]].includes(`<strike>`)){
											match=true;
											break;
										}
									}
									if(match===true||listQuestions[questionIndex].markedForLater===true){
										if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
											if(listQuestions[questionIndex].q_type==="5"&&listQuestions[questionIndex].correct_option&&listQuestions[questionIndex].correct_option.length!==listQuestions[questionIndex].selectedAnswer.length){
												if(checkAnswer==="true"){
													message.error(constants.SelectOptionDragAndDropTutorials);
												}else{
													message.error(constants.SelectOptionDragAndDrop);
												}
											}else{
												setPauseTest(true);
											}
										}else{
											setPauseTest(true);
										}
									}else{
										if(checkAnswer==="true"){
											message.error(constants.SelectOptionOnly);
										}else{
											message.error(constants.SelectOption);
										}
									}
								}}>
									Pause Test
							</Button>):""
							:""}
						{listQuestions&&listQuestions.length>0&&displaySearch==="false"?
							checkAnswer==="false"&&checkMarkLater==="false"?
								listQuestions&&questionIndex === listQuestions.length - 1&&(<Button className="btn-submit min-width-100" onClick={()=>{handleNextQuestion();}} loading={nextAndPreviousLoading}>{constants.SubmitTest}</Button>)
								:checkMarkLater==="true"?
									// listQuestions&&questionIndex === listQuestions.length - 1&&(<Button className="btn-submit min-width-100" onClick={()=>{handleNextQuestion("SUBMIT");}} loading={nextAndPreviousLoading}>{constants.SubmitTest}</Button>)
									listQuestions&&
									markedForLaterQuestions&&markedForLaterQuestions.length>0&&listQuestions[questionIndex].question_id!==markedForLaterQuestions[markedForLaterQuestions.length-1]?
										(<Button className="btnDb min-width-100" onClick={()=>{handleNextQuestion();}} disabled={nextAndPreviousLoading}>
											{constants.Next}<RightOutlined/>
										</Button>):(<Button className="btn-submit min-width-100" onClick={()=>{handleNextQuestion("SUBMIT");}} loading={nextAndPreviousLoading}>{constants.SubmitTest}</Button>)
									:(<Button className="btn-submit min-width-100" onClick={()=>{handleNextQuestion("SUBMIT");}} loading={nextAndPreviousLoading}>{constants.SubmitTest}</Button>)
							:""
						}
						{listQuestions&&listQuestions.length>0&&displaySearch==="false"?<Space size={'middle'} className="purple-border">
							<Tooltip title="Strikethrough"><Button type="text" onClick={()=>{handleStrikeThrough();}}icon={<StrikethroughOutlined className="strike"/>}></Button></Tooltip>
							{listQuestions&&listQuestions.length>0&&checkAnswer==="false"&&checkMarkLater==="false"&&<Tooltip title="Mark for Later">
								<Button type="text"
									onClick={()=>(checkMarkLater==="false"&&checkAnswer==="false"?handleMarkForLater():null)}
									icon={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].markedForLater===true?
										<FlagFilled className="flag"/>:<FlagOutlined className="flag"/>}>
								</Button></Tooltip>}
							<Tooltip title="Highlight"><Button type="text" onTouchStart={(e)=>e.preventDefault()} onTouchEnd={()=>handleHighlightText()} onClick={()=>{handleHighlightText();}} icon={<HighlightOutlined className="highlight"/>}></Button></Tooltip>
							{checkMarkLater==="false"&&<Tooltip title="Question Index"><Button type="text" onClick={showDrawer} icon={<ProfileOutlined/>}></Button></Tooltip>}
							{listQuestions.length>0
								?listQuestions[questionIndex].notes!==null
						&&listQuestions[questionIndex].notes!==undefined
						&&listQuestions[questionIndex].notes!==""?
									<Tooltip title="Notes"><Button type="text" icon={<Badge dot color={"blue"}><Comment/></Badge>} onClick={showModal}></Button></Tooltip>
									:
									<Tooltip title="Notes"><Button type="text" icon={<Comment/>} onClick={showModal}></Button></Tooltip>
								:null}
							<Tooltip title="Decrease font"><Button onClick={()=>{zbminus();}} className={count>=2? "zoom-btn iconDisable": "zoom-btn"}>A-</Button></Tooltip>
							<Tooltip title="Increase font"><Button onClick={()=>{zb();}} className={count<=0? "zoom-btn iconDisable": "zoom-btn"}>A+</Button></Tooltip>
						</Space>:null}
					</Space>
				</Col>
			</Row>
			{displaySearch==="false"?<Row>
				{listQuestions&&listQuestions.length>0?
					displayQuestionDetails(listQuestions,questionIndex)
					:""}
			</Row>:""}
			<Row>
				<Col span={24} style={{ visibility:"hidden" }}>
					<b>Server time:</b> {serverTime?serverTime:""}{"           "}
					<b>Browser time:</b> {browserTime?browserTime:""}
				</Col>
			</Row>
			{testModalPopup(
				pauseTest,
				completeTest,
				categoryDetails,
				topicDetails,
				viewTimer,
				timerDisplay,
				toggleTimer,
				attemptedQuestions,
				pauseTestAnswer,
				saveNotes,
				pauseTestLoading,
				setPauseTest,
				{},
				checkAnswers,
				completeTestApi,
				completeTestLoading,
				lastTenMinutes,
				setLastTenMinutes,
				markedLaterVisible,
				handleOkMarkForLaterModal,
				visibleTimeOut,
				handleTimeOutOk,
				null,
				null)}
			{Prompt}
		</div>
	);
};
export default ShortLengthTest;
