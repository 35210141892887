import React,{ useEffect,useState } from 'react';
import { Breadcrumb, Row, Col, Card, Table,BackTop, DatePicker, Form, Select, Space, Radio, Tooltip } from 'antd';
import { fetchAdminApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import moment from 'moment';
import { Chart } from "react-google-charts";
import { registrationFilter } from '../../../globalFunctions/GlobalFunctions';
const { Option } = Select;
const FlashcardDetails =(props)=> {
	const history = useHistory();
	const [allTestData, setAllTestData] = useState({});
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [flashcardType,setFlashcardType]=useState(3);
	const [TextbookGraphData, setTextbookGraphData] = useState([]);
	const [topicGraphData, setTopicGraphData] = useState([]);
	const [AllGraphData, setAllGraphData] = useState([]);
	const [duration,setDuration]=useState("1");
	const [form] = Form.useForm();
	useEffect(() => {
		if(props&&props.location&&props.location.state&&props.location.state.type&&props.location.state.type==="dashboard"){
			setDateFilters(constants.startDateOfTheYear,constants.endDateOfTheYear);
			fetchTableData(constants.startDateOfTheYear,constants.endDateOfTheYear);
		}else if(props&&props.location&&props.location.state&&props.location.state.type&&props.location.state.type==="flashcardTaken"){
			// onChangeDateFilter(props&&props.location&&props.location.state&&props.location.state.duration);
			setDateFilters(props.location.state.sd,props.location.state.ed,props.location.state.duration);
			fetchTableData(props.location.state.sd,props.location.state.ed);
			setFlashcardType(props&&props.location&&props.location.state&&props.location.state.flashcard_type);
		}
		else{
			fetchTableData();
		}
	}, []);
	const setDateFilters=(startDate,endDate,durationValue)=>{
		setDuration(durationValue);
		setStartDate(moment(startDate).format("MM/DD/YYYY"));
		setEndDate(moment(endDate).format("MM/DD/YYYY"));
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
		// console.log(durationValue);
		// setDuration("0");
	};
	const fetchTableData = (startDateValue,endDateValue) =>{
		var startDate=null;
		var endDate=null;
		if(startDateValue&&endDateValue){
			startDate=moment(startDateValue).format("YYYY-MM-DD");
			endDate=moment(endDateValue).format("YYYY-MM-DD");
		}else{
			var date = new Date(), y = date.getFullYear(), m = date.getMonth();
			var firstDay = new Date(y, m, 1);
			var lastDay = new Date(y, m + 1, 0);
			startDate=moment(firstDay).format("YYYY-MM-DD");
			endDate=moment(lastDay).format("YYYY-MM-DD");
			setStartDate(moment(startDate).format("MM/DD/YYYY"));
			setEndDate(moment(endDate).format("MM/DD/YYYY"));
			form.setFieldsValue({
				from:moment(startDate),
				to:moment(endDate)
			});
		}
		fetchAdminApi(`/get-flashcard-data?start_date=${startDate}&end_date=${endDate}`, "get").then(
			(res) => {
				if (res && res.data) {
					setAllTestData(res.data);
					let chartHeaderData = ["Flashcard", "Complete" ,"In-Progress"];
					if(res.data.Textbook){
						let textBookChartData = [];
						textBookChartData.push(chartHeaderData);
						for (let index = 0; index < res.data.Textbook.length; index++) {
							let textbook=[];
							textbook.push(res.data.Textbook[index].questions,Number(res.data.Textbook[index].completed_times),Number(res.data.Textbook[index].in_progress_times));
							textBookChartData.push(textbook);
						}
						setTextbookGraphData(textBookChartData);
					}else{
						setTextbookGraphData([]);
					}
					if(res.data.all){
						let allChartData = [];
						allChartData.push(chartHeaderData);
						for (let index = 0; index < res.data.all.length; index++) {
							let all=[];
							all.push(res.data.all[index].questions,Number(res.data.all[index].completed_times),Number(res.data.all[index].in_progress_times));
							allChartData.push(all);
						}
						setAllGraphData(allChartData);
					}else{
						setAllGraphData([]);
					}
					if(res.data.Topic){
						let topicChartData = [];
						topicChartData.push(chartHeaderData);
						for (let index = 0; index < res.data.Topic.length; index++) {
							let topic=[];
							topic.push(res.data.Topic[index].questions,Number(res.data.Topic[index].completed_times),Number(res.data.Topic[index].in_progress_times));
							topicChartData.push(topic);
						}
						console.log("tcd",topicChartData);
						setTopicGraphData(topicChartData);
					}else{
						setTopicGraphData([]);
					}
				}
			}
		);
	};
	const optionsforGraph = {
		title: constants.FlashcardsTaken,
		height: 400,
		isStacked: true,
		vAxis: {
			title: "No. of Flashcards",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		hAxis: { 
			title: "Tutorial" ,
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			}
		},
		seriesType: "bars",
	};
	const column = [
		{
			title: "Chapter/Topic",
			key: "questions",
			dataIndex: "questions",
			ellipsis: { showTitle: false },
			render: (record) => {
				return (
					<Tooltip placement="top" title={<div dangerouslySetInnerHTML={{ __html: record }}/>}>
						<span dangerouslySetInnerHTML={{ __html: record }}/>
					</Tooltip>
				);
			},
			sorter: (a, b) => a.questions.localeCompare(b.questions),
			width: 500,
		},
		{
			title: "Type",
			key: "heading",
			dataIndex: "heading",
			render: (record) => {
				if (record === "C") {
					return <span>Textbook</span>;
				} else {
					return <span>Topic</span>;
				}
			},
			sorter: (a, b) => a.heading.localeCompare(b.heading),
			width: 150,
			align: 'center',
		},
		// {
		// 	title: "Date",
		// 	key: "month_year_completed",
		// 	dataIndex: "month_year_completed",
		// 	render: (record) => {
		// 		return <span>{moment(record).format(constants.dateFormat)}</span>;
		// 	},
		// 	sorter: (a, b) => new Date(a.dt_created) - new Date(b.dt_created),
		// 	width: 150
		// },
		{
			title: "Completed",
			key: "completed_times",
			dataIndex: "completed_times",
			sorter: (a, b) => a.completed_times - b.completed_times,
			render: (record) => {
				return (
					<span className="p-relative" style={{ width: "48px" }}>{record ? record:""}</span>
				);
			},
			width: 150,
			className: "td-registartion-role",
			align: 'center',
		},
		{
			title: "In Progress",
			key: "in_progress_times",
			dataIndex: "in_progress_times",
			sorter: (a, b) => a.in_progress_times - b.in_progress_times,
			render: (record) => {
				return (
					<span className="p-relative" style={{ width: "48px" }}>{record ? record:""}</span>
				);
			},
			width: 150,
			className: "td-registartion-role",
			align: 'center',
		},
	];
	const handleStartDate = (_date, dateString) => {
		setStartDate(dateString);
		fetchTableData(dateString,endDate);
	};
	const handleEndDate = (_date, dateString) => {
		setEndDate(dateString);
		fetchTableData(startDate,dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const onChangeDateFilter = (e) => {
		setDuration(e);
		registrationFilter(e,setStartDateEndDate);
	};
	const onChangeTestType = (e) => {
		setFlashcardType(e.target.value);
	};
	const setStartDateEndDate = (startDate,endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from:moment(startDate),
			to:moment(endDate)
		});
		fetchTableData(startDate,endDate);
	};
	const totalColumn = (complete,inprogress,height) =>{
		console.log("test",complete,inprogress);
		return (<Table.Summary.Row style = {{ 
			fontWeight:500 ,
			height:height 
		}}>
			<Table.Summary.Cell align = "left" index={0}><span>Total</span></Table.Summary.Cell>
			<Table.Summary.Cell index={1} className='td-right'><span className="p-relative"></span></Table.Summary.Cell>
			<Table.Summary.Cell index={2} align="center"><span className="p-relative">{complete}</span></Table.Summary.Cell>
			<Table.Summary.Cell index={3} className = "td-right"><span className="p-relative">{inprogress}</span></Table.Summary.Cell>
		</Table.Summary.Row>);
	};
	const noDataAvailableText=()=>{
		return(
			<>							
				<div className="nograph-div">
					<span>No Data Available.</span>
				</div>
			</>
		);
	};
	const chartTag=(options,data)=>{
		return(
			<Chart
				chartType="ComboChart"
				width="100%"
				data={data}
				options={options}
			/>);
	};
	return (
		<>
			<div className="div">
				<BackTop/>
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/analysis-menu" });
						}}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>{constants.FlashcardsTaken}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" title={<span className="text-uppercase">{constants.FlashcardsTaken}</span>}
								extra={
									<Form layout="horizontal" form = {form}>
										<Space>
											<Form.Item className="label-default mb-0">
												<Radio.Group value={flashcardType} onChange={onChangeTestType} placeholder="Select test type" >
													<Radio value={3}>All</Radio>
													<Radio value={1}>Textbook</Radio>
													<Radio value={2}>Topic</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="From:" name = "from">
												<DatePicker defaultValue={startDate} onChange={ handleStartDate} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="To:" name = "to">
												<DatePicker defaultValue={endDate} onChange={handleEndDate} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Select value={duration} style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
													<Option value="0">This Year</Option>
													<Option value="7">Last Year</Option>
													<Option value="1">This Month</Option>
													<Option value="2">Last Month</Option>
													<Option value="3">Last 3 Months</Option>
													<Option value="4">This Month (Last Year)</Option>
													<Option value="5">Last 3 Months (Last Year)</Option>
													<Option value="6">Previous Years</Option>
												</Select>
											</Form.Item>
										</Space>
									</Form>
								}
							>
								<Row>
									<Col xs={24} sm={24} md={24} lg={24}>
										<span style = {{ 
											fontWeight:500,
											fontSize:"medium"
										}}>{startDate&&endDate&&`Search Criteria: From: ${moment(startDate).format("MM/DD/YYYY")} To: ${moment(endDate).format("MM/DD/YYYY")}`}</span>
										<Table className="gap-table"
											bordered
											columns={column}
											dataSource={flashcardType&&flashcardType===3?allTestData&&allTestData.all&&allTestData.all.length>0?allTestData.all:[]:
												flashcardType&&flashcardType===1?allTestData&&allTestData.Textbook&&allTestData.Textbook.length>0?allTestData.Textbook:[]:
													flashcardType&&flashcardType===2?allTestData&&allTestData.Topic&&allTestData.Topic.length>0?allTestData.Topic:[]:
														[]}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
											summary={() => (
												<Table.Summary fixed>
													{flashcardType&&flashcardType===3?
														totalColumn(allTestData&&allTestData.all_count&&allTestData.all_count[0].completed,allTestData&&allTestData.all_count&&allTestData.all_count[0].inprogress):
														flashcardType&&flashcardType===1?
															totalColumn(allTestData&&allTestData.textbook_count&&allTestData.textbook_count[0].completed,allTestData&&allTestData.textbook_count&&allTestData.textbook_count[0].inprogress):
															flashcardType&&flashcardType===2?totalColumn(allTestData&&allTestData.topic_count&&allTestData.topic_count[0].completed,allTestData&&allTestData.topic_count&&allTestData.topic_count[0].inprogress):""}
												</Table.Summary>
											)}
										/>
									</Col>
									{flashcardType&&flashcardType===4?
										<Col xs={24} sm={24} md={24} lg={24}>
											{flashcardType&&flashcardType===1?
												TextbookGraphData&&TextbookGraphData.length>0?
													chartTag(optionsforGraph,TextbookGraphData)
													:noDataAvailableText()
												:
												flashcardType&&flashcardType===2?
													topicGraphData&&topicGraphData.length>0?
														chartTag(optionsforGraph,topicGraphData)
														:noDataAvailableText()
													:
													flashcardType&&flashcardType===3?
														AllGraphData&&AllGraphData.length>0?
															chartTag(optionsforGraph,AllGraphData)
															:noDataAvailableText()
														:
														""}
										</Col>:null}
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default FlashcardDetails;
