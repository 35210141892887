import React, { useEffect, useState } from "react";
import { Breadcrumb, Row, Col, Card,Table } from 'antd';
import { fetchAdminApi } from "../../../services/api";
import { StarFilled } from '@ant-design/icons';
import { useHistory } from "react-router";
const UniversityMaster = () => {
	const history = useHistory();
	const [universityMasterData,setUniversityMasterData]=useState([]);
	const columns = [
		{
			title: 'State',
			dataIndex: 'State',
			key: 'State',
			sorter: (a, b) => {
				a = a.State || '';
				b = b.State || '';
				return a.localeCompare(b);
			},
		},
		{
			title: 'University',
			dataIndex: 'University_name',
			key: 'University_name',
			sorter: (a, b) => {
				a = a.University_name || '';
				b = b.University_name || '';
				return a.localeCompare(b);
			},
		},
		{
			title: 'Custom',
			dataIndex: 'custom',
			key: 'custom',
			sorter: (a, b) => {
				a = a.custom || '';
				b = b.custom || '';
				return a.localeCompare(b);
			},
			render: (text) => {
				return (
					<>
						{text==="0"?"-":<StarFilled className='yellow'/>}
					</>
				);
			},
			align: 'center',
		},
		{
			title: 'Last Updated By',
			dataIndex: 'updated_by_name',
			key: 'updated_by_name',
			sorter: (a, b) => {
				a = a.Updated_by_name || '';
				b = b.Updated_by_name || '';
				return a.localeCompare(b);
			},
			render: (text)=>{
				return(
					<>
						<span>{text ? text : ""}</span>
					</>
				);
			}
		},
		{
			title: 'Last Updated On',
			dataIndex: 'updated_by',
			key: 'updated_by',
			align: 'center',
			sorter: (a, b) => new Date(a.updated_by) - new Date(b.updated_by),
			render: (value,object)=>{
				return(
					<>
						<span>{object&&object.updated_by_name ? value : ""}</span>
					</>
				);
			}
		},
	];
	useEffect(() => {
		fetchUniversityMasterInfo();
	},[]);
	//To get university master data
	const fetchUniversityMasterInfo = () => {
		fetchAdminApi(`/university_master_list`, "get").then(
			(res) => {
				if (res && res.data) {
					setUniversityMasterData(res.data);
				} else {
					setUniversityMasterData([]);
				}
			}
		);
	};
	return(
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/masters-menu" });
						}}>Masters</a></Breadcrumb.Item>
						<Breadcrumb.Item>Universities</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">{" "}University Master</span>} >
								<Table dataSource={universityMasterData} columns={columns}/>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default UniversityMaster;