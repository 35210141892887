import { Row,Col,Table } from "antd";
import "../../styles/login.css";
import constants from "../../constants/constants";
import React, { useEffect, useState } from "react";
import { fetchApi } from "../../services/api";
import Headers from "../../header";
import Footers from "../../footer";
const VersionViewer = () => {
	const [buildAndVersion,setBuildAndVersion]=useState([]);
	useEffect(() => {
		getTutorialType();
	}, []);
	const getTutorialType=()=>{
		fetchApi("/version", "get")
			.then((res) => {
				if (res && res.code && res.code === 200) {
					if(res.data){
						let buildAndVersionData=[
							{
								Text:"Frontend",
								Build:constants.feBuild,
								Version:constants.feVersion
							},
							{
								Text:"Backend",
								Build:res.data.build,
								Version:res.data.version
							}
						];
						setBuildAndVersion(buildAndVersionData);
					}
				} else {
					setBuildAndVersion([]);
				}
			});
	};
	const topTenColumns = [
		{
			title: '',
			key: 'Text',
			render: (record) => {
				return (
					<span>
						{record&&record.Text? record.Text:""}
					</span>
				);
			}
		},
		{
			title: 'Version',
			key: 'index',
			render: (record) => {
				return (
					<span>
						{record&&record.Version? record.Version:""}
					</span>
				);
			}
		},
		{
			title: 'Build',
			key: 'Build',
			align: 'center',
			render: (record) => {
				return (
					<span>{record&&record.Build?record.Build:""}</span>
				);
			}
		},
	];
	return (
		<div>
			<Headers/>
			<div className="version-content">
				<Row
					gutter={[
						{
							xs: 0,
							sm: 0,
							md: 8,
							lg: 16 
						},
						{
							xs: 8,
							sm: 8,
							md: 8,
							lg: 16 
						},
					]}
				>
					<Col xs={24} lg={24}>
						<br/><br/>
						<div className="text-center position-relative">
							<h3 className="mainHeading">SLP ARP</h3>
						</div>
					</Col>
					<Col xs={24}
						lg={{
							span: 16, 
							offset: 4,
						}}>
						{/* <div className="text-center position-relative">
						Frontend: {constants.feBuild}
						<br/>
						Backend: {backendVersion}
					</div> */}
						<div className="text-center position-relative">
							<Table className="gap-table mytest-table" 
								locale={{ emptyText: 'No Records Found' }}
								dataSource={buildAndVersion} 
								columns={topTenColumns}
								pagination={false}
							/>
						</div>
					</Col>
				</Row>
			</div>
			<Footers/>
		</div>
	);
};
export default VersionViewer;
