import React, { useEffect, useState } from 'react';
import { fetchAdminApi,fetchAdminDownloadApi } from "../../../services/api";
import { Breadcrumb, Row, Col, Card, Form, Space, Select, Table, Avatar, Tag, Tooltip, Image, Button, message, Spin, Input  } from 'antd';
import { EyeOutlined, FileWordOutlined } from '@ant-design/icons';
import constants from '../../../constants/constants';
import "../../../styles/totalregistration.css";
import { useHistory } from "react-router";
import moment from 'moment';
import Loader from '../../Common/Cred/Loader';
const { Search } = Input;
const QuestionListing = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const { Option } = Select;
	const [tableData, setTableData] = useState([]);
	const [testOptionId, setTestOptionId] = useState([]);
	const [topicOptionId, setTopicOptionId] = useState([]);
	const [categoryOptionId, setCategoryOptionId] = useState([]);
	const [test, setTest] = useState("");
	const [category, setCategory] = useState("");
	const [topic, setTopic] = useState("");
	const [status, setStatus] = useState("");
	const [questionTypeValue, setQuestionTypeValue] = useState("");
	const [reload, setReload] = useState(false);
	const [loader,setLoader]=useState(false);
	const [optionPayload] = useState(props?.location?.state ? props.location.state :JSON.parse(sessionStorage.getItem("pay")));
	const [addQuestionType,setAddQuestionType]=useState("");
	const [filteredData,setFilteredData]=useState([]);
	const [searchValue,setSearchValue]=useState("");
	useEffect(() => {
		if (props?.location?.state) {
			sessionStorage.setItem("pay", JSON.stringify(props.location.state));
		}
		fetchCategoryOption();
		fetchTestOption();
		fetchTopicOption();
	}, []);
	useEffect(() => {
		fetchQuestionListing();
		setFilterInput();
	}, [reload,optionPayload?.testId, optionPayload?.categoryId, optionPayload?.topicId, optionPayload?.statusId, optionPayload?.questionType]);
	const setFilterInput = () =>{
		form.setFieldsValue({
			status:optionPayload && optionPayload.statusId ? optionPayload.statusId : "--Status--",
			questionType:optionPayload && optionPayload.questionType ? optionPayload.questionType : "--Question Type--",
		});
	};
	// storing the testid,topicId, categoryId, statusId, questionType in localstorage
	const setStorageVale = (testId, topicId, categoryId, statusId, questionType) => {
		if (testId !== null) {
			optionPayload['testId'] = testId;
		} else if (topicId !== null) {
			optionPayload['topicId'] = topicId;
		} else if (categoryId !== null) {
			optionPayload['categoryId'] = categoryId;
		} else if (statusId !== null) {
			optionPayload['statusId'] = statusId;
		} else if (questionType !== null) {
			optionPayload['questionType'] = questionType;
		}
		sessionStorage.setItem('pay', JSON.stringify(optionPayload));
	};
	// fetching the test-listing data to get all the categoryid to display on the dropdown
	const fetchCategoryOption = () => {
		fetchAdminApi("/test-listing?type=category_options", "get").then((res) => {
			if (res && res.data) {
				setCategoryOptionId(res.data);
				form.setFieldsValue({ category:optionPayload?.categoryId ? optionPayload.categoryId : "--Category--" });
			}
			else {
				setCategoryOptionId([]);
			}
		});
	};
	// fetching the test-listing data to get all the topicid to display on the dropdown
	const fetchTopicOption = () => {
		fetchAdminApi("/test-listing?type=topic_options", "get").then((res) => {
			if (res && res.data) {
				setTopicOptionId(res.data);
				form.setFieldsValue({ topic:optionPayload?.topicId ? optionPayload.topicId : "--Topic--" });
			}
			else {
				setTopicOptionId([]);
			}
		});
	};
	// fetching the test-listing data to get all the testid to display on the dropdown
	const fetchTestOption = () => {
		fetchAdminApi("/test-listing?type=test_options", "get").then((res) => {
			if (res && res.data) {
				setTestOptionId(res.data);
				form.setFieldsValue({ test:optionPayload?.testId ? optionPayload.testId : "--Test--" });
			}
			else {
				setTestOptionId([]);
			}
		});
	};
	const checkNullEmptyPayload = Object.values(optionPayload).every(value => {
		if (value === null || value === ""||value===undefined) {
			return true;
		}
	});
	// fetching the questionlisting data based on the localstorage id's to display data 
	const fetchQuestionListing = () => {
		setLoader(true);
		let url=`/questions-list?`;
		if(optionPayload.testId){
			url+=`&test_id=${optionPayload.testId}`;
		}
		if(optionPayload.test === 0){
			url+=`&test=${optionPayload.test}`;
		}
		if(optionPayload.categoryId){
			url+=`&category_id=${optionPayload.categoryId}`;
		}
		if(optionPayload.topicId){
			url+=`&topic_id=${optionPayload.topicId}`;
		}
		if(optionPayload.statusId){
			if(optionPayload.statusId == 4){
				url += `&status=0`;
			}else{
				url+=`&status=${optionPayload.statusId}`;
			}
		}
		if(optionPayload.questionType){
			url+=`&question_type=${optionPayload.questionType}`;
		}
		if(checkNullEmptyPayload === true){
			url+=`&test_type=0`;
		}
		fetchAdminApi(url, "get").then((res) => {
			if (res && res.code === 200) {
				setTableData(res.data);
				setFilteredData(res.data);
				setSearchValue("");
				setLoader(false);
			} else {
				setTableData([]);
				setLoader(false);
			}
		});
	};
	const columns = [
		{
			title: 'Category',
			dataIndex: 'category',
			sorter: (a, b) => a.category.localeCompare(b.category),
		},
		{
			title: '',
			render: (record) => {
				let icon_font = "";
				let quesion_type = "";
				if (record.type == 1) {
					icon_font = "SS";
					quesion_type = "Single Select Question";
				} else if (record.type == 2) {
					icon_font = "MS";
					quesion_type = "Multi-Select Question";
				} else if (record.type == 3) {
					icon_font = "MP";
					quesion_type = "Match the Pair Question";
				} else if (record.type == 4) {
					icon_font = "AS";
					quesion_type = "Arrange or Sequence Question";
				} else if (record.type == 5) {
					icon_font = "DD";
					quesion_type = "Drag and Drop Question";
				}
				return (<Space>
					<Tooltip title={quesion_type}>
						<Avatar shape="square" size= "default"><h2>{icon_font}</h2></Avatar>
					</Tooltip>
				</Space>);
			},
			width: 100,
			align: 'center',
		},
		{
			title: 'Question Name',
			dataIndex: 'question_text',
			key: 'question_text',
			ellipsis: { showTitle: false },
			render: (record,allData) => {
				return (
					<Tooltip placement="top" title={<div dangerouslySetInnerHTML={{ __html: record }}/>} overlayStyle={{ 
						minWidth: record.length > 70? "80%":"30px",
						minHeight: 20
					}}>
						<a onClick = {()=>{history.push({
							pathname:"/edit-question",
							state: { 
								questionId:allData?.question_id,
								questionImage:allData?.image_name
							}
						});}}><span dangerouslySetInnerHTML={{ __html: record }}/></a>
					</Tooltip>
				);
			},
			sorter: (a, b) => a.question_text.localeCompare(b.question_text),
			width: 500,
		},
		{
			title: 'Topic',
			dataIndex: 'topic',
			sorter: (a, b) => a.topic.localeCompare(b.topic),
		},
		{
			title: 'Image',
			dataIndex: 'featured_img_data',
			render: (record) => {
				if(record){
					return <Image width={80} alt={record} src={record}/>;
				}else{
					return null;
				}
			},
			align:"center"
		},
		{
			title: 'Status',
			dataIndex: 'is_verified',
			sorter: (a, b) => a.is_verified.localeCompare(b.is_verified),
			render: (record) => {
				if (record == 1) {
					return <Tag color={'blue'} key={record}>Verified</Tag>;
				} else if (record == 0) {
					return <Tag color={'green'} key={record}>To be verified</Tag>;
				} else if (record == 3) {
					return <Tag color={'red'} key={record}>Rejected</Tag>;
				} else if (record == 2) {
					return <Tag color={'green'} key={record}>In-Progress</Tag>;
				}
			}
		},
		{
			title: 'Test',
			dataIndex: 'test_title',
			sorter: (a, b) => a.test_title.localeCompare(b.test_title),
			// sorter: (a, b) => a.test_title - b.test_title,
			align: "center"
		},
		{
			title: 'Action',
			render: (record) => {
				return <Tooltip title="Preview Question"><EyeOutlined onClick={()=>{history.push({
					pathname:"/preview-question",
					state: { questionId:record?.question_id }
				});}}/></Tooltip>;
			},
			align: "center",
		},
	];
	const onchangeTestId = (value) => {
		setReload(!reload);
		setTest(value);
		setStorageVale(value, null);
	};
	const onchangeCategoryid = (value) => {
		setReload(!reload);
		setCategory(value);
		setStorageVale(null, null, value);
	};
	const onchangeTopicId = (value) => {
		setReload(!reload);
		setTopic(value);
		setStorageVale(null, value);
	};
	const onchangeStatusId = (value) => {
		setReload(!reload);
		setStatus(value);
		setStorageVale(null, null, null, value);
	};
	const onchangeQuestionId = (value) => {
		setReload(!reload);
		setStorageVale(null, null, null, null, value);
		setQuestionTypeValue(value);
	};
	const onWordDownload = (values) =>{
		let fileName="";
		let url=`/download-test-questions?`;
		if(values.test!=="--Test--"){
			let testSelected=testOptionId.filter((item)=>item.test_id===values.test);
			if(fileName!==""){
				fileName=fileName+"-"+testSelected[0].test_title.replace(/\s/g, "");
			}else{
				fileName=testSelected[0].test_title.replace(/\s/g, "");
			}
			url=url+`&test_id=${values.test}`;
		}else{
			url=url+`&test_id=0`;
		}
		if(values.category!=="--Category--"){
			let categorySelected=categoryOptionId.filter((item)=>item.category_id===values.category);
			if(fileName!==""){
				fileName=fileName+"-"+categorySelected[0].category_abbreviation;
			}else{
				fileName=categorySelected[0].category_abbreviation;
			}
			url=url+`&category_id=${values.category}`;
		}else{
			url=url+`&category_id=0`;
		}
		if(values.topic!=="--Topic--"){
			let topicSelected=topicOptionId.filter((item)=>item.topic_id===values.topic);
			if(fileName!==""){
				fileName=fileName+"-"+topicSelected[0].topic_abbreviation;
			}else{
				fileName=topicSelected[0].topic_abbreviation;
			}
			url=url+`&topic_id=${values.topic}`;
		}else{
			url=url+`&topic_id=0`;
		}
		if(values.status!=="--Status--"){
			let questionStatusSelected=constants.QuestionStatusarray.filter((item)=>item.question_status_id===values.status);
			if(fileName!==""){
				fileName=fileName+"-"+questionStatusSelected[0].question_status_type;
			}else{
				fileName=questionStatusSelected[0].question_status_type;
			}
			url=url+`&status=${values.status}`;
		}else{
			// url=url+`&status=0`;
		}
		if(values.questionType!=="--Question Type--"){
			let questionSelected=constants.QuestionTypearray.filter((item)=>item.question_id===values.questionType);
			if(fileName!==""){
				fileName=fileName+"-"+questionSelected[0].question_type;
			}else{
				fileName=questionSelected[0].question_type;
			}
			url=url+`&q_type=${values.questionType}`;
		}else{
			url=url+`&q_type=0`;
		}
		fetchAdminDownloadApi(url, "get", null).then(
			(res) => {
				if(res){
					let url,a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application/vnd.ms-word" }),
						{ type: "data:attachment/doc" }
					);
					let todayDate= moment().format(constants.dateFormat);
					let filename="";
					if(constants.EnviromentName!=="qa"&&constants.EnviromentName!=="staging"&&constants.EnviromentName!=="dev"){
						filename="Questions -"+ fileName +" ("+todayDate+").doc";
					}else{
						filename=constants.EnviromentName+"Questions -"+ fileName +" ("+todayDate+").doc";
					}
					a = document.createElement("a");
					a.download = filename;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
				}else{
					message.error("Failed to download");
				}
			}
		);
	};
	const onChangeAddQuestionType=(value)=>{
		setAddQuestionType(value);
		history.push({ 
			pathname:"/add-question",
			state: { qType:value.toString() }
		});
	};
	const onSearch=(e)=>{
		const filterData = tableData.filter((fdata) =>
			fdata?.question_text?.toLowerCase().includes(e?.toLowerCase()) ||
			fdata?.option_first?.toLowerCase().includes(e?.toLowerCase()) ||
			fdata?.option_second?.toLowerCase().includes(e?.toLowerCase()) ||
			fdata?.option_third?.toLowerCase().includes(e?.toLowerCase()) ||
			fdata?.option_fourth?.toLowerCase().includes(e?.toLowerCase()) ||
			fdata?.option_fifth?.toLowerCase().includes(e?.toLowerCase()) ||
			fdata?.option_sixth?.toLowerCase().includes(e?.toLowerCase()) ||
			fdata?.option_seventh?.toLowerCase().includes(e?.toLowerCase()) ||
			fdata?.option_eighth?.toLowerCase().includes(e?.toLowerCase())
		);
		setFilteredData(filterData);
	};
	const onChangeSearchText=(e)=>{
		if(e.target.value){
			setSearchValue(e.target.value);
		}else{
			setSearchValue("");
			setFilteredData(tableData);
		}
	};
	return (
		<Spin spinning={loader} size="large" indicator={<Loader/>}>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item><a onClick = { () => {
							history.push("/admin-dashboard");
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item><a onClick = { () => {
							history.push("/test-list");
						}}>Test List</a></Breadcrumb.Item>
						<Breadcrumb.Item>Question List</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div >
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">Question List</span>} extra={
								<Form layout="horizontal">
									<Space>
										{/* <Form.Item className="label-default mb-0" >
											<Button type="primary">Export</Button>
										</Form.Item> */}
										<Form.Item className="label-default mb-0">
											<Search
												value={searchValue} 
												placeholder="input search text" 
												onSearch={(e)=>{onSearch(e);}}
												onChange={(e)=>{onChangeSearchText(e);}} 
												enterButton="Search"/>
										</Form.Item>
										<Form.Item className="label-default mb-0">
											<Select onChange={onChangeAddQuestionType} value={addQuestionType} style={{ width: 180 }} >
												<Option value={""} >--Add Question--</Option>
												<Option value={constants.QuestionListing.single_select}>Single Select</Option>
												{/* <Option value={constants.QuestionListing.multi_select}>Multi-Select</Option> */}
												{/* <Option value={constants.QuestionListing.match_pair}>Match Pairs</Option> */}
												{/* <Option value={constants.QuestionListing.arrange_sequence}>Arrange Sequence</Option>
												<Option value={constants.QuestionListing.drag_drop}>Drag & Drop</Option> */}
											</Select>
										</Form.Item>
									</Space>
								</Form>
							}>
								<Row>
									<Col xs={24} sm={24} md={24} lg={24}>
										<Form form = {form} onFinish={onWordDownload}>
											<Space style={{ alignItems:'end' }}>
												<Form.Item className="label-default mb-0" label="Test" name ="test">
													<Select onChange={onchangeTestId} value={test} style={{ width: 180 }}>
														<Option value={""}>--Test--</Option>
														{testOptionId.map(option => {
															return <Option key={option.test_id} value={option.test_id}>{option.test_title}</Option>;
														})}
													</Select>
												</Form.Item>
												<Form.Item className="label-default mb-0" label="Category" name ="category">
													<Select onChange={onchangeCategoryid} value={category} style={{ width: 180 }} >
														<Option value={""}>--Category--</Option>
														{categoryOptionId.map(option => {
															return <Option key={option.category_id} value={option.category_id}>{option.category_abbreviation}</Option>;
														})}
													</Select>
												</Form.Item>
												<Form.Item className="label-default mb-0" label="Topic" name ="topic">
													<Select onChange={onchangeTopicId} value={topic} style={{ width: 180 }}>
														<Option value={""}>--Topic--</Option>
														{topicOptionId.map(option => {
															return <Option key={option.topic_id} value={option.topic_id}>{option.topic_abbreviation}</Option>;
														})}
													</Select>
												</Form.Item>
												<Form.Item className="label-default mb-0" label="Status" name ="status">
													<Select onChange={onchangeStatusId} value={status} style={{ width: 180 }} >
														<Option value={""} >--Status--</Option>
														<Option value={constants.TestListing.in_progress}>In Progress</Option>
														<Option value={constants.TestListing.rejected}>Rejected</Option>
														<Option value={constants.TestListing.to_be_verified}>To be Verified</Option>
														<Option value={constants.TestListing.verified}>Verified</Option>
													</Select>
												</Form.Item>
												<Form.Item className="label-default mb-0" label="Question Type" name ="questionType">
													<Select onChange={onchangeQuestionId} value={questionTypeValue} style={{ width: 180 }} >
														<Option value={""} >--Question Type--</Option>
														<Option value={constants.QuestionListing.single_select}>Single Select</Option>
														<Option value={constants.QuestionListing.multi_select}>Multi-Select</Option>
														{/* <Option value={constants.QuestionListing.match_pair}>Match Pairs</Option> */}
														<Option value={constants.QuestionListing.arrange_sequence}>Arrange Sequence</Option>
														<Option value={constants.QuestionListing.drag_drop}>Drag & Drop</Option>
													</Select>
												</Form.Item>
												{/* <Form.Item className="label-default mb-0" label="Included in">
											<Checkbox.Group style={{ width: '100%' }} onChange={onChangeIncludeIn} >
												<Row>
													<Col span={8}>
														<Checkbox checked={fullLength} value="A">Full Length</Checkbox>
													</Col>
													<Col span={8}>
														<Checkbox checked={shortTest} value="B">Short tests</Checkbox>
													</Col>
													<Col span={8}>
														<Checkbox checked={tutorials} value="C">Tutorials</Checkbox>
													</Col>
													<Col span={8}>
														<Checkbox checked={qod} value="D">QOD</Checkbox>
													</Col>
													<Col span={8}>
														<Checkbox checked={mockTest} value="E">Mock Tests</Checkbox>
													</Col>
												</Row>
											</Checkbox.Group>
										</Form.Item> */}
												<Form.Item  className="label-default mb-0">
													<Tooltip title="Export to word">
														<Button className = "edit-butoon" type='primary' htmlType='submit' icon={<FileWordOutlined/>}/>
													</Tooltip>
												</Form.Item>
											</Space>
										</Form>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24}>
										<br/>
										<span>{filteredData !== null ? ` ${filteredData.length>0?filteredData.length:0} record(s) found.` : ""}</span>
										<Table columns={columns}
											dataSource={filteredData}
											pagination={false}
											scroll={{ y: 400 }}
											bordered
											locale={{ emptyText: 'No Records Found' }}/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</Spin>
	);
};
export default QuestionListing;
