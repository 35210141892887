import React, { useEffect, useState } from "react";
import { Input, Form, Row, Col, Button, Space, message, notification } from "antd";
import "../../../styles/login.css";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import { fetchAdminApi, fetchApi } from "../../../services/api";
import Headers from "../../../header";
import Footers from "../../../footer";
import Book from './Book';
import { openNotificationCred } from "../../../globalFunctions/GlobalFunctions";
const ForgotPassword = (props) => {
	const history = useHistory();
	const [loading,setLoading]=useState(false);
	const [isAdminUser,]=useState(props&&props.location&&props.location.state&&props.location.state.isAdmin?props&&props.location&&props.location.state&&props.location.state.isAdmin:false);
	useEffect(() => {
		notification.destroy();
		openNotificationCred();
	}, []);
	const sendInstructions = (values) => {
		let payload = null;
		payload = {
			user: values.userName,
			type: isAdminUser===true?"admin":"student" 
		};
		setLoading(true);
		if(isAdminUser===true){
			fetchAdminApi("/user/forgot-password", "post", payload).then((res) => {
				setLoading(false);
				if (res && res.code && res.code === 200) {
					setLoading(false);
					message.success(res.message);
				} else {
					setLoading(false);
					message.error(res.message);
				}
			});
		}else{
			fetchApi("/user/forgot-password", "post", payload).then((res) => {
				setLoading(false);
				if (res && res.code && res.code === 200) {
					setLoading(false);
					message.success(res.message);
				} else {
					setLoading(false);
					message.error(res.message);
				}
			});
		}
	};
	return (
		<div>
			<Headers/>
			<div className="login-content flex-vertical-middle">
				<Row gutter={[{
					xs: 8,
					sm: 24,
					md: 48,
					lg: 8,
					xl: 8 
				}, 8]} className="login-px">
					<Book/>
					<Col lg={24} xl={14} className="w-100 flex-middle">
						{isAdminUser===true?null:
							<div className="text-right signup-link">
								<Space>
									{constants.SignUpRedirect}
									<Button type="link" className="pl-0" onClick={()=>{	history.push("/registration");}}>
										{constants.SignUpRedirectLink}
									</Button>
								</Space>
							</div>
						}
						<div className="login-col">
							<div className="ml-135">
								<br/>
								<h1>{constants.ForgotPsdHeader}</h1>
								<Form layout="vertical" onFinish={sendInstructions}>
									<div className="login-form">
										<p>{constants.ForgotPsdSubHeader}</p>
										<Form.Item
											label={constants.Username}
											name="userName"
											rules={[
												{
													required: true,
													message: "This field is required",
												},
											]}
										>
											<Input
												autoFocus
												className="login-input"
												placeholder={constants.UsernameEmail}
											/>
										</Form.Item>
									</div>
									<Space>
										<Form.Item>
											<Button
												shape="round"
												size="large"
												className="gold-btn"
												onClick={() => {
													if(props && props.history && props.history.location && props.history.location.state){
														history.push("/admin-login");
													}else{
														history.push("/");
													}
												}}
											>
												{constants.CancelBTN}
											</Button>
										</Form.Item>
										<Form.Item>
											<Button
												shape="round"
												size="large"
												className="gold-btn"
												htmlType="submit"
												loading={loading}
											>
												{constants.SendInstructionsBTN}
											</Button>
										</Form.Item>
									</Space>
								</Form>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<Footers/>
		</div>
	);
};
export default ForgotPassword;
