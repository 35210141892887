import React,{ useEffect,useState } from 'react';
import { Breadcrumb,Skeleton, Row, Col, Card, Form, DatePicker, Table,Radio,Space,Select } from 'antd';
import { Chart } from "react-google-charts";
import constants from "../../../constants/constants";
import moment from "moment";
import { fetchAdminApi } from "../../../services/api";
import { registrationFilter } from '../../../globalFunctions/GlobalFunctions';
const { Option } = Select;
const ScorePercentage =() => {
	const [form] = Form.useForm();
	const [graphData, setGraphData] = useState([]);
	const [tableDta, setTableData] = useState([]);
	const [radioButtonValue, setRadioButtonValue] = useState(1);
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [duration, setDuration] = useState("0");
	const onChangeRadioButton = e => {
		console.log('radio checked', e.target.value);
		setRadioButtonValue(e.target.value);
	};
	useEffect(() => {
		fetchTableData();
		const data = [];
		for (let i = 0; i < 3; i++) {
			data.push({
				key: i,
				score_percentage: `Test ${i + 1}`,
				no_of_students: i + 100,
			});
		}
		setTableData(data);
		let gfHeader = [];
		gfHeader.push(["Score Perscentage", "No. of Students"]);
		data.map(obj => gfHeader.push([obj.score_percentage, parseInt(obj.no_of_students)]));
		console.log("gfHeader", gfHeader);
		setGraphData(gfHeader);
	}, []);
	const fetchTableData = (startDateValue,endDateValue) =>{
		var startDate=null;
		var endDate=null;
		if(startDateValue&&endDateValue){
			startDate=moment(startDateValue).format("YYYY-MM-DD");
			endDate=moment(endDateValue).format("YYYY-MM-DD");
		}else{
			startDate=constants.startDateOfTheYear;
			endDate=constants.endDateOfTheLastYear;
			setStartDate(moment(startDate).format("MM/DD/YYYY"));
			setEndDate(moment(endDate).format("MM/DD/YYYY"));
			form.setFieldsValue({
				from:moment(startDate),
				to:moment(endDate)
			});
		}
		fetchAdminApi(`/total-tests-taken-result?start_date=${startDate}&end_date=${endDate}`, "get").then(
			(res) => {
				if (res && res.data) {
					console.log(res.data,"res");
				}
			}
		);
	};
	const column = [
		{
			title: "Score Percentage",
			key: "score_percentage",
			dataIndex: "score_percentage",
		},
		{
			title: "No of Students",
			key: "no_of_students",
			dataIndex: "no_of_students",
			align: "center",
			render: (_text, record) => (
				<span className="p-relative">{record?.no_of_students}</span>
			),
			className : "td-total-registration",
		},
	];
	const graphOption = { title: constants.ScorePercentage + " "+"2021 – US - TX.", };
	const handleStartDate = (_date, dateString) => {
		setStartDate(dateString);
		fetchTableData(dateString,endDate);
	};
	const handleEndDate = (_date, dateString) => {
		setEndDate(dateString);
		fetchTableData(startDate,dateString);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const onChangeDateFilter = (e) => {
		setDuration(e);
		registrationFilter(e, setStartDateEndDate);
	};
	const setStartDateEndDate = (startDate, endDate) => {
		setStartDate(startDate);
		setEndDate(endDate);
		form.setFieldsValue({
			from: moment(startDate),
			to: moment(endDate)
		});
		fetchTableData(startDate, endDate);
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item ><a onClick={() => {
							history.push({ pathname: "/admin-dashboard" });
						}}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item>{constants.ScorePercentage}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card bordered size="small" title={<span className="text-uppercase">{constants.ScorePercentage}</span>}extra={
								<Form layout="horizontal">
									<Radio.Group onChange={onChangeRadioButton} value={radioButtonValue}>
										<Space direction = "horizontal" >
											<Form.Item className="label-default mb-0">
												<Radio value={1}>Full length</Radio>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Radio value={2}>Category</Radio>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Radio value={3}>Topic</Radio>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="From:" name = "from">
												<DatePicker defaultValue={startDate} onChange={ handleStartDate} disabledDate={disabledFutureDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0" label="To:" name = "to">
												<DatePicker defaultValue={endDate} onChange={handleEndDate} disabledDate={disabledPastDate} format="MM/DD/YYYY" inputReadOnly={true}/>
											</Form.Item>
											<Form.Item className="label-default mb-0">
												<Select value={duration} style={{ width: 200 }} onChange={onChangeDateFilter} placeholder="Select period" >
													<Option value="0">This Year</Option>
													<Option value="7">Last Year</Option>
													<Option value="1">This Month</Option>
													<Option value="2">Last Month</Option>
													<Option value="3">Last 3 Months</Option>
													<Option value="4">This Month (Last Year)</Option>
													<Option value="5">Last 3 Months (Last Year)</Option>
													<Option value="6">Previous Years</Option>
												</Select>
											</Form.Item>
										</Space>
									</Radio.Group>
								</Form>
							}>
								<Row>
									<Col xs={12} sm={12} md={10} lg={10}>
										<span style = {{ 
											fontWeight:500,
											fontSize:"medium"
										}}>{startDate&&endDate&&`Search Criteria: From: ${moment(startDate).format("MM/DD/YYYY")} To: ${moment(endDate).format("MM/DD/YYYY")}`}</span>
										<Table className="gap-table"
											columns={column}
											dataSource={tableDta}
											pagination={false}
											locale={{ emptyText: 'No Records Found' }}
										/>
									</Col>
									<Col xs={12} sm={12} md={14} lg={14}>
										<Chart
											width="100%"
											height="400px"
											chartType="PieChart"
											loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
											data={graphData}
											options={graphOption}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default ScorePercentage;