import React,{ useState,useEffect } from 'react';
import { Form, Breadcrumb, Row, Col, Card, Button, Space,Table,Tooltip,Popconfirm,Radio } from "antd";
import { EditOutlined, DeleteOutlined,CheckCircleOutlined } from '@ant-design/icons';
import { fetchAdminApi } from "../../../services/api";
import constants from '../../../constants/constants';
import moment from "moment";
import { useHistory } from "react-router";
const AdminFaq =() => {
	const history = useHistory();
	const [tableData, setTableData] = useState([]);
	const [filterTable, setFilterTable] = useState([]);
	const [dataLength, setDataLength] = useState(0);
	const fetchFaqListing = () =>{
		fetchAdminApi("/faq-questions", "get").then((res) => {
			if (res && res.data) {
				setFilterTable(res.data);
				setTableData(res.data);
				setDataLength(res.data.length);
			}else{
				setTableData([]);
				setDataLength(0);
				setFilterTable([]);
			}
		});
	};
	const handleFaqsDelete = (record) => {
		let payload = null;
		payload ={ is_display:false };
		fetchAdminApi(`/faq-delete/${record.id}`, "put",payload).then((res) => {
			if (res && res.code === 200) {
				fetchFaqListing();
			}
		});
	};
	const faqColumn = [{
		title: 'Actions',
		align: 'center',
		render: (record) => {
			return (
				<Space>
					<Tooltip title={'Edit'}><Button type="text" onClick = {() =>{history.push({ 
						pathname:"/edit-faq" ,
						state: {
							faqRecord:record,
							faqType : 0
						} 
					});} }  icon={<EditOutlined/>}></Button></Tooltip>
					<Tooltip title={'Delete'}>
						<Popconfirm disabled ={record.is_display === "f"} title="Sure to delete?" onConfirm={() => handleFaqsDelete(record)}>
							<Button disabled ={record.is_display === "f"} type="text" icon={<DeleteOutlined/>}></Button>
						</Popconfirm>
					</Tooltip>
				</Space>
			);
		}
	},
	{
		title: 'FAQs',
		key: 'questions',
		dataIndex: 'question',
		render:(record) =>{
			return <span dangerouslySetInnerHTML={{ __html: record }}/>;
		}
	},
	{
		title: 'Display Order',
		key: 'display_order',
		dataIndex: 'display_order',
		align: 'center',
	},
	{
		title: 'Display',
		key: 'display',
		dataIndex: 'is_display',
		align: 'center',
		render: (record) => {
			if (record === "t"){
				return <CheckCircleOutlined style={{ fontSize:"large" }}/>;
			}else{
				return <span></span>;
			}
		},
	},
	// Required in future
	// {
	// 	title: 'Image',
	// 	key: 'image',
	// 	dataIndex: 'image',
	// 	align: 'center',
	// 	render: (record) => {
	// 		if(record !== null){
	// 			return <Image height = "50px" width = "50px" src={`${constants.FeaturedImageUrl}/${record}`}/>;
	// 		}else{
	// 			return <img src={record}/>;
	// 		}
	// 	},
	// },
	{
		title: 'New Page',
		key: 'new_page',
		dataIndex: 'is_new_page',
		align: 'center',
		render: (record,linkedurl) => {
			if(record === "t"){
				return <Tooltip title={linkedurl.linked_url}>
					<Button type="text" href={linkedurl.linked_url} target="_blank" icon={<CheckCircleOutlined style={{ 
						fontSize:"large",
						color:"#000",
						paddingTop:"5px"
					}}/>}></Button>
				</Tooltip>;
			}else{
				return <span></span>;
			}
		},
	},
	{
		title: 'Displayed On',
		key: 'dt_displayed',
		dataIndex: 'dt_displayed',
		render: (record) => {
			return <span>{record?moment(record).format(constants.dateFormat): ""}</span>;
		},
		sorter: (a, b) => new Date(a.dt_created) - new Date(b.dt_created),
		width: 150,
	},
	// {
	// 	title: 'Date Displayed',
	// 	key: 'dt_created',
	// 	dataIndex: 'dt_created',
	// 	render: (record) => {
	// 		var dc = record;
	// 		return <span>{dc?moment(dc).format(constants.dateFormat): ""}</span>;
	// 	},
	// 	sorter: (a, b) => new Date(a.dt_created) - new Date(b.dt_created),
	// 	align: 'center',
	// },
	];
	const faqDisplay = [
		{
			label: 'All',
			value: 'All',
		},
		{
			label: 'Displayed',
			value: 'Displayed',
		},
		{
			label: 'Hidden',
			value: 'Hidden' , 
		}
	];
	const onchangeFaqDisplay =(e)=>{
		let filterData = [];
		if(e.target.value ==="Displayed"){
			filterData = filterTable.filter((a)=>{
				return a.is_display === "t";
			});
		}else if(e.target.value ==="Hidden"){
			filterData = filterTable.filter((a)=>{
				return a.is_display === "f";
			});
		}else if(e.target.value ==="All"){
			fetchFaqListing();
		}
		setTableData(filterData);
		setDataLength(filterData.length);
	};
	useEffect(() => {
		fetchFaqListing();
	}, []);
	return (
		<div className="div">
			<div className="p-3" >
				<Breadcrumb>
					<Breadcrumb.Item ><a onClick={() => {
						history.push({ pathname: "/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/masters-menu" });
					}}>Masters</a></Breadcrumb.Item>
					<Breadcrumb.Item>FAQs</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<Row gutter={[24, 24]}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title="FAQs" extra={
						<Form layout="horizontal">
							<Space>
								<Form.Item className="label-default mb-0">
									<Button className = "edit-butoon" onClick={()=> history.push({
										pathname : "/add-faq",
										state : { faqType : 1 }
									})}
									>Add FAQ</Button>
								</Form.Item>
								<Form.Item className="label-default mb-0">
									<Button className = "edit-butoon">Export to Word</Button>
								</Form.Item>
							</Space>
						</Form>
					}>
						<Form.Item className="label-default mb-0">
							<Space className ="space-between">
								<span className="p-1">{dataLength !== null ? `${dataLength} record(s) found.` : ""}</span>
								<Form.Item className="label-default mb-0" label="Display">
									<Radio.Group defaultValue="All" options={faqDisplay} size="small" optionType="button" buttonStyle="solid" onChange={onchangeFaqDisplay}/>
								</Form.Item>
							</Space>
						</Form.Item>
						<Table className="admin-gap-table"
							locale={{ emptyText: 'No Records Found' }}
							dataSource={tableData}
							columns={faqColumn}
							pagination={{ showSizeChanger:true }}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default AdminFaq;